import { useEffect, useRef } from 'react'
import useAnalytics from '../../../analytics/useAnalytics'
import { QuizLogInfo } from '@learnn/analytics/src'
import { QuizStatus } from '../DailyQuizScreen'
import { QuizAttemptDetails, QuizItem } from '@learnn/sdk/src/api/quiz/'

const useQuizAnalytics = (
  quiz: QuizItem | undefined,
  attempt: QuizAttemptDetails | undefined,
  status: QuizStatus,
) => {
  const loggedState = useRef<QuizStatus>('not_started')

  const { logQuizStarted, logQuizSubmitted, logQuizFailed, logQuizPassed } = useAnalytics()

  const logStartQuizEvents = () => {
    if (!quiz) return

    logQuizStarted(quiz.attemptId, {
      title: quiz.title,
      type: 'DAILY',
      score: 0,
    })
  }

  function logSubmitQuizEvents() {
    if (!quiz || !attempt) return

    const attemptToLog: QuizLogInfo = {
      title: quiz.title,
      type: 'DAILY',
      score: Math.round((attempt.correctAnswers / attempt.totalQuestions) * 100),
    }

    logQuizSubmitted(quiz.attemptId, attemptToLog)

    if (attempt.passed) {
      logQuizPassed(quiz.attemptId, attemptToLog)
    } else {
      logQuizFailed(quiz.attemptId, attemptToLog)
    }
    attemptToLog
  }

  useEffect(() => {
    if (loggedState.current === status) return

    if (status === 'started') {
      logStartQuizEvents()
    } else if (status === 'completed') {
      logSubmitQuizEvents()
    }
  }, [status])

  return {}
}

export default useQuizAnalytics
