import style from './yearRecapCard.module.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export const LoaderCard = () => {
  return (
    <SkeletonTheme baseColor='rgba(50, 52, 56, 0.4)' highlightColor='rgba(35, 36, 39, 0)'>
      <div className={style.mainTitle} />
      <div style={{ height: '2rem' }} />
      <div className={style.container}>
        <div className={style.desktopLoader}>
          <div className={style.row}>
            <div className={style.column}>
              <Skeleton
                height={220}
                width={200}
                style={{ marginBottom: '2rem', borderRadius: '24px' }}
              />
            </div>
            <div className={style.column}>
              <div className={style.stat}>
                <Skeleton width={140} height='1.2rem' />
                <Skeleton width={200} height='3.5rem' />
              </div>
              <div className={style.stat}>
                <Skeleton width={130} height='1.2rem' />
                <Skeleton width={180} height='3.5rem' />
              </div>
            </div>
          </div>
          <div className={style.row}>
            <div className={style.column}>
              <div className={style.stat}>
                <Skeleton width={140} height='1.2rem' />
                <Skeleton width={170} height='3.5rem' />
              </div>
              <div className={style.stat}>
                <Skeleton width={130} height='1.2rem' />
                <Skeleton width={140} height='3.5rem' />
              </div>
            </div>
            <div className={style.column}>
              <div className={style.stat}>
                <Skeleton width={230} height='1.2rem' />
                <Skeleton width={170} height='1.4rem' style={{ marginTop: '0.5rem' }} />
                <Skeleton width={190} height='1.4rem' style={{ marginTop: '0.5rem' }} />
                <Skeleton width={140} height='1.4rem' style={{ marginTop: '0.5rem' }} />
                <Skeleton width={200} height='1.4rem' style={{ marginTop: '0.5rem' }} />
                <Skeleton width={130} height='1.4rem' style={{ marginTop: '0.5rem' }} />
              </div>
            </div>
          </div>
        </div>
        <div className={style.mobileLoader}>
          <div className={style.row}>
            <div className={style.column}>
              <Skeleton
                height={140}
                width={120}
                style={{ marginBottom: '2rem', borderRadius: '24px' }}
              />
            </div>
            <div className={style.column}>
              <div className={style.stat}>
                <Skeleton width={120} height='1.2rem' />
                <Skeleton width={170} height='3.5rem' />
              </div>
              <div className={style.stat}>
                <Skeleton width={130} height='1rem' />
                <Skeleton width={180} height='3rem' />
              </div>
            </div>
          </div>
          <div className={style.row}>
            <div className={style.column}>
              <div className={style.stat}>
                <Skeleton width={100} height='1.2rem' />
                <Skeleton width={130} height='3.5rem' />
              </div>
              <div className={style.stat}>
                <Skeleton width={100} height='1.2rem' />
                <Skeleton width={140} height='3rem' />
              </div>
            </div>
            <div className={style.column}>
              <div className={style.stat}>
                <Skeleton width={120} height='1.2rem' />
                <Skeleton width={140} height='1.4rem' style={{ marginTop: '0.5rem' }} />
                <Skeleton width={150} height='1.4rem' style={{ marginTop: '0.5rem' }} />
                <Skeleton width={120} height='1.4rem' style={{ marginTop: '0.5rem' }} />
                <Skeleton width={100} height='1.4rem' style={{ marginTop: '0.5rem' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  )
}
