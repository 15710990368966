import style from './sharePopup.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share'
import { faFacebook, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FocusEvent, useRef, useState } from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import { ShareSocialType, ShareSubjectType } from './shareContext'
import useAnalytics from '../../analytics/useAnalytics'

export type ShareComponentProps = {
  title: string
  url: string
  body: string
  type: ShareSubjectType
  onPress?: (social: ShareSocialType) => unknown
  containerClassName?: string
}

export const ShareComponent = ({
  title,
  url,
  body,
  type,
  onPress,
  containerClassName,
}: ShareComponentProps) => {
  const urlInputRef = useRef<HTMLInputElement>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const { logContentShare } = useAnalytics()

  const handleClick = (social: ShareSocialType) => {
    if (onPress) onPress(social)
    logContentShare(type, social, url)
  }

  const selectAll = (event: FocusEvent<HTMLInputElement>) => event.target.select()
  const copyUrl = () => {
    if (urlInputRef.current) {
      urlInputRef.current.select()
      document.execCommand('copy')
      setShowTooltip(true)
      setTimeout(() => {
        setShowTooltip(false)
      }, 2000)
    }
  }
  const renderTooltip = (props: any) => <Tooltip {...props}>Copiato!</Tooltip>

  return (
    <div className={cx([containerClassName])}>
      <div className={style.title}>{title}</div>

      {type !== 'note' ? (
        <div className={cx([style.containerUrl])}>
          <input
            onClick={e => e.stopPropagation()}
            aria-label='Link da condividere'
            className={cx([style.inputUrl])}
            value={url}
            onFocus={selectAll}
            ref={urlInputRef}
          />
          <div className={cx([style.copyContainer])}>
            <OverlayTrigger overlay={renderTooltip} show={showTooltip}>
              <div
                className={cx([style.copyButton])}
                onClick={e => {
                  e.stopPropagation()
                  copyUrl()
                }}>
                Copia
              </div>
            </OverlayTrigger>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={cx([style.socialBox, 'social-share-webapp'])}>
        <WhatsappShareButton
          url={`${body}\n\n${url}`}
          className={style.button}
          name='whastapp'
          beforeOnClick={() => handleClick('whatsapp')}>
          <div className={cx([style.socialButton, style.whatsapp])}>
            <FontAwesomeIcon icon={faWhatsapp} className={cx([style.socialIcon])} />
            <span className={cx([style.socialName])}>Whatsapp</span>
          </div>
        </WhatsappShareButton>
        <LinkedinShareButton
          summary={body}
          url={url}
          source='https://learnn.com'
          className={style.button}
          name='linkedin'
          beforeOnClick={() => handleClick('linkedin')}>
          <div className={cx([style.socialButton, style.linkedin])}>
            <FontAwesomeIcon icon={faLinkedinIn} className={cx([style.socialIcon])} />
            <span className={cx([style.socialName])}>Linkedin</span>
          </div>
        </LinkedinShareButton>
        <FacebookShareButton
          quote={body}
          url={url}
          className={style.button}
          name='facebook'
          beforeOnClick={() => handleClick('facebook')}>
          <div className={cx([style.socialButton, style.facebook])}>
            <FontAwesomeIcon icon={faFacebook} className={cx([style.socialIcon])} />
            <span className={cx([style.socialName])}>Facebook</span>
          </div>
        </FacebookShareButton>
        <EmailShareButton
          url={`${body}\n\n${url}`}
          className={style.button}
          name='email'
          beforeOnClick={() => handleClick('email')}>
          <div className={cx([style.socialButton, style.email])}>
            <FontAwesomeIcon icon={faEnvelope} className={cx([style.socialIcon])} />
            <span className={cx([style.socialName])}>Email</span>
          </div>
        </EmailShareButton>
      </div>
    </div>
  )
}
