import { FC } from 'react'

import { Menu, Item } from 'react-contexify'
import 'react-contexify/ReactContexify.css'
import { handleItemClick } from '../../utils/contextMenu'

export interface IContextMenu {
  menuId: string
}

export const ContextMenu: FC<IContextMenu> = ({ menuId }) => {
  return (
    <Menu id={menuId} theme='dark'>
      <Item id='newTab' onClick={handleItemClick}>
        Apri in una nuova tab
      </Item>
    </Menu>
  )
}
