import { FC } from 'react'
import { CourseBaseInfo, CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import { CourseCard } from '../CourseCard'
import { CustomCarousel } from '../Carousel/CustomCarousel'

type CoursesCarouselProps = {
  containerClass?: string
  title: string
  courses: (CourseBaseInfo & Partial<CourseBaseInfoEngagement>)[]
  onShowAll?: Function
  onCourseClick: (course: CourseBaseInfo & Partial<CourseBaseInfoEngagement>) => unknown
  myLearnn?: boolean
}

export const CoursesCarousel: FC<CoursesCarouselProps> = ({
  title,
  courses,
  onShowAll,
  myLearnn = true,
  onCourseClick,
}) => {

  return (
    <CustomCarousel 
      title={title}
      data={courses}
      onShowAll={onShowAll}
      carouselKind='course'
      renderSlide={course => {
        return (
          <CourseCard
            course={course}
            onClick={() => onCourseClick(course)}
            myLearnn={myLearnn}
            myLearnnId={course.myLearnnId}
            size='md'
          />
        )
      }}
    />
  );
}
