import { Provider } from '@learnn/analytics/src/providers'
import env from '../../env.json'
import axios from 'axios'
import qs from 'query-string'
import auth from 'src/utils/auth'


let userId: string | null = null
let userEmail: string | null = null

export const activecampaignAnalyticsProvider = (): Provider => {
    const handleEvent = async (name: string, params: { [key: string]: any }) => {
      let eventData = null
  
      switch (name) {
        case 'verified_unlocked': {
          eventData = params['course_title']
          break
        }
        case 'course_completed': {
          eventData = params['course_title']
          break
        }
        case 'lesson_completed': {
          eventData = params['lesson_title']
          break
        }
        case 'lesson_started': {
          eventData = params['lesson_title']
          break
        }
        case 'new_course_started': {
          eventData = params['course_title']
          break
        }
        case 'module_completed': {
          eventData = params['module_title']
          break
        }
      }
  
      if (eventData)
        return {
          user: await auth.getToken(),
          eventName: name,
          eventData: eventData,
          ambiente: env.NODE_ENV === 'production' ? undefined : 'staging'
        }
      else return null
    }
  
    const logEvent = async (name: string, params: { [key: string]: any }) => {
        const eventData = await handleEvent(name, params)
  
        if (eventData) {
          const data = qs.stringify(eventData)
          await axios.post(`${env.AC_TRACKING_API_URL}`, data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
        }
    }
    const logSelectContent = async (contentType: string, itemId: string) =>
      logEvent('select_content', { content_type: contentType, item_id: itemId })
  
    const logLogin = async (method?: string) => logEvent('login', { method: method ?? 'strapi' })
    const logScreenView = async (screenClass: string, screenName: string) => {
        logEvent('screen_view', {
            screen_class: screenClass,
            screen_name: screenName,
          })
    }
    const setUserId = async (id: string) => {
      userId = id
    }
    const setUserEmail = async (email: string) => {
      userEmail = email
    }
  
    return {
      logLogin,
      logSelectContent,
      logEvent,
      logScreenView,
      setUserId,
      setUserEmail,
      name: 'activecampaign',
    }
  }
  