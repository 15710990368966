import { lastProgress } from '@learnn/sdk/src/api/engagement'
import api from './api'
import { CourseBaseInfo, Lesson } from '@learnn/sdk/src/api/course'

export type LastProgress = Omit<lastProgress, 'course'> & {
  course?: CourseBaseInfo
}

const toProgress = (lesson: lastProgress, lessonBase: Lesson, course?: CourseBaseInfo): LastProgress => {
  return {
    id: lesson.id,
    title: lesson.title,
    duration: lesson.duration,
    coverImage: lessonBase.coverImageUrl ?? '',
    course: course,
    progress: lesson.progress ?? 0,
  }
}

export const getLastProgress = async (): Promise<LastProgress> => {
  const { engagement, course, player } = await api

  try {
    const { lastProgresses } = await engagement.getHome([])

    if (lastProgresses.length === 0) throw new Error('Last lesson undefined')

    let courseInfo

    const lessonInfo = await player.getLessonBase(String(lastProgresses[0].id))
    
    if (lastProgresses[0].course) {
      courseInfo = await course.getCourseBaseInfo(lastProgresses[0].course.id)
    }

    return toProgress(lastProgresses[0], lessonInfo, courseInfo)
  } catch (e) {
    console.warn(e)
    throw e
  }
}
