import { Text, useTheme, Dropdown, HorizontalStack, ActiveTag, Box } from '@learnn/designn'
import { FormikProps } from 'formik'
import { FC } from 'react'
import { Profile } from '@learnn/sdk/src/api/profile'
import { getCompanyRoles, getGoals, getInterests, getSegments } from '../../controllers/Profile'
import { useQuery } from 'react-query'
import { SectionCard } from './SectionCard'
import { matchQuery } from '../../utils/matchQuery'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const getDefaultValues = async () => {
  return {
    companyRoles: await getCompanyRoles(),
    goals: await getGoals(),
    interests: await getInterests(),
    segments: await getSegments(),
  }
}

type PrivateProfileEditorProps = {
  profile?: Profile
  formik: FormikProps<any>
  noBorder?: boolean
}
export const PrivateProfileEditor: FC<PrivateProfileEditorProps> = ({
  profile,
  formik,
  noBorder,
}) => {
  const { colors, spacing } = useTheme()
  const defaultValuesQuery = useQuery('default-values', getDefaultValues)

  const removeInterest = (value: string) => {
    const newInterests = formik.values.interests.filter(i => i !== value)
    formik.setFieldValue('interests', newInterests)
  }

  const addInterest = (value: string) => {
    const newInterests = [...formik.values.interests, value]
    formik.setFieldValue('interests', newInterests)
  }

  return (
    <SectionCard noBorder={noBorder}>
      <Text textColor={colors.text.dimmed}>
        Queste informazioni ci aiutano a migliorare la tua esperienza su Learnn.
      </Text>
      <Text textColor={colors.text.dimmed}>
        Tienile aggiornate, ti consiglieremo così sempre i corsi più adatti alle tue esigenze.
      </Text>
      {matchQuery(defaultValuesQuery, {
        error: () => <></>,
        loading: () => (
          <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
            <Box mt={spacing.space_10}>
              <Skeleton height={spacing.space_3} width={spacing.space_32} borderRadius={15} />
            </Box>
            <Box>
              <Skeleton height={spacing.space_8} borderRadius={15} />
            </Box>
            <Box mt={spacing.space_10}>
              <Skeleton height={spacing.space_3} width={spacing.space_28} borderRadius={15} />
            </Box>
            <Box>
              <Skeleton height={spacing.space_8} borderRadius={15} />
            </Box>
            <Box mt={spacing.space_10}>
              <Skeleton height={spacing.space_3} width={spacing.space_20} borderRadius={15} />
            </Box>
            <Box>
              <Skeleton height={spacing.space_8} borderRadius={15} />
            </Box>
          </SkeletonTheme>
        ),
        success: ({ companyRoles, goals, interests, segments }) => (
          <>
            {companyRoles.length > 0 && (
              <Dropdown
                variant='dark'
                label="Qual è il tuo ruolo?"
                size='lg'
                items={companyRoles}
                onSelect={value => formik.setFieldValue('companyRole', value.id)}
                selectedId={
                  companyRoles.find(r => r.id.toUpperCase() === profile?.companyRole?.toUpperCase())
                    ?.id
                }
                mt={spacing.space_10}
                mb={spacing.space_8}
              />
            )}

            {segments.length > 0 && (
              <Dropdown
                variant='dark'
                label='Come ti identifichi professionalmente?'
                size='lg'
                items={segments}
                onSelect={value => formik.setFieldValue('segment', value.id)}
                selectedId={
                  segments.find(r => r.id.toUpperCase() === profile?.segment?.toUpperCase())?.id
                }
                mb={spacing.space_8}
              />
            )}

            {goals.length > 0 && (
              <Dropdown
                variant='dark'
                label='Il tuo obiettivo professionale'
                size='lg'
                items={goals}
                onSelect={value => formik.setFieldValue('goal', value.id)}
                selectedId={
                  goals.find(g => g.id.toUpperCase() === profile?.goal?.toUpperCase())?.id
                }
                mb={spacing.space_8}
              />
            )}

            <Text variant='bodyXs'>Argomenti che vuoi approfondire</Text>
            <HorizontalStack
              mt={spacing.space_4}
              gap={spacing.space_2}
              alignItems='center'
              flexWrap='wrap'>
              {interests.map(interest => (
                <ActiveTag
                  body={interest.label}
                  variant='select'
                  selected={profile?.interests?.includes(interest.id)}
                  onClick={selected =>
                    selected ? addInterest(interest.id) : removeInterest(interest.id)
                  }
                />
              ))}
            </HorizontalStack>
          </>
        ),
      })}
    </SectionCard>
  )
}
