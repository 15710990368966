import { useContext, useEffect, useState } from "react"
import auth from "../../utils/auth"
import { extractConfiguration } from "../../utils/data";
import { GlobalProviderContext } from "../GlobalProvider";
import env from '../../env.json'
import { generateAutologinToken } from "../../controllers/User";
import { isIOS, isAndroid, isMobile } from 'react-device-detect'

export const useOpenAppBanner = () => {
    const [show, setShow] = useState(false)
    const globalContext = useContext(GlobalProviderContext);
    const configuration = extractConfiguration(globalContext);

    const getPlatform = (): 'android' | 'ios' | undefined => {
        if (isMobile && isAndroid) {
            return 'android';
        } 
        else if (isMobile && isIOS) {
            return 'ios';
        }
        return undefined
    }
  
    useEffect(() => {
        (async () => {
            const platform = getPlatform()
            if (configuration?.premiumVisible && platform !== null) {
                const closed = auth.getOpenAppBannerClosed()
                setShow(!!!closed)
            }
        })()
    }, [])

    return { 
        show,
        close: async () => {
            await auth.setOpenAppBannerClosed((new Date()).toISOString())
            setShow(false)  
        },
        openApp: async () => {
            let autologinToken;
            try {
              autologinToken = await generateAutologinToken()
            } catch (e) {
              console.error('Error retrieving autologin token')
            }
            const link = `learnn://login${autologinToken ? `?autologin=${encodeURIComponent(autologinToken)}` : ''}`
            window.location.href = link
            setTimeout(() => {
                if (document.visibilityState === 'visible') {
                    const getStoreUrl = () => {
                        const platform = getPlatform()
                        switch(platform) {
                            case 'android': return env.LEARNN_PLAY_STORE_LINK
                            case 'ios': return env.LEARNN_APP_STORE_LINK
                            default: return undefined
                        }
                    }
                    const storeUrl = getStoreUrl()
                    if (storeUrl)
                        window.location.href = storeUrl;
                }
            }, 4000);
        }
    }
}