import { FC, useState, useEffect, useContext } from 'react'
import cx from 'classnames'
import style from './myLearnn.module.css'
import { useQueryClient } from 'react-query'
import { saveCourse, saveLesson, unsaveItem } from '../../controllers/MyLearnn'
import { GlobalProviderContext } from '../GlobalProvider'
import { Tooltip } from '../Tooltip'
import { ReactComponent as AddedIcon } from '../../assets/images/icons/my_learnn_added.svg'
import { ReactComponent as AddIcon } from '../../assets/images/icons/my_learnn_add.svg'
import { ReactComponent as AddIconWhite } from '../../assets/images/icons/my_learnn_add_white.svg'

import useAnalytics from '../../analytics/useAnalytics'

type MyLearnnButton = {
  itemType: 'course' | 'lesson'
  itemId: string
  myLearnnId: string | undefined
  className?: string
  textClassName?: string
  containerClass?: string
  tooltip?: boolean
  text?: string
  savedText?: string
  addIconColor?: 'light' | 'dark'
}

export const MyLearnnButton: FC<MyLearnnButton> = ({
  itemType,
  itemId,
  myLearnnId,
  className,
  containerClass,
  tooltip,
  text,
  savedText,
  textClassName,
  addIconColor = 'dark',
}) => {
  const [saved, setSaved] = useState(myLearnnId === "true" ? true : false)
  const [myLearnnElement, setMyLearnnElement] = useState<string | undefined>(myLearnnId)
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId
  const queryClient = useQueryClient()
  const { logLessonSave, logLessonUnsave, logCourseSave, logCourseUnsave } = useAnalytics()

  useEffect(() => {
    setSaved(myLearnnId === "true" ? true : false)
    setMyLearnnElement(myLearnnId)
  }, [myLearnnId])

  const handleSave = async () => {
    let id: string = ''
    if (userId) {
      try {
        console.log(itemType, itemId, myLearnnId)
        if (saved && myLearnnElement) {
          await unsaveItem(itemType, itemId)
          if (itemType === 'course') {
            logCourseUnsave(myLearnnElement)
          } else {
            logLessonUnsave(myLearnnElement)
          }
        } else {
          if (itemType === 'course') {
            id = await saveCourse(itemId)
            logCourseSave(itemId)
          } else {
            id = await saveLesson(itemId)
            logLessonSave(itemId)
          }
        }
        setMyLearnnElement(id)
        setSaved(!saved)

        queryClient.refetchQueries(['myLearnn', userId])
      } catch (error) {
        console.log(error)
      }
    }
  }
  return tooltip ? (
    <Tooltip text={saved ? 'Rimuovi da MyLearnn' : 'Aggiungi a MyLearnn'} position='right'>
      <button
        className={cx([style.container, containerClass])}
        onClick={async e => {
          e.stopPropagation()
          await handleSave()
        }}>
        {!saved && <AddIcon className={cx([style.saveIcon, className])} />}
        {saved ? <AddedIcon className={cx([style.saveIcon, className])} /> : null}
      </button>
    </Tooltip>
  ) : (
    <button
      className={cx([style.container, containerClass])}
      onClick={async e => {
        e.stopPropagation()
        await handleSave()
      }}>
      {!saved ? (
        addIconColor === 'light' ? (
          <AddIconWhite className={cx([style.saveIcon, className])} />
        ) : (
          <AddIcon className={cx([style.saveIcon, className])} />
        )
      ) : null}
      {saved ? <AddedIcon className={cx([style.saveIcon, className])} /> : null}
      {text ? (
        <span className={cx([style.text, textClassName])}>
          {saved ? savedText ?? 'Salvato' : text}
        </span>
      ) : null}
    </button>
  )
}
