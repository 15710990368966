import { CourseCard } from '../CourseCard'
import useAnalytics from '../../analytics/useAnalytics'
import {
  ComponentSectionStaticPath,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/dist/api/dynamic-sections/types'
import * as F from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { useCoursePopup } from '../CoursePopup/useCoursePopup'
import { CourseBaseInfoEngagement, CourseBaseInfo } from '@learnn/sdk/dist/api/course'
import { getAuthorsNames } from '../../utils/data'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'
import { useHistory } from 'react-router-dom'

export interface IPathCarouselProps {
  data: DynamicSectionsValue<
    DynamicSectionsError,
    ComponentSectionStaticPath
  >
  myLearnn?: boolean
}

export const PathCoursesCarousel = ({ data, myLearnn = false }: IPathCarouselProps) => {
  const { logSelectSliderCourse, logOpenPathFromSlider } = useAnalytics()
  const history = useHistory()
  const { show } = useCoursePopup()

  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const handleCourseClick = (
    course: CourseBaseInfo & Partial<CourseBaseInfoEngagement>,
    section: ComponentSectionStaticPath,
  ) => {
    console.log('click course', course)
    logSelectSliderCourse(course.id, {
      course_title: course.title,
      course_type: course.type.slug,
      course_duration: course.duration,
      course_author: getAuthorsNames(course.authors),
      slider_title: section.title,
    })

    show(course)
  }

  const render = (
    section: ComponentSectionStaticPath,
  ) => {
    return (
      <CustomCarousel
        title={section.title}
        data={section.courses}
        carouselKind='course'
        onShowAll={() => {
          logOpenPathFromSlider(section.path.id, section.path.title, {slider_title: section.title})
          history.push(`/paths/${section.path.slug}`)
        }}
        renderSlide={course => {
          return (
            <CourseCard
              course={course}
              onClick={() => handleCourseClick(course, section)}
              myLearnn={myLearnn}
              myLearnnId={course.myLearnnId}
              size='md'
            />
          )
        }}
      />
    )
  }

  return F.pipe(
    data,
    O.fold(CustomCarouselLoading, data => {
      return F.pipe(data, E.fold(error, render))
    }),
  )
}
