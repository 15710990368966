import api from "./api";

export const getComingContents = async () => {
  try {
    const { comingcontents } = await api;
    const contents = await comingcontents.get();
    return contents;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
