import { gql } from '@apollo/client';
import { normalizeGraphqlResponse } from '../utils';
import { extractCourseAuthorAndProfession, extractThumbUrlFromImage, calculateLessonDuration, } from '../utils';
import { toCourseBaseInfo, } from '../course';
function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        ;
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}
const normalize = (l) => {
    l = l.filter(function (lesson) {
        return lesson.content !== null;
    });
    let lessons = l.map(toLesson);
    const result = lessons.reduce((acc, d) => {
        const found = acc.find(a => a.course.id === d.course.id);
        if (!found) {
            acc.push({
                course: { title: d.course.title, id: d.course.id, type: 'course' },
                lessons: [d],
            });
        }
        else {
            found.lessons.push(d);
        }
        return acc;
    }, []);
    return result.flatMap((x) => x.lessons).flat();
};
export const mylearnn = (client) => {
    const get = async (userId) => {
        const response = await client
            .query({
            query: GET_MY_LEARNN,
            variables: { userId },
        })
            .then(normalizeGraphqlResponse);
        const data = groupBy(response.data.myLearnns, 'type');
        const mylearnn = {
            courses: data.course
                ? data.course
                    .sort((a, b) => (+new Date(a.createdAt) < +new Date(b.createdAt) ? 1 : -1))
                    .map(toCourse)
                : [],
            lessons: data.lesson ? normalize(data.lesson.reverse()) : [],
        };
        return mylearnn;
    };
    const getElementId = async (userId, type, itemId) => {
        const response = await client
            .query({
            query: GET_ELEMENT,
            variables: { userId, itemId, type },
        })
            .then(normalizeGraphqlResponse);
        const element = response.data.myLearnns[0];
        if (!element)
            return undefined;
        return element.id;
    };
    const saveCourse = async (userId, courseId) => {
        const response = await client.mutate({
            mutation: SAVE_CONTENT,
            variables: {
                userId,
                contentId: courseId,
                type: 'course',
            },
        });
        const id = response.data.createMyLearnn.myLearnn.id;
        return id;
    };
    const saveLesson = async (userId, lessonId) => {
        const response = await client.mutate({
            mutation: SAVE_CONTENT,
            variables: {
                userId,
                contentId: lessonId,
                type: 'lesson',
            },
        });
        const id = response.data.createMyLearnn.myLearnn.id;
        return id;
    };
    const removeItem = async (id) => {
        await client.mutate({
            mutation: DELETE_CONTENT,
            variables: {
                id,
            },
        });
    };
    return {
        get: get,
        getElementId: getElementId,
        saveCourse: saveCourse,
        saveLesson: saveLesson,
        removeItem: removeItem,
    };
};
const toLesson = (my) => {
    const { course } = my.content;
    return {
        id: String(my.content.id),
        myLearnnId: my.id,
        title: my.content.title,
        durationSeconds: my.content.duration,
        authors: course.authors.map(extractCourseAuthorAndProfession),
        coverImageUrl: extractThumbUrlFromImage(my.content.cover ?? course.coverImage, 'medium') ?? '',
        duration: calculateLessonDuration(my.content.duration),
        course: { title: course.title, id: String(course.id) },
        type: my.content.awsMedia?.id ? 'video' : 'text',
        contentType: my.content.awsMedia?.id ? 'media' : 'text',
        free: my.content.free ?? false,
    };
};
const toCourse = (my) => {
    const course = toCourseBaseInfo(my.content);
    return {
        myLearnnId: my.id,
        ...course,
    };
};
const GET_MY_LEARNN = gql `
  query getMyLearnn($userId: ID!) {
    myLearnns(filters: { user: { id: { eq: $userId } } }) {
      data {
        id
        attributes {
          type
          contentId
          content
          createdAt
        }
      }
    }
  }
`;
const GET_ELEMENT = gql `
  query getMyLearnnElement($userId: ID!, $itemId: String!, $type: String!) {
    myLearnns(
      filters: { user: { id: { eq: $userId } }, contentId: { eq: $itemId }, type: { eq: $type } }
    ) {
      data {
        id
      }
    }
  }
`;
const SAVE_CONTENT = gql `
  mutation saveContent($userId: ID!, $contentId: String!, $type: ENUM_MYLEARNN_TYPE!) {
    createMyLearnn(data: { user: $userId, contentId: $contentId, type: $type }) {
      data {
        id
      }
    }
  }
`;
const DELETE_CONTENT = gql `
  mutation deleteContent($id: ID!) {
    deleteMyLearnn(id: $id) {
      data {
        id
      }
    }
  }
`;
