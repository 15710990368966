import { CourseLessonsEngagement, Module, ModuleEngagement } from "@learnn/sdk/src/api/course"
import { FC, useMemo, useRef } from "react"
import { Title, VerticalStack, useTheme } from "@learnn/designn"
import useAnalytics from "src/analytics/useAnalytics"
import { CompactModuleGroup } from "./CompactModuleGroup/CompactModuleGroup"
import style from "./compactModules.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { useHistory } from "react-router-dom"
import { useCoursePopup } from "./useCoursePopup"
import { CourseStatusCard } from "../CourseStatusCard/CourseStatusCard"

type CompactModulesProps = {
  course: CourseLessonsEngagement
  userId: string
}
export const CompactModules: FC<CompactModulesProps> = ({
  course,
  userId
}) => {
  const highlightModuleRef = useRef<null | HTMLDivElement>(null)
  const { logCoursePopupExpandCourseModule, logCoursePopupOpenLesson } = useAnalytics()
  const history = useHistory()
  const { spacing } = useTheme()
  const { close } = useCoursePopup()

  const handleLessonClick = (id: string) => {
    close()
    history.push(`/player/${id}`)
  }
  
  const modulesMap = useMemo(
    () =>
      course?.modules?.map((module: Module & Partial<ModuleEngagement>, i) => {
        return (
          <div
            ref={module.id === course.lastViewedModule ? highlightModuleRef : null}
            key={`module-course-${module.id}`}
            onClick={() => {
              if (!module.last_viewed) {
                logCoursePopupExpandCourseModule(module.id, module.title)
              }
            }}>
            {module.sectionTitle && (
              <Title variant='headingSm' fontWeight='semibold' mb={spacing.space_2} mt={i === 0 ? spacing.space_1 : spacing.space_3}>{module.sectionTitle}</Title>
            )}
            <CompactModuleGroup
              lastProgress={course.lastProgress}
              module={module}
              courseId={course.id}
              lastItem={i === course?.modules.length - 1}
              initVisible={module.last_viewed}
            />
          </div>
        )
      }),
    [course?.modules],
  )

  return (
    <VerticalStack>
      {course?.modules.length === 1 && course?.modules[0].lessons.length == 1 ?
        <div
          className={style.lastCourseCard}
          onClick={() => {
            logCoursePopupOpenLesson(course?.modules[0].lessons[0].id, course?.modules[0].lessons[0].title)
            handleLessonClick(course?.modules[0].lessons[0].id)
          }}>
          <div className={style.lastCourseInfo}>
            <div className={style.details}>
              <div className={style.lastCourseSubtitle}>
                {course?.modules[0].lessons[0].progress > 0 ? 'CONTINUA A GUARDARE' : 'INIZIA A GUARDARE'}
              </div>
              <Title variant='headingMd' mt={spacing.space_2} mb={spacing.space_3}>{course?.modules[0].lessons[0].title}</Title>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <div className={style.lastCourseDuration}>{course?.modules[0].lessons[0].duration}</div>
                <div className={style.lastCourseProgressBarContainer}>
                  <div
                    className={style.lastCourseProgressBar}
                    style={{ width: `${course?.modules[0].lessons[0].progress}%` ?? 0 }}
                  />
                </div>
              </div>
            </div>
            <div
              className={style.lastCourseCallToAction}
              onClick={() => handleLessonClick(course?.modules[0].lessons[0].id)}>
              <FontAwesomeIcon icon={faPlay} />
            </div>
          </div>
        </div>
        :
        <VerticalStack>
          <Title variant='headingMd' mb={spacing.space_4}>Moduli del corso</Title>
          {modulesMap}
        </VerticalStack>
      }

      <CourseStatusCard 
        userId={userId}
        courseId={course.id}
        courseProgressPercentage={Math.floor(course.progress)}
        variant="small"
      />
    </VerticalStack>
  )
}