import { createContextReducer } from '../../../utils/createContext'


export type PlayerSideStateType = {
  status: 'visible' | 'hide'
  selectedTab: number,
  playerSideFocus: boolean
}

export const playerSideInitialState: PlayerSideStateType = {
  status: 'visible',
  selectedTab: 0,
  playerSideFocus: false
}

export type IPlayerStateActions = PlayerSideStateType & {}

function reducer(state: PlayerSideStateType, newState: IPlayerStateActions) {
  return { ...state, ...newState }
}

const [ctx, Provider] = createContextReducer<PlayerSideStateType, IPlayerStateActions>(reducer, playerSideInitialState)
export const PlayerSideContext = ctx
export const PlayerSideContextProvider = Provider
