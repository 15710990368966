import style from './styles/pathCertificateAchieved.module.css'
import env from '../../../env.json'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ShareComponent } from '../../../components/SharePopup/Share'
import Confetti from 'react-confetti'
import { useWindowSize } from '../../../utils/hooks'
import { PathAttemptData } from '@learnn/sdk/dist/api/quiz'
import { PathWithProgress } from '../../../controllers/Paths'

export type PathCertificateAchievedProps = {
  onSubmitClick: () => unknown
  attempt: PathAttemptData
  path: PathWithProgress
}
export const PathCertificateAchieved = ({
  onSubmitClick,
  attempt,
  path,
}: PathCertificateAchievedProps) => {
  const size = useWindowSize()
  return (
    <div
      className={style.popupContainer}
      onClick={event => {
        event.preventDefault()
        onSubmitClick()
      }}>
      <div className={style.content}>
        <FontAwesomeIcon
          icon={faTimes}
          className={style.closeIcon}
          onClick={() => onSubmitClick()}
        />
        <div
          className={cx([style.box])}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          <img src={env.QUIZ_GOAL_ACHIVED_IMAGE_URL} className={style.feedbackImage} />
          <h1 className={style.title}>Complimenti!</h1>
          <p className={style.description}>
            Hai appena riscattato la Verifica Finale per il percorso "{path.title}"
          </p>
          <button
            className={style.ctaButton}
            onClick={() => {
              window.open(`https://learnn.com/v/${attempt.attemptId}`, '_blank')
            }}>
            Visualizza <FontAwesomeIcon icon={faChevronRight} className='ml-2' />
          </button>
          <ShareComponent
            type='external'
            title={`Verifica Finale - "${path.title}"`}
            url={`https://learnn.com/v/${attempt.attemptId}`}
            body={`Ho appena riscattato la Verifica Finale per il percorso "${path.title}" su Learnn!`}
            containerClassName={cx([style.shareContainer])}
          />
        </div>
      </div>
      <Confetti
        width={size.width ? size.width - 20 : 0}
        height={size.height}
        recycle={false}
        numberOfPieces={1000}
        tweenDuration={6000}
      />
    </div>
  )
}
