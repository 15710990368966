const AC_FF_AI_ASSISTANT_TAG = 'ff-ai-assistant';
export const defaultFeatureConfig = {
    'ai-assistant': false,
};
export const getFeatures = () => async ({ acData, featureFlagCmsOverride }) => {
    const getAiAssistantStatus = async () => {
        if (featureFlagCmsOverride?.['ai-assistant'] === false)
            return false;
        if (acData?.tags.some(x => x === AC_FF_AI_ASSISTANT_TAG))
            return true;
        return false;
    };
    return {
        'ai-assistant': await getAiAssistantStatus(),
    };
};
export const featureFlag = () => {
    const getFeaturesFactory = getFeatures();
    return {
        getFeatures: getFeaturesFactory,
    };
};
