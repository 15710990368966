import { getCourse } from '../../../../controllers/Course'
import { useBodyClass } from '../../../../utils/hooks'
import { ErrorMessage } from '../../../../components/ErrorMessage'
import { Lessons } from './Lessons'
import { useQuery } from 'react-query'
import { matchQuery } from '../../../../utils/matchQuery'
import { Container } from '@learnn/designn'
import { Loader } from './Loader'
import { useContext } from 'react'
import { GlobalProviderContext } from '../../../../components/GlobalProvider'

export type LessonsSectionProps = {
  courseId: string
}

export const LessonsSection = ({ courseId }: LessonsSectionProps) => {
  useBodyClass('app')
  const globalContext = useContext(GlobalProviderContext);
  const userId = globalContext?.userId ?? ''
  const courseQuery = useQuery(['courseLessons', courseId], () => getCourse(courseId))

  return (
    <>
      {matchQuery(courseQuery, {
        loading: () => (
          <Container>
            <Loader />
          </Container>
        ),
        error: () => (
          <Container>
            <ErrorMessage />
          </Container>
        ),
        success: course => <Lessons course={course} userId={userId}/>,
      })}
    </>
  )
}
