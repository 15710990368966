import { FeaturesConfig } from "@learnn/sdk/src/api/feature-flag"
import { useQuery } from "react-query"
import { GlobalProviderContext } from "../GlobalProvider"
import { useContext, useEffect, useState } from "react"
import { getFeatures } from "../../controllers/FeatureFlag"
import { matchQuery } from "../../utils/matchQuery"

type SetupFeatureFlag = {
    features: FeaturesConfig
}

export const setupFeatureFlag = (): SetupFeatureFlag => {
    const globalContext = useContext(GlobalProviderContext);
    const userId = globalContext?.userId
    const acData = globalContext?.acData
    const featureFlagCmsOverride = globalContext?.configuration.featureFlagOverride
    
    const [features, setFeatures] = useState<FeaturesConfig>({})
    const featuresQuery = useQuery(['feature-flag', userId], () => getFeatures({userId, acData, featureFlagCmsOverride}), { enabled: !!(acData && userId) })

    useEffect(() => {
        matchQuery(featuresQuery, {
            loading: () => {
            }, 
            error: () => {
            },
            success: (data) => {
                setFeatures(data)
            }
        })
    }, [featuresQuery])

    return {
        features
    }
}