import { createContext } from 'react'
import { Provider } from '@learnn/analytics/src/providers'
import { ConfigHash } from '@learnn/sdk/dist/api/configuration/'
import { User } from './GlobalProvider'
import { TeamRole } from '@learnn/sdk/dist/api/auth'
import { FeatureFlagOverride } from '@learnn/sdk/src/api/configuration'

export type IGlobalProviderContext = {
  configuration: IConfiguration
  analyticsProvider: Provider
  userId: string
  userEmail: string
  user: User
  acData?: AcData
  b2b: B2BData
}

export type IHomeBanner =
  | { enable: false }
  | {
      enable: true
      title: string
      description?: string
      imageUrl?: string
      url?: string
      target?: string
    }

export type AcData = { tags: string[] }

export type B2BData = { teamId: string | undefined; role: TeamRole | undefined }

export type IConfiguration = {
  enableTracking?: boolean
  trackingMode?:
    | 'void'
    | 'firebase'
    | 'gtm'
    | 'console'
    | 'gtm_activecampaign'
    | 'gtm_activecampaign_posthog'
  homeBanner?: IHomeBanner
  searchCategories?: string[]
  premiumVisible: boolean
  howtoLogo?: string
  webinarLogo?: string
  jobLogo?: string
  quizAvailability?: ConfigHash
  featureFlagOverride?: FeatureFlagOverride
  maintenanceMode?:
    | {
        active: false
      }
    | { active: true; title: string; message: string }
}

export const GlobalProviderContext = createContext<IGlobalProviderContext | null>(null)
