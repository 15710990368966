import { gql } from '@apollo/client';
import { getYoutubeVideoId } from '../utils';
export const webinar = (client) => {
    const getWebinar = async (webinarId) => {
        const result = await client.query({
            query: WEBINAR_BY_KEY,
            variables: {
                webinarId: webinarId,
            },
        });
        if (!result.data?.liveContent) {
            throw new Error(`Webinar ${webinarId} not found`);
        }
        return {
            ...result.data?.liveContent,
            youtubeId: getYoutubeVideoId(result.data?.liveContent.playerHref),
        };
    };
    return {
        get: getWebinar,
    };
};
const WEBINAR_BY_KEY = gql `
  query liveContents($webinarId: ID!) {
    liveContent(id: $webinarId) {
      id
      title
      description
      coverImage {
        urlCdn
      }
      playerHref
    }
  }
`;
