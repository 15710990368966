import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Formik } from 'formik'
import style from './forgotPasswordScreen.module.css'
import * as Yup from 'yup'
import cx from 'classnames'
import { sendMagicLink } from '../../controllers/User'
import { LiveChat } from '../../components/LiveChat'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Inserisci un indirizzo email valido')
    .required('Inserisci il tuo indirizzo email'),
})

export const ForgotPasswordScreen = ({ history }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const isLocalhost =
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

  const handleForm = async (email, setSubmitting) => {
    setLoading(true)
    try {
      const result = await sendMagicLink(email, location.origin + '/reset-password')
      setError('')
      setSubmitted(true)
    } catch (error) {
      setSubmitting(false)
      setLoading(false)
      setError('Impossibile recuperare la password.')
    }
  }

  let manyChatScript
  if (!isLocalhost) {
    manyChatScript = <LiveChat />
  }

  return (
    <>
      {manyChatScript}
      <Header />
      <Container className={style.container}>
        <Row className='justify-content-center'>
          <Col xs={12} md={6}>
            {!submitted ? (
              <>
                <h1 className={style.title}>Recupera password</h1>
                <p className={style.subtitle}>
                  Inserisci il tuo indirizzo email e recupera subito la tua password.
                </p>
              </>
            ) : (
              <>
                <h1 className={style.title}>Controlla la tua email</h1>
                <p className={style.subtitle}>
                  Ti abbiamo appena inviato un link che ti consentirà di recuperare la password.
                </p>
              </>
            )}
          </Col>
        </Row>
        <Row className='justify-content-center'>
          {!submitted ? (
            <Col xs={11} md={5} lg={4}>
              <Formik
                initialValues={{ email: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleForm(values.email, setSubmitting)
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <input
                        className={cx(['form-control', style.inputField])}
                        type='email'
                        name='email'
                        placeholder='Email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                    </div>
                    <div className={style.errorText}>
                      {(errors.email && touched.email && errors.email) || error}
                    </div>
                    <button
                      className={cx(['btn', 'mt-4', style.submitButton])}
                      type='submit'
                      disabled={isSubmitting}>
                      RECUPERA PASSWORD
                    </button>
                  </form>
                )}
              </Formik>
            </Col>
          ) : null}
        </Row>
      </Container>
      <Footer />
    </>
  )
}
