import { FC } from 'react'
import { SourceCourse, SourceLesson, SourceType } from './assistantService'
import { Box, HorizontalStack, Text, Title, VerticalStack, useTheme } from '@learnn/designn'
import { useQuery } from 'react-query'
import { getCourse } from '../../controllers/Course'
import { matchQuery } from '../../utils/matchQuery'
import { getLesson } from '../../controllers/Player'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import useAnalytics from '../../analytics/useAnalytics'

export type AssistantMessageSourcesProps = {
  sources: SourceType[]
}
export const AssistantMessageSources: FC<AssistantMessageSourcesProps> = ({ sources }) => {
  const { spacing } = useTheme()
  return (
    <VerticalStack>
      <Title variant='headingXs' mb={spacing.space_2}>
        Riferimenti
      </Title>
      {sources.map(source => {
        switch (source.type) {
          case 'lesson':
            return (
              <Box mb={spacing.space_1}>
                <LessonSource source={source} />
              </Box>
            )
          case 'course':
            return (
              <Box mb={spacing.space_1}>
                <CourseSource source={source} />
              </Box>
            )
        }
      })}
    </VerticalStack>
  )
}

type CourseSourceProps = {
  source: SourceCourse
}
const CourseSource: FC<CourseSourceProps> = ({ source }) => {
  const { spacing } = useTheme()
  const courseQuery = useQuery(['course', source.courseId], () => getCourse(source.courseId), {
    refetchOnWindowFocus: false,
  })
  const { logAiAssistantSourceClick } = useAnalytics()

  const handleSourceClick = (courseId: string) => {
    logAiAssistantSourceClick('course', source.courseId)
    window.open(`/corso/${courseId}`, '_blank')
  }

  return (
    <>
      {matchQuery(courseQuery, {
        loading: () => (
          <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
            <Skeleton style={{ width: '300px', height: '20px' }}></Skeleton>
          </SkeletonTheme>
        ),
        error: () => <></>,
        success: course => (
          <HorizontalStack>
            <Text mr={spacing.space_2}>Corso:</Text>
            <HorizontalStack cursor='pointer'>
              <Text
                variant='bodySm'
                fontWeight='bold'
                cursor='pointer'
                onClick={() => handleSourceClick(course.id)}>
                {course.title}
              </Text>
            </HorizontalStack>
          </HorizontalStack>
        ),
      })}
    </>
  )
}

type LessonSourceProps = {
  source: SourceLesson
}
const LessonSource: FC<LessonSourceProps> = ({ source }) => {
  const { spacing } = useTheme()
  const lessonQuery = useQuery(['lesson', source.lessonId], () => getLesson(source.lessonId), {
    refetchOnWindowFocus: false,
  })
  const { logAiAssistantSourceClick } = useAnalytics()

  const handleSourceClick = (courseId: string) => {
    logAiAssistantSourceClick('lesson', source.lessonId)
    window.open(`/player/${courseId}`, '_blank')
  }

  return (
    <>
      {matchQuery(lessonQuery, {
        loading: () => <></>,
        error: () => <></>,
        success: lesson => (
          <HorizontalStack alignItems='center'>
            <Text mr={spacing.space_2}>Lezione:</Text>
            <HorizontalStack cursor='pointer'>
              <Text
                variant='bodySm'
                fontWeight='bold'
                cursor='pointer'
                onClick={() => handleSourceClick(lesson.id)}>
                {lesson.title}
              </Text>
            </HorizontalStack>
          </HorizontalStack>
        ),
      })}
    </>
  )
}
