import { useContext } from 'react'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { useBodyClass } from '../../utils/hooks'
import { useQuery } from 'react-query'
import { ErrorMessage } from '../../components/ErrorMessage'
import { getUserProfile } from '../../controllers/Profile'
import { matchQuery } from '../../utils/matchQuery'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/lib/function'
import { Box, useTheme, HorizontalStack, VerticalStack } from '@learnn/designn'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Preferences } from './Preferences'

export const PreferencesScreen = () => {
  useBodyClass('app')
  const { spacing } = useTheme()
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  if (!userId) return <ErrorMessage />

  const profileQuery = useQuery(['profile', userId], () => getUserProfile(userId), {
    refetchOnWindowFocus: false,
  })

  return (
    <AppLayout>
      {matchQuery(profileQuery, {
        loading: () => (
          <VerticalStack maxWidth='900px'>
            <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
              <HorizontalStack justifyContent='flex-end'>
                <Box mb={spacing.space_3}>
                  <Skeleton height={spacing.space_10} width='150px' borderRadius={10} />
                </Box>
              </HorizontalStack>
              <Box>
                <Skeleton height='500px' borderRadius={10} />
              </Box>
            </SkeletonTheme>
          </VerticalStack>
        ),
        error: () => <ErrorMessage />,
        success: data =>
          pipe(
            data,
            O.fold(
              () => (
                <Box mb={spacing.space_20}>
                  <Preferences />
                </Box>
              ),
              profile => (
                <Box mb={spacing.space_20}>
                  <Preferences profile={profile} />
                </Box>
              ),
            ),
          ),
      })}
    </AppLayout>
  )
}
