import { gql } from '@apollo/client';
import { pathDtoConverter } from './types';
import { DEFAULT_WALL_LIMIT, extractThumbUrlFromImage, normalizeGraphqlResponse } from '../utils';
import { PATH_GRAPH_ATTRIBUTES } from './getPath';
export const getRecommendedPathsBySlug = ({ client }) => async (slug) => {
    const result = await client
        .query({
        query: GET_RECOMMENDED_PATHS,
        variables: { slug: slug },
    })
        .then(normalizeGraphqlResponse);
    const paths = result.data?.paths;
    if (!paths || (paths && paths.length === 0)) {
        throw new Error(`Path ${slug} not found`);
    }
    const recommendedPaths = [];
    paths[0].recommendedPaths?.forEach(item => {
        recommendedPaths.push({
            ...pathDtoConverter(item.path),
            image: item.image
                ? extractThumbUrlFromImage(item.image, 'large')
                : item.path.image && item.path.image.length > 0
                    ? extractThumbUrlFromImage(item.path.image[0], 'large')
                    : null,
        });
    });
    return recommendedPaths;
};
export const GET_RECOMMENDED_PATHS = gql `
  query getPath($slug: String!) {
    paths(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          recommendedPaths(pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
            title
            image {
              data {
              id
                attributes {
                  formats
                  urlCdn
                }
              }
            }
            path {
              data {
                id
                attributes {
                  ${PATH_GRAPH_ATTRIBUTES}
                }
              }
            }  
          }
        }
      }
    }
  }
`;
