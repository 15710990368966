import Error from '../components/Error'

import { LiveChat } from '../components/LiveChat'

const extractMessage = props => props?.location?.state?.errorMessage

function ErrorScreen(props) {
  const errorMessage = extractMessage(props)

  return (
    <>
      <LiveChat />
      <Error message={errorMessage ?? 'Si è verificato un errore'} />
    </>
  )
}

export default ErrorScreen
