import { DependencyList, MutableRefObject, useState, useRef, useEffect } from 'react'

export function useDetectOutsideClick(callback: () => void) {
  const ref: MutableRefObject<any> = useRef(null)
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return ref
}

type Measure = undefined | number
export function useWindowSize() {
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{ width: Measure; height: Measure }>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

const addBodyClass = (className: string) => document.body.classList.add(className)
const removeBodyClass = (className: string) => document.body.classList.remove(className)

export function useBodyClass(className: string | string[]) {
  useEffect(() => {
    className instanceof Array ? className.map(addBodyClass) : addBodyClass(className)
    return () => {
      className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className)
    }
  }, [className])
}

export function useAsyncMemo<T>(
  factory: () => Promise<T> | undefined | null,
  deps: DependencyList,
  initial: T,
): T {
  const [val, setVal] = useState<T>(initial)
  useEffect(() => {
    let cancel = false
    const promise = factory()
    if (promise === undefined || promise === null) return
    promise.then(val => {
      if (!cancel) {
        setVal(val)
      }
    })
    return () => {
      cancel = true
    }
  }, deps)
  return val
}

export const useKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler(event: KeyboardEvent) {
    if (event.key === targetKey) {
      setKeyPressed(true)
    }
  }

  const upHandler = (event: KeyboardEvent) => {
    if (event.key === targetKey) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])
  return keyPressed
}

export const useKeyClick = (targetKey: string, handler: Function) => {

  const upHandler = (event: KeyboardEvent) => {
    if (event.key === targetKey) {
      handler()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keyup', upHandler)
    }
  }, [])
  return null
}

export const useCountdown = (targetDate: string | number | Date) => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown: number) => {
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [minutes, seconds]
}
