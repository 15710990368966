import { FC, useState } from 'react'
import style from './optionSwitch.module.css'
import cx from 'classnames'

type OptionSwitchProps = {
  firstOption: Option
  secondOption: Option
  initialSelectedOption: 'first' | 'second'
}

type Option = {
  text: string
  onClick(): void
}
export const OptionSwitch: FC<OptionSwitchProps> = ({
  firstOption,
  secondOption,
  initialSelectedOption = 'first',
}) => {
  const [selectedOption, selectOption] = useState<'first' | 'second'>(initialSelectedOption)

  const handleSecondOption = () => {
    selectOption('second')
    secondOption.onClick()
  }

  const handleFirstOption = () => {
    selectOption('first')
    firstOption.onClick()
  }
  return (
    <div className={style.container}>
      <div
        className={cx([style.firstOption, selectedOption === 'first' && style.selected])}
        onClick={handleFirstOption}>
        {firstOption.text}
      </div>
      <div
        className={cx([style.secondOption, selectedOption === 'second' && style.selected])}
        onClick={handleSecondOption}>
        {secondOption.text}
      </div>
    </div>
  )
}
