import { EditProfile, Profile, ProfileWithInterests } from '@learnn/sdk/src/api/profile'
import api from './api'
import * as O from 'fp-ts/Option'
import { sanitizeArray, sanitizeInput } from 'src/utils/data'

export const getUserProfile = async (userId: string): Promise<O.Option<Profile>> => {
  const { profile } = await api

  try {
    return O.some(await profile.get(userId))
  } catch (error: unknown) {
    if (error instanceof Error && error.message === 'Not Found') {
      return O.none
    }
    throw error
  }
}

export const getUserProfileWithInterests = async (
  userId: string | undefined,
): Promise<O.Option<ProfileWithInterests>> => {
  const { profile, course } = await api

  if (!userId) return O.none

  try {
    const userProfile = await profile.get(userId)
    // WORKAROUND: Temporary fix until bug #6267 is resolved 
    const cleanInterests = userProfile.interests?.filter(x => x !== '[object Object]')
    if (cleanInterests && cleanInterests.length > 0) {
      const profileInterets = await course.getCourseCategoriesByIds(cleanInterests)
      return O.some({
        ...userProfile,
        interests: profileInterets,
      } as ProfileWithInterests)
    } else {
      return O.some({
        ...userProfile,
        interests: [],
      })
    }
  } catch (error: unknown) {
    if (error instanceof Error && error.message === 'Not Found') {
      return O.none
    }
    throw error
  }
}

export const updateProfile = async (p: EditProfile) => {
  const { profile } = await api

  const newProfile = {
    ...p,
    ...(p.name && { name: sanitizeInput(p.name) }),
    ...(p.lastName && { lastName: sanitizeInput(p.lastName) }),
    ...(p.location && { location: sanitizeInput(p.location) }),
    ...(p.profession && { profession: sanitizeInput(p.profession) }),
    ...(p.companyRole && { companyRole: sanitizeInput(p.companyRole) }),
    ...(p.company && { company: sanitizeInput(p.company) }),
    ...(p.bio && { bio: sanitizeInput(p.bio) }),
    ...(p.nickname && { nickname: sanitizeInput(p.nickname) }),
    ...(p.linkedinLink && { linkedinLink: sanitizeInput(p.linkedinLink) }),
    ...(p.socialLink && { socialLink: sanitizeInput(p.socialLink) }),
    ...(p.websiteLink && { websiteLink: sanitizeInput(p.websiteLink) }),
    ...(p.goal && { goal: sanitizeInput(p.goal) }),
    ...(p.segment && { segment: sanitizeInput(p.segment) }),
    ...(p.availableFor && { availableFor: sanitizeArray(p.availableFor) }),
    ...(p.competences && { competences: sanitizeArray(p.competences) }),
    ...(p.interests && { interests: sanitizeArray(p.interests) }),
  }

  return profile.editProfile(newProfile)
}

export const uploadProfileImage = async (file: File) => {
  const { profile } = await api

  return profile.uploadProfileImage(file)
}

export const getGoals = async () => {
  const { profile } = await api

  return profile.getGoals()
}

export const getCompanyRoles = async () => {
  const { profile } = await api

  return profile.getCompanyRoles()
}

export const getInterests = async () => {
  const { course } = await api

  const courseCategories = await course.getCourseCategories()

  return courseCategories.map(c => ({ id: String(c.id), label: c.title }))
}

export const getSegments = async () => {
  const { profile } = await api

  return profile.getSegments()
}
