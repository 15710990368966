import { LessonCard } from '../LessonCard'
import style from './dynamicSections.module.css'
import useAnalytics from '../../analytics/useAnalytics'
import {
  ComponentSectionDynamicDynamicLessons,
  ComponentSectionStaticLessons,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/dist/api/dynamic-sections/types'
import * as F from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { getUrlFromLesson } from '../../utils/data'
import { useHistory } from 'react-router-dom'
import { Lesson, LessonEngagement } from '@learnn/sdk/dist/api/course'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'

export interface ILessonsCarouselProps {
  data: DynamicSectionsValue<
    DynamicSectionsError,
    ComponentSectionStaticLessons | ComponentSectionDynamicDynamicLessons
  >
  myLearnn?: boolean
}

export const LessonsCarousel = ({ data, myLearnn = false }: ILessonsCarouselProps) => {
  const { logSelectSliderLesson } = useAnalytics()
  const history = useHistory()

  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const handleLessonClick = (
    lesson: Lesson & Partial<LessonEngagement>,
    section: ComponentSectionStaticLessons | ComponentSectionDynamicDynamicLessons,
  ) => {
    logSelectSliderLesson(lesson.id, {
      lesson_title: lesson.title,
      lesson_duration: lesson.duration,
      slider_title: section.title,
    })

    history.push(getUrlFromLesson({ id: lesson.id }))
  }

  const render = (
    section: ComponentSectionStaticLessons | ComponentSectionDynamicDynamicLessons,
  ) => {return (
    <CustomCarousel 
      title={section.title}
      data={section.lessons}
      carouselKind='wideLesson'
      renderSlide={lesson => {
        return (
          <LessonCard
            wide
            myLearnn={myLearnn}
            myLearnnId={lesson.myLearnnId}
            myLearnnClass={style.myLearnnButton}
            lesson={lesson}
            onClick={() => handleLessonClick(lesson, section)}
          />
        )
      }}
    />
  );
  }

  return F.pipe(
    data,
    O.fold(() => CustomCarouselLoading('wideLesson'), data => {
      return F.pipe(data, E.fold(error, render))
    }),
  )
}
