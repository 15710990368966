import { gql } from '@apollo/client';
export const note = (client, player) => {
    const get = async (lessonId) => {
        const response = await client.query({
            query: GET_NOTES,
            variables: { filter: { lessonId: { eq: lessonId } } },
        });
        const noteItem = response.data.getNotes.items.length > 0 ? response.data.getNotes.items[0] : null;
        if (noteItem) {
            const lesson = await player.getLesson(noteItem.lessonId);
            return {
                ...noteItem,
                lesson: {
                    id: lesson.id,
                    title: lesson.title,
                    label: lesson.title,
                },
            };
        }
        else {
            return null;
        }
    };
    const updateNote = async (noteId, content) => {
        const response = await client.mutate({
            mutation: UPDATE_NOTE,
            variables: {
                noteId: noteId,
                data: {
                    data: content,
                },
            },
        });
        const data = response.data.updateNote;
        return data.id;
    };
    const createNote = async (content, viewTime, courseId, lessonId) => {
        const response = await client.mutate({
            mutation: CREATE_NOTE,
            variables: {
                data: {
                    courseId,
                    lessonId,
                    data: content,
                    viewTime,
                },
            },
        });
        const data = response.data.createNote;
        return data.noteId;
    };
    const deleteNote = async (noteId) => {
        await client.mutate({
            mutation: DELETE_NOTE,
            variables: {
                noteId,
            },
        });
    };
    const getCourseNotes = async (courseId) => {
        const response = await client.query({
            query: GET_NOTES,
            variables: { filter: { courseId: { eq: courseId } } },
        });
        const data = response?.data?.getNotes?.items;
        if (!data)
            return [];
        const courseNotes = await Promise.all(data.map(async (noteItem) => {
            const lesson = await player.getLesson(noteItem.lessonId);
            return {
                ...noteItem,
                lesson: {
                    id: lesson.id,
                    title: lesson.title,
                    label: lesson.title,
                },
            };
        }));
        return courseNotes.sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : 0));
    };
    const getLessonNotes = async (lessonId) => {
        const response = await client.query({
            query: GET_NOTES,
            variables: { filter: { lessonId: { eq: lessonId } } },
        });
        const data = response?.data?.getNotes?.items;
        if (!data)
            return [];
        return data.sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : 0));
    };
    return {
        get: get,
        updateNote: updateNote,
        createNote: createNote,
        deleteNote: deleteNote,
        getCourseNotes: getCourseNotes,
        getLessonNotes: getLessonNotes,
    };
};
const GET_NOTES = gql `
  query GetNotes($filter: GetNoteInput!) {
    getNotes(filter: $filter) {
      items {
        noteId
        data
        userId
        viewTime
        courseId
        lessonId
        createdAt
        updatedAt
      }
    }
  }
`;
const UPDATE_NOTE = gql `
  mutation UpdateNote($noteId: String, $data: NoteInput!) {
    updateNote(noteId: $noteId, data: $data) {
      noteId
      data
      userId
      viewTime
      courseId
      lessonId
      createdAt
      updatedAt
    }
  }
`;
const CREATE_NOTE = gql `
  mutation CreateNote($data: NoteInput!) {
    createNote(data: $data) {
      noteId
      data
      userId
      viewTime
      courseId
      lessonId
      createdAt
      updatedAt
    }
  }
`;
const DELETE_NOTE = gql `
  mutation RemoveNote($noteId: String) {
    removeNote(noteId: $noteId) {
      noteId
      data
      userId
      viewTime
      courseId
      lessonId
      createdAt
      updatedAt
    }
  }
`;
