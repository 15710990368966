import * as Yup from 'yup'

export const validationSchema = Yup.object().shape(
  {
    billingName: Yup.string().required('Inserisci una ragione sociale'),
    pec: Yup.string()
      .email('Inserisci un indirizzo PEC valido')
      .ensure()
      .when('codiceDestinatario', {
        is: '',
        then: Yup.string().required('Inserisci PEC o codice destinatario'),
      }),
    codiceDestinatario: Yup.string()
      .matches(/^$|^[a-zA-Z0-9]{7}$/, 'Inserisci un codice destinatario valido')
      .ensure()
      .when('pec', {
        is: '',
        then: Yup.string().required('Inserisci PEC o codice destinatario'),
      }),
    pIva: Yup.string()
      .matches(
        /(^[0-9]{11}$)|(^[A-Za-z]{2,4}(?=.{2,12}$)[-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$)/gm,
        'Inserisci una partita IVA valida',
      )
      .required('Inserisci una partita IVA'),
    cfCodiceFiscale: Yup.string()
      .matches(
        /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]|[0-9]{11}$/,
        'Inserisci un codice fiscale valido',
      )
      .required('Inserisci un codice fiscale'),
    billingAddress: Yup.string().required('Inserisci un indirizzo di fatturazione'),
    billingCity: Yup.string().required('Inserisci una città'),
    cap: Yup.string().required('Inserisci un CAP'),
    fullAddress: Yup.string().required('Inserisci un Indirizzo valido'),
  },
  [['pec', 'codiceDestinatario']],
)
