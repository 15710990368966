import { useEffect, useRef } from 'react'
import QueryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { logout } from '../controllers/User'
import { usePostHog } from 'posthog-js/react'

function Logout() {
  const history = useHistory()
  const posthog = usePostHog()

  const search = location.search
    .replace('appurl', 'appUrl')
    .replace('appDestinationUrl', 'appUrl')
    .replace('appuestinationurl', 'appUrl')
  const urlParams = useRef(QueryString.parse(search))
  const appUrl = urlParams.current?.appUrl as string

  const resetApiToken = async () => {
    posthog.reset(true)
    await logout()

    if (appUrl) {
      window.location.replace(appUrl)
      return
    }
    history.push({ pathname: `/login` })
  }

  useEffect(() => {
    resetApiToken()
  }, [])

  return <></>
}

export default Logout
