import { isLocalhost } from 'src/utils/common'
import env from '../env.json'
import TagManager from 'react-gtm-consent-module'

declare global {
  interface Window {
    dataLayer: Object | undefined
    [key: string]: any
  }
}

export type SendGTMFunction = (data: { [key: string]: any }) => Promise<void>

const useGTM = (gtmId: string, dataLayerName: string, options?: object) => {
  const tagManagerArgs = {
    gtmId,
    dataLayerName,
    ...options,
  }

  if (isLocalhost()) {
    return {
      sendToGTM: async (_data: { [key: string]: any }) => null,
    }
  }

  TagManager.initialize(tagManagerArgs)

  return {
    sendToGTM: async (data: { [key: string]: any }) => {
      TagManager.dataLayer({ dataLayer: data, dataLayerName: tagManagerArgs.dataLayerName })
    },
  }
}

export const useGTMCheckout = () => {
  return useGTM(env.GTM_ID_CHECKOUT, 'dataLayer', {
    consentSettings: {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functional_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
      wait_for_update: 10,
    },
  })
}

export const useGTMWebapp = () => {
  return useGTM(env.GTM_ID_WEBAPP, 'webapp')
}

export const configureGTMWebapp = () => {
  if (isLocalhost()) {
    return {
      sendToGTM: async (_data: { [key: string]: any }) => {},
    }
  }

  TagManager.initialize({
    gtmId: env.GTM_ID_WEBAPP,
    dataLayerName: 'webapp',
  })

  return {
    sendToGTM: async (data: { [key: string]: any }) => {
      TagManager.dataLayer({ dataLayer: data, dataLayerName: 'webapp' })
    },
  }
}
