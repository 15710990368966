import React, { useState, useRef, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
//import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Error from '../../components/Error'
import InputPassword from '../../components/InputPassword'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import auth from '../../utils/auth'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { login, magicLinkLogin, sendMagicLink } from '../../controllers/User'
import env from '../../env.json'
import QueryString from 'query-string'
import style from './loginScreen.module.css'
import outlookIcon from '../../assets/images/icons/outlook.png'
import gmailIcon from '../../assets/images/icons/gmail.png'
import {
  VerticalStack,
  Title,
  HorizontalStack,
  Text,
  useTheme,
  TextInput,
  Button,
} from '@learnn/designn'
import {
  SocialLoginButtons,
  EmailButton,
} from '../../components/SocialLoginButtons/SocialLoginButtons'
import { useGTMCheckout } from '../../analytics/gtm'
import { useBodyClass } from '../../utils/hooks'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Inserisci un indirizzo email valido')
    .required('Inserisci il tuo indirizzo email'),
  password: Yup.string().required('Inserisci la tua password'),
})

const magicLinkValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Inserisci un indirizzo email valido')
    .required('Inserisci il tuo indirizzo email'),
})

const INVALID_CREDENTIAL_ERROR_MESSAGE = 'Email o password errate.'

const extractMessage = props => props?.location.state?.message

const extractEmail = (urlParams, props) => {
  if (urlParams.email) {
    return decodeURI(urlParams?.email)
  }
  return props?.location.state?.email
}

const loginRedirectUrl = env.LOGIN_REDIRECT_URL !== '' ? env.LOGIN_REDIRECT_URL : location.origin

export const LoginScreen = ({ history, ...props }) => {
  useBodyClass('app')
  const currentLocation = new URL(location.href.replace(/#/g, '?'))

  const search = currentLocation.search
    .replace('appurl', 'appUrl')
    .replace('appDestinationUrl', 'appUrl')
    .replace('appuestinationurl', 'appUrl')

  const urlParams = useRef(QueryString.parse(search))
  const [showInvalidCredentialError, setShowInvalidCredentialError] = useState(false)
  const [error, _] = useState('')
  const initialEmail = extractEmail(urlParams.current, props)
  const initialMessage = extractMessage(props)
  const appUrl = urlParams.current?.appUrl
    ? decodeURIComponent(urlParams.current.appUrl)
    : loginRedirectUrl ?? '/'
  const authorizationCode = urlParams.current?.code
  const [magicLinkSended, setMagicLinkSended] = useState(false)
  const [loginWithPassword, setLoginWithPassword] = useState(false)
  const [emailLogin, showEmailLogin] = useState(initialEmail ? true : false)
  const { spacing, borders, colors } = useTheme()
  const [isSubmitting, setSubmitting] = useState(false)

  const datalayer = useGTMCheckout()

  if (auth.isAuthenticated()) {
    auth.refreshToken()
    window.location.replace(appUrl)
  }

  useEffect(() => {
    const magicLogin = async () => {
      if (authorizationCode !== undefined) {
        const result = await magicLinkLogin(location.origin + location.pathname, authorizationCode)
        window.location.replace(appUrl)
      }
    }
    magicLogin()
  }, [authorizationCode])

  const handleForm = async (email, password) => {
    if (showInvalidCredentialError) setShowInvalidCredentialError(false)
    try {
      const result = await login(email, password)
      if (result) {
        if (!result.blocked) {
          window.location.replace(appUrl)
        } else {
          if (urlParams.current?.appUrl) {
            window.location.replace(appUrl)
          } else {
            history.push({ pathname: `/pagamento` })
          }
        }
      } else {
        setSubmitting(false)
        setShowInvalidCredentialError(true)
      }
    } catch (error) {
      setShowInvalidCredentialError(true)
      setSubmitting(false)
    }
  }

  const goToSignIn = () => {
    const appUrl = urlParams.current?.appUrl
    if (appUrl) {
      window.location.replace(`/crea-account/?next_step=${appUrl}`)
    } else {
      window.location.replace('/crea-account')
    }
  }

  if (error) {
    return <Error message={error} />
  }
  if (auth.isAuthenticated()) {
    return <></>
  }
  return (
    <div className={style.root}>
      <div className={style.backgroundImage} id='login-background-container'></div>
      <VerticalStack height='100vh'>
        {/* <Header type='dark' /> */}
        <HorizontalStack
          flex={1}
          mt={{ _: spacing.space_10, small: 0 }}
          alignItems={{ _: 'start', small: 'center' }}
          justifyContent='center'>
          <VerticalStack
            maxWidth='500px'
            borderRadius={borders.radius.large}
            backgroundColor={colors.modal_background}
            paddingY={{ _: spacing.space_8, small: spacing.space_10 }}
            paddingX={{ _: spacing.space_4, small: spacing.space_10 }}
            mx={spacing.space_4}
            borderWidth={spacing.space_035}
            borderStyle='solid'
            borderColor={colors.edge}
            boxShadow={`0 0 ${spacing.space_16} ${spacing.space_16} #000000a1`}>
            <VerticalStack alignItems='center'>
              <Title variant='heading3xl' mb={spacing.space_4} alignment='center'>
                Accedi a Learnn
              </Title>
              {!magicLinkSended && (
                <Text fontWeight='light' alignment='center' mb={spacing.space_8}>
                  {initialMessage
                    ? initialMessage
                    : loginWithPassword
                    ? 'Inserisci l’email e password che hai usato durante la registrazione per accedere a Learnn.'
                    : emailLogin
                    ? 'Inserisci l’indirizzo email che hai usato durante la registrazione per accedere a Learnn'
                    : 'Per accedere, utilizza l’email che hai usato durante la registrazione.'}
                </Text>
              )}
            </VerticalStack>
            {emailLogin ? (
              <VerticalStack>
                {!magicLinkSended && (
                  <HorizontalStack justifyContent='flex-start' mb={spacing.space_2}>
                    <Button
                      paddingLeft={spacing.space_0}
                      size='md'
                      variant='tertiary'
                      icon={<FontAwesomeIcon size='xs' icon={faChevronLeft} />}
                      iconPosition='left'
                      label='Indietro'
                      onPress={() => {
                        showEmailLogin(false)
                        setLoginWithPassword(false)
                      }}
                    />
                  </HorizontalStack>
                )}
                <Formik
                  initialValues={{ email: initialEmail ?? '', password: '' }}
                  validationSchema={
                    loginWithPassword ? validationSchema : magicLinkValidationSchema
                  }
                  onSubmit={async values => {
                    setSubmitting(true)
                    if (loginWithPassword) handleForm(values.email, values.password)
                    else {
                      await sendMagicLink(values.email, location.origin + location.pathname)
                      setMagicLinkSended(true)
                      setSubmitting(false)
                    }
                  }}>
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form>
                      {(loginWithPassword || !magicLinkSended) && (
                        <>
                          <div>
                            <TextInput
                              data-test-id='login-input_email'
                              type='email'
                              name='email'
                              placeholder='Inserisci la tua email'
                              size='xl'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              onFocus={() => setShowInvalidCredentialError(false)}
                              borderColor={colors.outline}
                              borderWidth={spacing.space_035}
                            />
                          </div>
                          <Text
                            fontWeight='light'
                            color='error'
                            variant='bodyXs'
                            mt={spacing.space_1}>
                            {errors.email && touched.email && errors.email}
                          </Text>
                        </>
                      )}

                      {loginWithPassword && (
                        <>
                          <HorizontalStack mt={spacing.space_5} width='100%'>
                            <InputPassword
                              testId='login-input_password'
                              placeholder='Inserisci la password'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              name='password'
                              onFocus={() => setShowInvalidCredentialError(false)}
                            />
                          </HorizontalStack>
                          <Text
                            fontWeight='light'
                            color='error'
                            variant='bodyXs'
                            mt={spacing.space_1}>
                            {errors.password && touched.password && errors.password}
                          </Text>
                        </>
                      )}
                      <Text color='error' variant='bodyXs' mt={spacing.space_5}>
                        {showInvalidCredentialError ? INVALID_CREDENTIAL_ERROR_MESSAGE : ''}
                      </Text>
                      {(loginWithPassword || !magicLinkSended) && (
                        <HorizontalStack justifyContent='center'>
                          <Button
                            width='100%'
                            mt={spacing.space_4}
                            variant='primary'
                            label='ACCEDI'
                            loading={isSubmitting}
                            onClick={handleSubmit}
                            size='lg'
                            data-test-id='login-button_login'
                          />
                        </HorizontalStack>
                      )}
                      {loginWithPassword && (
                        <HorizontalStack mt={spacing.space_4} justifyContent='flex-end'>
                          <span
                            className={style.forgotPassword}
                            onClick={() => history.push('/forgot')}>
                            Password dimenticata?
                          </span>
                        </HorizontalStack>
                      )}
                      {!loginWithPassword && !magicLinkSended && (
                        <VerticalStack
                          borderRadius={borders.radius.medium}
                          backgroundColor={colors.card_background}
                          padding={spacing.space_4}
                          mt={spacing.space_6}>
                          <Text fontWeight='light' alignment='center' variant='bodySm'>
                            Ti invieremo un link magico per email per accedere senza password.
                          </Text>
                          <button
                            data-test-id='login-insert_password'
                            className={style.loginButton}
                            onClick={() => {
                              setLoginWithPassword(true)
                              setMagicLinkSended(false)
                            }}>
                            <Text
                              fontWeight='black'
                              alignment='center'
                              variant='bodySm'
                              mt={spacing.space_2}>
                              Oppure inserisci la password{' '}
                              <FontAwesomeIcon size='xs' icon={faChevronRight} />
                            </Text>
                          </button>
                        </VerticalStack>
                      )}
                      {magicLinkSended && (
                        <VerticalStack alignItems='center' justifyContent='center'>
                          <VerticalStack
                            borderRadius={borders.radius.medium}
                            backgroundColor={colors.card_background}
                            padding={spacing.space_8}
                            mt={spacing.space_6}>
                            <Title variant='headingXl' alignment='center'>
                              Controlla l'email
                            </Title>

                            <Text
                              fontWeight='light'
                              alignment='center'
                              variant='bodySm'
                              mt={spacing.space_8}>
                              Ti abbiamo inviato una email a
                            </Text>
                            <Text
                              fontWeight='black'
                              alignment='center'
                              variant='bodySm'
                              mt={spacing.space_2}>
                              {values.email}
                            </Text>
                            <Text
                              fontWeight='light'
                              alignment='center'
                              variant='bodySm'
                              mt={spacing.space_2}>
                              Se non la ricevi controlla in spam e di avere un account registrato.
                            </Text>
                            <Text
                              fontWeight='light'
                              alignment='center'
                              variant='bodySm'
                              mt={spacing.space_8}>
                              Se non hai un account puoi crearlo
                            </Text>
                            <button className={style.loginButton} onClick={goToSignIn}>
                              <Text
                                alignment='center'
                                fontWeight='black'
                                variant='bodySm'
                                mt={spacing.space_2}>
                                qui <FontAwesomeIcon size='xs' icon={faChevronRight} />
                              </Text>
                            </button>

                            <HorizontalStack
                              justifyContent='space-between'
                              alignItems='center'
                              mt='4'>
                              <HorizontalStack justifyContent='center' alignItems='center'>
                                <img className={style.emailIcon} src={gmailIcon}></img>{' '}
                                <a
                                  href='https://mail.google.com/mail/u/0/'
                                  rel='noopener noreferrer'
                                  target='_blank'>
                                  <HorizontalStack alignItems='center'>
                                    <Text fontWeight='black'>
                                      Apri Gmail <FontAwesomeIcon size='xs' icon={faChevronRight} />
                                    </Text>
                                  </HorizontalStack>
                                </a>
                              </HorizontalStack>
                              <HorizontalStack justifyContent='center' alignItems='center'>
                                <img className={style.emailIcon} src={outlookIcon}></img>{' '}
                                <a
                                  href='https://outlook.live.com/mail/0/inbox'
                                  rel='noopener noreferrer'
                                  target='_blank'>
                                  <HorizontalStack alignItems='center'>
                                    <Text fontWeight='black'>
                                      Apri Outlook{' '}
                                      <FontAwesomeIcon size='xs' icon={faChevronRight} />
                                    </Text>
                                  </HorizontalStack>
                                </a>
                              </HorizontalStack>
                            </HorizontalStack>
                          </VerticalStack>
                          <VerticalStack alignItems='center'>
                            <Text mt={spacing.space_6} mb={spacing.space_2}>
                              In alternativa
                            </Text>
                            <button
                              className={style.loginButton}
                              onClick={() => {
                                setLoginWithPassword(true)
                                setMagicLinkSended(false)
                              }}>
                              <Text fontWeight='black'>
                                Accedi manualmente{' '}
                                <FontAwesomeIcon size='xs' icon={faChevronRight} />
                              </Text>
                            </button>
                          </VerticalStack>
                        </VerticalStack>
                      )}
                    </form>
                  )}
                </Formik>
              </VerticalStack>
            ) : (
              <VerticalStack mt={spacing.space_3}>
                <SocialLoginButtons type='login' next_step={urlParams?.current?.appUrl} />
                <EmailButton onClick={() => showEmailLogin(true)} type='login' />
              </VerticalStack>
            )}
            {!magicLinkSended ? (
              <Row className='justify-content-center mt-5'>
                <VerticalStack alignItems='center'>
                  <Text fontWeight='light' mb={spacing.space_2} mt={spacing.space_4}>
                    Non hai un account?
                  </Text>
                  <button className={style.loginButton} onClick={goToSignIn}>
                    <Text variant='bodySm' fontWeight='black'>
                      Crea il tuo account Learnn <FontAwesomeIcon size='xs' icon={faChevronRight} />
                    </Text>
                  </button>
                </VerticalStack>
              </Row>
            ) : null}
          </VerticalStack>
        </HorizontalStack>
      </VerticalStack>
      <Footer type='dark-minified' />
    </div>
  )
}
