import { useRef, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Formik, FormikProps } from 'formik'
import cx from 'classnames'

import style from './billingScreen.module.css'
import { RouteComponentProps } from 'react-router-dom'

import { LiveChat } from '../../components/LiveChat'
import { AddressAutocomplete } from '../../components/AddressAutocomplete'
import { validationSchema } from './validation'
import { setAddress } from '../../utils/checkout'

import Loader from 'react-loader-spinner'
import { saveBillingData } from '../../controllers/Payment'

interface IPaymentScreenProps {
  history: RouteComponentProps['history']
}

export type FormActions = {
  submit(): void
}

type FormValues = {
  billingName: string
  pec: string
  codiceDestinatario: string
  pIva: string
  cfCodiceFiscale: string
  billingAddress: string
  billingCity: string
  provincia: string
  cap: string
  country: string
  fullAddress: string
}

const BillingScreen = (_props: IPaymentScreenProps) => {
  const [submitted, setFormSubmitted] = useState(false)
  const [error, setError] = useState('')

  const handleForm = async ({
    values,
    setSubmitting,
  }: {
    values: FormValues
    setSubmitting(isSubmitting: boolean): void
  }) => {
    try {
      setError('')

      await saveBillingData({
        cf_codice_fiscale: values.cfCodiceFiscale,
        cf_codice_destinatario: values.codiceDestinatario,
        company: values.billingName,
        email: values.pec,
        line1: values.billingAddress,
        city: values.billingCity,
        zip: values.cap,
        country: values.country,
        state: values.provincia,
        vat_number: values.pIva,
      })

      setFormSubmitted(true)
    } catch (error: any) {
      setError('Si è verificato un errore, riprova o contattaci in live chat.')
      setSubmitting(false)
    }
  }

  const formRef = useRef<FormikProps<FormValues>>()
  return (
    <>
      <LiveChat />
      <Header type='light' />
      <Container className={style.container}>
        <Row className='justify-content-center '>
          <Col xs={12} md={6} lg={5}>
            <h1 className={style.title}>
              {!submitted
                ? 'Inserisci i tuoi dati di fatturazione'
                : 'Dati di fatturazione inviati'}
            </h1>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          {!submitted ? (
            <Col xs={12} md={6} lg={5} className='pr-md-5'>
              <Formik
                innerRef={e => {
                  if (e) {
                    formRef.current = e
                  }
                }}
                initialValues={{
                  billingName: '',
                  pec: '',
                  pIva: '',
                  cfCodiceFiscale: '',
                  codiceDestinatario: '',
                  billingAddress: '',
                  billingCity: '',
                  provincia: '',
                  cap: '',
                  country: '',
                  fullAddress: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleForm({ values, setSubmitting })
                }}>
                {({
                  isSubmitting,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldError,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={cx([style.field, 'mt-5'])}>
                      <input
                        name='billingName'
                        className={values.billingName ? cx([style.input, style.val]) : style.input}
                        type='text'
                        placeholder='Ragione sociale'
                        value={values.billingName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className={style.label}>Ragione sociale</label>
                      <i className={style.bar}></i>
                      <div className={style.errorText}>
                        {errors.billingName && touched.billingName && errors.billingName}
                      </div>
                    </div>
                    <div className={style.adaptiveFields}>
                      <div className={style.adaptiveField}>
                        <input
                          name='pec'
                          className={values.pec ? cx([style.input, style.val]) : style.input}
                          type='text'
                          placeholder='Ragione sociale'
                          value={values.pec}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>PEC</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.pec && touched.pec && errors.pec}
                        </div>
                      </div>
                      <div className={cx([style.adaptiveField, style.rightField])}>
                        <input
                          name='codiceDestinatario'
                          className={
                            values.codiceDestinatario ? cx([style.input, style.val]) : style.input
                          }
                          type='text'
                          placeholder='Codice Destinatario'
                          value={values.codiceDestinatario}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>Codice Destinatario</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.codiceDestinatario &&
                            touched.codiceDestinatario &&
                            errors.codiceDestinatario}
                        </div>
                      </div>
                    </div>

                    <div className={style.adaptiveFields}>
                      <div className={style.adaptiveField}>
                        <input
                          name='pIva'
                          className={values.pIva ? cx([style.input, style.val]) : style.input}
                          type='text'
                          placeholder='Ragione sociale'
                          value={values.pIva}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>Partita IVA</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.pIva && touched.pIva && errors.pIva}
                        </div>
                      </div>
                      <div className={cx([style.adaptiveField, style.rightField])}>
                        <input
                          name='cfCodiceFiscale'
                          className={
                            values.cfCodiceFiscale ? cx([style.input, style.val]) : style.input
                          }
                          type='text'
                          placeholder='Codice Fiscale'
                          value={values.cfCodiceFiscale}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>Codice Fiscale</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.cfCodiceFiscale &&
                            touched.cfCodiceFiscale &&
                            errors.cfCodiceFiscale}
                        </div>
                      </div>
                    </div>
                    <AddressAutocomplete
                      name='fullAddress'
                      onChange={data => setAddress(data, setFieldValue, setFieldError)}
                      onBlur={handleBlur}
                      errors={errors.fullAddress && touched.fullAddress && errors.fullAddress}
                    />
                    <div className={style.error} role='alert'>
                      {error}
                    </div>
                    <button className={style.button} disabled={isSubmitting} type='submit'>
                      {isSubmitting ? (
                        <Loader type='TailSpin' color='white' height={30} width={30} />
                      ) : (
                        'INVIA DATI ➔'
                      )}
                    </button>
                  </form>
                )}
              </Formik>
            </Col>
          ) : (
            <Col xs={12} md={6} lg={5} className='pr-md-5'>
              <p>Dati inviati correttamente, torna alla pagina di prenotazione.</p>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default BillingScreen
