import { FC, useContext } from 'react'
import { ControlsContext } from './controlsContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons'

export interface DiscussionButtonProps {
  classNameImage?: string
  onClick?: () => void
}

export const DiscussionButton: FC<DiscussionButtonProps> = ({ classNameImage, onClick }) => {
  const { state, dispatch } = useContext(ControlsContext)

  return (
    <div>
      <FontAwesomeIcon
        icon={faMessage}
        className={classNameImage}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          dispatch({
            ...state,
            communityVisible: true,
          })
          if (onClick) {
            onClick()
          }
        }}
      />
    </div>
  )
}
