import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  generateInfiniteQuiz,
  extendInfiniteQuiz,
  saveInfiniteAnswer,
} from '../../controllers/Quiz'
import Loader from 'react-loader-spinner'
import style from './styles/infiniteQuizScreen.module.css'
import { AttemptDetailQuestion, QuizItem, QuizQuestion } from '@learnn/sdk/src/api/quiz/'
import { useBodyClass } from '../../utils/hooks'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { matchQuery } from '../../utils/matchQuery'
import { IconButton } from '../../components/IconButton'
import closeIcon from '../../assets/images/icons/close.svg'
import { QuestionList } from '../../components/QuestionList'
import { InfiniteQuizState, useInfiniteQuizControllers } from './useInfiniteQuizControllers'
import { InfiniteQuizProgressBar } from './InfiniteQuizProgressBar'
import { AnswerResult } from './AnswerResult'
import { Goal } from './Goal'
import { ConfirmPopup } from '../../components/ConfirmPopup'
import useAnalytics from '../../analytics/useAnalytics'
import { InfiniteQuizSummary } from './InfiniteQuizSummary'
import cx from 'classnames'
import { InfiniteQuizStreakLose } from './InfiniteQuizStreakLose'

export const GOALS = [3, 6, 10, 15, 20, 25, 30, 35, 40]
export const DEFAULT_GOAL_INCREMENT = 10

export type QuestionStatusDone = {
  status: 'done'
  questionIndex: number
  answerResult: AttemptDetailQuestion
}
export type QuestionStatusLoading = {
  status: 'loading'
  questionIndex: number
}
export type QuestionStatusStarted = {
  status: 'started'
  questionIndex: number
}
export type QuestionStatusSummary = {
  status: 'summary'
  questionIndex: number
}
export type QuestionStatus =
  | QuestionStatusStarted
  | QuestionStatusLoading
  | QuestionStatusDone
  | QuestionStatusSummary

type InfiniteQuizType = {
  questionsFeed: QuizQuestion[]
  loadMoreQuestions: () => void
  quiz: QuizItem
}

export const InfiniteQuizScreen = () => {
  useBodyClass('app-quiz-dark')
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId
  const [questionsFeed, setQuestionsFeed] = useState<QuizQuestion[]>([])

  const quizQuery = useQuery<QuizItem, Error>(['infiniteQuiz', userId], generateInfiniteQuiz, {
    onSuccess: data => {
      setQuestionsFeed(data.questions)
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  return (
    <>
      {matchQuery(quizQuery, {
        success: data => {
          return (
            <InfiniteQuiz
              questionsFeed={questionsFeed}
              loadMoreQuestions={() =>
                extendInfiniteQuiz(data.attemptId).then(questions =>
                  setQuestionsFeed([...questionsFeed, ...questions]),
                )
              }
              quiz={data}
            />
          )
        },
        loading: () => (
          <div className={style.containerLoading}>
            <Loader
              type='ThreeDots'
              color='#ffffff'
              height={60}
              width={60}
              style={{ alignSelf: 'center' }}
            />
          </div>
        ),
        error: () => (
          <>
            <div className={style.container}></div>
            <div
              className={
                style.footer
              }>{`Copyright © ${new Date().getFullYear()} Learnn. Tutti i diritti riservati.`}</div>
          </>
        ),
      })}
    </>
  )
}

export const InfiniteQuiz = ({ questionsFeed, loadMoreQuestions, quiz }: InfiniteQuizType) => {
  const [skipConfirm, showSkipConfirm] = useState(false)
  const [closeConfirm, showCloseConfirm] = useState(false)
  const [error, setError] = useState('')
  const [goalAchieved, setGoalAchieved] = useState(false)
  const [streakLost, setStreakLost] = useState<InfiniteQuizState | false>(false)

  const {
    logInfiniteQuizAnswer,
    logInfiniteQuizSkip,
    logInfiniteQuizGoalAchieved,
    logInfiniteQuizStarted,
  } = useAnalytics()
  const [questionStatus, setStatus] = useState<QuestionStatus>({
    status: 'started',
    questionIndex: 0,
  })
  const currentQuestion = questionsFeed[questionStatus.questionIndex]

  const { addWrongAnswer, addCorrectAnswer, end, start, state } = useInfiniteQuizControllers({
    onGoalAchieved: () => {
      setGoalAchieved(true)
      logInfiniteQuizGoalAchieved(quiz.attemptId, state.currentAnswersStreak)
    },
    onStreakLose: finalStreak => {
      setStreakLost(finalStreak)
    },
    goals: GOALS,
    defaultGoalIncrement: DEFAULT_GOAL_INCREMENT,
  })

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)
  }, [questionStatus?.questionIndex])

  useEffect(() => {
    start()
    logInfiniteQuizStarted(quiz.attemptId)
  }, [])

  const goToNextQuestion = async () => {
    const nextQuestionIndex = questionStatus.questionIndex + 1

    if (nextQuestionIndex === questionsFeed.length - 3) {
      loadMoreQuestions()
    }

    if (nextQuestionIndex < questionsFeed.length) {
      setStatus({ status: 'started', questionIndex: nextQuestionIndex })
    } else {
      setError('Non ci sono più domande a disposizione')
    }
  }

  const skipQuestion = async () => {
    logInfiniteQuizSkip(quiz.attemptId, currentQuestion.questionId)
    addWrongAnswer()
    await goToNextQuestion()
  }

  const handleSubmit = async (questionId: string, answerId: string) => {
    try {
      setStatus({
        status: 'loading',
        questionIndex: questionStatus.questionIndex,
      })
      const response: AttemptDetailQuestion = await saveInfiniteAnswer(
        quiz.attemptId,
        questionId,
        answerId,
      )
      logInfiniteQuizAnswer(
        quiz.attemptId,
        questionId,
        answerId,
        response.answers.some(a => a.correctAnswer && a.userAnswer),
      )

      if (response.answers.some(a => a.correctAnswer && a.userAnswer)) addCorrectAnswer()
      else addWrongAnswer()
      setStatus({
        status: 'done',
        questionIndex: questionStatus.questionIndex,
        answerResult: response,
      })
    } catch (error) {
      console.log(error)
      setError("Si è verificato un durante l'invio della risposta, prova nuovamente.")
    }
  }

  const renderQuestionContainer = () => {
    switch (questionStatus.status) {
      case 'started':
        return (
          <div className={style.card}>
            <QuestionList
              theme='dark'
              question={currentQuestion}
              onAnswerSubmit={handleSubmit}
              onQuestionSkipped={() => showSkipConfirm(true)}
              error={error}
              containerClassName={style.questionList}
              titleContainerClassName={style.fullwidth}
              innerContainerClassName={style.fullwidth}
            />
          </div>
        )
      case 'loading':
        return (
          <div className={style.card}>
            <div className={style.containerLoading}>
              <Loader
                type='ThreeDots'
                color='#ffffff'
                height={60}
                width={60}
                style={{ alignSelf: 'center' }}
              />
            </div>
          </div>
        )
      case 'done':
        return (
          <div className={style.card}>
            <AnswerResult
              status={questionStatus}
              error={error}
              onNextQuestionClick={goToNextQuestion}
            />
          </div>
        )
      case 'summary':
        return (
          <div className={cx([style.card, 'mt-5'])}>
            <InfiniteQuizSummary summaryState={state} />
          </div>
        )
    }
  }

  return (
    <div className={style.container}>
      {questionStatus.status !== 'summary' && (
        <div className={style.header}>
          <div className={style.headerContainer}>
            <div className={style.headerLeft}>
              <IconButton
                iconClassName={style.closeIcon}
                onClick={() => showCloseConfirm(true)}
                icon={closeIcon}
              />
            </div>

            <div className={style.headerCenter}>
              <InfiniteQuizProgressBar quizState={state} />
            </div>
          </div>
        </div>
      )}

      <div className={style.body}>{renderQuestionContainer()}</div>

      {streakLost && (
        <InfiniteQuizStreakLose
          finalState={streakLost}
          onCloseClick={() => {
            setStreakLost(false)
          }}
        />
      )}
      {skipConfirm && (
        <ConfirmPopup
          text={'Saltando la domanda perderai la serie! Sei sicuro di voler continuare?'}
          onCancelClick={() => showSkipConfirm(false)}
          onConfirmClick={async () => {
            showSkipConfirm(false)
            await skipQuestion()
          }}
        />
      )}
      {closeConfirm && (
        <ConfirmPopup
          text={'Chiudendo il quiz perderai la serie! Sei sicuro di voler continuare?'}
          onCancelClick={() => showCloseConfirm(false)}
          onConfirmClick={async () => {
            showCloseConfirm(false)
            end()
            setStatus({
              status: 'summary',
              questionIndex: questionStatus.questionIndex,
            })
          }}
        />
      )}

      {goalAchieved && (
        <Goal
          onSubmitClick={() => setGoalAchieved(false)}
          correctAnswersToReach={state.correctAnswersToReach}
          currentAnswersStreak={state.currentAnswersStreak}
        />
      )}

      <div
        className={
          style.footer
        }>{`Copyright © ${new Date().getFullYear()} Learnn. Tutti i diritti riservati.`}</div>
    </div>
  )
}
