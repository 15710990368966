import ReactMarkdown from "react-markdown";
import infoIcon from "../../../assets/images/icons/info.svg";
import { TriggerPopup } from "../../TriggerPopup";
import { Link } from "react-router-dom";
import style from "./styles/description.module.css";
import useLinkNavigation from "../../../utils/useLinkNavigation";
import {OpenSideType} from "../../TriggerPopup/TriggerPopup";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface IPlayerDescriptionProps {
  description: string;
  visible: boolean;
  onClick(): void;
  buttonClassName?: string;
  buttonClassNameImage?: string;
  openSide?: OpenSideType;
  icon?: string;
}

// const variants = {
//   hidden: {
//     opacity: 0,
//     display: "none"
//   },
//   visible: {
//     opacity: 1,
//     display: "block"
//   }
// }

export const Description = ({ description, visible = false, onClick, buttonClassName, buttonClassNameImage, openSide = 'top-left', icon = infoIcon }: IPlayerDescriptionProps) => {
  const linkNavigation = useLinkNavigation();
  return (
    <TriggerPopup
      image={icon}
      visible={visible}
      onClick={onClick}
      buttonClassName={buttonClassName}
      buttonClassNameImage={buttonClassNameImage}
      openSide={openSide}>
      <div>
        <h4 className={style.descriptionTitle}
        >Descrizione
          <FontAwesomeIcon
            onClick={onClick}
            icon={faTimes}
            className={cx([style.closeIcon])}
          />
        </h4>
        <div className={style.container}>
          <ReactMarkdown
            renderers={{
              link: props => {
                return props.node.url.startsWith("https://") | props.node.url.startsWith("http://") ? (
                  <a className={style.link} href={props.node.url} target="_blank">
                    {props.children}
                  </a>
                ) : (
                  <Link className={style.link} to={linkNavigation.parseUrl(props.node.url)}>
                    {props.children}
                  </Link>
                );
              },
            }}>
            {description}
          </ReactMarkdown>
        </div>
      </div>
    </TriggerPopup>
  );
};
