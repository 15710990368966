import { FeaturesConfig } from '@learnn/sdk/src/api/feature-flag'
import { createContext } from 'react'
import { setupFeatureFlag } from './setupFeatureFlag'

export type FeatureFlagState = {
  features: FeaturesConfig
}

export const featureFlagInitialState: FeatureFlagState = {
  features: {}
}

export const FeatureFlagContext = createContext<FeatureFlagState>(featureFlagInitialState)

export type FeatureFlagProvider = {
  children: React.ReactChild | React.ReactChild[]
}
export const FeatureFlagProvider = ({ children }: FeatureFlagProvider) => {
  const { features } = setupFeatureFlag()

  return (
    <FeatureFlagContext.Provider
      value={{features}}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}