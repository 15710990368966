import { useState, useContext, useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import ReactMarkdown from 'react-markdown'
import { useHistory } from 'react-router-dom'
import { useBodyClass } from '../../utils/hooks'
import { durationToHuman } from '../../utils/time'
// import useLinkNavigation from '../../utils/useLinkNavigation'
import { Back } from '../../components/Back/Back'
import { TriggerButton } from '../../components/LessonPlayer/controls/TriggerButton'
import { MyLearnnButton } from '../../components/MyLearnnButton'
import { NotePopup } from '../../components/NotePopup'
import skipNext from '../../assets/images/icons/skip-next.svg'
import { GlobalProviderContext } from '../GlobalProvider'
import { extractConfiguration } from '../../utils/data'
import style from './textLessonPlayer.module.css'
import { LessonBrowser } from '../../components/LessonsBrowser'
import { CourseSummary } from '@learnn/sdk/src/api/player'
import { syncProgress } from '../../controllers/Player'
import useAnalytics from '../../analytics/useAnalytics'
import { IVideoProps } from '../LessonPlayer/useReactPlayer'
import { Markdown } from '@learnn/designn'
import env from '../../env.json'

type TextLessonPlayerProps = {
  courseSummary: CourseSummary
  lesson: any
  initialProps: IVideoProps
}

export const TextLessonPlayer: React.FC<TextLessonPlayerProps> = ({
  courseSummary,
  lesson,
  initialProps,
}) => {
  useBodyClass('app-light')

  const history = useHistory()
  // const linkNavigation = useLinkNavigation()

  const [noteVisible, setNoteVisible] = useState(false)
  const [browserVisible, setBrowserVisible] = useState(false)
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)

  const { logLessonProgress, logModuleCompleted, logLessonCompleted, logCourseCompleted } =
    useAnalytics()

  function toggleNote() {
    setBrowserVisible(false)
    setNoteVisible(!noteVisible)
  }

  function toggleBrowser() {
    setNoteVisible(false)
    setBrowserVisible(!browserVisible)
  }

  function goToNextLesson(nextLessonId: string | null) {
    if (nextLessonId)
      history.push(`/player/${nextLessonId}?play=true`, { startingRate: initialProps.playbackRate })
  }

  const sumProgress = (currentProgress: number, totalItems: number) => {
    const completedItems = (currentProgress * totalItems) / 100
    const newProgress = ((completedItems + 1) * 100) / totalItems

    return newProgress
  }

  const logCompletePlayingEvents = (summary: CourseSummary) => {
    if (summary) {
      if (
        !summary.course_completed &&
        sumProgress(summary.course_progress, summary.total_course_lessons) >=
          env.COURSE_COMPLETED_THRESHOLD
      ) {
        logCourseCompleted(summary.course_id, {
          ...summary,
          course_progress: sumProgress(summary.course_progress, summary.total_course_lessons),
        })
        summary.course_completed = true
      }
      if (
        !summary.module_completed &&
        sumProgress(summary.module_progress, summary.total_lessons) >=
          env.MODULE_COMPLETED_THRESHOLD
      ) {
        logModuleCompleted(summary.course_id, summary.module_id, {
          ...summary,
          module_progress: sumProgress(summary.module_progress, summary.total_lessons),
        })
        summary.module_completed = true
      }
      if (!summary.lesson_completed) {
        logLessonCompleted(summary.course_id, summary.module_id, summary.lesson_id, summary)
        summary.lesson_completed = true
      }
    }
  }

  useEffect(() => {
    if (!lesson) {
      return
    }
    syncProgress({
      courseId: courseSummary.course_id,
      lessonId: lesson.id,
      currentViewTime: lesson.duration || 100,
    })

    const summary: CourseSummary = courseSummary

    if (summary) {
      logLessonProgress(summary.course_id, summary.module_id, summary.lesson_id, 100, summary)
      logCompletePlayingEvents(summary)
    }
  }, [lesson.id])

  return (
    <div className={style.container}>
      <Back
        className={style.back}
        backTo='/'
        onClick={() => {
          if (history.action !== 'POP') history.goBack()
          else history.push(`/corso/${courseSummary.course_id}`)
        }}
      />
      <h1 className={style.title}>{lesson.title}</h1>
      <p className={style.duration}>{durationToHuman(lesson.duration)} di lettura</p>
      <div>
        <div className={style.innerContainer}>
          <div className={style.controls}>
            <div className={style.innerControls}>
              <LessonBrowser
                currentLessonId={lesson.id}
                moduleId={courseSummary.module_id}
                courseId={courseSummary.course_id}
                onClick={toggleBrowser}
                visible={browserVisible}
                buttonClassNameImage={style.lessonBrowserButtonImage}
                buttonClassName={style.triggerButton}
                popupContainerClass={style.textPopup}
                openSide='bottom-left'
              />
              <NotePopup
                courseId={courseSummary.course_id}
                lessonId={lesson.id}
                moduleId={courseSummary.module_id}
                onClick={toggleNote}
                viewTime={0}
                visible={noteVisible}
                openSide='bottom-left'
                buttonClassNameImage={style.notePopupButtonImage}
                buttonClassName={style.triggerButton}
                popupContainerClass={style.notePopup}
              />
              {configuration?.features?.mylearnn !== false ? (
                <MyLearnnButton
                  itemType='lesson'
                  itemId={lesson.id}
                  myLearnnId={lesson.myLearnnId}
                  className={style.myLearnnButton}
                />
              ) : null}
              {lesson.nextLessonId ? (
                <TriggerButton
                  className={style.triggerButton}
                  image={skipNext}
                  //@ts-ignore //FIX NEXT LESSON
                  onClick={() => goToNextLesson(lesson.nextLessonId)}
                  width={28}
                  height={28}
                />
              ) : null}
            </div>
          </div>
          <Markdown size='lg'>{lesson.description}</Markdown>
        </div>
      </div>
    </div>
  )
}
