import { useContext, useEffect } from 'react'
import { PlayerSideContext } from './playerSideContext'

export const usePlayerSide = (defaultTab: number) => {
  const { dispatch, state: prevState } = useContext(PlayerSideContext)

  useEffect(() => {
    dispatch({...prevState, selectedTab: defaultTab })
  }, [])

  return {
    show: () => {
      dispatch({
        ...prevState,
        status: 'visible',
      })
    },
    hide: () => {
      dispatch({
        ...prevState,
        status: 'hide',
      })
    },
    toggle: () => {
      dispatch({
        ...prevState,
        status: prevState.status === 'visible' ? 'hide' : 'visible',
        ...(prevState.status === 'visible' && { selectedTab: defaultTab }),
      })
    },
    setFocus: (focus: boolean) => {
      dispatch({
        ...prevState,
        playerSideFocus: focus,
      })
    },
    setSelectedTab: (index: number) => {
      dispatch({
        ...prevState,
        selectedTab: index,
      })
    },
    state: prevState,
  }
}
