import style from './availableCredits.module.css'
import cx from 'classnames'
import { useCheckout } from '../../CheckoutContext'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export const AvailableCredits = () => {
  const { availableCredits } = useCheckout()
  
  return (
    availableCredits > 0 ? (
      <div>
        <p className={cx([style.header, 'mb-1'])}>CREDITI DISPONIBILI</p>
        <p>{`Hai accumulato un totale di `}<strong>{`${Number.isInteger(availableCredits / 100) ? `${availableCredits / 100}.00` : (availableCredits / 100).toFixed(2)} €`}</strong></p>
      </div>
    ) : <></>
  )
}

export const AvailableCreditsSkeleton = () => {
  return (
    <SkeletonTheme baseColor='#a3a3a3' highlightColor='#cacaca'>
      <Skeleton style={{ width: '100%', height: '20px' }}></Skeleton>
      <div style={{ marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Skeleton style={{ width: '200px', height: '20px' }}></Skeleton>
        <Skeleton style={{ width: '50px', height: '20px' }}></Skeleton>
      </div>
    </SkeletonTheme>
  )
}