import {
  ComponentSectionDynamicMostUseful,
  ComponentSectionDynamicMostViewed,
  ComponentSectionDynamicTopPicksForYou,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/dist/api/dynamic-sections/types'
import * as F from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { CourseCard } from '../CourseCard'
import useAnalytics from '../../analytics/useAnalytics'
import { useCoursePopup } from '../CoursePopup/useCoursePopup'
import { CourseBaseInfoEngagement, CourseBaseInfo } from '@learnn/sdk/dist/api/course'
import { ComponentSectionDynamicTrendingNow } from '@learnn/sdk/src/api/dynamic-sections/types'
import { getAuthorsNames } from '../../utils/data'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'

type Sections =
  | ComponentSectionDynamicMostViewed
  | ComponentSectionDynamicMostUseful
  | ComponentSectionDynamicTopPicksForYou
  | ComponentSectionDynamicTrendingNow
export interface IContentCarouselProps {
  data: DynamicSectionsValue<DynamicSectionsError, Sections>
}

export const ChartCarousel = ({ data }: IContentCarouselProps) => {
  const { logSelectSliderCourse } = useAnalytics()
  const { show } = useCoursePopup()

  const handleCourseClick = (
    course: CourseBaseInfo & Partial<CourseBaseInfoEngagement>,
    section: Sections,
  ) => {
    logSelectSliderCourse(course.id, {
      course_title: course.title,
      course_type: course.type.slug,
      course_duration: course.duration,
      course_author: getAuthorsNames(course.authors),
      slider_title: section.title,
    })

    show(course)
  }

  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const render = (section: Sections) => {
    return (
      <CustomCarousel
        title={section.title}
        data={section.data}
        carouselKind='course'
        renderSlide={content => {
          return (
            <CourseCard
              course={content}
              onClick={() => handleCourseClick(content, section)}
              myLearnn
              myLearnnId={content.myLearnnId}
              size='md'
            />
          )
        }}
      />
    )
  }

  return F.pipe(
    data,
    O.fold(CustomCarouselLoading, data => {
      return F.pipe(data, E.fold(error, render))
    }),
  )
}
