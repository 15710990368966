import { createContextReducer } from '../../utils/createContext'

export type PopupType = {
  show: boolean
}

export const popupInitialState: PopupType = {
  show: false,
}

export type IPopupActions = PopupType & {}

function reducer(state: PopupType, newState: IPopupActions) {
  return { ...state, ...newState }
}

const [ctx, Provider] = createContextReducer<PopupType, IPopupActions>(reducer, popupInitialState)
export const PreferencesPopupContext = ctx
export const PreferencesPopupContextProvider = Provider
