import styles from './styles/pathItem.module.css'
import compactStyle from './styles/compactPathItem.module.css'

import { ResponsiveImage } from '../../../components/ResponsiveImage'
import { FC } from 'react'
import cx from 'classnames'
import { CourseWithProgress } from '../../../controllers/Paths'
import { CircularProgress } from '../../../components/CircularProgress'
import { ReactComponent as StartLogo } from '../../../assets/images/icons/play_circle_white.svg'
import { ReactComponent as CompletedLogo } from '../../../assets/images/icons/passed_quiz.svg'
import { ReactComponent as VerifiedLogo } from '../../../assets/images/icons/verified.svg'
import { ReactComponent as ToVerifyLogo } from '../../../assets/images/icons/verified_outline.svg'

import { faPlay, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Course } from '@learnn/sdk/src/api/paths/types'
import { Button, Title, useTheme } from '@learnn/designn'

export type PathItemProps = {
  containerStyle?: string
  item: Course & Partial<CourseWithProgress>
  onClick: () => unknown
  isCompact?: boolean
}
export const PathItem: FC<PathItemProps> = ({ item, containerStyle, isCompact = false, onClick }) => {
  const { type, image, title, author } = item
  const { spacing } = useTheme()

  const details = [
    type.title,
    item.durationString? item.durationString : null,
    item.type.slug === 'course' ? <div className={compactStyle.verifiedStringContainer}>
      <VerifiedLogo className={compactStyle.statusIconSmall} />
       <div className={compactStyle.verifiedString}>    
          Verifica
        </div>
    </div> : null
  ].filter(Boolean)


  const renderStatus = () => {
    switch (item.status) {
      case 'to_start':
        return <StartLogo className={styles.statusIcon} />
      case 'to_finish':
        return (
          <CircularProgress
            progress={Math.round(item.progress ?? 0)}
            size={48}
            labelSize='12px'
            trackWidth={2}
            indicatorWidth={3.5}
          />
        )
      case 'completed':
        return <CompletedLogo className={styles.statusIcon} />
      case 'to_verify':
        return <ToVerifyLogo className={styles.statusIcon} />
      case 'verified':
        return <VerifiedLogo className={styles.statusIcon} />
      default:
        return (
          <div className={styles.circularLoader}>
            <span></span>
          </div>
        )
    }
  }

  const renderCallToAction = () => {
    switch (item.status) {
      default:
      case 'to_start':
        return (
          <Button
            icon={<FontAwesomeIcon icon={faPlay} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Inizia'
          />
        )
      case 'to_finish':
        return (
          <Button
            icon={<FontAwesomeIcon icon={faPlay} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Continua'
          />
        )
      case 'completed':
        return (
          <Button
            icon={<FontAwesomeIcon icon={faRepeat} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Riguarda'
          />
        )
      case 'to_verify':
        return (
          <Button
            icon={<ToVerifyLogo className={styles.ctaIcon} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Verifica'
          />
        )
      case 'verified':
        return (
          <Button
            icon={<ToVerifyLogo className={styles.ctaIcon} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Vedi verifica'
          />
        )
    }
  }

  const renderStandardPathCard = () => {

    return (
      <div className={styles.container}>
        {item.sectionTitle && (<Title mb={spacing.space_3} variant='headingLg'>{item.sectionTitle}</Title>)}

        <div
          className={cx([styles.cardContainer, item.verified ? styles.verified : ''])}
          onClick={onClick}>
          { /*<div className={styles.status}>{renderStatus()}</div> */}
          <div className={styles.imageContainer}>
            <ResponsiveImage wrapperClassName={styles.image} src={image} />
          </div>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>{title}</div>
              <div className={styles.author}>{author}</div>
            </div>
            <div className={styles.details}>
              {details.map((detail, i) => (
                <div className={styles.detailItem} key={i}>
                  {detail}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.overlay}>{renderCallToAction()}</div>
        </div>
      </div>
    )
  }

  const renderCompactPathCard = () => {
    return (
      <div className={cx([compactStyle.container, containerStyle])}>
          {item.sectionTitle && (<Title mb={spacing.space_3} variant='headingLg'>{item.sectionTitle}</Title>)}
          <div
            className={cx([compactStyle.cardContainer, item.verified ? compactStyle.verified : ''])}
            onClick={onClick}>
            { /*<div className={compactStyle.status}>{renderStatus()}</div> */}
            <div className={compactStyle.imageContainer}>
              <ResponsiveImage wrapperClassName={compactStyle.image} src={image} />
            </div>
            <div className={compactStyle.content}>
              <div className={compactStyle.header}>
                <div className={compactStyle.title}>{title}</div>
                <div className={compactStyle.author}>{author}</div>
              </div>
              <div className={compactStyle.details}>
                {details.map((detail, i) => (
                  <div className={compactStyle.detailItem} key={i}>
                    {detail}
                  </div>
                ))}
              </div>
            </div>
            <div className={compactStyle.overlay}>
              <Button
                icon={<FontAwesomeIcon icon={faPlay} />}
                variant='primary'
                size='md'
                iconPosition='right'
                label='Vai al corso'
              />
            </div>
          </div>
        </div>
    )
  }
  return isCompact ? renderCompactPathCard() : renderStandardPathCard()
}
