import { useBodyClass } from '../../../../utils/hooks'
import { Title, useTheme, Container, Markdown } from '@learnn/designn'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import useLinkNavigation from '../../../../utils/useLinkNavigation'
import { Link } from 'react-router-dom'

export type ResourcesSectionProps = {
  course: CourseBaseInfoEngagement
}

export const ResourcesSection = ({ course }: ResourcesSectionProps) => {
  useBodyClass('app')
  const { spacing } = useTheme()
  const linkNavigation = useLinkNavigation()

  return (
    <Container>
      <Title variant='heading2xl' mb={spacing.space_6}>
        Risorse
      </Title>
      {course.resources && (
        <Markdown
          overrides={{
            reactMarkdownProps: {
              renderers:{
                link: props => {
                  return props.node.url.startsWith('https://') |
                    props.node.url.startsWith('http://') ? (
                    <a href={props.node.url} target='_blank'>
                      {props.children}
                    </a>
                  ) : (
                    <Link to={linkNavigation.parseUrl(props.node.url)}>
                      {props.children}
                    </Link>
                  )
                },
              }
            }
          }}
        >
          {course.resources}
        </Markdown>
      )}
    </Container>
  )
}
