import { FC, useContext, useEffect } from 'react'
import { useShare } from '../../../../components/SharePopup/useShare'
import env from '../../../../env.json'
import style from './overview.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { MyLearnnButton } from '../../../../components/MyLearnnButton'
import { Button as OldButton } from '../../../../components/Button'
import { ReactComponent as ShareIcon } from '../../../../assets/images/icons/share_black.svg'
import { ReactComponent as VerifyIcon } from '../../../../assets/images/icons/verified.svg'
import { ReactComponent as VerifyOutlineIcon } from '../../../../assets/images/icons/verified_outline.svg'
import { ReactComponent as DownloadIcon } from '../../../../assets/images/icons/download.svg'
import { ReactComponent as SquaresLessonsIcon } from '../../../../assets/images/icons/squares-lessons.svg'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import {
  HorizontalStack,
  Badge,
  Container,
  Title,
  FeatureCard,
  LearningListItem,
  AuthorCard,
  Grid,
  useTheme,
  Box,
  Markdown,
} from '@learnn/designn'
import { ResponsiveImage } from '../../../../components/ResponsiveImage'
import { SimplePlayer } from '../../../../components/SimplePlayer/SimplePlayer'
import { youLearnToArray } from '../../../../utils/course'
import { MainCta } from '../MainCta'
import { useQuery } from 'react-query'
import { getCourseVerifiedStatus } from '../../../../controllers/Quiz'
import { GlobalProviderContext } from '../../../../components/GlobalProvider'
import { matchQuery } from '../../../../utils/matchQuery'

export type OverviewProps = {
  course: CourseBaseInfoEngagement
  onVerifyCourseClick: Function
  onShowVerifiedClick: Function
  verifiedEnabled: boolean
  onLoad: (course: CourseBaseInfoEngagement) => unknown
}
export const Overview: FC<OverviewProps> = ({
  course,
  onLoad,
  onVerifyCourseClick,
  onShowVerifiedClick,
  verifiedEnabled,
}) => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId ?? ''
  const { share } = useShare()
  const { spacing } = useTheme()
  const youLearnArray = course.youLearn ? youLearnToArray(course.youLearn) : null
  const verifiedAvailabilityQuery = useQuery(
    ['verifiedAvailability', course.id],
    () => getCourseVerifiedStatus(userId, course.id),
    { retry: false },
  )

  useEffect(() => {
    if (!course) return
    onLoad(course)
  }, [course?.id])

  const renderVerifiedCard = () => {
    switch (verifiedAvailabilityQuery.status) {
      case 'success': {
        switch (verifiedAvailabilityQuery.data.status) {
          case 'passed':
            return (
              <FeatureCard
                title='Verifica'
                subtitle='ottenuta'
                icon={<VerifyIcon className={style.icon} style={{ fill: 'white' }} />}
              />
            )
          default:
            return (
              <FeatureCard
                title='Verifica'
                subtitle='disponibile'
                icon={<VerifyOutlineIcon className={style.icon} style={{ fill: 'white' }} />}
              />
            )
        }
      }
      case 'loading':
        return (
          <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
            <div style={{ width: '180px', height: '75px' }}>
              <Skeleton />
            </div>
          </SkeletonTheme>
        )
      case 'idle':
        return <></>
      case 'error':
        return <></>
    }
  }

  const isCourse = course.type.slug === 'course'

  return (
    <Container>
      <Title variant='heading3xl' mb={spacing.space_6}>
        {course.title}
      </Title>
      <div className={style.imageContainer}>
        {course.highlightVideo ? (
          <SimplePlayer url={course.highlightVideo} />
        ) : course.trailerVideoUrl ? (
          <SimplePlayer url={course.trailerVideoUrl} />
        ) : (
          <ResponsiveImage wrapperClassName={style.borderedImage} src={course.coverImage} />
        )}
      </div>

      <HorizontalStack
        mt={spacing.space_8}
        mb={spacing.space_20}
        justifyContent={{
          default: 'center',
          small: 'flex-start',
        }}>
        {isCourse && verifiedEnabled ? (
          matchQuery(verifiedAvailabilityQuery, {
            loading: () => <></>,
            error: () => <></>,
            success: verifiedState => (
              <MainCta
                size='md'
                course={course}
                verify={{
                  verifiedState,
                  onShowVerifiedClick,
                  onVerifyCourseClick,
                }}
              />
            ),
          })
        ) : (
          <MainCta size='md' course={course} />
        )}
        <HorizontalStack ml={spacing.space_3}>
          <OldButton
            className={style.circularButton}
            onClick={() =>
              share({
                title: 'Regala 7 giorni PRO gratis e ottieni crediti',
                type: course.type.slug,
                url: `${env.SITE_URL}/corso/${course.id}`,
              })
            }>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <ShareIcon className={style.shareIcon} />
            </div>
          </OldButton>
          <MyLearnnButton
            itemType='course'
            itemId={course.id}
            myLearnnId={course.myLearnnId}
            containerClass={style.myLearnnButton}
            className={style.circularButtonIcon}
          />
        </HorizontalStack>
      </HorizontalStack>
      <Title variant='headingXl' mb={spacing.space_6}>
        Questo {course.type.title.toLowerCase()} include
      </Title>
      <Grid
        gridTemplateColumns={{
          default: 'repeat(2, 1fr)',
          small: 'repeat(2, 1fr)',
          medium: `repeat(${isCourse ? '4' : '3'}, 1fr)`,
        }}
        gridGap={spacing.space_6}
        width='100%'
        mb={spacing.space_20}>
        <FeatureCard
          title={course.duration}
          subtitle='durata corso'
          icon={<FontAwesomeIcon icon={faClock} className={style.icon} color='white' />}
        />
        {course.lessonsCount && (
          <FeatureCard
            title={`${course.lessonsCount.toString()} Lezioni`}
            subtitle='da 5-10 minuti'
            icon={<SquaresLessonsIcon className={style.icon} />}
          />
        )}
        {course.resources && (
          <FeatureCard
            title='Slide e PDF'
            subtitle='scaricabili'
            icon={<DownloadIcon className={style.icon} />}
          />
        )}
        {isCourse && renderVerifiedCard()}
      </Grid>
      <Title variant='headingXl' mb={spacing.space_6}>
        Da chi imparerai
      </Title>
      {course.authors.map((author, index) => (
        <AuthorCard
          key={`${index}${author.authorName}`}
          authorName={author.authorName}
          authorProfession={author.profession}
          authorImageSrc={author.avatar ?? course.coverImage}
          mb={spacing.space_6}
        />
      ))}
      {course.categories && course.categories.length > 0 ? (
        <>
          <Title variant='headingXl' mb={spacing.space_6} mt={spacing.space_16}>
            Categorie
          </Title>
          <HorizontalStack
            width='100%'
            mb={spacing.space_16}
            flexWrap='wrap'
            style={{ fontSize: '2rem !important' }}>
            {course.categories.map(({ title }) => (
              <Badge
                key={title}
                variant='contained'
                body={title}
                squareBorder
                mr={spacing.space_4}
                mb={spacing.space_4}
              />
            ))}
          </HorizontalStack>
        </>
      ) : (
        <></>
      )}

      {course.youLearn && (
        <>
          <Title variant='headingXl' mb={spacing.space_6}>
            Cosa imparerai
          </Title>
          <Box mb={spacing.space_20}>
            {youLearnArray ? (
              <Grid
                gridTemplateColumns={{
                  default: 'repeat(2, 1fr)',
                  small: 'repeat(2, 1fr)',
                  medium: 'repeat(3, 1fr)',
                }}
                gridGap={spacing.space_6}
                width='100%'>
                {youLearnArray.map((value, index) => (
                  <LearningListItem
                    key={value}
                    index={(index + 1).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                    title={value}
                  />
                ))}
              </Grid>
            ) : (
              <Markdown>{course.youLearn}</Markdown>
            )}
          </Box>
        </>
      )}
      {course.description ? (
        <>
          <Title variant='headingXl' mb={spacing.space_6}>
            Descrizione
          </Title>
          <Markdown>{course.description}</Markdown>
        </>
      ) : (
        <></>
      )}
    </Container>
  )
}
