import { Addon, CustomPrice, DiscountDetails, TrialPlan, ValidatedPlan } from '../../controllers/Payment'
import { useState } from 'react'
import style from './PlanDetails.module.css'
import cx from 'classnames'

import { calculateTotalPrice, displayTrial, printTotalPrice } from '../../utils/checkout'


type IPlanDetailsProps =
  | {
    type: 'standard'
    coupon: string
    couponInvoiceName: string
    setCoupon(id: string): void
    setTrialPlan(trial: TrialPlan): void
    setCouponPrice(price: number): void
    setDiscountDetails(details?: DiscountDetails): void
    onCouponSubmit(): Promise<ValidatedPlan>
    afterCouponApplied?(price: number): void
    plan?: string
    planInfo: ValidatedPlan | undefined
    planPrice?: number
    couponPrice?: number
    trial?: TrialPlan
    creditNotes?: number
    addon: Addon
    planQuantity?: number
    discountDetails?: DiscountDetails
    customPrice?: CustomPrice
    isVatExempt: boolean
  } | {
    type: 'gift'
    coupon: string
    couponInvoiceName: string
    setCoupon(id: string): void
    setCouponPrice(price: number): void
    setDiscountDetails(details?: DiscountDetails): void
    onCouponSubmit(): Promise<ValidatedPlan>
    afterCouponApplied?(price: number): void
    plan: string
    planInfo: ValidatedPlan | undefined
    planPrice?: number
    couponPrice?: number
    creditNotes?: number
    discountDetails?: DiscountDetails
    customPrice?: CustomPrice
    isVatExempt: boolean
  }

function PlanDetails(props: IPlanDetailsProps) {
  const [couponVisible, setCouponVisible] = useState(false)
  const [loadingCoupon, setLoadingCoupon] = useState(false)
  const [couponError, setCouponError] = useState('')

  const handleCoupon = async () => {
    setLoadingCoupon(true)
    try {
      const retrievedCoupon = await props.onCouponSubmit()
      props.setCouponPrice(retrievedCoupon.price)
      props.setDiscountDetails(retrievedCoupon.discount)
      if (props.type === 'standard' && retrievedCoupon.trial)
        props.setTrialPlan(retrievedCoupon.trial)
      setLoadingCoupon(false)
      if (props.afterCouponApplied) {
        props.afterCouponApplied(retrievedCoupon.price)
      }
    } catch (error) {
      console.log(error)
      props.setCoupon('')
      setLoadingCoupon(false)
      setCouponError('Coupon non valido.')
    }
  }

  return (
    <div>
      <div>
        <>
          {props.type === 'gift' ? (
            <div style={{ width: '100%' }}>
              <div className={style.planName}>
                <span>{props.planInfo?.invoice_name ?? 'Abbonamento Regalo'}</span>
                {
                  props.customPrice?.displayPrice ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ textDecoration: 'line-through' }}>{props.customPrice.displayPrice.toFixed(2)}</span>
                    </div>
                  ) : (
                    <span style={{ textDecoration: props.discountDetails ? 'line-through' : null }}>{props.planPrice?.toFixed(2)}</span>
                  )
                }
              </div>
              {/* 
              //TODO handle VAT
              <div className={style.vatContainer}>
                <span>IVA (22%)</span>
                <span>
                  {`${getAppliedVat(
                    calculateTotalPrice(props.price, 0, 0, props.creditNotes),
                    22,
                  )}€`}
                </span>
              </div> */}
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              {
                props.planInfo && (
                  <div className={style.planName}>
                    {(props.trial) ? (
                      <span>{`Accesso Gratuito per ${displayTrial(props.trial?.period, props.trial?.unit)}`}</span>
                    ) : props.plan?.includes('b2b') || props.plan?.includes('business') ? (
                      <span>Piano Business {props.planQuantity && `x${props.planQuantity}`}</span>
                    ) : (
                      props.planInfo?.invoice_name
                    )}
                    {(props.planPrice || props.planPrice === 0) ? (
                      <div
                        className={style.planPrice}>
                        {
                          props.customPrice?.displayPrice ? (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ textDecoration: !props.addon ? 'line-through' : null }}>{props.customPrice.displayPrice.toFixed(2) + ' €'}</span>
                            </div>
                          ) : (
                            <span style={{ textDecoration: (props.discountDetails && !props.addon) ? 'line-through' : null }}>{props.planPrice.toFixed(2) + ' €'}</span>
                          )
                        }
                      </div>
                    ) : null}
                  </div>
                )
              }
              {/* 
              //TODO handle VAT
              <div className={style.vatContainer}>
                <span>IVA (22%)</span>
                <span>
                  {`${getAppliedVat(
                    calculateTotalPrice(
                      props.price,
                      props.addon?.price,
                      props.addon?.quantity,
                      props.creditNotes,
                    ),
                    22,
                  )}€`}
                </span>
              </div> */}
            </div>
          )}
        </>
      </div>
      {
        props.customPrice?.customMessage && !props.discountDetails ? (
          <div className={style.vatContainer}>
            <span>{props.customPrice.customMessage}</span>
            {
              props.customPrice?.displayPrice && props.planPrice ? (
                <span className={style.couponPrice}>{(props.planPrice - props.customPrice?.displayPrice).toFixed(2) + ' €'}</span>
              ) : null
            }
          </div>
        ) : null
      }
      {
        props.type !== 'gift' && (
          <>
            {props.addon ? (
              <div className={style.addonName}>
                <span>{`${props.addon?.name} x${props.addon?.quantity}`}</span>
                <div className={style.planPrice}>
                  {(props.addon?.price * props.addon?.quantity).toFixed(2) + ' €'}
                </div>
              </div>
            ) : null}
          </>
        )
      }
      <div className={style.descriptionContainer}>
        {!props.discountDetails ? (
          <>
            {couponVisible ? (
              <div style={{ width: '100%' }}>
                <div className={style.couponContainer}>
                  <input
                    className={cx(['form-control', style.couponInput])}
                    type='text'
                    name='coupon'
                    placeholder='Coupon'
                    onChange={c => props.setCoupon(c.target.value)}
                    value={props.coupon}
                  />
                  <button
                    type='button'
                    className={
                      loadingCoupon
                        ? cx([style.couponButton, style.submitting])
                        : style.couponButton
                    }
                    disabled={!props.coupon}
                    onClick={handleCoupon}>
                    Applica
                  </button>
                </div>
                <div className='error-text mt-1'>{couponError}</div>
              </div>
            ) : (
              <span
                className={cx([style.couponText, style.hoverUnderline])}
                onClick={() => setCouponVisible(true)}>
                Hai un coupon?
              </span>
            )}
          </>
        ) : (
          <div className={style.couponContainer}>
            <div className={style.couponDetails}>
              <div className={style.appliedCouponText}>Coupon applicato</div>
              {props.discountDetails && (
                <div className={style.couponPercentageText}>
                  {props.planInfo?.couponInvoiceName ?? `Risparmi il ${parseFloat(String(props.discountDetails?.discountPercentage))}%`} 
                </div>
              )}
            </div>
            <div className={style.couponPrice}>{`-${props.discountDetails?.discountAmount}€`}</div>
          </div>
        )}
      </div>
      {props.type === 'gift' ? (
        props.creditNotes && props.creditNotes > 0 ? (
          <div className={style.vatContainer}>
            <span>Crediti Referral</span>{' '}
            <span className={style.couponPrice}>{`- ${props.creditNotes}€`}</span>
          </div>
        ) : (
          <></>
        )
      ) : props.creditNotes && props.creditNotes > 0 && !props.trial ? (
        <div className={style.vatContainer}>
          <span>Crediti Referral</span>{' '}
          <span className={style.couponPrice}>{`- ${props.creditNotes}€`}</span>
        </div>
      ) : (
        <></>
      )}

      {
        props.isVatExempt && (
          <div className={style.vatContainer}>
            <span>Esenzione IVA</span>
            <span>
              {`- ${props.type === 'gift' ?
                (
                  calculateTotalPrice(
                    props.planPrice, props.couponPrice, 0, 0, props.creditNotes, false, false
                  ) -
                  calculateTotalPrice(
                    props.planPrice, props.couponPrice, 0, 0, props.creditNotes, false, props.isVatExempt
                  )).toFixed(2)
                :
                (
                  calculateTotalPrice(
                    props.planPrice,
                    props.couponPrice,
                    props.addon?.price,
                    props.addon?.quantity,
                    props.creditNotes,
                    !!props.trial,
                    false
                  ) -
                  calculateTotalPrice(
                    props.planPrice,
                    props.couponPrice,
                    props.addon?.price,
                    props.addon?.quantity,
                    props.creditNotes,
                    !!props.trial,
                    props.isVatExempt
                  )).toFixed(2)
                }
              €`}
            </span>
          </div>
        )
      }
      <div className={style.descriptionContainer}>
        <div className={style.planTotal}>Totale (IVA incl.)</div>
        <div className={style.planTotal}>
          {props.type == 'gift'
            ? printTotalPrice(props.planPrice, props.couponPrice, 0, 0, props.creditNotes, false, props.isVatExempt)
            : printTotalPrice(
              props.planPrice,
              props.couponPrice,
              props.addon?.price,
              props.addon?.quantity,
              props.creditNotes,
              !!props.trial,
              props.isVatExempt
            )}
        </div>
      </div>
      {
        (props.type === 'standard' && props.trial) && (
          <div className={style.renewContainer}>
            <div className={style.renewTotal}>
              {`Si rinnova tra ${displayTrial(props.trial.period, props.trial.unit)} a ${printTotalPrice(
                props.trial.basePrice,
                undefined,
                props.addon?.price,
                props.addon?.quantity,
                props.creditNotes,
                props.isVatExempt
              )}. Puoi disdire la prova in qualsiasi momento con 1 click.`}
            </div>
          </div>
        )
      }
    </div>
  )
}

export default PlanDetails
