import { isEmpty } from 'lodash'
import * as jwt from 'jsonwebtoken'
import Cookies from 'universal-cookie'

import env from '../env.json'

const parse = JSON.parse
const stringify = JSON.stringify

const cookies = new Cookies()
const auth = {
  /**
   * Remove an item from the used storage
   * @param  {String} key [description]
   */
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key)
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key)
    }

    return null
  },

  /**
   * Clear all app storage
   */
  clearAppStorage() {
    if (localStorage) {
      localStorage.clear()
    }

    if (sessionStorage) {
      sessionStorage.clear()
    }
  },

  clearToken(tokenKey = env.STORAGE_KEY_TOKEN) {
    cookies.remove('_nnId', { path: '/', maxAge: '2592000', domain: 'learnn.com' })
    return auth.clear(tokenKey)
  },

  clearRefreshToken(tokenKey = env.STORAGE_KEY_REFRESH_TOKEN) {
    cookies.remove('_nnRf', { path: '/', maxAge: '2592000', domain: 'learnn.com' })
    return auth.clear(tokenKey)
  },

  clearUserInfo(userInfo = env.STORAGE_KEY_USER) {
    return auth.clear(userInfo)
  },

  /**
   * Returns data from storage
   * @param  {String} key Item to get from the storage
   * @return {String|Object}     Data from the storage
   */
  get(key) {
    if (localStorage && localStorage.getItem(key)) {
      return parse(localStorage.getItem(key)) || null
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return parse(sessionStorage.getItem(key)) || null
    }

    return null
  },

  isAuthenticated(tokenKey = env.STORAGE_KEY_TOKEN) {
    return auth.get(tokenKey) !== null
  },

  getToken(tokenKey = env.STORAGE_KEY_TOKEN) {
    return auth.get(tokenKey)
  },
  getRefreshToken(tokenKey = env.STORAGE_KEY_REFRESH_TOKEN) {
    return auth.get(tokenKey)
  },
  getSessionState(tokenKey = env.STORAGE_KEY_SESSION_STATE) {
    return auth.get(tokenKey)
  },
  getLastProgressWidgetTs(tokenKey = env.STORAGE_KEY_LAST_PROGRESS_WIDGET_TS) {
    return auth.get(tokenKey)
  },
  getOpenAppBannerClosed(tokenKey = env.OPEN_APP_BANNER_CLOSED_KEY) {
    return auth.get(tokenKey)
  },
  getCourseCompletedWidgetHide(tokenKey = env.STORAGE_KEY_COURSE_COMPLETED_HIDE) {
    return auth.get(tokenKey)
  },
  getIssuedFor() {
    return env.WEB_KEYCLOAK_CLIENT_ID
  },

  /**
   * Set data in storage
   * @param {String|Object}  value    The data to store
   * @param {String}  key
   * @param {Boolean} isLocalStorage  Defines if we need to store in localStorage or sessionStorage
   */
  set(value, key, isLocalStorage) {
    if (isEmpty(value)) {
      return null
    }

    if (isLocalStorage && localStorage) {
      return localStorage.setItem(key, stringify(value))
    }

    if (sessionStorage) {
      return sessionStorage.setItem(key, stringify(value))
    }

    return null
  },

  setToken(value = '', isLocalStorage = false, tokenKey = env.STORAGE_KEY_TOKEN) {
    cookies.set('_nnId', value, { path: '/', maxAge: '2592000', domain: 'learnn.com' })
    return auth.set(value, tokenKey, isLocalStorage)
  },
  setRefreshToken(value = '', isLocalStorage = false, tokenKey = env.STORAGE_KEY_REFRESH_TOKEN) {
    cookies.set('_nnRf', value, { path: '/', maxAge: '2592000', domain: 'learnn.com' })
    return auth.set(value, tokenKey, isLocalStorage)
  },
  setSessionState(value = '', isLocalStorage = false, tokenKey = env.STORAGE_KEY_SESSION_STATE) {
    cookies.set('_nnSession', value, { path: '/', maxAge: '2592000', domain: 'learnn.com' })
    return auth.set(value, tokenKey, isLocalStorage)
  },
  setLastProgressWidgetTs(
    value = '',
    isLocalStorage = true,
    tokenKey = env.STORAGE_KEY_LAST_PROGRESS_WIDGET_TS,
  ) {
    return auth.set(value, tokenKey, isLocalStorage)
  },
  setOpenAppBannerClosed(
    value = '',
    isLocalStorage = true,
    tokenKey = env.OPEN_APP_BANNER_CLOSED_KEY,
  ) {
    return auth.set(value, tokenKey, isLocalStorage)
  },
  setCourseCompletedWidgetHide(
    value = '',
    isLocalStorage = true,
    tokenKey = env.STORAGE_KEY_COURSE_COMPLETED_HIDE,
  ) {
    return auth.set(value, tokenKey, isLocalStorage)
  },
  setNextStep(value = '') {
    cookies.set('_nnNextStep', value, { path: '/', maxAge: '2592000', domain: 'learnn.com' })
  },
  getNextStep() {
    return cookies.get('_nnNextStep', { path: '/', maxAge: '2592000', domain: 'learnn.com' })
  },

  refreshToken() {
    const authToken = auth.getToken()
    const cookie = document.cookie.match('(^|;) ?' + '_nnId' + '=([^;]*)(;|$)')
    if (!cookie) {
      cookies.set('_nnId', authToken, { path: '/', maxAge: '2592000', domain: 'learnn.com' })
    }
  },
}

export default auth
