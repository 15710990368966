import { FC, useState } from 'react'
import style from './inlineSelect.module.css'
import cx from 'classnames'

export interface ISelectOption {
  value: string
  label: string
}

export type SelectProps = {
  className?: string
  options: ISelectOption[]
  value: ISelectOption | null
  onChange?(optionValues: ISelectOption): void
}

export const InlineSelect: FC<SelectProps> = ({ className, value, options, onChange }) => {
  const [selected, setSelected] = useState(value)

  const _onChange = (value: any) => {
    setSelected(value)
    onChange && onChange(value)
  }

  return (
    <div className={cx([style.container, className])}>
      {options.map(option => {
        return (
          <div
            className={cx([style.option, selected?.value === option.value && style.selected])}
            key={option.value}
            onClick={e => {
              e.stopPropagation()
              _onChange(option)
            }}>
            {option.label}
          </div>
        )
      })}
    </div>
  )
}
