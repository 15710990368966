import React, { useState, useContext, createContext, useEffect } from "react";
import { IHookResponse, useReactive } from "../../utils/useReactive";
import { progressiveSearch, ProgressiveSearchContent } from "../../controllers/Search";
import QueryString from 'query-string'
import { useHistory } from "react-router-dom";


type SearchContextType = {
  keyword: string;
  query?: string;
  setKeyword: (keyword: string) => void
  search: (overrideKeyword?: string) => void
  resetSearch: () => void
  searchResponse?: IHookResponse<ProgressiveSearchContent, string>
}


const SearchContext = createContext<SearchContextType | undefined>(undefined);

export type SearchProvider = {
  children: React.ReactChild | React.ReactChild[]
}
export const SearchProvider = ({ children }: SearchProvider) => {
  const history = useHistory()
  const searchKey = QueryString.parse(location.search)?.find?.toString()

  const [keyword, setKeyword] = useState<string>('');
  const [query, setQuery] = useState<string>();

  const searchResponse = useReactive<ProgressiveSearchContent, string>({
    observableFn$: progressiveSearch(query ?? ''),
    queryKey: ['search', query],
    enabled: !!query
  })

  const updateURLParameter = (param: string, value?: string) => {
    const params = new URLSearchParams()
    if (value) {
      params.set(param, value);
    } else {
      params.delete(param);
    }
    history.push(`/?${params.toString()}`)
  };

  const handleSearch = (overrideKeyword?: string) => {
    const newKeyword = overrideKeyword ?? keyword;
    if (newKeyword) {
      setKeyword(newKeyword);
      setQuery(newKeyword);
      updateURLParameter('find', newKeyword);
    }    
  }

  const handleReset = () => {
    setKeyword('');
    setQuery(undefined);
    updateURLParameter('find');
  }

  useEffect(() => {
    if (searchKey) {
      handleSearch(searchKey);
    } else {
      setKeyword('');
      setQuery(undefined);
    }
  }, [searchKey]);

  return (
    <SearchContext.Provider
      value={{
        query,
        keyword,
        setKeyword,
        search: handleSearch,
        resetSearch: handleReset,
        searchResponse
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext)
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider')
  }
  return context
};