import { FC } from "react";
import style from "./verifiedCertificateItem.module.css";
import { ReactComponent as VerifiedOutlineLogo } from '../../assets/images/icons/verified_outline.svg'
import { ReactComponent as VerifiedLogo } from '../../assets/images/icons/verified.svg'
import { Title, Text } from "@learnn/designn";


export type VerifiedCertificateItemProps = {
    isVerified: boolean;
    label?: string;
};

export const VerifiedCertificateItem: FC<VerifiedCertificateItemProps> = ({isVerified, label}) => {
  return (
    <div className={style.verifiedContainer}>
      <div className={style.verifiedColumn}>
        <div className={style.verified}>
          {isVerified ? <VerifiedLogo className={style.detailIcon} /> : <VerifiedOutlineLogo className={style.detailIcon} />}
          <Title variant="headingSm" fontWeight="bold">
            LEARNN VERIFIED
          </Title>
        </div>
        <Text variant="bodyXs" alignment="center">
          {label}
        </Text>
      </div>
    </div>
  );
};
