const jsonGet = (token, credentials) => async (host, path) => {
    if (!token) {
        throw new Error('not authorized');
    }
    const result = await fetch(`${host}${path}`, {
        method: 'get',
        headers: new Headers({
            Authorization: 'Bearer ' + token,
        }),
        credentials: credentials ? 'include' : undefined,
    });
    if (result.status === 502)
        throw new Error('Internal Server Error');
    if (result.status === 500)
        throw new Error('Internal Server Error');
    const text = await result.text();
    if (!text || text.trim() === '') {
        return undefined;
    }
    return JSON.parse(text);
};
const publicJsonGet = async (host, path) => {
    const result = await fetch(`${host}${path}`, {
        method: 'get',
    });
    if (result.status !== 200)
        throw new Error('Internal Server Error');
    const text = await result.text();
    if (!text || text.trim() === '') {
        return undefined;
    }
    return JSON.parse(text);
};
const jsonDelete = (token) => async (host, path) => {
    if (!token) {
        throw new Error('not authorized');
    }
    const result = await fetch(`${host}${path}`, {
        method: 'DELETE',
        headers: new Headers({
            Authorization: 'Bearer ' + token,
        }),
    });
    const text = await result.text();
    if (!text || text.trim() === '') {
        return undefined;
    }
    return JSON.parse(text);
};
const jsonPost = (token) => async (host, path, body) => {
    const authHeader = token
        ? {
            Authorization: 'Bearer ' + token,
        }
        : {};
    const url = `${host}${path}`;
    const hs = {
        ...{
            'Content-Type': 'application/json',
        },
        ...authHeader,
    };
    const headers = new Headers(hs);
    const result = await fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify(body),
    });
    const text = await result.text();
    if (!text || text.trim() === '') {
        return undefined;
    }
    return JSON.parse(text);
};
export const restClient = (token, credentials) => ({
    jsonGet: jsonGet(token, credentials),
    jsonPost: jsonPost(token),
    jsonDelete: jsonDelete(token),
    publicJsonGet,
});
export default restClient;
