import { useContext } from "react"
import { useExpertPopup } from "./useExpertPopup"
import { useShare } from "../SharePopup/useShare"
import { ExpertPopupContext } from "./popupContext"
import style from "./expertPopup.module.css"
import { ReactComponent as ShareIcon } from '../../assets/images/icons/share.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import env from '../../env.json'
import {Markdown, Title, CircularButton, Button, Badge} from "@learnn/designn"
import { capitalizeWords } from "src/utils/string"


export const ExpertPopup = () => {
    const { state } = useContext(ExpertPopupContext)
    const { close } = useExpertPopup()
    const { share } = useShare()
 
    const goToExpert = (expertSlug: string) => {
      window.open(`https://learnn.com/expert/${expertSlug}`, '_blank');
      close()
    }

   switch (state.show) {
    case true: {
        return (
            <div
            className={style.popupContainer}
            onClick={event => {
              event.preventDefault()
              close()
            }}>
            <div className={style.content}>
          
            <CloseIcon className={style.closeIcon} onClick={() => close()} />
            
            <div
              className={style.box}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}>
              <div className={style.card}>           
                <div className={style.cardBody}> 
                  <div className={style.cardHeader}>
                    <img className={style.image} src={state.expert.profileImage.cdnUrl} />
                    
                    <div className={style.info}>
                      <Title variant='headingXl'>{state.expert.name} {state.expert.lastName}</Title>
                      <Title variant='headingSm' fontWeight='bold'>{state.expert.profession} @ {state.expert.company}</Title>
                       
                        <div className={style.badgesContainer}>
                            {state.expert.competences.map((competence: string) => (
                              <Badge body={capitalizeWords(competence)} key={competence} variant='contained' />
                            ))}
                        </div>                
                    </div>
                  </div>

                  {state.expert.bio &&          
                  <div className={style.bioContainer}>
                    <Title variant='headingMd' fontWeight='bold'>Bio</Title>
                    <Markdown size='md'>{state.expert.bio}</Markdown>                 
                  </div>}

                  <div className={style.buttonsContainer}>       
                    <Button 
                      variant='primary' size='lg' label={"Esplora i Servizi"} 
                      icon={<FontAwesomeIcon icon={faArrowRight} />}
                      iconPosition='right'
                      onPress={() => goToExpert(state.expert.nickname)}
                      />
                      <CircularButton
                        variant='flat'
                        size='lg'
                        icon={<ShareIcon style={{ marginTop: '4px' }} />}
                        onPress={() =>
                        share({
                          title: 'Condividi questo Expert con un tuo collega',
                          type: 'external',
                          url: `${env.SITE_URL}/expert/${state.expert.nickname}`,
                        })}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        )
    }

    default: {
        return <></>
    }
   }
}