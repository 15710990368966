import { useContext } from 'react'
import style from './sharePopup.module.css'

import { ShareContext } from './shareContext'
import { useShare } from './useShare'
import { ShareComponent } from './Share'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export const SharePopup = () => {
  const { state: shareState } = useContext(ShareContext)
  const { close } = useShare()

  if (!shareState.show) return null

  return (
    <>
      <div
        className={style.popupContainer}
        onClick={event => {
          event.stopPropagation()
          event.preventDefault()
          close()
        }}>
        <div className={style.content}>
          <FontAwesomeIcon
            icon={faTimes}
            className={style.closeIcon}
            onClick={e => {
              e.stopPropagation()
              close()
            }}
          />
          <div
            className={cx([style.box])}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}>
            <ShareComponent
              url={shareState.url}
              body={shareState.body}
              title={shareState.title}
              type={shareState.type}
              onPress={shareState.onPress}
            />
          </div>
        </div>
      </div>
    </>
  )
}
