import { FC, useState } from 'react'
import { useDetectOutsideClick } from '../../../utils/hooks'
import style from './styles/playbackRate.module.css'
import cx from 'classnames'

export interface IPlaybackRate {
  startingRate?: number
  buttonClassName?: string
  onSetPlayback(rate: number): void
  onClick(rate: number): void
}

export const PlaybackRate: FC<IPlaybackRate> = ({
  startingRate = 1,
  onSetPlayback,
  onClick,
  buttonClassName,
}) => {
  const rates = [0.75, 1, 1.2, 1.5, 1.75, 2]
  const [playBackRate, setPlaybackRate] = useState(
    rates.indexOf(startingRate) > 0 ? rates.indexOf(startingRate) : 0,
  )
  const [popupVisible, setVisible] = useState(false)
  const clickDetectRef = useDetectOutsideClick(() => {
    setVisible(false)
  })

  const togglePopup = () => {
    setVisible(!popupVisible)
  }

  return (
    <div className={style.container} ref={clickDetectRef}>
      <div className={style.popup} data-visible={popupVisible}>
        {rates.map((rate, index) => {
          return (
            <button
              key={`video-rate-${rate}`}
              className={style.rateButton}
              onClick={e => {
                e.stopPropagation()
                setPlaybackRate(index)
                onSetPlayback(rate)
                onClick(rate)
                setVisible(false)
              }}>
              {rate} x
            </button>
          )
        })}
      </div>
      <button
        className={cx([style.playbackRate, buttonClassName])}
        onClick={e => {
          e.stopPropagation()
          togglePopup()
        }}>
        <p className={style.rateText}>{rates[playBackRate]} x</p>
      </button>
    </div>
  )
}
