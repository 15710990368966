import { restClient } from '../rest';
export const recommender = (API_URL, getToken) => {
    const getTopPicks = async (userId, limit) => {
        if (!getToken()) {
            return null;
        }
        try {
            const result = await restClient(getToken()).jsonGet(API_URL, `/top-picks?userId=${userId}${limit ? '&limit=' + limit : ''}`);
            return result;
        }
        catch (e) {
            throw e;
        }
    };
    return {
        getTopPicks,
    };
};
