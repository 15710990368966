import { useContext } from 'react'
import { ContinueWatchingPopupContext } from './popupContext'
import { ContinueWatchingPopupData } from './ContinueWatchingPopup'
import auth from '../../utils/auth'

export const useContinueWatchingPopup = () => {
  const { dispatch, state: prevState } = useContext(ContinueWatchingPopupContext)
  const currentTime = new Date().getTime()

  return {
    show: (data: ContinueWatchingPopupData) => {
      if (prevState.show === false && prevState.closed === false)
        dispatch({
          data,
          show: true,
        })
    },
    close: () => {
      auth.setLastProgressWidgetTs(currentTime.toString())

      dispatch({
        ...prevState,
        show: false,
        closed: true,
      })
    },
    hide: () => {
      auth.setLastProgressWidgetTs(currentTime.toString())

      dispatch({
        ...prevState,
        show: false,
        closed: false,
      })
    },
    currentState: prevState.show,
  }
}
