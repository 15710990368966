import { AnimatePresence, motion } from "framer-motion";
import React, { FC, useState } from "react";
import style from "./singleAccordion.module.css";
import arrowDown from "../../assets/images/icons/arrow-down.png";

export interface ISingleAccordion {
  prefix?: string;
  title: string;

  children: React.ReactChild | React.ReactChild[];
  renderSubtitle?(): React.ReactNode;

  opened?: boolean;
  lastItem?: boolean;
}

const variants = {
  hidden: { height: 0, opacity: 0 },
  visible: { height: "auto", opacity: 1 },
};
const variantsLastItem = {
  hidden: { height: 0, opacity: 0 },
  visible: { height: "100vh", opacity: 1 },
};

const arrowVariants = {
  normal: { rotate: 0 },
  rotated: { rotate: 180 },
};

export const SingleAccordion: FC<ISingleAccordion> = ({
  prefix,
  title,
  renderSubtitle,
  children,
  opened,

  lastItem = false,
}) => {
  const [visible, setVisible] = useState(opened);
  const toggleAccordion = () => setVisible(!visible);

  return (
    <motion.div className={style.container}>
      <button className={style.button} onClick={toggleAccordion}>
        <div className={style.headerInfo}>
          {prefix ? <p className={style.prefix}>{prefix}</p> : null}
          <div className={style.headerMain}>
            <div className={style.headerTitle}>
              <h3 className={style.title}>{title}</h3>
            </div>
            {renderSubtitle && renderSubtitle()}
          </div>
        </div>
        <div className={style.arrowContainer} data-rotate={visible}>
          <motion.img
            variants={arrowVariants}
            initial="open"
            animate={visible ? "rotated" : "normal"}
            className={style.arrow}
            src={arrowDown}
          />
        </div>
      </button>

      <motion.div
        initial="hidden"
        animate={visible ? "visible" : "hidden"}
        variants={lastItem ? variantsLastItem : variants}
        className={style.itemsContainer}>
        <AnimatePresence initial={false}>
          {visible ? <motion.div className={style.itemsInner}>{children}</motion.div> : null}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};
