import useAnalytics from '../../analytics/useAnalytics'
import {
  ComponentSectionDynamicCompletedCourses,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/dist/api/dynamic-sections/types'
import * as F from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { useCoursePopup } from '../CoursePopup/useCoursePopup'
import { CourseCard } from '../CourseCard'
import { CourseBaseInfoEngagement } from '@learnn/sdk/dist/api/course'
import { getAuthorsNames } from '../../utils/data'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'

export interface IPathCompletedCoursesProps {
  data: DynamicSectionsValue<DynamicSectionsError, ComponentSectionDynamicCompletedCourses>
  onShowAll: () => unknown
}

export const PathCompletedCourses = ({ data }: IPathCompletedCoursesProps) => {
  const { logSelectSliderCourse } = useAnalytics()
  const { show } = useCoursePopup()

  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const handleCourseClick = (
    course: CourseBaseInfoEngagement,
    section: ComponentSectionDynamicCompletedCourses,
  ) => {
    logSelectSliderCourse(course.id, {
      course_title: course.title,
      course_type: course.type.slug,
      course_duration: course.duration,
      course_author: getAuthorsNames(course.authors),
      slider_title: section.title,
    })

    show(course)
  }

  const render = (section: ComponentSectionDynamicCompletedCourses) => {
    return (
      <CustomCarousel
        title={section.title}
        data={section.data}
        carouselKind='course'
        renderSlide={course => {
          return <CourseCard course={course} onClick={() => handleCourseClick(course, section)} size='md' />
        }}
      />
    )
  }

  return F.pipe(
    data,
    O.fold(CustomCarouselLoading, data => {
      return F.pipe(data, E.fold(error, render))
    }),
  )
}
