import style from './iconButton.module.css'
import cx from 'classnames'
import play from '../../assets/images/icons/play.png'

export interface IconButtonProps {
  text?: string
  textClassName?: string
  containerClassName?: string
  iconClassName?: string
  icon?: string
  onClick?: () => void
}

export const IconButton = ({
  containerClassName,
  iconClassName,
  textClassName,
  onClick,
  icon = play,
  text,
}: IconButtonProps) => {
  return (
    <div
      onClick={e => {
        e.stopPropagation()
        if (onClick) onClick()
        return
      }}
      className={cx([style.container, containerClassName])}>
      <img src={icon} className={cx([style.icon, iconClassName])} />
      {text ? <span className={cx([style.text, textClassName])}>{text}</span> : null}
    </div>
  )
}
