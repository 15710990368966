import { TriggerPopup } from '../TriggerPopup'
import noteIcon from '../../assets/images/icons/note.svg'
import style from './notePopup.module.css'
import Loader from 'react-loader-spinner'
import { OpenSideType } from '../TriggerPopup/TriggerPopup'
import cx from 'classnames'
import { useQuery, useQueryClient } from 'react-query'
import useAnalytics from '../../analytics/useAnalytics'
import { useShare } from '../SharePopup/useShare'
import { getLessonNotes } from '../../controllers/Player'
import { createNote, deleteNote, updateNote } from '../../controllers/Note'
import { formatSeconds } from '../LessonPlayer/utils'
import { EditableNote } from '@learnn/designn'
import { NoteEditor } from '../EditableNote/EditableNote'
import { matchQuery } from '../../utils/matchQuery'
import env from '../../env.json'

interface INotePopup {
  lessonId: string
  courseId: string
  moduleId: string
  visible: boolean

  onClick(): void

  viewTime: number
  openSide?: OpenSideType
  buttonClassNameImage?: string
  buttonClassName?: string
  popupContainerClass?: string
  icon?: string
  zIndex?: number
}

export const NotePopup = ({
  lessonId,
  courseId,
  moduleId,
  visible,
  onClick,
  viewTime,
  openSide = 'top-left',
  buttonClassName,
  buttonClassNameImage,
  popupContainerClass,
  icon = noteIcon,
  zIndex = 0,
}: INotePopup) => {
  const queryClient = useQueryClient()
  const { logSaveNote, logShareNote } = useAnalytics()
  const { share } = useShare()
  const notesQuery = useQuery(['lessonNotes', lessonId], () => getLessonNotes(lessonId))

  const saveNewNote = async (text: string) => {
    await createNote({
      content: text,
      lessonId,
      courseId,
      viewTime,
    })

    logSaveNote(courseId, moduleId, lessonId)

    await queryClient.refetchQueries(['lessonNotes', lessonId])
  }

  const refetchQueries = async () => {
    await queryClient.refetchQueries(['lessonNotes', lessonId])
    await queryClient.invalidateQueries(['notes', courseId])
  }

  const iconClick = () => [onClick()]

  return (
    <TriggerPopup
      zIndex={zIndex}
      openSide={openSide}
      width='28.6em'
      height='16.4em'
      image={icon}
      visible={visible}
      onClick={iconClick}
      buttonClassName={buttonClassName}
      buttonClassNameImage={buttonClassNameImage}
      popupContainer={cx([popupContainerClass])}>
      <div className={style.textAreaContainer}>
        {matchQuery(notesQuery, {
          loading: () => (
            <div className={style.containerLoading}>
              <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
            </div>
          ),
          error: () => <></>,
          success: notes => (
            <div>
              <NoteEditor loading={false} onSave={saveNewNote} resetOnSave />
              {notes.map(note => (
                <EditableNote
                  variant='small'
                  mb={3}
                  key={`note-${note.noteId}-${lessonId}`}
                  title={formatSeconds(note.viewTime)}
                  body={note.data}
                  //@ts-ignore //TODO
                  onEditClick={async value => {
                    await updateNote(note.noteId, value)
                    refetchQueries()
                  }}
                  onDeleteClick={async () => {
                    await deleteNote(note.noteId)
                    refetchQueries()
                  }}
                  onShareClick={() => {
                    share({
                      body: `Ho appena seguito una lezione su Learnn e ho preso una nota:\n\n"${note.data}"`,
                      type: 'note',
                      url: `${env.SITE_URL}/lezione/${note.lessonId}`,
                      title: 'Condividi la nota con un tuo collega',
                      onPress: () => logShareNote(courseId, moduleId, lessonId),
                    })
                  }}
                />
              ))}
            </div>
          ),
        })}
      </div>
    </TriggerPopup>
  )
}
