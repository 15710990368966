import  { FC } from 'react';
import { formatSeconds } from '../utils';
import style from './styles/scrubber.module.css';

export interface IDuration {
  playedSeconds?: number;
  duration?: number;
}

export const Duration: FC<IDuration> = ({ playedSeconds = 0, duration = 0 }) => {
  const remainingTime = duration - playedSeconds;
  return (
    <time dateTime={`P${Math.round(remainingTime)}S`} className={`${style.time} ${style.duration}`}>
      {formatSeconds(remainingTime)}
    </time>
  )
}
