import { useEffect, useState } from "react"
import { AssistantService, assistantService } from "../../controllers/Assistant"

export type Message = {
    role: 'user' | 'assistant',
    content: string,
    createdAt: Date
}
export type Status = 'done' | 'wait_for_assistant'
export const useAssistant = ({ userId }: { userId: string }) => {
    const [messages, setMessages] = useState<Message[]>(
        []
    )
    const [instance, setInstance] = useState<AssistantService>()
    const [status, setStatus] = useState<Status>('done')

    const onMessageReceive = (content: string) => {
        console.log('message received', content);
        setMessages((messages) => ([
            ...messages,
            { role: 'assistant', content, createdAt: new Date() }
        ]))
        setStatus('done')
    }

    useEffect(() => {
        const instance = assistantService({
            userId,
            onMessageReceive: onMessageReceive
        })
        setInstance(instance)
    }, [userId])

    return {
        messages,
        status,
        sendMessage: async (message: string) => {
            if (instance) {
                setMessages((messages) => ([
                    ...messages,
                    { role: 'user', content: message, createdAt: new Date() }
                ]))
                setStatus('wait_for_assistant')
                await instance.sendMessage(message)
            }
        }
    }

}
