import { MutableRefObject, useEffect, useReducer, useRef } from 'react'
import { ReactPlayerProps } from 'react-player'
import { ReactPlayerControls } from './types'

function reducerProps(props: IVideoProps, newProps: IVideoProps) {
  return { ...props, ...newProps }
}

function reducerState(state: IVideoState, newState: IVideoState) {
  return { ...state, ...newState }
}

export interface IVideoProps {
  url?: string
  pip?: boolean
  playing?: boolean
  played?: number
  playedSeconds?: number
  controls?: boolean
  light?: boolean
  loop?: boolean
  playbackRate?: number
  subtitlesVisible?: boolean
  volume?: number
  muted?: boolean
  noteOpened?: boolean
}

export type OnProgress = {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

export interface IVideoState {
  seeking?: boolean
  duration?: number
  played?: number
  playedSeconds?: number
  playedPercentage?: number
  loaded?: number
  loadedSeconds?: number
  playerReady?: boolean
  ended?: boolean
}

export interface IVideoReadOnlyState extends IVideoState, IVideoProps {}

export interface IVideoControls extends ReactPlayerControls {
  seekTo(n?: number, type?: string): void
  setSeeking(status: boolean): void
  setPlayed(n: number): void
  setPlaybackRate(n: number): void
  setSubtitlesVisible(visible: boolean): void
}

export interface IReactPlayerProps extends ReactPlayerProps {
  onProgress(state: OnProgress): void
}

export const useReactPlayer = (
  initialProps: IVideoProps,
): {
  reactPlayerProps: ReactPlayerProps
  state: IVideoReadOnlyState
  ref: MutableRefObject<any>
  controls: IVideoControls
} => {
  const [props, dispatchProps] = useReducer(reducerProps, initialProps)
  const [state, dispatchState] = useReducer(reducerState, {})

  const localRef: MutableRefObject<any> = useRef(null)
  const redOnlyState = { ...props, ...state }

  useEffect(() => {
    dispatchProps(initialProps)
  }, [initialProps.url])

  return {
    // Le props vengono buildate per essere passate a react-player, quindi seguono questa struttura
    // https://github.com/cookpete/react-player#props
    reactPlayerProps: {
      onPlay: () => dispatchProps({ playing: true }),
      onPause: () => dispatchProps({ playing: false }),
      onReady: () => dispatchState({ playerReady: true }),
      onProgress: (newState: OnProgress) => {
        if (!state.seeking) {
          dispatchState({
            ...newState,
            playedPercentage: state.duration ? (newState.playedSeconds * 100) / state.duration : 0
          })
        }
      },
      onEnded: () => {
        dispatchProps({ playing: false })
        dispatchState({ ended: true })
      },
      onDuration: (duration: number) => dispatchState({ duration }),
      ...props,
    },
    // Lo state e' quello che viene consumato dai nostri componenti
    // viene messo in sync con il player e quindi se vogliamo
    // prendere informazioni dal video dobbiamo leggere questo state.
    state: redOnlyState,
    // per controllare il video bisogna usare questi controls, perche' oltre
    // ad agire su react player si occupano anche di tenere in sync il nostro internal state
    controls: {
      seekTo: (n, type) => {
        localRef?.current.seekTo(Number(n), type)
      },
      setSeeking: status => {
        dispatchState({ seeking: status })
      },
      togglePlay: () => {
        dispatchProps({ playing: !props.playing })
      },
      play: () => dispatchProps({ playing: true }),
      pause: () => dispatchProps({ playing: false }),
      toggleControls: () => dispatchProps({ controls: !props?.controls }),
      setPlaybackRate: n => dispatchProps({ playbackRate: n }),
      setSubtitlesVisible: a => dispatchProps({ subtitlesVisible: a }),
      toggleLight: () => dispatchProps({ light: !props?.light }),
      toggleMuted: () => dispatchProps({ muted: !props?.muted }),
      togglePip: () => dispatchProps({ pip: !props?.pip }),
      enablePip: () => dispatchProps({ pip: true }),
      disablePip: () => dispatchProps({ pip: false }),
      onVolumeChange: (e: any) => dispatchProps({ volume: parseFloat(e.target.value) }),
      setPlayed: (n: number) => {
        dispatchState({ played: n })
      },
    },
    ref: localRef,
  }
}
