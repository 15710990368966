import { FC } from 'react'
import { Markdown, VerticalStack, useTheme, Text } from '@learnn/designn'
import { Message } from './types'
import { AssistantMessageSources } from './AssistantMessageSources'
type AssistantMessageItemProps = {
  message: Message
}

export const AssistantMessageItem: FC<AssistantMessageItemProps> = ({ message }) => {
  const { spacing, colors, borders } = useTheme()

  switch (message.role) {
    case 'assistant':
      return (
        <VerticalStack mb={spacing.space_5}>
          <Markdown size='md'>## Learnn Assistant</Markdown>
          <Markdown size='sm'>{message.data}</Markdown>
          {message.sources && message.sources.length > 0 && (
            <AssistantMessageSources sources={message.sources} />
          )}
        </VerticalStack>
      )
    case 'user':
      return (
        <VerticalStack
          mb={spacing.space_0}
          py={spacing.space_3}
          px={spacing.space_6}
          bg={colors.card_border}
          borderRadius={borders.radius.large}
          maxWidth='90%'>
          <Text size='sm'>{message.data}</Text>
        </VerticalStack>
      )
  }
}
