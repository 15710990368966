import { gql } from '@apollo/client';
export const feedback = (engagementGraphClient) => {
    const createFeedback = async (feedback) => {
        const response = await engagementGraphClient.mutate({
            mutation: CREATE_FEEDBACK,
            variables: {
                data: feedback,
            },
        });
        var newFeedback = response.data?.createFeedback;
        return newFeedback.feedbackId;
    };
    const getFeedbackCategories = async () => {
        const result = await engagementGraphClient.query({
            query: FEEDBACK_CATEGORIES,
        });
        return result.data ? result.data?.getFeedbackCategories : [];
    };
    return {
        send: createFeedback,
        getCategories: getFeedbackCategories,
    };
};
const CREATE_FEEDBACK = gql `
  mutation CreateFeedback($data: CreateFeedbackInput!) {
    createFeedback(data: $data) {
      feedbackId
      userId
      courseId
      lessonId
      contentType
      userDeviceInfo
      data
      createdAt
    }
  }
`;
const FEEDBACK_CATEGORIES = gql `
  query GetFeedbackCategories {
    getFeedbackCategories {
      feedbackCategoryId
      name
    }
  }
`;
