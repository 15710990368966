import { AnimatePresence, motion } from 'framer-motion'
import { FC, useMemo, useState, useEffect, useContext, useRef } from 'react'
import style from './moduleGroup.module.css'
import arrowDown from '../../../../../assets/images/icons/arrow-down.png'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LessonItem } from './LessonItem'
import { GlobalProviderContext } from '../../../../../components/GlobalProvider'
import { useHistory } from 'react-router-dom'
import { Lesson, LessonEngagement, Module, ModuleEngagement } from '@learnn/sdk/dist/api/course'
import { getUrlFromLesson, extractConfiguration } from '../../../../../utils/data'
import cx from 'classnames'
import { HorizontalStack, Title, Text, useTheme } from '@learnn/designn'
import { handleContextMenu } from '../../../../../utils/contextMenu'
import { ContextMenu } from '../../../../../components/ContextMenu'

type ModuleGroupProps = {
  module: Module & Partial<ModuleEngagement>
  lastProgress?: { id: string; progress: number } | null
  courseId: string
  initVisible?: boolean
  lastItem?: boolean
}

const variants = {
  hidden: { height: 0, opacity: 0 },
  visible: { height: 'auto', opacity: 1 },
}
const variantsLastItem = {
  hidden: { height: 0, opacity: 0 },
  visible: { minHeight: '100vh', height: 'auto', opacity: 1 },
}

const arrowVariants = {
  normal: { rotate: 0 },
  rotated: { rotate: 180 },
}

export const ModuleGroup: FC<ModuleGroupProps> = ({
  module,
  lastProgress,
  initVisible,
  lastItem = false,
}) => {
  const [visible, setVisible] = useState(initVisible)
  const toggleAccordion = () => setVisible(!visible)
  const history = useHistory()
  const myRef = useRef<null | HTMLDivElement>(null)
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const blocked = !configuration?.premiumVisible && !module.free

  const { spacing } = useTheme()

  const itemsList = useMemo(
    () =>
      module.lessons.map((item: Lesson & Partial<LessonEngagement>, index) => {
        const lessonUrl = getUrlFromLesson(item);

        return (
          <>
            <ContextMenu menuId={`default`} />  
            <LessonItem
              key={`moduleGroup-${module.id}-${index}`}
              lesson={item}
              continueWatching={lastProgress ? lastProgress.id === item.id : false}
              onClick={() => history.push(lessonUrl)}
              onContextMenu={(e) => handleContextMenu(e, { url: lessonUrl })}
            />
          </>
        )
      }),
    [module.lessons],
  )

  useEffect(() => {
    setVisible(initVisible)
    if (initVisible) {
      setTimeout(() => {
        if (myRef.current) {
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }, 500)
    }
  }, [initVisible])

  return (
    <motion.div className={style.container} ref={myRef}>
      <div className={style.content} onClick={toggleAccordion}>
        <div className={style.details}>
          <HorizontalStack alignItems='center' mb={spacing.space_2}>
            {blocked ? <FontAwesomeIcon icon={faLock} className={style.lockIcon} /> : <></>}
            <Title variant='headingSm'>{module.title}</Title>          
          </HorizontalStack>
          
            <Text variant='bodyXs' >
              {module.completed_lessons ?? 0}/{module.module_length} Lezioni &nbsp; - &nbsp;
              {module.module_duration}
            </Text>     
        
        </div>

      <HorizontalStack alignItems='center' gap={spacing.space_4}>

       
      

        <div className={style.arrowContainer} data-rotate={visible}>
          <motion.img
            variants={arrowVariants}
            initial='open'
            animate={visible ? 'rotated' : 'normal'}
            className={cx([style.arrow, visible ? style.rotated : ''])}
            src={arrowDown}
            />
        </div>
      </HorizontalStack>
        
      <div className={style.lastCourseProgressBarContainer}>
          <div
            className={style.lastCourseProgressBar}
            style={{ width: `${module.module_progress}%` ?? 0 }}
            />
        </div>
      </div>


      <motion.div
        initial='hidden'
        animate={visible ? 'visible' : 'hidden'}
        variants={lastItem ? variantsLastItem : variants}
        className={style.itemsContainer}>
        <AnimatePresence initial={false}>
          {visible ? <motion.div className={style.itemsInner}>{itemsList}</motion.div> : null}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  )
}
