import { FC } from 'react'
import style from './lessons.module.css'

import {
  CourseLessonsEngagement,
  LessonEngagement,
  ModuleEngagement,
} from '@learnn/sdk/dist/api/course'
import { ResponsiveImage } from '../../../../components/ResponsiveImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { Container, Title, VerticalStack, useTheme } from '@learnn/designn'
import { Modules } from './ModuleGroup/Modules'
import { CourseStatusCard } from '../../../../components/CourseStatusCard/CourseStatusCard'
import { CompletedPopup } from '../../../../components/CourseCompletedPopup'

const nextLessonHook = (
  course: CourseLessonsEngagement,
): { isFirstLesson: boolean; lesson: LessonEngagement } => {
  let lesson: LessonEngagement = course.modules[0].lessons[0]

  if (course.lastProgress?.id) {
    course.modules.forEach((module: ModuleEngagement) =>
      module.lessons.forEach((l: LessonEngagement) => {
        if (l.id === course.lastProgress?.id) lesson = l
      }),
    )
    return {
      isFirstLesson: false,
      lesson,
    }
  } else {
    return {
      isFirstLesson: true,
      lesson,
    }
  }
}

type LessonsProps = {
  course: CourseLessonsEngagement
  userId: string
}
export const Lessons: FC<LessonsProps> = ({ course, userId }) => {
  const history = useHistory()
  const { isFirstLesson, lesson: nextLesson } = nextLessonHook(course)
  const { spacing } = useTheme()

  return (
    <Container>
      <Title variant='heading2xl' mb={spacing.space_6}>
        Moduli
      </Title>

      <div className={style.lastCourseCard}>
        <div className={style.lastCourseImageContainer}>
          <ResponsiveImage wrapperClassName={style.lastCourseImage} src={course.coverImage} />
        </div>
        <div className={style.lastCourseInfo}>
          <div className={style.details}>
            <div className={style.lastCourseSubtitle}>
              {isFirstLesson ? 'INIZIA A GUARDARE' : 'CONTINUA A GUARDARE'}
            </div>
            <div className={style.lastCourseTitle}>{nextLesson.title}</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <div className={style.lastCourseDuration}>{nextLesson.duration}</div>
              <div className={style.lastCourseProgressBarContainer}>
                <div
                  className={style.lastCourseProgressBar}
                  style={{ width: `${nextLesson.progress}%` ?? 0 }}
                />
              </div>
            </div>
          </div>
          <div
            className={style.lastCourseCallToAction}
            onClick={() => history.push(`/player/${nextLesson.id}`)}>
            <FontAwesomeIcon icon={faPlay} />
          </div>
        </div>
      </div>
      <VerticalStack mb='40px'>
        <CourseStatusCard
          userId={userId}
          courseId={course.id}
          courseProgressPercentage={Math.round(course.progress ?? 0)}
        />
      </VerticalStack>
      <p className={style.moduleGroupTitle}>Contenuti del {course.type.title.toLowerCase()}</p>
      <Modules course={course} userId={userId} />
      <CompletedPopup
        userId={userId}
        courseId={course.id}
        courseProgressPercentage={Math.round(course.progress ?? 0)}
      />
    </Container>
  )
}
