import { Explore } from './Explore'
import { useBodyClass } from '../../utils/hooks'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { Onboarding } from '../../components/Onboarding'
import { PreferencesPopupContextProvider } from '../../components/PreferencesPopup/popupContext'
import { PreferencesPopup } from '../../components/PreferencesPopup'
import { useQueryClient } from 'react-query'


export const ExploreScreen = () => {
  useBodyClass('app')
  const queryClient = useQueryClient()

  return (
    <Onboarding>
      <AppLayout showSearchbar>
        <PreferencesPopupContextProvider>
          <Explore />
          <PreferencesPopup 
            onSubmit={() => {
                  queryClient.refetchQueries({queryKey: "explore"})
            }}
          />
        </PreferencesPopupContextProvider>
      </AppLayout>
    </Onboarding>
  )
}
