import { useContext, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getQuiz, getResult, getAttemptDetails, saveAnswser } from '../../controllers/Quiz'
import { getCourseBaseInfo } from '../../controllers/Course'
import Loader from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import style from './styles/quizScreen.module.css'
import { QuizHeader } from './QuizHeader'
import { QuestionList } from '../../components/QuestionList'
import { QuizInfo } from './QuizInfo'
import { AttemptDetails } from './AttemptDetails'
import { QuizAttemptDetails, QuizItem, QuizResult } from '@learnn/sdk/src/api/quiz/'
import { useBodyClass } from '../../utils/hooks'
import useQuizAnalytics from './hooks/useQuizAnalytics'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import env from '../../env.json'
import { extractConfiguration } from '../../utils/data'

export type QuizStatus = 'not_started' | 'started' | 'time_up' | 'completed'

export const QuizScreen = () => {
  useBodyClass('app-quiz')
  const history = useHistory()
  const queryClient = useQueryClient()
  const { courseId } = useParams<{ courseId: string }>()
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext) //@ts-ignore
  const userId = globalContext?.userId
  const quizAvailability = configuration?.quizAvailability ?? {} //@ts-ignore
  const isQuizEnabled =
    quizAvailability && quizAvailability[courseId] ? quizAvailability[courseId] : false

  const {
    isLoading: courseLoading,
    error: courseError,
    data: course,
  } = useQuery(['course', courseId], () => getCourseBaseInfo(courseId))
  const {
    isLoading: quizLoading,
    error: quizError,
    data: quiz,
    refetch: getQuestions,
  } = useQuery<QuizItem, Error>(['quiz', courseId], () => getQuiz(courseId), { enabled: false })

  const [status, setStatus] = useState<QuizStatus>('not_started')
  const [result, setResult] = useState<QuizResult>()
  const [currentQuestionIndex, setCurrentQuestion] = useState(0)
  const [submitError, setError] = useState('')
  const [attemptDetails, setAttemptDetails] = useState<QuizAttemptDetails>()
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [isSubmittingAnswer, setSubmittingAnswer] = useState(false)

  const startQuiz = async () => {
    await getQuestions()
    setStatus('started')
  }

  const handleSubmit = async (questionId: string, answerId: string) => {
    setError('')

    if (!quiz) return

    try {
      setSubmittingAnswer(true)
      await saveAnswser(quiz.attemptId, questionId, answerId)

      const nextQuestion = currentQuestionIndex + 1

      if (nextQuestion < quiz.questions.length) {
        setCurrentQuestion(nextQuestion)
        setSubmittingAnswer(false)
      } else {
        setLoadingDetails(true)
        const result = await getResult(quiz.attemptId)
        if (!userId) throw new Error('Invalid user id')
        const attempt = await getAttemptDetails(quiz.attemptId, userId)
        setResult(result)
        setAttemptDetails(attempt)
        setLoadingDetails(false)
        setStatus('completed')
      }
    } catch (error) {
      console.log(error)
      setError("Si è verificato un durante l'invio della risposta, prova nuovamente.")
    }
  }

  const handleRefresh = (event: any) => {
    //Displays confirmation message
    event.preventDefault()
  }

  useEffect(() => {
    if (!isQuizEnabled) history.push(`/corso/${courseId}`)
  }, [isQuizEnabled])

  useEffect(() => {
    if (course && course?.progress < env.QUIZ_ALLOW_THRESHOLD) history.push(`/corso/${courseId}`)
  }, [course])

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.onpopstate = function () {
      history.go(1)
    }
    window.addEventListener('beforeunload', handleRefresh)

    return () => {
      window.removeEventListener('beforeunload', handleRefresh)
      queryClient.invalidateQueries(['quizAttempts', courseId, userId])
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)
  }, [currentQuestionIndex])

  useQuizAnalytics(quiz, attemptDetails, course, quizError, status)

  return courseLoading || loadingDetails ? (
    <div className={style.containerLoading}>
      <Loader type='ThreeDots' color='#000000' height={60} width={60} />
    </div>
  ) : courseError || !course || quizError ? (
    <>
      <div className={style.container}>
        <div className={style.containerSummary}>
          {quizError &&
          quizError.hasOwnProperty('message') &&
          quizError.message.includes('LastAttemptTooCloseError') ? (
            <>
              <h1 className={style.descriptionTitle}>Tentativo già effettuato</h1>
              <p className={style.quizDescription}>
                Hai già effettuato un tentativo per questo quiz. Potrai riprovare una volta passati
                {` ${env.QUIZ_RETRY_TIME_DAYS}`} giorni dall'ultimo tentativo effettuato.
              </p>
            </>
          ) : (
            <>
              <h1 className={style.descriptionTitle}>Si è verificato un errore</h1>
              <p className={style.quizDescription}>
                Si è verificato un errore durante il caricamento del quiz, ricarica la pagina.
              </p>
            </>
          )}
          <button className={style.beginButton} onClick={() => history.goBack()}>
            TORNA AL CORSO
          </button>
        </div>
      </div>
      <div className={style.footer}>Copyright © 2022 Learnn. Tutti i diritti riservati.</div>
    </>
  ) : (
    <>
      <QuizHeader
        course={course}
        quiz={quiz}
        status={status}
        currentQuestion={currentQuestionIndex}
        onBackClick={() => history.push(`/corso/${courseId}`)}
        onTimeUp={() => setStatus('time_up')}
      />
      {quizLoading ? (
        <div className={style.containerLoadingQuestions}>
          <Loader type='ThreeDots' color='#000000' height={60} width={60} />
        </div>
      ) : (
        <div className={style.container}>
          {status === 'not_started' ? (
            <div className={style.containerSummary}>
              <h1 className={style.descriptionTitle}>Informazioni sullo svolgimento</h1>
              <p className={style.quizDescription}>
                Il quiz è composto da 10 domande, una volta risposto ad una domanda non potrai più
                tornare indietro.
              </p>
              <p className={style.quizDescription}>
                Se aggiorni la pagina o la chiudi, non potrai riprendere il quiz già iniziato e il
                tuo tentativo sarà automaticamente considerato come fallito.
              </p>
              <p className={style.quizDescription}>
                {`In caso di fallimento è possibile ritentare il quiz dopo ${env.QUIZ_RETRY_TIME_DAYS} giorni.`}
              </p>
              <p className={style.quizDescription}>
                {`Il tempo limite per svolgere il quiz è di ${env.QUIZ_DURATION_MINUTES} minuti, una volta scaduto il tempo il quiz non verrà considerato come
                completato.`}
              </p>
              <p className={style.quizDescription}>
                Il timer partirà una volta premuto il pulsante "Inizia il quiz"
              </p>
              <button className={style.beginButton} onClick={startQuiz}>
                INIZIA IL QUIZ
              </button>
            </div>
          ) : quiz && status === 'started' ? (
            <QuestionList
              theme='light'
              question={quiz.questions[currentQuestionIndex]}
              isSubmittingAnswer={isSubmittingAnswer}
              onAnswerSubmit={handleSubmit}
              error={submitError}
            />
          ) : quiz && status === 'time_up' ? (
            <QuizInfo timeUp quiz={quiz} onBackClick={() => history.push(`/corso/${courseId}`)} />
          ) : attemptDetails && result && status === 'completed' ? (
            <AttemptDetails
              attempt={attemptDetails}
              result={result}
              onBackClick={() => history.push(`/corso/${courseId}`)}
            />
          ) : null}
        </div>
      )}

      <div
        className={
          style.footer
        }>{`Copyright © ${new Date().getFullYear()} Learnn. Tutti i diritti riservati.`}</div>
    </>
  )
}
