import { FC } from "react";
import cx from "classnames";
import style from "./errorMessage.module.css";
import errorImage from "../../assets/images/bug.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

type ErrorMessageProps = {
  containerClass?: string;
  variant?: 'dark' | 'light'
};

export const ErrorMessage: FC<ErrorMessageProps> = ({ containerClass, variant }) => {
  return (
    <div className={cx([style.container, containerClass])}>
      <div className={style.row}>
        <div className={style.column}>
          <h1 className={style.title}>We have a bug</h1>
          <div className={cx([style.text, variant === 'light' ? style.light : null])}>
            <p> Per bug si intende un errore del programma che può avvenire per determinate ragioni.</p>
            <p>Tu ne hai appena scoperto uno.</p>
            <p>Puoi provare a ricare la pagina oppure se vuoi aiutarci a velocizzare la risoluzione di questo problema</p>
            <a className={cx([style.link, variant === 'light' ? style.light : null])} href="https://learnn.com/faq" target="_blank">
              Visita le FAQ o scrivici <FontAwesomeIcon icon={faChevronRight} size="sm" className="ml-1" />
            </a>
          </div>
        </div>
        <div className={style.column}>
          <img className={style.image} src={errorImage} />
        </div>
      </div>
    </div>
  );
};
