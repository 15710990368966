import { createContextReducer } from '../../utils/createContext'
import { ContinueWatchingPopupData } from './ContinueWatchingPopup'

export type PopupState =
  | {
      data: ContinueWatchingPopupData
      show: true
    }
  | {
      show: false
      closed: boolean
    }

export const popupInitialState: PopupState = {
  show: false,
  closed: false
}

export type IPopupActions = PopupState & {}

function reducer(state: PopupState, newState: IPopupActions) {
  return { ...state, ...newState }
}

const [ctx, Provider] = createContextReducer<PopupState, IPopupActions>(reducer, popupInitialState)
export const ContinueWatchingPopupContext = ctx
export const ContinueWatchingPopupContextProvider = Provider
