import { useQuery } from 'react-query'
import { useRef, useState } from 'react'
import { downloadImageFromDiv, getBase64FromDiv } from '../../utils/data'
import { useHistory, Redirect } from 'react-router-dom'
import { getYearRecap, me, YearRecap } from '../../controllers/User'
import { YearRecapCard, LoaderCard, ErrorCard } from '../../components/YearRecapCard'
import style from './yearRecapScreen.module.css'
import { useBodyClass } from '../../utils/hooks'
import { matchQuery } from '../../utils/matchQuery'
import QueryString from 'query-string'
import { useShare } from '../../components/SharePopup/useShare'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import Confetti from 'react-confetti'
import { useWindowSize } from '../../utils/hooks'
import cx from 'classnames'
import { useParams } from 'react-router-dom'
import { useGTMWebapp } from '../../analytics/gtm'

type UrlParams = {
  download?: string
  embed?: string
  borderType?: string
}

type StatsType = 'last_year' | 'total'
type BorderType = 'square' | 'round'

const extractDownload = (urlParams: UrlParams) => urlParams?.download
const extractEmbed = (urlParams: UrlParams) => urlParams?.embed
const extractBorder = (urlParams: UrlParams) => urlParams?.borderType ?? 'round'

const isOwner = (loggedId: string | null, userId: string) => loggedId && loggedId === userId

export const YearRecapScreen = () => {
  useBodyClass('dark-gradient')
  const { sendToGTM } = useGTMWebapp();
  const { share } = useShare()
  const history = useHistory()
  const size = useWindowSize()
  const { userId } = useParams<{ userId: string }>()
  const urlParams = useRef(QueryString.parse(location.search))
  const downloadOnOpen = extractDownload(urlParams.current)
  const embed = extractEmbed(urlParams.current)
  const border = extractBorder(urlParams.current) as BorderType
  const [borderType, setBorderType] = useState<BorderType>(border)
  const [statsType, setStatsType] = useState<StatsType>('last_year')
  const statsQuery = useQuery<YearRecap, Error>(
    ['yearRecap', userId, statsType],
    () => getYearRecap(userId, statsType),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  const sendImageToBrowser = async () => {
    const base64Image = await getBase64FromDiv('recap-card')
    if (base64Image && window && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ imageUrl: base64Image }))
    } else {
      window.ReactNativeWebView.postMessage('Si è verificato un errore')
    }
  }

  const downloadImage = async () => {
    setBorderType('square')
    await downloadImageFromDiv('recap-card', 'my-recap')
    setBorderType('round')
  }

  return (
    <div className={cx([style.screen, embed && style.embedScreen])}>
      {matchQuery(statsQuery, {
        success: data => {
          return (
            <>
              <div className={embed ? style.headerEmbed : style.header}>
                <h1>
                  {isOwner(data.loggedId, userId)
                    ? 'Grazie per aver trascorso il 2023 con noi 😍'
                    : `Il 2023 di ${data.name} 😍`}
                </h1>
              </div>
              <div className={embed ? style.containerEmbed : style.container}>
                <YearRecapCard
                  statsType={statsType}
                  onFirstOptionClick={() => setStatsType('last_year')}
                  onSecondOptionClick={() => setStatsType('total')}
                  data={data}
                  {...(borderType === 'square' && {
                    containerClass: style.squareBorder,
                  })}
                  {...(downloadOnOpen && {
                    onComponentMount: () => downloadImage(),
                  })}
                />
                <div className={style.row}>
                  {embed ? (
                    <button className={style.callToAction} onClick={async () => {
                      sendImageToBrowser()
                      const userData = await me()
                      if (userData.email) {
                        sendToGTM({
                          event: 'g4a_file_download',
                          detail: 'wrapped',
                          label: "2023",
                          user_data: {
                            email: userData.email,
                          },
                        })
                      }
                     }}>
                      <FontAwesomeIcon icon={faShareAlt} className='mr-3' /> Condividi
                    </button>
                  ) : isOwner(data.loggedId, userId) ? (
                    <>
                      <button className={style.callToAction} onClick={async () => {
                        downloadImage()
                        const userData = await me()
                        if (userData.email) {
                          sendToGTM({
                            event: 'g4a_file_download',
                            detail: 'wrapped',
                            label: "2023",
                            user_data: {
                              email: userData.email,
                            },
                          })
                        }
                      }}>
                        <FontAwesomeIcon icon={faDownload} className='mr-3' /> Scarica
                      </button>
                      <button
                        className={cx([style.callToAction, 'ml-4'])}
                        onClick={() => {
                          share({
                            type: 'external',
                            title: 'Condividi le statistiche del tuo 2023 su Learnn',
                            body: 'Guarda quanto ho imparato nel 2023 su Learnn 🔥',
                            url: `https://learnn.com/r/wrapped/?s=${userId}`,
                            onPress: async (social) => {
                              const userData = await me()
                              if (userData.email) {  
                                sendToGTM({
                                  event: 'g4a_share',
                                  detail: social,
                                  label: 'wrapped',
                                  user_data: {
                                    email: userData.email,
                                  },
                                })
                              }
                            } 
                          })
                        }
                        }>
                        <FontAwesomeIcon icon={faShareAlt} className='mr-3' /> Condividi
                      </button>
                    </>
                  ) : (
                    <button
                      className={cx([style.callToAction, 'ml-4'])}
                      onClick={() =>
                        data.loggedId
                          ? history.push(`/login/?appurl=/year-recap/${data.loggedId}`)
                          : window.location.replace('https://learnn.com/wrapped/')
                      }>
                      <FontAwesomeIcon icon={faShareAlt} className='mr-3' />{' '}
                      {data.loggedId ? 'Crea il tuo Wrapped' : 'Apprendi anche tu su Learnn'}
                    </button>
                  )}
                </div>
              </div>
              {isOwner(data.loggedId, userId) && (
                <Confetti
                  width={size.width ? size.width - 20 : 0}
                  height={size.height}
                  recycle={false}
                  numberOfPieces={1000}
                  tweenDuration={6000}
                />
              )}
            </>
          )
        },
        loading: () => (
          <div className={embed ? style.containerEmbed : style.container}>
            <LoaderCard />
          </div>
        ),
        error: error =>
          error.message === 'stats_error' ? (
            <div className={embed ? style.containerEmbed : style.container}>
              <ErrorCard />
            </div>
          ) : (
            <Redirect to='/login/?appurl=https://learnn.com/int/wrapped/' />
          ),
      })}
      {!embed && (
        <div className={style.footer}>Copyright © 2023 Learnn. Tutti i diritti riservati.</div>
      )}
    </div>
  )
}
