import { FC, useRef, useState } from 'react'
import { IVideoControls, IVideoReadOnlyState } from '../useReactPlayer'
import { formatSeconds } from '../utils'
import { Duration } from './Duration'

import style from './styles/scrubber.module.css'

export interface IScrubberProps {
  state: IVideoReadOnlyState
  controls: IVideoControls
  onSeek(by: number): void
}

function toWidth(n?: number): number {
  return Number(n) * 100
}

export const Scrubber: FC<IScrubberProps> = ({ state, controls, onSeek }) => {
  const progressBarRef = useRef<HTMLDivElement>(null)
  const [touchId, setTouchId] = useState(0)

  const positionToPercentage = (x: number): number => {
    if (!progressBarRef.current) return 0
    const { left, width } = progressBarRef.current.getBoundingClientRect()
    return (Number(x) - left) / width
  }

  const percentageToSeconds = (x: number): number => {
    return x * Number(state.duration)
  }

  const move = (x: number) => {
    const percentage = positionToPercentage(x)
    const seconds = percentageToSeconds(percentage)
    onSeek(Math.abs(seconds - Number(state.playedSeconds)))

    if (percentage >= 0 && percentage <= 1) {
      controls.setPlayed(percentage)
    }
  }

  const getTouch = (e: React.TouchEvent<HTMLDivElement>) =>
    Array.from(e.changedTouches).find(t => t.identifier === touchId)

  /* Mouse/Touch handling */
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (state.seeking) {
      move(e.clientX)
    }
  }

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    controls.setSeeking(false)
    controls.seekTo(state.played)
  }

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (state.seeking) {
      e.preventDefault()
    }

    const touch = getTouch(e)
    if (touch) {
      move(touch.clientX)
    }
  }

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    controls.setSeeking(true)
    move(e.clientX)
  }

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const touch = e.changedTouches[0]
    setTouchId(touch.identifier)
    controls.setSeeking(true)
  }

  const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    controls.setSeeking(false)
    controls.seekTo(state.played)
  }

  const mouseEvents = {
    onMouseDown,
    onTouchEnd,
    onMouseMove,
    onMouseUp,
    onTouchMove,
    onTouchStart,
    ref: progressBarRef,
  }

  /* Mouse/Touch events end */

  return (
    <div className={style.progressOuter} onClick={e => e.stopPropagation()}>
      <div className={style.progressContainer}>
        <div className={style.progressFlex}>
          <div className={style.scrubberContainer} {...mouseEvents}>
            <div className={style.scrubberBar}>
              <div className={style.track}>
                <div className={style.buffered} style={{ width: `${toWidth(state.loaded)}%` }} />
                <div
                  className={style.currentProgress}
                  style={{ width: `${toWidth(state.played)}%` }}
                />
              </div>
              <div className={style.dot} style={{ left: `${toWidth(state.played)}%` }} />
            </div>
          </div>
          <time className={`${style.time} ${style.played}`}>
            {formatSeconds(state.playedSeconds || 0)}
          </time>
          <Duration duration={state.duration} playedSeconds={state.playedSeconds} />
        </div>
      </div>
    </div>
  )
}
