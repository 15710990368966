import { createContextReducer } from '../../utils/createContext'
import {SearchExpert} from "@learnn/sdk/dist/api/smart-search"
export type PopupType =
  | {
      expert: (SearchExpert)
      show: true
    }
  | {
      show: false
    }

export const popupInitialState: PopupType = {
  show: false,
}

export type IPopupActions = PopupType & {}

function reducer(state: PopupType, newState: IPopupActions) {
  return { ...state, ...newState }
}

const [ctx, Provider] = createContextReducer<PopupType, IPopupActions>(reducer, popupInitialState)
export const ExpertPopupContext = ctx
export const ExpertPopupContextProvider = Provider
