import auth from '../utils/auth'
import api from './api'
import { graphqlClient } from './apollo'
import { restClient } from './rest'
import env from '../env.json'
import { LikeState } from '@learnn/sdk/dist/api/engagement'
import { LikeEngagementType } from '@learnn/sdk/src/api/engagement'
import { Profile } from '@learnn/sdk/src/api/profile'
import { RegistrationData, SocialProviders } from '@learnn/sdk/src/api/auth'
import { UserUtms } from '@learnn/sdk/src/api/account'
import { chargebeeInstance } from '../utils/chargebee'

import { EventType, UpolloClient } from '@upollo/web'

const upollo = new UpolloClient(env.UPOLLO_PUBLIC_KEY)

export type YearRecap = {
  user_id: string
  loggedId: string | null
  totalViewTime: number
  tier: number
  totalLessonStarted: number
  completedCourses: string[]
  startedCourses: string[]
  coursesVerified: string[]
  completedCoursesList: string[]
  startedCoursesList: string[]
  completedCoursesNumber: number
  startedCoursesNumber: number
} & Profile

export const postEmail = async (email: string, plan: string, gaClientId: string) => {
  const acceptedStatus = [200, 202]
  const contact = { contact: { email, plan, gaClientId } }
  try {
    const result = await restClient.jsonPost(`${env.ANALYTICS_URL}/contacts/signup`, contact)

    if (!result || !acceptedStatus.includes(result.status)) {
      throw new Error("Errore durante l'invio dell'email")
    }
    return result
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createAccount = async (email: string, password: string, gaClientId: string) => {
  try {
    const result = await restClient.jsonPost(`${env.CHECKOUT_API_URL}/signup/queue/prepare`, {
      queue: { email, password, gaClientId },
    })
    if (!result || result.status !== 200) {
      throw new Error("Errore durante la creazione dell'account")
    }
    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const createFreeAccount = async (data: RegistrationData) => {
  const acceptedStatus = [200, 401, 409]

  try {
    const { auth: authentication } = await api
    const response = await authentication.signupV2(data)

    if (!response || !acceptedStatus.includes(response.status)) {
      throw new Error("Errore durante la creazione dell'account")
    } else {
      if (response.status === 200) {
        graphqlClient.resetStore()

        auth.setToken(response.body.jwt, true)
        await authentication.createKeycloakSession()
      }
      return response
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const login = async (email: string, password: string) => {
  try {
    const { auth: authentication } = await api

    const response = await authentication.login({ email, password })

    graphqlClient.resetStore()

    auth.setToken(response.jwt, true)
    auth.setRefreshToken(response.refresh_token, true)
    auth.setSessionState(response.session_state, true)
    await authentication.createKeycloakSession()

    await upollo.track(
      { userId: response.userId, userEmail: response.email },
      EventType.EVENT_TYPE_LOGIN_SUCCESS,
    )

    return response
  } catch (e) {
    throw e
  }
}

export const socialLogin = async (social: SocialProviders, redirectUrl: string) => {
  try {
    const { auth: authentication } = await api

    const response = await authentication.getSocialSsoUrl(social, redirectUrl)

    return response
  } catch (e) {
    throw e
  }
}

export const magicLinkLogin = async (redirectUrl: string, code: string) => {
  try {
    const { auth: authentication } = await api
    const response = await authentication.getTokenFromAutorizationCode(redirectUrl, code)
    graphqlClient.resetStore()

    auth.setToken(response.jwt, true)
    auth.setRefreshToken(response.refresh_token, true)
    await authentication.createKeycloakSession()

    return response
  } catch (e) {
    throw e
  }
}

export const sendMagicLink = async (email: string, redirectUrl: string) => {
  try {
    const { auth: authentication } = await api

    const response = await authentication.sendMagicLink(email, redirectUrl)

    return response
  } catch (e) {
    throw e
  }
}

export const updateUtm = async (utmParams: UserUtms) => {
  try {
    const { account } = await api
    await account.updateUtm(utmParams)
  } catch (e) {
    throw e
  }
}

export const refreshToken = async () => {
  try {
    const { auth: authentication } = await api
    const refreshToken = auth.getRefreshToken() as string

    const response = await authentication.refreshToken(refreshToken)

    auth.setToken(response.jwt, true)
    auth.setRefreshToken(response.refresh_token, true)
    await authentication.createKeycloakSession()

    return response
  } catch (e) {
    throw e
  }
}

export const logout = async () => {
  try {
    const { auth: authentication } = await api
    const refreshToken = auth.getRefreshToken() as string

    await authentication.logout(refreshToken)
    auth.clearAppStorage()
    auth.clearToken()
    auth.clearRefreshToken()
    chargebeeInstance.logout()
    delete window.ACUserData
  } catch (e) {
    throw e
  }
}

export const deleteAccount = async () => {
  try {
    const { account } = await api

    const response = await account.deleteAccount()

    if (!response) {
      throw new Error("Errore durante l'eliminazione dell'account")
    }

    return response
  } catch (e) {
    throw e
  }
}

export const me = async () => {
  try {
    const { auth: authentication } = await api

    const response = await authentication.me()

    return response
  } catch (e) {
    throw e
  }
}

export const self = async () => {
  try {
    const { auth: authentication } = await api

    const response = await authentication.self()

    return response
  } catch (e) {
    throw e
  }
}

export const selfOffline = async () => {
  try {
    const { auth: authentication } = await api

    const response = await authentication.selfOffline()

    return response
  } catch (e) {
    throw e
  }
}

export const forgotPassword = async (email: string) => {
  try {
    const { auth: authentication } = await api

    const response = await authentication.forgotPassword(email)

    return response
  } catch (e) {
    throw e
  }
}

export const membershipToken = async () => {
  const requestUrl = `${env.MY_API_URL}/identity/create-membership-token`
  const apiToken = auth.getToken()
  try {
    const result = await restClient.jsonPost(requestUrl, {
      apiToken,
      redirectUrl: env.CHARGEBEE_REDIRECT_URL,
    })
    if (!result || (result.status !== 200 && result.status !== 404 && result.status !== 457)) {
      throw new Error('Errore durante il la richiesta del token')
    }
    return result
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCBPaymentData = async () => {
  const requestUrl = `${env.MY_API_URL}/identity/check-payment-method`
  const apiToken = auth.getToken()
  try {
    const result = await restClient.jsonPost(requestUrl, { apiToken })
    if (!result || result.status !== 200) {
      throw new Error('Errore durante la verifica del metodo di pagamento')
    }
    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getActiveCampaignData = async () => {
  const authToken = auth.getToken()

  try {
    const result = await restClient.jsonGet(`${env.MY_API_URL}/crm/contact`, {
      Authorization: `Bearer ${authToken}`,
    })
    if (!result) {
      throw new Error('Cannot get AC Data')
    }
    if (result.status !== 200) {
      throw new Error(result.body.message)
    }
    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const firstPromoterDashboard = async () => {
  const apiToken = auth.getToken()
  try {
    const result = await restClient.jsonGet(
      `${env.MY_API_URL}/services/referral/dashboard/${apiToken}`,
    )
    if (!result) {
      throw new Error('Cannot load dashboard')
    }
    if (result.status !== 200) {
      throw new Error(result.body.message)
    }
    return result.body
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getConfiguration = async () => {
  const { configuration } = await api

  const config = await configuration.getWeb()

  return config
}

export const likeEngagement = async (
  type: LikeEngagementType,
  lessonId: string,
  state: LikeState,
) => {
  const { engagement } = await api

  const response = await engagement.likeEngagement(type, lessonId, state)

  return response
}

export const generateTokenFromAutologinToken = async (token: string) => {
  try {
    const { auth: authentication } = await api
    const result = await authentication.generateTokenFromAutologinToken(token)

    await graphqlClient.resetStore()

    auth.setToken(result, true)
    await authentication.createKeycloakSession()

    return result
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const generateAutologinToken = async () => {
  try {
    const { auth: authentication } = await api
    const result = await authentication.generateAutologinToken()
    return result
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const getYearRecap = async (
  userId: string,
  statsType: 'last_year' | 'total',
): Promise<YearRecap> => {
  let wrapped

  try {
    const { profile } = await api
    const result = await profile.get(userId)

    wrapped = { ...result }
  } catch (error) {
    console.log(error)
    throw new Error('missing_profile_data')
  }

  try {
    const user = await me()

    wrapped = { ...wrapped, loggedId: String(user.userId) }
  } catch (error) {
    console.log(error)
    wrapped = { ...wrapped, loggedId: null }
  }

  try {
    const result = await restClient.jsonGet(
      `${env.MY_API_URL}/yearly-recap/${userId}/?type=${statsType}`,
    )

    if (!result || result.status !== 200) {
      throw new Error('stats_error')
    }

    const data = result.body

    wrapped = {
      ...wrapped,
      ...data,
      completedCoursesList: data.completedCourses.slice(0, 5),
      startedCoursesList: data.startedCourses.slice(0, 5),
      totalViewTime: data.totalViewTime ? data.totalViewTime : 0,
      completedCoursesNumber: data.completedCoursesNumber ?? 0,
      startedCoursesNumber: data.startedCoursesNumber ?? 0,
    }
  } catch (e) {
    console.error(e)
    throw e
  }

  return wrapped
}

export const getCurrentSubscription = async () => {
  const { billing } = await api

  return await billing.getCurrentSubscription()
}

export const getCurrentCustomerInfo = async () => {
  const { billing } = await api

  return await billing.getCurrentCustomerInfo()
}
