import { FC } from 'react'
import { ResponsiveImage } from '../ResponsiveImage'
import style from './verifiedStatusCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCheck, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { VerifiedState } from '@learnn/sdk/dist/api/quiz'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import { toReadableDate } from '../../utils/time'

export interface IVerifiedCard {
  verified: VerifiedState
}

export const VerifiedStatusCard: FC<IVerifiedCard> = ({ verified }) => {
  const history = useHistory()

  const switchBadge = () => {
    switch (verified.status) {
      case 'available':
        return (
          <span className={cx([style.badge, style[verified.status]])}>
            Inizia la verifica <FontAwesomeIcon icon={faCaretRight} className={style.icon} />
          </span>
        )
      case 'passed':
        return (
          <span className={cx([style.badge, style[verified.status]])}>
            Passato <FontAwesomeIcon icon={faCheck} className={style.icon} />
          </span>
        )
      case 'failed':
        if (verified.quizStatus.retryDate >= new Date())
          return (
            <span className={cx([style.badge, style[verified.status]])}>
              {`Riprova il ${toReadableDate(verified.quizStatus.retryDate)}`}
            </span>
          )
        else
          return (
            <span className={cx([style.badge, style[verified.status]])}>
              Ritenta <FontAwesomeIcon icon={faRotateRight} className={style.icon} />
            </span>
          )
      case 'unavailable':
        return (
          <span className={cx([style.badge, style[verified.status]])}>
            Completa il corso <FontAwesomeIcon icon={faCaretRight} className={style.icon} />
          </span>
        )
    }
  }

  return (
    <div
      className={cx([style.card])}
      onClick={() => {
        if (verified.status === 'available') history.push(`/test/corso/${verified.courseId}`)
        else history.push(`/corso/${verified.courseId}`)
      }}>
      <div className={style.cardInner}>
        <div className={style.imageContainer}>
          <ResponsiveImage wrapperClassName={style.imageCover} src={verified.course.coverImage} />
        </div>
        <div className={style.infoContainer}>
          <p className={style.title}>{verified.course.title}</p>
          <p className={style.author}>{`10 DOMANDE`}</p>
          {switchBadge()}
        </div>
      </div>
    </div>
  )
}
