import { motion } from "framer-motion";

import style from "./loadingIndicator.module.css";

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingCircleVariants = {
  start: {
    y: "0%",
  },
  end: {
    y: "100%",
  },
};

const loadingCircleTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: "easeInOut",
};

export const LoadingIndicator = () => {
  return (
    <motion.div className={style.loadingContainer} variants={loadingContainerVariants} initial="start" animate="end">
      <motion.span className={style.loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span className={style.loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span className={style.loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
    </motion.div>
  );
};
