import { FC } from 'react'
import style from './styles/triggerButton.module.css'
import cx from 'classnames'
export interface ITriggerButton {
  image: any
  activeImage?: any
  width?: number
  height?: number
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
  className?: string
  classNameImage?: string
  active?: boolean
}

export const TriggerButton: FC<ITriggerButton> = ({
  onClick,
  image,
  activeImage = null,
  width,
  height,
  className,
  classNameImage,
  active,
}) => {
  return (
    <button
      className={cx([style.triggerButton, className])}
      onClick={e => {
        e.stopPropagation()
        onClick(e)
      }}>
      {!active && (
        <div
          className={cx([style.image, classNameImage])}
          style={{
            backgroundImage: `url(${image}`,
            ...(width && { width: width }),
            ...(height && { height: height }),
          }}
        />
      )}
      {activeImage && active ? (
        <div
          className={cx([style.image, classNameImage])}
          style={{
            backgroundImage: `url(${activeImage}`,
            ...(width && { width: width }),
            ...(height && { height: height }),
          }}
        />
      ) : null}
    </button>
  )
}
