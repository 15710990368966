import moment from 'moment';
export const DEFAULT_WALL_LIMIT = 1000;
export const COURSE_COMPLETED_THRESHOLD = 80;
export const LESSON_COMPLETED_THRESHOLD = 50;
export const VERIFIED_AVAILABLE_THRESHOLD = 80;
export const extractFirstAuthor = (authors) => {
    if (!authors || authors.length === 0) {
        return null;
    }
    let author = { ...authors[0] };
    if (author.avatar)
        author.avatar = extractThumbUrlFromImage(author.avatar[0], 'large');
    return author;
};
export const extractCourseAuthorAndProfession = (author) => {
    if (!author) {
        return { authorName: '', profession: '', avatar: null };
    }
    const profession = author.profession ? author.profession : '';
    const authorName = `${author.firstName} ${author.lastName}`;
    const avatar = extractThumbUrlFromImage(author.avatar, 'medium') ?? null;
    return { authorName, profession, avatar };
};
export const extractThumbUrlFromImage = (image, format) => {
    if (!image) {
        return;
    }
    if (format && format === 'fullsize' && image.url) {
        return image.url;
    }
    if (format && image.formats && image.formats[format]) {
        return image.formats[format].url;
    }
    if (image.formats?.thumbnail) {
        return image.formats?.thumbnail.url;
    }
    if (image.formats?.small) {
        return image.formats?.small.url;
    }
    if (image.formats?.medium) {
        return image.formats?.medium.url;
    }
    if (image.formats?.large) {
        return image.formats?.large.url;
    }
    return image.urlCdn;
};
export const extractVideoUrlFromAwsMedia = (awsMedia) => {
    if (!awsMedia) {
        return;
    }
    if (awsMedia.aws) {
        if (awsMedia.aws.hlsUrl) {
            return awsMedia.aws.hlsUrl;
        }
        if (awsMedia.aws.egressEndpoints) {
            if (awsMedia.aws.egressEndpoints.HLS) {
                return awsMedia.aws.egressEndpoints.HLS;
            }
            if (awsMedia.aws.egressEndpoints.CMAF) {
                return awsMedia.aws.egressEndpoints.CMAF;
            }
        }
    }
    return awsMedia.urlCdn;
};
export const extractAudioUrlFromAwsMedia = (awsMedia) => {
    if (!awsMedia) {
        return;
    }
    if (awsMedia.aws) {
        if (awsMedia.aws.mp3Urls && awsMedia.aws.mp3Urls.length > 0) {
            return awsMedia.aws.mp3Urls[0];
        }
    }
    return extractVideoUrlFromAwsMedia(awsMedia);
};
export function getYoutubeVideoId(url) {
    const regex = /(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/gim;
    const match = regex.exec(url);
    if (match && match.length > 0 && match[3])
        return match[3];
    throw new Error('Malformed youtube url: ' + url);
}
export const getLessonType = (l) => {
    if (l.awsMedia && l.awsMedia.aws)
        return 'media';
    else
        return 'text';
};
export const isLessonStarted = (progress) => {
    return progress > 0;
};
export const isLessonCompleted = (progress) => {
    return progress >= LESSON_COMPLETED_THRESHOLD;
};
export const isModuleStarted = (startedLessons) => {
    return startedLessons > 0;
};
export const isModuleCompleted = (completedLessons, totalLessons) => {
    return completedLessons === totalLessons;
};
export const isCourseStarted = (startedLessons) => {
    return startedLessons > 0;
};
export const isCourseInProgress = (progress) => {
    return progress && progress > 0;
};
export const isVerifiedAvailable = (progress) => {
    return progress >= VERIFIED_AVAILABLE_THRESHOLD;
};
export const isCourseCompleted = (completedLessons, totalLessons) => {
    const percentage = (completedLessons / totalLessons) * 100;
    return percentage >= COURSE_COMPLETED_THRESHOLD;
};
export const isCourseCompletedByPercentage = (progress) => {
    return progress >= COURSE_COMPLETED_THRESHOLD;
};
export const calculateProgress = (completed, total) => {
    return Math.round((completed / total) * 100);
};
export const calculateLessonDuration = (secs) => {
    if (!secs) {
        return '';
    }
    if (secs < 60)
        return `1 min`;
    const m = Math.trunc(secs / 60);
    return `${m} min`;
};
export const array2map = (keyf) => (arr) => {
    return arr.reduce(function (map, obj) {
        const key = keyf(obj);
        map[key] = obj;
        return map;
    }, {});
};
export const hideDraft = (c) => {
    return !c.draft;
};
export const hideAwsNull = (c) => {
    return !(c.defaultMedia === 'awsMediaVideo' && (!c.awsMedia || !c.awsMedia.aws));
};
export const distinct = (xs) => Array.from(new Set(xs));
export const sortLabels = (a, b) => {
    if (a == null || a == undefined)
        return 1;
    if (b == null || b == undefined)
        return -1;
    if (a < b)
        return -1;
    if (a > b)
        return 1;
    return 0;
};
export const zeroPad = (num, places) => String(num).padStart(places, '0');
export const getTextDate = (stringDate, format = 'long') => {
    if (!stringDate)
        return null;
    const date = new Date(stringDate);
    const day = date.getDate();
    const month = getMonthName(date.getMonth(), format);
    return `${day} ${month}`;
};
export const getTextTime = (stringDate) => {
    if (!stringDate)
        return null;
    const time = moment(stringDate, 'HH:mm').format('HH:mm');
    return time;
};
export const getTextDateWithYearAndTime = (stringDate, format = 'long') => {
    const date = new Date(stringDate);
    const day = date.getDate();
    const month = getMonthName(date.getMonth(), format);
    const year = date.getFullYear();
    const time = moment(date).format('HH:mm');
    return `${day} ${month} ${year} - ${time}`;
};
export const getTextDateWithTime = (stringDate, format = 'long') => {
    const date = new Date(stringDate);
    const day = date.getDate();
    const month = getMonthName(date.getMonth(), format);
    const time = moment(date).format('HH:mm');
    return `${day} ${month} - ${time}`;
};
export const getTextDateWithYear = (stringDate, format = 'long') => {
    if (stringDate === null || stringDate === undefined)
        return '';
    const date = new Date(stringDate);
    const day = date.getDate();
    const month = getMonthName(date.getMonth(), format);
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
};
export const getTextDateFull = (stringDate, format = 'long') => {
    const date = new Date(stringDate);
    const day = date.getDate();
    const month = getMonthName(date.getMonth(), format);
    const year = date.getFullYear();
    const hours = zeroPad(date.getHours(), 2);
    const minutes = zeroPad(date.getMinutes(), 2);
    return `${day} ${month} ${year} ${hours}:${minutes}`;
};
export const getObjectDate = (stringDate, format = 'long') => {
    if (!stringDate)
        return null;
    const date = new Date(stringDate);
    const day = date.getDate();
    const month = getMonthName(date.getMonth(), format);
    const year = date.getFullYear();
    const hours = zeroPad(date.getHours(), 2);
    const minutes = zeroPad(date.getMinutes(), 2);
    return { day: day < 10 ? '0' + day : day, month, year, hours, minutes };
};
export const getObjectDay = (stringDate, format = 'long') => {
    if (!stringDate)
        return null;
    const date = new Date(stringDate);
    const day = date.getDate();
    const month = getMonthName(date.getMonth(), format);
    const year = date.getFullYear();
    return { day: day < 10 ? '0' + day : day, month, year };
};
export function getMonthName(month, format) {
    const MONTHS = [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
    ];
    if (MONTHS[month] !== undefined) {
        if (format === 'short') {
            return MONTHS[month].substring(0, 3);
        }
        return MONTHS[month];
    }
    else {
        throw 'Invalid month number';
    }
}
export const addDaysToDate = (date, days) => {
    const attemptUnlockDate = new Date(date);
    attemptUnlockDate.setDate(attemptUnlockDate.getDate() + days);
    attemptUnlockDate.setHours(0, 0, 0);
    return attemptUnlockDate;
};
export const parseVtt = (raw) => {
    if (!raw)
        return [];
    const divideBlocks = /((?:^[0-9])+(?:.|\n.|\r\n.)+)/gm;
    const divideTextToTimestamp = /((?:[0-9]|:|\..)+) --> ((?:[0-9]|:|\.)+)(?:.*)(?:\n|\r\n)((?:.|\n.|\r\n.)+)/gm;
    const blocks = raw.match(divideBlocks);
    if (!blocks) {
        return [];
    }
    const editedSubtitle = blocks.map((block, index) => {
        const subtitle = block.matchAll(divideTextToTimestamp).next().value;
        return {
            id: index + 1,
            start: stringToSeconds(subtitle[1]),
            stop: stringToSeconds(subtitle[2]),
            text: subtitle[3],
        };
    });
    if (!editedSubtitle) {
        return [];
    }
    return editedSubtitle;
};
const stringToSeconds = (time) => {
    const parts = time.split(':');
    let hours = parseInt(parts[0]);
    let minutes = parseInt(parts[1]);
    let secondsAndMilliseconds = parts[2].split('.');
    let seconds = parseInt(secondsAndMilliseconds[0]);
    let milliseconds = parseInt(secondsAndMilliseconds[1]);
    return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
};
export const Normalize = (data) => {
    const isObject = (dataObj) => Object.prototype.toString.call(dataObj) === '[object Object]';
    const isArray = (dataObj) => Object.prototype.toString.call(dataObj) === '[object Array]';
    const flatten = (dataObj) => {
        if (!dataObj.attributes) {
            return dataObj;
        }
        return {
            id: dataObj.id,
            ...dataObj.attributes,
        };
    };
    if (isArray(data)) {
        return data.map((item) => Normalize(item));
    }
    if (isObject(data)) {
        if (isArray(data.data)) {
            data = JSON.parse(JSON.stringify(data.data));
        }
        else if (isObject(data.data)) {
            data = flatten({ ...data.data });
        }
        else if (data.data === null) {
            data = null;
        }
        else {
            data = flatten(data);
        }
        for (const key in data) {
            data[key] = Normalize(data[key]);
        }
        return data;
    }
    return data;
};
export const normalizeGraphqlResponse = (response) => {
    let data = JSON.parse(JSON.stringify(response));
    const res = { data: Normalize(data) };
    return res;
};
export const apolloWatchQueryWrapper = async (_observable) => {
    return new Promise((resolve, reject) => {
        const observable = _observable;
        observable.subscribe({
            next: d => {
                resolve(normalizeGraphqlResponse(d));
            },
            error: reject,
        });
        return null;
    });
};
export const isUserTeamMember = (user) => user.teamId && user.teamId && user.teamRole === 'member';
