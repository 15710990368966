import { IFrame } from '../../components/IFrame/IFrame'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export const LandingOfflineScreen = () => {
  return (
    <AppLayout fullwidth disableMargin hideHeader>
      <IFrame url='https://learnn.com/offline/' fullHeight />
    </AppLayout>
  )
}
