import { FC } from "react";
import { QuizAttempt } from "@learnn/sdk/src/api/quiz/";
import passedImage from "../../assets/images/icons/passed_quiz.svg";
import notPassedImage from "../../assets/images/icons/failed_quiz.svg";
import style from "./attemptCard.module.css";
import cx from "classnames";

import env from "../../env.json";
import { getTextDateWithYear } from "@learnn/sdk/dist/api/utils";

export interface IAttemptCard {
  attempt: QuizAttempt;
  best?: boolean;
  onClick?(): void;
}

export const AttemptCard: FC<IAttemptCard> = ({ attempt, onClick, best }) => {
  return (
    <div
      className={cx([attempt.passed === false ? style.container : style.clickableContainer, best ? style.focused : ""])}
      onClick={() => {
        if (onClick && attempt.submittedAt) {
          onClick();
        }
      }}>
      {attempt.passed ? (
        <>
          <div className={style.details}>
            <div className={style.imageContainer}>
              <img src={attempt.passed ? passedImage : notPassedImage} className={style.image} />
            </div>

            <div className={style.detailItem}>
              <div className={style.detailTitle}>Punteggio</div>
              <span className={style.detailResult}>{`${Math.round((attempt.correctAnswers / attempt.totalQuestions) * 100)}%`}</span>
            </div>
            <div className={style.detailItem}>
              <div className={style.detailTitle}>Data tentativo</div>
              <span className={style.detailResult}>{getTextDateWithYear(attempt.startedAt, "short")}</span>
            </div>
          </div>

          <div className={style.buttonContainer}>
            {best ? (
              <a href={`${env.QUIZ_CERTIFICATION_URL}${attempt.attemptId}`} target="_blank" className={style.certificationButton}>
                Mostra <span className={style.hideOnMobile}>Verifica</span>
              </a>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className={style.details}>
            <div className={style.imageContainer}>
              <img src={attempt.passed ? passedImage : notPassedImage} className={style.image} />
            </div>
            <div className={style.detailItem}>
              <div className={style.detailTitle}>Punteggio</div>
              <span className={style.detailResult}>{`${Math.round((attempt.correctAnswers / attempt.totalQuestions) * 100)}%`}</span>
            </div>
            <div className={style.detailItem}>
              <div className={style.detailTitle}>Data tentativo</div>
              <span className={style.detailResult}>{getTextDateWithYear(attempt.startedAt, "short")}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
