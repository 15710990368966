import {
  ComponentSectionDynamicQuiz,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/dist/api/dynamic-sections/types'
import * as F from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { VerifiedCard } from '../VerifiedCard'
import { useHistory } from 'react-router-dom'
import { QuizCard } from '../QuizCard'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'

export interface QuizCarouselProps {
  data: DynamicSectionsValue<DynamicSectionsError, ComponentSectionDynamicQuiz>
  onShowAll(type: 'verified' | 'dailyQuiz'): void
}

export const QuizCarousel = ({ data }: QuizCarouselProps) => {
  const history = useHistory()

  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const render = (section: ComponentSectionDynamicQuiz) => {
    return (
      <CustomCarousel
        title={section.title}
        data={section.data}
        carouselKind='verified'
        renderSlide={item => {
          switch (section.quizEntity) {
            case 'dailyQuiz':
              return (
                <QuizCard
                  format='small'
                  quiz={item}
                  onClick={() => history.push(`/sfide/${item.dailyQuizId}`)}
                />
              )
            case 'verified':
              return (
                <VerifiedCard quiz={item} onClick={() => history.push(`/test/corso/${item.id}`)} />
              )
          }
        }}
      />
    );
  }

  return F.pipe(
    data,
    O.fold(() => CustomCarouselLoading('verified'), data =>
      F.pipe(data, E.fold(error, render))
    )
  )
}
