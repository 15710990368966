import { createContextReducer } from '../../utils/createContext'

export type ShareSubjectType =
  | 'lesson'
  | 'course'
  | 'webinar'
  | 'comingContent'
  | 'note'
  | 'external'
  | 'profile'
export type ShareSocialType = 'whatsapp' | 'linkedin' | 'facebook' | 'email'

export type ShareType =
  | {
      type: ShareSubjectType
      url: string
      body: string
      title: string
      show: true
      onPress?: (social: ShareSocialType) => unknown
    }
  | {
      show: false
    }

export const shareInitialState: ShareType = {
  show: false,
}

export type IShareActions = ShareType & {}

function reducer(state: ShareType, newState: IShareActions) {
  return { ...state, ...newState }
}

const [ctx, Provider] = createContextReducer<ShareType, IShareActions>(reducer, shareInitialState)
export const ShareContext = ctx
export const ShareContextProvider = Provider
