import { Verified } from "./Verified";
import { useBodyClass } from "../../utils/hooks";
import {AppLayout} from "../../layouts/AppLayout/AppLayout";

export const VerifiedScreen = () => {
  useBodyClass("app");
  return (
    <AppLayout>
      <Verified />
    </AppLayout>
  );
};
