import { ButtonHTMLAttributes, FC } from "react";
import cx from "classnames"
import style from "./button.module.css";

type ButtonType = {
  scheme?: "light" | "dark";
  small?: boolean;
}

export const Button: FC<ButtonHTMLAttributes<HTMLButtonElement> & ButtonType> = ({
  children = "",
  className,
  scheme = "light",
  small,
  ...props
}) => {
  return (
    <button className={cx([style.button, className])} data-type={scheme}  data-size={small ? "small" : ""} {...props}>
      {children}
    </button>
  );
};
