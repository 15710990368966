import { FC } from 'react'
import ReactPlayer from 'react-player'

import style from './videoPlayer.module.css'
import { useReactPlayer } from '../LessonPlayer/useReactPlayer'

export interface IVideoPlayer {
  url: string
  startTime?: number
}

export const VideoPlayer: FC<IVideoPlayer> = ({ url, startTime = 0 }) => {
  const { ref, reactPlayerProps, controls } = useReactPlayer({
    playedSeconds: startTime,
    played: startTime,
  })

  return (
    <div className={style.playerWrapper}>
      <ReactPlayer
        ref={ref}
        url={url}
        className={style.reactPlayer}
        {...reactPlayerProps}
        width='100%'
        height='100%'
        controls
        onReady={() => (startTime ? controls.seekTo(startTime) : null)}
      />
    </div>
  )
}
