import { createContextReducer } from "../../../utils/createContext";

export interface IFullscreenState {
  fullscreen: boolean;
}

export const playerControlsInitialState: IFullscreenState = {
  fullscreen: false,
};

export interface IFullscreenActions extends IFullscreenState {}

function reducer(state: IFullscreenState, newState: IFullscreenActions) {
  return { ...state, ...newState };
}

const [ctx, Provider] = createContextReducer<IFullscreenState, IFullscreenActions>(reducer, playerControlsInitialState);
export const FullscreenContext = ctx;
export const FullscreenContextProvider = Provider;
