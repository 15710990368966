import {FC} from "react"
import {InfiniteQuizState} from "./useInfiniteQuizControllers";
import style from './styles/infiniteQuizStreakLose.module.css'
import cx from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import env from "../../env.json";

export type InfiniteQuizSummaryProps = {
  finalState: InfiniteQuizState,
  onCloseClick: () => unknown
}
export const InfiniteQuizStreakLose: FC<InfiniteQuizSummaryProps> = ({finalState, onCloseClick}) => {
  return (
    <div
      className={style.popupContainer}
      onClick={event => {
        event.preventDefault()
        onCloseClick()
      }}>
      <div className={style.content}>
        <FontAwesomeIcon icon={faTimes} className={style.closeIcon} onClick={() => onCloseClick()}/>
        <div
          className={cx([style.box])}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          <img src={env.QUIZ_NOT_PASSED_IMAGE_URL} className={style.feedbackImage}/>
          <h1 className={style.title}>Peccato, Hai perso la streak!</h1>
          <p className={style.description}>
            Riprova per battere il tuo record
          </p>

          <div className={style.detailGrid}>
            <div className={style.detailItem}>
              <div className={style.detailTitle}>Risposte Corrette</div>
              <div className={style.detailValue}>{finalState.currentAnswersStreak}</div>
            </div>
            <div className={style.detailItem}>
              <div className={style.detailTitle}>Prossimo Obiettivo</div>
              <div className={style.detailValue}>{finalState.correctAnswersToReach}</div>
            </div>
          </div>

          <button
            className={style.ctaButton}
            onClick={onCloseClick}>
            Riprova
          </button>
        </div>
      </div>
    </div>
  )
}