import style from './styles/infiniteQuizProgressBar.module.css'
import { InfiniteQuizState } from './useInfiniteQuizControllers'

import flag from '../../assets/images/icons/feedback.svg'

export type InfiniteQuizProgressBarProps = {
  quizState: InfiniteQuizState
}
export const InfiniteQuizProgressBar = ({ quizState }: InfiniteQuizProgressBarProps) => {
  return (
    <div className={style.progressContainer}>
      <div className={style.progressBar}>
        {quizState.correctAnswersToReach &&
          Array.from({ length: quizState.correctAnswersToReach }).map((_v, index: number) => (
            <div className={style.progressItemContainer}>
              <div
                key={`streak-item-${index}`}
                className={
                  index + 1 > quizState.currentAnswersStreak
                    ? style.progressQuestion
                    : style.doneProgressQuestion
                }
              />
            </div>
          ))}
      </div>
      <div className={style.goal}>
        <span>{quizState.correctAnswersToReach}</span>
        <img className={style.goalIcon} src={flag} />
      </div>
    </div>
  )
}
