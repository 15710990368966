import { useContext } from 'react'
import { PathPopupContext } from './popupContext'
import { Path } from '@learnn/sdk/dist/api/paths/types'

export const usePathPopup = () => {
  const { dispatch, state: prevState } = useContext(PathPopupContext)

  return {
    show: (path: Path) => {
      dispatch({
        path,
        show: true,
      })
    },
    close: () => {
      dispatch({
        ...prevState,
        show: false,
      })
    },
  }
}
