import { FC, useContext, useState, useEffect } from 'react'
import { IVideoControls, IVideoReadOnlyState } from '../useReactPlayer'
import { useHistory } from 'react-router-dom'
import { Scrubber } from './Scrubber'
import style from './styles/videoControls.module.css'
import { TriggerButton } from './TriggerButton'
import { PlaybackRate } from './PlaybackRate'
import Play from '../../../assets/images/icons/play.svg'
import Pause from '../../../assets/images/icons/pause.svg'
import ForwardSeconds from '../../../assets/images/icons/forward15.svg'
import BackwardSeconds from '../../../assets/images/icons/backward15.svg'
import backIcon from '../../../assets/images/icons/back_arrow.svg'
import skipNext from '../../../assets/images/icons/skip-next.svg'
import subtitlesIcon from '../../../assets/images/icons/subtitles.svg'
import subtitlesActiveIcon from '../../../assets/images/icons/subtitles_active.svg'
import Draggable from 'react-draggable'
import { useVideoControls } from './useVideoControls'
import { NextLesson } from './NextLesson'
import { ControlsContext } from './controlsContext'
import { FeedbackPopup } from '../../FeedbackPopup'
import { MyLearnnButton } from '../../MyLearnnButton'
import { GlobalProviderContext } from '../../GlobalProvider'
import { extractConfiguration } from '../../../utils/data'
import { motion } from 'framer-motion'
import cx from 'classnames'
import fullscreenIcon from '../../../assets/images/icons/fullscreen.svg'
import sidebarOpen from '../../../assets/images/icons/sidebar.svg'
import sidebarClose from '../../../assets/images/icons/sidebar-open.svg'
import { useFullscreenWrapper } from './useFullscreenWrapper'
import { Share } from '../../Share'
import env from '../../../env.json'
import { useShare } from '../../SharePopup/useShare'
import { isMobile } from 'react-device-detect'
import { IconButton } from '../../IconButton'
import { LikeState } from '@learnn/sdk/dist/api/engagement'
import { useSubtitles } from '../useSubtitles'
import { Vtt } from '@learnn/sdk/src/api/utils'
import { usePlayerSide } from '../PlayerSideContent/usePlayerSide'
import { useKeyboardControls } from './useKeyboardControls'
import { LessonTags } from '@learnn/sdk/src/api/course'
import { DiscussionButton } from './DiscussionButton'

export interface VideoControlsCourseInfo {
  moduleId: string
  lessonName: string
  lessonTags: LessonTags[]
  courseName: string
  lessonDuration: number
  lessonCompleted: boolean
  lessonId: string
  myLearnnId: string | undefined
  courseId: string
  description: string
  circlePostUrl?: string
  nextLessonId: string | undefined
  currentViewTime: number
  maxViewTime: number
  likeState: LikeState
  subtitles: Vtt[]
  transcript?: string
}

export interface VideoControlsProps {
  state: IVideoReadOnlyState
  controls: IVideoControls
  courseInfo: VideoControlsCourseInfo
  analytics: AnalyticsProps
  lessonCompleted: boolean
  subtitles: Vtt[]
}

export type AnalyticsProps = {
  onNextLesson(): void
  onChangeSpeed(rate: number): void
  onShowVideoDescription(): void
  onFullscreen(): void
  onForward(): void
  onBack(): void
  onSeek(by: number): void
  onShareClick(): void
  logSidebarToggle(on: boolean, course_id: string, module_id: string, lesson_id: string): void
  onToggleSubtitles(enabled: boolean): void
  logCommunityOpenLesson(course_id: string, lesson_id: string): void
}

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

export const VideoControls: FC<VideoControlsProps> = ({
  controls,
  state,
  courseInfo,
  analytics,
  subtitles,
}) => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const { state: playerSideState, toggle: togglePlayerSide } = usePlayerSide()
  const {
    togglePlay,
    seekForward,
    setPlaybackRate,
    seekBackward,
    goToNextLesson,
    togglePopup,
    dismissNextLesson,
    dismissEndLesson,
    toggleFeedback,
    toggleSubtitles,
  } = useVideoControls(controls, state, courseInfo)

  const { toggleFullscreen, toggleMobileFullscreen, isFullscreen } = useFullscreenWrapper()
  const { share } = useShare()
  const history = useHistory()
  useKeyboardControls(controls, state)

  const {
    onNextLesson,
    onChangeSpeed,
    onFullscreen,
    onForward,
    onBack,
    onSeek,
    onToggleSubtitles,
    logSidebarToggle,
    onShareClick,
    logCommunityOpenLesson,
  } = analytics
  const [controlsVisible, setControlsVisible] = useState(true)
  const { state: controlsState } = useContext(ControlsContext)
  const { currentBlock } = useSubtitles(subtitles, state.playedSeconds ?? 0)

  useEffect(() => {
    let timeout: any
    const showControls = () => {
      const duration = 3000
      setControlsVisible(true)
      clearTimeout(timeout)
      if (
        !controlsState.descriptionVisible &&
        !controlsState.noteVisible &&
        !controlsState.feedbackPopup.visible &&
        !controlsState.browserVisible &&
        !controlsState.nextLessonVisible
      ) {
        timeout = setTimeout(function () {
          setControlsVisible(false)
        }, duration)
      }
    }

    window.addEventListener('mousemove', showControls)
    return () => {
      window.removeEventListener('mousemove', showControls)
      clearTimeout(timeout)
    }
  }, [controlsState])

  useEffect(() => {
    if (state.ended) {
      setControlsVisible(true)
    }
  }, [state.ended])

  useEffect(() => {
    if (state.noteOpened) togglePopup('noteVisible')
  }, [])

  return (
    <>
      {subtitles?.length && state.subtitlesVisible ? (
        <Draggable>
          <div className={cx([style.subtitlesContainer, isFullscreen && style.fullscreenSubs])}>
            <span className={style.subtitles}>{currentBlock?.text}</span>
          </div>
        </Draggable>
      ) : null}

      <motion.div
        onClick={() => togglePlay()}
        variants={variants}
        initial='visible'
        animate={controlsVisible ? 'visible' : 'hidden'}
        className={style.overlay}
        id={style.videoControls}>
        <div className={style.topControls}>
          <div className={style.rowControls}>
            <div className={style.controls}>
              <IconButton
                iconClassName={style.backIcon}
                onClick={() => history.replace(`/corso/${courseInfo.courseId}`)}
                icon={backIcon}
              />
            </div>
            <div className={style.controls} style={{ gap: '1.5rem' }}>
              <Share
                containerClassName={style.shareButtonContainer}
                onClick={() => {
                  if (state.playing) togglePlay()
                  onShareClick()
                  share({
                    type: 'lesson',
                    url: `${env.SITE_URL}/lezione/${courseInfo.lessonId}`,
                  })
                }}
              />
              <FeedbackPopup
                lessonId={courseInfo.lessonId}
                visible={controlsState.feedbackPopup.visible}
                onClick={() => toggleFeedback()}
                courseId={courseInfo.courseId}
                viewTime={state.playedSeconds || 0}
                buttonClassNameImage={style.feedbackPopupButtonImage}
                buttonClassName={style.feedbackPopupButton}
                popupContainerClassName={style.feedbackPopupContainer}
                openSide='bottom-left'
                selectedCategoryId={controlsState.feedbackPopup.selectedCategoryId}
              />
            </div>
          </div>
        </div>

        <div className={style.centerControls}>
          <TriggerButton
            image={BackwardSeconds}
            onClick={() => {
              onBack()
              seekBackward()
            }}
            classNameImage={style.seekControlImage}
            className={cx([style.seekControl, style.hideOnTablet, style.hideOnDesktop])}
          />
          <TriggerButton
            active={state.playing}
            activeImage={Pause}
            image={Play}
            onClick={togglePlay}
            classNameImage={style.seekControlImage}
            className={cx([style.playControl, style.hideOnTablet, style.hideOnDesktop])}
          />
          <TriggerButton
            image={ForwardSeconds}
            onClick={() => {
              onForward()
              seekForward()
            }}
            classNameImage={style.seekControlImage}
            className={cx([style.seekControl, style.hideOnTablet, style.hideOnDesktop])}
          />
        </div>

        <div className={style.bottomControls}>
          <Scrubber state={state} controls={controls} onSeek={onSeek} />
          <div className={cx([style.rowControls])}>
            <div className={cx([style.controls])}>
              <TriggerButton
                active={state.playing}
                activeImage={Pause}
                image={Play}
                onClick={togglePlay}
                classNameImage={style.playControlImage}
                className={cx([style.smallPlayControl, style.hideOnMobile])}
              />
              <TriggerButton
                image={BackwardSeconds}
                onClick={() => {
                  onBack()
                  seekBackward()
                }}
                classNameImage={style.seekControlImage}
                className={cx([style.smallSeekControl, style.hideOnMobile])}
              />
              <TriggerButton
                image={ForwardSeconds}
                onClick={() => {
                  onForward()
                  seekForward()
                }}
                classNameImage={style.seekControlImage}
                className={cx([style.smallSeekControl, style.hideOnMobile])}
              />

              <PlaybackRate
                startingRate={state.playbackRate}
                onSetPlayback={setPlaybackRate}
                onClick={onChangeSpeed}
                buttonClassName={style.playbackRateButton}
              />
              {subtitles?.length ? (
                <IconButton
                  iconClassName={style.likeButtonIcon}
                  onClick={() => {
                    onToggleSubtitles(!state.subtitlesVisible)
                    toggleSubtitles(!state.subtitlesVisible)
                  }}
                  icon={state.subtitlesVisible ? subtitlesActiveIcon : subtitlesIcon}
                />
              ) : null}
              {playerSideState.status === 'hide' ? (
                <div className={cx([style.title, style.hideOnTablet])}>
                  <p className={cx([style.courseName])}>{courseInfo.lessonName}</p>
                </div>
              ) : null}
            </div>
            <div className={cx([style.controls])}>
              {courseInfo.nextLessonId ? (
                <TriggerButton
                  image={skipNext}
                  onClick={() => {
                    goToNextLesson()
                  }}
                  classNameImage={style.nextLessonControlImage}
                  className={cx([style.nextLessonControl, style.hideOnDesktop])}
                />
              ) : null}
              {configuration?.features?.mylearnn !== false && (
                <MyLearnnButton
                  itemType='lesson'
                  itemId={courseInfo.lessonId}
                  myLearnnId={courseInfo.myLearnnId}
                  containerClass={style.myLearnnContainer}
                  className={style.myLearnnButton}
                />
              )}
              {courseInfo.nextLessonId ? (
                <TriggerButton
                  image={skipNext}
                  onClick={() => {
                    goToNextLesson()
                  }}
                  classNameImage={style.nextLessonControlImage}
                  className={cx([style.nextLessonControl, style.hideOnTablet, style.hideOnMobile])}
                />
              ) : null}
              <TriggerButton
                image={playerSideState.status === 'visible' ? sidebarClose : sidebarOpen}
                onClick={() => {
                  togglePlayerSide()
                  logSidebarToggle(
                    playerSideState.status === 'visible',
                    courseInfo.courseId,
                    courseInfo.moduleId,
                    courseInfo.lessonId,
                  )
                }}
                className={style.hideOnMobile}
                classNameImage={cx([style.sidebarButtonImage])}
              />
              {courseInfo.circlePostUrl && (
                <DiscussionButton
                  onClick={() => logCommunityOpenLesson(courseInfo.courseId, courseInfo.lessonId)}
                  classNameImage={style.discussionButtonImage}
                />
              )}
              <TriggerButton
                image={fullscreenIcon}
                onClick={() => {
                  if (isMobile) {
                    toggleMobileFullscreen()
                  } else {
                    toggleFullscreen()
                  }
                  onFullscreen()
                }}
                classNameImage={style.fullscreenButtonImage}
                className={cx([style.fullscreenButton])}
              />
            </div>
          </div>
        </div>

        {courseInfo.nextLessonId ? (
          <NextLesson
            visible={controlsState.nextLessonVisible}
            onClick={goToNextLesson}
            onEnded={onNextLesson}
            onDismiss={courseInfo.likeState !== 'none' ? dismissEndLesson : dismissNextLesson}
            dismissed={controlsState.nextLessonDismissed}
          />
        ) : null}
      </motion.div>
    </>
  )
}
