import { FC } from 'react'
import env from '../../env.json'
import style from './styles/quizInfo.module.css'
import { QuizItem, QuizResult } from '@learnn/sdk/src/api/quiz/'

type QuizInfo = {
  result?: QuizResult
  quiz: QuizItem
  onBackClick(): void
  timeUp?: boolean
}

export const QuizInfo: FC<QuizInfo> = ({ result, timeUp, quiz, onBackClick }) => {
  return (
    <div className={style.containerSummary}>
      {timeUp ? (
        <>
          <img src={env.QUIZ_TIME_UP_IMAGE_URL} className={style.feedbackImage} />
          <h1 className={style.descriptionTitle}>Tempo scaduto</h1>
          <p className={style.quizDescription}>
            Hai esaurito il tempo a disposizione per completare il quiz. Il tentativo verrà
            automaticamente considerato come non completato
          </p>
          <p className={style.quizDescription}>
            Potrai effettuare un nuovo tentativo tra {env.QUIZ_RETRY_TIME_DAYS} giorni.
          </p>
        </>
      ) : result && result.passed ? (
        <>
          <img src={env.QUIZ_PASSED_IMAGE_URL} className={style.feedbackImage} />
          <h1 className={style.descriptionTitle}>Congratulazioni!</h1>
          <p className={style.quizDescription}>Hai superato il quiz.</p>
          <p
            className={
              style.quizDescription
            }>{`Hai risposto correttamente a ${result.correctAnswers} domande su ${quiz.questions?.length}`}</p>
        </>
      ) : result && !result.passed ? (
        <>
          <img src={env.QUIZ_NOT_PASSED_IMAGE_URL} className={style.feedbackImage} />
          <h1 className={style.descriptionTitle}>Ci dispiace!</h1>
          <p className={style.quizDescription}>Non hai superato il quiz.</p>
          <p className={style.quizDescription}>
            {`Hai risposto correttamente a ${result.correctAnswers} domande su ${quiz.questions?.length}`}
          </p>
          <p className={style.quizDescription}>
            Potrai effettuare un nuovo tentativo tra {env.QUIZ_RETRY_TIME_DAYS} giorni.
          </p>
        </>
      ) : null}
      <button className={style.beginButton} onClick={onBackClick}>
        TORNA AL CORSO
      </button>
    </div>
  )
}
