import { FC, useState } from 'react'
import style from './styles/mobilePlaybackRate.module.css'

export interface IPlaybackRate {
  startingRate?: number
  buttonClassName?: string
  onSetPlayback(rate: number): void
  onClick(rate: number): void
}

export const MobilePlaybackRate: FC<IPlaybackRate> = ({
  startingRate = 1,
  onSetPlayback,
  onClick,
}) => {
  const rates = [0.75, 1, 1.25, 1.5, 1.75, 2]
  const [playBackRate, setPlaybackRate] = useState(startingRate)

  return (
    <div className={style.container}>
      <button
        className={style.rate}
        onClick={() => {
          const currentIndex = rates.indexOf(playBackRate)
          let newRate
          if (currentIndex === -1) {
            newRate = rates[1]
          }
          if (currentIndex + 1 === rates.length) newRate = rates[0]
          else newRate = rates[currentIndex + 1]

          setPlaybackRate(newRate)
          onSetPlayback(newRate)
          onClick(newRate)
        }}>
        {playBackRate}x
      </button>
    </div>
  )
}
