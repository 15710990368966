import { useContext } from 'react'
import { AssistantContext } from './assistantContext'


export const useAssistantPopup = () => {
  const { dispatch, state } = useContext(AssistantContext)

  return {
    state: state,
    toggle: () => {
      dispatch({
        ...state,
        show: !state.show,
      })
    },
    open: () => {
      dispatch({
        ...state,
        show: true,
      })
    },
    close: () => {
      dispatch({
        ...state,
        show: false,
      })
    },
  }
}
