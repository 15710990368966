import { FC, ReactNode } from "react";
import cx from "classnames";
import style from "./alertBanner.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

type AlertProps = {
  title: string;
  message: string;
  cta?: ReactNode;
  className?: boolean;
};

export const AlertBanner: FC<AlertProps> = ({ title, message, cta, className }) => {
  return (
    <div className={cx([style.container, className])}>
      <div className={style.iconContainer}>
        <FontAwesomeIcon icon={faExclamationCircle} color="white" className={style.icon} />
      </div>
      <div className={style.textContainer}>
        <span className={style.boldText}>{title}</span>
        <span className={style.text}>
          {message}
        </span>
        {cta}
      </div>
    </div>
  );
};
