import { FC, useEffect, useRef } from 'react'
import { useCountdown } from '../../utils/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass } from '@fortawesome/free-solid-svg-icons'
import style from './countdown.module.css'
import cx from 'classnames'

type CountdownProps = {
  durationInMinutes: number
  onCountdownEnd(): void
  containerClassName?: string
}

export const Countdown: FC<CountdownProps> = ({
  durationInMinutes,
  onCountdownEnd,
  containerClassName,
}) => {
  const currentTime = useRef(new Date().getTime())
  const dateTimeAfterMinutes = useRef(durationInMinutes * 60000 + currentTime.current)
  const ended = useRef(false)

  const [minutes, seconds] = useCountdown(dateTimeAfterMinutes.current)

  useEffect(() => {
    if (minutes + seconds <= 0 && !ended.current) {
      onCountdownEnd()
      ended.current = true
    }
  }, [minutes, seconds])

  const displayTime = (time: number) => {
    return time.toString().padStart(2, '0')
  }

  return (
    <div className={minutes < 3 ? style.redContainer : cx([style.container, containerClassName])}>
      {minutes + seconds <= 0 ? (
        <span className={style.text}>{`- 00:00`}</span>
      ) : (
        <span className={style.text}>{`- ${displayTime(minutes)}:${displayTime(seconds)}`}</span>
      )}

      <FontAwesomeIcon icon={faHourglass} className='ml-2' />
    </div>
  )
}
