import React, { createContext, useState, useContext, useEffect, FC } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { Estimate } from './types'
import {
  AddonPaymentResult,
  addonPurchase,
  getPrimaryPaymentMethod,
  PaymentMethodType,
  upgradeDowngrade,
  UpgradeDowngradeResult,
} from 'src/controllers/Payment'
import { getCheckoutEstimate, isUserAllowedToCheckout } from './utils'
import { getCurrentCustomerInfo, getCurrentSubscription } from 'src/controllers/User'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import env from '../../env.json'
import { useGTMCheckout } from 'src/analytics/gtm'
import { matchQuery } from 'src/utils/matchQuery'
import auth from 'src/utils/auth'
import { useHistory } from 'react-router-dom'

/**
 * Context Types
 */
export type CheckoutData = {
  plan?: string
  planQuantity?: number
  coupon?: string
  addon?: string
  addonQuantity?: number
  userId: string
  nextStep?: string
}
type CheckoutContextType = {
  checkoutData: CheckoutData
  setCheckoutData: React.Dispatch<React.SetStateAction<CheckoutData>>
  estimateQuery: UseQueryResult<Estimate>
  defaultPaymentMethodQuery: UseQueryResult<O.Option<PaymentMethodType>>
  setCoupon: (couponCode: string) => unknown
  checkoutState: CheckoutState
  couponState: CouponState
  paymentState: PaymentState
  submitPayment: Function
  availableCredits: number
}
const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined)

type CheckoutState = { state: 'loading' } | { state: 'ready' } | { state: 'error'; message: string }
type PaymentState =
  | { state: 'loading' }
  | { state: 'ready' }
  | { state: 'error'; message: string }
  | { state: 'success' }
type CouponState = { state: 'loading' } | { state: 'ready' } | { state: 'error'; message: string }

type InvoiceData =
  | ({ type: 'addon' } & AddonPaymentResult)
  | ({
      type: 'upgrade-downgrade'
      planName?: string
      addonValue?: number
      discount?: boolean
      discountAmount?: number
      total?: number
    } & UpgradeDowngradeResult)

/**
 * Url Types
 */

type CheckoutProviderProps = {
  initialCheckoutData: CheckoutData
  onPaymentSuccess: (checkoutData: CheckoutData, invoiceData: InvoiceData) => unknown
  redirectToPayment: (checkoutData: CheckoutData) => unknown
}
export const CheckoutProvider: FC<CheckoutProviderProps> = ({
  children,
  initialCheckoutData,
  onPaymentSuccess,
  redirectToPayment
}) => {
  const { sendToGTM } = useGTMCheckout()
  const history = useHistory()

  const [checkoutData, setCheckoutData] = useState<CheckoutData>(initialCheckoutData)
  const [checkoutState, setCheckoutState] = useState<CheckoutState>({ state: 'ready' })
  const [couponState, setCouponState] = useState<CouponState>({ state: 'ready' })
  const [paymentState, setPaymentState] = useState<PaymentState>({ state: 'ready' })
  const [availableCredits, setAvailableCredits] = useState(0)

  const estimateQuery = useQuery(
    ['estimate', checkoutData],
    () => getCheckoutEstimate(checkoutData),
    { refetchOnWindowFocus: false },
  )
  const currentSubscriptionQuery = useQuery(
    ['currentSubscription', checkoutData.userId],
    () => getCurrentSubscription(),
    { refetchOnWindowFocus: false },
  )
  const defaultPaymentMethodQuery = useQuery(['defaultPaymentMethod', checkoutData.userId], () =>
    getPrimaryPaymentMethod(),
  )

  const customerInfoQuery = useQuery(
    ['customer-info', checkoutData.userId], 
    () => getCurrentCustomerInfo(),
    { refetchOnWindowFocus: false },
  )

  useEffect(() => {
    if (!auth.getToken()) {
      history.push(`/login/?appurl=${encodeURIComponent(`/checkout${location.search}`)}`)
      return
    }
  }, [history])

  useEffect(() => {
    setCheckoutState({ state: 'loading' })
    if (!checkoutData.plan && !checkoutData.addon)
      window.location.replace(env.CHOOSE_PLAN_URL + location.search)
    setCheckoutState({ state: 'ready' })
  }, [checkoutData])

  useEffect(() => {
    if (estimateQuery.status === 'error') setCheckoutState({ state: 'error', message: '' })

    if (estimateQuery.data) {
      sendToGTM({
        event: 'checkoutStep',
        productName: estimateQuery.data.lines.filter(x => x.entityType == 'plan')[0]?.name,
        productSKU: checkoutData.plan,
        price: estimateQuery.data.total,
        discount:
          estimateQuery.data.discountLines && estimateQuery.data.discountLines.length > 0
            ? true
            : false,
        checkoutStep: 3,
        addon_id: checkoutData.addon,
        addon_qty: checkoutData.addon ? checkoutData.addonQuantity ?? 1 : undefined,
        addon_value: estimateQuery.data.lines.filter(x => x.entityType == 'addon')[0]?.amount,
      })
    }
  }, [estimateQuery.status])

  useEffect(() => {
    if (currentSubscriptionQuery.status === 'error')
      setCheckoutState({ state: 'error', message: 'Abbonamento utente non trovato' })

    if (
      currentSubscriptionQuery.status === 'loading' ||
      defaultPaymentMethodQuery.status === 'loading'
    )
      setCheckoutState({ state: 'loading' })

    if (
      currentSubscriptionQuery.status === 'success' &&
      (defaultPaymentMethodQuery.status === 'error' ||
        defaultPaymentMethodQuery.status === 'success')
    ) {
      const _isUserAllowedToCheckout = isUserAllowedToCheckout({
        planUnitPrice: currentSubscriptionQuery.data.plan_unit_price,
        defaultMethodStatus: defaultPaymentMethodQuery.data
          ? pipe(
              defaultPaymentMethodQuery.data,
              O.map(x => x.status),
            )
          : O.none,
        plan: O.fromNullable(checkoutData.plan),
        coupon: O.fromNullable(checkoutData.coupon),
        addon: O.fromNullable(checkoutData.addon),
      })
      if (!_isUserAllowedToCheckout) redirectToPayment(checkoutData)

      setCheckoutState({ state: 'ready' })
    }
  }, [currentSubscriptionQuery.status, defaultPaymentMethodQuery.status])

  useEffect(() => {
    matchQuery(customerInfoQuery, {
      loading: () => {
        if (customerInfoQuery.isLoading) {
          setCheckoutState({ state: 'loading' })
        }
      },
      error: () => {
        setCheckoutState({ state: 'error', message: 'Info utente non trovate' })
      },
      success: data => {
         setAvailableCredits(data.customer.promotional_credits)
      },
    })
  }, [customerInfoQuery.status])


  const setCoupon = async (couponCode: string) => {
    const isValidEstimateWithCoupon = async () => {
      try {
        await getCheckoutEstimate({ ...checkoutData, coupon: couponCode })
        return true
      } catch (e) {
        return false
      }
    }

    setCouponState({ state: 'loading' })
    if (await isValidEstimateWithCoupon()) {
      setCheckoutData(prev => ({ ...prev, coupon: couponCode }))
      setCouponState({ state: 'ready' })
    } else {
      setCouponState({ state: 'error', message: 'Coupon non valido' })
    }
  }

  const submitPayment = async () => {
    let invoiceData
    try {
      setPaymentState({ state: 'loading' })
      if (checkoutData.plan) {
        if (estimateQuery.data) {
          invoiceData = {
            addonValue: estimateQuery.data.lines.filter(x => x.entityType == 'addon')[0]?.amount,
            discount:
              estimateQuery.data.discountLines && estimateQuery.data.discountLines.length > 0
                ? true
                : false,
            discountAmount: estimateQuery.data.discountLines?.reduce(
              (acc, curr) => acc + curr.amount,
              0,
            ),
            total: estimateQuery.data.total,
            planName: estimateQuery.data.lines.filter(x => x.entityType == 'plan')[0]?.name,
          }
        }
        const result = await upgradeDowngrade({
          coupon: checkoutData.coupon,
          plan: checkoutData.plan,
          planQuantity: checkoutData.planQuantity,
          addon: checkoutData.addon,
        })

        if (E.isRight(result)) {
          invoiceData = { ...invoiceData, ...result, type: 'upgrade-downgrade' }
          setPaymentState({ state: 'success' })
          onPaymentSuccess(checkoutData, invoiceData)
        } else {
          setPaymentState({
            state: 'error',
            message: result.left.message,
          })
        }
      } else if (checkoutData.addon) {
        const result = await addonPurchase({
          coupon: checkoutData.coupon,
          addonId: checkoutData.addon,
          addonQuantity: checkoutData.addonQuantity ?? 1,
        })
        invoiceData = { ...result, type: 'addon' }
        setPaymentState({ state: 'success' })
        onPaymentSuccess(checkoutData, invoiceData)
      }
    } catch (e) {
      setPaymentState({
        state: 'error',
        message: "Si è verificato un errore. Contatta l'assistenza",
      })
    }
  }

  return (
    <CheckoutContext.Provider
      value={{
        checkoutData,
        setCheckoutData,
        estimateQuery,
        defaultPaymentMethodQuery,
        setCoupon,
        submitPayment,
        checkoutState,
        couponState,
        paymentState,
        availableCredits
      }}>
      {children}
    </CheckoutContext.Provider>
  )
}

export const useCheckout = () => {
  const context = useContext(CheckoutContext)
  if (!context) {
    throw new Error('useCheckout must be used within a CheckoutProvider')
  }
  return context
}
