import { Fast } from './Fast'
import { useBodyClass } from '../../utils/hooks'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export const FastScreen = () => {
  useBodyClass('app')
  return (
    <AppLayout showSearchbar>
      <Fast />
    </AppLayout>
  )
}
