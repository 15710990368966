import { Route } from 'react-router-dom'
import { AppShell, defaultTheme } from '@learnn/designn'

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <AppShell theme={defaultTheme}>
            <Component {...props} />
          </AppShell>
        )
      }}
    />
  )
}

export default PublicRoute
