import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import { CardsGrid } from '../../components/CardsGrid/CardsGrid'


export const WallScreenSkeleton = () => {

  return (
    <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', flexDirection: 'row', marginBottom: '3rem'}}>
          <Skeleton width={100} height={40} style={{marginRight: 20}} borderRadius='4px'/>
          <Skeleton width={100} height={40} style={{marginRight: 20}} borderRadius='4px'/>
          <Skeleton width={100} height={40} style={{marginRight: 20}} borderRadius='4px'/>
        </div>

        <CardsGrid type='courses'>
          {Array.from({length: 12}).map(() => <Skeleton width='100%' height='170px' borderRadius={15}/>)}
        </CardsGrid>
        </div>
    </SkeletonTheme>
  )
}