import { useEffect, useRef, useState } from 'react'
import { CardsGrid } from '../../components/CardsGrid/CardsGrid'
import { CourseCard } from '../../components/CourseCard'
import { ErrorMessage } from '../../components/ErrorMessage'
import { getCourseCategories, getWall } from '../../controllers/Course'
import { useBodyClass } from '../../utils/hooks'
import useAnalytics from '../../analytics/useAnalytics'
import style from './styles/wallScreen.module.css'
import {
  CourseBaseInfo,
  CourseBaseInfoEngagement,
  CourseCategory,
  CourseType,
} from '@learnn/sdk/src/api/course'
import { HorizontalScroll } from '@learnn/designn'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

import { useCoursePopup } from '../../components/CoursePopup/useCoursePopup'
import { useReactive } from '../../utils/useReactive'
import * as lib from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { WallScreenSkeleton } from './WallScreenSkeleton'
import { getAuthorsNames } from '../../utils/data'
import { useQuery } from 'react-query'
import { matchQuery } from '../../utils/matchQuery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

type FilterCategory = CourseType | 'all'

export const WallScreen = () => {
  const [filterCategory, setFilterCategory] = useState<{ type: FilterCategory; label: string }>({
    type: 'all',
    label: 'Tutti',
  })
  const [filterTopic, setFilterTopic] = useState<CourseCategory>()
  const [isDropdownTypeOpen, setIsDropdownTypeOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { data, error } = useReactive({
    observableFn$: getWall({
      type: filterCategory.type === 'all' ? 'course' : filterCategory.type,
      showAll: filterCategory.type === 'all',
    }),
    queryKey: 'wall',
  })

  const categoriesQuery = useQuery('categories', () => getCourseCategories())

  const { logSelectListCourse } = useAnalytics()

  const { show } = useCoursePopup()
  useBodyClass('app')

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownTypeOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleCourseClick = (course: CourseBaseInfoEngagement) => {
    logSelectListCourse(course.id, {
      lesson_title: course.title,
      lesson_duration: course.duration ?? '',
      lesson_author: getAuthorsNames(course.authors),
    })

    show(course)
  }

  const render = () => {
    return lib.pipe(
      error,
      O.fold(
        () => {
          if (O.isNone(data)) return <WallScreenSkeleton />
          let filteredData: (CourseBaseInfo & Partial<CourseBaseInfoEngagement>)[] = []

          const filterCategories: { type: FilterCategory; label: string }[] = [
            {
              type: 'all',
              label: 'Tutti',
            },
            {
              type: 'course',
              label: 'Corsi',
            },
            {
              type: 'webinar',
              label: 'Webinar',
            },
          ]

          switch (filterCategory.type) {
            case 'all':
              filteredData = data.value
              break
            case 'course':
              filteredData = data.value.filter(s => s.type.slug === 'course')
              break
            case 'webinar':
              filteredData = data.value.filter(s => s.type.slug === 'webinar')
              break
          }

          if (filterTopic) {
            filteredData = filteredData.filter(x =>
              x.categories?.some(x => x.id === filterTopic.id),
            )
          }

          return (
            <div className={style.block}>
              <div className={style.featured}>
                <div className={style.barContainer}>
                  <div className={style.dropdownContainer} ref={dropdownRef}>
                    <button
                      className={`${style.filter} ${style.dropdownDisplay}`}
                      onClick={() => setIsDropdownTypeOpen(x => !x)}>
                      {filterCategory.label}
                      <span className={style.caret}>▼</span>
                    </button>
                    {isDropdownTypeOpen && (
                      <div className={style.dropdownList}>
                        {filterCategories.map((option, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              setFilterCategory(option)
                              setIsDropdownTypeOpen(false)
                            }}
                            className={`${style.filter} ${style.dropdownItem}`}>
                            {option.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={style.filtersContainer}>
                    {matchQuery(categoriesQuery, {
                      loading: () => <></>,
                      error: () => <></>,
                      success: categories => {
                        return (
                          <HorizontalScroll>
                            {categories.map(category => (
                              <button
                                className={style.filter}
                                data-active={filterTopic?.id === category.id}
                                onClick={() => {
                                  setFilterTopic(x =>
                                    x?.id === category.id ? undefined : category,
                                  )
                                }}>
                                {category.title}
                                {filterTopic?.id === category.id && (
                                  <div className={style.close}>
                                    <FontAwesomeIcon icon={faTimes} size='sm' color='white' />
                                  </div>
                                )}
                              </button>
                            ))}
                          </HorizontalScroll>
                        )
                      },
                    })}
                  </div>
                </div>
                <CardsGrid type='courses'>
                  {filteredData.map((course: any) => {
                    return course && !course?.draft ? (
                      <CourseCard
                        key={`filter-${course?.id}`}
                        course={course}
                        onClick={() => handleCourseClick(course)}
                        myLearnnId={course?.myLearnnId}
                        myLearnn
                        size='md'
                      />
                    ) : null
                  })}
                </CardsGrid>
              </div>
            </div>
          )
        },
        () => {
          return <ErrorMessage />
        },
      ),
    )
  }

  return <AppLayout hideSearchFilters showSearchbar>{render()}</AppLayout>
}
