import { useState } from 'react'

import { createFeedback, getFeedbackCategories } from '../../controllers/Feedback'
//@ts-ignore
import { osName, osVersion, browserName } from 'react-device-detect'
import { useQuery } from 'react-query'

export const useFeedback = (lessonId: string, courseId: string) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [sent, setSent] = useState(false)
  const { isLoading: loadingFeedbackCategories, data: feedbackCategories } = useQuery(
    ['feedbackCategories'],
    getFeedbackCategories,
  )

  const onError = () => {
    setError(true)
  }

  return {
    error,
    loading: loading || loadingFeedbackCategories,
    sent,
    feedbackCategories,
    reset: () => {
      setSent(false)
    },
    saveFeedback: async (categories: string[], content: string, viewTime: number) => {
      try {
        setLoading(false)
        await createFeedback({
          categories,
          data: content,
          courseId,
          lessonId,
          contentType: 'lesson',
          viewTime,
          userDeviceInfo: JSON.stringify({ osName, osVersion, browserName }),
        })
        setLoading(false)
        setSent(true)
      } catch (error) {
        console.log(error)
        onError()
      }
    },
  }
}
