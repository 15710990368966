type userProgresses = {
  __typename: 'UserProgress'
  id: string
  maxViewTime: number | null
  currentViewTime: number | null
  lesson: userProgressesLesson | null
}

type userProgressesLesson = {
  __typename: 'Lesson'
  id: string
  duration: number | null
  title: string | null
  awsMedia: { __typename: 'UploadFile'; id: string } | null
  cover: {
    __typename: 'UploadFile'
    formats: any | null
  } | null
}

export const calculateProgressPercentage = (viewed: number, total: number): number => {
  const percentage = (100 * viewed) / total
  return percentage
}

export const createProgressHash = (progress?: userProgresses[] | any) => {
  if (!progress) return
  if (!progress.length) return {}
  let progressHash = {}
  progress.forEach((p: userProgresses) => {
    if (p && p.lesson) {
      //@ts-ignore //TODO
      progressHash[p?.lesson?.id] = calculateProgressPercentage(
        p?.maxViewTime || 0,
        p?.lesson?.duration || 0,
      )
    }
  })

  return progressHash
}

export const youLearnToArray = (youLearn: string): string[] | null => {
  const result = youLearn.replaceAll('\n', '').split('- ')
  result.shift()
  return result.length > 0 ? result : null
}
