import { createContextReducer } from '../../utils/createContext'
import {CourseBaseInfoEngagement, CourseBaseInfo} from "@learnn/sdk/dist/api/course";

export type PopupType =
  | {
      course: (CourseBaseInfo & Partial<CourseBaseInfoEngagement>)
      show: true
    }
  | {
      show: false
    }

export const popupInitialState: PopupType = {
  show: false,
}

export type IPopupActions = PopupType & {}

function reducer(state: PopupType, newState: IPopupActions) {
  return { ...state, ...newState }
}

const [ctx, Provider] = createContextReducer<PopupType, IPopupActions>(reducer, popupInitialState)
export const CoursePopupContext = ctx
export const CoursePopupContextProvider = Provider
