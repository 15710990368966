import { VerticalStack, useTheme } from '@learnn/designn'
import { ReactNode } from 'react'

export const SectionCard = ({
  children,
  noBorder,
}: {
  children: ReactNode
  noBorder?: boolean
}) => {
  const { borders, colors, spacing } = useTheme()
  return (
    <VerticalStack
      borderRadius={borders.radius.large}
      bg={colors.card_background}
      borderStyle='solid'
      borderColor={colors.card_border}
      borderWidth={noBorder ? 0 : borders.width.base}
      p={{ _: spacing.space_4, small: spacing.space_8 }}>
      {children}
    </VerticalStack>
  )
}
