"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function consoleAnalytics() {
    const logEvent = (name, params) => {
        return Promise.resolve().then(() => {
            console.log(`[CONSOLE ANALYTYCS-${Date.now()}] event ${name} params:${JSON.stringify(params) ?? ''} `);
        });
    };
    const logSelectContent = (contentType, itemId) => {
        return Promise.resolve().then(() => {
            console.log(`[CONSOLE ANALYTYCS-${Date.now()}] select content content type:${contentType} item ${itemId}`);
        });
    };
    const logLogin = (method) => {
        return Promise.resolve().then(() => {
            console.log(`[CONSOLE ANALYTYCS-${Date.now()}] login method:${method}`);
        });
    };
    const logScreenView = async (screenClass, screenName) => {
        return Promise.resolve().then(() => {
            console.log(`[CONSOLE ANALYTYCS-${Date.now()}] screen view: class ${screenClass}, name ${screenName}`);
        });
    };
    const setUserId = async (id) => {
        return Promise.resolve().then(() => {
            console.log(`[CONSOLE ANALYTYCS-${Date.now()}] set user id ${id}`);
        });
    };
    const setUserEmail = async (email) => {
        return Promise.resolve().then(() => {
            console.log(`[CONSOLE ANALYTYCS-${Date.now()}] set user email ${email}`);
        });
    };
    const setUserProperties = async (properties) => {
        return Promise.resolve().then(() => {
            console.log(`[CONSOLE ANALYTYCS-${Date.now()}] user properties: ${JSON.stringify(properties)} `);
        });
    };
    const setUserProperty = async (name, value) => {
        return Promise.resolve().then(() => {
            console.log(`[CONSOLE ANALYTYCS-${Date.now()}] set property ${name} ${value}`);
        });
    };
    return {
        logLogin,
        logSelectContent,
        logEvent,
        logScreenView,
        setUserId,
        setUserProperties,
        setUserProperty,
        setUserEmail,
        name: 'console',
    };
}
exports.default = consoleAnalytics;
