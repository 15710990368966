import { VerticalStack, HorizontalStack, Text, Button } from '@learnn/designn'
import { FC, useState, useContext } from 'react'
import { SectionTitle } from './SectionTitle'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ConfirmPopup } from '../../../components/ConfirmPopup'
import { GlobalProviderContext } from '../../../components/GlobalProvider'
import { spacing } from '@learnn/designn/build/theme/tokens/spacing'
import { deleteAccount } from '../../../controllers/User'
import { useHistory } from 'react-router-dom'

export type PaymentHistoryProps = { restrictAccess: boolean }

export const DeleteAccount: FC<PaymentHistoryProps> = ({ restrictAccess }) => {
  const globalContext = useContext(GlobalProviderContext)
  const history = useHistory()
  const userEmail = globalContext?.userEmail
  const [confirmation, showConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleDeletion = async () => {
    try {
      setError(false)
      setLoading(true)
      showConfirmation(false)
      await deleteAccount()
      history.push(`/logout`)
    } catch (error) {
      console.log(error)
      showConfirmation(false)
      setLoading(false)
      setError(true)
    }
  }

  return (
    <VerticalStack>
      <SectionTitle title='Elimina account' />
      <HorizontalStack>
        {restrictAccess ? (
          <Text variant='bodySm'>
            Il tuo abbonamento fa parte di un team, solo il proprietario del team può eliminare
            l'account
          </Text>
        ) : (
          <VerticalStack alignItems='flex-start'>
            <Button
              loading={loading}
              label={loading ? 'Eliminazione in corso...' : 'Elimina il tuo account'}
              onPress={() => showConfirmation(true)}
              variant='secondary'
              icon={<FontAwesomeIcon icon={faTrash} />}
            />
            {error && (
              <Text variant='bodyXs' color='error' mt={spacing.space_2}>
                Si è verificato un errore durante la cancellazione dell'account.
              </Text>
            )}
          </VerticalStack>
        )}
      </HorizontalStack>
      {confirmation && (
        <ConfirmPopup
          text={`Il tuo account, compresi tutti i tuoi dati ed il tuo abbonamento verranno eliminati completamente. Per proseguire inserisci il tuo indirizzo email qui sotto. L'azione è irreversibile`}
          textConfirmation={userEmail}
          onCancelClick={() => showConfirmation(false)}
          onConfirmClick={handleDeletion}
        />
      )}
    </VerticalStack>
  )
}
