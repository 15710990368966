import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import { getProgressiveCourseModuleLessons } from '../../../../controllers/Course'
import env from '../../../../env.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { useReactive } from '../../../../utils/useReactive'
import * as lib from 'fp-ts/function'
import * as O from 'fp-ts/Option'

import { Button, Box, HorizontalStack } from '@learnn/designn'
import { useHistory } from 'react-router-dom'
import { VerifiedState } from '@learnn/sdk/src/api/quiz'

export type MainCtaProps = {
  course: CourseBaseInfoEngagement
  verify?: {
    verifiedState: VerifiedState
    onVerifyCourseClick: Function
    onShowVerifiedClick: Function
  }
  size?: 'md' | 'lg'
}

export type Tabs = 'overview' | 'modules' | 'notes' | 'verified'

export const MainCta = ({ course, verify, size }: MainCtaProps) => {
  const history = useHistory()
  const { data, error } = useReactive({
    observableFn$: getProgressiveCourseModuleLessons(course.id),
    queryKey: ['courseModuleLessons', course.id],
  })

  if (course.lastProgress) {
    if (
      course.type.slug === 'course' &&
      course.progress &&
      course.progress >= env.QUIZ_ALLOW_THRESHOLD &&
      verify
    )
      if (verify.verifiedState.status !== 'passed')
        return (
          <Box
            display='flex'
            justifyContent={{ default: 'center', small: 'flex-start' }}
            flexDirection='row'>
            <Button
              icon={<FontAwesomeIcon icon={faPlay} />}
              variant='primary'
              size={size ?? 'lg'}
              iconPosition='left'
              onPress={() => {
                verify.onVerifyCourseClick()
              }}
              label={`Verifica il ${course.type.title.toLocaleLowerCase()}`}
            />
          </Box>
        )
      else
        return (
          <Box
            display='flex'
            justifyContent={{ default: 'center', small: 'flex-start' }}
            flexDirection='row'>
            <Button
              icon={<FontAwesomeIcon icon={faPlay} />}
              variant='primary'
              size={size ?? 'lg'}
              iconPosition='left'
              onPress={() => {
                verify.onShowVerifiedClick()
              }}
              label={`Visualizza la verifica`}
              squareBorder
            />
          </Box>
        )
    else
      return (
        <Box
          display='flex'
          justifyContent={{ default: 'center', small: 'flex-start' }}
          flexDirection='row'>
          <Button
            icon={<FontAwesomeIcon icon={faPlay} />}
            variant='primary'
            iconPosition='left'
            size={size ?? 'lg'}
            onPress={() => {
              history.push(`/player/${course?.lastProgress?.id}`)
            }}
            label={`Continua il ${course.type.title.toLocaleLowerCase()}`}
            squareBorder
          />
        </Box>
      )
  } else {
    return (
      <>
        {lib.pipe(
          error,
          O.fold(
            () =>
              lib.pipe(
                data,
                O.fold(
                  () => (
                    <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
                      <HorizontalStack justifyContent='center'>
                        <div style={{ width: '250px', height: '50px' }}>
                          <Skeleton height='100%' borderRadius={15} />
                        </div>
                      </HorizontalStack>
                    </SkeletonTheme>
                  ),
                  ({ modules }) => {
                    return (
                      <Box
                        display='flex'
                        justifyContent={{ default: 'center', small: 'flex-start' }}
                        flexDirection='row'>
                        <Button
                          icon={<FontAwesomeIcon icon={faPlay} />}
                          iconPosition='left'
                          variant='primary'
                          size={size ?? 'lg'}
                          squareBorder
                          onPress={() => {
                            const firstLesson =
                              modules.length > 0 && modules[0].lessons.length > 0
                                ? modules[0].lessons[0]
                                : null
                            if (firstLesson) {
                              history.push(`/player/${firstLesson.id}`)
                            }
                          }}
                          label={`Inizia il ${course.type.title.toLocaleLowerCase()}`}
                        />
                      </Box>
                    )
                  },
                ),
              ),
            () => <></>,
          ),
        )}
      </>
    )
  }
}
