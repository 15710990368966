import { useEffect, useRef } from 'react'

import Loader from 'react-loader-spinner'

import auth from '../../utils/auth'
import { useGTMCheckout } from '../../analytics/gtm'
import QueryString from 'query-string'
import env from '../../env.json'
import { self } from '../../controllers/User'
import style from './socialRedirectScreen.module.css'

import { VerticalStack, HorizontalStack, useTheme } from '@learnn/designn'
import { useBodyClass } from '../../utils/hooks'

type UrlParams = {
  email?: string
  appUrl?: string
  social?: string
  type?: string
  code?: string
  next_step: string
}

const extractAuthToken = () => {
  return auth.getToken()
}

export const SocialRedirectScreen = ({ history }) => {
  useBodyClass('app')

  const search = location.search
    .replace('appurl', 'appUrl')
    .replace('appDestinationUrl', 'appUrl')
    .replace('appuestinationurl', 'appUrl')
  const loginRedirectUrl = env.LOGIN_REDIRECT_URL !== '' ? env.LOGIN_REDIRECT_URL : location.origin

  const urlParams = useRef<UrlParams>(QueryString.parse(search) as UrlParams)
  const { spacing, colors, borders } = useTheme()

  const { sendToGTM } = useGTMCheckout()

  const authToken = extractAuthToken()

  //@ta-ignore
  const socialSignupRedirect = async social => {
    let email, userId, firstSocialLogin
    try {
      const userSelf = await self()
      email = userSelf.email
      userId = userSelf.id
      firstSocialLogin = userSelf.firstLogin
    } catch (error) {
      console.log(error)
    }

    if (firstSocialLogin) {
      const redirectUrl = decodeURIComponent(urlParams.current.next_step) ?? env.SECOND_STEP_URL

      sendToGTM({
        event: 'accountCreated',
        checkoutStep: 1,
        method: social,
        user_data: {
          email,
          userId,
        },
      })

      setTimeout(() => {
        window.location.replace(redirectUrl)
      }, 2000) //Delay to allow gtm event
    } else {
      const redirectUrl = decodeURIComponent(urlParams.current.next_step) ?? loginRedirectUrl
      window.location.replace(redirectUrl)
    }
  }

  useEffect(() => {
    if (authToken) {
      if (urlParams.current.social) {
        socialSignupRedirect(urlParams.current.social)
      } else {
        history.push('/')
      }
    }
  }, [])

  return (
    <div className={style.root}>
      <div className={style.backgroundImage} id='login-background-container'></div>
      <VerticalStack
        justifyContent='center'
        pt={spacing.space_12}
        pb={{ _: spacing.space_20, lg: '300px' }}>
        <HorizontalStack
          flex={1}
          mt={{ _: spacing.space_10, small: 0 }}
          alignItems={{ _: 'start', small: 'center' }}
          justifyContent='center'>
          <VerticalStack
            minWidth='500px'
            minHeight='500px'
            borderRadius={borders.radius.large}
            backgroundColor={colors.modal_background}
            paddingY={{ _: spacing.space_8, small: spacing.space_10 }}
            paddingX={{ _: spacing.space_4, small: spacing.space_10 }}
            mx={spacing.space_4}
            borderWidth={spacing.space_035}
            borderStyle='solid'
            borderColor={colors.edge}
            alignItems='center'
            justifyContent='center'
            boxShadow={`0 0 ${spacing.space_16} ${spacing.space_16} #000000a1`}>
            <Loader type='TailSpin' color='black' height={60} width={60} />
          </VerticalStack>
        </HorizontalStack>
      </VerticalStack>
    </div>
  )
}
