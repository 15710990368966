//@ts-ignore
import { FC, useRef, useState, useEffect } from 'react'
import { self } from '../../controllers/User'
import Loader from 'react-loader-spinner'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { useHistory } from 'react-router-dom'
import Error from '../Error'
import usePolling from '../../utils/usePolling'
import auth from '../../utils/auth'

const pollInterval = 5000
const maxPollAttempts = 5

type Polling = {
  running: boolean
  chargebeeId: string | undefined
}

export const EnsureRegistration: FC = ({ children }) => {
  const failedAttempts = useRef(0)
  const [loading, setLoading] = useState(true)
  const [polling, setPolling] = useState<Polling>({
    running: false,
    chargebeeId: undefined,
  })
  const history = useHistory()

  const fetchData = async () => {
    try {
      const result = await self()
      if (result.chargeBeeId) {
        setPolling({
          running: false,
          chargebeeId: result.chargeBeeId,
        })
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      } else {
        handleFail()
      }
    } catch (error) {
      console.log(error)
      handleFail()
    }
  }

  const handleFail = () => {
    failedAttempts.current = failedAttempts.current + 1

    if (failedAttempts.current >= maxPollAttempts) {
      setPolling({
        running: false,
        chargebeeId: undefined,
      })

      setLoading(false)
      setTimeout(() => {
        history.push('/logout')
      }, 10000)
    } else {
      setPolling({ running: true, chargebeeId: undefined })
    }
  }

  useEffect(() => {
    if (!auth.getToken()) {
      history.push(`/login/?appurl=${encodeURIComponent(`/pagamento${location.search}`)}`)
      return
    }
    fetchData()
  }, [])

  usePolling(fetchData, polling.running ? pollInterval : null)

  if (loading)
    return (
      <div style={{ height: '100vh' }}>
        <Container className='justify-content-center w-100 h-100'>
          <Row className='justify-content-center w-100 h-100 align-items-center'>
            <Loader type='TailSpin' color='black' height={50} width={50} className='mt-5' />
          </Row>
        </Container>
      </div>
    )

  if (!polling.chargebeeId)
    return (
      <Error
        message={
          "Si è verificato un errore durante la creazione del tuo account, per favore contatta l'assistenza."
        }
      />
    )

  return <>{children}</>
}
