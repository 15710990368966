import styles from './assistantPopup.module.css'
import { useAssistantPopup } from './useAssistantPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  HorizontalStack,
  TextInput,
  Title,
  Text,
  Markdown,
  VerticalStack,
  useTheme,
} from '@learnn/designn'
import { FC, useState } from 'react'
import { oramaLessonsClient, useOramaAISearch } from 'src/controllers/Orama'

import { ProfileWithInterests } from '@learnn/sdk/dist/api/profile'

type OramaPopupComponentProps = {
  profile?: ProfileWithInterests
}
export const OramaPopupComponent: FC<OramaPopupComponentProps> = ({ profile }) => {
  const [keyword, setKeyword] = useState<string>('')
  const { sendMessage, messages, lastAssistantMessage, loading } = useOramaAISearch(
    oramaLessonsClient,
    { profile },
  )
  const { close, state: assistantState } = useAssistantPopup()
  const { spacing } = useTheme()

  if (!assistantState.show) return <></>

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword) {
      sendMessage(keyword)
      setKeyword('')
    }
  }

  const DefaultQuestion: FC = ({ children }) => (
    <VerticalStack
      borderColor='rgba(217,217,227,.2)'
      borderWidth='1px'
      borderRadius='15px'
      borderStyle='solid'
      padding='15px'
      cursor='pointer'
      onClick={() => (children ? sendMessage(children?.toString()) : null)}>
      <Text variant='bodyXs'>{children}</Text>
    </VerticalStack>
  )

  const StartComponent = () => {
    return (
      <HorizontalStack height='100%'>
        <VerticalStack width='100%' justifyContent='center' marginBottom={spacing.space_4}>
          <VerticalStack justifyContent='center' alignItems='center' flex='1'>
            <Title variant='headingLg' fontWeight='bold' mt={spacing.space_6}>
              Qual è il tuo obiettivo?
            </Title>
          </VerticalStack>
          <HorizontalStack gap='10px'>
            <VerticalStack gap='10px' flex={1}>
              <DefaultQuestion>Ho bisogno di vendere il mio prodotto online</DefaultQuestion>
              <DefaultQuestion>Come posso pubblicizzare il mio brand?</DefaultQuestion>
            </VerticalStack>
            <VerticalStack gap='10px' flex={1}>
              <DefaultQuestion>Devo creare un sito per la mia attività</DefaultQuestion>
              <DefaultQuestion>Mi puoi aiutare a creare un piano editoriale?</DefaultQuestion>
            </VerticalStack>
          </HorizontalStack>
        </VerticalStack>
      </HorizontalStack>
    )
  }

  return (
    <div className={styles.overlay} onClick={close}>
      <div className={styles.popup} onClick={e => e.stopPropagation()}>
        <FontAwesomeIcon
          icon={faTimes}
          className={styles.closeIcon}
          onClick={e => {
            e.stopPropagation()
            close()
          }}
        />
        <div className={styles.content}>
          <div className={styles.messagesContainer}>
            {messages.length > 0 &&
              messages.map((message, index) => (
                <VerticalStack key={index} mb={spacing.space_4}>
                  <Text fontWeight='black' variant='bodySm'>
                    {message.role === 'user' ? 'Tu' : 'Learnn Assistant'}
                  </Text>
                  <Markdown size='md'>{message.content}</Markdown>
                </VerticalStack>
              ))}
            {messages.length === 0 && !loading && <StartComponent />}
            {lastAssistantMessage === null && loading && (
              <div className={styles.loadingDots}>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
              </div>
            )}
          </div>
          <HorizontalStack>
            <TextInput
              variant='dark'
              size='xl'
              rightComponent={
                <FontAwesomeIcon icon={faArrowUp} style={{ color: '#808080' }} fontSize={15} />
              }
              placeholder='Scrivi il tuo messaggio...'
              value={keyword}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </HorizontalStack>
        </div>
      </div>
    </div>
  )
}
