import style from './yearRecapCard.module.css'

export const ErrorCard = () => {
  return (
    <>
      <div className={style.mainTitle} />
      <div style={{ height: '2rem' }} />
      <div className={style.container}>
        <div className={style.errorContainer}>
          <h1 className={style.coursesText}>Oops 😅, qualcosa è andato storto</h1>
          <p className='text-center'>
            Non riusciamo a caricare le tue statistiche.
            <br />
            Prova a ricaricare la pagina.
          </p>
        </div>
      </div>
    </>
  )
}
