import { useContext } from 'react'
import style from './discussionPopup.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ControlsContext } from '../controls/controlsContext'
import { IFrame } from '../../IFrame/IFrame'
import { Title, useTheme } from '@learnn/designn'

export const DiscussionPopup = ({
  circlePostUrl
}: { circlePostUrl?: string }) => {
  const { state, dispatch } = useContext(ControlsContext)
  const { spacing } = useTheme()

  if (!state.communityVisible) return null

  return (
    <>
      <div
        className={style.container}
        onClick={event => {
          event.stopPropagation()
          event.preventDefault()
          dispatch({
            ...state,
            communityVisible: false
          })
        }}>
        <div className={style.content}>
          <FontAwesomeIcon
            icon={faTimes}
            className={style.closeIcon}
            onClick={e => {
              e.stopPropagation()
              dispatch({
                ...state,
                communityVisible: false
              })
            }}
          />
          <div
            className={style.box}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}>
            <Title variant='heading2xl' mb={spacing.space_3}>
              Discussione
            </Title>
            <IFrame fullHeight styles={{flex: 1, height: 'unset !important', marginBottom: '50px'}} url={`${circlePostUrl}?iframe=true&post=true&automatic_login=true`} />
          </div>
        </div>
      </div>
    </>
  )
}
