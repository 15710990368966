import api from './api'

import { formatISO } from 'date-fns'
import { ILessonBrowserElement } from '../components/LessonsBrowser/LessonBrowser'
import { createProgressHash } from '../utils/course'
import { durationinMinutes } from '../utils/time'
import {
  extractThumbUrlFromImage,
  getAuthorsNames,
  getUrlFromLesson,
  sortLabels,
} from '../utils/data'
import { CourseSummary, Lesson } from '@learnn/sdk/src/api/player'
import { LessonEngagement, LikeState } from '@learnn/sdk/src/api/engagement'
import {
  CourseLessons,
  CourseLessonsEngagement,
  ModuleEngagement,
} from '@learnn/sdk/src/api/course'
import env from '../env.json'

export type SyncProgress = {
  lessonId: string
  courseId: string
  currentViewTime: number
}

export type EngagementWebPlayer = {
  moduleId: string
  courseId: string
}

export type PlayerLesson = Lesson & {
  courseImageUrl: string
  courseSummary: CourseSummary
  currentViewTime: number
  maxViewTime: number
  myLearnnId: string | undefined
  nextLessonId: string | undefined
  progress: number
  likeState: LikeState
}

export const getLesson = async (lessonId: string) => {
  try {
    const { player, engagement, course } = await api

    const toPlayer = (
      l: Lesson,
      engagement: LessonEngagement,
      courseLessons: CourseLessons,
      courseEngagement: CourseLessonsEngagement,
    ): PlayerLesson => {
      const { courseSummary, progress, currentViewTime, maxViewTime, myLearnnId } = engagement
      const { lesson_id } = courseSummary

      const module = courseLessons.modules.find(el => el.lessons.find(el => el.id === lessonId))

      if (!module) throw new Error('Module not found')

      //@ts-ignore //TODO
      const moduleEngagement: ModuleEngagement | null = courseEngagement.modules[module.id]
        ? //@ts-ignore //TODO
          courseEngagement.modules[module.id]
        : null

      const getNextLessonId = (currentLessonId: string, courseLessons: CourseLessons) => {
        let found = false
        for (const module of courseLessons.modules) {
          for (const lesson of module.lessons) {
            if (found) return lesson.id
            if (lesson.id === currentLessonId) {
              found = true
            }
          }
        }
        return undefined
      }

      return {
        ...l,
        courseSummary: {
          course_id: courseLessons.id,
          course_title: courseLessons.title,
          course_type: courseLessons.type.slug,
          authors: getAuthorsNames(courseLessons.authors),
          circlePostUrl: courseLessons.circlePostUrl,
          categories_ids: courseLessons.categories
            ? courseLessons.categories?.map(el => el.id).join(',')
            : '',
          last_of_course: false, //l.id === last_course_lesson,
          last_of_module: false, //l.id === last_module_lesson,
          lesson_completed: progress >= env.LESSON_COMPLETED_THRESHOLD,
          lesson_id,
          lesson_progress: progress,
          lesson_started: progress > 0,
          lesson_title: l.title,
          lesson_type: l.contentType === 'text' ? 'text' : 'video',
          module_id: module.id,
          module_title: module.title,
          total_lessons: module.lessons.length,
          total_modules: courseLessons.modules.length, //TODO centralize checks
          total_course_lessons: courseLessons.lessonsCount ?? 0,
          total_module_lessons: module.lessons.length,
          course_completed: courseEngagement.progress >= env.COURSE_COMPLETED_THRESHOLD,
          course_started: courseEngagement.progress > 0,
          module_completed: moduleEngagement ? moduleEngagement.module_progress === 100 : false,
          module_started: moduleEngagement ? moduleEngagement.module_progress > 0 : false,
          course_progress: courseEngagement.progress,
          module_progress: moduleEngagement ? moduleEngagement.module_progress : 0,
          course_started_lessons: courseEngagement?.lessonsInProgress ?? 0,
          course_completed_lessons: courseEngagement?.lessonsCompleted ?? 0,
          module_started_lessons: moduleEngagement?.lessonsInProgress ?? 0,
          module_completed_lessons: moduleEngagement?.lessonsCompleted ?? 0,
        },
        courseImageUrl: courseLessons.coverImage,
        progress: progress,
        currentViewTime:
          currentViewTime < l.duration
            ? Math.round((currentViewTime + Number.EPSILON) * 100) / 100
            : l.duration,
        maxViewTime: maxViewTime < l.duration ? maxViewTime : l.duration,
        myLearnnId: myLearnnId ?? undefined,
        nextLessonId: getNextLessonId(lessonId, courseLessons),
        imageUrl: l.imageUrl ?? courseLessons.coverImage,
        likeState: engagement.likeState,
      }
    }

    return await Promise.all([
      player.getLesson(lessonId),
      course.getCourseByLessonId(lessonId),
      engagement.getLesson(lessonId),
    ]).then(async ([lesson, courseLessons, lessonEngagement]) => {
      const courseEngagement = await engagement.getCourse(courseLessons.id)
      return toPlayer(lesson, lessonEngagement, courseLessons, courseEngagement)
    })
  } catch (e) {
    console.warn(e)
    throw e
  }
}

export const getLessonPreview = async (lessonId: string) => {
  try {
    const { player } = await api

    const lesson = await player.getPreview(lessonId)

    return lesson
  } catch (e) {
    console.warn(e)
    throw e
  }
}

export const getLessonNotes = async (lessonId: string) => {
  try {
    const { note } = await api
    const notes = await note.getLessonNotes(lessonId)

    return notes.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))
  } catch (error) {
    console.warn(error)
    throw error
  }
}

export const syncProgress = async ({ lessonId, courseId, currentViewTime }: SyncProgress) => {
  try {
    const { engagement } = await api
    await engagement.saveProgress(lessonId, courseId, currentViewTime, formatISO(new Date()))
  } catch (error) {
    throw error
  }

  return
}

export const getEngagementWebPlayer = async ({ moduleId, courseId }: EngagementWebPlayer) => {
  try {
    const { engagement } = await api

    const lessonsQuery = await engagement.getWebPlayer(moduleId, courseId)

    if (
      lessonsQuery.data.moduleCourse?.lessons &&
      lessonsQuery.data.moduleCourse?.lessons?.length > 0 &&
      lessonsQuery.data.userProgresses
    ) {
      const progress = createProgressHash(lessonsQuery.data.userProgresses)
      const lessons: ILessonBrowserElement[] = lessonsQuery.data.moduleCourse?.lessons
        ?.filter((l: any) => !l?.draft)
        .sort((a: any, b: any) => sortLabels(a?.label, b?.label))
        .map((lesson: any) => {
          return {
            title: lesson?.title || '',
            label: lesson?.label || '',
            image: extractThumbUrlFromImage(
              lesson?.cover || lessonsQuery.data.course?.coverImage,
              'small',
            ),
            id: lesson?.id || '',
            progressPercentage:
              //@ts-ignore //TODO
              progress && lesson?.id && progress[lesson?.id] ? progress[lesson?.id] : 0,
            duration: durationinMinutes(lesson?.duration) || '',
            linkTo: getUrlFromLesson(lesson),
          }
        })

      return {
        moduleName: lessonsQuery.data.moduleCourse.title,
        lessons,
      }
    }

    return {
      moduleName: '',
      lessons: [],
    }
  } catch (error) {
    throw error
  }
}
