import { FC, useEffect, useState, useRef } from "react";
import style from "./styles/nextLesson.module.css";
import closeImage from "../../../assets/images/icons/close.svg";
import playimage from "../../../assets/images/icons/play-black.svg";
import env from "../../../env.json";

export interface INextLesson {
  onClick(): void;
  onDismiss(): void;
  onEnded(): void;
  visible?: boolean;
  dismissed?: boolean;
}

export const NextLesson: FC<INextLesson> = ({ onClick, onEnded, onDismiss, visible, dismissed }) => {
  const [width, setWidth] = useState(0);
  const timeRef = useRef(0);
  const timer = useRef(-1);
  const _dismissed = useRef(dismissed);

  useEffect(() => {
    if (visible) {
      onEnded();
      timer.current = window.setInterval(function () {
        timeRef.current = timeRef.current + 1;
        setWidth(prevWidth => prevWidth + 1);

        if (timeRef.current >= 100) {
          window.clearInterval(timer.current);
        }
      }, env.NEXT_LESSON_TIMER_DURATION / 100);
      setTimeout(() => {
        if (!_dismissed.current)
          onClick()
      }, env.NEXT_LESSON_TIMER_DURATION);
    }
  }, [visible]);

  useEffect(() => {
    _dismissed.current = dismissed;
  }, [dismissed])

  return visible ? (
    <div className={style.overlay}>
    <div className={style.container}>
      <button
        className={style.closeContainer}
        onClick={() => {
          onDismiss();
        }}>
        <div className={style.closeImage} style={{ backgroundImage: `url(${closeImage}` }} />
      </button>
      <button className={style.nextLesson} onClick={onClick}>
        <div className={style.progress} style={{ width: `${width}%` }}></div>
        <div className={style.playImage} style={{ backgroundImage: `url(${playimage}` }} />
        <h4 className={style.text}>Prossima Lezione</h4>
      </button>
    </div>
    </div>
  ) : (
    <></>
  );
};
