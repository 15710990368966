import { PreferencesPopupContext } from './popupContext'
import { usePreferencesPopup } from './usePreferencesPopup'
import { FC, useContext, useEffect } from 'react'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { getUserProfile } from '../../controllers/Profile'
import { Box, useTheme, HorizontalStack, VerticalStack } from '@learnn/designn'
import { useQuery } from 'react-query'
import { matchQuery } from '../../utils/matchQuery'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/lib/function'
import style from './preferencesPopup.module.css'
import { Preferences } from '../../screens/PreferencesScreen/Preferences'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export type PreferencesPopupProps = {
  onSubmit: () => void
}
export const PreferencesPopup: FC<PreferencesPopupProps> = ({ onSubmit }) => {
  const { state } = useContext(PreferencesPopupContext)
  const { close } = usePreferencesPopup()
  const { spacing } = useTheme()

  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  if (!userId) return (<></>)

  const profileQuery = useQuery(['profile', userId], () => getUserProfile(userId), {
    enabled: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (state.show) {
      profileQuery.refetch()
    }
  }, [state.show])

  switch (state.show) {
    case true: {
      return (
        <div
          className={style.popupContainer}
          onClick={event => {
            event.preventDefault()
            close()
          }}>
          <div
            className={style.content}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}>
            <div className={style.box}>
              {matchQuery(profileQuery, {
                loading: () => (
                  <VerticalStack maxWidth='900px'>
                    <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
                      <Box>
                        <Skeleton height='500px' borderRadius={10} />
                      </Box>
                      <HorizontalStack justifyContent='center'>
                        <Box mt={spacing.space_3}>
                          <Skeleton height={spacing.space_10} width='150px' borderRadius={10} />
                        </Box>
                      </HorizontalStack>
                    </SkeletonTheme>
                  </VerticalStack>
                ),
                error: () => <></>,
                success: data =>
                  pipe(
                    data,
                    O.fold(
                      () => <Preferences submitPosition='bottom' noBorder onSubmit={() => {onSubmit(); close()}}/>,
                      profile => <Preferences profile={profile} submitPosition='bottom' noBorder onSubmit={() => {onSubmit(); close()}}/>,
                    ),
                  ),
              })}
            </div>
          </div>
        </div>
      )
    }

    default: {
      return <></>
    }
  }
}
