import { gql } from '@apollo/client';
export const profile = (client, API_URL, getToken) => {
    const getProfile = async (userId) => {
        try {
            const profileResult = await client.query({
                query: USER_PROFILE,
                variables: { filter: { userId: { eq: userId } } },
            });
            const profile = profileResult.data?.profiles?.items[0];
            if (!profile)
                throw new Error(`Not Found`);
            return { ...profile, profileImage: profile.profileImage?.cdnUrl };
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const editProfile = async (profile) => {
        try {
            const profileResult = await client.mutate({
                mutation: UPDATE_USER_PROFILE,
                variables: { data: profile },
            });
            const newProfile = profileResult.data?.editProfile;
            if (!newProfile)
                throw new Error(`Not Found`);
            return { ...newProfile, profileImage: newProfile.profileImage?.cdnUrl };
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const uploadProfileImage = async (file) => {
        if (!getToken()) {
            return false;
        }
        const formData = new FormData();
        formData.append('file', file);
        const response = await fetch(`${API_URL}/upload-profile-image`, {
            method: 'POST',
            headers: {
                Authorization: `${getToken()}`,
            },
            body: formData,
        });
        if (!response.ok) {
            throw new Error('Upload failed');
        }
        return true;
    };
    const getCompanyRoles = async () => {
        const response = await fetch(`${API_URL}/default-values?key=companyRoles`, {
            method: 'GET',
        });
        if (!response.ok) {
            return [];
        }
        return response.json();
    };
    const getGoals = async () => {
        const response = await fetch(`${API_URL}/default-values?key=goals`, {
            method: 'GET',
        });
        if (!response.ok) {
            return [];
        }
        return response.json();
    };
    const getSegments = async () => {
        const response = await fetch(`${API_URL}/default-values?key=segments`, {
            method: 'GET',
        });
        if (!response.ok) {
            return [];
        }
        return response.json();
    };
    return {
        get: getProfile,
        editProfile: editProfile,
        uploadProfileImage: uploadProfileImage,
        getCompanyRoles: getCompanyRoles,
        getGoals: getGoals,
        getSegments: getSegments,
    };
};
const USER_PROFILE = gql `
  query GetProfile($filter: ProfileFilterInput!) {
    profiles(filter: $filter, limit: 1) {
      items {
        profileId
        name
        lastName
        location
        profession
        goal
        company
        bio
        nickname
        linkedinLink
        socialLink
        websiteLink
        availableFor
        competences
        companyRole
        isPublic
        showProgress
        showRefId
        showMarketPlace
        interests
        reminderTime
        reminderDays
        segment
        profileImage {
          cdnUrl
        }
      }
    }
  }
`;
const UPDATE_USER_PROFILE = gql `
  mutation updateProfile($data: EditProfileInput!) {
    editProfile(data: $data) {
      name
      lastName
      location
      goal
      companyRole
      company
      bio
      nickname
      linkedinLink
      socialLink
      websiteLink
      availableFor
      competences
      isPublic
      showProgress
      showRefId
      showMarketPlace
      interests
      reminderTime
      reminderDays
      segment
      profileImage {
        cdnUrl
      }
    }
  }
`;
