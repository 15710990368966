import { useContext, useRef } from 'react'
import playlist from '../../assets/images/icons/playlist.svg'
import { GlobalProviderContext } from '../GlobalProvider'
import { TriggerPopup } from '../TriggerPopup'
import style from './lessonBrowser.module.css'
import { OpenSideType } from '../TriggerPopup/TriggerPopup'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IVideoReadOnlyState } from '../LessonPlayer/useReactPlayer'
import { LessonBrowseTab } from '../LessonPlayer/PlayerSideContent/LessonBrowserTab'

export interface ILessonBrowserElement {
  label: string
  id: string
  progressPercentage: number
  duration: string
  title: string
  image: string
  linkTo: string
}

export interface ILessonBrowser {
  state?: IVideoReadOnlyState
  openSide?: OpenSideType
  visible: boolean

  onClick(): void

  currentLessonId: string
  moduleId: string
  courseId: string
  buttonClassName?: string
  buttonClassNameImage?: string
  popupContainerClass?: string
  icon?: string
}

export const LessonBrowser = ({
  state,
  visible = false,
  onClick,
  currentLessonId,
  moduleId,
  courseId,
  buttonClassName,
  buttonClassNameImage,
  popupContainerClass,
  openSide,
  icon = playlist,
}: ILessonBrowser) => {
  const globalContext = useContext(GlobalProviderContext)
  const nowPlayingRef = useRef<null | HTMLDivElement>(null)
  const userId = globalContext?.userId ?? ''

  return (
    <TriggerPopup
      lockScroll
      buttonClassName={buttonClassName}
      buttonClassNameImage={buttonClassNameImage}
      width='29rem'
      height='40rem'
      visible={visible}
      image={icon}
      zIndex={1000}
      onOpen={() => {
        if (nowPlayingRef.current) {
          nowPlayingRef.current.scrollIntoView({ block: 'end' })
        }
      }}
      onClick={onClick}
      openSide={openSide}
      popupContainer={cx([style.container, popupContainerClass])}>
      <h4 className={style.title}>
        <FontAwesomeIcon onClick={onClick} icon={faTimes} className={cx([style.closeIcon])} />
      </h4>
      <div className={style.innerPlaylist}>
        <LessonBrowseTab
          userId={userId}
          currentLessonId={currentLessonId}
          currentModuleId={moduleId}
          courseId={courseId}
          state={state}
        />
      </div>
    </TriggerPopup>
  )
}
