import { restClient } from './../rest';
export const engagement = (API_URL, getToken, courseEndpoint) => {
    const saveProgress = async (lessonId, courseId, currentViewTime, lastViewDateTime) => {
        if (!getToken()) {
            return;
        }
        const body = {
            lessonId,
            courseId,
            currentViewTime,
            lastViewDateTime,
        };
        try {
            const result = await restClient(getToken()).jsonPost(API_URL, '/engagement/progress', body);
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const saveMyLearnn = async (type, itemId) => {
        if (!getToken()) {
            return;
        }
        try {
            const result = await restClient(getToken()).jsonPost(API_URL, '/engagement/mylearnn', {
                type,
                itemId,
            });
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getSavedCourses = async () => {
        if (!getToken()) {
            throw new Error('Authentication Error');
        }
        try {
            const result = await restClient(getToken()).jsonGet(API_URL, '/engagement/mylearnn?type=course');
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getSavedLessons = async () => {
        if (!getToken()) {
            throw new Error('Authentication Error');
        }
        try {
            const result = await restClient(getToken()).jsonGet(API_URL, '/engagement/mylearnn?type=lesson');
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const removeMyLearnn = async (itemType, itemId) => {
        if (!getToken()) {
            return;
        }
        try {
            await restClient(getToken()).jsonDelete(API_URL, `/engagement/mylearnn?itemType=${itemType}&itemId=${itemId}`);
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getHome = async (courseIds) => {
        const emptyResult = {
            lastProgresses: [],
            coursesEngagement: {},
        };
        if (!getToken()) {
            return emptyResult;
        }
        try {
            const result = await restClient(getToken()).jsonPost(API_URL, '/engagement/home', { courseIds });
            if (!result) {
                return emptyResult;
            }
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getCompletedCourses = async () => {
        const toCompletedCourse = (completedCourse, course) => {
            return {
                ...course,
                progress: completedCourse.progress,
                myLearnnId: completedCourse.myLearnnId,
            };
        };
        if (!getToken()) {
            return [];
        }
        try {
            const result = await restClient(getToken()).jsonPost(API_URL, '/engagement/courses');
            if (!result || result?.completedCourses.length === 0)
                return [];
            const coursesData = [];
            for (const completedCourse of result.completedCourses) {
                const courseData = await courseEndpoint.getCourseBaseInfo(completedCourse.id);
                coursesData.push(toCompletedCourse(completedCourse, courseData));
            }
            return coursesData;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getWebPlayer = async (moduleId, courseId) => {
        const emptyResult = {
            data: {
                course: null,
                moduleCourse: null,
                userProgresses: null,
            },
        };
        if (!getToken()) {
            return emptyResult;
        }
        try {
            const result = await restClient(getToken()).jsonPost(API_URL, '/engagement/web-player', {
                moduleId,
                courseId,
            });
            if (!result) {
                return emptyResult;
            }
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getCourses = async (courseIds) => {
        if (!getToken()) {
            return;
        }
        try {
            const result = await restClient(getToken()).jsonPost(API_URL, '/engagement/courses', {
                courseIds: courseIds.map(id => String(id)),
            });
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getLessons = async (lessonIds) => {
        if (!getToken()) {
            throw new Error('Authentication Error');
        }
        try {
            const result = await restClient(getToken()).jsonPost(API_URL, '/engagement/lessons', {
                lessonIds,
            });
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getCourse = async (courseId) => {
        if (!getToken()) {
            throw new Error('Authentication Error');
        }
        try {
            const result = await restClient(getToken()).jsonGet(API_URL, `/engagement/courses/${courseId}`);
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getLesson = async (lessonId) => {
        if (!getToken()) {
            throw new Error('Authentication Error');
        }
        try {
            const result = await restClient(getToken()).jsonGet(API_URL, `/engagement/lessons/${lessonId}`);
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    const getCourseCharts = async (chartType, limit) => {
        if (!getToken()) {
            return null;
        }
        try {
            const result = await restClient(getToken()).jsonGet(API_URL, `/charts/courses?chartType=${chartType}${limit ? '&limit=' + limit : ''}`);
            return result;
        }
        catch (e) {
            throw e;
        }
    };
    const getCourseBySegment = async (segmentType, limit) => {
        if (!getToken()) {
            throw new Error('Token not provided');
        }
        try {
            const result = await restClient(getToken()).jsonGet(API_URL, `/charts/courses?chartType=${segmentType}${limit ? '&limit=' + limit : ''}`);
            return result;
        }
        catch (e) {
            throw e;
        }
    };
    const likeEngagement = async (type, contentId, state) => {
        if (!getToken()) {
            return;
        }
        try {
            const result = await restClient(getToken()).jsonPost(API_URL, `/engagement/likes`, {
                type,
                contentId,
                state,
            });
            return result;
        }
        catch (e) {
            console.log(e);
            throw e;
        }
    };
    return {
        saveProgress: saveProgress,
        saveMyLearnn: saveMyLearnn,
        getSavedCourses: getSavedCourses,
        getSavedLessons: getSavedLessons,
        removeMyLearnn: removeMyLearnn,
        getHome: getHome,
        getWebPlayer: getWebPlayer,
        getCourses: getCourses,
        getLessons: getLessons,
        getCourse: getCourse,
        getCompletedCourses: getCompletedCourses,
        getLesson: getLesson,
        getCourseCharts: getCourseCharts,
        getCourseBySegment: getCourseBySegment,
        likeEngagement: likeEngagement,
    };
};
