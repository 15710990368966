import { AnimatePresence, motion } from 'framer-motion'
import { FC, useMemo, useState, useEffect, useContext, useRef } from 'react'
import style from './compactModuleGroup.module.css'

import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import arrowDown from '../../../assets/images/icons/arrow-down.png'

import { Lesson, LessonEngagement, Module, ModuleEngagement } from '@learnn/sdk/dist/api/course'
import cx from 'classnames'
import { HorizontalStack, Title, Text, useTheme, VerticalStack } from '@learnn/designn'
import { extractConfiguration, getUrlFromLesson } from 'src/utils/data'
import { GlobalProviderContext } from '../../GlobalProvider'
import { ContextMenu } from '../../ContextMenu'
import { useHistory } from 'react-router-dom'
import { handleContextMenu } from 'src/utils/contextMenu'
import { useCoursePopup } from '../useCoursePopup'
import { CompactLessonItem } from '../CompactLessonItem/CompactLessonItem'
import useAnalytics from 'src/analytics/useAnalytics'


type CompactModuleGroupProps = {
  module: Module & Partial<ModuleEngagement>
  lastProgress?: { id: string; progress: number } | null
  courseId: string
  initVisible?: boolean
  lastItem?: boolean
}

const variants = {
  hidden: { height: 0, opacity: 0 },
  visible: { height: 'auto', opacity: 1 },
}
const variantsLastItem = {
  hidden: { height: 0, opacity: 0 },
  visible: {  height: 'auto', opacity: 1 },
}

const arrowVariants = {
  normal: { rotate: 0 },
  rotated: { rotate: 180 },
}

export const CompactModuleGroup: FC<CompactModuleGroupProps> = ({
  module,
  lastProgress,
  initVisible=false,
  lastItem = false,
}) => {
  const [visible, setVisible] = useState(false)
  const toggleAccordion = () => setVisible(!visible)  
  const history = useHistory()
  const myRef = useRef<null | HTMLDivElement>(null)
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const blocked = !configuration?.premiumVisible && !module.free
  const { close } = useCoursePopup()
  const {spacing} = useTheme()
  const { logCoursePopupOpenLesson } = useAnalytics()

  useEffect(() => {
  }, [initVisible])

  const itemsList = useMemo(
    () =>
      module.lessons.map((item: Lesson & Partial<LessonEngagement>, index) => {
        const lessonUrl = getUrlFromLesson(item);

        return (
          <>
            <ContextMenu menuId={`default`} />  
            <CompactLessonItem
              key={`moduleGroup-${module.id}-${index}`}
              lesson={item}
              continueWatching={lastProgress ? lastProgress.id === item.id : false}
              onClick={() => {
                logCoursePopupOpenLesson(item.id, item.title)
                close();
                history.push(lessonUrl)
              }}
              onContextMenu={(e) => handleContextMenu(e, { url: lessonUrl })}
            /> 
          </>
        )
      }),
    [module.lessons, lastProgress],
  )

  return (
    <motion.div className={style.container} ref={myRef}>
      <div className={style.content} onClick={toggleAccordion}>    
            <VerticalStack justifyContent='center' alignItems='start' gap={spacing.space_1}>
              <HorizontalStack fullwidth gap={spacing.space_4} alignItems='center' justifyContent='space-between'>
                <>
                {blocked ? <FontAwesomeIcon icon={faLock} className={style.lockIcon} /> : <></>}
                <Title variant='headingSm'>{module.title}</Title>
                </>
              </HorizontalStack>

              <VerticalStack alignItems='center' justifyContent='center'>
                    <Text variant='bodyXs'>
                      {module.completed_lessons ?? 0}/{module.module_length} Lezioni -&nbsp;
                      {module.module_duration}
                    </Text>
              </VerticalStack>
            </VerticalStack>

            <div className={style.arrowContainer} data-rotate={visible}>
                  <motion.img
                      variants={arrowVariants}
                      initial='open'
                      animate={visible ? 'rotated' : 'normal'}
                      className={cx([style.arrow, visible ? style.rotated : ''])}
                      src={arrowDown}
                  />
            </div>
            
          <div className={style.lastCourseProgressBarContainer}>
            <div
              className={style.lastCourseProgressBar}
              style={{ width: `${module.module_progress}%` ?? 0 }}
            />
          </div>
      </div>

      <motion.div
        initial='hidden'
        animate={visible ? 'visible' : 'hidden'}
        variants={lastItem ? variantsLastItem : variants}
        className={style.itemsContainer}>
        <AnimatePresence initial={false}>
          {visible ? <motion.div className={style.itemsInner}>{itemsList}</motion.div> : null}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  )
}
