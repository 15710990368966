import { useContext } from "react";
import { useQuery } from "react-query";
import { getCourse } from "src/controllers/Course";
import { matchQuery } from "src/utils/matchQuery";
import { useTheme, VerticalStack } from "@learnn/designn";
import { GlobalProviderContext } from "../GlobalProvider";
import { CompactModules } from "./CompactModules";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export type CoursePopupModulesProps = {
  courseId: string
}

export const CoursePopupModulesSkeleton = () => {
  return (
    <SkeletonTheme baseColor='#27272C' highlightColor='#37393E'>
      <Skeleton style={{ width: '40%', height: '27px', marginTop: '20px'}}></Skeleton>
      <div style={{ marginTop: '8px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Skeleton style={{ width: '100%', height: '73px', marginBottom: '9px', borderRadius: '16px'}}></Skeleton>
        <Skeleton style={{ width: '100%', height: '73px', marginBottom: '9px', borderRadius: '16px' }}></Skeleton>
        <Skeleton style={{ width: '100%', height: '73px', marginBottom: '9px', borderRadius: '16px' }}></Skeleton>
      </div>
    </SkeletonTheme>
  )}

export const CoursePopupModules = ({ courseId }: CoursePopupModulesProps) => {
  const { spacing } = useTheme()
  const globalContext = useContext(GlobalProviderContext);
  const userId = globalContext?.userId ?? ''
  const courseQuery = useQuery(['courseLessons', courseId], () => getCourse(courseId))

  return (
    <>
      {matchQuery(courseQuery, {
        loading: () => <CoursePopupModulesSkeleton/>,
        error: () => <></> ,
        success: (course) => 
            <VerticalStack mt={spacing.space_4}>
                <CompactModules course={course} userId={userId}/>
            </VerticalStack>
      })}
    </>
  )
}
