import { useContext } from 'react'
import { ExpertPopupContext } from './popupContext'
import { SearchExpert } from '@learnn/sdk/dist/api/smart-search';

export const useExpertPopup = () => {
  const { dispatch, state: prevState } = useContext(ExpertPopupContext)

  return {
    showExpert: (expert: SearchExpert) => {
      dispatch({
        expert,
        show: true,
      })
    },
    close: () => {
      dispatch({
        ...prevState,
        show: false,
      })
    },
  }
}
