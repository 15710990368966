import { FC, useState, useEffect } from 'react'
import { TriggerPopup } from '../TriggerPopup'
import { useFeedback } from './useFeedback'
import feedbackIcon from '../../assets/images/icons/feedback.svg'
import thanksImage from '../../assets/images/feedback.gif'
import style from './feedbackPopup.module.css'
import { Button } from '../Button'
import { OpenSideType } from '../TriggerPopup/TriggerPopup'
import cx from 'classnames'
import { InlineSelect, ISelectOption } from '../InlineSelect/InlineSelect'
import { LoadingIndicator } from '../LoadingIndicator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export type IFeedbackPopupProps = {
  lessonId: string
  courseId: string
  visible: boolean
  onClick(): void
  viewTime: number
  openSide?: OpenSideType
  buttonClassName?: string
  buttonClassNameImage?: string
  popupContainerClassName?: string
  selectedCategoryId?: string
  zIndex?: number
}

export const FeedbackPopup: FC<IFeedbackPopupProps> = ({
  lessonId,
  courseId,
  visible,
  onClick,
  viewTime,
  openSide = 'top-left',
  buttonClassName,
  buttonClassNameImage,
  popupContainerClassName,
  selectedCategoryId,
  zIndex = 0,
}) => {
  const feedback = useFeedback(lessonId, courseId)
  const [textArea, setTextArea] = useState('')
  const [categoryId, setCategoryId] = useState<string | undefined>(selectedCategoryId)
  const [showCategoryError, setShowCategoryError] = useState(false)
  const [showTextAreaError, setShowTextAreaError] = useState(false)

  const save = () => {
    setShowTextAreaError(!textArea)
    setShowCategoryError(!categoryId)
    textArea && categoryId && feedback.saveFeedback([categoryId], textArea, viewTime)
  }

  const iconClick = () => {
    if (feedback.sent) {
      feedback.reset()
      setTextArea('')
      setCategoryId(undefined)
    }
    onClick()
  }

  useEffect(() => {
    if (selectedCategoryId !== categoryId) {
      setCategoryId(selectedCategoryId)
    }
  }, [selectedCategoryId])

  return (
    <TriggerPopup
      openSide={openSide}
      width='28.6em'
      height='19em'
      image={feedbackIcon}
      visible={visible}
      onClick={e => {
        e.stopPropagation()
        iconClick()
      }}
      buttonHeight={32}
      buttonWidth={32}
      zIndex={zIndex}
      buttonClassNameImage={buttonClassNameImage}
      buttonClassName={buttonClassName}
      popupContainer={popupContainerClassName}>
      <div className={style.textAreaContainer}>
        <>
          {feedback.sent ? (
            <div className={style.sentContainer}>
              <img className={style.thanksImage} src={thanksImage} />
              <p className={style.thanksText}> ps: no, il tuo feedback non farà questa fine</p>
              <Button
                small
                scheme='dark'
                onClick={e => {
                  e.stopPropagation()
                  iconClick()
                }}
                className={cx([style.buttonClose, 'mr-3'])}>
                Chiudi
              </Button>
            </div>
          ) : !feedback.loading ? (
            <>
              <h4 className={style.title}>
                Invia un feedback al team Learnn
                <FontAwesomeIcon
                  onClick={e => {
                    e.stopPropagation()
                    onClick()
                  }}
                  icon={faTimes}
                  className={cx([style.closeIcon])}
                />
              </h4>
              <div className={style.boxControls}>
                <div className={style.form}>
                  {feedback.feedbackCategories && (
                    <>
                      {showCategoryError ? (
                        <div className={cx([style.error])}>Seleziona una tipologia</div>
                      ) : (
                        <div className={cx([style.name])}>Seleziona una tipologia</div>
                      )}

                      <InlineSelect
                        className={style.selectCategoryContainer}
                        options={feedback.feedbackCategories}
                        value={
                          feedback.feedbackCategories.filter(
                            el => el.value === selectedCategoryId,
                          )[0]
                        }
                        onChange={(value: ISelectOption) => {
                          setShowCategoryError(!value.value)
                          setCategoryId(value.value)
                        }}
                      />
                    </>
                  )}

                  {showTextAreaError ? (
                    <div className={cx([style.error])}>Inserisci un messaggio</div>
                  ) : (
                    <div className={cx([style.name])}>Inserisci un messaggio</div>
                  )}
                  <textarea
                    onClick={e => e.stopPropagation()}
                    onChange={e => {
                      e.stopPropagation()
                      setTextArea(e.target.value)
                      setShowTextAreaError(!e.target.value)
                    }}
                    className={style.textArea}
                    name='feedback'
                    value={textArea}
                    placeholder={`Può essere un suggerimento, una segnalazione di un bug, dirci cosa vorresti, farci i complimenti (ci piacciono sempre i complimenti 😄)`}
                  />
                </div>
                <div className={style.buttonsContainer}>
                  <Button
                    small
                    scheme='dark'
                    onClick={e => {
                      e.stopPropagation()
                      save()
                    }}
                    className={style.button}
                    disabled={feedback.loading}>
                    {!feedback.loading ? 'Invia' : <LoadingIndicator />}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <LoadingIndicator />
          )}
        </>
      </div>
    </TriggerPopup>
  )
}
