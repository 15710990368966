import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { useBodyClass } from '../../utils/hooks'
import styles from './pathScreen.module.css'
import { PathItem } from './components/PathItem'
import { Sidebar } from './components/Sidebar'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { CourseStatus, CourseWithProgress, getPath, getRecommendedPathsBySlug } from '../../controllers/Paths'
import { ErrorMessage } from '../../components/ErrorMessage'
import { useHistory, useParams } from 'react-router-dom'
import { PathCertificateItem, PathStatus } from './components/PathCertificateItem'
import { useContext, useState } from 'react'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { PathAttemptData } from '@learnn/sdk/src/api/quiz'
import { PathCertificateAchieved } from './components/PathCertificateAchieved'
import { useReactive } from '../../utils/useReactive'
import { Course, Path } from '@learnn/sdk/src/api/paths/types'
import { extractConfiguration } from '../../utils/data'
import { HorizontalStack, Title, useTheme, VerticalStack } from '@learnn/designn'
import { ReactComponent as CheckLogo } from '../../assets/images/icons/check.svg'
import { ReactComponent as VerifiedOutlineLogo } from '../../assets/images/icons/verified_outline.svg'
import { ReactComponent as VerifiedLogo } from '../../assets/images/icons/verified.svg'
import { TimelineCountItemCircle } from 'src/components/Timeline/TimelineCountItem'
import env from '../../env.json'
import { VerifiedCertificateItem } from 'src/components/VerifiedCertificateItem'
import { TimelineItem } from 'src/components/Timeline/TimelineItem'
import useAnalytics from 'src/analytics/useAnalytics'
import { CustomCarousel } from 'src/components/Carousel/CustomCarousel'
import { usePathPopup } from 'src/components/PathPopup/usePathPopup'
import { PathListItem } from './components/PathListItem'
import { useQuery } from 'react-query'
import { matchQuery } from 'src/utils/matchQuery'

export const PathScreen = () => {
  useBodyClass('app')
  const history = useHistory()
  const { slug } = useParams<{ slug: string }>()
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const quizAvailability = configuration?.quizAvailability ?? {}
  const userId = globalContext?.userId ?? ''
  const [receviedPathAttempt, setReceivedPathAttempt] = useState<PathAttemptData | null>()
  const { spacing } = useTheme()
  const { logOpenPathCourse } = useAnalytics()
  const { show } = usePathPopup()
  const recommendedPathsQuery = useQuery(['recommended-paths', slug], () => getRecommendedPathsBySlug(slug))

  const handlePathClick = (
    path: Path
  ) => {
    show(path)
  }

  const { data, error } = useReactive({
    observableFn$: getPath(slug, userId),
    queryKey: ['path', slug, userId],
  })

  const getActionByStatus = (status: CourseStatus | undefined, courseId: string) =>
    status === 'to_verify' || status === 'verified'
      ? history.push(`/corso/${courseId}`, { selectedTab: 'verified' })
      : history.push(`/corso/${courseId}`)

  const renderTimelineItem = (item: Course & Partial<CourseWithProgress>) => {
    switch (item.status) {
      default:
      case 'to_start':
      case 'to_finish':
        return undefined
      case 'completed':
        return (
          <TimelineCountItemCircle
            containerStyle={{ backgroundColor: 'white' }}
            innerStyle={{ padding: spacing.space_3 }}
          >
            <CheckLogo className={styles.statusIcon} />
          </TimelineCountItemCircle>
        )
      case 'to_verify':
        return (
          <TimelineCountItemCircle
            hideCircle
          >
            <VerifiedOutlineLogo className={styles.statusIcon} />
          </TimelineCountItemCircle>
        )
      case 'verified':
        return (
          <TimelineCountItemCircle
            hideCircle
          >
            <VerifiedLogo className={styles.statusIcon} />
          </TimelineCountItemCircle>
        )
    }
  }

  return pipe(
    error,
    O.fold(
      () =>
        pipe(
          data,
          O.fold(
            () => <></>,
            data => {
              const verifiedCount =
                data.courses
                  .filter(c => c.type.slug === 'course')
                  .filter(course => !!quizAvailability[course.id]).length
              const getPathStatus = (): PathStatus => {
                const isVerificationAvailable = verifiedCount && verifiedCount > 0
                if (!isVerificationAvailable) return 'unavailable'

                const isEngagementLoading = data.courses
                  .reduce((arr: boolean[], course: Course & Partial<CourseWithProgress>) => {
                    if (course.status === undefined) return [...arr, true]
                    return [...arr, false]
                  }, [])
                  .every(el => el)
                const isPathCompleted = data.courses
                  .filter(course => !!quizAvailability[course.id])
                  .reduce((arr: boolean[], course: Course & Partial<CourseWithProgress>) => {
                    if (
                      (course.status &&
                        (course.status === 'completed' || course.status === 'verified')) ||
                      course.type.slug !== 'course'
                    )
                      return [...arr, true]
                    return [...arr, false]
                  }, [])
                  .every(el => el)

                return isEngagementLoading
                  ? 'loading'
                  : isPathCompleted
                    ? 'to_verify'
                    : 'in_progress'
              }

              console.log('path', data)

              return (
                <AppLayout backButton title={data.title} showLastProgress={false}>
                  <div className={styles.container}>
                    <HorizontalStack>
                      <div className={styles.modules}>
                        <VerticalStack>
                          {data.courses.map((course: Partial<CourseWithProgress>, i) => {
                            let previousCompleted =
                              i > 0 && (data.courses[i - 1].progress ?? 0) > env.COURSE_COMPLETED_THRESHOLD;
                            let nextCompleted =
                              i < data.courses.length - 1 && (data.courses[i + 1].progress ?? 0) > env.COURSE_COMPLETED_THRESHOLD;

                            return (
                              <TimelineItem
                                data={course}
                                index={i}
                                previousConnected={(course.progress ?? 0) > env.COURSE_COMPLETED_THRESHOLD && previousCompleted}
                                nextConnected={(course.progress ?? 0) > env.COURSE_COMPLETED_THRESHOLD && nextCompleted}
                                hidePrevious={i === 0}
                                hideNext={verifiedCount === 0}
                                renderComponent={(course) => (
                                  <PathItem
                                    item={course}
                                    onClick={() => {
                                      logOpenPathCourse(data.id, data.title, course.id, course.title)
                                      getActionByStatus(course.status, course.id)
                                    }}
                                    isCompact={false}
                                  />
                                )}
                                progressPercentage={course.progress ?? 0}
                                label={`${i + 1}`}
                                renderTimelineItem={() => renderTimelineItem(data.courses[i])}
                              />
                            );
                          })}

                          {verifiedCount > 0 && (
                            <TimelineItem
                              data={null}
                              progressPercentage={getPathStatus() === 'verified' ? 100 : 0}
                              index={data.courses.length}
                              previousConnected={getPathStatus() === 'verified'}
                              nextConnected={false}
                              hidePrevious={false}
                              hideNext={true}
                              renderComponent={() => (
                                <VerifiedCertificateItem
                                  label={
                                    getPathStatus() === 'verified'
                                      ? 'Complimenti hai ottenuto la verifica del percorso!'
                                      : verifiedCount === 1
                                        ? 'Completa la verifica del corso mancante per ottenere la certificazione del percorso'
                                        : `Completa le ${verifiedCount} verifiche dei corsi per ottenere la certificazione del percorso`
                                  }
                                  isVerified={getPathStatus() === 'verified'}
                                />
                              )}
                              label={`${data.courses.length + 1}`}
                            />
                          )}
                        </VerticalStack>
                      </div>

                      <div className={styles.sidebar}>
                        <Sidebar path={data} />
                        <PathCertificateItem
                          path={data}
                          userId={userId}
                          progress={data.progress}
                          status={getPathStatus()}
                          containerStyle={styles.moduleItem}
                          onCertificationReceived={attempt => {
                            setReceivedPathAttempt(attempt)
                          }}
                          type='sidebar'
                        />
                      </div>
                    </HorizontalStack>

                    {
                      matchQuery(recommendedPathsQuery, {
                        loading: () => <></>,
                        error: () => <></>,
                        success: (data) => (
                          <VerticalStack>
                            <CustomCarousel
                              title={'Percorsi suggeriti'}
                              data={data}
                              carouselKind='course'
                              renderSlide={(path: Path) => {
                                return (
                                  <PathListItem path={path} onClick={() => handlePathClick(path as Path)} size='md' />
                                )
                              }}
                            />
                          </VerticalStack>
                        )
                      })
                    }

                  </div>

                  {receviedPathAttempt && (
                    <PathCertificateAchieved
                      attempt={receviedPathAttempt}
                      onSubmitClick={() => setReceivedPathAttempt(null)}
                      path={data}
                    />
                  )}
                </AppLayout>
              )
            },
          ),
        ),
      () => <ErrorMessage />,
    ),
  )
}
