import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'
import style from './pathPopup.module.css'
import { Text, Title, VerticalStack } from '@learnn/designn'
import { PathPopupContext } from './popupContext'
import { usePathPopup } from './usePathPopup'
import { PathItem } from 'src/screens/PathScreen/components/PathItem'
import { PopupHeader } from './PopupHeader'
import { GlobalProviderContext } from '../GlobalProvider'
import { extractConfiguration } from 'src/utils/data'
import { ReactComponent as VerifiedLogo } from '../../assets/images/icons/verified.svg'
import { useQuery } from 'react-query'
import { getPathAttempt } from 'src/controllers/Quiz'
import { PathAttemptData } from '@learnn/sdk/dist/api/quiz'
import { matchQuery } from 'src/utils/matchQuery'
import { TimelineItem } from '../Timeline/TimelineItem'
import { ResponsiveImage } from '../ResponsiveImage/ResponsiveImage'
import useAnalytics from 'src/analytics/useAnalytics'

export const PathPopup = () => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const userId = globalContext?.userId ?? ''

  const { state } = useContext(PathPopupContext)
  const { close } = usePathPopup()
  const history = useHistory()
  const { logPathPopupOpenCourse } = useAnalytics()

  const quizAvailability = configuration?.quizAvailability ?? {}

  const goToPath = (slug: string) => {
    close()
    history.push(`/paths/${slug}`)
  }

  const handlePathItemClick = (id: string) => {
    close()
    history.push(`/corso/${id}`)
  }

  switch (state.show) {
    case true: {
      const verifiedCount = state.path.courses
        .filter(c => c.type.slug === 'course')
        .filter(course => !!quizAvailability[course.id]).length

      const attemptQuery = useQuery(
        ['path-attempt', state.path.id],
        () => getPathAttempt(userId, state.path.id),
        {
          refetchOnReconnect: false,
          refetchOnWindowFocus: false,
        },
      )

      let isPathVerified = false
      {
        matchQuery(attemptQuery, {
          loading: () => <></>,
          error: () => <></>,
          success: (attempt: PathAttemptData | null) => {
            isPathVerified = !!attempt
          },
        })
      }

      return (
        <div
          className={style.popupContainer}
          onClick={event => {
            event.preventDefault()
            close()
          }}>
          <div className={style.content}>
            <CloseIcon className={style.closeIcon} onClick={() => close()} />
            <div
              className={style.box}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}>
              <div className={style.imageContainer}>
                {state.path.image && (
                  <ResponsiveImage wrapperClassName={style.borderedImage} src={state.path.image} />
                )}
              </div>
              <div className={style.card}>
                <div>
                  <PopupHeader path={state.path} onCtaClick={() => goToPath(state.path.slug)} />

                  <VerticalStack>
                    {state.path.courses.map((data, index) => (
                      <TimelineItem
                        data={data}
                        index={index}
                        previousConnected={false}
                        nextConnected={false}
                        hidePrevious={index === 0}
                        hideNext={verifiedCount === 0}
                        renderComponent={(data) => {
                          return (
                            <PathItem
                              item={data}
                              onClick={() => {
                                logPathPopupOpenCourse(state.path.id, state.path.title, data.id, data.title)
                                handlePathItemClick(data.id)
                              }}
                              isCompact={true}
                            />
                          )
                        }}
                        label={`${index + 1}`}
                      />
                    ))}
                    {verifiedCount > 0 && (
                      <TimelineItem
                        data={null}
                        progressPercentage={isPathVerified ? 100 : 0}
                        index={state.path.courses.length}
                        previousConnected={false}
                        nextConnected={false}
                        hidePrevious={false}
                        hideNext={true}
                        renderComponent={() => {
                          return (
                            <div className={style.verifiedContainer}>
                              <div className={style.verifiedColumn}>
                                <div className={style.verified}>
                                  <VerifiedLogo className={style.detailIcon} />
                                  <Title variant='headingSm' fontWeight='bold'>
                                    LEARNN VERIFIED
                                  </Title>
                                </div>
                                <Text variant='bodyXs' alignment='center'>
                                  {isPathVerified
                                    ? 'Complimenti hai ottenuto la verifica del percorso!'
                                    : verifiedCount === 1
                                    ? `Completa la verifica del corso mancante per ottenere la certificazione del percorso`
                                    : `Completa le ${verifiedCount} verifiche dei corsi per ottenere la certificazione del percorso`}
                                </Text>
                              </div>
                            </div>
                          )
                        }}
                        label={`${state.path.courses.length + 1}`}
                      />
                    )}
                  </VerticalStack>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    default: {
      return <></>
    }
  }
}
