import styles from './assistantPopup.module.css'
import { useAssistantPopup } from './useAssistantPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { FC, useEffect } from 'react'

import { FlowiseChatGlobal } from '../FlowiseChat/FlowiseChatGlobal'
import useAnalytics from '../../analytics/useAnalytics'
import { ProfileWithInterests } from '@learnn/sdk/dist/api/profile'

type FlowisePopupComponentProps = {
  profile?: ProfileWithInterests
}

export const FlowisePopupComponent: FC<FlowisePopupComponentProps> = ({ profile }) => {
  const { logAiGlobalAssistantOpen } = useAnalytics()
  const { close, state: assistantState } = useAssistantPopup()

  useEffect(() => {
    if (assistantState.show)
      logAiGlobalAssistantOpen()
  }, [assistantState.show])

  return (
    <div style={{display: assistantState.show ? 'flex' : 'none'}} className={styles.overlay} onClick={close}>
      <div className={styles.popup} onClick={e => e.stopPropagation()}>
        <FontAwesomeIcon
          icon={faTimes}
          className={styles.closeIcon}
          onClick={e => {
            e.stopPropagation()
            close()
          }}
        />
        <div className={styles.content}>
          <FlowiseChatGlobal profile={profile} />
        </div>
      </div>
    </div>
  )
}
