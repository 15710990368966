import { FC, useContext, useEffect } from "react";
import { GlobalProviderContext } from "../GlobalProvider";
import env from '../../env.json'


export const Onboarding: FC = ({ children }) => {
    const globalContext = useContext(GlobalProviderContext)
    const acData = globalContext?.acData

    useEffect(() => {
        if (acData && acData.tags && Array.isArray(acData.tags)) {
            const onboardingCompletato = acData.tags.some((obj: string) => obj === 'AZIONE: completato onboarding CF');
            const multistepCompletato = acData.tags.some((obj: string) => obj === 'AZIONE: completato multistep');

            const hasCompletedOnboarding = onboardingCompletato || multistepCompletato
            if (!hasCompletedOnboarding && env.NODE_ENV === 'production') {
                window.location.href = env.ONBOARDING_URL
            }
        }

    }, [acData])

    return (
        <>{children}</>
    )
}