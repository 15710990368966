import { gql } from '@apollo/client';
import { addDaysToDate, isVerifiedAvailable } from '../utils';
import { getQuizCourse } from '../dynamic-sections/libs';
const QUIZ_RETRY_TIME_DAYS = 2;
export const quiz = (client, cmsClient, configuration, engagement) => {
    const getCourseLessons = async (courseId) => {
        const lessonsResult = await client.query({
            query: COURSE_LESSONS_BY_KEY,
            variables: { courseId: courseId },
        });
        const lessons = lessonsResult.data?.lessons;
        if (!lessons)
            throw new Error(`Lessons of ${courseId} not found`);
        return lessons;
    };
    const generateQuiz = async (generator) => {
        const quizResult = await client.mutate({
            mutation: GENERATE_QUIZ,
            variables: { data: generator },
        });
        const quiz = quizResult.data?.generateAttempt;
        if (!quiz)
            throw new Error(`Attempt generation failed`);
        return {
            ...quiz,
            questions: quiz.questions.map(q => ({
                questionId: q.question.questionId,
                text: q.question.text,
                answers: q.answers,
            })),
        };
    };
    const generateDailyQuiz = async (dailyQuizId) => {
        const quizResult = await client.mutate({
            mutation: GENERATE_DAILY_QUIZ,
            variables: { data: { dailyQuizId } },
        });
        const quiz = quizResult.data?.generateDailyQuizAttempt;
        if (!quiz)
            throw new Error(`Attempt generation failed`);
        return {
            ...quiz,
            questions: quiz.questions.map(q => ({
                questionId: q.question.questionId,
                text: q.question.text,
                answers: q.answers,
            })),
        };
    };
    const generatePathAttempt = async (pathId) => {
        const pathSignedResult = await client.query({
            query: GET_PATH_SIGNED,
            variables: { pathId: pathId },
        });
        if (!pathSignedResult.data.path)
            throw new Error(`Attempt generation failed`);
        const input = {
            id: pathSignedResult.data.path.id,
            visible: pathSignedResult.data.path.visible,
            _signature_: pathSignedResult.data.path._signature_,
            iat: pathSignedResult.data.path.iat,
            exp: pathSignedResult.data.path.exp,
            courses: pathSignedResult.data.path.courses.map(c => ({
                course: {
                    id: c.course.id,
                    draft: c.course.draft,
                    type: {
                        slug: c.course.type.slug,
                    },
                },
            })),
        };
        const quizResult = await client.mutate({
            mutation: GENERATE_PATH_ATTEMPT,
            variables: { data: input },
        });
        const quiz = quizResult.data?.generatePathAttempt;
        if (!quiz)
            throw new Error(`Attempt generation failed`);
        return quiz;
    };
    const generateInfiniteQuiz = async () => {
        const quizResult = await client.mutate({
            mutation: GENERATE_INFINITE_QUIZ,
            variables: { data: { title: 'Infinite quiz' } },
        });
        const quiz = quizResult.data?.generateInfiniteQuiz;
        if (!quiz)
            throw new Error(`Attempt generation failed`);
        return {
            ...quiz,
            questions: quiz.questions.map(q => ({
                questionId: q.question.questionId,
                text: q.question.text,
                answers: q.answers,
            })),
        };
    };
    const extendInfiniteQuiz = async (attemptId) => {
        const quizResult = await client.mutate({
            mutation: EXTEND_INFINITE_QUIZ,
            variables: { data: { attemptId: attemptId } },
        });
        const result = quizResult.data?.generateInfiniteQuizQuestions;
        if (!result)
            throw new Error(`Questions generation failed`);
        return result.questions.map(q => ({
            questionId: q.question.questionId,
            text: q.question.text,
            answers: q.answers,
        }));
    };
    const terminateQuiz = async (attemptId) => {
        const quizResult = await client.mutate({
            mutation: TERMINATE_ATTEMPT,
            variables: { data: { attemptId } },
        });
        const result = quizResult.data?.terminateAttempt;
        if (!result)
            throw new Error(`Attempt termination failed`);
        return result;
    };
    const saveAnswer = async (attemptId, questionId, answerId) => {
        const answerResult = await client.mutate({
            mutation: SAVE_ANSWER,
            variables: { attemptId, questionId, answerId },
        });
        const userAnswer = answerResult.data;
        if (!userAnswer || !userAnswer.saveUserAnswer)
            throw new Error(`Save user answer failed`);
    };
    const saveInfiniteAnswer = async (attemptId, questionId, answerId) => {
        const answerResult = await client.mutate({
            mutation: SAVE_INFINITE_ANSWER,
            variables: { data: { attemptId, questionId, answerId } },
        });
        const userAnswer = answerResult.data?.saveInfiniteQuizUserAnswer;
        if (!userAnswer)
            throw new Error(`Save user answer failed`);
        return toQuestionDetail(userAnswer.question);
    };
    const getDailyQuiz = async (userId, dailyQuizId) => {
        let attempt;
        const quizResult = await client.query({
            query: SINGLE_DAILY_QUIZ,
            variables: {
                filter: {
                    attempt: {
                        userId: { eq: userId },
                    },
                    dailyQuiz: {
                        dailyQuizId: {
                            eq: dailyQuizId,
                        },
                    },
                },
            },
        });
        const quiz = quizResult.data?.dailyQuiz?.items?.length
            ? quizResult.data?.dailyQuiz?.items[0]
            : undefined;
        if (!quiz)
            throw new Error(`Quiz with id ${dailyQuizId} not found`);
        if (quiz.attempt) {
            attempt = {
                ...quiz.attempt,
                questions: quiz.attempt.questions.map(toQuestionDetail),
            };
        }
        return { ...quiz, ...(attempt && { attempt }) };
    };
    const getDailyQuizList = async (userId, filter, nextToken) => {
        let categoriesHash = {};
        let queryFilter = {
            releaseDate: {
                eq: undefined,
                ge: undefined,
                le: undefined,
            },
        };
        if (filter?.releaseDate)
            queryFilter.releaseDate.eq = filter.releaseDate.toISOString();
        if (filter?.minReleaseDate)
            queryFilter.releaseDate.ge = filter.minReleaseDate.toISOString();
        if (filter?.maxReleaseDate)
            queryFilter.releaseDate.le = filter.maxReleaseDate.toISOString();
        const listResult = await client.query({
            query: DAILY_QUIZ_LIST,
            variables: {
                limit: 5,
                filter: {
                    dailyQuiz: {
                        ...queryFilter,
                        publish: { eq: true },
                    },
                    attempt: {
                        userId: { eq: userId },
                    },
                },
                ...(nextToken && { nextToken }),
            },
        });
        const list = listResult.data?.dailyQuiz?.items?.length ? listResult.data?.dailyQuiz?.items : [];
        if (list.length) {
            try {
                const categoriesId = [...list].map((q) => q.categoriesId).flat();
                const result = await cmsClient.query({
                    query: QUIZ_CATEGORIES,
                    variables: { ids: categoriesId },
                });
                const categories = result.data?.categoryCourses ? result.data.categoryCourses : [];
                categories.forEach((c) => {
                    categoriesHash[c.id] = c.title;
                });
            }
            catch (error) {
                console.log(error);
                categoriesHash = {};
            }
        }
        return list.map((q) => toQuizCard(q, categoriesHash));
    };
    const getAttemptsByCourse = async (courseId, _userId, quizRetryTimeDays) => {
        const attemptsResult = await client.query({
            query: USER_ATTEMPTS,
            variables: { courseId },
        });
        const attempts = [...attemptsResult.data?.attempts];
        if (!attempts)
            throw new Error(`Attempts for ${courseId} not found`);
        const quizPassed = attempts.some((a) => a.passed === true);
        const bestAttemptIndex = getBestAttemptIndex(attempts);
        if (quizPassed) {
            attempts.unshift(...attempts.splice(bestAttemptIndex, 1));
        }
        const sortedAttempts = [...attempts];
        sortedAttempts.sort((a, b) => Date.parse(b.startedAt) - Date.parse(a.startedAt));
        return {
            passed: quizPassed,
            retryDate: sortedAttempts.length > 0
                ? addDaysToDate(new Date(sortedAttempts[0].startedAt), quizRetryTimeDays ?? QUIZ_RETRY_TIME_DAYS)
                : new Date(),
            attempts,
        };
    };
    const getPathAttempt = async (userId, pathId) => {
        const attemptsResult = await client.query({
            query: USER_ATTEMPT,
            variables: {
                filter: {
                    pathId: {
                        eq: pathId,
                    },
                    userId: {
                        eq: userId,
                    },
                    type: {
                        eq: 'PATH',
                    },
                },
            },
        });
        const attempt = attemptsResult.data?.results?.items?.length
            ? attemptsResult.data?.results?.items[0]
            : undefined;
        if (!attempt)
            throw new Error(`Attempts for pathId ${pathId} not found`);
        return attempt;
    };
    const getAttemptDetails = async (attemptId, userId) => {
        const attemptsResult = await client.query({
            query: USER_ATTEMPT,
            variables: {
                filter: {
                    attemptId: {
                        eq: attemptId,
                    },
                    userId: {
                        eq: userId,
                    },
                },
            },
        });
        const attempt = attemptsResult.data?.results?.items?.length
            ? attemptsResult.data?.results?.items[0]
            : undefined;
        if (!attempt)
            throw new Error(`Attempts with id ${attemptId} not found`);
        return {
            ...attempt,
            questions: attempt.questions.map(toQuestionDetail),
        };
    };
    const toQuestionDetail = (q) => {
        const correct = q.answers.some(a => a.correctAnswer && a.userAnswer);
        const answers = [...q.answers];
        return {
            question: { ...q.question, correct },
            answers: answers.sort((a, b) => Number(b.userAnswer) - Number(a.userAnswer)),
        };
    };
    const toQuizCard = (q, hash) => {
        return {
            ...q,
            categories: q.categoriesId
                .map((c) => (hash && hash[c] ? hash[c] : ''))
                .filter((c) => c !== ''),
        };
    };
    const getBestAttemptIndex = (attempts) => {
        let index = -1;
        let maxCorrect = 0;
        for (let i = 0; i < attempts.length; i++) {
            if (attempts[i].passed && attempts[i].correctAnswers > maxCorrect) {
                index = i;
                maxCorrect = attempts[i].correctAnswers;
            }
        }
        return index;
    };
    const getVerifiedStatus = async (userId) => {
        let coursesId = await configuration.quizAvailabilityList();
        let coursesProgressHash = {};
        const quizData = [];
        const coursesEngagement = await engagement.getCourses(coursesId);
        const { coursesEngagement: progresses } = coursesEngagement;
        coursesProgressHash = progresses;
        await Promise.all(coursesId.map(async (id) => {
            try {
                let course;
                const quizStatus = await getAttemptsByCourse(id, userId);
                const isAvailable = isVerifiedAvailable(coursesProgressHash[id].progress ?? 0);
                const getQuizStatus = () => {
                    if (!isAvailable && !quizStatus.passed) {
                        return 'unavailable';
                    }
                    else {
                        if (quizStatus.attempts.length > 0) {
                            if (quizStatus.passed) {
                                return 'passed';
                            }
                            else {
                                if (quizStatus.retryDate > new Date())
                                    return 'failed';
                                if (quizStatus.retryDate <= new Date())
                                    return 'available';
                                return 'unavailable';
                            }
                        }
                        else {
                            return 'available';
                        }
                    }
                };
                const status = getQuizStatus();
                course = await getQuizCourse(cmsClient)(id);
                quizData.push({
                    courseId: id,
                    quizStatus: quizStatus,
                    status: status,
                    available: isAvailable,
                    course,
                    retryDate: quizStatus.retryDate,
                });
            }
            catch (e) {
                console.error(e);
            }
        }));
        return quizData.sort((a, b) => (a.courseId > b.courseId ? 1 : -1));
    };
    const getCourseVerifiedStatus = async (userId, courseId) => {
        let coursesId = await configuration.quizAvailabilityList();
        if (coursesId.filter(id => String(id) === String(courseId)).length === 0)
            throw new Error('Not Found');
        const courseEngagement = await engagement.getCourse(courseId);
        const quizStatus = await getAttemptsByCourse(courseId, userId);
        const isAvailable = isVerifiedAvailable(courseEngagement.progress ?? 0) || quizStatus.passed;
        const getQuizStatus = () => {
            if (!isAvailable) {
                return 'unavailable';
            }
            else {
                if (quizStatus.attempts.length > 0) {
                    if (quizStatus.passed) {
                        return 'passed';
                    }
                    else {
                        if (quizStatus.retryDate > new Date())
                            return 'failed';
                        if (quizStatus.retryDate <= new Date())
                            return 'available';
                        return 'unavailable';
                    }
                }
                else {
                    return 'available';
                }
            }
        };
        const status = getQuizStatus();
        const course = await getQuizCourse(cmsClient)(courseId);
        return {
            courseId,
            quizStatus: quizStatus,
            status: status,
            available: isAvailable,
            course,
            retryDate: quizStatus.retryDate,
        };
    };
    return {
        generateAttempt: generateQuiz,
        generateDailyAttempt: generateDailyQuiz,
        generatePathAttempt: generatePathAttempt,
        getDaily: getDailyQuiz,
        getDailyList: getDailyQuizList,
        terminate: terminateQuiz,
        saveAnswer: saveAnswer,
        saveInfiniteAnswer: saveInfiniteAnswer,
        getCourseLessons: getCourseLessons,
        getAttemptsByCourse: getAttemptsByCourse,
        getAttemptDetails: getAttemptDetails,
        generateInfiniteQuiz: generateInfiniteQuiz,
        extendInfiniteQuiz: extendInfiniteQuiz,
        getVerifiedStatus: getVerifiedStatus,
        getCourseVerifiedStatus: getCourseVerifiedStatus,
        getPathAttempt: getPathAttempt,
    };
};
const COURSE_LESSONS_BY_KEY = gql `
  query courseLessons($courseId: String!) {
    lessons(courseId: $courseId) {
      lessons {
        id
      }
      _signature_
      iat
      exp
    }
  }
`;
const GENERATE_QUIZ = gql `
  mutation quiz($data: GenerateAttempt!) {
    generateAttempt(data: $data) {
      attemptId
      courseId
      type
      startedAt
      expiringAt
      questions {
        question {
          questionId
          text
        }
        answers {
          answerId
          text
        }
      }
    }
  }
`;
const GENERATE_DAILY_QUIZ = gql `
  mutation GenerateDailyQuizAttempt($data: GenerateDailyQuizAttempt!) {
    generateDailyQuizAttempt(data: $data) {
      attemptId
      type
      startedAt
      expiringAt
      questions {
        question {
          questionId
          text
        }
        answers {
          answerId
          text
        }
      }
    }
  }
`;
const GET_PATH_SIGNED = gql `
  query Path($pathId: String!) {
    path(id: $pathId) {
      id
      visible
      courses {
        course {
          id
          draft
          type {
            slug
          }
        }
      }
      _signature_
      iat
      exp
    }
  }
`;
const GENERATE_PATH_ATTEMPT = gql `
  mutation GeneratePathAttempt($data: PathInput!) {
    generatePathAttempt(data: $data) {
      attemptId
      submittedAt
    }
  }
`;
const GENERATE_INFINITE_QUIZ = gql `
  mutation GenerateInfiniteQuiz($data: GenerateInfiniteQuiz!) {
    generateInfiniteQuiz(data: $data) {
      attemptId
      type
      startedAt
      questions {
        question {
          questionId
          text
        }
        answers {
          answerId
          text
        }
      }
    }
  }
`;
const EXTEND_INFINITE_QUIZ = gql `
  mutation GenerateInfiniteQuizQuestions($data: GenerateInfiniteQuizQuestions!) {
    generateInfiniteQuizQuestions(data: $data) {
      questions {
        question {
          questionId
          text
        }
        answers {
          answerId
          text
        }
      }
    }
  }
`;
const SAVE_ANSWER = gql `
  mutation saveUserAnswer($questionId: String!, $answerId: String!, $attemptId: String!) {
    saveUserAnswer(data: { questionId: $questionId, answerId: $answerId, attemptId: $attemptId })
  }
`;
const SAVE_INFINITE_ANSWER = gql `
  mutation saveInfiniteUserAnswer($data: SaveUserAnswer!) {
    saveInfiniteQuizUserAnswer(data: $data) {
      question {
        question {
          questionId
          explanationTime
          explanationLink
          answerExplanation
          text
          lessonId
        }
        answers {
          answerId
          text
          correctAnswer
          userAnswer
        }
      }
    }
  }
`;
const TERMINATE_ATTEMPT = gql `
  mutation terminateAttempt($data: TerminateAttemptInput!) {
    terminateAttempt(data: $data) {
      attemptId
      passed
      correctAnswers
      wrongAnswers
    }
  }
`;
const USER_ATTEMPTS = gql `
  query userAttempts($courseId: String!) {
    attempts(courseId: $courseId) {
      attemptId
      passed
      correctAnswers
      wrongAnswers
      submittedAt
      startedAt
      totalQuestions
    }
  }
`;
const USER_ATTEMPT = gql `
  query GetAttempt($filter: AttemptFilterInput!) {
    results(filter: $filter) {
      items {
        attemptId
        totalQuestions
        correctAnswers
        wrongAnswers
        passed
        courseId
        questions {
          question {
            questionId
            lessonId
            explanationTime
            explanationLink
            answerExplanation
            text
          }
          answers {
            answerId
            text
            correctAnswer
            userAnswer
          }
        }
      }
      nextToken
    }
  }
`;
const SINGLE_DAILY_QUIZ = gql `
  query GetDailyQuiz($filter: GetDailyQuizFilterInput!) {
    dailyQuiz(filter: $filter, limit: 1) {
      items {
        dailyQuizId
        title
        description
        coverImage
        timeLimit
        passedPercentage
        featuredCourseId
        questionsId
        difficulty
        attempt {
          attemptId
          totalQuestions
          correctAnswers
          wrongAnswers
          passed
          questions {
            question {
              questionId
              lessonId
              explanationTime
              explanationLink
              answerExplanation
              text
            }
            answers {
              answerId
              text
              correctAnswer
              userAnswer
            }
          }
        }
      }
    }
  }
`;
const DAILY_QUIZ_LIST = gql `
  query GetDailyQuizList($limit: Int, $filter: GetDailyQuizFilterInput, $nextToken: String) {
    dailyQuiz(limit: $limit, filter: $filter, nextToken: $nextToken) {
      items {
        dailyQuizId
        title
        description
        categoriesId
        coverImage
        timeLimit
        releaseDate
        difficulty
        passedPercentage
        attempt {
          attemptId
          passed
        }
      }
      nextToken
    }
  }
`;
const QUIZ_CATEGORIES = gql `
  query CourseCategories($ids: [ID]) {
    categoryCourses(where: { id: $ids }) {
      id
      title
    }
  }
`;
