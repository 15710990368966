import { useBodyClass } from '../../../../utils/hooks'
import { Title, useTheme, Container } from '@learnn/designn'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import { FlowiseChatCourse } from 'src/components/FlowiseChat/FlowiseChatCourse'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { GlobalProviderContext } from '../../../../components/GlobalProvider'
import { matchQuery } from '../../../../utils/matchQuery'
import { getUserProfileWithInterests } from 'src/controllers/Profile'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export type AssistantSectionProps = {
  course: CourseBaseInfoEngagement
}

export const AssistantSection = ({ course }: AssistantSectionProps) => {
  useBodyClass('app')
  const { spacing } = useTheme()

  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  const profileQuery = useQuery(['profile', userId], () => getUserProfileWithInterests(userId))

  return (
    <Container>
      <Title mb={spacing.space_6} variant='heading2xl'>
        Assistente AI
      </Title>
      {
        <>
          {matchQuery(profileQuery, {
            loading: () => <FlowiseChatCourseSkeleton />,
            error: () => (
              <FlowiseChatCourse
                courseId={course.id}
                courseName={course.title}
                courseDescription={course.description ?? ''}
                courseGoals={course.youLearn ?? ''}
                courseProgress={course.progress}
              />
            ),
            success: profile =>
              pipe(
                profile,
                O.fold(
                  () => (
                    <FlowiseChatCourse
                      courseId={course.id}
                      courseName={course.title}
                      courseDescription={course.description ?? ''}
                      courseGoals={course.youLearn ?? ''}
                      courseProgress={course.progress}
                    />
                  ),
                  profile => (
                    <FlowiseChatCourse
                      courseId={course.id}
                      courseName={course.title}
                      courseDescription={course.description ?? ''}
                      courseGoals={course.youLearn ?? ''}
                      courseProgress={course.progress}
                      profile={profile}
                    />
                  ),
                ),
              ),
          })}
        </>
      }
    </Container>
  )
}

export const FlowiseChatCourseSkeleton = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '100%',
      }}>
      <SkeletonTheme baseColor='#27272C' highlightColor='#37393E'>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '16px',
            marginBottom: '16px',
          }}>
          <Skeleton style={{ height: '52px', borderRadius: '15px' }}></Skeleton>
          <Skeleton style={{ height: '52px', borderRadius: '15px' }}></Skeleton>
          <Skeleton style={{ height: '52px', borderRadius: '15px' }}></Skeleton>
          <Skeleton style={{ height: '52px', borderRadius: '15px' }}></Skeleton>
        </div>
        <Skeleton
          style={{
            width: '100%',
            height: '52px',
            marginBottom: '0px',
            borderRadius: '15px',
          }}></Skeleton>
      </SkeletonTheme>
    </div>
  )
}
