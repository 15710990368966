import { useContext } from 'react'
import { Box, useTheme } from '@learnn/designn'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { useBodyClass } from '../../utils/hooks'
import { CurrentPlan, PaymentMethods, BillingInfo } from './components'
import { PaymentHistory } from './components/PaymentHistory'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { TeamRole } from '@learnn/sdk/dist/api/auth'

const isB2BOwner = (role: TeamRole | undefined) => role === 'owner'
const isB2BUser = (teamId: string | undefined) => teamId !== undefined

export const SubscriptionScreen = () => {
  useBodyClass('app')
  const { spacing } = useTheme()
  const globalContext = useContext(GlobalProviderContext)

  const isB2B = isB2BUser(globalContext?.b2b.teamId)
  const b2bOwner = isB2BOwner(globalContext?.b2b.role)
  const restrictAccess = isB2B && !b2bOwner

  return (
    <AppLayout>
      <div style={{ overflowY: 'scroll' }}>
        <CurrentPlan restrictAccess={restrictAccess} />
        <Box mt={spacing.space_16}>
          <PaymentMethods restrictAccess={restrictAccess} />
        </Box>
        <Box mt={spacing.space_16}>
          <BillingInfo restrictAccess={restrictAccess} />
        </Box>
        <Box my={spacing.space_16}>
          <PaymentHistory restrictAccess={restrictAccess} />
        </Box>
        {
          /**
           <Box my={spacing.space_16}>
            <DeleteAccount restrictAccess={restrictAccess} />
          </Box>
           */
        }
      </div>
    </AppLayout>
  )
}
