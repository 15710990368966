import { motion } from 'framer-motion'
import { FC, MouseEventHandler, useContext } from 'react'
import cx from 'classnames'
import style from './compactLessonItem.module.css'
import { Lesson, LessonEngagement } from '@learnn/sdk/src/api/course'

import { HorizontalStack, Title, useTheme, VerticalStack } from '@learnn/designn'
import { GlobalProviderContext } from '../../GlobalProvider'
import { extractConfiguration } from 'src/utils/data'
import env from '../../../env.json'
import { MyLearnnButton } from '../../MyLearnnButton'


type CompactLessonItem = {
  lesson: Partial<LessonEngagement> & Lesson
  onClick?(): void
  onContextMenu?: MouseEventHandler<HTMLHeadingElement>
  continueWatching: boolean
}

export const CompactLessonItem: FC<CompactLessonItem> = ({
  lesson,
  onClick = () => { },
  onContextMenu,
  continueWatching,
}) => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)

  const { spacing } = useTheme()

  const blocked = !configuration?.premiumVisible && !lesson.free

  const handleClick = () => {
    if (!blocked && onClick) {
      onClick()
    }
  }

  return (
    <motion.div
      className={style.lessonContainer}
      transition={{
        delay: 1.1,
        duration: 0.6,
      }}>
      <motion.div className={style.container}>
        <div
          className={cx([style.innerContainer, blocked && env.PRO_LESSON_CLASS])}
          onClick={handleClick}
          onContextMenu={onContextMenu}>
          <VerticalStack>
                <HorizontalStack>
                    <div>
                        <HorizontalStack alignItems='center'>
                            <Title variant='headingXs' fontWeight='bold' mr={spacing.space_2}> {lesson.title}</Title>
                            
                            {lesson.tags?.length && lesson.tags.length > 0 ? (
                                <HorizontalStack alignItems='center'>
                                {lesson.tags.slice(0, 2).map(tag => (
                                    <div key={`tag-${tag.id}`} className={style.tagBadge} style={{borderWidth: continueWatching ? 1 : 0}}>
                                    {tag.name}
                                    </div>
                                ))}
                                </HorizontalStack>
                            ) : (
                                <></>
                                )}

                            {blocked ? <div className={style.proBadge}>Pro</div> : <></>}
                        </HorizontalStack>
                    </div>      
                </ HorizontalStack>
            </VerticalStack>
          
        </div>
      </motion.div>

      <HorizontalStack justifyContent='end'>
        <div className={style.buttonsContainer}>
          {configuration?.features?.mylearnn !== false && (
            <MyLearnnButton
              itemType='lesson'
              itemId={lesson.id}
              myLearnnId={lesson.myLearnnId}
              containerClass={style.circularButton}
              className={cx([style.myLearnn])}
            />
          )}
        </div>
      </HorizontalStack>
    </motion.div>
  )
}
