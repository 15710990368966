import { useContext, useRef } from 'react'
import { IFrame } from '../components/IFrame/IFrame'
import { AppLayout } from '../layouts/AppLayout/AppLayout'
import { extractConfiguration } from 'src/utils/data'
import { GlobalProviderContext } from 'src/components/GlobalProvider'
import env from '../env.json'

export const PartnerScreen = () => {
  const globalContext = useContext(GlobalProviderContext)
  const blockRef = useRef<HTMLDivElement>()
  const configuration = extractConfiguration(globalContext)

  const setBlockRef = (e: HTMLDivElement) => {
    if (e) {
      blockRef.current = e
    }
  }

  const handleIframeClick = () => {
    if (blockRef.current) {
      blockRef.current.click()
    }
  }


  return (
    <AppLayout hideSecondaryMenu fillcontent hideHeader>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {
          !configuration?.premiumVisible && (
            <div ref={setBlockRef} className={env.PRO_PARTNER_CLASS} style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} onClick={!configuration?.premiumVisible ? handleIframeClick : undefined}>
            </div>
          )
        }
        <IFrame
          url='https://learnn.com/partner'
          fullHeight
        />
      </div>
    </AppLayout>
  )
}
