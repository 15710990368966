import React from 'react'
import style from './cardsGrid.module.css'

export interface ICardsGrid {
  children: React.ReactNode
  type: 'courses' | 'webinars' | 'jobs' | 'verified' | 'path' | 'lessons' | 'expert'
}

export const CardsGrid = ({ children, type }: ICardsGrid) => {
  const getClassname = () => {
    switch (type) {
      case 'verified': {
        return style.containerVerified
        break
      }
      case 'courses': {
        return style.containerCourses
        break
      }
      case 'webinars': {
        return style.containerWebinars
        break
      }
      case 'jobs': {
        return style.containerJobs
        break
      }
      case 'path': {
        return style.containerPath
        break
      }
      case 'expert': {
        return style.containerExperts
        break
      }
      case 'lessons': {
        return style.containerLessons
        break
      }
    }
  }
  return <div className={getClassname()}>{children}</div>
}
