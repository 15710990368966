import { CoursePopupContext } from './popupContext'
import { useCoursePopup } from './useCoursePopup'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useShare } from '../SharePopup/useShare'
import { getCourseHighlight } from '../../controllers/Course'

import { useQueryClient } from 'react-query'

import { ResponsiveImage } from '../ResponsiveImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPlay } from '@fortawesome/free-solid-svg-icons'
import { MyLearnnButton } from '../MyLearnnButton'

import { ReactComponent as ShareIcon } from '../../assets/images/icons/share.svg'
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'
import { SimplePlayer } from '../../components/SimplePlayer/SimplePlayer'

import style from './coursePopup.module.css'
import env from '../../env.json'
import { CourseHighlight } from '@learnn/sdk/src/api/course'
import { Markdown, Title, Button, useTheme, HorizontalStack, VerticalStack } from '@learnn/designn'
import { CoursePopupModules } from './CoursePopupModules'
import { Details } from './Details'

export const CoursePopup = () => {
  const { state } = useContext(CoursePopupContext)
  const { close } = useCoursePopup()
  const { share } = useShare()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [loadingHighlight, setLoading] = useState(true)
  const [highlight, setHighlight] = useState<CourseHighlight>()
  const [fullDescription, setFullDescription] = useState(false)

  const { spacing } = useTheme()

  const goToCourse = (id: string) => {
    close()
    history.push(`/corso/${id}`)
  }

  const getHighlight = async (id: string) => {
    try {
      setLoading(true)
      const highlight = await queryClient.fetchQuery(['highlight', id], {
        queryFn: () => getCourseHighlight(id),
      })
      setHighlight(highlight)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (state.show && state.course.id) {
      getHighlight(state.course.id)
      setFullDescription(false)
    }
  }, [state.show])

  switch (state.show) {
    case true: {
      return (
        <div
          className={style.popupContainer}
          onClick={event => {
            event.preventDefault()
            close()
          }}>
          <div className={style.content}>
            {state.course.companyLogo ? (
              <img className={style.companyLogo} src={state.course.companyLogo} />
            ) : null}
            <CloseIcon className={style.closeIcon} onClick={() => close()} />
            <div
              className={style.box}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}>
              <div className={style.card}>
                <div className={style.imageContainer}>
                  {loadingHighlight ? (
                    <div style={{ minHeight: 340, aspectRatio: 16 / 9, width: '100%' }} />
                  ) : (
                    <>
                      {highlight?.highlightVideoUrl ? (
                        <SimplePlayer
                          url={highlight?.highlightVideoUrl}
                          playerClassName={style.simplePlayer}
                        />
                      ) : highlight?.trailerVideoUrl ? (
                        <SimplePlayer
                          url={highlight?.trailerVideoUrl}
                          playerClassName={style.simplePlayer}
                        />
                      ) : (
                        <ResponsiveImage
                          wrapperClassName={style.borderedImage}
                          src={state.course.coverImage}
                        />
                      )}

                    <div className={style.overlay}>
                      <HorizontalStack justifyContent='start' className={style.ctaContainer}>
                          <Button variant='primary' size='lg' label={`Esplora il ${state.course.type.title.toLocaleLowerCase()}`} 
                            onPress={() => goToCourse(state.course.id)}
                            icon={<FontAwesomeIcon icon={faPlay} size='sm' color='black' />}
                            />

                          <HorizontalStack ml={spacing.space_4} gap={spacing.space_2}>
                            <MyLearnnButton
                              itemType='course'
                              itemId={state.course.id}
                              myLearnnId={state.course.myLearnnId}
                              
                              className={style.circularButtonIcon}
                              addIconColor='light'
                              />
        
                              <Button
                              variant='tertiary'
                                className={style.circularButton}
                                onPress={() =>
                                  share({
                                    title: 'Regala 7 giorni PRO gratis e ottieni crediti',
                                    type: state.course.type.slug,
                                    url: `${env.SITE_URL}/corso/${state.course.id}`,
                                  })
                                }
                                icon={<ShareIcon className={style.shareIcon} />}
                                > 
                              </Button>
                          </HorizontalStack>
                        </HorizontalStack>
        
                    </div>

                    </>
                  )}
                </div>
                <div className={style.cardBody}>
                  {/* CardHeader */}
                      <div className={style.badges}>
                          <div className={style.contentType}>{state.course.type.title}</div>
                          
                          {state.course.duration ? (
                            <div className={style.detailBadge}>
                              <ClockIcon className={style.deailIcon} />
                              <div>{state.course.duration}</div>
                            </div>
                          ) : (
                            <></>
                          )}

                        <div style={{ marginLeft: '0.2rem' }}> 
                            <Details course={state.course} asRow withoutTitle></Details>
                        </div>                               
                        </div>
                      
                        <h4 className={style.title} style={{ width: '100%' }}>{state.course.title}</h4>
                        <div className={style.infoContainer}>           
                            <VerticalStack mr={spacing.space_4} >
                                <h4 className={style.sectionTitle}>TENUTO DA</h4>                     
                                  <HorizontalStack>
                                    <img
                                      src={state.course.authors[0].avatar ?? state.course.coverImage}
                                      className={style.authorImage}
                                      />
                                    <div className={style.authorName}>
                                      <h4>{state.course.authors[0].authorName}</h4>
                                      <p>{state.course.authors[0].profession}</p>
                                    </div>
                                  </HorizontalStack>                 
                            </VerticalStack>                        
                      </div>

                  {state.course.description ? (
                          fullDescription ? (
                            <Markdown size='sm'>
                              {state.course.description}
                            </Markdown>
                          ) : (
                            <>
                              <Markdown size='sm' >
                                {`${state.course.description.slice(0, 110)}...`}
                              </Markdown>
                              <div className={style.readMoreContainer}>
                                <a
                                  onClick={() => setFullDescription(true)}
                                  className={style.readMore}
                                >
                                  vedi altro
                                </a>
                              </div>
                            </>
                          )
                        ) : (
                          <></>
                        )}
         
                 <CoursePopupModules courseId={state.course.id} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    default: {
      return <></>
    }
  }
}
