

import { CatClient } from 'ccat-api'
import env from '../env.json'

type Config = {
    userId: string,
    onMessageReceive: (message: string) => unknown
}
export type AssistantService = {
    sendMessage: (message: string) => Promise<void>
}
export const assistantService = (config: Config): AssistantService => {
    const cat = new CatClient({
        baseUrl: env.ASSISTANT_URL.replace('https://', ''),
        port: 443,
        user: config.userId,
        secure: true
    })
    cat.onMessage((data) => {
        config.onMessageReceive(data.content)
    }) 

    const sendMessage = async (message: string) => {
        await cat.send(message)
    }

    return {
        sendMessage
    }
}