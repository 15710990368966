import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import env from '../env.json'
import auth from '../utils/auth'

const uri = `${env.CMS_URL}/graphql`

const httpLink = createHttpLink({
  uri,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = auth.getToken()
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${token}` || null,
    },
  }))

  return forward(operation)
})

const logoutLink = onError(({ response }) => {
  if (response?.errors && response?.errors?.length > 0) {
    response?.errors?.map(error => {
      console.log(error)
      if (
        error &&
        error?.extensions?.exception?.output?.statusCode !== 'undefined' &&
        (error?.extensions?.exception?.output?.statusCode === 401 ||
          error?.extensions?.exception?.output?.payload?.statusCode)
      ) {
        auth.clearToken()
        return
      }
    })
  }
})

const createClientInjectingJwt = () => {
  return new ApolloClient({
    link: authMiddleware.concat(logoutLink).concat(httpLink),
    cache: new InMemoryCache(),
  })
}

export const graphqlClient = createClientInjectingJwt()
