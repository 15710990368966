import { FC } from 'react'
import style from './styles/quizHeader.module.css'

import backIcon from '../../assets/images/icons/back_arrow.svg'
import triangle from '../../assets/images/icons/down_triangle.svg'
import { IconButton } from '../../components/IconButton'
import {CourseBaseInfoEngagement} from '@learnn/sdk/src/api/course'
import { QuizStatus } from './QuizScreen'
import { Countdown } from '../../components/Countdown'
import { QuizItem, QuizQuestion } from '@learnn/sdk/src/api/quiz/'
import env from '../../env.json'

type QuizHeaderProps = {
  quiz?: QuizItem
  course: CourseBaseInfoEngagement
  currentQuestion: number
  onBackClick(): void
  onTimeUp(): void
  status: QuizStatus
}

export const QuizHeader: FC<QuizHeaderProps> = ({
  course,
  quiz,
  currentQuestion,
  status,
  onBackClick,
  onTimeUp,
}) => {
  return (
    <div className={style.header}>
      <div className={style.headerContainer}>
        <div className={style.headerLeft}>
          {status !== 'started' ? (
            <IconButton iconClassName={style.backIcon} onClick={onBackClick} icon={backIcon} />
          ) : null}
        </div>
        <h1 className={style.quizTitle}>{`Quiz: ${course.title}`}</h1>
        <div className={style.headerRight}>
          {status === 'started' ? (
            <Countdown durationInMinutes={env.QUIZ_DURATION_MINUTES} onCountdownEnd={onTimeUp} />
          ) : (
            <div />
          )}
        </div>
      </div>
      {status === 'started' && quiz ? (
        <div className={style.progressContainer}>
          <div className={style.progressBar}>
            {quiz.questions &&
              quiz.questions.map((q: QuizQuestion, index: number) => (
                <div
                  key={`question-item-${q.questionId}`}
                  className={
                    index <= currentQuestion
                      ? style.currentProgressQuestion
                      : style.progressQuestion
                  }>
                  {index === currentQuestion ? (
                    <div className={style.percentage}>
                      <span>{`${((currentQuestion + 1) * 100) / quiz.questions.length}%`}</span>
                      <img className={style.triangleIcon} src={triangle} />
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
          <span className={style.questionNumber}>{`DOMANDA ${currentQuestion + 1} / ${
            quiz.questions.length
          }`}</span>
        </div>
      ) : null}
    </div>
  )
}
