import { FC, useState, useEffect } from 'react'
import style from './questionList.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { QuizQuestion } from '@learnn/sdk/src/api/quiz'
import ReactMarkdown from 'react-markdown'

import cx from 'classnames'

type QuestionList = {
  error: string
  question: QuizQuestion
  containerClassName?: string
  innerContainerClassName?: string
  answerContainerClassName?: string
  titleContainerClassName?: string
  isSubmittingAnswer?: boolean
  onAnswerSubmit(questionId: string, answerId: string): void
  onQuestionSkipped?: (questionId: string) => void
  questionNumber?: number
  theme: 'light' | 'dark'
}

export const QuestionList: FC<QuestionList> = ({
  question,
  onAnswerSubmit,
  onQuestionSkipped,
  containerClassName,
  innerContainerClassName,
  answerContainerClassName,
  titleContainerClassName,
  error,
  theme,
  questionNumber,
  isSubmittingAnswer,
}: QuestionList) => {
  const [selectedAnswer, setSelectedAnswer] = useState<number>(-1)
  const { answers, questionId, text } = question

  const selectAnswer = (index: number) => {
    if (selectedAnswer !== index) {
      setSelectedAnswer(index)
    }
  }

  const handleSubmit = () => {
    if (selectedAnswer < 0) return
    onAnswerSubmit(questionId, answers[selectedAnswer].answerId)
  }

  useEffect(() => {
    setSelectedAnswer(-1)
  }, [question])

  return (
    <div className={cx([style.container, containerClassName])}>
      {questionNumber && <p className={style.questionNumber}>{`DOMANDA ${questionNumber}`}</p>}
      <div className={cx([style[`${theme}_questionTitleContainer`], titleContainerClassName])}>
        <ReactMarkdown>{text}</ReactMarkdown>
      </div>
      <div className={cx([style.answersContainer, innerContainerClassName])}>
        {answers && answers.length
          ? answers.map((a, index) => (
              <div
                className={cx([
                  index === selectedAnswer
                    ? style[`${theme}_selectedAnswer`]
                    : style[`${theme}_answer`],
                  answerContainerClassName,
                ])}
                key={`answer-${index}`}
                onClick={() => selectAnswer(index)}>
                <span>{a.text}</span>
                {index === selectedAnswer && (
                  <FontAwesomeIcon icon={faCheck} className={style[`${theme}_checkIcon`]} />
                )}
              </div>
            ))
          : null}
      </div>
      {error ? <div className={style.errorContainer}>{error}</div> : null}
      <button
        className={style[`${theme}_submitButton`]}
        disabled={isSubmittingAnswer || selectedAnswer < 0}
        onClick={handleSubmit}>
        INVIA RISPOSTA <FontAwesomeIcon icon={faChevronRight} className='ml-2' />
      </button>
      {onQuestionSkipped && (
        <div
          className={cx([style.skipButton, style[theme]])}
          onClick={() => onQuestionSkipped(questionId)}>
          Salta
        </div>
      )}
    </div>
  )
}
