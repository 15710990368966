import React, { memo, useState, useCallback, FC } from 'react'
import style from './assistant.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

type AssistantChatInputProps = {
  onSendMessage: (message: string) => unknown
}

export const AssistantChatInput: FC<AssistantChatInputProps> = memo(({ onSendMessage }) => {
  const [keyword, setKeyword] = useState('')

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
  }, [])

  const onKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword) {
      onSendMessage(keyword)
      setKeyword('')
    }
  }, [keyword, onSendMessage])

  return (
    <div className={style.chatInputContainer}>
      <input
        type='text'
        className={style.chatInput}
        placeholder='Messaggio'
        value={keyword}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      <FontAwesomeIcon icon={faArrowUp} style={{ color: '#808080' }} fontSize={15} />
    </div>
  )
})
