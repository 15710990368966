import { FC } from "react";
import style from "./styles/attemptDetails.module.css";
import ReactMarkdown from "react-markdown";
import { SingleAccordion } from "../../components/SingleAccordion";
import { QuizAttemptDetails, QuizResult } from "@learnn/sdk/src/api/quiz/";
import env from "../../env.json";
import { useWindowSize } from "../../utils/hooks";
import correctIcon from "../../assets/images/icons/correct.svg";
import correctIconDark from "../../assets/images/icons/correct_dark.svg";
import wrongIcon from "../../assets/images/icons/wrong.svg";
import Confetti from "react-confetti";
import cx from "classnames";

type AttemptDetails = {
  attempt: QuizAttemptDetails;
  result: QuizResult;
  onBackClick(): void;
};

export const AttemptDetails: FC<AttemptDetails> = ({ attempt, result, onBackClick }: AttemptDetails) => {
  const size = useWindowSize();
  const { questions } = attempt;

  return (
    <div className={style.container}>
      {result.passed ? (
        <div className={style.resultContainer}>
          <img src={env.QUIZ_PASSED_IMAGE_URL} className={style.resultImage} />
          <div className={style.resultinfo}>
            <h1 className={style.resultTitle}>Congratulazioni!</h1>
            <p className={style.resultDescription}>Hai superato il quiz.</p>
            <p className={style.resultDescription}>
              Il tuo punteggio è <strong>{`${Math.round((result.correctAnswers / attempt.totalQuestions) * 100)}%`}</strong>
            </p>
            <a href={`${env.QUIZ_CERTIFICATION_URL}${attempt.attemptId}`} target="_blank" className={cx([style.backButton, "mt-5"])}>
              MOSTRA CORSO VERIFICATO
            </a>
          </div>
        </div>
      ) : result.passed === false ? (
        <div className={style.resultContainer}>
          <img src={env.QUIZ_NOT_PASSED_IMAGE_URL} className={style.resultImage} />
          <div className={style.resultinfo}>
            <h1 className={style.resultTitle}>Ci dispiace!</h1>
            <p className={style.resultDescription}>Non hai superato il quiz.</p>
            <p className={style.resultDescription}>
              Il tuo punteggio è <strong>{`${Math.round((result.correctAnswers / attempt.totalQuestions) * 100)}%`}</strong>
            </p>
          </div>
        </div>
      ) : null}
      {questions.length
        ? questions.map((q, index: number) => (
            <div className={style.questionContainer} key={`question-${q.question.questionId}`}>
              <div className={style.questionTitleContainer}>
                <h4 className={style.questionNumber}>{`Domanda ${index + 1}:`} </h4>
                <ReactMarkdown>{q.question.text}</ReactMarkdown>
              </div>
              <div className={style.answersContainer}>
                {q.answers && q.answers.length
                  ? q.answers.map(a =>
                      a.userAnswer || a.correctAnswer ? (
                        <div className={style.answer} key={`answer-${q.question.questionId}-${a.answerId}`} onClick={() => {}}>
                          {a.correctAnswer && a.userAnswer ? (
                            <img src={correctIcon} className={style.answerImage} />
                          ) : a.correctAnswer && !a.userAnswer ? (
                            <img src={correctIconDark} className={style.answerImage} />
                          ) : a.correctAnswer === false && a.userAnswer ? (
                            <img src={wrongIcon} className={style.answerImage} />
                          ) : null}
                          <span className={a.correctAnswer === false ? style.wrongAnswer : ""}>{a.text}</span>
                        </div>
                      ) : null,
                    )
                  : null}
                {q.question.answerExplanation && q.question.answerExplanation !== "null" ? ( //Workaround -> PBI to fix DB is present
                  <SingleAccordion title="Vedi spiegazione" opened={false}>
                    <div className={style.explanationContainer}>
                      <ReactMarkdown>{q.question.answerExplanation}</ReactMarkdown>
                      <a
                        href={
                          q.question.explanationLink && q.question.explanationLink !== "null"
                            ? q.question.explanationLink
                            : `/player/${q.question.lessonId}/?t=${q.question.explanationTime}`
                        }
                        target="_blank">
                        Approfondisci la spiegazione →
                      </a>
                    </div>
                  </SingleAccordion>
                ) : null}
              </div>
            </div>
          ))
        : null}
      <button className={style.backButton} onClick={onBackClick}>
        TORNA AL CORSO
      </button>
      {attempt.passed && <Confetti width={size.width} height={size.height} recycle={false} numberOfPieces={1000} tweenDuration={6000} />}
    </div>
  );
};
