import { FC } from 'react'
import { InfiniteQuizState } from './useInfiniteQuizControllers'
import style from './styles/infiniteQuizSummary.module.css'
import { durationinMinutes } from '../../utils/time'
import * as O from 'fp-ts/Option'
import { ShareComponent } from '../../components/SharePopup/Share'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'

export type InfiniteQuizSummaryProps = {
  summaryState: InfiniteQuizState
}
export const InfiniteQuizSummary: FC<InfiniteQuizSummaryProps> = ({ summaryState }) => {
  const history = useHistory()
  const duration =
    O.isSome(summaryState.startTime) && O.isSome(summaryState.endTime)
      ? durationinMinutes((+summaryState.endTime.value - +summaryState.startTime.value) / 1000)
      : null

  return (
    <div className={style.container}>
      <FontAwesomeIcon
        icon={faTimes}
        className={style.closeIcon}
        onClick={() => history.push('/infinite')}
      />
      <h1 className={style.title}>Riepilogo sessione</h1>
      <h1 className={style.sectionTitle}>Il tuo record</h1>
      <div className={style.detailGrid}>
        <div className={style.detailItem}>
          <div className={style.detailTitle}>Serie</div>
          <div className={style.detailValue}>{summaryState.maxGoalAchievedCounter}</div>
        </div>
        <div className={style.detailItem}>
          <div className={style.detailTitle}>Serie migliore</div>
          <div className={style.detailValue}>{summaryState.maxAnswersStreak}</div>
        </div>
      </div>
      <h1 className={style.sectionTitle}>Sessione</h1>
      <div className={style.detailGrid}>
        <div className={style.detailItem}>
          <div className={style.detailTitle}>Totale Risposte Corrette</div>
          <div className={style.detailValue}>{summaryState.totalCorrectAnswers}</div>
        </div>
        {duration && (
          <div className={style.detailItem}>
            <div className={style.detailTitle}>Durata Sessione</div>
            <div className={style.detailValue}>{duration}</div>
          </div>
        )}
      </div>

      <ShareComponent
        type='external'
        title={'Sfida un tuo amico a fare meglio'}
        url={'https://my.learnn.com/infinite'}
        body={`Ho raggiunto il record di ${summaryState.totalCorrectAnswers} risposte corrette di fila su Learnn. Vediamo se mi superi 😉`}
        containerClassName={cx([style.shareContainer])}
      />
    </div>
  )
}
