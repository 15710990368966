import { useQuery } from "react-query"
import { self } from "src/controllers/User"
import { matchQuery } from "src/utils/matchQuery"
import { CheckoutSkeleton } from "./CheckoutScreen"
import Container from 'react-bootstrap/Container'
import style from './paymentScreen.module.css'
import { LiveChat } from 'src/components/LiveChat'
import { FC } from "react"


export const DenyTeamMember: FC<{ onError: Function }> = ({ children, onError }) => {
    const selfQuery = useQuery(['self'], () => self())

    return (
        <>
            {
                matchQuery(selfQuery, {
                    error: () => onError(),
                    loading: () => <Container className={style.container}><CheckoutSkeleton /></Container>,
                    success: (data) => {
                        if (data.teamRole && data.teamRole === 'member') {
                            if (window.zE) window.zE('messenger', 'open')
                            return (
                                <Container className={style.container}>
                                    <LiveChat />

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>
                                            Solo il proprietario del team può effettuare pagamenti.
                                        </span>
                                        <span>Contatta l'assistenza per essere supportato.</span>
                                    </div>

                                </Container>
                            )
                        }
                        return children
                    }
                })
            }
        </>
    )
}