import { CourseLessonsEngagement, Module, ModuleEngagement } from "@learnn/sdk/src/api/course"
import { FC, useMemo, useRef } from "react"
import useAnalytics from "../../../../../analytics/useAnalytics"
import { ModuleGroup } from "./ModuleGroup"
import { HorizontalStack, Title, useTheme, VerticalStack } from "@learnn/designn"

type ModulesProps = {
  course: CourseLessonsEngagement
  userId: string
}
export const Modules: FC<ModulesProps> = ({
  course
}) => {
  const highlightModuleRef = useRef<null | HTMLDivElement>(null)
  const { logExpandCourseModule } = useAnalytics()
  const { spacing } = useTheme()

  const modulesMap = useMemo(
    () =>
      course?.modules?.map((module: Module & Partial<ModuleEngagement>, i) => {
        return (
          <div
            ref={module.id === course.lastViewedModule ? highlightModuleRef : null}
            key={`module-course-${module.id}`}
            onClick={() => {
              if (!module.last_viewed) {
                logExpandCourseModule(module.id, module.title)
              }
            }}>
            {module.sectionTitle && (
                <Title variant='headingLg' mb={spacing.space_4} mt={i === 0 ? spacing.space_3 : spacing.space_6}>{module.sectionTitle}</Title>
              )}
            <ModuleGroup
              lastProgress={course.lastProgress}
              module={module}
              courseId={course.id}
              lastItem={i === course?.modules.length - 1}
              initVisible={module.last_viewed}
            />
          </div>
        )
      }),
    [course?.modules],
  )

  return (
    <VerticalStack>
      {modulesMap}
    </VerticalStack>
  )
}