import style from './styles/mobileVideoControls.module.css'
import { IVideoControls, IVideoReadOnlyState } from '../useReactPlayer'
import { VideoControlsCourseInfo } from './VideoControls'
import { FC, useContext, useEffect, useState } from 'react'
import { useVideoControls } from './useVideoControls'
import { TriggerButton } from './TriggerButton'
import BackwardSeconds from '../../../assets/images/icons/backward15.svg'
import cx from 'classnames'
import Pause from '../../../assets/images/icons/pause.svg'
import Play from '../../../assets/images/icons/play.svg'
import ForwardSeconds from '../../../assets/images/icons/forward15.svg'
import { Back } from '../../Back'
import { Description } from './Description'
import { ControlsContext } from './controlsContext'
import { MyLearnnButton } from '../../MyLearnnButton'
import { extractConfiguration } from '../../../utils/data'
import { GlobalProviderContext } from '../../GlobalProvider'
import { LessonBrowser } from '../../LessonsBrowser'
import { NotePopup } from '../../NotePopup'
import { useFullscreenWrapper } from './useFullscreenWrapper'
import { MobilePlaybackRate } from './MobilePlaybackRate'
import { MobileScrubber } from './MobileScrubber'
import risorse from '../../../assets/images/icons/info_dark.svg'
import noteDark from '../../../assets/images/icons/note_dark.svg'
import playlist from '../../../assets/images/icons/playlist_dark.svg'
import skipNext from '../../../assets/images/icons/skip-next.png'
import fullscreenIcon from '../../../assets/images/icons/fullscreen.svg'
import { FeedbackPopup } from '../../FeedbackPopup'
import { motion } from 'framer-motion'
import { NextLesson } from './NextLesson'
import { Share } from '../../Share'
import { useShare } from '../../SharePopup/useShare'
import env from '../../../env.json'
import shareIcon from '../../../assets/images/icons/share_black.svg'
import { useHistory } from 'react-router-dom'
import { Vtt } from '@learnn/sdk/src/api/utils'
import { useSubtitles } from '../useSubtitles'
import subtitlesIcon from '../../../assets/images/icons/subtitles_dark.svg'
import subtitlesActiveIcon from '../../../assets/images/icons/subtitles_active.svg'

export interface VideoControlsProps {
  state: IVideoReadOnlyState
  controls: IVideoControls
  courseInfo: VideoControlsCourseInfo
  analytics: AnalyticsProps
  lessonCompleted: boolean
  subtitles: Vtt[]
}

type AnalyticsProps = {
  onNextLesson(): void
  onChangeSpeed(rate: number): void
  onShowVideoDescription(): void
  onFullscreen(): void
  onForward(): void
  onBack(): void
  onSeek(by: number): void
  onShareClick(): void
  onToggleSubtitles(enabled: boolean): void
}

export const MobileVideoControls: FC<VideoControlsProps> = ({
  subtitles,
  controls,
  state,
  courseInfo,
  analytics,
}) => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const [controlsVisible, setControlsVisible] = useState(true)
  const { setMobileFullscreen, exitMobileFullscreen } = useFullscreenWrapper()
  const { currentBlock } = useSubtitles(subtitles, state.playedSeconds ?? 0)
  const {
    togglePlay,
    seekForward,
    seekBackward,
    togglePopup,
    goToNextLesson,
    setPlaybackRate,
    dismissNextLesson,
    toggleFeedback,
    toggleSubtitles,
  } = useVideoControls(controls, state, courseInfo)
  const {
    onNextLesson,
    onForward,
    onBack,
    onShowVideoDescription,
    onFullscreen,
    onSeek,
    onChangeSpeed,
    onShareClick,
    onToggleSubtitles,
  } = analytics
  const { state: controlsState } = useContext(ControlsContext)
  const { share } = useShare()
  const history = useHistory()

  useEffect(() => {
    if (state.ended) {
      setControlsVisible(true)
    }
  }, [state.ended])

  useEffect(() => {
    if (state.noteOpened) togglePopup('noteVisible')
  }, [])

  return (
    <>
      <motion.div
        initial='visible'
        animate={controlsVisible ? 'visible' : 'hidden'}
        className={style.overlay}
        id={style.videoControls}>
        <div className={style.topControls}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Back
              className={cx([style.back])}
              onClick={() => {
                exitMobileFullscreen()
                if (history.action !== 'POP') history.goBack()
                else history.push(`/corso/${courseInfo.courseId}`)
              }}
              backTo={`/corso/${courseInfo.courseId}`}
            />

            <FeedbackPopup
              lessonId={courseInfo.lessonId}
              visible={controlsState.feedbackPopup.visible}
              onClick={() => toggleFeedback()}
              courseId={courseInfo.courseId}
              viewTime={state.playedSeconds || 0}
              buttonClassNameImage={style.feedbackPopupButtonImage}
              buttonClassName={style.feedbackPopupButton}
              selectedCategoryId={controlsState.feedbackPopup.selectedCategoryId}
            />
          </div>
          <TriggerButton
            image={fullscreenIcon}
            onClick={() => {
              setMobileFullscreen()
              onFullscreen()
            }}
            classNameImage={style.fullscreenButtonImage}
            className={cx([style.fullscreenButton])}
          />
        </div>
        <div className={style.container}>
          <div className={style.controlsGroup}>
            <div className={cx([style.title])}>
              {subtitles?.length && state.subtitlesVisible ? (
                <p className={cx([style.subtitles])}>{currentBlock?.text}</p>
              ) : (
                <p className={cx([style.courseName])}>{courseInfo.lessonName}</p>
              )}
            </div>
            <div className={cx([style.scrubberContainer])}>
              <MobileScrubber state={state} controls={controls} onSeek={onSeek} />
            </div>
            <div className={style.controls}>
              <div className={style.controlContainer}>
                <TriggerButton
                  image={BackwardSeconds}
                  onClick={() => {
                    onBack()
                    seekBackward()
                  }}
                  classNameImage={style.seekControlImage}
                  className={cx([style.seekControl])}
                />
              </div>

              <div className={style.controlContainer}>
                <TriggerButton
                  active={state.playing}
                  activeImage={Pause}
                  image={Play}
                  onClick={togglePlay}
                  classNameImage={style.seekControlImage}
                  className={cx([style.playControl])}
                />
              </div>

              <div className={style.controlContainer}>
                <TriggerButton
                  image={ForwardSeconds}
                  onClick={() => {
                    onForward()
                    seekForward()
                  }}
                  classNameImage={style.seekControlImage}
                  className={cx([style.seekControl])}
                />
              </div>
            </div>

            <div className={style.controls}>
              <div className={style.controlContainer}>
                {subtitles?.length ? (
                  <TriggerButton
                    image={state.subtitlesVisible ? subtitlesActiveIcon : subtitlesIcon}
                    onClick={() => {
                      onToggleSubtitles(!state.subtitlesVisible)
                      toggleSubtitles(!state.subtitlesVisible)
                    }}
                    classNameImage={style.subtitlesImage}
                    className={cx([style.fullscreenButton])}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className={style.controlContainer}>
                <MobilePlaybackRate
                  startingRate={state.playbackRate}
                  onSetPlayback={setPlaybackRate}
                  onClick={onChangeSpeed}
                />
              </div>
              <div className={style.controlContainer}>
                {courseInfo.nextLessonId ? (
                  <TriggerButton
                    image={skipNext}
                    onClick={goToNextLesson}
                    classNameImage={style.nextLessonControlImage}
                    className={cx([style.nextLessonControl, style.hideOnMobile])}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className={style.bottomMenu}>
            <div className={style.bottomMenuItem}>
              <LessonBrowser
                icon={playlist}
                state={state}
                currentLessonId={courseInfo.lessonId}
                moduleId={courseInfo.moduleId}
                courseId={courseInfo.courseId}
                visible={controlsState.browserVisible}
                onClick={() => togglePopup('browserVisible')}
                buttonClassNameImage={style.lessonBrowserButtonImage}
                buttonClassName={style.lessonBrowserButton}
              />
              <span className={style.bottomMenuTitle}>Lezioni</span>
            </div>
            {courseInfo.description ? (
              <div className={style.bottomMenuItem}>
                <Description
                  icon={risorse}
                  description={courseInfo.description}
                  visible={controlsState.descriptionVisible}
                  buttonClassNameImage={cx([style.descriptionButtonImage])}
                  buttonClassName={cx([
                    style.descriptionButton,
                    style.hideOnDesktop,
                    style.hideOnTablet,
                  ])}
                  openSide='top-left'
                  onClick={() => {
                    togglePopup('descriptionVisible')
                    if (!controlsState.descriptionVisible) {
                      onShowVideoDescription()
                    }
                  }}
                />
                <span className={style.bottomMenuTitle}>Risorse</span>
              </div>
            ) : null}
            <div className={style.bottomMenuItem}>
              {configuration?.features?.mylearnn !== false && (
                <>
                  <MyLearnnButton
                    itemType='lesson'
                    itemId={courseInfo.lessonId}
                    myLearnnId={courseInfo.myLearnnId}
                    containerClass={style.myLearnnContainer}
                    className={style.myLearnnButton}
                  />
                  <span className={style.bottomMenuTitle}>Salva</span>
                </>
              )}
            </div>
            <div className={style.bottomMenuItem}>
              <NotePopup
                icon={noteDark}
                lessonId={courseInfo.lessonId}
                moduleId={courseInfo.moduleId}
                visible={controlsState.noteVisible}
                onClick={() => togglePopup('noteVisible')}
                courseId={courseInfo.courseId}
                viewTime={state.playedSeconds || 0}
                buttonClassNameImage={style.notePopupButtonImage}
                buttonClassName={style.notePopupButton}
              />
              <span className={style.bottomMenuTitle}>Note</span>
            </div>
            <div className={style.bottomMenuItem}>
              <Share
                icon={shareIcon}
                containerClassName={style.shareButton}
                onClick={() => {
                  if (state.playing) togglePlay()
                  onShareClick()
                  share({
                    type: 'lesson',
                    url: `${env.SITE_URL}/lezione/${courseInfo.lessonId}`,
                  })
                }}
              />
              <span className={style.bottomMenuTitle}>Condividi</span>
            </div>
          </div>
        </div>
      </motion.div>

      {courseInfo.nextLessonId ? (
        <NextLesson
          visible={controlsState.nextLessonVisible}
          onClick={goToNextLesson}
          onEnded={onNextLesson}
          onDismiss={dismissNextLesson}
          dismissed={controlsState.nextLessonDismissed}
        />
      ) : null}
    </>
  )
}
