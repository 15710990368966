import { FC, useState, useEffect, useRef, RefObject } from 'react'

import env from '../../env.json'

import './autocomplete.css'

type AutocompleteInput = {
  name: string
  onChange(address: any): void
  onBlur(event: React.FocusEvent<HTMLInputElement>): void
  errors?: string | false
}

//@ts-ignore
export const AddressAutocomplete: FC<AutocompleteInput> = ({ onChange, onBlur, errors, name }) => {
  const [query, setQuery] = useState('')
  const autoCompleteRef = useRef<HTMLInputElement>(null)

  let autoComplete: any

  const loadScript = (url: string, callback: Function) => {
    let script: any = document.createElement('script')
    script.type = 'text/javascript'

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null
          callback()
        }
      }
    } else {
      script.onload = () => callback()
    }

    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  function handleScriptLoad(updateQuery: Function, autoCompleteRef: RefObject<HTMLInputElement>) {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      types: ['address'],
    })
    autoComplete.setFields(['address_components', 'formatted_address'])
    autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery))
  }

  async function handlePlaceSelect(updateQuery: Function) {
    const addressObject = autoComplete.getPlace()
    const query = addressObject.formatted_address
    updateQuery(query)
    onChange(addressObject)
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${env.PLACES_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef),
    )
  }, [])

  return (
    <div className='auto-field'>
      <input
        ref={autoCompleteRef}
        name={name}
        onChange={event => setQuery(event.target.value)}
        onBlur={onBlur}
        placeholder='Inserisci un indirizzo'
        value={query}
        className={query ? 'auto-input val' : 'auto-input'}
      />

      <label className='auto-label'>Indirizzo (via e numero civico)</label>
      <i className='auto-bar'></i>
      <div className='error-text mt-1'>{errors}</div>
    </div>
  )
}
