import { JobCard } from '../JobCard'
import { ComingCard } from '../ComingCard'
import useAnalytics from '../../analytics/useAnalytics'
import {
  ComponentSectionDynamicComingContent,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/dist/api/dynamic-sections/types'
import { ComingContent } from '@learnn/sdk/dist/api/comingcontents'
import * as F from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { ModalComing, ModalComingProps } from '../ModalComing/ModalComing'
import { useState } from 'react'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'

export interface IContentCarouselProps {
  data: DynamicSectionsValue<DynamicSectionsError, ComponentSectionDynamicComingContent>

  onShowAll?(): void
}

export const ContentCarousel = ({ data, onShowAll }: IContentCarouselProps) => {
  const { logSelectContent } = useAnalytics()
  const [modalComingProps, setModalComingProps] = useState<ModalComingProps>({ status: 'closed' })

  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const render = (section: ComponentSectionDynamicComingContent) => {
    console.log('this section', section)
    return (
      <>
        <CustomCarousel
          title={section.title}
          data={section.data}
          carouselKind='course'
          onShowAll={() => onShowAll && onShowAll()}
          renderSlide={content => (
            <ComingCard
              onClick={async () => {
                setModalComingProps({
                  status: 'open',
                  onClose: () => {
                    setModalComingProps({ status: 'closed' })
                  },
                  comingContent: content,
                })
                await logSelectContent(content.type, content.id)
              }}
              course={content}
            />
          )}
        />        
        <ModalComing {...modalComingProps} />
      </>
    );
  }

  return F.pipe(
    data,
    O.fold(CustomCarouselLoading, data => {
      return F.pipe(data, E.fold(error, render))
    }),
  )
}
