import { FC, useContext } from 'react'
import { useQuery } from 'react-query'
import { getCurrentCustomerInfo, getCurrentSubscription, me } from '../../../controllers/User'
import { matchQuery } from '../../../utils/matchQuery'
import * as O from 'fp-ts/Option'
import {
  Title,
  Text,
  VerticalStack,
  useTheme,
  HorizontalStack,
  Button,
  Box,
  Badge,
} from '@learnn/designn'
import { SectionTitle } from '.'
import { toReadableDate } from '../../../utils/time'
import env from '../../../env.json'
import { CurrentSubscription } from '@learnn/sdk/dist/api/billing/getCurrentSubscription'
import { useHistory } from 'react-router-dom'
import { CurrentCustomerInfo } from '@learnn/sdk/dist/api/billing/getCurrentCustomerInfo'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { getUserProfile } from '../../../controllers/Profile'
import { pipe } from 'fp-ts/lib/function'
import { GlobalProviderContext } from '../../../components/GlobalProvider'
import { ErrorMessage } from '../../../components/ErrorMessage'

const FREE_PLAN_ID = 'free'

export type CurrentPlanProps = { restrictAccess: boolean }
export const CurrentPlan: FC<CurrentPlanProps> = ({ restrictAccess }) => {
  const { spacing, colors, borders } = useTheme()
  const history = useHistory()
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  if (!userId) return <ErrorMessage />

  const currentSubscriptionQuery = useQuery('current-plan', () => getCurrentSubscription())
  const customerInfo = useQuery('customer-info', () => getCurrentCustomerInfo())
  const userInfoQuery = useQuery('userinfo', () => me())
  const profileQuery = useQuery('profile', () => getUserProfile(userId))

  const isFree = (planId: string) => planId === FREE_PLAN_ID

  const displayPrice = (data: CurrentSubscription) => {
    return `${data.plan_amount / 100} ${data.currency_code}`
  }
  const displayPeriod = (data: CurrentSubscription) => {
    const translatePeriod = (plural?: boolean) => {
      switch (data.billing_period_unit) {
        case 'month':
          return plural ? 'Mesi' : 'Mese'
        case 'year':
          return plural ? 'Anni' : 'Anno'
        default:
          return plural ? 'Mesi' : 'Mese'
      }
    }

    if (isFree(data.plan_id)) {
      return 'per sempre'
    }

    if (data.billing_period === 1) {
      return translatePeriod(false)
    } else {
      return `${data.billing_period} ${translatePeriod(true)}`
    }
  }
  const displayDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000)
    return toReadableDate(date)
  }
  const displayBadge = (data: CurrentSubscription) => {
    switch (data.status) {
      case 'active':
        return (
          <Badge
            body='Attivo'
            variant='contained'
            backgroundColor='green'
            py={spacing.space_1}
            mr={spacing.space_2}
          />
        )
      case 'non_renewing':
        return (
          <Badge
            body='Rinnovo disattivato'
            variant='contained'
            backgroundColor='#ffc107'
            py={spacing.space_1}
            mr={spacing.space_2}
          />
        )
      case 'cancelled':
        return (
          <Badge
            body='Cancellato'
            variant='contained'
            backgroundColor='red'
            py={spacing.space_1}
            mr={spacing.space_2}
          />
        )
      case 'in_trial':
        return (
          <Badge
            body='Trial'
            variant='contained'
            backgroundColor='#eb9d02'
            py={spacing.space_1}
            mr={spacing.space_2}
          />
        )
      default:
        return <></>
    }
  }
  const displayCta = (
    currentSubscription: CurrentSubscription,
    customerInfo: CurrentCustomerInfo,
  ) => {
    const hasAtLeastOnePaymentActive = customerInfo.paymentSources.some(
      x => x.status === 'valid' || x.status === 'pending_verification',
    )
    switch (currentSubscription.status) {
      case 'active':
        return (
          <VerticalStack>
            <Button
              label='Cambia piano'
              onPress={() => {
                window.location.replace(env.MANAGE_ACCOUNT_URL)
              }}
              variant='primary'
              disabled={!hasAtLeastOnePaymentActive}
            />
            {!hasAtLeastOnePaymentActive && (
              <Text mt={spacing.space_1} variant='bodyXs' textAlign='center' color='error'>
                Metodo di pagamento non valido
              </Text>
            )}
            {!isFree(currentSubscription.plan_id) ? (
              <Button
                label='Disdici abbonamento'
                onPress={() => {
                  window.location.replace(env.CANCEL_SUBSCRIPTION_URL)
                }}
                variant='secondary'
                marginTop={spacing.space_4}
              />
            ) : (
              <></>
            )}
          </VerticalStack>
        )
      case 'in_trial':
        return (
          <VerticalStack>
            <Button
              label={
                currentSubscription.next_billing_at ? 'Cambia piano' : 'Riattiva o cambia piano'
              }
              onPress={() => {
                window.location.replace(env.MANAGE_ACCOUNT_URL)
              }}
              variant='primary'
              disabled={!hasAtLeastOnePaymentActive}
            />
            {!hasAtLeastOnePaymentActive && (
              <Text mt={spacing.space_1} variant='bodyXs' textAlign='center' color='error'>
                Metodo di pagamento non valido
              </Text>
            )}
            {currentSubscription.next_billing_at ? (
              <Box mt={spacing.space_4}>
                <Button
                  label='Disdici abbonamento'
                  onPress={() => {
                    window.location.replace(env.CANCEL_SUBSCRIPTION_URL)
                  }}
                  variant='secondary'
                />
              </Box>
            ) : (
              <></>
            )}
          </VerticalStack>
        )
      case 'non_renewing':
      case 'cancelled':
        return (
          <Button
            label='Riattiva o cambia piano'
            onPress={() => {
              window.location.replace(env.MANAGE_ACCOUNT_URL)
            }}
            variant='primary'
          />
        )
      default:
        return <></>
    }
  }
  const displaySummary = (data: CurrentSubscription) => {
    switch (data.status) {
      case 'active':
        if (data.next_billing_at)
          return `Il tuo abbonamento si rinnova il giorno ${displayDate(data.next_billing_at)}.`
        else return null
      case 'non_renewing':
        if (data.cancelled_at)
          return `Il tuo abbonamento scade il giorno ${displayDate(data.cancelled_at)}.`
        else return null
      case 'cancelled':
        if (data.cancelled_at)
          return `Il tuo abbonamento è scaduto il giorno ${displayDate(data.cancelled_at)}.`
        else return null
      case 'in_trial':
        if (data.trial_end && data.next_billing_at)
          return `Il tuo abbonamento è si attiverà il giorno ${displayDate(data.trial_end)}.`
        if (data.cancelled_at)
          return `Rinnovo disattivato, la prova scade il giorno ${displayDate(data.cancelled_at)}.`
        else return null
      default:
        return null
    }
  }

  const displayProfileName = () => {
    return matchQuery(profileQuery, {
      loading: () => 'Ciao,',
      error: () => 'Ciao,',
      success: (data) => pipe(
        data,
        O.fold(
          () => 'Ciao,',
          (profile) => profile.name ? `Ciao ${profile.name},` : ''
        )
      )
    })
  }

  return (
    <VerticalStack>
      <Box mb={spacing.space_8}>
        {matchQuery(userInfoQuery, {
          loading: () => (
            <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
              <VerticalStack>
                <Skeleton
                  style={{ height: '20px', width: '100px' }}
                  borderRadius={15}
                />
                <Skeleton
                  style={{ height: '45px', width: '300px' }}
                  borderRadius={15}
                />
              </VerticalStack>
            </SkeletonTheme>
          ),
          error: () => <></>,
          success: data => {
            return (
              <VerticalStack>

                <Text variant='bodyMd'>
                  {displayProfileName()}
                </Text>
                <Title variant='headingXl'>{data.email}</Title>
              </VerticalStack>
            )
          }
        })}
      </Box>
      <SectionTitle title='Piano attuale' />

      {restrictAccess ? (
        <VerticalStack>
          <Text variant='bodySm'>
            Il tuo abbonamento fa parte di un team, solo il proprietario del team può gestire il piano
          </Text>
        </VerticalStack>
      ) : (
        <HorizontalStack flexDirection={{ _: 'column', small: 'row' }}>
          <div style={{ flex: 1 }}>
            <VerticalStack alignItems='flex-start'>
              {matchQuery(currentSubscriptionQuery, {
                loading: () => (
                  <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
                    <VerticalStack>
                      <HorizontalStack>
                        <Skeleton
                          style={{ height: '30px', width: '100px', marginRight: '20px' }}
                          borderRadius={15}
                        />
                        <Skeleton style={{ height: '30px', width: '400px' }} borderRadius={15} />
                      </HorizontalStack>
                      <Skeleton
                        style={{ height: '40px', width: '400px', marginTop: '20px' }}
                        borderRadius={15}
                      />
                      <Skeleton
                        style={{ height: '20px', width: '600px', marginTop: '20px' }}
                        borderRadius={15}
                      />
                      <Skeleton
                        style={{ height: '70px', width: '200px', marginTop: '20px' }}
                        borderRadius={15}
                      />
                    </VerticalStack>
                  </SkeletonTheme>
                ),
                error: () => <></>,
                success: data => {
                  const summary = displaySummary(data)
                  const free = isFree(data.plan_id)

                  return (
                    <>
                      <HorizontalStack alignItems='center'>
                        {displayBadge(data)}
                        <Title variant='headingMd' fontWeight='regular'>
                          {data.plan_name}
                        </Title>
                      </HorizontalStack>
                      <Title variant='headingLg' fontWeight='bold' mt={spacing.space_2}>
                        {`${displayPrice(data)}/${displayPeriod(data)}`}
                      </Title>
                      {!restrictAccess && !free && summary ? (
                        <Text variant='bodySm' fontWeight='regular' mt={spacing.space_5}>
                          {summary}
                        </Text>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                },
              })}

              {matchQuery(customerInfo, {
                loading: () => <></>,
                error: () => <></>,
                success: data => {
                  return !restrictAccess ? (
                    <VerticalStack
                      borderRadius={borders.radius.medium}
                      borderColor='#ededed'
                      borderWidth={borders.width.base}
                      borderStyle='solid'
                      p={spacing.space_3}
                      mt={spacing.space_8}
                      justifyContent='center'
                      bg='#f3f3f3'>
                      <HorizontalStack alignItems='center'>
                        <Text variant='bodySm' fontWeight='regular' textColor={colors.bg_app}>
                          Crediti disponibili
                        </Text>
                        <Box
                          ml={spacing.space_3}
                          borderRadius='0.25rem'
                          borderColor='#ededed'
                          borderWidth={borders.width.base}
                          borderStyle='solid'
                          py={spacing.space_05}
                          px={spacing.space_2}
                          justifyContent='center'
                          bg='#fff'>
                          <Text variant='bodyXs' fontWeight='bold' textColor={colors.bg_app}>
                            {data.customer.promotional_credits}
                          </Text>
                        </Box>
                      </HorizontalStack>
                      <Text
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        mt={spacing.space_3}
                        variant='bodyXs'
                        fontWeight='regular'
                        textColor={colors.outline}
                        onClick={() => {
                          console.log('click')
                          history.push('/invita')
                        }}>
                        Ottieni crediti →
                      </Text>
                    </VerticalStack>
                  ) : (
                    <></>
                  )
                },
              })}
            </VerticalStack>
          </div>
          <Box marginTop={{ _: spacing.space_8, small: spacing.space_0 }}>
            {matchQuery(currentSubscriptionQuery, {
              loading: () => <></>,
              error: () => <></>,
              success: currentSubscription => {
                return matchQuery(customerInfo, {
                  loading: () => <></>,
                  error: () => <></>,
                  success: data => {
                    return !restrictAccess ? displayCta(currentSubscription, data) : <></>
                  },
                })
              },
            })}
          </Box>
        </HorizontalStack>
      )}
    </VerticalStack>
  )
}
