import { createContextReducer } from '../../utils/createContext'


export type AssistantStateType =
  | {
      show: true
    }
  | {
      show: false
    }

export const assistantInitialState: AssistantStateType = {
  show: false,
}

export type IAssistantActions = AssistantStateType & {}

function reducer(state: AssistantStateType, newState: IAssistantActions) {
  return { ...state, ...newState }
}

const [ctx, Provider] = createContextReducer<AssistantStateType, IAssistantActions>(reducer, assistantInitialState)
export const AssistantContext = ctx
export const AssistantContextProvider = Provider
