import { FC } from 'react'
import { motion } from 'framer-motion'
import style from './progressBar.module.css'
import cx from 'classnames'

export interface IProgressBar {
  fillPercentage?: number
  width?: string
  height?: number
  className?: string
  fillClassName?: string
  borderRadius?: number
}

export const ProgressBar: FC<IProgressBar> = ({
  width,
  height,
  fillPercentage,
  className,
  fillClassName,
  borderRadius,
}) => {
  return (
    <motion.div
      className={cx([style.wrapper, className])}
      //@ts-ignore //TODO
      style={{ width, height: height, borderRadius }}>
      <motion.div
        className={cx([style.progress, fillClassName])}
        //@ts-ignore //TODO
        style={{ width: `${fillPercentage ?? 0}%`, height, borderRadius }}
      />
    </motion.div>
  )
}
