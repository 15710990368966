import cx from 'classnames'
import styles from './styles/playerSideContent.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Tab, TabList, Tabs } from 'react-tabs'
import { Tab as TabType } from './setupTabs'
import 'react-tabs/style/react-tabs.css'
import { IVideoReadOnlyState } from '../useReactPlayer'
import { Vtt } from '@learnn/sdk/src/api/utils'
import { usePlayerSide } from './usePlayerSide'
import useAnalytics from '../../../analytics/useAnalytics'
import levetta from '../../../assets/images/icons/levetta.svg'
import arrow from '../../../assets/images/icons/arrow.svg'
import { useContext, useEffect } from 'react'
import { GlobalProviderContext } from '../../GlobalProvider'
import { CourseStatusCard } from '../../CourseStatusCard/CourseStatusCard'
import { getCourse } from '../../../controllers/Course'
import { useQuery } from 'react-query'
import { matchQuery } from '../../../utils/matchQuery'
import { Box, HorizontalStack, Tooltip, VerticalStack, useTheme } from '@learnn/designn'
import { LessonTags } from '@learnn/sdk/src/api/course'
import { useFeatureFlag } from 'src/components/FeatureFlag/useFeatureFlag'
import { setupTabs } from './setupTabs'
import { extractConfiguration } from 'src/utils/data'
import env from '../../../env.json'

export type PlayerSideContentProps = {
  initialSelectedTab?: number
  containerStyle?: string
  title: string
  lessonTags: LessonTags[]
  currentLessonId: string
  moduleId: string
  courseId: string
  lessonId: string
  state?: IVideoReadOnlyState
  viewTime: number
  lessonDescription?: string
  courseName: string
  subtitles: Vtt[]
  transcript?: string
  onBlockClick: (seconds: number) => void
}

export const PlayerSideContent = (props: PlayerSideContentProps) => {
  const {
    initialSelectedTab,
    containerStyle,
    title,
    lessonTags,
    moduleId,
    lessonId,
    courseId,
    courseName,
  } = props
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const userId = globalContext?.userId ?? ''
  const courseQuery = useQuery(['courseLessons', courseId], () => getCourse(courseId))
  const { state: playerSideState, toggle: togglePlayerSide, setSelectedTab } = usePlayerSide(0)
  const { features } = useFeatureFlag()
  const tabs: TabType[] = setupTabs({
    ...props,
    userId,
    resourcesEnabled: configuration?.premiumVisible ?? false,
    featureConfig: features,
  })

  const { logSidebarToggle } = useAnalytics()
  const { spacing } = useTheme()

  useEffect(() => {
    if (initialSelectedTab) {
      togglePlayerSide()
      logSidebarToggle(true, courseId, moduleId, lessonId)
      setSelectedTab(initialSelectedTab)
    }
  }, [initialSelectedTab])

  return (
    <>
      <div
        className={styles.toggleContainer}
        style={{
          backgroundImage: `url(${levetta}`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          backgroundSize: 'contain',
        }}
        onClick={() => {
          togglePlayerSide()
          logSidebarToggle(playerSideState.status === 'visible', courseId, moduleId, lessonId)
        }}>
        <img
          className={cx([styles.arrow, playerSideState.status === 'visible' ? styles.invert : ''])}
          src={arrow}
        />
      </div>
      <div
        className={cx([
          styles.sideContent,
          playerSideState.status === 'visible' ? styles.openLarge : '',
        ])}>
        <div className={cx([containerStyle, styles.container])}>
          <div className={styles.header} id='sidebar-header'>
            <div className={styles.courseTitle}>{courseName}</div>
            <div className={styles.title}>{title}</div>
            {lessonTags?.length && lessonTags.length > 0 ? (
              <HorizontalStack alignItems='center' mt={spacing.space_2}>
                {lessonTags.slice(0, 2).map(tag => (
                  <div key={`tag-${tag.id}`} className={styles.tagBadge}>
                    {tag.name}
                  </div>
                ))}
              </HorizontalStack>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.tabsContainer}>
            <Tabs
              className={styles.tabsContainerInner}
              selectedIndex={playerSideState.selectedTab}
              onSelect={index => setSelectedTab(index)}>
              <TabList id='tab-list'>
                {tabs.map((tab, index) => (
                  <Tab
                    disabled={tab.disabled}
                    disabledClassName={env.PRO_RESOURCES_CLASS}
                    style={{ flexDirection: 'row' }}
                    onClick={() => {
                      if (!tab.disabled) tab.onClick && tab.onClick()
                    }}>
                    {tab.labelTooltip || tab.componentTooltip ? (
                      <Tooltip
                        label={tab.labelTooltip ?? ''}
                        customComponent={tab.componentTooltip}
                        position='bottom'
                        variant='dark'
                        delaySeconds={0.7}
                        width={'20rem'}>
                        <div style={tab.tabContainerStyle || { marginTop: '0.2rem' }}>
                          <h3
                            style={{ marginBottom: '0.2rem' }}
                            className={styles.tabTitle}
                            data-active={playerSideState.selectedTab === index}>
                            <Box mr={spacing.space_1}>{tab.leftComponent}</Box>
                            {tab.label}
                          </h3>
                        </div>
                      </Tooltip>
                    ) : (
                      <div style={tab.tabContainerStyle || { marginTop: '0.2rem' }}>
                        <h3
                          style={{ marginBottom: '0.2rem' }}
                          className={styles.tabTitle}
                          data-active={playerSideState.selectedTab === index}>
                          <Box mr={spacing.space_1}>{tab.leftComponent}</Box>
                          {tab.label}
                        </h3>
                      </div>
                    )}
                  </Tab>
                ))}
              </TabList>
              {tabs.map((tab, index) => (
                <div
                  className={styles.tabContainer}
                  data-active={playerSideState.selectedTab === index}>
                  <div className={styles.tabContainerInner}>{tab.component}</div>
                </div>
              ))}
            </Tabs>
          </div>
          {matchQuery(courseQuery, {
            loading: () => <></>,
            error: () => <></>,
            success: course => (
              <VerticalStack p='20px'>
                <CourseStatusCard
                  variant='small'
                  userId={userId}
                  courseId={courseId}
                  courseProgressPercentage={Math.round(course.progress ?? 0)}
                />
              </VerticalStack>
            ),
          })}
        </div>
      </div>
    </>
  )
}

export const PlayerSideModal = (props: PlayerSideContentProps & { onDismiss: () => void }) => {
  const { onDismiss, ...childProps } = props
  return (
    <div
      className={styles.popupContainer}
      onClick={event => {
        event.preventDefault()
        onDismiss()
      }}>
      <div className={styles.content}>
        <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} onClick={() => onDismiss()} />
        <div
          className={cx([styles.box])}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          <PlayerSideContent {...childProps} />
        </div>
      </div>
    </div>
  )
}
