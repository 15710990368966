import { FC } from 'react'
import style from './quizTimeUp.module.css'
import env from '../../env.json'

type QuizTimeUp = {
  title?: string
  paragraphs: string[]
}

export const QuizTimeUp: FC<QuizTimeUp> = ({ title, paragraphs }) => {
  return (
    <div className={style.container}>
      <img src={env.QUIZ_TIME_UP_IMAGE_URL} className={style.image} />
      <h1 className={style.title}>{title ?? 'Tempo scaduto'}</h1>
      {paragraphs.map(p => (
        <p className={style.text}>{p}</p>
      ))}
    </div>
  )
}
