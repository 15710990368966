import { createContextReducer } from '../../../utils/createContext'

export interface IPlayerControlsState {
  browserVisible: boolean
  descriptionVisible: boolean
  playbackRateVisible: boolean
  noteVisible: boolean
  nextLessonVisible: boolean
  nextLessonDismissed: boolean
  communityVisible: boolean
  feedbackPopup: { visible: boolean; selectedCategoryId: string | undefined }
}

export const playerControlsInitialState: IPlayerControlsState = {
  browserVisible: false,
  descriptionVisible: false,
  playbackRateVisible: false,
  noteVisible: false,
  nextLessonVisible: false,
  nextLessonDismissed: false,
  communityVisible: false,
  feedbackPopup: { visible: false, selectedCategoryId: undefined },
}

export interface IPlayerControlsActions extends IPlayerControlsState {}

export type IPlayerControlsPopupKinds =
  | 'browserVisible'
  | 'descriptionVisible'
  | 'playbackRateVisible'
  | 'noteVisible'

function reducer(state: IPlayerControlsState, newState: IPlayerControlsActions) {
  return { ...state, ...newState }
}

const [ctx, Provider] = createContextReducer<IPlayerControlsState, IPlayerControlsActions>(
  reducer,
  playerControlsInitialState,
)
export const ControlsContext = ctx
export const ControlsContextProvider = Provider
