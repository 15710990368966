import { FC } from "react";
import cx from "classnames";
import style from "./alertBanner.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faArrowRight } from "@fortawesome/free-solid-svg-icons";

type AlertProps = {
  gift?: boolean;
  className?: boolean;
  onClick(): void;
};

export const AlertBanner: FC<AlertProps> = ({ gift, className, onClick }) => {
  return (
    <div className={cx([style.container, className])}>
      <div className={style.iconContainer}>
        <FontAwesomeIcon icon={faExclamationCircle} color="white" className={style.icon} />
      </div>
      <div className={style.textContainer}>
        {gift ? (
          <div>
            <span className={style.boldText}>Hai già un account.</span>
            <span className={style.text}>
              Per inviare un regalo
              <button className={style.button} onClick={onClick}>
                Fai login da qui
              </button>
              <FontAwesomeIcon size="sm" icon={faArrowRight} color="white" className={style.arrowIcon} />
            </span>
          </div>
        ) : (
          <div>
            <span className={style.boldText}>Questa email è già associata ad un account.</span>
            <span className={style.text}>
              Prova con un altro indirizzo email o
              <button className={style.button} onClick={onClick}>
                Fai login da qui
              </button>
              <FontAwesomeIcon size="sm" icon={faArrowRight} color="white" className={style.arrowIcon} />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
