import { useTheme, VerticalStack, HorizontalStack, Grid, Text, Markdown } from '@learnn/designn'
import { FC, useEffect, useRef } from 'react'
import style from './assistant.module.css'
import { AssistantMessageItem } from './AssistantMessageItem'
import { AssistantChatInput } from './AssistantChatInput'
import { Message } from './types'
import cx from 'classnames'

type AssistantChatProps = {
  loadingForResponse?: boolean
  messages: Message[]
  defaultPrompts?: {
    label: string
    prompt: string
  }[]
  onSendMessage: (message: string) => unknown
}

export const AssistantChat: FC<AssistantChatProps> = ({
  messages,
  defaultPrompts,
  loadingForResponse,
  onSendMessage,
}) => {
  const { spacing } = useTheme()
  const bottomEl = useRef<HTMLDivElement>(null)
  const lastUserMessageEl = useRef<HTMLDivElement>(null)

  const lastUserMessageIndex =
    messages.length > 0
      ? messages
          .map((_, index) => index)
          .filter(i => i % 2 === 0)
          .pop()
      : null

  useEffect(() => {
    if (lastUserMessageEl.current) {
      lastUserMessageEl.current.scrollIntoView({ behavior: 'smooth' })
    } else if (bottomEl.current) {
      bottomEl.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  const DefaultPrompts = () => {
    if (!defaultPrompts || defaultPrompts.length === 0) return <></>

    return (
      <Grid gridTemplateColumns={{ _: '1fr', medium: '1fr 1fr' }} gridGap={spacing.space_4}>
        {defaultPrompts?.map((item, i) => (
          <div
            key={i}
            className={style.questionContainer}
            onClick={() => onSendMessage(item.prompt)}>
            <Text>{item.label}</Text>
          </div>
        ))}
      </Grid>
    )
  }

  return (
    <div className={style.container}>
      <div className={style.content}>
        <VerticalStack flex={1} justifyContent='flex-end'>
          {messages.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
                overflowY: 'auto',
                height: '100px',
              }}
              className={style.messages}>
              {messages
                .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
                .map((message, i) => (
                  <div key={i} className={cx([message.role === 'user' && style.rightMessage])}>
                    {i === lastUserMessageIndex && <div ref={lastUserMessageEl} />}
                    <AssistantMessageItem message={message} key={i} />
                  </div>
                ))}
              {loadingForResponse && (
                <VerticalStack>
                  <Markdown mb={spacing.space_3} size='md'>
                    ## Learnn Assistant
                  </Markdown>
                  <div className={style.loadingDots}>
                    <div className={style.dot}></div>
                    <div className={style.dot}></div>
                    <div className={style.dot}></div>
                  </div>
                </VerticalStack>
              )}
              <div ref={bottomEl} />
            </div>
          ) : (
            <DefaultPrompts />
          )}
          <HorizontalStack marginTop={spacing.space_5}>
            <AssistantChatInput onSendMessage={onSendMessage} />
          </HorizontalStack>
        </VerticalStack>
      </div>
    </div>
  )
}
