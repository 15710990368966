import { IFrame } from '../components/IFrame/IFrame'
import { AppLayout } from '../layouts/AppLayout/AppLayout'

export const LiveScreen = () => {
  return (
    <AppLayout fullwidth showLastProgress={false}>
      <IFrame url='https://learnn.com/int/live/' fullHeight />
    </AppLayout>
  )
}
