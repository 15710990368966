import {HomeBanner} from "./HomeBanner";
import style from "./homeScreen.module.css";
import {GlobalProviderContext} from "../../components/GlobalProvider";
import {ErrorMessage} from "../../components/ErrorMessage";
import {useContext} from "react";
import {getCustomHome} from "../../controllers/Home";
import {DynamicSectionsState} from "@learnn/sdk/src/api/dynamic-sections/types";
import * as O from "fp-ts/Option";
import * as lib from "fp-ts/lib/function";
import Loader from "react-loader-spinner";
import {useReactive} from "../../utils/useReactive";
import {DynamicSections} from "../../components/DynamicSections/DynamicSections";

interface IHomeScreenProps {
  children?: any;
}

export const Home = (_: IHomeScreenProps) => {
  const globalContext = useContext(GlobalProviderContext);
  const userId = globalContext?.userId;
  const {data, error} = useReactive<DynamicSectionsState, string>({
    observableFn$: getCustomHome(userId),
    queryKey: "home"
  });


  const renderError = () => (
    <div className={style.container}>
      {" "}
      <ErrorMessage/>{" "}
    </div>
  );
  const loading = () => (
    <div className={style.containerLoading}>
      <Loader type="ThreeDots" color="#FFFFFF" height={50} width={50}/>
    </div>
  );
  const render = (customHomeState: DynamicSectionsState) => {
    return (
      <div className={style.container}>
        {globalContext?.configuration.homeBanner && globalContext?.configuration.homeBanner.enable && (
          <HomeBanner content={globalContext?.configuration.homeBanner}/>
        )}
        <DynamicSections state={customHomeState}/>
      </div>
    );
  };
  return lib.pipe(
    error,
    O.fold(() => lib.pipe(data, O.fold(loading, render)), renderError),
  );
};
