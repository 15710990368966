import { FC } from 'react'
import { ResponsiveImage } from '../ResponsiveImage'
import style from './featuredCard.module.css'
import useAnalytics from '../../analytics/useAnalytics'
import { ComponentContentFeatureContentApp } from '@learnn/sdk/src/api/dynamic-sections/types'
import { isMobile } from 'react-device-detect'

export interface IFeaturedCard {
  featured: ComponentContentFeatureContentApp
}

export const FeaturedCard: FC<IFeaturedCard> = ({ featured }) => {
  const { logSelectContent } = useAnalytics()

  const getHostname = (url: string) => {
    // run against regex
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)
    // extract hostname (will be null if no match is found)
    return matches && matches[1]
  }

  if (featured.url) {
    const target =
      getHostname(window.location.href) === getHostname(featured.url) ? '_self' : '_blank'

    return (
      <div
        className={style.card}
        onClick={() => {
          logSelectContent('featured_content', featured.url)
          window.open(featured.url, target) || window.location.replace(featured.url)
        }}>
        <ResponsiveImage wrapperClassName={style.image} src={(isMobile && featured.coverImageMobile) ? featured.coverImageMobile : featured.coverImage} />
      </div>
    )
  }
  return (
    <div className={style.card}>
      <ResponsiveImage wrapperClassName={style.image} src={featured.coverImage} />
    </div>
  )
}
