import { IFrame } from '../../components/IFrame/IFrame'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export const ExpertTutelaScreen = () => {
  return (
    <AppLayout hideHeader fullwidth disableMargin>
      <IFrame url='https://learnn.com/garanzia-expert/' fullHeight />
    </AppLayout>
  )
}
