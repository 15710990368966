import { OramaClient } from '@oramacloud/client';
const getSearchClients = (config) => {
    const coursesIndex = new OramaClient({
        endpoint: config.coursesEndpoint,
        api_key: config.coursesApiKey,
    });
    const lessonsIndex = new OramaClient({
        endpoint: config.lessonsEndpoint,
        api_key: config.lessonsApiKey,
    });
    const pathsIndex = new OramaClient({
        endpoint: config.pathsEndpoint,
        api_key: config.pathsApiKey,
    });
    const expertsIndex = new OramaClient({
        endpoint: config.expertsEndpoint,
        api_key: config.expertsApiKey,
    });
    const coursesSearchConfig = {
        mode: config.coursesSearchType,
        threshold: config.coursesSearchThreshold,
    };
    const lessonsSearchConfig = {
        mode: config.lessonsSearchType,
        threshold: config.lessonsSearchThreshold,
    };
    const pathsSearchConfig = {
        mode: config.pathsSearchType,
        threshold: config.pathsSearchThreshold,
    };
    const expertsSearchConfig = {
        mode: config.expertsSearchType,
        threshold: config.expertsSearchThreshold,
    };
    return {
        coursesIndex,
        lessonsIndex,
        pathsIndex,
        expertsIndex,
        coursesSearchConfig,
        lessonsSearchConfig,
        pathsSearchConfig,
        expertsSearchConfig,
    };
};
export default getSearchClients;
