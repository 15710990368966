import { FeaturedCard } from '../FeaturedCard'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import * as F from 'fp-ts/lib/function'
import {
  ComponentSectionStaticFeatureContent,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/dist/api/dynamic-sections/types'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'

export interface IFeaturedProps {
  data: DynamicSectionsValue<DynamicSectionsError, ComponentSectionStaticFeatureContent>
}

export const FeaturedCards = ({ data }: IFeaturedProps) => {
  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const render = (section: ComponentSectionStaticFeatureContent) => {
    return (
      <CustomCarousel 
        title={section.title ?? 'Novità'}
        data={section.webApp ?? []}
        carouselKind='featured'
        renderSlide={featured => {
          return (
            <FeaturedCard featured={featured} />
          )
        }}
      />
    );
  }

  return F.pipe(
    data,
    O.fold(CustomCarouselLoading, data => {
      return F.pipe(data, E.fold(error, render))
    }),
  )
}
