import { FC, useEffect, useRef, useState } from 'react'

import style from './yearRecapCard.module.css'
import { YearRecap } from '../../controllers/User'
import { OptionSwitch } from '../../components/OptionSwitch'
import cx from 'classnames'
import { isSafari } from 'react-device-detect'

export interface IYearRecapCard {
  data: YearRecap
  statsType: 'last_year' | 'total'
  containerClass?: string
  onComponentMount?(): void
  onFirstOptionClick(): void
  onSecondOptionClick(): void
}

export const YearRecapCard: FC<IYearRecapCard> = ({
  data,
  statsType,
  onComponentMount,
  containerClass,
  onFirstOptionClick,
  onSecondOptionClick,
}) => {
  const [height, setHeight] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (onComponentMount) onComponentMount()
    if (isSafari) {
      if (ref.current) {
        setHeight(ref.current.clientWidth)
      }
    }
  }, [])

  return (
    <>
      <OptionSwitch
        initialSelectedOption={statsType === 'last_year' ? 'first' : 'second'}
        firstOption={{ text: '2023', onClick: () => onFirstOptionClick() }}
        secondOption={{ text: "Dall'inizio", onClick: () => onSecondOptionClick() }}
      />
      <div
        id='recap-card'
        className={cx([style.container, containerClass])}
        style={isSafari ? { height: `${height}px` } : {}}
        ref={ref}>
        <div className={style.desktopContent}>
          <div className={style.row}>
            <div className={style.column}>
              <div className={style.imageContainer}>
                <img src={data.profileImage} className={style.profileImage} />
                {data.tier ? (
                  <div className={style.badge}>
                    <span>Top</span>
                    <span>{`${data.tier}%`}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={style.column} style={{ marginLeft: '-2rem' }}>
              <div className={style.logoContainer}>
                <span className={style.logo}>🎉 Learnn Wrapped</span>
                <div className={style.line} />
              </div>
              <div className={style.userName}>{data.name}</div>
              <div className={style.userName}>{data.lastName}</div>
              <div className={style.profession}>{`${data.profession} ${
                data.company ? '@ ' + data.company : ''
              }`}</div>
            </div>
          </div>
          <div className={style.row}>
            <div className={style.column} style={{ justifyContent: 'space-between' }}>
              <div className={style.stat} style={{ marginBottom: 0 }}>
                <h4 className={style.title}>⏰ Minuti su Learnn</h4>
                <div className={style.hoursText}>{data.totalViewTime.toLocaleString()}</div>
              </div>
              <div className={style.row}>
                <div className={style.stat}>
                  <h4 className={style.title}>💡Corsi</h4>
                  <div className={style.hoursText}>{data.startedCourses.length}</div>
                </div>
                {data.coursesVerified?.length >= 0 ? (
                  <div className={style.rightStat}>
                    <h4 className={style.title}>✅ Quiz</h4>
                    <div className={style.hoursText}>{data.coursesVerified.length}</div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={style.column} style={{ marginLeft: '-2rem' }}>
              <div className={style.stat}>
                <h4 className={style.title}>
                  {data.startedCoursesList && data.startedCoursesList.length > 0 ? (
                    '⚡️ Corsi seguiti'
                  ) : (
                    <></>
                  )}
                </h4>
                {data.startedCoursesList && data.startedCoursesList.length > 0 ? (
                  data.startedCoursesList.map((c, i) => (
                    <div key={`course-${c}`} className={style.coursesText}>
                      {`${i + 1}. ${c}`}
                    </div>
                  ))
                ) : (
                  <></>
                )}
                {data.startedCourses.length === 6 ? (
                  <div className={style.coursesText}>{`6. ${data.startedCourses[5]}`}</div>
                ) : data.startedCourses.length > 6 ? (
                  <div className={style.coursesText}>
                    {`e altri ${data.startedCourses.length - data.startedCoursesList.length} ...`}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
