import { FC, useContext } from 'react'
import style from './courseCard.module.css'
import { MyLearnnButton } from '../MyLearnnButton'
import { CourseBaseInfo, CourseBaseInfoEngagement } from '@learnn/sdk/dist/api/course'
import { Badge, CourseCard as CourseCardDS } from '@learnn/designn'
import { GlobalProviderContext } from '../GlobalProvider'
import { extractConfiguration } from '../../utils/data'
import { useFeatureFlagVariantKey } from 'posthog-js/react'

export interface ICourseCard {
  course: CourseBaseInfo & Partial<CourseBaseInfoEngagement>
  titleClassName?: string
  subtitleClassName?: string
  detailsClassName?: string
  onClick(): void
  myLearnn?: boolean
  myLearnnId?: string
  size?: 'lg' | 'md'
}

export const CourseCard: FC<ICourseCard> = ({ course, onClick, myLearnn, myLearnnId, size }) => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const blocked = !configuration?.premiumVisible && course.type.slug === 'webinar'
  const variant = useFeatureFlagVariantKey('course-vard-variant')

  return (
    <CourseCardDS
      variant={variant === 'new_card' ? 'longTitle' : 'fullImage'}
      onClick={onClick}
      title={variant === 'new_card' ? course.title : (course.shortTitle ?? course.title)}
      coverImage={course.coverImage}
      companyLogo={course.companyLogo ?? undefined}
      subtitle={`${course.type.title}${course.duration ? ` • ${course.duration}` : ''}`}
      size={size}
      buttons={
        myLearnn ? (
          <MyLearnnButton
            itemType='course'
            itemId={course?.id ?? ''}
            myLearnnId={myLearnnId}
            className={style.myLearnnButton}
          />
        ) : undefined
      }
      rightComponent={
        blocked ? <Badge body='Pro' variant='contained' squareBorder={false} /> : undefined
      }
      progressPercentage={course.progress}
    />
  )
}
