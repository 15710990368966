import { gql } from '@apollo/client';
import { Observable } from 'rxjs';
import { dynamicSections, QUERY_SECTIONS } from '../dynamic-sections';
import { normalizeGraphqlResponse } from '../utils';
export const fast = (client, engagement, recommender, quiz, course, configuration, player, profile, path) => {
    const getDynamicSections = (userId, options) => {
        return new Observable(subscriber => {
            client
                .query({
                query: DYNAMIC_FAST,
            })
                .then(normalizeGraphqlResponse)
                .then(result => dynamicSections(client, engagement, recommender, quiz, course, configuration, player, profile, path)(result.data.fast.container, userId, options).subscribe(r => subscriber.next(r)))
                .catch(error => {
                subscriber.error(error);
            });
        });
    };
    return {
        getDynamicSections: getDynamicSections,
    };
};
const DYNAMIC_FAST = gql `
  query DynamicFast {
    fast {
      data {
        attributes {
          ${QUERY_SECTIONS}
        }
      }
    }
  }
`;
