import { Box, Button, HorizontalStack, Text, VerticalStack, useTheme } from '@learnn/designn'
import { FC } from 'react'
import { SectionTitle } from '.'
import { useQuery } from 'react-query'
import { getCurrentCustomerInfo, membershipToken } from '../../../controllers/User'
import { matchQuery } from '../../../utils/matchQuery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBank, faWarning } from '@fortawesome/free-solid-svg-icons'
import { displayCardBrand, getCardIcon } from '../../../controllers/Payment'
import { faPaypal } from '@fortawesome/free-brands-svg-icons'
import { PaymentSource } from '@learnn/sdk/src/api/billing'
import { chargebeeInstance } from '../../../utils/chargebee'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export type PaymentMethodsProps = { restrictAccess: boolean }
export const PaymentMethods: FC<PaymentMethodsProps> = ({ restrictAccess }) => {
  const customerInfo = useQuery('customer-info', () => getCurrentCustomerInfo())
  const { spacing, borders } = useTheme()

  const handleEditMethodClick = async () => {
    try {
      const session = await membershipToken()
      if (session?.status === 404) {
        return
      }
      chargebeeInstance.setPortalSession(() => {
        return Promise.resolve(session?.body.portal_session)
      })
      chargebeeInstance.setPortalCallbacks({
        paymentSourceAdd: () => {
          customerInfo.refetch()
        },
        paymentSourceUpdate: () => {
          customerInfo.refetch()
        },
        paymentSourceRemove: () => {
          customerInfo.refetch()
        },
      })
      const portal = chargebeeInstance.createChargebeePortal()
      portal.open({}, { sectionType: 'portal_payment_methods' })
    } catch (error) {
      console.error(error)
    }
  }

  const renderPaymentMethod = (paymentMethod: PaymentSource) => {
    switch (paymentMethod.type) {
      case 'card':
        const brandName = displayCardBrand(paymentMethod.card.brand)
        return (
          <HorizontalStack alignItems='center'>
            <FontAwesomeIcon
              icon={getCardIcon(paymentMethod.card.brand)}
              style={{ color: 'white', height: '20px' }}
            />
            {brandName && <Text ml={spacing.space_2}>{brandName}</Text>}
            <Text ml={spacing.space_2} variant='bodySm'>
              ••••
            </Text>
            <Text ml={spacing.space_2} fontWeight='bold' variant='bodySm'>
              {paymentMethod.card.last4}
            </Text>
            <Text ml={spacing.space_6} variant='bodySm'>
              Scadenza: {paymentMethod.card.expiry_month}/{paymentMethod.card.expiry_year}
            </Text>
          </HorizontalStack>
        )
      case 'paypal_express_checkout':
        return (
          <HorizontalStack alignItems='center'>
            <FontAwesomeIcon icon={faPaypal} style={{ color: 'white', height: '20px' }} />
            <Text ml={spacing.space_2} variant='bodySm'>
              Paypal:
            </Text>
            <Text ml={spacing.space_2} fontWeight='bold' variant='bodySm'>
              {paymentMethod.paypal.email}
            </Text>
          </HorizontalStack>
        )
      case 'direct_debit':
        return (
          <HorizontalStack alignItems='center'>
            <FontAwesomeIcon icon={faBank} style={{ color: 'white', height: '20px' }} />
            <Text ml={spacing.space_2} variant='bodySm'>
              Addebito Diretto SEPA:{' '}
            </Text>
            <Text ml={spacing.space_2} variant='bodySm'>
              ••••
            </Text>
            <Text ml={spacing.space_2} fontWeight='bold' variant='bodySm'>
              {paymentMethod.bank_account.last4}
            </Text>
          </HorizontalStack>
        )
    }
  }

  const renderAlert = (paymentMethod: PaymentSource) => {
    const Badge = ({
      color,
      iconColor,
      icon,
      text,
    }: {
      color: string
      iconColor?: string
      icon: string
      text: string
    }) => (
      <HorizontalStack
        borderRadius={borders.radius.large}
        py={spacing.space_1}
        px={spacing.space_3}
        justifyContent='center'
        alignItems='center'
        bg={color}
        ml={spacing.space_5}>
        <FontAwesomeIcon
          icon={icon}
          style={{ color: iconColor ?? '#c81f1e', height: '20px', marginRight: '10px' }}
        />
        <Text variant='bodyXs' fontWeight='semibold' textColor={'black'}>
          {text}
        </Text>
      </HorizontalStack>
    )

    switch (paymentMethod.status) {
      case 'valid':
        return <></>
      case 'expiring':
        return (
          <Badge
            icon={faWarning}
            text={
              paymentMethod.type === 'card'
                ? `In scadenza il ${paymentMethod.card.expiry_month}/${paymentMethod.card.expiry_year}`
                : `In scadenza`
            }
            color={'#ffadaa'}
          />
        )
      case 'expired':
        return (
          <Badge
            icon={faWarning}
            text={
              paymentMethod.type === 'card'
                ? `Scaduto il ${paymentMethod.card.expiry_month}/${paymentMethod.card.expiry_year}`
                : `Scaduto`
            }
            color={'#ffadaa'}
          />
        )
      case 'invalid':
        return (
          <Badge icon={faWarning} text={`Non valido`} color={'#919191'} iconColor={'#444444'} />
        )
      case 'pending_verification':
        return <Badge icon={faWarning} text={`Verifica`} color={'#919191'} iconColor={'#444444'} />
    }
  }

  const renderEmptyPaymentMethod = () => {
    return (
      <VerticalStack>
        <Text variant='bodySm'>Non hai metodi di pagamento salvati</Text>
        <Box mt={spacing.space_4}>
          <Button
            label='Aggiungi un metodo di pagamento'
            onPress={handleEditMethodClick}
            variant='primary'
          />
        </Box>
      </VerticalStack>
    )
  }

  return (
    <VerticalStack>
      <SectionTitle title='Modalità di pagamento' />
      {restrictAccess ? (
        <VerticalStack>
          <Text variant='bodySm'>
            Il tuo abbonamento fa parte di un team, solo il proprietario del team può gestire i
            pagamenti
          </Text>
        </VerticalStack>
      ) : (
        <HorizontalStack>
          {matchQuery(customerInfo, {
            loading: () => (
              <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
                <VerticalStack>
                  <Skeleton style={{ height: '20px', width: '500px' }} borderRadius={15} />ì{' '}
                </VerticalStack>
              </SkeletonTheme>
            ),
            error: () => renderEmptyPaymentMethod(),
            success: data => {
              const primaryMethod = data.paymentSources.filter(
                x => x.id === data.customer.primary_payment_source_id,
              )[0]

              if (!primaryMethod) return renderEmptyPaymentMethod()

              return (
                <>
                  <VerticalStack alignItems='flex-start'>
                    <HorizontalStack>{renderPaymentMethod(primaryMethod)}</HorizontalStack>
                    <Button
                      label='Cambia metodo di pagamento'
                      onPress={handleEditMethodClick}
                      variant='primary'
                      marginTop={spacing.space_8}
                    />
                  </VerticalStack>
                  {renderAlert(primaryMethod)}
                </>
              )
            },
          })}
        </HorizontalStack>
      )}
    </VerticalStack>
  )
}
