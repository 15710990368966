import { calculateEstimate, PaymentMethodType } from 'src/controllers/Payment'
import { CheckoutData } from './CheckoutContext'
import { AmountCents, DiscountLineItem, Estimate, EstimateLineItem } from './types'
import { GetCurrentSubscriptionDto } from '@learnn/sdk/src/api/billing/getCurrentSubscription'
import QueryString from 'query-string'
import * as O from 'fp-ts/Option'

export const displayAmountCents = (amount: AmountCents) => {
  return `${amount / 100} €`
}

export const filterDocumentLevelDiscounts = (discountLine: DiscountLineItem) => {
  return discountLine.discountType !== 'item_level_coupon'
}

export const calculatePercentageOnTotal = (amount: AmountCents, total: AmountCents): string => {
  return ((amount * 100) / total).toFixed(2)
}

export const getCheckoutEstimate = async (checkoutData: CheckoutData): Promise<Estimate> => {
  const { future, invoice, credit_notes } = await calculateEstimate(checkoutData)

  const lineItems: EstimateLineItem[] = []

  invoice.line_items.forEach(line => {
    const getRealtedDiscountLines = (): DiscountLineItem[] | undefined => {
      const relatedDiscountLines = invoice.line_item_discounts?.filter(
        x => x.discount_type === 'item_level_coupon' && x.line_item_id === line.id,
      )
      if (!relatedDiscountLines) return undefined

      return relatedDiscountLines.map(relatedDiscountLine => {
        const percentage = Number(
          ((relatedDiscountLine.discount_amount / invoice.sub_total) * 100).toFixed(2),
        )
        const relatedDiscount = invoice.discounts?.find(
          x => x.entity_id === relatedDiscountLine.entity_id,
        )
        const discountDescription = relatedDiscount ? relatedDiscount.description : ''

        return {
          discountType: relatedDiscountLine.discount_type,
          amount: relatedDiscountLine.discount_amount as AmountCents,
          percentage: percentage,
          name: discountDescription,
        }
      })
    }

    lineItems.push({
      type: 'invoice',
      entityId: line.entity_id,
      entityType: line.entity_type,
      name: line.description,
      amount: line.amount as AmountCents,
      quantity: line.quantity,
      unitAmount: line.unit_amount as AmountCents,
      taxAmount: line.tax_amount as AmountCents,
      discounts: getRealtedDiscountLines(),
    })
  })

  credit_notes?.forEach(credit_note => {
    credit_note.line_items.forEach(line => {
      lineItems.push({
        type: 'credit_note',
        entityId: line.entity_id,
        entityType: line.entity_type,
        name: line.description,
        amount: (line.amount - line.discount_amount) as AmountCents,
        quantity: line.quantity,
        unitAmount: line.unit_amount as AmountCents,
        taxAmount: line.tax_amount as AmountCents,
      })
    })
  })

  let nextRenewal = invoice.date
  if (!nextRenewal)
    nextRenewal = invoice.line_items[0].date_from

  const response: Estimate = {
    future: future,
    nextRenewal: new Date(nextRenewal * 1000),
    total: invoice.amount_due as AmountCents,
    subTotal: invoice.sub_total as AmountCents,
    creditsApplied: invoice.credits_applied as AmountCents,
    lines: lineItems,
    discountLines: invoice.discounts?.map(line => {
      const percentage =
        line.discount_percentage ?? Number(((line.amount / invoice.sub_total) * 100).toFixed(2))
      return {
        discountType: line.entity_type,
        amount: line.amount as AmountCents,
        percentage: percentage,
        name: line.description,
      }
    }),
  }

  return response
}

export const isUserFree = (planUnitPrice: number) => {
  return planUnitPrice === 0
}

export const isMethodValid = (status: string) => {
  return status === 'valid' || status === 'expiring'
}

export const removeParamsFromSearch = (locationSearch: string) =>
  locationSearch.length > 0
    ? QueryString.exclude(locationSearch, ['plan', 'coupon', 'addon', 'addon_qty', 'plan_qty', 'next_step'])
    : locationSearch

export const getAnalyticsProductVariant = (actionState: string) => {
  //Mapper created using Growth Team business logic
  const mapper = {
    'switch-paid': 'Upgrade',
    upgrade: 'Upgrade',
    'reactive-free': 'Reactive',
    'reactive-paid': 'Reactive',
    downgrade: 'Downgrade',
  }

  return mapper[actionState] ?? 'Upgrade/Downgrade'
}

type Params = {
  planUnitPrice: number
  defaultMethodStatus: O.Option<string>
  plan: O.Option<string>
  coupon: O.Option<string>
  addon: O.Option<string>
}
export const isUserAllowedToCheckout = (params: Params) => {
  const isUserFreeAllowed = () => {
    const isUserFreeBuyingAddonWithCoupon = () => {
      return (
        isUserFree(params.planUnitPrice) &&
        O.isNone(params.plan) &&
        O.isSome(params.coupon) &&
        O.isSome(params.addon)
      )
    }

    const isUserFreeWithPaymentMethod = () => {
      return (
        isUserFree(params.planUnitPrice) &&
        O.isSome(params.defaultMethodStatus)
      )
    }

    return isUserFreeBuyingAddonWithCoupon() || isUserFreeWithPaymentMethod()
  }

  if (isUserFree(params.planUnitPrice) && !isUserFreeAllowed()) {
    return false
  }
  return true
}