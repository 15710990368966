
import { Box, Button, HorizontalStack, Text, VerticalStack, useTheme } from '@learnn/designn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useOpenAppBanner } from './useOpenAppBanner';

export const OpenAppBanner = () => {
    const { colors, spacing } = useTheme()
    const { show, close , openApp} = useOpenAppBanner()

    if (!show)
        return <></>

    return (
        <HorizontalStack
            backgroundColor={colors.modal_background}
            alignItems='center'
            boxShadow='inset 0 -10px 7px -10px #000000'
            width={'100%'}
            display={{_: 'flex', small: 'none !important'}}
        >
            <Box
                p={spacing.space_6}
                onClick={close}
            >
                <FontAwesomeIcon icon={faTimes} size='lg' color='white'/>
            </Box>
            <VerticalStack flex={1}>
                <Text
                    variant='bodySm'
                    fontWeight='bold'
                >
                   Learnn 
                </Text>
                <Text
                    variant='bodyXs'
                    fontWeight='regular'
                >
                   Apri Learnn App
                </Text>
            </VerticalStack>
            <Button 
                variant='primary'
                size='sm'
                label='APRI APP'
                onPress={openApp}
                mr={spacing.space_6}
            />
            
        </HorizontalStack>
    )
}