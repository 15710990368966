import style from './appLayout.module.css'
import React, { useContext, useEffect, useState } from 'react'
import { Navigation } from '../../components/Navigation'
import { getPositionIndexByUrl } from '../../components/Navigation/utils'
import { MenuProviderContext } from '../../components/Navigation/menuContext'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import FormControl from 'react-bootstrap/FormControl'
import { useHistory } from 'react-router-dom'
import { Back } from '../../components/Back'
import useAnalytics from '../../analytics/useAnalytics'
import { ContinueWatchingPopup } from '../../components/ContinueWatchingPopup'
import auth from '../../utils/auth'
import { useContinueWatchingPopup } from '../../components/ContinueWatchingPopup/useContinueWatchingPopup'
import { Text, HorizontalStack, useTheme, HorizontalScroll } from '@learnn/designn'
import { ReactComponent as BackIcon } from '../../assets/images/icons/back_arrow.svg'
import { OpenAppBanner } from '../../components/OpenAppBanner'
import { GlobalProviderContext } from 'src/components/GlobalProvider'
import { SearchCategories } from 'src/components/SearchBar/SearchBar'
import { useSearch } from 'src/components/SearchProvider/searchContext'

export type AppLayoutProps = {
  showSearchbar?: boolean
  showLastProgress?: boolean
  hideMobileBackToMenuButton?: boolean /** Mobile back button is shown on pages without header (susy pages) */
  fillcontent?: boolean /* Full width content without horizontal margin */
  fullwidth?: boolean /* Full width content with small horizontal margin */
  fillScreen?: boolean
  disableMargin?: boolean
  hideSecondaryMenu?: boolean
  hideHeader?: boolean
  hideSearchFilters?: boolean
  title?: string
  backButton?: boolean
  fixedHeader?: boolean
}
export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  fullwidth,
  fillcontent,
  fillScreen,
  disableMargin,
  hideSecondaryMenu,
  showSearchbar,
  title: titleProp,
  backButton,
  hideHeader,
  hideSearchFilters,
  fixedHeader = true,
  hideMobileBackToMenuButton,
  showLastProgress = true,
}) => {
  const { state: navigationState, dispatch } = useContext(MenuProviderContext)
  const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false)
  const [forceHideLastProgress, setForceHideLastProgress] = useState<boolean>(false)
  const { currentState: continueWatchingPopupState } = useContinueWatchingPopup()
  const history = useHistory()
  const { logSearchKeyword, logSearchCategory } = useAnalytics()
  const { spacing } = useTheme()
  const globalContext = useContext(GlobalProviderContext)
  const { search, resetSearch, keyword, setKeyword } = useSearch()

  const [activeMacroIndex, activeSecondaryIndex] = getPositionIndexByUrl(navigationState.menu, navigationState.currentMenuUrl)
  const menuItem = navigationState.menu[activeMacroIndex]
  const title = titleProp
    ? titleProp
    : menuItem.subs !== undefined && menuItem.subs.length > 0
      ? menuItem.subs[activeSecondaryIndex].label
      : menuItem.label

  useEffect(() => {
    dispatch({ currentMenuUrl: location.pathname })
  }, [])

  useEffect(() => {
    const lpwts = parseInt(
      auth.getLastProgressWidgetTs() ? auth.getLastProgressWidgetTs().toString() : '0',
    )

    if (!lpwts) return

    // Show last progress widget only if the last progress is older than * hours
    const currentTime = new Date().getTime()
    const maxHours = 3
    const limitTime = maxHours * 60 * 60 * 1000

    setForceHideLastProgress(currentTime - lpwts < limitTime)
  }, [continueWatchingPopupState])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword) {
      logSearchKeyword(keyword)
      search()
      //dispatch({ menuPosition: [0, 1] })
    }
  }

  const showSearchInput = showSearchbar

  return (
    <div className={style.container}>
      <Navigation hideSecondaryMenu={hideSecondaryMenu} />
      <div className={style.content}>
        <OpenAppBanner />
        {
          !(hideMobileBackToMenuButton || !hideHeader) && (
            <div className={style.backToMenuButton}>
              <HorizontalStack width='100%' flexDirection='row' alignItems='center' justifyContent='flex-start' padding={spacing.space_4}>
                <BackIcon
                  style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                  onClick={() => dispatch({ mobileMenuStatus: 'opened' })}
                />
                <Text ml={spacing.space_4} fontWeight='bold'>Torna al Menu</Text>
              </HorizontalStack>
            </div>
          )
        }

        {
          !hideHeader && (
            <div
              className={cx([
                style.header,
                fullwidth ? style.fullwidth : null,
                fillcontent ? style.fillcontent : null,
              ])}>

              <div
                className={cx([
                  style.headerTop
                ])}>
                {backButton && (
                  <Back className={style.backButton} backTo='/' onClick={() => history.goBack()} />
                )}
                <div className={style.headerLeft}>

                  {(!showSearchInput && !showMobileSearch) && <p className={style.headerTitle}>{title}</p>}

                  {showSearchInput && (
                    <div className={cx([style.inputContainer, showMobileSearch ? style.show : ''])}>
                      <div id='search-input' className={style.inputIcon}>
                        <FontAwesomeIcon icon={faSearch} color='#808080' fontSize={15} />
                      </div>
                      <FormControl
                        className={style.input}
                        placeholder='Cosa vuoi imparare?'
                        aria-label='Cosa vuoi imparare?'
                        aria-describedby='search-input'
                        onChange={onChange}
                        value={keyword}
                        onKeyPress={onKeyPress}
                      />
                      <button
                        className={cx([style.closeButton, !keyword && style.hide])}
                        onClick={() => {
                          resetSearch()
                          setShowMobileSearch(false)
                        }}>
                        <FontAwesomeIcon icon={faTimesCircle} color='#808080' />
                      </button>
                    </div>
                  )}
                </div>

                <div className={style.headerRight}>
                  <div
                    className={cx([
                      style.mobileSearchMenuIcon,
                      navigationState.mobileMenuStatus && !showMobileSearch && showSearchInput
                        ? style.show
                        : '',
                    ])}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size='2x'
                      onClick={() => setShowMobileSearch(true)}
                    />
                  </div>
                  <div
                    className={cx([
                      style.mobileMenuIcon,
                      'ml-4',
                      navigationState.mobileMenuStatus ? style.show : '',
                    ])}>
                    <FontAwesomeIcon
                      icon={faBars}
                      size='2x'
                      onClick={() => dispatch({ mobileMenuStatus: 'opened' })}
                    />
                  </div>
                </div>
              </div>

              {(showSearchInput && !hideSearchFilters) && (
                <div
                  className={cx([
                    style.headerBottom
                  ])}>
                  <HorizontalScroll width='100%' shadowColor={'#000'}>
                    <SearchCategories
                      currentKeyword={keyword}
                      filters={globalContext?.configuration.searchCategories ?? []}
                      onCategoryClick={(filter) => {
                        if (keyword.toLowerCase() !== filter.toLowerCase()) {
                          logSearchCategory(filter)
                          logSearchKeyword(filter)
                          search(filter)
                        } else {
                          resetSearch()
                        }
                      }}
                    />
                  </HorizontalScroll>
                </div>
              )}
            </div>
          )
        }
        {showLastProgress && !forceHideLastProgress && <ContinueWatchingPopup />}
        <div
          className={cx([
            style.main,
            fullwidth ? style.fullwidth : null,
            disableMargin ? style.disableMargin : null,
            fillScreen && style.fillScreen,
            fillcontent && style.fillcontent,
            fixedHeader && style.scroll,
          ])}>
          {children}
        </div>
      </div>
    </div>
  )
}
