import { useHistory } from "react-router-dom";
import style from "./back.module.css";
import arrow from "../../assets/images/icons/arrow.svg";
import cx from "classnames";

export interface IBackProps {
  className?: string;
  backTo: string;
  onClick?: () => void;
}

export const Back = ({ className, backTo, onClick }: IBackProps) => {
  const history = useHistory();
  return (
    <button
      onClick={() => {
        if (onClick) onClick();
        history.push(backTo);
        return;
      }}
      className={cx([style.back, className])}>
      <img className={style.arrow} src={arrow} />
    </button>
  );
};
