import { Redirect, Route } from 'react-router-dom'
import useAnalytics from '../analytics/useAnalytics'
import { useContext } from 'react'
import { GlobalProviderContext } from './GlobalProvider'
import { extractConfiguration } from '../utils/data'
import auth from '../utils/auth'
import { AppShell, defaultTheme } from '@learnn/designn'

const PrivateRoute = ({ name, component: Component, ...rest }) => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)

  const { logScreenView } = useAnalytics()
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated()) {
          logScreenView(name, name)
        }

        return auth.isAuthenticated() ? (
          configuration?.maintenanceMode?.active ? (
            <Redirect
              to={{
                pathname: '/manutenzione',
                state: { config: configuration.maintenanceMode },
              }}
            />
          ) : (
            <AppShell theme={defaultTheme}>
              <Component {...props} />
            </AppShell>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
