import { FC } from 'react'

import style from './courseWidget.module.css'
import ReactMarkdown from 'react-markdown'
import { CoursePreview } from '@learnn/sdk/src/api/course'
import { useHistory } from 'react-router-dom'

export interface ICourseWidget {
  course: CoursePreview
  widgetTitle?: string
}

export const CourseWidget: FC<ICourseWidget> = ({ course, widgetTitle }) => {
  const history = useHistory()
  return (
    <div className={style.container} onClick={() => history.push(`/corso/${course.id}`)}>
      <img src={course.coverImage} className={style.image} />
      <div className={style.widgetTitle}>{widgetTitle ?? 'CORSO CONSIGLIATO'}</div>
      <div className={style.courseTitle}>{course.title}</div>
      <div className={style.courseDescription}>
        <ReactMarkdown>{course.description}</ReactMarkdown>
      </div>
      <div className={style.tagsContainer}>
        {course.categories.map(c => (
          <div key={`${course.id}-${c.id}`} className={style.tag}>
            {c.title}
          </div>
        ))}
      </div>
    </div>
  )
}
