import { useContext } from 'react'
import { GlobalProviderContext } from '../../components/GlobalProvider'

type ErrorWithCode = {
  code: string
}

export const useCheckoutErrorHandler = () => {
  // @ts-ignore
  const globalContext = useContext(GlobalProviderContext)

  const handleError = (error: unknown) => {
    let code = error

    const checkErrorWithCode = (error: unknown): error is ErrorWithCode => {
      return typeof error === 'object' && error !== null && 'code' in error
    }

    if (checkErrorWithCode(error)) code = error.code

    if (window.Intercom) {
      switch (code) {
        case 'card_declined': {
          window.Intercom('trackEvent', 'error-payment', { code: 'error_checkout_card_declined' })
          break
        }
        case 'expired_card': {
          window.Intercom('trackEvent', 'error-payment', { code: 'error_checkout_expired_card' })
          break
        }
        default: {
          window.Intercom('trackEvent', 'error-payment', { code: 'error_checkout_generic' })
        }
      }
    }
  }

  return {
    handleError,
  }
}
