import DOMPurify from 'dompurify';

const allowedStyles = [
  'color', 'font-size', 'font-weight', 'font-family', 'line-height', 'text-align',
  'margin', 'padding', 'border', 'display', 'width', 'height', 'background-color'
];

const sanitizeJustHtml = (html: string): string => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: [
      'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'div', 'span', 'img', 'table', 'thead', 'tbody', 'th', 'tr', 'td', 'br', 'ul', 'ol', 'li', 'b', 'i', 'strong', 'em'
    ],
    ALLOWED_ATTR: [
      'href', 'target', 'style', 'src', 'alt', 'width', 'height', 'title', 'class', 'id'
    ],
    FORBID_ATTR: ['onerror', 'onload', 'onclick'],
    ALLOWED_URI_REGEXP: /^https?:\/\//i
  });
};

const sanitizeStyleAttributes = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');  
  const elementsWithStyle = doc.querySelectorAll('[style]');
  
  elementsWithStyle.forEach((element) => {
    const styles = element.getAttribute('style')?.split(';') || [];    
    const filteredStyles = styles
      .filter(style => getAllowedStyles(style))
      .join(';');    
    element.setAttribute('style', filteredStyles);
  });
  return doc.body.innerHTML;
};

const getAllowedStyles = (style: string): boolean => {
  const [property] = style.split(':').map(str => str.trim());
  return allowedStyles.includes(property);
};

export const sanitizeHtml = (html: string): string => {
  const sanitizedHtml = sanitizeJustHtml(html);
  return sanitizeStyleAttributes(sanitizedHtml);
};
