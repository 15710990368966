import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import useAnalytics from '../../analytics/useAnalytics'
//@ts-ignore
import { ProgressBar } from '../ProgressBar'
import { IHomeLastProgress } from '@learnn/sdk/src/api/dynamic-sections/types'
import { CourseCard } from '@learnn/designn'
import { useFeatureFlagVariantKey } from 'posthog-js/react'

export interface IProgressCard {
  lastProgress: IHomeLastProgress
}

export const ProgressCard: FC<IProgressCard> = ({ lastProgress }) => {
  const { logContinueWatching } = useAnalytics()
  const history = useHistory()
  const { course } = lastProgress
  const variant = useFeatureFlagVariantKey('course-vard-variant')

  return (
    <CourseCard
      variant={variant === 'new_card' ? 'longTitle' : 'fullImage'}
      onClick={() => {
        logContinueWatching(lastProgress.id.toString(), {
          lesson_title: lastProgress.title,
          lesson_duration: lastProgress.duration,
          lesson_progress: lastProgress.progress,
          call_to_action: 'Continua',
        })
        history.push(`/corso/${course.id}`)
      }}
      title={course.shortTitle ?? course.title}
      coverImage={course.coverImage}
      subtitle={`${course.type?.title}${course.duration ? ` • ${course.duration}` : ''}`}
      progressPercentage={course.progress}
      size='md'
    />
  )
}
