import { useNote } from '../NotePopup/useNote'
import { forwardRef, useState } from 'react'
import style from './note.module.css'
import { Button } from '../Button'
import { LoadingIndicator } from '../LoadingIndicator'
import useAnalytics from '../../analytics/useAnalytics'
import { LessonNote } from '@learnn/sdk/src/api/note'

type NoteContentProps = {
  savedNote: LessonNote
  moduleId: string
  viewTime: number
  toggleFocus?: (isFocused: boolean) => void
}

type NoteEditorProps = {
  onChange?: () => void
  onBlur?: () => void
  onFocus?: () => void
  onSave: (text: string) => void
  loading: boolean
  text?: string | null
  resetOnSave?: boolean
}

export type NoteEditorControls = {
  getText: () => string
}

export const EditableNote = ({ moduleId, viewTime, savedNote, toggleFocus }: NoteContentProps) => {
  const note = useNote(savedNote.lessonId, savedNote.courseId, {
    noteId: savedNote.noteId,
    content: savedNote.data,
  })
  const { logSaveNote } = useAnalytics()

  const save = async (text: string) => {
    if (!note.loading) {
      await note.saveNote(text, viewTime)
      logSaveNote(savedNote.courseId, moduleId, savedNote.lessonId)
    }
  }

  return (
    <NoteEditor
      loading={note.loading}
      onFocus={() => {
        if (toggleFocus) {
          toggleFocus(true)
        }
      }}
      onBlur={() => {
        if (toggleFocus) {
          toggleFocus(false)
        }
      }}
      onSave={save}
      text={note.currentNote?.content}
    />
  )
}

export const NoteEditor = forwardRef<NoteEditorControls, NoteEditorProps>(
  ({ onChange, onFocus, onBlur, onSave, loading, text, resetOnSave }, ref) => {
    const [textArea, setTextArea] = useState(text ?? '')

    const ensureRef = () => {
      if (!ref || ref === null) {
        return
      }

      const controls: NoteEditorControls = {
        getText: () => {
          return textArea
        },
      }

      if (typeof ref === 'function') {
        ref(controls)
      }
    }

    return (
      <div className={style.container}>
        <textarea
          ref={(e: HTMLTextAreaElement) => {
            if (e) {
              ensureRef()
            }
          }}
          onChange={e => {
            setTextArea(e.target.value)
            if (onChange) {
              onChange()
            }
          }}
          onFocus={() => {
            if (onFocus) {
              onFocus()
            }
          }}
          onBlur={() => {
            if (onBlur) {
              onBlur()
            }
          }}
          className={style.textArea}
          name='note'
          rows={10}
          value={textArea}
          placeholder='Cosa vuoi ricordarti?'
        />
        <div className={style.buttonsContainer}>
          <Button
            small
            scheme='dark'
            onClick={() => {
              onSave(textArea)
              if (resetOnSave) {
                setTextArea('')
              }
            }}
            className={style.button}
            disabled={loading || !textArea}>
            {!loading ? 'Aggiungi' : <LoadingIndicator />}
          </Button>
        </div>
      </div>
    )
  },
)
