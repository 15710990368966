import { FC } from 'react'
import { CourseNote as Nota } from '@learnn/sdk/src/api/note'
import {
  Container,
  Title,
  useTheme,
  EditableNote,
  Grid,
  HorizontalStack,
  Text,
  VerticalStack,
} from '@learnn/designn'
import { updateNote } from '../../../../controllers/Note'
import { useQueryClient } from 'react-query'
import { useShare } from './../../../../components/SharePopup/useShare'
import useAnalytics from '../../../../analytics/useAnalytics'
import env from '../../../../env.json'

export type NotesListProps = {
  notes: Nota[]
  onDeleteNotaClick: (noteId: string) => void
}

export const NotesList: FC<NotesListProps> = ({ notes, onDeleteNotaClick }) => {
  const { spacing, colors, borders } = useTheme()
  const queryClient = useQueryClient()
  const { share } = useShare()
  const { logSaveNote, logShareNote } = useAnalytics()

  return (
    <Container>
      <Title variant='heading2xl' mb={spacing.space_6}>
        Note
      </Title>

      <Grid gridTemplateColumns={'repeat(1, 1fr)'} gridGap={spacing.space_4}>
        {notes.length ? (
          notes.map(note => (
            <EditableNote
              title={note.lesson.title}
              body={note.data}
              //@ts-ignore //TODO
              onEditClick={async value => {
                await updateNote(note.noteId, value)
                await queryClient.invalidateQueries(['notes', note.courseId])
                logSaveNote(note.courseId, '', note.lessonId)
              }}
              onDeleteClick={() => {
                onDeleteNotaClick(note.noteId)
              }}
              onShareClick={() => {
                share({
                  body: `Ho preso una nota su Learnn :\n\n"${note.data}"`,
                  type: 'note',
                  url: `${env.SITE_URL}/lezione/${note.lessonId}`,
                  title: 'Condividi la nota con un tuo collega',
                  onPress: () => logShareNote(note.courseId, '', note.lessonId),
                })
              }}
            />
          ))
        ) : (
          <HorizontalStack
            borderRadius={borders.radius.large}
            bg={colors.card_background}
            borderStyle='solid'
            borderColor={colors.card_border}
            borderWidth={borders.width.base}
            p={spacing.space_20}
            justifyContent='center'>
            <VerticalStack>
              <Text alignment='center' variant='bodyMd'>
                Non hai ancora aggiunto nessuna nota
              </Text>
            </VerticalStack>
          </HorizontalStack>
        )}
      </Grid>
    </Container>
  )
}
