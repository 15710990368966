import { motion } from 'framer-motion'

import { TriggerButton } from '../LessonPlayer/controls/TriggerButton'
import style from './triggerPopup.module.css'
import cx from 'classnames'
import { useEffect } from 'react'

export type OpenSideType = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'

export interface ITriggerPopup {
  openSide?: OpenSideType
  visible: boolean
  children: React.ReactChild | React.ReactChild[]
  image: string
  width?: string
  height?: string
  onOpen?(): void
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void

  buttonWidth?: number
  buttonHeight?: number
  buttonClassName?: string
  buttonClassNameImage?: string
  popupContainer?: string
  lockScroll?: boolean
  zIndex?: number
}

const variants = {
  hidden: {
    opacity: 0,
    display: 'none',
  },
  visible: {
    opacity: 1,
    display: 'block',
  },
}

export const TriggerPopup = ({
  children,
  visible = false,
  onClick,
  image,
  openSide = 'top-left',
  buttonClassName,
  buttonClassNameImage,
  lockScroll,
  popupContainer,
  onOpen,
  zIndex = 0,
}: ITriggerPopup) => {
  useEffect(() => {
    if (visible) {
      if (onOpen) {
        onOpen()
      }
    }
  }, [visible])
  return (
    <div className={style.container} style={{ zIndex }}>
      {visible && (
        <motion.div
          variants={variants}
          className={cx([style.innerContainer, popupContainer])}
          //@ts-ignore //TODO
          style={{ overflowY: lockScroll ? 'hidden' : 'scroll' }}
          data-side={openSide}>
          {children}
        </motion.div>
      )}
      <TriggerButton
        className={buttonClassName}
        classNameImage={buttonClassNameImage}
        image={image}
        onClick={e => {
          e.stopPropagation()
          onClick(e)
        }}
      />
    </div>
  )
}
