import { IVideoReadOnlyState } from '../useReactPlayer'
import { useQuery } from 'react-query'
import { getCourse } from '../../../controllers/Course'
import { matchQuery } from '../../../utils/matchQuery'
import { ErrorMessage } from '../../ErrorMessage'
import { Modules } from './components/ModuleGroup/Modules'
import { Loader } from './components/ModuleGroup/Loader'
import { getUrlFromLesson } from '../../../utils/data'
import { useHistory } from 'react-router-dom'

export type LessonBrowseContentProps = {
  currentLessonId: string
  currentModuleId: string
  courseId: string
  userId: string
  state?: IVideoReadOnlyState
}
export const LessonBrowseTab = ({
  courseId,
  currentModuleId,
  currentLessonId,
  userId,
  state: playerState,
}: LessonBrowseContentProps) => {
  const courseQuery = useQuery(['courseLessons', courseId], () => getCourse(courseId))
  const history = useHistory()

  const handleLessonClick = (id: string) => {
    const lessonUrl = getUrlFromLesson({ id })

    history.push(
      lessonUrl,
      playerState && {
        startingRate: playerState.playbackRate,
        subtitlesVisible: playerState.subtitlesVisible,
      },
    )
  }

  return (
    <>
      {matchQuery(courseQuery, {
        loading: () => <Loader />,
        error: () => <ErrorMessage />,
        success: course => (
          <Modules
            userId={userId}
            course={course}
            currentModuleId={currentModuleId}
            currentLessonId={currentLessonId}
            onLessonCLick={handleLessonClick}
          />
        ),
      })}
    </>
  )
}
