import { auth } from './auth';
import { mylearnn } from './mylearnn';
import { engagement } from './engagement';
import { configuration } from './configuration';
import { player } from './player';
import { webinar } from './webinar';
import { home } from './home';
import { account } from './account';
import { comingcontents } from './comingcontents';
import { quiz } from './quiz';
import { profile } from './profile';
import { search } from './search';
import { smartSearch } from './smart-search';
import { singlecontent } from './singlecontent';
import { feedback } from './feedback';
import { note } from './note';
import { course } from './course';
import { getApolloClient, getCmsApolloClient } from './apollo';
import { recommender } from './recommender';
import { explore } from './explore';
import { fast } from './fast';
import { paths } from './paths';
import getSearchClient from './meilisearch';
import getSearchClients from './orama';
import { billing } from './billing';
import { featureFlag } from './feature-flag';
const initialize = async (params) => {
    const { client: cmsClient, refreshToken, getToken, } = await getCmsApolloClient(params.apiUrl, params.cmsConfigApiUrl, params.cmsApiToken, params.token, params.options.cache?.persistentStorage);
    const { client: quizClient, refreshToken: refreshQuizToken } = getApolloClient(params.quizUrl, params.token, params.quizApiKey);
    const { client: configClient, refreshToken: refreshConfigToken } = getApolloClient(params.configUrl, params.token, params.configApiKey);
    const { client: engagementClient, refreshToken: refreshEngagementToken } = getApolloClient(params.engagementGraphUrl, params.token, params.engagementGraphKey);
    const { client: profileClient, refreshToken: refreshProfileToken } = getApolloClient(params.profileApiUrl, params.token, params.profileApiKey);
    const authFactory = auth(params.keycloakRealmUrl, params.clientId, params.clientSecret, params.identityApiUrl, params.checkoutApiUrl, params.webKeycloakJwtPublicKey, cmsClient, t => {
        refreshToken(t);
        refreshQuizToken(t);
        refreshConfigToken(t);
        refreshEngagementToken(t);
        refreshProfileToken(t);
    }, () => {
        refreshToken();
        refreshQuizToken();
        refreshConfigToken();
        refreshProfileToken();
    }, getToken, params.options.auth?.verifyOfflineToken);
    const { client: searchClient } = getSearchClient(params.searchUrl, params.searchApiKey);
    const oramaClient = getSearchClients({
        coursesEndpoint: params.oramaCoursesUrl,
        lessonsEndpoint: params.oramaLessonsUrl,
        pathsEndpoint: params.oramaPathsUrl,
        expertsEndpoint: params.oramaExpertsUrl,
        coursesApiKey: params.oramaCoursesApiKey,
        lessonsApiKey: params.oramaLessonsApiKey,
        pathsApiKey: params.oramaPathsApiKey,
        expertsApiKey: params.oramaExpertsApiKey,
        coursesSearchType: params.oramaCoursesSearchType,
        coursesSearchThreshold: params.oramaCoursesSearchThreshold,
        lessonsSearchType: params.oramaLessonsSearchType,
        lessonsSearchThreshold: params.oramaLessonsSearchThreshold,
        pathsSearchType: params.oramaPathsSearchType,
        pathsSearchThreshold: params.oramaPathsSearchThreshold,
        expertsSearchType: params.oramaExpertsSearchType,
        expertsSearchThreshold: params.oramaExpertsSearchThreshold,
    });
    const configurationFactory = configuration(cmsClient, configClient, authFactory);
    const courseFactory = course(cmsClient);
    const playerFactory = player(params.engagementUrl, cmsClient, getToken, courseFactory.getCourseBaseByLessonId);
    const myLearnnFactory = mylearnn(cmsClient);
    const engagementFactory = engagement(params.engagementUrl, getToken, courseFactory);
    const recommenderFactory = recommender(params.recommenderUrl, getToken);
    const quizFactory = quiz(quizClient, cmsClient, configurationFactory, engagementFactory);
    const profileFactory = profile(profileClient, params.profileRestApiUrl, getToken);
    const pathsFactory = paths({
        client: cmsClient,
    });
    const smartSearchFactory = smartSearch(searchClient, oramaClient, engagementFactory);
    const featureFlagFactory = featureFlag();
    return {
        auth: authFactory,
        mylearnn: myLearnnFactory,
        configuration: configurationFactory,
        note: note(engagementClient, playerFactory),
        recommender: recommenderFactory,
        engagement: engagementFactory,
        player: playerFactory,
        home: home(cmsClient, engagementFactory, recommenderFactory, quizFactory, courseFactory, configurationFactory, playerFactory, profileFactory, pathsFactory),
        explore: explore(cmsClient, engagementFactory, recommenderFactory, quizFactory, courseFactory, configurationFactory, playerFactory, profileFactory, pathsFactory),
        fast: fast(cmsClient, engagementFactory, recommenderFactory, quizFactory, courseFactory, configurationFactory, playerFactory, profileFactory, pathsFactory),
        account: account(params.identityApiUrl, params.keycloakRealmUrl, params.myApiUrl, cmsClient, getToken),
        comingcontents: comingcontents(cmsClient),
        search: search(cmsClient),
        webinar: webinar(cmsClient),
        singlecontent: singlecontent(cmsClient),
        feedback: feedback(engagementClient),
        course: courseFactory,
        quiz: quizFactory,
        profile: profileFactory,
        paths: pathsFactory,
        smartSearch: smartSearchFactory,
        billing: billing(params.myApiUrl, getToken),
        featureFlag: featureFlagFactory,
    };
};
export const API = {
    initialize: initialize,
};
