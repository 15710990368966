import { forwardRef, useEffect } from 'react'
import { GiftDetails, displayCardBrand, giftSubscription } from '../../controllers/Payment'
import style from './primaryCardPayment.module.css'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { useGTMCheckout } from '../../analytics/gtm'
import { faApplePay, faGooglePay, faPaypal } from '@fortawesome/free-brands-svg-icons'
import { getErrorMessage } from '../../utils/checkout'
import { FormActions } from '../../screens/PaymentScreen/PaymentScreen'



type IPrimaryPaymentProps = {
  userToken: string
  primaryPaymentMethod: any
  plan: string
  price?: number
  giftDetails: GiftDetails
  onSuccess(): void
  onChangeCardClick(): void
  togglePaymentLoading(loading: boolean): void
  setErrorMessage(message: string): void
  creditNotes?: number
  coupon: string
}

export const PrimaryMethodPayment = forwardRef<FormActions, IPrimaryPaymentProps>(({
  userToken,
  primaryPaymentMethod,
  plan,
  price,
  coupon,
  giftDetails,
  onSuccess,
  onChangeCardClick,
  setErrorMessage,
  togglePaymentLoading
}, ref) => {
  const { sendToGTM } = useGTMCheckout()

  const sendGift = async () => {
    togglePaymentLoading(true)
    try {
      if (giftDetails) {
        const giftId = await giftSubscription({
          userToken,
          planId: plan,
          //@ts-ignore
          gifter: {
            email: giftDetails.senderEmail,
            signature: giftDetails.senderName,
            note: giftDetails.senderMessage ?? '',
          },
          gifted: {
            first_name: giftDetails.receiverName,
            last_name: giftDetails.receiverLastName,
            email: giftDetails.receiverEmail,
          },
          scheduledAt: giftDetails.scheduledAt ? giftDetails.scheduledAt : undefined,
          couponCodes: coupon ? [coupon] : [],
        })
        await sendToGTM({
          event: 'checkoutCompleted',
          productName:
            plan === 'regalo---1-anno'
              ? 'Regalo Annuale'
              : plan === 'regalo---6-mesi'
              ? 'Regalo 6 mesi'
              : 'Regalo 3 mesi',
          productSKU: plan,
          trial: false,
          price: price,
          paymentMethod: primaryPaymentMethod,
          subscriptionPeriod:
            plan === 'regalo---1-anno'
              ? 'Yearly'
              : plan === 'regalo---6-mesi'
              ? '6 Months'
              : '3 Months',
          discount: coupon ? true : false,
          transactionId: giftId,
          couponName: coupon ?? '',
          productVariant: 'gift',
        })
        setErrorMessage('')
        onSuccess()
      }
    } catch (error: any) {
      togglePaymentLoading(false)
      if (error.name !== 'VALIDATION_FAILED') {
        if (error.message === 'GIFTED_INVALID_SUBSCRIPTION')
          return setErrorMessage(getErrorMessage('giftedInvalidSubscriptionMessage'))
        setErrorMessage(getErrorMessage('cardNotAccepted'))
      }
    }
  }


  const renderPaymentMethod = () => {
    switch (primaryPaymentMethod.type) {
      case 'card':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faCreditCard} className={style.methodIcon} />
            <div className={style.methodInfo}>
              <span className={style.cardBrand}>
                {displayCardBrand(primaryPaymentMethod.card.brand)}
              </span>
              <span>termina con</span>
              <span className={style.last4}>{primaryPaymentMethod.card.last4}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica metodo
            </div>
          </div>
        )
      case 'paypal_express_checkout':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faPaypal} className={style.methodIcon} />
            <div className={style.methodInfo}>
              <span className={style.paymentEmail}>{primaryPaymentMethod.paypal.email}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica
            </div>
          </div>
        )
      case 'google_pay':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faGooglePay} className={style.methodIcon} />
            <div className={style.methodInfo}>
              <span>termina con</span>
              <span className={style.last4}>{primaryPaymentMethod.card.last4}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica
            </div>
          </div>
        )
      case 'apply_pay':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faApplePay} className={style.methodIcon} />
            <div className={style.methodInfo}>
              <span>termina con</span>
              <span className={style.last4}>{primaryPaymentMethod.card.last4}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica
            </div>
          </div>
        )
      case 'direct_debit':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faBuildingColumns} className={style.methodIcon} />
            <div className={style.methodInfo}>
              Intestato a
              <span className={cx([style.cardBrand, 'ml-1'])}>
                {primaryPaymentMethod.bankAccount.nameOnAccount}
              </span>
              <br />
              <span>termina con</span>
              <span className={style.last4}>{primaryPaymentMethod.bankAccount.last4}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica metodo
            </div>
          </div>
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    const controls: FormActions = {
      submit: () => {
        sendGift()
      },
    }
    if (typeof ref === 'function') {
      ref(controls)
    }

  }, [])

  return (
    <>
      <p className={cx([style.header, 'mb-1'])}>METODO DI PAGAMENTO</p>
      <p className={cx([style.subtitle, 'mb-4'])}>
        Questo è il metodo di pagamento preferito impostato
      </p>

      {renderPaymentMethod()}
    </>
  )
})