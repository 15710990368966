import Header from '../components/Header'
import Container from 'react-bootstrap/Container'
import Footer from '../components/Footer'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button } from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { LiveChat } from './LiveChat'

function Error({ message, ...props }) {
  const history = useHistory()
  return (
    <>
      <LiveChat />
      <Header />
      <Container>
        <Row className='justify-content-center   pt-5  main-container-big'>
          <Col xs={12} md={6}>
            <h1 className='title'>Si è verificato un errore</h1>
            <span className='subtitle mb-4'>{message}</span>
            {props.goBack ? (
              <div
                className='mt-4'
                onClick={() => (props.onGoBack ? props.onGoBack() : history.goBack())}>
                <FontAwesomeIcon icon={faChevronLeft} />{' '}
                <span className='goBackSubtitle'>{props.goBackText ?? 'Torna indietro'}</span>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Error
