import { Provider } from '@learnn/analytics/src/providers'
import env from '../../env.json'
import { PostHog } from 'posthog-js'

export default function createPostHogAnalyticsProvider(posthog: PostHog): Provider {
  const logEvent = async (name: string, params: { [key: string]: any }) => {
    if (eventsToLog.includes(name)) {
      posthog.capture(name, params)
    }
  }
  const logSelectContent = async (contentType: string, itemId: string) =>
    logEvent('select_content', { contentType, itemId })
  const logLogin = async (method?: string) => logEvent('login', { method: method ?? 'strapi' })
  const logScreenView = async (_screenClass: string, screenName: string) => {
    posthog.capture('$pageview', { screenName })
  }
  const setUserProperties = async (properties: { [key: string]: any }) => {
    posthog.capture('$set', {
      $set: properties,
    })
  }

  const setUserProperty = async (name: string, value: any) => {
    let o = {}
    o[name] = value
    posthog.capture('$set', {
      $set: o,
    })
  }

  const setUserId = async (userId: string) => {
    posthog.capture('$set', {
      $set_once: { userId },
    })
  }
  const setUserEmail = async (email: string) => posthog.identify(email)

  const eventsToLog = [
    'new_course_started',
    'course_completed',
    'new_module_started',
    'module_completed',
    'lesson_save',
    'lesson_unsave',
    'course_save',
    'course_unsave',
    'lesson_started',
    'lesson_completed',
    'video_fullscreen',
    'video_change_speed',
    'video_switch_audio',
    'video_switch_video',
    'lesson_utile_remove',
    'lesson_nonutile_remove',
    'lesson_utile_add',
    'lesson_nonutile_add',
    'course_utile_remove',
    'course_nonutile_remove',
    'course_utile_add',
    'course_nonutile_add',
    'create_note',
    'save_note',
    'search_keyword',
    'search_category',
    'select_search_section',
    'select_search_item',
    'notification_opened',
    'select_item',
    'continue_watching',
    'quiz_started',
    'quiz_submitted',
    'quiz_failed',
    'quiz_passed',
    'navigate_to',
    'logout',
    'toggle_notification',
    'change_password',
    'update_account_info',
    'select_section',
    'expand_course_module',
    'ai_assistant_source_click',
    'ai_assistant_send_message',
    'ai_player_assistant_open',
    'ai_course_assistant_open',
    'ai_global_assistant_open',
    'ai_assistant_message_received',
  ]

  return {
    logLogin,
    logSelectContent,
    logEvent,
    logScreenView,
    setUserId,
    setUserEmail,
    setUserProperties,
    setUserProperty,
    name: 'posthog',
  }
}

export const initializationOptions = {
  api_host: env.WEB_PUBLIC_POSTHOG_API_HOST,
  ui_host: env.WEB_PUBLIC_POSTHOG_UI_HOST,
  person_profiles: 'identified_only',
  autocapture: false,
  capture_pageview: false,
  disable_session_recording: true,
}
