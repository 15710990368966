import style from './lessonsCarousel.module.css'
import { useHistory } from 'react-router-dom'
import { LessonCard } from '../../components/LessonCard'
import { getUrlFromLesson } from '../../utils/data'
import { Lesson, LessonEngagement } from '@learnn/sdk/dist/api/course'
import { CustomCarousel } from '../Carousel/CustomCarousel'

export interface LessonsCarouselProps {
  lessons: (Lesson & Partial<LessonEngagement>)[][]
  title?: string
  id?: string
  titleClass?: string
  myLearnn?: boolean
  onShowAll?: Function
}
export const LessonsCarousel = ({
  lessons,
  title,
  onShowAll,
  myLearnn = false,
}: LessonsCarouselProps) => {
  const history = useHistory()


  return (
    <CustomCarousel
      title={title ?? ''}
      data={lessons}
      carouselKind='lesson'
      onShowAll={onShowAll}
      renderSlide={group => {
        return (
          <div className={style.groupContainer}>
            {myLearnn ? (
              <>
                <LessonCard
                  myLearnn
                  myLearnnId={group[0].myLearnnId}
                  myLearnnClass={style.myLearnnButton}
                  lesson={group[0]}
                  imageStyle={style.lessonImageSmall}
                  contentStyle={style.lessonInfo}
                  onClick={() => history.push(getUrlFromLesson({ id: group[0].id }))}
                />
                {group[1] ? (
                  <LessonCard
                    myLearnn
                    myLearnnId={group[1].myLearnnId}
                    myLearnnClass={style.myLearnnButton}
                    lesson={group[1]}
                    imageStyle={style.lessonImageSmall}
                    contentStyle={style.lessonInfo}
                    wrapperStyle={style.lessonCard}
                    onClick={() => history.push(getUrlFromLesson({ id: group[1].id }))}
                  />
                ) : null}
                {group[2] ? (
                  <LessonCard
                    myLearnn
                    myLearnnId={group[2].myLearnnId}
                    myLearnnClass={style.myLearnnButton}
                    lesson={group[2]}
                    imageStyle={style.lessonImageSmall}
                    contentStyle={style.lessonInfo}
                    wrapperStyle={style.lessonCard}
                    onClick={() => history.push(getUrlFromLesson({ id: group[2].id }))}
                  />
                ) : null}
              </>
            ) : (
              <>
                <LessonCard
                  lesson={group[0]}
                  imageStyle={style.lessonImage}
                  titleClassName={style.lessonTitle}
                  contentStyle={style.lessonInfo}
                  onClick={() => history.push(getUrlFromLesson({ id: group[0].id }))}
                />
                {group[1] ? (
                  <LessonCard
                    lesson={group[1]}
                    imageStyle={style.lessonImage}
                    titleClassName={style.lessonTitle}
                    contentStyle={style.lessonInfo}
                    wrapperStyle={style.lessonCard}
                    onClick={() => history.push(getUrlFromLesson({ id: group[1].id }))}
                  />
                ) : null}
                {group[2] ? (
                  <LessonCard
                    lesson={group[2]}
                    imageStyle={style.lessonImage}
                    titleClassName={style.lessonTitle}
                    contentStyle={style.lessonInfo}
                    wrapperStyle={style.lessonCard}
                    onClick={() => history.push(getUrlFromLesson({ id: group[2].id }))}
                  />
                ) : null}
              </>
            )}
          </div>
        )
      }}
    />
  );
}
