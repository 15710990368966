import style from './verified.module.css'
import { useQuery } from 'react-query'

//Components
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ErrorMessage } from '../../components/ErrorMessage'
import Loader from 'react-loader-spinner'
import { matchQuery } from '../../utils/matchQuery'
import { CardsGrid } from '../../components/CardsGrid/CardsGrid'
import { getVerifiedStatus } from '../../controllers/Quiz'
import { useContext } from 'react'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { VerifiedStatusCard } from '../../components/VerifiedStatusCard'
import { VerifiedPassedCard } from '../../components/VerifiedPassedCard'

export const Verified: React.FC = () => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  if (!userId) {
    throw new Error('User not logged in')
  }
  const verifiedStatusQuery = useQuery(['verifiedStatus'], () => getVerifiedStatus(userId), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  return (
    <>
      {matchQuery(verifiedStatusQuery, {
        success: ({ passed, available, failed }) => {
          console.log({ passed, available, failed })
          return (
            <div className={style.container}>
              {passed.length > 0 && (
                <>
                  <h2 className={style.sectionTitle}>Verifiche ottenute</h2>
                  <div className='mt-1 mb-5'>
                    <CardsGrid type='verified'>
                      {passed.map(data => (
                        <VerifiedPassedCard key={`wall-${data?.courseId}`} verified={data} />
                      ))}
                    </CardsGrid>
                  </div>
                </>
              )}

              <h2 className={style.sectionTitle}>Verifiche disponibili</h2>

              {available.length === 0 ? (
                <Row>
                  <Col xs={12} md={{ span: 8 }} className={style.containerEmpty}>
                    <h1 className={style.emptyTextTitle}>Non ci sono verifiche disponibili.</h1>
                  </Col>
                </Row>
              ) : (
                <div className='mt-1'>
                  <CardsGrid type='verified'>
                    {available.map(data => (
                      <VerifiedStatusCard key={`wall-${data?.courseId}`} verified={data} />
                    ))}
                  </CardsGrid>
                </div>
              )}

              {failed.length > 0 ? (
                <div className='mt-5'>
                  <h2 className={style.sectionTitle}>Verifiche fallite</h2>
                  <div className='mt-1'>
                    <CardsGrid type='verified'>
                      {failed.map(data => (
                        <VerifiedStatusCard key={`wall-${data?.courseId}`} verified={data} />
                      ))}
                    </CardsGrid>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )
        },
        error: () => (
          <div className={style.errorContainer}>
            <ErrorMessage />
          </div>
        ),
        loading: () => (
          <div className={style.containerLoading}>
            <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
          </div>
        ),
        idle: () => (
          <div className={style.containerLoading}>
            <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
          </div>
        ),
      })}
    </>
  )
}
