import { UseQueryResult } from 'react-query'
import { ReactNode } from 'react'

export function matchQuery<E, D>(
  useQueryResult: UseQueryResult<D, E>,
  matches: {
    loading: () => ReactNode
    idle?: () => ReactNode
    error: (error: E, loading: boolean) => ReactNode
    success: (data: D, loading: boolean) => ReactNode
  },
): ReactNode {
  switch (useQueryResult.status) {
    case 'loading':
      return matches.loading()
    case 'idle':
      return matches.idle ? matches.idle() : matches.loading()
    case 'success':
      return matches.success(useQueryResult.data, useQueryResult.isFetching)
    case 'error':
      return matches.error(useQueryResult.error, useQueryResult.isFetching)
  }
}
