import { IFrame } from '../../components/IFrame/IFrame'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export const TechFeedbackScreen = () => {
  return (
    <AppLayout>
      <IFrame url='https://c.learnn.com/tech/' fullHeight />
    </AppLayout>
  )
}
