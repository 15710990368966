import { getPaths } from './getPaths';
import { getPath, getPathById } from './getPath';
import { getRecommendedPathsBySlug } from './getRecommendedPaths';
export const paths = (props) => {
    return {
        getPaths: getPaths(props),
        getPath: getPath(props),
        getPathById: getPathById(props),
        getRecommendedPathsBySlug: getRecommendedPathsBySlug(props),
    };
};
