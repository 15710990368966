import { FC, useState } from 'react'
import style from './select.module.css'
/*
  DOC: https://react-select.com
 */
import ReactSelect, { StylesConfig } from 'react-select'
import cx from 'classnames'

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faTimesCircle, faSearch } from "@fortawesome/free-solid-svg-icons";

export interface ISelectOption {
  value: any
  label: string
}
export type SelectProps =
  | {
      className?: string
      placeholder: string
      type: 'multi'
      options: ISelectOption[]
      value: ISelectOption[] | null
      onChange?(optionValues: ISelectOption[]): void
    }
  | {
      className?: string
      placeholder: string
      type: 'single'
      options: ISelectOption[]
      value: any
      onChange?(optionValues: ISelectOption): void
    }

export const Select: FC<SelectProps> = ({
  className,
  placeholder,
  type = 'single',
  value,
  options,
  onChange,
}) => {
  const [selected, setSelected] = useState(value)

  const handleChange = (value: any) => {
    setSelected(value)
    onChange && value !== 'default' && onChange(value)
  }

  const customStyles: StylesConfig = {
    control: base => ({
      ...base,
      borderColor: '#808080',
    }),
    option: provided => ({
      ...provided,
      padding: 10,
    }),
  }

  return (
    <div className={cx([style.container, className])}>
      <ReactSelect
        styles={customStyles}
        placeholder={placeholder}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'lightgrey',
            primary50: 'gainsboro',
            primary: 'black',
          },
        })}
        onChange={handleChange}
        value={selected}
        options={options}
        isMulti={type === 'multi'}
      />
    </div>
  )
}
