import { useRef } from 'react'
import style from './styles/answerResponse.module.css'
import { AnswersResults } from '../../components/QuizAttemptResults/AnswersResults'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'
import { QuestionStatusDone } from './InfiniteQuizScreen'
import { LessonPopup, PopupControls } from '../../components/LessonPopup'
import { Button, useTheme } from '@learnn/designn'

export type AnswerResultProps = {
  status: QuestionStatusDone
  onNextQuestionClick: () => unknown
  error: string
}
export const AnswerResult = ({ status, onNextQuestionClick, error }: AnswerResultProps) => {
  const lessonPopupref = useRef<null | PopupControls>(null)
  const { spacing } = useTheme()

  return (
    <>
      {status.answerResult.answers.some(a => a.userAnswer && a.correctAnswer) ? (
        <div className={style.resultInfo}>
          <p className={style.preTitle}>RISPOSTA CORRETTA</p>
          <h1 className={style.resultTitle}>Congratulazioni! 👏</h1>
          <p className={style.resultDescription}>Hai risposto correttamente alla domanda.</p>
        </div>
      ) : (
        <div className={style.resultInfo}>
          <p className={style.preTitle}>RISPOSTA SBAGLIATA</p>
          <h1 className={style.resultTitle}>Ci dispiace! 😔</h1>
          <p className={style.resultDescription}>Prova a rispondere ad un'altra domanda.</p>
        </div>
      )}

      <div className={style.answersContainer}>
        <AnswersResults question={status.answerResult} theme={'dark'} />
        <Button
          icon={<FontAwesomeIcon icon={faChevronRight} />}
          variant='primary'
          size='lg'
          iconPosition='right'
          label='Prossima domanda'
          onPress={onNextQuestionClick}
          mt={spacing.space_8}
        />
      </div>

      {status.answerResult.question.answerExplanation &&
        status.answerResult.question.answerExplanation !== 'null' && ( //Workaround -> PBI to fix DB is present
          <div className={style.explanationContainer}>
            <p className={style.explanationTitle}>SPIEGAZIONE</p>
            <ReactMarkdown>{status.answerResult.question.answerExplanation}</ReactMarkdown>
            <div
              className={style.explanationButton}
              onClick={() => (lessonPopupref.current ? lessonPopupref.current.show(true) : null)}>
              Approfondisci la spiegazione →
            </div>
          </div>
        )}
      <LessonPopup
        lessonId={status.answerResult.question.lessonId}
        startTime={status.answerResult.question.explanationTime}
        ref={e => {
          if (e) {
            lessonPopupref.current = e
          }
        }}
      />
      <div className={style.errorContainer}>{error}</div>
    </>
  )
}
