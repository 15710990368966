import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logo from '../assets/images/logo.png'

function Header({ type }) {
  return (
    <Container fluid className='p-3 '>
      <Row>
        <Col>
          <img
            className='logo'
            src={logo}
            alt='Learnn'
            style={{ filter: type === 'dark' ? 'invert(100%)' : 'unset' }}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Header
