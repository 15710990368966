import { FC } from 'react'
import style from './verifiedPassedCard.module.css'
import { VerifiedState } from '@learnn/sdk/dist/api/quiz'
import cx from 'classnames'
import verifiedImage from '../../assets/images/verified.png'
import { toReadableDate } from '../../utils/time'

export interface IVerifiedCard {
  verified: VerifiedState
}

export const VerifiedPassedCard: FC<IVerifiedCard> = ({ verified }) => {
  return (
    <a
      href={`https://learnn.com/v/${verified.quizStatus.attempts[0].attemptId}`}
      target='_blank'
      className={cx([style.card])}>
      <div className={style.cardInner}>
        <div className={style.imageContainer}>
          <img className={style.imageCover} src={verifiedImage} />
        </div>
        <div className={style.infoContainer}>
          <p className={style.title}>{verified.course.title}</p>
          <div className={style.dateContainer}>
            <p className={style.dateLabel}>DATA VERIFICA:</p>
            <p className={style.dateValue}>{`${toReadableDate(
              new Date(Date.parse(verified.quizStatus.attempts[0].submittedAt)),
            )}`}</p>
          </div>
          <p className={style.verifiedId}>
            <strong>ID VERIFICA:</strong> {verified.quizStatus.attempts[0].attemptId}
          </p>
        </div>
      </div>
    </a>
  )
}
