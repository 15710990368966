import { matchQuery } from "src/utils/matchQuery"
import { useCheckout } from "../../CheckoutContext"
import style from '../../paymentScreen.module.css'
import { displayAmountCents, filterDocumentLevelDiscounts } from "../../utils"
import { AmountCents, DiscountLineItem } from "../../types"
import { useState } from "react"
import cx from 'classnames'
import { toReadableDate } from "src/utils/time"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { DetailLine } from "./DetailLine"

export const CheckoutDetails = () => {
    const { estimateQuery, checkoutData, couponState, setCoupon } = useCheckout()
    const [couponVisible, setCouponVisible] = useState(false)
    const [couponValue, setCouponValue] = useState('')

    const renderDiscount = (discountLine: DiscountLineItem) => {
        switch (discountLine.discountType) {
            case "item_level_coupon":
                return <></>
            case "document_level_coupon":
                return (
                    <div className={style.discountContainer}>
                        <div className={style.couponDetails}>
                            <div className={style.appliedCouponText}>{discountLine.name ?? `Coupon applicato`}</div>
                            {/**
                            <div className={style.couponPercentageText}>
                                Risparmi il {discountLine.percentage}%
                            </div>
                            */}
                        </div>
                        <div className={style.couponPrice}>{`- ${displayAmountCents((discountLine.amount))}`}</div>
                    </div>
                )
            case "promotional_credits":
                return (
                    <div className={style.discountContainer}>
                        <div className={style.lineText}>Crediti</div>
                        <div className={style.linePrice}>- {displayAmountCents((discountLine.amount))}</div>
                    </div>
                )
            case "prorated_credits":
                return (
                    <div className={style.discountContainer}>
                        {/** TODO custom line text and line price */}
                        <div className={style.lineText}>Rettifica</div>
                        <div className={style.linePrice}>- {displayAmountCents((discountLine.amount))}</div>
                    </div>
                )
        }
    }

    const renderCouponState = () => {

        return (
            <div style={{ width: '100%' }}>
                <div className={style.couponInputContainer}>
                    <input
                        className={cx(['form-control', style.couponInput])}
                        type='text'
                        name='coupon'
                        placeholder='Coupon'
                        onChange={c => setCouponValue(c.target.value)}
                        value={couponValue}
                    />
                    <button
                        type='button'
                        className={
                            couponState.state === 'error'
                                ? cx([style.couponButton, style.submitting])
                                : style.couponButton
                        }
                        disabled={!couponValue}
                        onClick={() => setCoupon(couponValue)}>
                        Applica
                    </button>
                </div>
                {couponState.state === 'error' && (<div className='error-text mt-1'>{couponState.message}</div>)}
            </div>
        )
    }


    return (
        <>
            {
                matchQuery(estimateQuery, {
                    loading: () => (
                        <CheckoutDetailsSkeleton />
                    ),
                    error: () => <></>,
                    success: (data) => (
                        <div className={style.detailsContainer}>
                            {
                                data.lines.filter(x => x.type === 'invoice').map((line, i) => (
                                    <div className={style.lineContainer}>
                                        <DetailLine line={line} index={i} key={i} />
                                    </div>
                                ))
                            }
                            {
                                data.discountLines?.filter(filterDocumentLevelDiscounts).map((line) => (
                                    <div className={style.lineContainer}>
                                        {renderDiscount(line)}
                                    </div>
                                ))
                            }


                            {!checkoutData.coupon && (
                                <div className={cx([style.couponContainer, style.lineContainer])}>
                                    {couponVisible ?
                                        renderCouponState()
                                        : (
                                            <span
                                                className={cx([style.couponText, style.hoverUnderline])}
                                                onClick={() => setCouponVisible(true)}>
                                                Hai un coupon?
                                            </span>
                                        )}
                                </div>
                            )}

                            {
                                data.lines.filter(x => x.type === 'credit_note').map((line, i) => (
                                    <div className={style.lineContainer}>
                                        <DetailLine line={line} index={i} key={i} />
                                    </div>))
                            }


                            {
                                data.future ? (
                                    <>
                                        <div className={style.lineContainer}>
                                            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                            <div className={style.totalContainer}>
                                                <div className={style.totalText}>Paghi ora</div>
                                                <div className={style.totalPrice}>
                                                    {displayAmountCents(0 as AmountCents)}
                                                </div>
                                            </div>
                                            <div className={style.totalContainer} style={{ marginTop: '10px' }}>
                                                <div className={style.renewTotal}>Prossimo rinnovo (IVA incl.)</div>
                                                <div className={style.totalPrice}>
                                                    {displayAmountCents(data.total)}
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className={style.lineContainer}>
                                        <div className={style.totalContainer}>
                                            <div className={style.totalText}>Totale (IVA incl.)</div>
                                            <div className={style.totalPrice}>
                                                {displayAmountCents(data.total)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                            {
                                data.future && (
                                    <div className={style.renewContainer}>
                                        <div className={style.renewText}>
                                            {`Il pagamento verrà effettuato il  ${toReadableDate(data.nextRenewal)}, alla data del prossimo rinnovo.`}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                })
            }
        </>

    )
}

export const CheckoutDetailsSkeleton = () => {
    return (
        <SkeletonTheme baseColor='#a3a3a3' highlightColor='#cacaca'>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Skeleton style={{ width: '150px', height: '30px' }}></Skeleton>
                <Skeleton style={{ width: '40px', height: '30px' }}></Skeleton>
            </div>
            <div style={{ height: '1px', backgroundColor: 'rgba(128, 128, 128, 0.3)', width: '100%', margin: '10px 0' }} />
            <Skeleton style={{ width: '100px', height: '30px' }}></Skeleton>
            <div style={{ height: '1px', backgroundColor: 'rgba(128, 128, 128, 0.3)', width: '100%', margin: '10px 0' }} />
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Skeleton style={{ width: '130px', height: '30px' }}></Skeleton>
                <Skeleton style={{ width: '40px', height: '30px' }}></Skeleton>
            </div>
        </SkeletonTheme>
    )
}