import style from './styles/goal.module.css'
import env from '../../env.json'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ShareComponent } from '../../components/SharePopup/Share'
import Confetti from 'react-confetti'
import { useWindowSize } from '../../utils/hooks'

export type GoalProps = {
  onSubmitClick: () => unknown
  currentAnswersStreak: number
  correctAnswersToReach: number
}
export const Goal = ({ onSubmitClick, currentAnswersStreak, correctAnswersToReach }: GoalProps) => {
  const size = useWindowSize()
  return (
    <div
      className={style.popupContainer}
      onClick={event => {
        event.preventDefault()
        onSubmitClick()
      }}>
      <div className={style.content}>
        <FontAwesomeIcon
          icon={faTimes}
          className={style.closeIcon}
          onClick={() => onSubmitClick()}
        />
        <div
          className={cx([style.box])}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          <img src={env.QUIZ_GOAL_ACHIVED_IMAGE_URL} className={style.feedbackImage} />
          <h1 className={style.title}>Complimenti: {currentAnswersStreak} di fila!</h1>
          <p className={style.description}>
            Fanne altri {correctAnswersToReach - currentAnswersStreak} per raggiungere il prossimo
            traguardo
          </p>
          <button className={style.ctaButton} onClick={onSubmitClick}>
            Continua la serie <FontAwesomeIcon icon={faChevronRight} className='ml-2' />
          </button>
          <ShareComponent
            type='external'
            title={'Sfida un tuo amico a fare meglio'}
            url={'https://my.learnn.com/infinite'}
            body={`Ho risposto correttamente a ${currentAnswersStreak} domande di fila su Learnn. Vediamo se mi superi 😉`}
            containerClassName={cx([style.shareContainer])}
          />
        </div>
      </div>
      <Confetti
        width={size.width ? size.width - 20 : 0}
        height={size.height}
        recycle={false}
        numberOfPieces={1000}
        tweenDuration={6000}
      />
    </div>
  )
}
