import React from 'react'
import { createGtmAnalyticsProvider } from './providers'
import { analytics, voidAnalytics, consoleAnalytics } from '@learnn/analytics'
import { GlobalProviderContext, IConfiguration } from '../components/GlobalProvider/context'
import { configureGTMWebapp } from './gtm'
import { activecampaignAnalyticsProvider } from './providers/activecampaign'
import mergeAnalyticsProviders from './merge_analytics_providers'
import { Provider } from '@learnn/analytics/src/providers'
import createPostHogAnalyticsProvider from './providers/posthog'
import { PostHog } from 'posthog-js'

//TODO: custom select lesson in course screen including course data
//TODO: extract content type and id from url (when possible)
//TODO: track scroll seconds stay view
//TODO: track single content
//TODO: track trailer
export const getAnalyticsProviderFactory = (
  configuration: IConfiguration,
  posthog: PostHog,
): Provider => {
  if (configuration.trackingMode === 'console') {
    return consoleAnalytics()
  }
  if (configuration.trackingMode === 'gtm') {
    const { sendToGTM } = configureGTMWebapp()
    return createGtmAnalyticsProvider(sendToGTM)
  }
  if (configuration.trackingMode === 'gtm_activecampaign') {
    const { sendToGTM } = configureGTMWebapp()
    const gtmProvider = createGtmAnalyticsProvider(sendToGTM)
    const activecampaignProvider = activecampaignAnalyticsProvider()
    return mergeAnalyticsProviders([gtmProvider, activecampaignProvider])
  }
  if (configuration.trackingMode === 'gtm_activecampaign_posthog') {
    const { sendToGTM } = configureGTMWebapp()
    const gtmProvider = createGtmAnalyticsProvider(sendToGTM)
    const activecampaignProvider = activecampaignAnalyticsProvider()
    const postHogProvider = createPostHogAnalyticsProvider(posthog)
    return mergeAnalyticsProviders([gtmProvider, activecampaignProvider, postHogProvider])
  }

  return voidAnalytics()
}

export const useAnalytics = () => {
  const globalProviderContext = React.useContext(GlobalProviderContext)
  const provider = globalProviderContext?.analyticsProvider ?? voidAnalytics()
  const route = { name: window.location.pathname }
  return createAnalytics(provider, route.name)
}
export const createAnalytics = (provider: Provider, route_name?: string) => {
  return analytics(provider, route_name ?? window.location.pathname)
}
export default useAnalytics
