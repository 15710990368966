import styles from './styles/pathListItem.module.css'
import { FC, useContext } from 'react'
import cx from 'classnames'
import { PathCard } from '@learnn/designn'
import { PathWithProgress } from '../../../controllers/Paths'
import { Path } from '@learnn/sdk/src/api/paths/types'
import { GlobalProviderContext } from '../../../components/GlobalProvider'
import { extractConfiguration } from '../../../utils/data'

export type PathListItemProps = {
  containerStyle?: string
  path: Path & PathWithProgress
  onClick: () => unknown
  size?: "lg" | "md"
}
export const PathListItem: FC<PathListItemProps> = ({ path, containerStyle, onClick, size }) => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)

  const { image } = path
  const totalHoursDuration = Math.round(path.courses.reduce((prev: number, i) => prev + i.durationHour + i.durationMinutes/60, 0))
  const quizAvailability = configuration?.quizAvailability ?? {}
  const verifiedCount = path.courses
    .filter(c => c.type?.slug && c.type.slug === 'course')
    .filter(course => !!quizAvailability[course.id]).length
  const cardSubtitle =
    totalHoursDuration
      ? `${path.courses.length} Corsi • ${totalHoursDuration} Ore` +
        (verifiedCount && verifiedCount > 0 ? ` • ${verifiedCount} ${verifiedCount === 1 ? 'Verifica' : 'Verifiche'}` : '')
      : ''

  return (
    <div className={cx([styles.container, containerStyle])} onClick={onClick}>
      <PathCard
        coverImage={image}
        subtitle={cardSubtitle}
        progress={path.progress ?? 0}
        hideProgressBar
        size={size}
      />
    </div>
  )
}
