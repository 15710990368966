import api from './api'
import {
  CourseBaseInfo,
  CourseBaseInfoEngagement,
  Lesson,
  LessonEngagement,
} from '@learnn/sdk/src/api/course'
import { Observable } from 'rxjs'

export type MyLearnnContent = {
  lessons: (Lesson & Partial<LessonEngagement>)[][]
  courses: (CourseBaseInfo & Partial<CourseBaseInfoEngagement>)[]
  webinars: (CourseBaseInfo & Partial<CourseBaseInfoEngagement>)[]
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}
export const getMyLearnn = () => async (): Promise<Observable<MyLearnnContent>> => {
  const { engagement, course, player } = await api
  try {
    return new Observable(subscriber => {
      new Promise(async () => {
        const [savedCourses, savedLessons] = await Promise.all([
          engagement.getSavedCourses(),
          engagement.getSavedLessons(),
        ])

        const [coursesData, lessonsData] = await Promise.all([
          (
            await Promise.all(
              savedCourses.map(async savedCourse => {
                try {
                  return await course.getCourseBaseInfo(savedCourse.courseId)
                } catch (_e) {
                  return null
                }
              }),
            )
          ).filter(notEmpty),
          (
            await Promise.all(
              savedLessons.map(async savedLesson => {
                try {
                  return await player.getLessonBase(savedLesson.lessonId)
                } catch (_e) {
                  return null
                }
              }),
            )
          ).filter(notEmpty),
        ])

        const lessonsToGroup = lessonsData.slice()
        let groupedLessons: Lesson[][] = []
        while (lessonsToGroup.length) {
          groupedLessons.push(lessonsToGroup.splice(0, 3))
        }

        subscriber.next({
          lessons: groupedLessons,
          webinars: coursesData.filter(c => c.type.slug === 'webinar'),
          courses: coursesData.filter(c => c.type.slug === 'course'),
        })

        const courseIds = coursesData.map(c => c.id)
        const lessonIds = lessonsData.map(l => l.id)

        const [coursesEngagement, lessonsEngagement] = await Promise.all([
          await engagement.getCourses(courseIds),
          await engagement.getLessons(lessonIds),
        ])

        const { lessonsEngagement: lessonsHash } = lessonsEngagement
        const { coursesEngagement: coursesHash } = coursesEngagement

        const webinars = coursesData
          .filter(c => c.type.slug === 'webinar')
          .map(c => applyEngagement(c, coursesHash))
        const courses = coursesData
          .filter(c => c.type.slug === 'course')
          .map(c => applyEngagement(c, coursesHash))

        const lessons: LessonEngagement[] = lessonsData.map(l => ({
          ...l,
          progress: lessonsHash && lessonsHash[l.id] ? lessonsHash[l.id].progress : 0,
          likeState: lessonsHash && lessonsHash[l.id] ? lessonsHash[l.id].likeState : 'none',
          myLearnnId: lessonsHash && lessonsHash[l.id] ? lessonsHash[l.id].myLearnnId : undefined,
        }))

        const groupedLessonsE: LessonEngagement[][] = []
        while (lessons.length) {
          groupedLessonsE.push(lessons.splice(0, 3))
        }

        subscriber.next({ lessons: groupedLessonsE, courses, webinars })
      }).catch(e => subscriber.error(e))
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const saveCourse = async (itemId: string) => {
  const { engagement } = await api
  try {
    const elementId = await engagement.saveMyLearnn('course', itemId)

    return elementId.myLearnnId
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const saveLesson = async (itemId: string) => {
  const { engagement } = await api
  try {
    const elementId = await engagement.saveMyLearnn('lesson', itemId)

    return elementId.myLearnnId
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const unsaveItem = async (itemType: string, itemId: string) => {
  const { engagement } = await api
  try {
    await engagement.removeMyLearnn(itemType, itemId)
  } catch (error) {
    console.log(error)
    throw error
  }
}

const applyEngagement = (c: CourseBaseInfo, engagement: object[]): CourseBaseInfoEngagement => ({
  ...c,
  //@ts-ignore //TODO
  progress: engagement && engagement[c.id] ? engagement[c.id].progress : 0,
  //@ts-ignore //TODO
  myLearnnId: engagement && engagement[c.id] ? engagement[c.id].myLearnnId : undefined,
})
