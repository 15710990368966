/* eslint-disable jsx-a11y/media-has-caption */
import { useContext, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { VideoControls, VideoControlsCourseInfo } from './controls/VideoControls'
import { IVideoProps, useReactPlayer, OnProgress } from './useReactPlayer'
import style from './lessonPlayer.module.css'
import './mediaQueries.css'
import { syncProgress } from '../../controllers/Player'
import { useBodyClass } from '../../utils/hooks'
import useAnalytics from '../../analytics/useAnalytics'
import { MobileVideoControls } from './controls/MobileVideoControls'
import env from '../../env.json'
import { PlayerSideContent } from './PlayerSideContent/PlayerSideContent'
import { ControlsContextProvider } from './controls/controlsContext'

import { Vtt } from '@learnn/sdk/src/api/utils'
import { CourseSummary } from '@learnn/sdk/src/api/player'
import { DiscussionPopup } from './Discussion/DiscussionPopup'
import { isDesktop } from 'react-device-detect'
import { GlobalProviderContext } from '../GlobalProvider'
import { extractConfiguration } from 'src/utils/data'

interface ILessonPlayerProps {
  courseSummary: CourseSummary
  initialProps: IVideoProps
  courseInfo: VideoControlsCourseInfo
  signedCookies: object
  cookieExpires: number
  showCompletedWidget: boolean
  subtitles: Vtt[]
}

const PROGRESS_THRESHOLD = 10
const COMPLETED_POPUP_THRESHOLD = 95
export const LessonPlayer: React.FC<ILessonPlayerProps> = ({
  courseSummary,
  initialProps,
  courseInfo,
  signedCookies,
  cookieExpires,
  showCompletedWidget,
  subtitles,
}) => {
  useBodyClass('videoPlayer')
  const {
    logPlay,
    logStop,
    logLessonProgress,
    logNewCourseStarted,
    logNewModuleStarted,
    logLessonStarted,
    logLessonOpened,
    logCourseCompleted,
    logVerifiedUnlocked,
    logModuleCompleted,
    logLessonCompleted,
    logVideoForward15,
    logVideoBack15,
    logVideoSeek,
    logShowVideoDescription,
    logVideoFullscreen,
    logVideoChangeSpeed,
    logTranscriptBlockClick,
    logSidebarToggle,
    logVideoToggleSubtitles,
    logVideoShare,
    logCommunityOpenLesson
  } = useAnalytics()
  const { reactPlayerProps, controls, state, ref } = useReactPlayer(initialProps)
  const [currentProgress, setCurrentProgress] = useState<number>(state.playedSeconds || 0)

  const courseId = courseInfo.courseId
  const moduleId = courseInfo.moduleId
  const lessonId = courseInfo.lessonId
  
  const lessonDuration = courseInfo.lessonDuration
  
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)  
  const quizAvailability = configuration?.quizAvailability ?? {}
  
  for (const cookieName in signedCookies) {
    const expires = new Date(cookieExpires * 1000)
    document.cookie =
      cookieName +
      '=' +
      //@ts-ignore //TODO: maybe remove this
      escape(signedCookies[cookieName]) +
      ';expires=' +
      expires +
      ';domain=.learnn.com;path=/;'
  }

  const currentViewTime = useRef(state.playedSeconds || 0)
  const savedViewTime = useRef(state.playedSeconds || 0)
  const summaryRef = useRef<CourseSummary & { opened?: boolean }>()

  if (!summaryRef.current && courseSummary) {
    summaryRef.current = courseSummary
  }

  const onProgress = (newState: OnProgress) => {
    if (!summaryRef.current) {
      return
    }

    currentViewTime.current = newState.playedSeconds

    if (newState.playedSeconds > savedViewTime.current + PROGRESS_THRESHOLD) {
      syncProgress({
        courseId: courseId,
        lessonId: lessonId,
        currentViewTime: newState.playedSeconds,
      })

      savedViewTime.current = newState.playedSeconds
      const progress = (currentViewTime.current / lessonDuration) * 100
      logProgressEvents(summaryRef.current, progress)
      if (progress >= env.LESSON_COMPLETED_THRESHOLD) {
        logCompletePlayingEvents(summaryRef.current)
      }
    }
    if (reactPlayerProps.onProgress) {
      reactPlayerProps.onProgress(newState)
    }
  }

  const onPlay = async () => {
    if (summaryRef.current) {
      logPlay(
        summaryRef.current.course_id,
        summaryRef.current.module_id,
        summaryRef.current.lesson_id,
        summaryRef.current,
      )

      if (currentProgress === 0) {
        logStartPlayingEvents(summaryRef.current)
      }
    }

    syncProgress({
      courseId: courseId,
      lessonId: lessonId,
      currentViewTime: currentViewTime.current,
    })

    savedViewTime.current = currentViewTime.current

    if (reactPlayerProps.onPlay) {
      reactPlayerProps.onPlay
    }
  }

  const onPause = async () => {
    if (summaryRef.current) {
      logStop(
        summaryRef.current.course_id,
        summaryRef.current.module_id,
        summaryRef.current.lesson_id,
        summaryRef.current,
      )
    }

    syncProgress({
      courseId: courseId,
      lessonId: lessonId,
      currentViewTime: currentViewTime.current,
    })

    savedViewTime.current = currentViewTime.current

    if (reactPlayerProps.onPause) {
      reactPlayerProps.onPause
    }
  }

  const onNextLesson = async () => {
    if (!summaryRef.current) {
      return
    }
    logCompletePlayingEvents(summaryRef.current)
  }

  const onChangeSpeed = async (rate: number) => {
    if (summaryRef.current) {
      logVideoChangeSpeed(
        summaryRef.current.course_id,
        summaryRef.current.module_id,
        summaryRef.current.lesson_id,
        rate,
        summaryRef.current,
      )
    }
  }

  const onShowVideoDescription = async () => {
    if (summaryRef.current) {
      logShowVideoDescription(
        summaryRef.current.course_id,
        summaryRef.current.module_id,
        summaryRef.current.lesson_id,
        summaryRef.current,
      )
    }
  }

  const onFullscreen = async () => {
    if (summaryRef.current) {
      if (!summaryRef.current.fullscreen) {
        logVideoFullscreen(
          summaryRef.current.course_id,
          summaryRef.current.module_id,
          summaryRef.current.lesson_id,
          summaryRef.current,
        )
        summaryRef.current.fullscreen = true
      }
    }
  }

  const onForward = async () => {
    if (summaryRef.current) {
      logVideoForward15(
        summaryRef.current.course_id,
        summaryRef.current.module_id,
        summaryRef.current.lesson_id,
        summaryRef.current,
      )
    }
  }

  const onToggleSubtitles = async (enabled: boolean) => {
    if (summaryRef.current) {
      logVideoToggleSubtitles(
        summaryRef.current.course_id,
        summaryRef.current.module_id,
        summaryRef.current.lesson_id,
        enabled,
        summaryRef.current,
      )
    }
  }

  const onBack = async () => {
    if (summaryRef.current) {
      logVideoBack15(
        summaryRef.current.course_id,
        summaryRef.current.module_id,
        summaryRef.current.lesson_id,
        summaryRef.current,
      )
    }
  }

  const onSeek = async (by: number) => {
    if (summaryRef.current) {
      logVideoSeek(
        summaryRef.current.course_id,
        summaryRef.current.module_id,
        summaryRef.current.lesson_id,
        by,
        summaryRef.current,
      )
    }
  }

  const onShareClick = async () => {
    if (summaryRef.current) {
      logVideoShare(
        summaryRef.current.course_id,
        summaryRef.current.module_id,
        summaryRef.current.lesson_id,
        summaryRef.current,
      )
    }
  }

  const onError = async (e: any) => {
    throw e
  }

  const setProgress = async () => {
    if (courseSummary.lesson_started) {
      if (courseSummary.lesson_progress >= COMPLETED_POPUP_THRESHOLD && showCompletedWidget) {
        setCurrentProgress(0)
        return
      }
      setCurrentProgress(courseInfo.currentViewTime)
    }
  }

  useEffect(() => {
    syncProgress({
      courseId: courseId,
      lessonId: lessonId,
      currentViewTime: currentViewTime.current,
    })
    setProgress()

    logLessonOpenEvents(summaryRef.current)

    return () => {
      syncProgress({
        courseId: courseId,
        lessonId: lessonId,
        currentViewTime: currentViewTime.current,
      })
    }
  }, [])

  useEffect(() => {
    if (currentProgress && state.playerReady) {
      controls.seekTo(currentProgress)
    }
  }, [currentProgress, state.playerReady])

  const logProgressEvents = (summary: CourseSummary, progress: number) => {
    const progressesToLog = [25, 50, 75]
    if (summary) {
      progressesToLog.forEach(p => {
        if (summary.lesson_progress < p && progress >= p) {
          logLessonProgress(summary.course_id, summary.module_id, summary.lesson_id, p, summary)
        }
      })
      summary.lesson_progress = Math.round(progress)
    }
  }

  const logStartPlayingEvents = (summary: CourseSummary) => {
    if (summary) {
      if (!summary.course_started) {
        logNewCourseStarted(summary.course_id, summary)
        summary.course_started = true
      }
      if (!summary.module_started) {
        logNewModuleStarted(summary.course_id, summary.module_id, summary)
        summary.module_started = true
      }
      if (!summary.lesson_started) {
        logLessonStarted(summary.course_id, summary.module_id, summary.lesson_id, summary)
        summary.lesson_started = true
      }
    }
  }

  const logCompletePlayingEvents = (summary: CourseSummary) => {
    if (summary) {
      if (
        !summary.course_completed &&
        sumProgress(summary.course_progress, summary.total_course_lessons) >=
          env.COURSE_COMPLETED_THRESHOLD
      ) {
        logCourseCompleted(summary.course_id, {
          ...summary,
          course_progress: sumProgress(summary.course_progress, summary.total_course_lessons),
        })
        summary.course_completed = true

        if (!summary.verified_unlocked && !!quizAvailability[courseId]){
          logVerifiedUnlocked(summary.course_id, {
            ...summary,
            course_progress: sumProgress(summary.course_progress, summary.total_course_lessons),
          })
          summary.verified_unlocked = true
        }
      }
      if (
        !summary.module_completed &&
        sumProgress(summary.module_progress, summary.total_lessons) >=
          env.MODULE_COMPLETED_THRESHOLD
      ) {
        logModuleCompleted(summary.course_id, summary.module_id, {
          ...summary,
          module_progress: sumProgress(summary.module_progress, summary.total_lessons),
        })
        summary.module_completed = true
      }
      if (!summary.lesson_completed) {
        logLessonCompleted(summary.course_id, summary.module_id, summary.lesson_id, summary)
        summary.lesson_completed = true
      }
    }
  }

  const logLessonOpenEvents = (summary: (CourseSummary & { opened?: boolean }) | undefined) => {
    if (summary) {
      if (!summary.opened) {
        logLessonOpened(summary.course_id, summary.module_id, summary.lesson_id, summary)
        summary.opened = true
      }
      if (!summary.course_completed && summary.course_progress >= env.COURSE_COMPLETED_THRESHOLD) {
        logCourseCompleted(summary.course_id, summary)
        summary.course_completed = true

        if (!summary.verified_unlocked && !!quizAvailability[courseId]){
          logVerifiedUnlocked(summary.course_id, {
            ...summary,
            course_progress: sumProgress(summary.course_progress, summary.total_course_lessons),
          })
          summary.verified_unlocked = true
        }
      }
      if (!summary.module_completed && summary.module_progress >= env.MODULE_COMPLETED_THRESHOLD) {
        logModuleCompleted(summary.course_id, summary.module_id, summary)
        summary.module_completed = true
      }
    }
  }

  const sumProgress = (currentProgress: number, totalItems: number) => {
    const completedItems = (currentProgress * totalItems) / 100
    const newProgress = ((completedItems + 1) * 100) / totalItems

    return newProgress
  }

  return (
    <>
      <div className={style.container}>
        <div className={style.videoContainer}>
          <ReactPlayer
            ref={ref}
            className={style.player}
            playsinline
            id='videoPlayer'
            {...reactPlayerProps}
            onPlay={onPlay}
            onPause={onPause}
            onProgress={onProgress}
            onError={onError}
            config={{
              youtube: { playerVars: { modestbranding: 1, rel: 0 } },
              file: {
                hlsOptions: {
                  xhrSetup: function (xhr: XMLHttpRequest) {
                    xhr.withCredentials = true // send cookies
                  },
                  startLevel: -1,
                },
              },
            }}
          />

          {state.playerReady ? (
            <ControlsContextProvider>
              <div className={style.overlayControls}>
                <VideoControls
                  subtitles={subtitles}
                  lessonCompleted={
                    showCompletedWidget &&
                    courseSummary.lesson_progress >= COMPLETED_POPUP_THRESHOLD
                  }
                  state={state}
                  controls={controls}
                  courseInfo={courseInfo}
                  analytics={{
                    onNextLesson,
                    onShowVideoDescription,
                    onChangeSpeed,
                    onBack,
                    onForward,
                    onFullscreen,
                    onSeek,
                    logSidebarToggle,
                    onToggleSubtitles,
                    onShareClick,
                    logCommunityOpenLesson,
                  }}
                />
              </div>
              <div className={style.mobileControls}>
                <MobileVideoControls
                  subtitles={subtitles}
                  lessonCompleted={
                    showCompletedWidget &&
                    courseSummary.lesson_progress >= COMPLETED_POPUP_THRESHOLD
                  }
                  state={state}
                  controls={controls}
                  courseInfo={courseInfo}
                  analytics={{
                    onNextLesson,
                    onShowVideoDescription,
                    onChangeSpeed,
                    onBack,
                    onForward,
                    onFullscreen,
                    onSeek,
                    onShareClick,
                    onToggleSubtitles,
                  }}
                />
              </div>
              <DiscussionPopup circlePostUrl={courseInfo.circlePostUrl} />
            </ControlsContextProvider>
          ) : null}
        </div>
        {(state.playerReady && isDesktop) ? (
          <div className={style.sideContainer}>
            <PlayerSideContent
              title={courseInfo.lessonName}
              lessonTags={courseInfo.lessonTags}
              state={state}
              currentLessonId={courseInfo.lessonId}
              moduleId={moduleId}
              courseId={courseId}
              lessonId={lessonId}
              viewTime={state.playedSeconds || 0}
              lessonDescription={courseInfo.description}
              courseName={courseInfo.courseName}
              subtitles={courseInfo.subtitles}
              transcript={courseInfo.transcript}
              onBlockClick={seconds => {
                controls.seekTo(seconds)
                logTranscriptBlockClick(courseId, moduleId, lessonId, seconds)
              }}
              {...(initialProps.noteOpened && { initialSelectedTab: 1 })}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}
