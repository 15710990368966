import { Button, Text, TextInput, Title, useTheme } from '@learnn/designn'
import style from './confirmPopup.module.css'
import cx from 'classnames'
import { useState } from 'react'

interface IConfirmPopup {
  text: string
  onConfirmClick: () => unknown
  onCancelClick: () => unknown
  textConfirmation?: string
}

export const ConfirmPopup = ({
  text,
  onConfirmClick,
  onCancelClick,
  textConfirmation,
}: IConfirmPopup) => {
  const [matchText, setMatchText] = useState('')
  const [error, setError] = useState('')
  const { spacing } = useTheme()

  const handleTextConfirmation = () => {
    setError('')
    if (matchText === textConfirmation) {
      onConfirmClick()
    } else {
      setError('Il testo inserito non è corretto.')
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMatchText(e.target.value)
  }

  return (
    <div className={style.popupContainer}>
      <div className={style.content}>
        <div
          className={cx([style.box])}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          {textConfirmation ? (
            <div>
              <Title variant='headingMd' mb={spacing.space_6}>
                Elimina account
              </Title>
              <Text color='secondary'>{text}</Text>
              <Text
                fontWeight='semibold'
                mt={spacing.space_4}>{`La tua email: ${textConfirmation}`}</Text>

              <TextInput
                size='lg'
                mt={spacing.space_4}
                mb={spacing.space_6}
                value={matchText}
                placeholder={textConfirmation}
                error={error}
                onChange={onInputChange}
              />

              <div className={style.buttonsContainer}>
                <Button variant='secondary' label='Annulla' onPress={onCancelClick} />
                <Button
                  disabled={!matchText}
                  variant='primary'
                  label='Elimina account'
                  onPress={handleTextConfirmation}
                />
              </div>
            </div>
          ) : (
            <>
              <h3 className={style.text}>{text}</h3>
              <div className={style.buttonsContainer}>
                <Button variant='secondary' size='md' label='Conferma' onPress={onConfirmClick} />
                <Button variant='primary' size='md' label='Cancella' onPress={onCancelClick} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
