import style from "./quizAttemptResults.module.css";
import wrongIcon from "../../assets/images/icons/wrong.svg";
import correctIcon from "../../assets/images/icons/correct.svg";
import correctIconDark from "../../assets/images/icons/correct_dark.svg";
import correctIconLight from "../../assets/images/icons/passed_quiz.svg";
import {AttemptDetailQuestion} from "@learnn/sdk/dist/api/quiz";

export type AnswersResultsProps = {
  question: AttemptDetailQuestion
  theme: 'light' | 'dark'
}
export const AnswersResults = ({question, theme}: AnswersResultsProps) => {
  return (
    <>
      {question.answers?.filter(question => question.userAnswer).length === 0 ? (
        <div
          className={style[`${theme}_answer`]}
          key={`answer-${question.question.questionId}-not-submitted`}>
          <img src={wrongIcon} className={style.answerImage}/>
          <span className={style[`${theme}_wrongAnswer`]}>Risposta non data</span>
        </div>
      ) : null}
      {question.answers && question.answers.length
        ? question.answers.map(a =>
          a.userAnswer || a.correctAnswer ? (
            <div
              className={style[`${theme}_answer`]}
              key={`answer-${question.question.questionId}-${a.answerId}`}
              onClick={() => {
              }}>
              {a.correctAnswer && a.userAnswer ? (
                <img src={correctIcon} className={style.answerImage}/>
              ) : a.correctAnswer && !a.userAnswer ? (
                <img
                  src={theme === 'light' ? correctIconDark : correctIconLight}
                  className={style.answerImage}
                />
              ) : a.correctAnswer === false && a.userAnswer ? (
                <img src={wrongIcon} className={style.answerImage}/>
              ) : null}
              <span
                className={
                  a.correctAnswer === false ? style[`${theme}_wrongAnswer`] : ''
                }>
                              {a.text}
                            </span>
            </div>
          ) : null,
        )
        : null}
    </>
  )
}