import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { getDailyQuizList } from '../../controllers/Quiz'
import style from './dailyListScreen.module.css'
import { useBodyClass } from '../../utils/hooks'
import Loader from 'react-loader-spinner'
import { QuizCard } from '../../components/QuizCard'
import { useContext } from 'react'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export type QuizStatus = 'not_started' | 'started' | 'time_up' | 'completed'

export const DailyListScreen = () => {
  useBodyClass('app-quiz-dark')
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId
  const history = useHistory()

  const { isLoading, error, data, refetch } = useQuery('dailyQuizList', () =>
    getDailyQuizList(userId),
  )

  return (
    <AppLayout>
      <div className={style.mainContainer}>
        <div className={style.sidebar}>
          <div className={style.sidebarContent}>
            <h1 className={style.screenTitle}>🏆 SFIDE</h1>
            <div className={style.sidebarWidget}>
              <div className={style.sidebarWidgetTitle}>COME FUNZIONA</div>
              <p>
                Con le sfide settimanali puoi applicare le tue conoscenze in situazioni reali,
                scoprire nuovi ambiti e capire come sei posizionato rispetto agli altri.
              </p>
              <br />
              <p>Inizia una delle sfide, prendi punti (coming soon) e sfida i tuoi colleghi.</p>
            </div>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.rightContent}>
            {isLoading ? (
              <div className={style.rightWidget}>
                <Loader type='ThreeDots' color='#ffffff' height={60} width={60} />
              </div>
            ) : error ? (
              <div className={style.rightWidget}>
                <span className={style.infoText}>
                  Si è verificato un errore durante il caricamento delle sfide.
                </span>
                <button className={style.callToAction} onClick={() => refetch()}>
                  RIPROVA
                </button>
              </div>
            ) : data && data.length ? (
              <>
                {data.map((quiz, index) => (
                  <QuizCard
                    format="large"
                    current={index === 0}
                    key={`quiz-${quiz.dailyQuizId}`}
                    quiz={quiz}
                    attempt={quiz.attempt}
                    onClick={() => history.push(`/sfide/${quiz.dailyQuizId}`)}
                  />
                ))}
                {/* <div className={style.loadMore}>
                  <button className={style.callToAction} onClick={() => refetch()}>
                    MOSTRA ALTRI
                  </button>
                </div> */}
              </>
            ) : (
              <div className={style.rightWidget}>
                <span className={style.infoText}>
                  Al momento non abbiamo sfide disponibili per te.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
