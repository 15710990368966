import style from "./responsiveImage.module.css";
import cx from "classnames";

export interface IResponsiveImage {
  src: string;
  wrapperClassName?: string;
  imageClassName?: string;
  onClick?(): void;
}

export const ResponsiveImage = ({ src, wrapperClassName, imageClassName, onClick }: IResponsiveImage) => {
  return onClick ? (
    <div className={cx([wrapperClassName, style.imageWrapper])} onClick={onClick}>
      <img className={cx([imageClassName, style.image])} src={src} />
    </div>
  ) : (
    <div className={cx([wrapperClassName, style.imageWrapper])}>
      <img className={cx([imageClassName, style.image])} src={src} />
    </div>
  );
};
