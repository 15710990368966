import { gql } from '@apollo/client';
import { apolloWatchQueryWrapper, extractThumbUrlFromImage, extractVideoUrlFromAwsMedia, getLessonType, } from '../utils';
export const singlecontent = (client) => {
    const getSingleContent = async (contentId) => {
        const result = await apolloWatchQueryWrapper(client.watchQuery({
            query: CONTENT_BY_KEY,
            variables: { contentId },
        }));
        const singleContent = result.data?.singleContent;
        if (!singleContent) {
            throw new Error(`content ${contentId} not found`);
        }
        return {
            ...{
                id: singleContent.id,
                title: singleContent.title,
                description: singleContent.description,
                imageUrl: extractThumbUrlFromImage(singleContent.cover, 'large'),
                videoUrl: extractVideoUrlFromAwsMedia(singleContent.awsMedia),
                contentType: getLessonType(singleContent),
            },
        };
    };
    return {
        get: getSingleContent,
    };
};
const CONTENT_BY_KEY = gql `
  query contentByKey($contentId: ID!) {
    singleContent(id: $contentId) {
      id
      title
      description
      awsMedia {
        url
        aws
      }
      cover {
        formats
      }
    }
  }
`;
