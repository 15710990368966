import { getCourseNotes } from '../../../../controllers/Course'
import { useBodyClass } from '../../../../utils/hooks'
import { useQuery, useQueryClient } from 'react-query'
import { matchQuery } from '../../../../utils/matchQuery'
import { Loader } from './Loader'
import { NotesList } from './NotesList'
import { Container } from '@learnn/designn'
import ErrorScreen from '../../../Error'
import { deleteNote } from '../../../../controllers/Note'

export type NotesSectionProps = {
  courseId: string
}

export const NotesSection = ({ courseId }: NotesSectionProps) => {
  useBodyClass('app')

  const notesQuery = useQuery(['notes', courseId], () => getCourseNotes(courseId))
  const queryClient = useQueryClient()

  const removeNote = async (noteId: string) => {
    try {
      await deleteNote(noteId)
      await queryClient.invalidateQueries(['notes', courseId])
    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <>
      {matchQuery(notesQuery, {
        loading: () => <Loader />,
        error: () => (
          <Container>
            <ErrorScreen />
          </Container>
        ),
        success: notes => (
          <NotesList
            notes={notes}
            onDeleteNotaClick={removeNote}
          />
        ),
      })}
    </>
  )
}
