import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import cx from 'classnames'
import styles from './notFoundScreen.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { LiveChat } from '../../components/LiveChat'

export const NotFoundScreen = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push('/')
  }
  return (
    <>
      <LiveChat />
      <Header type='light' />
      <Container>
        <Row className={styles.container}>
          <Col xs={12} md={6}>
            <h1 className={styles.title}>404 errori</h1>
            <h2 className={cx([styles.subtitle])}>(ancora troppo pochi)</h2>
            <div className={styles.description}>
              Nel 1999 Larry Page e Sergey Brin (fondatori di Google) andarono a parlare con George
              Bell (il CEO di Excite) dicendo che erano intenzionati a vendere Google per 1 milione
              di dollari.
            </div>
            <div className={styles.description}>
              Quando George Bell respinse l’offerta, Larry e Sergey la abbassarono a 750 mila
              dollari per riuscire a vendere. George rifiutò ancora.
            </div>
            <div className={cx([styles.description, styles.strong])}>
              Oggi Google vale più di 365 miliardi di dollari.
            </div>
            <div className={styles.description}>
              Questa pagina è un errore 404, significa che abbiamo fatto casino, ma non quanto
              George ;-)
            </div>
            <div className={styles.description}>
              Ti consigliamo di tornare alla home per trovare quello che cerchi.
            </div>
            <button className={styles.button} onClick={handleClick}>
              <FontAwesomeIcon size='xs' icon={faChevronLeft} className='mr-2' /> Torna alla home
            </button>
          </Col>
        </Row>
      </Container>
      <Footer type='light' />
    </>
  )
}
