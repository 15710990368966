import restClient from '../rest';
export const getCurrentSubscription = (MY_API_URL, getToken) => async () => {
    try {
        const response = await restClient(getToken()).jsonGet(MY_API_URL, `/identity/current-customer-subscription`);
        if (!response)
            throw new Error('Current customer subscription not found');
        return {
            ...response,
        };
    }
    catch (e) {
        console.warn(e);
        throw e;
    }
};
