import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import * as F from 'fp-ts/lib/function'
import style from './dynamicSections.module.css'
import {
  ComponentSectionDynamicProfile,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/dist/api/dynamic-sections/types'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Profile as ProfileItem } from '@learnn/sdk/dist/api/profile'
import userPlaceholder from '../../assets/images/icons/user_placeholder.svg'
import { Button } from '@learnn/designn'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'

export interface ILastCourseProps {
  data: DynamicSectionsValue<DynamicSectionsError, ComponentSectionDynamicProfile>
}

export const Profile = ({ data }: ILastCourseProps) => {
  const history = useHistory()
  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const render = (section: ComponentSectionDynamicProfile) => {
    const profile = section.data

    if (!profile) return <></>

    //@ts-ignore
    const { profileImage, name, lastName, profession, company } = profile

    const isProfileComplete = (profile: ProfileItem) => {
      return Object.values(profile).every(i => i !== null && i !== '')
    }

    return (
      <CustomCarousel
        title={section.title}
        singleSlide
        carouselKind='profile'
        renderSlide={() => {
          return (
            <div className={style.profileCard} onClick={() => history.push(`/modifica-profilo`)}>
              <img className={style.profileImage} src={profileImage ?? userPlaceholder} />

              <div className={style.profileInfo}>
                <div>
                  <div className={style.profileTitle}>{`${name ?? 'Nome'} ${lastName ?? 'Cognome'
                    }`}</div>
                  <div className={style.profileProfession}>
                    {(profession && profession) ?? 'Professione'}
                    {' @ '}
                    {(company && company) ?? 'Azienda'}
                  </div>
                  <Button
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    variant='primary'
                    size='md'
                    iconPosition='right'
                    label={isProfileComplete(profile) ? 'Modifica profilo' : 'Completa profilo'}
                    onPress={() => history.push(`/modifica-profilo`)}
                  />
                </div>
              </div>
            </div>
          )
        }}
      />
    );

  }

  return F.pipe(
    data,
    O.fold(CustomCarouselLoading, data => {
      return F.pipe(data, E.fold(error, render))
    }),
  )
}
