import { ItemParams, useContextMenu } from 'react-contexify'
export const MENU_ID = 'default'

const { show } = useContextMenu({
  id: MENU_ID,
})

interface ItemProps {
  url: string
}

// Defined just for documentation purpose
type ItemData = any

export function handleContextMenu(event: React.MouseEvent, props?: Record<string, any>) {
  show({
    event,
    props,
  })
}

export const handleItemClick = ({ id, event, props }: ItemParams<ItemProps, ItemData>) => {
  switch (id) {
    case 'newTab':
      props?.url && window.open(props?.url, '_blank', 'noreferrer')
      break
    case 'test':
      alert('test done')
      console.log(id, event)
      break
  }
}
