import api from './api'
import env from '../env.json'
import { QuizGenerator } from '@learnn/sdk/src/api/quiz/'
import { VerifiedState } from '@learnn/sdk/dist/api/quiz'

export const getQuiz = async (courseId: string) => {
  try {
    const { quiz } = await api

    const signedLessons = await quiz.getCourseLessons(courseId)

    const quizGenerator: QuizGenerator = {
      courseId,
      title: `Tentativo quiz corso`,
      type: 'COURSE',
      exp: signedLessons.exp,
      iat: signedLessons.iat,
      _signature_: signedLessons._signature_,
      lessons: signedLessons.lessons.map(lesson => ({ id: lesson.id })),
    }

    const quizData = await quiz.generateAttempt(quizGenerator)

    return quizData
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const generateDailyQuiz = async (quizId: string) => {
  try {
    const { quiz } = await api

    const quizData = await quiz.generateDailyAttempt(quizId)

    return quizData
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const generateInfiniteQuiz = async () => {
  try {
    const { quiz } = await api

    const quizData = await quiz.generateInfiniteQuiz()

    return quizData
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const extendInfiniteQuiz = async (attemptId: string) => {
  try {
    const { quiz } = await api

    const quizData = await quiz.extendInfiniteQuiz(attemptId)

    return quizData
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const saveInfiniteAnswer = async (
  attemptId: string,
  questionId: string,
  answerId: string,
) => {
  try {
    const { quiz } = await api

    const quizData = await quiz.saveInfiniteAnswer(attemptId, questionId, answerId)

    return quizData
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getResult = async (attemptId: string) => {
  try {
    const { quiz } = await api

    const result = await quiz.terminate(attemptId)

    return result
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getDailyQuizDetails = async (userId: string | undefined, dailyQuizId: string) => {
  if (!userId) return
  try {
    const { quiz } = await api

    const result = await quiz.getDaily(userId, dailyQuizId)

    return result
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getDailyQuizList = async (userId: string | undefined, nextToken?: string) => {
  if (!userId) return
  try {
    const { quiz } = await api

    const result = await quiz.getDailyList(userId, { maxReleaseDate: new Date() }, nextToken)

    return result
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getAttemptDetails = async (attemptId: string, userId: string | undefined) => {
  if (!userId) return
  try {
    const { quiz } = await api

    const result = await quiz.getAttemptDetails(attemptId, userId)

    return result
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCourseAttempts = async (courseId: string, userId: string) => {
  try {
    const { quiz } = await api

    const result = await quiz.getAttemptsByCourse(courseId, userId, env.QUIZ_RETRY_TIME_DAYS)

    return result
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const saveAnswser = async (attemptId: string, questionId: string, answerId: string) => {
  try {
    const { quiz } = await api

    await quiz.saveAnswer(attemptId, questionId, answerId)
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getQuizConfiguration = async () => {
  try {
    const { configuration } = await api

    const quizAvailability = await configuration.quizAvailability()

    return { quizAvailability }
  } catch (e) {
    console.error(e)
    return { quizAvailability: {} }
  }
}

export const getVerifiedStatus = async (
  userId: string,
): Promise<{
  passed: VerifiedState[]
  available: VerifiedState[]
  failed: VerifiedState[]
}> => {
  try {
    const { quiz } = await api

    const verifiedStatuses = await quiz.getVerifiedStatus(userId)

    console.log(verifiedStatuses)

    return {
      passed: verifiedStatuses.filter(quiz => quiz.status === 'passed'),
      available: verifiedStatuses.filter(quiz => quiz.status === 'available'),
      failed: verifiedStatuses.filter(quiz => quiz.status === 'failed'),
    }
  } catch (e) {
    console.error(e)
    return {
      passed: [],
      available: [],
      failed: [],
    }
  }
}

export const getCourseVerifiedStatus = async (
  userId: string,
  courseId: string,
): Promise<VerifiedState> => {
  try {
    const { quiz } = await api

    const verifiedStatus = await quiz.getCourseVerifiedStatus(userId, courseId)

    return verifiedStatus
  } catch (e) {
    throw new Error('Not found')
  }
}

export const getPathAttempt = async (userId: string, pathId: string) => {
  try {
    const { quiz } = await api

    const result = await quiz.getPathAttempt(userId, pathId)

    return result
  } catch (e) {
    console.error(e)
    return null
  }
}

export const generatePathAttempt = async (pathId: string) => {
  try {
    const { quiz } = await api

    const attemptData = await quiz.generatePathAttempt(pathId)

    return attemptData
  } catch (e) {
    console.error(e)
    throw e
  }
}
