import { FeaturesConfig } from "@learnn/sdk/src/api/feature-flag"
import { useContext } from "react"
import { FeatureFlagContext } from "./featureFlagContext"

type UseFeatureFlag = {
    features: FeaturesConfig
}

export const useFeatureFlag = (): UseFeatureFlag => {
    const { features } = useContext(FeatureFlagContext);

    return {
        features
    }
}