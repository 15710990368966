import { FC } from "react"
import { EstimateLineItem } from "../../types"
import style from './detailLine.module.css'
import { displayAmountCents } from "../../utils"
import { useQuery } from "react-query"
import { getPlanDetails } from "src/controllers/Payment"
import { HorizontalStack, VerticalStack } from "@learnn/designn"
import { matchQuery } from "src/utils/matchQuery"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { sanitizeHtml } from '../../../../utils/sanitizer'

export const PlanLine: FC<DetailLineProps> = ({ line }) => {
    const planCustomPriceQuery = useQuery(['plan-custom-price', line.entityId], () => getPlanDetails(line.entityId))

    return (
        <div className={style.lineContainer}>
            <VerticalStack width='100%'>
                <HorizontalStack width='100%'>
                    {
                        line.type === 'invoice' ? (
                            <>
                                <div className={style.lineText}>{line.name} {line.quantity > 1 ? `x${line.quantity}` : ''}</div>
                                <div className={style.linePrice}>{displayAmountCents(line.amount)}</div>
                            </>
                        ) : matchQuery(planCustomPriceQuery, {
                            loading: () => <></>,
                            error: () => <div className={style.lineText}>{line.name} {line.quantity > 1 ? `x${line.quantity}` : ''}</div>,
                            success: (data) => {
                                return (
                                    <>
                                        <div className={style.lineTextDimmed}>Storno {data.invoice_name}</div>
                                        <div className={style.linePriceDimmed}>- {displayAmountCents(line.amount)}</div>
                                    </>
                                )
                            }
                        })
                    }
                </HorizontalStack>

                {
                    (line.discounts && line.discounts.length > 0) ? line.discounts.map(line => (
                        <HorizontalStack width='100%'>
                            <div className={style.lineDiscountText}>{line.name}</div>
                            <div className={style.lineDiscountPrice}>- {displayAmountCents(line.amount)}</div>
                        </HorizontalStack>
                    )) :
                        matchQuery(planCustomPriceQuery, {
                            loading: () => (
                                <SkeletonTheme baseColor='#a3a3a3' highlightColor='#cacaca'>
                                    <Skeleton style={{ width: '100%', height: '30px' }}></Skeleton>
                                </SkeletonTheme>
                            ),
                            error: () => <></>,
                            success: (data) => {
                                if (!data || !data.customPrice)
                                    return <></>
                               
                                return (
                                    <HorizontalStack width='100%'>
                                        <div className={style.lineDiscountText} dangerouslySetInnerHTML={{
                                                __html: data.customPrice.customMessage ? sanitizeHtml(data.customPrice.customMessage) : ''
                                            }}
                                        ></div>
                                        <div className={style.lineDiscountPrice} style={{ textDecoration: 'line-through' }}>{data.customPrice.displayPrice} €</div>
                                    </HorizontalStack>
                                )
                            }
                        })
                }


            </VerticalStack>
        </div>
    )
}

export const AddonLine: FC<DetailLineProps> = ({ line, index }) => {
    return (
        <div className={style.lineContainer} style={{ borderTop: index === 0 && 'none', paddingTop: index === 0 && 0 }}>
            <VerticalStack width='100%'>
                <HorizontalStack width='100%'>
                    <div className={style.lineText}>{line.name} {line.quantity > 1 ? `x${line.quantity}` : ''}</div>
                    <div className={style.linePrice}>{displayAmountCents(line.amount)}</div>
                </HorizontalStack>
                {
                    line.discounts?.map( discount => (
                        <HorizontalStack width='100%'>
                            <div className={style.lineDiscountText}>{discount.name}</div>
                            <div className={style.lineDiscountPrice}>- {displayAmountCents(discount.amount)}</div>
                        </HorizontalStack>
                    ))
                }
            </VerticalStack>
        </div>
    )
}


export type DetailLineProps = {
    line: EstimateLineItem
    index: number
}
export const DetailLine: FC<DetailLineProps> = (props) => {

    switch (props.line.entityType) {
        case "plan": return (
            <PlanLine {...props} />
        )
        case "addon": return (
            <AddonLine {...props} />
        )
    }
}