import { FC, useContext, useState } from 'react';
import style from './invoiceDetails.module.css';
import cx from 'classnames';
import { membershipToken } from 'src/controllers/User';
import { chargebeeInstance } from '../../../../utils/chargebee';
import { TeamRole } from '@learnn/sdk/dist/api/auth';
import { GlobalProviderContext } from 'src/components/GlobalProvider';

const isB2BOwner = (role: TeamRole | undefined) => role === 'owner'
const isB2BUser = (teamId: string | undefined) => teamId !== undefined

type IInvoiceDetailsProps = {}

export const InvoiceDetails: FC<IInvoiceDetailsProps> = () => {
  const globalContext = useContext(GlobalProviderContext)

  const isB2B = isB2BUser(globalContext?.b2b.teamId)
  const b2bOwner = isB2BOwner(globalContext?.b2b.role)
  const restrictAccess = isB2B && !b2bOwner
  const [billingEnabled, setBilling] = useState(false)

  const handleClick = async () => {
    try {
      const session = await membershipToken();
      if (session?.status === 404) {
        return;
      }
      chargebeeInstance.setPortalSession(() => {
        return Promise.resolve(session?.body.portal_session);
      });
      const portal = chargebeeInstance.createChargebeePortal();
      portal.open({}, { sectionType: 'portal_edit_billing_address' });
    } catch (error) {
      console.error(error);
    }
  };

  const renderInvoiceSection = () => {
    return (
      <div className={style.methodContainer}>
        {restrictAccess ? (
          <div className={cx([style.changeMethodText])}>
            Il tuo abbonamento fa parte di un team, solo il proprietario del team può gestire la fatturazione.
          </div>
        ) : (
          <div className={cx([style.changeMethodText, style.hoverUnderline])} onClick={handleClick}>
            Visualizza o modifica i dati
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={style.methodContainer}>
      <div >
        <p className={cx([style.header, 'mb-1'])}>DATI DI FATTURAZIONE</p>
        <label
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <input
              name='billing'
              type='checkbox'
              checked={billingEnabled}
              onChange={() => {setBilling(!billingEnabled)}}
            />
            <span className='billing-text ml-2' style={{ flex: 1 }}>
              Desidero ricevere la fattura
            </span>
          </label>
      </div>
      
      {billingEnabled && renderInvoiceSection()}
    </div>
  );
};
