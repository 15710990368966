import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { VideoPlayer } from '../VideoPlayer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import style from './lessonPopup.module.css'
import { getLesson } from '../../controllers/Player'
import { useQuery } from 'react-query'
import { matchQuery } from '../../utils/matchQuery'
import Loader from 'react-loader-spinner'

// import cx from "classnames";

export type PopupProps = {
  lessonId: string
  show?: false
  startTime?: number
}

export type PopupControls = {
  show: (v: boolean) => void
}

export const LessonPopup = React.forwardRef<PopupControls, PopupProps>((props, ref) => {
  const [modalShow, setModalShow] = React.useState<boolean>(props.show ?? false)
  const lessonQuery = useQuery(['lesson', props.lessonId], () => getLesson(props.lessonId), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const ensureRef = () => {
    if (!ref || ref === null) {
      return
    }

    const controls: PopupControls = {
      show: (v: boolean) => {
        setModalShow(v)
      },
    }

    if (typeof ref === 'function') {
      ref(controls)
    }
  }

  return (
    <Modal
      onHide={() => setModalShow(false)}
      ref={(e: any) => {
        if (e) {
          ensureRef()
        }
      }}
      show={modalShow}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Body className={style.container}>
        {matchQuery(lessonQuery, {
          success: lesson => (
            <>
              <div className={style.header}>
                <span className={style.title}>{lesson.title}</span>
                <FontAwesomeIcon
                  size='lg'
                  icon={faTimes}
                  className={style.closeIcon}
                  onClick={() => setModalShow(false)}
                />
              </div>
              <VideoPlayer url={lesson.videoUrl} startTime={props.startTime ?? 0} />
            </>
          ),
          loading: () => (
            <>
              <div className={style.header}>
                <div />
                <FontAwesomeIcon
                  size='lg'
                  icon={faTimes}
                  className={style.closeIcon}
                  onClick={() => setModalShow(false)}
                />
              </div>
              <div className={style.body}>
                <Loader
                  type='ThreeDots'
                  color='#ffffff'
                  height={60}
                  width={60}
                  className={style.centered}
                />
              </div>
            </>
          ),
          error: () => (
            <>
              <div className={style.header}>
                <div />
                <FontAwesomeIcon
                  size='lg'
                  icon={faTimes}
                  className={style.closeIcon}
                  onClick={() => setModalShow(false)}
                />
              </div>
              <div className={style.body}>
                <span className={style.centered} style={{ color: '#ffffff' }}>
                  Si è verificato un errore durante il caricamento della lezione
                </span>
              </div>
            </>
          ),
        })}
      </Modal.Body>
    </Modal>
  )
})
