import style from './popup.module.css'
import env from '../../env.json'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import Confetti from 'react-confetti'
import { useWindowSize } from '../../utils/hooks'
import { matchQuery } from '../../utils/matchQuery'
import { useQuery } from 'react-query'
import { getCourseVerifiedStatus } from '../../controllers/Quiz'
import { isCourseCompletedByPercentage } from '@learnn/sdk/dist/api/utils'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getCompletedHideList } from '../../controllers/Course'
import auth from '../../utils/auth'
import { Button, useTheme } from '@learnn/designn'

export type GoalProps = {
  userId: string
  courseId: string
  courseProgressPercentage: number
}
export const CompletedPopup = ({ userId, courseId, courseProgressPercentage }: GoalProps) => {
  const [visible, setVisible] = useState(true)
  const size = useWindowSize()
  const history = useHistory()
  const { spacing } = useTheme()

  const verifiedStatusQuery = useQuery(['verifiedAvailability', courseId], () =>
    getCourseVerifiedStatus(userId, courseId),
  )

  const handleStopShowing = () => {
    let hideList = getCompletedHideList()

    if (hideList) {
      hideList.push(`${courseId}`)
    } else {
      hideList = [`${courseId}`]
    }
    auth.setCourseCompletedWidgetHide(JSON.stringify(hideList))
    setVisible(false)
  }

  const isCourseInHideList = () => {
    const hideList = getCompletedHideList()
    return hideList.includes(courseId)
  }

  return (
    <>
      {matchQuery(verifiedStatusQuery, {
        loading: () => <></>,
        error: () => <></>,
        success: data => {
          switch (data.status) {
            case 'available':
              return !isCourseInHideList() &&
                visible &&
                data.retryDate < new Date(Date.now()) &&
                isCourseCompletedByPercentage(courseProgressPercentage) ? (
                <div
                  className={style.popupContainer}
                  onClick={event => {
                    event.preventDefault()
                    setVisible(false)
                  }}>
                  <div className={style.content}>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={style.closeIcon}
                      onClick={() => setVisible(false)}
                    />
                    <div
                      className={cx([style.box])}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}>
                      <img src={env.COURSE_COMPLETED_IMAGE_URL} className={style.feedbackImage} />
                      <h1 className={style.title}>Hai sbloccato la verifica! 👏</h1>
                      <p className={style.description}>Puoi ora accedere alla verifica finale.</p>
                      <Button
                        icon={<FontAwesomeIcon icon={faChevronRight} />}
                        variant='primary'
                        size='lg'
                        iconPosition='right'
                        onPress={() => history.push(`/test/corso/${courseId}`)}
                        label='Verifica ora'
                        mt={spacing.space_6}
                        mb={spacing.space_8}
                      />
                      <span onClick={handleStopShowing} className={style.stopShowing}>
                        Non mostrare più
                      </span>
                    </div>
                  </div>
                  <Confetti
                    width={size.width ? size.width - 20 : 0}
                    height={size.height}
                    recycle={false}
                    numberOfPieces={1000}
                    tweenDuration={6000}
                  />
                </div>
              ) : (
                <></>
              )
            default:
              return <></>
          }
        },
      })}
    </>
  )
}
