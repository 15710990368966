import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import style from './overview.module.css'
import { Box, Container, Grid, HorizontalStack, useTheme } from '@learnn/designn'

export const Loader = () => {
  const { spacing } = useTheme()

  const SkeletonComponent = ({ br }: { br?: number }) => (
    <Skeleton height='100%' borderRadius={br ?? 15} />
  )

  return (
    <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
      <Container>
        <Box mb={spacing.space_6} style={{ width: '200px', height: '35px' }}>
          <SkeletonComponent />
        </Box>

        <Box className={style.imageContainer} style={{ height: '450px' }} mb={spacing.space_6}>
          <SkeletonComponent />
        </Box>

        <Grid
          gridTemplateColumns={{
            default: 'repeat(1, 1fr)',
            small: 'repeat(2, 1fr)',
          }}
          gridGap={spacing.space_4}
          mt={spacing.space_8}
          mb={spacing.space_20}
          justifyContent='space-between'>
          <div style={{ width: '250px', height: '50px' }}>
            <SkeletonComponent />
          </div>

          <HorizontalStack
            justifyContent={{
              default: 'center',
              small: 'flex-end',
            }}>
            <div style={{ width: '50px', height: '50px', marginRight: '10px' }}>
              <SkeletonComponent br={25} />
            </div>
            <div style={{ width: '50px', height: '50px', marginRight: '10px' }}>
              <SkeletonComponent br={25} />
            </div>
            <div style={{ width: '50px', height: '50px', marginRight: '10px' }}>
              <SkeletonComponent br={25} />
            </div>
          </HorizontalStack>
        </Grid>

        <Box mb={spacing.space_6} style={{ width: '150px', height: '25px' }}>
          <SkeletonComponent />
        </Box>
        <Grid
          gridTemplateColumns={{
            default: 'repeat(1, 1fr)',
            small: 'repeat(2, 1fr)',
            medium: 'repeat(4, 1fr)',
          }}
          gridGap={spacing.space_6}
          width='100%'
          mb={spacing.space_20}>
          {Array.from({ length: 4 }).map(() => (
            <div style={{ width: '100%', height: '100px' }}>
              <SkeletonComponent br={25} />
            </div>
          ))}
        </Grid>

        <Box mb={spacing.space_6} style={{ width: '150px', height: '25px' }}>
          <SkeletonComponent />
        </Box>
        <Box mb={spacing.space_20} style={{ width: '100%', height: '150px' }}>
          <SkeletonComponent />
        </Box>
      </Container>
    </SkeletonTheme>
  )
}
