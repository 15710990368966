import { gql } from '@apollo/client';
import { extractThumbUrlFromImage, getTextDate, normalizeGraphqlResponse, DEFAULT_WALL_LIMIT, getObjectDay, getTextTime, } from '../utils';
export const comingcontents = (client) => {
    const getComingContents = async () => {
        const result = await client
            .query({
            query: COMING_CONTENTS,
        })
            .then(normalizeGraphqlResponse);
        const comingContents = result.data?.comingContents.map((f) => {
            return {
                ...f,
                releaseDay: getTextDate(f.releaseDay),
                releaseDayObject: getObjectDay(f.releaseDay, 'short'),
                releaseDayString: f.releaseDay,
                releaseTime: getTextTime(f.releaseTime),
                releaseTimeString: f.releaseTime,
                ...{ coverImageUrl: extractThumbUrlFromImage(f.coverImage, 'large') },
            };
        });
        return comingContents;
    };
    return {
        get: getComingContents,
    };
};
const COMING_CONTENTS = gql `
  query ComingContents {
    comingContents(sort: "releaseDay:asc", filters: { hide: {eq: false} }, pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
      data {
        id
        attributes {
          title
          shortTitle
          contentType {
            data {
              attributes {
                title
                description
                slug
              }
            }
          }
          description
          releaseDay
          releaseTime
          coverImage {
            data {
             id
              attributes {
                formats
            		urlCdn
              }
            }
          }
          author {
            data {
              attributes {
                firstName
            		lastName
              }
            }
          }
          categories {
            data {
              attributes {
                title
              }
            }
          }
          contentUrl
        }
        }
      }
  }
`;
