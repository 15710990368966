import { FC, useContext, useEffect } from 'react'
import { useAiChatAssistant } from '../Assistant/useChatAssistant'
import { flowiseAiChatService } from './flowiseService'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { AssistantChat } from '../Assistant/AssistantChat'
import env from '../../env.json'

import useAnalytics from '../../analytics/useAnalytics'
import { ProfileWithInterests } from '@learnn/sdk/src/api/profile'

export type FlowiseChatPlayerProps = {
  courseName: string
  lessonName: string
  transcript: string
  profile?: ProfileWithInterests
}

export const FlowiseChatPlayer: FC<FlowiseChatPlayerProps> = ({
  courseName,
  lessonName,
  transcript,
  profile,
}) => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId
  const { logAiAssistantSendMessage, logAiAssistantMessageReceived } = useAnalytics()
  const { messages, isLoading, sendMessage } = useAiChatAssistant({
    assistantFactory: () =>
      flowiseAiChatService(
        {
          type: 'player_assistant',
          userId,
          courseName,
          lessonName,
          transcript,
          name: profile?.name,
          lastName: profile?.lastName,
          profession: profile?.profession,
          company: profile?.company,
          companyRole: profile?.companyRole,
          bio: profile?.bio,
          competences: profile?.competences,
          goal: profile?.goal,
          interests: profile?.interests,
        },
        env.FLOWISE_PLAYER_ASSISTANT_CHATFLOW_ID,
      ),
  })

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].role === 'assistant')
      logAiAssistantMessageReceived('player', messages[messages.length - 1].data)
  }, [messages])

  const handleSendMessage = async (message: string) => {
    await sendMessage(message)
    logAiAssistantSendMessage('player', message)
  }

  const defaultPrompts = [
    { label: 'Fai un riassunto', prompt: 'Fai un breve riassunto della lezione' },
    {
      label: 'Fammi un esempio',
      prompt: `Fammi un esempio pratico e sintetico che può essermi utile in base a quando detto nella lezione.`,
    },
    {
      label: 'Genera un quiz',
      prompt: 'Genera un quiz con una domanda a risposta multipla su questa lezione',
    },
    {
      label: 'Business case',
      prompt:
        'Genera un breve business case e fammi una sola domanda a cui devo rispondere in merito agli argomenti della lezione',
    },
  ]

  return (
    <AssistantChat
      messages={messages}
      loadingForResponse={isLoading}
      onSendMessage={handleSendMessage}
      defaultPrompts={defaultPrompts}
    />
  )
}
