import { FC } from 'react'
import { CustomCarousel } from '../Carousel/CustomCarousel'
import { SearchExpert } from '@learnn/sdk/src/api/smart-search'
import { ExpertCard } from '../ExpertCard/ExpertCard'

type ExpertsCarouselProps = {
  containerClass?: string
  title: string
  experts: SearchExpert[]
  onShowAll?: Function
  onExpertClick: (expert: SearchExpert) => unknown
}

export const ExpertsCarousel: FC<ExpertsCarouselProps> = ({
  title,
  experts,
  onShowAll,
  onExpertClick,
}) => {
  return (
    <CustomCarousel
      title={title}
      data={experts}
      onShowAll={onShowAll}
      carouselKind='expert'
      renderSlide={expert => {
        return (
          <ExpertCard
            firstName={expert.name}
            lastName={expert.lastName}
            company={expert.company}
            profession={expert.profession}
            coverImage={expert.profileImage.cdnUrl}
            onClick={() => onExpertClick(expert)}
          />
        )
      }}
    />
  );
}
