import style from './completedCourses.module.css'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

//Components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ErrorMessage } from '../../components/ErrorMessage'
import { Button } from '../../components/Button'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getCompletedCourses } from '../../controllers/Course'
import Loader from 'react-loader-spinner'
import { matchQuery } from '../../utils/matchQuery'
import { CardsGrid } from '../../components/CardsGrid/CardsGrid'
import { CourseCard } from '../../components/CourseCard'
import {CourseBaseInfoEngagement} from "@learnn/sdk/src/api/course";

export const CompletedCourses: React.FC = () => {
  const history = useHistory()

  const completedCoursesQuery = useQuery(['completedCourses'], () => getCompletedCourses())

  const handleCourseClick = (course: CourseBaseInfoEngagement) => {
    history.push(`/corso/${course.id}`)
  }

  return (
    <>
      {matchQuery(completedCoursesQuery, {
        success: data => {
          if (data.length === 0) {
            return (
              <Container>
                <Row className='pt-5'>
                  <Col xs={12} md={{ span: 8, offset: 2 }} className={style.containerEmpty}>
                    <h1 className={style.emptyTextTitle}>Nessun contenuto completato</h1>
                    <h4 className={style.emptyText}>
                      In questa sezione troverai tutti i corsi, i webinar e le lezioni che hai
                      completato.
                    </h4>
                    <Button
                      className={style.continueButton}
                      onClick={() => history.push(`/contenuti/`)}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p className={style.buttonText}>{'Vai ai contenuti'}</p>
                        <FontAwesomeIcon icon={faChevronRight} className={style.playIcon} />
                      </div>
                    </Button>
                  </Col>
                </Row>
              </Container>
            )
          }

          return (
            <div className={style.container}>
              <CardsGrid type='courses'>
                {data?.map(course => (
                  <CourseCard
                    course={course}
                    onClick={() => handleCourseClick(course)}
                    myLearnn
                    myLearnnId={course.myLearnnId}
                  />
                ))}
              </CardsGrid>
            </div>
          )
        },
        error: () => (
          <div className={style.errorContainer}>
            <ErrorMessage />
          </div>
        ),
        loading: () => (
          <div className={style.containerLoading}>
            <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
          </div>
        ),
        idle: () => (
          <div className={style.containerLoading}>
            <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
          </div>
        ),
      })}
    </>
  )
}
