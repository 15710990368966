import { FC, useContext, MouseEventHandler } from 'react'
import cx from 'classnames'
import style from './lessonCard.module.css'
import { ProgressBar } from '../ProgressBar'
import { MyLearnnButton } from '../../components/MyLearnnButton'
import { GlobalProviderContext } from '../GlobalProvider'
import { extractConfiguration } from '../../utils/data'
import env from '../../env.json'
import { Lesson, LessonEngagement } from '@learnn/sdk/dist/api/course'
import { SearchLesson } from '@learnn/sdk/src/api/smart-search'
import { HorizontalStack, Text, useTheme, ProgressBar as Progress } from '@learnn/designn'
import { formatSeconds } from '../LessonPlayer/utils'

type ILessonCard = {
  lesson: Lesson & Partial<LessonEngagement> & Partial<SearchLesson>
  onClick?(): void
  onContextMenu?: MouseEventHandler<HTMLHeadingElement>
  myLearnn?: boolean
  myLearnnId?: string | undefined
  myLearnnClass?: string
  titleClassName?: string
  courseNameStyle?: string
  imageStyle?: string
  contentStyle?: string
  durationStyle?: string
  wrapperStyle?: string
  wide?: boolean
}

export const LessonCard: FC<ILessonCard> = ({
  lesson,
  onClick,
  onContextMenu,
  myLearnn,
  myLearnnClass,
  imageStyle,
  contentStyle,
  wrapperStyle,
  durationStyle,
  titleClassName,
  wide = false,
}) => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const blocked = !configuration?.premiumVisible && !lesson.free
  const { spacing } = useTheme()

  const handleClick = () => {
    if (!blocked && onClick) {
      onClick()
    }
  }

  if (wide) {
    return (
      <div
        className={cx([style.cardContainerWide, blocked && env.PRO_LESSON_CLASS])}
        onClick={handleClick}
        onContextMenu={onContextMenu}>
        <div className={style.imageContainer} style={{ marginBottom: '0.75rem', width: '100%' }}>
          <div className={style.imageWrapper} style={{ borderRadius: '12px' }}>
            <img className={cx([style.imageWide, imageStyle])} src={lesson.coverImageUrl} />
            {blocked ? (
              <div className={style.proBadgeTop}>
                <span className={style.proText}>Pro</span>
              </div>
            ) : null}
            <div className={style.durationBadge}>
              <span>{formatSeconds(lesson.durationSeconds)}</span>
            </div>
            <div className={style.savedContainer}>
              <MyLearnnButton
                itemType='lesson'
                itemId={lesson.id}
                myLearnnId={lesson.myLearnnId}
                className={cx([style.myLearnnButton, myLearnnClass])}
              />
            </div>
            <Progress percentage={lesson.progress ?? 0} transition />
          </div>
        </div>
        <div className={cx([style.infoWide, contentStyle])}>
          <h4 className={cx([style.titleWide, titleClassName])}>{lesson.title}</h4>
          <div className={style.detailsContainer}>
            <div className={style.courseTitle}>{lesson.courseTitle ?? lesson.course?.title}</div>
            {lesson.tags?.length && lesson.tags.length > 0 ? (
              <HorizontalStack alignItems='center'>
                {lesson.tags.slice(0, 2).map(tag => (
                  <div key={`tag-${tag.id}`} className={style.tagBadge}>
                    {tag.name}
                  </div>
                ))}
              </HorizontalStack>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return myLearnn && configuration?.features?.mylearnn !== false ? (
      <div className={style.cardContainer}>
        <div
          className={cx([style.cardInner, blocked && env.PRO_LESSON_CLASS])}
          onClick={handleClick}
          onContextMenu={onContextMenu}>
          <div className={style.imageContainer}>
            <div className={style.imageWrapper}>
              <img className={cx([style.image, imageStyle])} src={lesson.coverImageUrl} />
            </div>
            {blocked ? (
              <div className={style.proBadge}>
                <span className={style.proText}>Pro</span>
              </div>
            ) : null}
          </div>

          <div className={cx([style.info, contentStyle])}>
            <div className={style.detailsContainer}>
              {lesson.course?.shortTitle || lesson.course?.title ? (
                <Text variant='bodyXs' mr={spacing.space_3} mb={spacing.space_1}>
                  <span style={{ color: '#808080' }}>
                    {lesson.course.shortTitle ?? lesson.course.title}
                  </span>
                </Text>
              ) : (
                <></>
              )}
              {lesson.tags?.length && lesson.tags.length > 0 ? (
                <HorizontalStack alignItems='center'>
                  {lesson.tags.slice(0, 2).map(tag => (
                    <div key={`tag-${tag.id}`} className={style.tagBadge}>
                      {tag.name}
                    </div>
                  ))}
                </HorizontalStack>
              ) : (
                <></>
              )}
            </div>
            <div>
              <h4 className={cx([style.title, titleClassName])}>{lesson.title}</h4>
              <Text variant='bodyXs' mr={spacing.space_2} mb={spacing.space_4} color='dimmed'>
                {lesson.duration}
              </Text>
              <ProgressBar width='8em' fillPercentage={lesson.progress ?? 0} />
            </div>
          </div>
        </div>
        <div className={style.myLearnnContainer}>
          <MyLearnnButton
            itemType='lesson'
            itemId={lesson.id}
            myLearnnId={lesson.myLearnnId}
            className={cx([style.myLearnnButton, myLearnnClass])}
          />
        </div>
      </div>
    ) : (
      <div
        className={cx([style.container, wrapperStyle, blocked && env.PRO_LESSON_CLASS])}
        onClick={handleClick}
        onContextMenu={onContextMenu}>
        <div className={style.imageContainer}>
          <div className={style.imageWrapper}>
            <img className={cx([style.image, imageStyle])} src={lesson.coverImageUrl} />
          </div>
          {blocked ? (
            <div className={style.proBadge}>
              <span className={style.proText}>Pro</span>
            </div>
          ) : null}
        </div>

        <div className={cx([style.info, contentStyle])}>
          <div>
            <h4 className={cx([style.title, titleClassName])}>{lesson.title}</h4>
          </div>
          <div className={style.details}>
            <p className={cx([style.duration, durationStyle])}>{lesson.duration}</p>
            <ProgressBar width='6em' fillPercentage={lesson.progress ?? 0} />
          </div>
        </div>
      </div>
    )
  }
}
