import { useContext, useEffect, useState } from 'react'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  generateDailyQuiz,
  getAttemptDetails,
  saveAnswser,
  getDailyQuizDetails,
  getResult,
} from '../../controllers/Quiz'
import { getCoursePreview } from '../../controllers/Course'
import Loader from 'react-loader-spinner'
import { useParams } from 'react-router-dom'
import style from './dailyQuizScreen.module.css'
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { QuestionList } from '../../components/QuestionList'
import { QuizProgress } from '../../components/QuizProgress'
import { QuizDifficulty } from '../../components/QuizDifficulty'
import { QuizAttemptResults } from '../../components/QuizAttemptResults'
import { QuizAttemptDetails, QuizItem } from '@learnn/sdk/src/api/quiz/'
import { useBodyClass } from '../../utils/hooks'
import useDailyQuizAnalytics from './hooks/useDailyQuizAnalytics'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { QuizTimeUp } from '../../components/QuizTimeUp'
import { CourseWidget } from '../../components/CourseWidget'
import { CoursePreview } from '@learnn/sdk/src/api/course/'
import correctIconLight from '../../assets/images/icons/passed_quiz.svg'
import playIcon from '../../assets/images/icons/play_circle.svg'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export type QuizStatus = 'idle' | 'not_started' | 'started' | 'time_up' | 'completed' | 'review'

export const DailyQuizScreen = () => {
  useBodyClass('app-quiz-dark')
  const history = useHistory()
  const queryClient = useQueryClient()
  const { quizId } = useParams<{ quizId: string }>()
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  const {
    isLoading: quizLoading,
    error: quizError,
    data: quiz,
    status: quizQueryStatus,
  } = useQuery(['dailyQuiz', userId, quizId], () => getDailyQuizDetails(userId, quizId), {
    onSuccess: data => {
      if (data?.attempt) {
        setStatus('review')
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const courseId = quiz?.featuredCourseId ?? ''

  const courseQuery = useQuery(['courseInfo', courseId], () => getCoursePreview(courseId), {
    enabled: !!courseId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: questionsLoading,
    error: questionsError,
    data: questions,
    refetch: getQuestions,
  } = useQuery<QuizItem, Error>(['dailyQuizAttempt', quizId], () => generateDailyQuiz(quizId), {
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const [status, setStatus] = useState<QuizStatus>('not_started')
  const [result, setResult] = useState<QuizAttemptDetails>()
  const [currentQuestionIndex, setCurrentQuestion] = useState(0)
  const [submitError, setError] = useState('')
  const [loadingDetails, setLoadingDetails] = useState(false)

  const startQuiz = async () => {
    await getQuestions()
    setStatus('started')
  }

  const handleSubmit = async (questionId: string, answerId: string) => {
    setError('')

    if (!questions) return

    try {
      await saveAnswser(questions.attemptId, questionId, answerId)

      const nextQuestion = currentQuestionIndex + 1

      if (nextQuestion < questions.questions.length) {
        setCurrentQuestion(nextQuestion)
      } else {
        setLoadingDetails(true)
        if (!userId) throw new Error('Invalid user id')
        await getResult(questions.attemptId)
        const attemptResult = await getAttemptDetails(questions.attemptId, userId)
        setResult(attemptResult)
        setLoadingDetails(false)
        setStatus('completed')
      }
    } catch (error) {
      console.log(error)
      setError("Si è verificato un durante l'invio della risposta, prova nuovamente.")
    }
  }

  useEffect(() => {
    return () => {
      if (status !== 'not_started' && status !== 'review') {
        queryClient.invalidateQueries('dailyQuizList')
      }
    }
  }, [])

  useEffect(() => {
    if (currentQuestionIndex === 0) {
      window.scrollTo(0, 0)
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 200)
    }
  }, [currentQuestionIndex])

  useDailyQuizAnalytics(questions, result, status)

  const renderSidebar = () => {
    switch (status) {
      case 'not_started':
        return quizQueryStatus === 'success' && !quiz?.attempt ? (
          <div className={style.sidebarWidget}>
            <div className={style.sidebarWidgetTitle}>COME FUNZIONA</div>
            <p>
              Con le sfide settimanali puoi applicare le tue conoscenze in situazioni reali,
              scoprire nuovi ambiti e capire come sei posizionato rispetto agli altri.
            </p>
            <br />
            <p>Inizia una delle sfide, prendi punti (coming soon) e sfida i tuoi colleghi.</p>
          </div>
        ) : quizQueryStatus === 'loading' ? (
          <div className={style.sidebarWidget}>
            <Loader
              type='ThreeDots'
              color='#ffffff'
              height={60}
              width={60}
              style={{ alignSelf: 'center' }}
            />
          </div>
        ) : quizQueryStatus === 'error' ? (
          <button className={style.backButton} onClick={() => history.push('/sfide')}>
            <FontAwesomeIcon icon={faChevronLeft} /> Indietro
          </button>
        ) : (
          <></>
        )
      case 'started':
        return (
          <>
            <div className={style.sidebarWidget}>
              <p>
                <strong>
                  Non uscire dalla pagina, altrimenti la sfida verrà considerata come non superata.
                </strong>
              </p>
            </div>
            {quiz && (
              <div className={style.sidebarWidget}>
                <div className={style.sidebarWidgetTitle}>DETTAGLI</div>
                <span className={style.listItem}> {`TEMPO MASSIMO: ${quiz.timeLimit} min`}</span>
                <span
                  className={
                    style.listItem
                  }>{`NUMERO DI DOMANDE: ${quiz.questionsId.length}`}</span>
                <span className={style.listItem}>{`PER SUPERARLA: ${quiz.passedPercentage}%`}</span>
                <span className={style.listItem}>{`DIFFICOLTÀ: ${quiz.difficulty}`}</span>
              </div>
            )}
          </>
        )
      case 'time_up':
        return (
          <button className={style.backButton} onClick={() => history.push('/sfide')}>
            <FontAwesomeIcon icon={faChevronLeft} /> Indietro
          </button>
        )
      case 'completed':
        return <FeaturedCourse query={courseQuery} />
      case 'review':
        return <FeaturedCourse query={courseQuery} />
      default:
        return <></>
    }
  }

  const renderTitle = () => {
    switch (status) {
      case 'not_started':
        return quizQueryStatus === 'success' && !quiz?.attempt ? (
          <h1 className={style.screenTitle}>Inizia la sfida</h1>
        ) : quizQueryStatus === 'loading' ? (
          <h1 className={style.screenTitle}> 🏆 Sfida</h1>
        ) : quizQueryStatus === 'error' ? (
          <h1 className={style.screenTitle}>Errore</h1>
        ) : (
          <h1 className={style.screenTitle}>🏆 Sfida</h1>
        )
      case 'started':
        return <h1 className={style.screenTitle}>Sfida in corso</h1>
      case 'time_up':
        return <h1 className={style.screenTitle}>Tempo scaduto</h1>
      case 'completed':
        return <h1 className={style.screenTitle}>Risultati</h1>
      case 'review':
        return <h1 className={style.screenTitle}>Risultati</h1>
      default:
        return <h1 className={style.screenTitle}>🏆 Sfida</h1>
    }
  }

  return (
    <AppLayout>
      <div className={style.titleContainer}>
        <div className={style.sidebar}>{renderTitle()}</div>
      </div>
      <div className={style.mainContainer}>
        <div className={style.sidebar}>
          <div className={style.sidebarContent}>{renderSidebar()}</div>
        </div>
        <div className={style.right}>
          <div>
            {quizLoading || questionsLoading || loadingDetails ? (
              <div className={style.containerLoading}>
                <Loader type='ThreeDots' color='#ffffff' height={60} width={60} />
              </div>
            ) : quizError || questionsError ? (
              <div className={style.rightContent}>
                <h1 className={style.quizTitle}>Si è verificato un errore</h1>
                <p className={style.quizDescription}>
                  Si è verificato un errore durante il caricamento della sfida.
                </p>
                <div>
                  <button className={style.beginButton} onClick={() => startQuiz()}>
                    RIPROVA
                  </button>
                </div>
              </div>
            ) : (
              <div className={style.quiz}>
                {quiz && status === 'not_started' ? (
                  <div className={style.rightContent}>
                    <p className={style.preTitle}>PRIMA DI INIZIARE</p>
                    <h1 className={style.quizTitle}>{quiz.title}</h1>
                    <div className={style.quizDescription}>
                      <ReactMarkdown>{quiz.description}</ReactMarkdown>
                    </div>
                    <div className={style.quizDuration}>
                      <img src={correctIconLight} className={style.listIcon} />
                      <span>Tempo massimo: </span>
                      {`${quiz.timeLimit} minuti`}
                    </div>
                    <div className={style.quizDuration}>
                      <img src={correctIconLight} className={style.listIcon} />
                      <span>Numero di domande: </span>
                      {` ${quiz.questionsId.length}`}
                    </div>
                    <div className={style.quizDuration}>
                      <img src={correctIconLight} className={style.listIcon} />
                      <span>Percentuale per superarla: </span>
                      {` ${quiz.passedPercentage}%`}
                    </div>
                    <div className={style.quizDuration}>
                      <img src={correctIconLight} className={style.listIcon} />
                      <span className={style.difficultyText}>Difficoltà:</span>
                      <QuizDifficulty difficulty={quiz.difficulty} />
                    </div>
                    <button className={style.beginButton} onClick={startQuiz}>
                      <img src={playIcon} className={style.playIcon} />
                      INIZIA LA SFIDA
                    </button>
                    <div className={style.buttonSubtitle}>
                      NB: appena inizierai la sfida il tempo inizierà a scorrere
                    </div>
                  </div>
                ) : questions && status === 'started' ? (
                  <div className={style.negativeMargin}>
                    <QuizProgress
                      inline
                      theme='dark'
                      maxTime={quiz?.timeLimit}
                      quiz={questions}
                      currentQuestion={currentQuestionIndex}
                      onTimeUp={() => setStatus('time_up')}
                    />
                    <QuestionList
                      theme='dark'
                      questionNumber={currentQuestionIndex + 1}
                      question={questions.questions[currentQuestionIndex]}
                      onAnswerSubmit={handleSubmit}
                      error={submitError}
                      containerClassName={style.questionList}
                      titleContainerClassName={style.fullwidth}
                      innerContainerClassName={style.fullwidth}
                    />
                  </div>
                ) : questions && status === 'time_up' ? (
                  <div className={style.rightContent}>
                    <QuizTimeUp
                      paragraphs={[
                        ' Hai esaurito il tempo a disposizione per completare la sfida.',
                        'Il tentativo verrà automaticamente considerato come non completato',
                      ]}
                    />
                  </div>
                ) : quiz && result && status === 'completed' ? (
                  <QuizAttemptResults
                    containerClassName={style.noMarginTop}
                    theme='dark'
                    attempt={result}
                    onBackClick={() => history.push('/sfide')}
                    share={{
                      title: 'Sfida un tuo amico a fare meglio',
                      url: `https://learnn.com/sfide/${quiz.dailyQuizId}`,
                      body: `Ho completato la sfida "${quiz.title}" su Learnn. Vediamo se la superi anche tu 😉`,
                    }}
                  />
                ) : quiz && quiz.attempt && status === 'review' ? (
                  <QuizAttemptResults
                    containerClassName={style.noMarginTop}
                    theme='dark'
                    onBackClick={() => history.push('/sfide')}
                    attempt={quiz.attempt}
                    share={{
                      title: 'Sfida un tuo amico a fare meglio',
                      url: `https://learnn.com/sfide/${quiz.dailyQuizId}`,
                      body: `Ho completato la sfida "${quiz.title}" su Learnn. Vediamo se la superi anche tu 😉`,
                    }}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

const FeaturedCourse = ({ query }: { query: UseQueryResult<CoursePreview, unknown> }) => {
  switch (query.status) {
    case 'success':
      return <CourseWidget course={query.data} />
    case 'error':
      return <></>
    case 'loading':
      return (
        <div className={style.sidebarWidget}>
          <Loader
            type='ThreeDots'
            color='#ffffff'
            height={60}
            width={60}
            style={{ alignSelf: 'center' }}
          />
        </div>
      )
    case 'idle':
      return <></>
    default:
      return <></>
  }
}
