import style from './share.module.css'
import cx from 'classnames'
import share from '../../assets/images/icons/share.svg'

export interface IShareProps {
  text?: string
  textClassName?: string
  containerClassName?: string
  iconClassName?: string
  icon?: string
  onClick?: () => void
}

export const Share = ({
  containerClassName,
  iconClassName,
  textClassName,
  onClick,
  icon = share,
  text,
}: IShareProps) => {
  return text ? (
    <div
      onClick={e => {
        e.stopPropagation()
        if (onClick) onClick()
        return
      }}
      className={cx([style.shareContainerText, containerClassName])}>
      <img src={icon} className={cx([style.shareIconText, iconClassName])} />
      {text ? <span className={cx([style.text, textClassName])}>{text}</span> : null}
    </div>
  ) : (
    <div
      onClick={e => {
        e.stopPropagation()
        if (onClick) onClick()
        return
      }}
      className={cx([style.shareContainer, containerClassName])}>
      <img src={icon} className={cx([style.shareIcon, iconClassName])} />
    </div>
  )
}
