import { useBodyClass } from '../../utils/hooks'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { HorizontalStack, Markdown, Text, Title, VerticalStack, useTheme } from '@learnn/designn'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { useAssistant } from './useAssistant'
import styles from './learnnAssistant.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

export const LearnnAssistantScreen = () => {
  useBodyClass('app')
  const [keyword, setKeyword] = useState<string>('')
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId as string
  const { spacing } = useTheme()
  const {
    sendMessage,
    messages,
    status
  } = useAssistant({ userId })
  const bottomEl = useRef<HTMLDivElement>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword) {
      sendMessage(keyword)
      setKeyword('')
    }
  }

  useEffect(() => {
    bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages])


  const DefaultQuestion: FC = ({ children }) => (
    <div className={styles.questionContainer} onClick={() => children ? sendMessage(children?.toString()): null}>
      <Text variant='bodyXs'>{children}</Text>
    </div>
  )

  const StartComponent = () => {

    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <VerticalStack width='100%' justifyContent='center' marginBottom={spacing.space_4}>
          <VerticalStack justifyContent='center' alignItems='center' flex='1'>
            <Title variant='headingLg' fontWeight='bold' mt={spacing.space_6}>Qual è il tuo obiettivo?</Title>
          </VerticalStack>
          <div className={styles.questionsContainerH}>
            <div className={styles.questionsContainerV}>
              <DefaultQuestion>Ho bisogno di vendere il mio prodotto online</DefaultQuestion>
              <DefaultQuestion>Come posso pubblicizzare il mio brand?</DefaultQuestion>
            </div>
            <div className={styles.questionsContainerV}>
              <DefaultQuestion>Devo creare un sito per la mia attività</DefaultQuestion>
              <DefaultQuestion>Mi puoi aiutare a creare un piano editoriale?</DefaultQuestion>
            </div>
          </div>
        </VerticalStack>
      </div>
    )
  }

  return (
    <AppLayout showSearchbar>
      <div className={styles.container}>
        <div className={styles.content}>
          <VerticalStack flex={1} mb={spacing.space_8}>
            {
              messages.length > 0 ? (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 1 auto',
                  overflowY: 'auto',
                  height: '100px',
                  marginBottom: spacing.space_5
                }}>
                  {
                    messages
                      .sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
                      .map(message => {
                        return (
                          <VerticalStack mb={spacing.space_4}>
                            <Text
                              fontWeight='black'
                              variant='bodySm'
                            >
                              {message.role === 'user' ? 'Tu' : 'Learnn Assistant'}
                            </Text>
                            <Markdown size='md'>{message.content}</Markdown>
                          </VerticalStack>
                        )
                      })
                  }
                  {
                    status === 'wait_for_assistant' && (
                      <div className={styles.loadingDots}>
                        <div className={styles.dot}></div>
                        <div className={styles.dot}></div>
                        <div className={styles.dot}></div>
                      </div>
                    )
                  }
                  <div ref={bottomEl} />
                </div>
              ) : (
                <StartComponent />
              )
            }
            <HorizontalStack>
              <div className={styles.chatInputContainer}>
                <input
                  type="text"
                  className={styles.chatInput}
                  placeholder="Scrivi il tuo obiettivo..."
                  value={keyword}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                />
                <FontAwesomeIcon icon={faArrowUp} style={{ color: '#808080' }} fontSize={15} />
              </div>
            </HorizontalStack>
          </VerticalStack>
        </div>
      </div>
    </AppLayout>
  )
}
