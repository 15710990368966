import style from './styles/description.module.css'
import { Link } from 'react-router-dom'
import useLinkNavigation from '../../../utils/useLinkNavigation'
import { useQuery } from 'react-query'
import { getCourseResources } from '../../../controllers/Course'
import { matchQuery } from '../../../utils/matchQuery'
import { Markdown, Title, useTheme } from '@learnn/designn'

type DescriptionTabProps = {
  courseId: string
  description?: string
}
export const DescriptionTab = ({ courseId, description }: DescriptionTabProps) => {
  const { spacing } = useTheme()
  const linkNavigation = useLinkNavigation()
  const resourcesQuery = useQuery(['courseResources', courseId], () => getCourseResources(courseId))

  useQuery

  return (
    <div className={style.container}>
      {description ? (
        <>
          <Title variant='headingSm' mb={spacing.space_6}>
            Risorse lezione
          </Title>
          <div className={style.description} style={{ marginBottom: '3rem' }}>
            <Markdown
              overrides={{
                reactMarkdownProps: {
                  renderers: {
                    link: props => {
                      return props.node.url.startsWith('https://') |
                        props.node.url.startsWith('http://') ? (
                        <a href={props.node.url} target='_blank'>
                          {props.children}
                        </a>
                      ) : (
                        <Link to={linkNavigation.parseUrl(props.node.url)}>
                          {props.children}
                        </Link>
                      )
                    },
                  }
                }
              }}
            >
              {description}
            </Markdown>
          </div>
        </>
      ) : (
        <></>
      )}
      {matchQuery(resourcesQuery, {
        loading: () => <></>,
        error: () => <></>,
        success: resources =>
          resources ? (
            <>
              <Title variant='headingSm' mb={spacing.space_6}>
                Risorse corso
              </Title>
              <div className={style.description}>
                <Markdown
                  overrides={{
                    reactMarkdownProps: {
                      renderers: {
                        link: props => {
                          return props.node.url.startsWith('https://') |
                            props.node.url.startsWith('http://') ? (
                            <a href={props.node.url} target='_blank'>
                              {props.children}
                            </a>
                          ) : (
                            <Link to={linkNavigation.parseUrl(props.node.url)}>
                              {props.children}
                            </Link>
                          )
                        },
                      }
                    }
                  }}
                >
                  {resources}
                </Markdown>
              </div>
            </>
          ) : (
            <></>
          ),
      })}
    </div>
  )
}
