import { useRef, useState, useEffect, forwardRef } from 'react'
import { Formik, FormikProps } from 'formik'
import { validationSchema, billingValidationSchema } from './validation'
import { AddressAutocomplete } from '../AddressAutocomplete'

import {
  paySubscription,
  getPaymentIntent,
  getCountryCodeFromIp,
  checkPaymentStatus,
  Addon,
  PaymentIntent,
  ValidatedPlan,
} from '../../controllers/Payment'
import {
  extractPlanPeriod,
  getErrorMessage,
  setAddress,
  calculateTotalPrice,
} from '../../utils/checkout'
import { chargebeeInstance } from '../../utils/chargebee'
import usePolling from '../../utils/usePolling'
import { useGTMCheckout } from '../../analytics/gtm'
import env from '../../env.json'
import style from './directDebitPayment.module.css'
import cx from 'classnames'
import { me } from '../../controllers/User'
import { useCheckoutErrorHandler } from '../../screens/PaymentScreen/errorHandling'
import { FormActions } from '../../screens/PaymentScreen/PaymentScreen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

type IDirectDebitPaymentProps = {
  type: 'standard'
  userToken: string
  plan?: string
  planInfo: ValidatedPlan | undefined
  coupon: string
  showCoupon: boolean
  setCoupon(id: string): void
  setCouponPrice(price: number): void
  price?: number
  isTrial: boolean
  trialPeriod?: number
  onSuccess(): void
  onCouponSubmit(): Promise<ValidatedPlan>
  creditNotes?: number
  addon: Addon
  planQuantity?: number
  togglePaymentLoading(loading: boolean): void
  setErrorMessage(message: string): void
  isVatExempt: boolean
  onVatExemptChange: (value: boolean) => void
}

type Polling = {
  running: boolean
  uuid: string | null
  userEmail: string | null
  userName: string | null
  userLastName: string | null
}

type FormValues = {
  name: string
  lastName: string
  iban: string
  bankAccountName: string
  billingName: string
  pec: string
  codiceDestinatario: string
  pIva: string
  cfCodiceFiscale: string
  billingAddress: string
  billingCity: string
  provincia: string
  cap: string
  country: string
  fullAddress: string
}

const DirectDebitPayment = forwardRef<FormActions, IDirectDebitPaymentProps>((props, ref) => {
  const formRef = useRef<FormikProps<FormValues>>()
  const failedAttempts = useRef(0)
  const [billingEnabled, setBilling] = useState(false)
  const [billingCountry, setBillingCountry] = useState('IT')
  const [ipAddress, setIpAddress] = useState<string | undefined>(undefined)
  const [polling, setPolling] = useState<Polling>({
    running: false,
    uuid: null,
    userEmail: null,
    userName: null,
    userLastName: null,
  })
  const { sendToGTM } = useGTMCheckout()
  const { handleError } = useCheckoutErrorHandler()

  const handleForm = async ({
    values,
    setSubmitting,
  }: {
    values: FormValues
    setSubmitting(isSubmitting: boolean): void
  }) => {
    props.togglePaymentLoading(true)

    try {
      const finalPrice = calculateTotalPrice(
        props.price,
        props.addon?.price,
        props.addon?.quantity,
        props.creditNotes,
      )
      const amount = Math.round(100 * finalPrice)
      const paymentIntent = await getPaymentIntent(amount, 'direct_debit')
      let direcDebitHandler = await chargebeeInstance.load('direct_debit')
      await direcDebitHandler.setPaymentIntent(paymentIntent)
      const userData = await me()
      direcDebitHandler.handlePayment(
        {
          customer: {
            firstName: values.name,
            lastName: values.lastName,
            email: userData.email,
          },
          bankAccount: {
            iban: values.iban,
          },
          additionalData: {
            billingAddress: {
              firstName: values.name,
              lastName: values.lastName,
              addressLine1: values.billingAddress,
              countryCode: values.country ? values.country : billingCountry,
            },
            email: values.pec,
          },
        },
        {
          change: (_intent: PaymentIntent) => {},
          success: (intent: PaymentIntent) => {
            console.log('success', intent)
            processPayment({ intent, values, setSubmitting })
          },
          error: (_intent: PaymentIntent, error: unknown) => {
            console.log(error)
            handleError(error)
            setSubmitting(false)
            props.togglePaymentLoading(false)
            props.setErrorMessage(getErrorMessage('directDebitNotAccepted'))
          },
        },
      )
    } catch (error: any) {
      handleError(error)
      setSubmitting(false)
      props.togglePaymentLoading(false)
      if (error.name && error.name !== 'VALIDATION_FAILED')
        props.setErrorMessage(getErrorMessage('directDebitNotAccepted'))
    }
  }

  const processPayment = async ({
    intent,
    values,
    setSubmitting,
  }: {
    intent: any
    values: FormValues
    setSubmitting(isSubmitting: boolean): void
  }) => {
    try {
      const { addon, plan, userToken, coupon, planQuantity } = props
      const result = await paySubscription({
        planId: plan ?? 'free',
        userToken,
        vatExempt: props.isVatExempt,
        paymentMethod: 'direct_debit',
        paymentId: intent.id,
        customerData: {
          first_name: values.name,
          last_name: values.lastName,
          vat_number: values.pIva,
          cf_codice_fiscale: values.cfCodiceFiscale,
          cf_codice_destinatario: values.codiceDestinatario,
        },
        couponCodes: coupon ? [coupon] : [],
        addons: addon ? [{ id: addon.id, quantity: addon.quantity }] : [],
        ipAddress,
        billingData: {
          company: values.billingName,
          email: values.pec,
          line1: values.billingAddress,
          city: values.billingCity,
          zip: values.cap,
          country: values.country ? values.country : billingCountry,
          state: values.provincia,
        },
        ...(planQuantity && { planQuantity }),
      })

      setPolling({
        running: true,
        uuid: result.uuid,
        userEmail: result.userEmail,
        userName: result.userName,
        userLastName: result.userLastName,
      })
      setTimeout(() => {
        setPolling({
          running: false,
          uuid: null,
          userEmail: null,
          userName: null,
          userLastName: null,
        })
        setSubmitting(false)
        props.togglePaymentLoading(false)
        props.setErrorMessage(getErrorMessage('genericError'))
      }, env.MAX_PAYMENT_POLLING ?? 15000)
    } catch (error: any) {
      console.log({ error })
      setSubmitting(false)
      props.togglePaymentLoading(false)
      if (error.message && error.message === 'GIFTED_INVALID_SUBSCRIPTION')
        return props.setErrorMessage(getErrorMessage('giftedInvalidSubscriptionMessage'))

      props.setErrorMessage(getErrorMessage('genericError'))
    }
  }

  useEffect(() => {
    const getCountryCode = async () => {
      try {
        const { countryCode, ipAddress } = await getCountryCodeFromIp()
        setBillingCountry(countryCode)
        setIpAddress(ipAddress)
      } catch (error) {
        console.log(error)
      }
    }

    getCountryCode()
  }, [])

  usePolling(
    async () => {
      if (!polling.uuid) return
      const result = await checkPaymentStatus(polling.uuid, props.userToken)

      if (result.status === 'completed') {
        if (props.type === 'standard') {
          await sendToGTM({
            event: 'checkoutCompleted',
            productName: props.planInfo
              ? props.planInfo.invoice_name
              : props.addon
              ? props.addon.name
              : '',
            productSKU: props.plan ?? props.addon?.id ?? '',
            trial: props.isTrial ? true : false,
            price: calculateTotalPrice(props.price, props.addon?.price, props.addon?.quantity),
            paymentMethod: 'directdebit',
            subscriptionPeriod: props.planInfo
              ? extractPlanPeriod(props.planInfo.period, props.planInfo.period_unit)
              : 'one-time',
            discount: props.coupon ? true : false,
            transactionId: result.subscriptionId ?? '',
            couponName: props.coupon ?? '',
            user_data: {
              email: polling.userEmail,
              first_name: polling.userName,
              last_name: polling.userLastName,
            },
            ...(props.addon && {
              addon_id: props.addon.id,
              addon_qty: props.addon.quantity,
              addon_value: props.addon.price,
            }),
          })
        }

        setPolling({
          running: false,
          uuid: null,
          userEmail: null,
          userName: null,
          userLastName: null,
        })
        setTimeout(() => {
          props.setErrorMessage('')
          formRef.current?.setSubmitting(false)
          props.togglePaymentLoading(false)
          props.onSuccess()
        }, 2000)
      } else if (result.status === 'failed') {
        failedAttempts.current = failedAttempts.current + 1

        if (failedAttempts.current >= 2) {
          setPolling({
            running: false,
            uuid: null,
            userEmail: null,
            userName: null,
            userLastName: null,
          })
          const errorMessage = getErrorMessage(result.reason)
          formRef.current?.setSubmitting(false)
          props.togglePaymentLoading(false)
          props.setErrorMessage(errorMessage)
        }
      }
    },
    polling.running ? env.PAYMENT_POLLING_INTERVAL ?? 3000 : null,
  )

  return (
    <div className={style.container}>
      <div className={style.wrap}>
        <div>
          <Formik
            innerRef={e => {
              if (e) {
                formRef.current = e

                const controls: FormActions = {
                  submit: () => {
                    formRef.current?.submitForm()
                  },
                }
                if (typeof ref === 'function') {
                  ref(controls)
                }
              }
            }}
            initialValues={{
              name: '',
              lastName: '',
              iban: '',
              bankAccountName: '',
              billingName: '',
              pec: '',
              codiceDestinatario: '',
              pIva: '',
              cfCodiceFiscale: '',
              billingAddress: '',
              billingCity: '',
              provincia: '',
              cap: '',
              country: '',
              fullAddress: '',
            }}
            validationSchema={billingEnabled ? billingValidationSchema : validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleForm({ values, setSubmitting })
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={style.field}>
                  <input
                    name='name'
                    className={values.name ? cx([style.input, style.val]) : style.input}
                    type='text'
                    placeholder='Nome'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label className={style.label}>Nome</label>
                  <i className={style.bar}></i>
                  <div className={style.errorText}>
                    {errors.name && touched.name && errors.name}
                  </div>
                </div>
                <div className={style.field}>
                  <input
                    name='lastName'
                    className={values.lastName ? cx([style.input, style.val]) : style.input}
                    type='text'
                    placeholder='Cognome'
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label className={style.label}>Cognome</label>
                  <i className={style.bar}></i>
                  <div className={style.errorText}>
                    {errors.lastName && touched.lastName && errors.lastName}
                  </div>
                </div>

                <div className={style.field}>
                  <input
                    name='iban'
                    className={values.iban ? cx([style.input, style.val]) : style.input}
                    type='text'
                    placeholder='IBAN'
                    value={values.iban}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label className={style.label}>IBAN</label>
                  <i className={style.bar}></i>
                  <div className={style.errorText}>
                    {errors.iban && touched.iban && errors.iban}
                  </div>
                </div>

                <div className={style.field}>
                  <input
                    name='bankAccountName'
                    className={values.bankAccountName ? cx([style.input, style.val]) : style.input}
                    type='text'
                    placeholder='Intestatario conto'
                    value={values.bankAccountName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label className={style.label}>Intestatario conto</label>
                  <i className={style.bar}></i>
                  <div className={style.errorText}>
                    {errors.bankAccountName && touched.bankAccountName && errors.bankAccountName}
                  </div>
                </div>

                {billingEnabled ? (
                  <>
                    <div className={style.field}>
                      <input
                        name='billingName'
                        className={values.billingName ? cx([style.input, style.val]) : style.input}
                        type='text'
                        placeholder='Ragione sociale'
                        value={values.billingName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className={style.label}>Ragione sociale</label>
                      <i className={style.bar}></i>
                      <div className={style.errorText}>
                        {errors.billingName && touched.billingName && errors.billingName}
                      </div>
                    </div>
                    <div className={style.adaptiveFields}>
                      <div className={style.adaptiveField}>
                        <input
                          name='pec'
                          className={values.pec ? cx([style.input, style.val]) : style.input}
                          type='text'
                          placeholder='PEC'
                          value={values.pec}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>PEC</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.pec && touched.pec && errors.pec}
                        </div>
                      </div>
                      <div className={cx([style.adaptiveField, style.rightField])}>
                        <input
                          name='codiceDestinatario'
                          className={
                            values.codiceDestinatario ? cx([style.input, style.val]) : style.input
                          }
                          type='text'
                          placeholder='Codice Destinatario'
                          value={values.codiceDestinatario}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>Codice Destinatario</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.codiceDestinatario &&
                            touched.codiceDestinatario &&
                            errors.codiceDestinatario}
                        </div>
                      </div>
                    </div>
                    <div className={style.adaptiveFields}>
                      <div className={style.adaptiveField}>
                        <input
                          name='pIva'
                          className={values.pIva ? cx([style.input, style.val]) : style.input}
                          type='text'
                          placeholder='Ragione sociale'
                          value={values.pIva}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>Partita IVA</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.pIva && touched.pIva && errors.pIva}
                        </div>
                      </div>
                      <div className={cx([style.adaptiveField, style.rightField])}>
                        <input
                          name='cfCodiceFiscale'
                          className={
                            values.cfCodiceFiscale ? cx([style.input, style.val]) : style.input
                          }
                          type='text'
                          placeholder='Codice Fiscale'
                          value={values.cfCodiceFiscale}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className={style.label}>Codice Fiscale</label>
                        <i className={style.bar}></i>
                        <div className={style.errorText}>
                          {errors.cfCodiceFiscale &&
                            touched.cfCodiceFiscale &&
                            errors.cfCodiceFiscale}
                        </div>
                      </div>
                    </div>
                    <AddressAutocomplete
                      name='fullAddress'
                      onChange={data => setAddress(data, setFieldValue, setFieldError)}
                      onBlur={handleBlur}
                      errors={errors.fullAddress && touched.fullAddress && errors.fullAddress}
                    />
                    <label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 30,
                        justifyContent: 'space-between',
                      }}>
                      <input
                        name='billing'
                        type='checkbox'
                        checked={props.isVatExempt}
                        onChange={() => props.onVatExemptChange(!props.isVatExempt)}
                      />
                      <span className={style.vatExemptText} style={{ flex: 1 }}>
                        Sono una PA (esente IVA)
                      </span>
                    </label>
                  </>
                ) : (
                  <></>
                )}
                <label
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 30,
                  }}>
                  <input
                    name='billing'
                    type='checkbox'
                    checked={billingEnabled}
                    onChange={() => {
                      if (billingEnabled)
                      props.onVatExemptChange(false)
                      setBilling(!billingEnabled)
                    }}
                  />
                  <span className='billing-text ml-2' style={{ flex: 1 }}>
                    Desidero ricevere la fattura
                  </span>
                  <div className={style.secureForm}>
                    <FontAwesomeIcon icon={faLock} /> Secure form
                  </div>
                </label>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
})

export default DirectDebitPayment
