import { gql } from '@apollo/client';
import { restClient } from './../rest';
export const account = (IDENTITY_API_URL, KEYCLOAK_REALM_URL, MY_API_URL, client, getToken) => {
    const getSocialProviders = async (userId) => {
        try {
            return await restClient(getToken()).jsonGet(IDENTITY_API_URL, `/users/${userId}/social-providers`);
        }
        catch (e) {
            console.warn(e);
            throw e;
        }
    };
    const linkSocialProvider = async (providerId, redirectUri) => {
        const publicClient = 'account';
        const token = getToken();
        const kcSession = await restClient(token, true).jsonGet(KEYCLOAK_REALM_URL, `/browser-session/init?publicClient=${publicClient}&providerId=${providerId}`);
        redirectUri = encodeURIComponent(redirectUri);
        const linkingPath = `/broker/${providerId}/link?client_id=${publicClient}&redirect_uri=${redirectUri}&nonce=${kcSession.nonce}&hash=${kcSession.hash}`;
        const linkingUrl = `${KEYCLOAK_REALM_URL}${linkingPath}`;
        window.location.href = linkingUrl;
    };
    const unlinkSocialProvider = async (userId, providerId) => {
        try {
            await restClient(getToken()).jsonDelete(IDENTITY_API_URL, `/users/${userId}/social-providers/${providerId}`);
        }
        catch (e) {
            console.warn(e);
            throw e;
        }
    };
    const updatePushPreference = async (userId, pushNotifications) => {
        try {
            await client.mutate({
                mutation: UPDATE_NOTIFICATIONS,
                variables: { identifier: userId, pushNotifications: pushNotifications },
            });
        }
        catch (e) {
            console.warn(e);
            throw e;
        }
    };
    const updatePassword = async (userId, password) => {
        try {
            await restClient(getToken()).jsonPost(IDENTITY_API_URL, `/users/${userId}/change-password`, {
                password,
            });
        }
        catch (e) {
            console.warn(e);
            throw e;
        }
    };
    const updateUtm = async (userUtm) => {
        try {
            await restClient(getToken()).jsonPost(MY_API_URL, `/identity/update-utm`, {
                ...userUtm,
            });
        }
        catch (e) {
            console.warn(e);
            throw e;
        }
    };
    const deleteAccount = async () => {
        try {
            return await restClient(getToken()).jsonDelete(MY_API_URL, `/delete-account`);
        }
        catch (e) {
            console.warn(e);
            throw e;
        }
    };
    return {
        getSocialProviders: getSocialProviders,
        linkSocialProvider: linkSocialProvider,
        unlinkSocialProvider: unlinkSocialProvider,
        updatePushPreference: updatePushPreference,
        updatePassword: updatePassword,
        deleteAccount: deleteAccount,
        updateUtm: updateUtm,
    };
};
const UPDATE_NOTIFICATIONS = gql `
  mutation setPushNotifications($identifier: ID!, $pushNotifications: Boolean!) {
    updateUser(
      input: { where: { id: $identifier }, data: { pushNotifications: $pushNotifications } }
    ) {
      user {
        username
        email
        pushNotifications
      }
    }
  }
`;
