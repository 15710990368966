import { useContext } from 'react'
import { ShareContext, ShareSocialType, ShareSubjectType } from './shareContext'

interface IShareInfo {
  type: ShareSubjectType
  body?: string
  url: string
  title?: string
  refEnabled?: boolean
  onPress?: (social: ShareSocialType) => unknown
}

export const useShare = () => {
  const { dispatch, state: prevState } = useContext(ShareContext)

  const applyFirstPromoterId = (url: string) => {
    if (window.FPUserData && window.FPUserData.referralId)
      return url + '?via=' + window.FPUserData.referralId
    else return url
  }

  const getBody = (type: ShareSubjectType) => {
    switch (type) {
      case 'course':
        return `Ho trovato interessante questo percorso su Learnn. Con il mio link puoi accedere a un prova gratuita riservata :)`
      case 'lesson':
        return `Ho trovato interessante questa lezione su Learnn. Con il mio link puoi accedere a un prova gratuita riservata :)`
      case 'webinar':
        return `Ho trovato interessante questo webinar su Learnn. Con il mio link puoi accedere a un prova gratuita riservata :)`
      case 'comingContent':
        return `Ho trovato interessante questo contenuto su Learnn. Con il mio link puoi accedere a un prova gratuita riservata :)`
      case 'note':
        return `Ho trovato interessante questa lezione su Learnn e ho preso una nota. Con il mio link puoi accedere a un prova gratuita riservata :)`
      case 'external':
        return `Guarda cos'ho trovato su learnn :)`
      case 'profile':
        return `Dai un occhiata al mio profilo Learnn :)`
    }
  }

  return {
    share: ({ type, url, body, title, onPress, refEnabled = true }: IShareInfo) => {
      dispatch({
        type,
        url: refEnabled ? applyFirstPromoterId(url) : url,
        show: true,
        body: body ?? getBody(type),
        onPress,
        title: title ?? 'Dai accesso gratuito a questo contenuto a un tuo collega',
      })
    },
    close: () => {
      dispatch({
        ...prevState,
        show: false,
      })
    },
  }
}
