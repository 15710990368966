import { FC } from 'react'
import style from './quizDifficulty.module.css'
import fireFull from '../../assets/images/icons/fire_full.svg'
import fireEmpty from '../../assets/images/icons/fire_empty.svg'
import cx from 'classnames'

type QuizDifficulty = {
  difficulty: number
  containerClassName?: string
}

const QUIZ_MAX_DIFFICULTY = 3

export const QuizDifficulty: FC<QuizDifficulty> = ({ difficulty, containerClassName }) => {
  return (
    <div className={cx([style.container, containerClassName])}>
      {[...Array(difficulty)].map((_, i) => (
        <img className={style.icon} src={fireFull} key={`difficulty-filled-${i}`} />
      ))}
      {[...Array(QUIZ_MAX_DIFFICULTY - difficulty)].map((_, i) => (
        <img className={style.icon} src={fireEmpty} key={`difficulty-${i}`} />
      ))}
    </div>
  )
}
