import { useContext } from 'react'

import { useQuery } from 'react-query'
import { getUserProfileWithInterests } from '../../controllers/Profile'
import { GlobalProviderContext } from '../GlobalProvider'
import { ErrorMessage } from '../ErrorMessage'

import { matchQuery } from '../../utils/matchQuery'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { OramaPopupComponent } from './OramaPopup'
import { FlowisePopupComponent } from './FlowisePopup'

export type IAssistantPopupProps = { provider?: 'orama' | 'flowise' }

//@ts-ignore
export const AssistantPopup = ({ provider }: IAssistantPopupProps) => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  if (!userId) return <ErrorMessage />

  const profileQuery = useQuery(['profile-with-interests', userId], () => getUserProfileWithInterests(userId))

  return (
    <>
      {matchQuery(profileQuery, {
        loading: () => <></>,
        error: () => (provider === 'orama' ? <OramaPopupComponent /> : <></>),
        success: profile =>
          pipe(
            profile,
            O.fold(
              () => (provider === 'orama' ? <OramaPopupComponent /> : <></>),
              profile =>
                provider === 'orama' ? (
                  <OramaPopupComponent profile={profile} />
                ) : (
                  <FlowisePopupComponent profile={profile} />
                ),
            ),
          ),
      })}
    </>
  )
}
