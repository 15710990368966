import { FC } from 'react'
import style from './quizCard.module.css'
import styleSmall from './smallQuizCard.module.css'
import { DailyQuiz, QuizAttemptDetails } from '@learnn/sdk/src/api/quiz'
import { getTextDateWithYear } from '@learnn/sdk/dist/api/utils'
import passedImage from '../../assets/images/icons/correct.svg'
import failedImage from '../../assets/images/icons/wrong.svg'
import { QuizDifficulty } from '../QuizDifficulty'
import ReactMarkdown from 'react-markdown'
import cx from 'classnames'
import { ResponsiveImage } from '../ResponsiveImage'

export interface IQuizCard {
  quiz: DailyQuiz
  attempt?: QuizAttemptDetails
  current?: boolean

  onClick(): void

  format: 'small' | 'large'
}

export const QuizCard: FC<IQuizCard> = ({ quiz, attempt, current, onClick, format }) => {
  switch (format) {
    case 'small':
      return (
        <div className={styleSmall.card} onClick={onClick}>
          <div className={styleSmall.cardInner}>
            <div className={styleSmall.imageContainer}>
              <ResponsiveImage wrapperClassName={styleSmall.image} src={quiz.coverImage} />
            </div>
            <div className={styleSmall.infoContainer}>
              <p className={styleSmall.title}>{quiz.title}</p>
              <p className={styleSmall.subtitle}>{`${quiz.timeLimit} MINUTI MAX`}</p>
              <QuizDifficulty
                difficulty={quiz.difficulty}
                containerClassName={style.diffContainer}
              />
            </div>
          </div>
        </div>
      )
    case 'large':
      return attempt ? (
        <div className={cx([style.container, current ? style.current : ''])}>
          <div className={style.date}>{getTextDateWithYear(quiz.releaseDate, 'long')}</div>
          <div className={style.completedCard} onClick={onClick}>
            <div className={style.imageContainer}>
              <img src={quiz.coverImage} className={style.image} />
              <div className={style.overlay}>
                <img
                  className={style.resultImage}
                  src={attempt.passed ? passedImage : failedImage}
                />
                <span className={style.resultText}>
                  {attempt.passed ? 'SUPERATA' : 'NON SUPERATA'}
                </span>
              </div>
            </div>
            <div className={style.details}>
              <div className={style.quizLength}>
                <span className={style.timeText}>
                  {`${quiz.timeLimit} MINUTI MASSIMO - DIFFICOLTÀ `}
                </span>
                <QuizDifficulty difficulty={quiz.difficulty} />
              </div>
              <div className={style.title}>{quiz.title}</div>
              <div className={style.description}>
                <ReactMarkdown>{quiz.description}</ReactMarkdown>
              </div>
              <div className={style.categoriesContainer}>
                {quiz.categories.map(c => (
                  <Tag key={`${quiz.dailyQuizId}-${c}`}>{c}</Tag>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={cx([style.container, current ? style.current : ''])}>
          <div className={style.date}>{getTextDateWithYear(quiz.releaseDate, 'long')}</div>
          <div className={style.card} onClick={onClick}>
            <div className={style.imageContainer}>
              <img src={quiz.coverImage} className={style.image} />
            </div>
            <div className={style.details}>
              <div className={style.quizLength}>
                <span className={style.timeText}>
                  {`${quiz.timeLimit} MINUTI MASSIMO - DIFFICOLTÀ `}
                </span>
                <QuizDifficulty difficulty={quiz.difficulty} />
              </div>
              <div className={style.title}>{quiz.title}</div>
              <div className={style.description}>
                <ReactMarkdown>{quiz.description}</ReactMarkdown>
              </div>
              <div className={style.categoriesContainer}>
                {quiz.categories.map(c => (
                  <Tag key={`${quiz.dailyQuizId}-${c}`}>{c}</Tag>
                ))}
              </div>
            </div>
          </div>
        </div>
      )
  }
}

const Tag = ({ children }: { children: string }) => {
  return <div className={style.tag}>{children}</div>
}
