import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Box, Container, Grid, useTheme } from '@learnn/designn'

export const Loader = () => {
  const { spacing } = useTheme()

  const SkeletonComponent = ({ br }: { br?: number }) => (
    <Skeleton height='100%' width='100%' borderRadius={br ?? 15} />
  )

  return (
    <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
      <Container>
        <Box mb={spacing.space_6} style={{ width: '200px', height: '35px' }}>
          <SkeletonComponent />
        </Box>

        <Grid gridTemplateColumns={'repeat(1, 1fr)'} gridGap={spacing.space_4}>
          {Array.from({ length: 4 }).map(() => (
            <div style={{ width: '100%', height: '160px' }}>
              <SkeletonComponent />
            </div>
          ))}
        </Grid>
      </Container>
    </SkeletonTheme>
  )
}
