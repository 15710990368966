import React, { useState, useRef, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../../components/Header'
import cx from 'classnames'
import Footer from '../../components/Footer'
import Error from '../../components/Error'
import Loader from 'react-loader-spinner'
import InputPassword from '../../components/InputPassword'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import auth from '../../utils/auth'
import usePolling from '../../utils/usePolling'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { login, tokenLogin } from '../../controllers/User'
import env from '../../env.json'
import { getCookie, deleteCreationCookie } from '../../utils/checkout'

import QueryString from 'query-string'
import style from './maintenanceScreen.module.css'
import logo from '../../assets/images/logo.png'
import { LiveChat } from '../../components/LiveChat'

const extractConfig = props => props?.location.state?.config

export const MaintenanceScreen = ({ history, ...props }) => {
  const config = extractConfig(props)

  return (
    <>
      <LiveChat />
      <Header />
      <Container className={style.container}>
        <Row className='justify-content-center'>
          <Col xs={12} md={6}>
            <h1 className={style.title}>{config?.title ?? '🛠 Manutenzione'}</h1>
            <p className={style.subtitle}>
              {config?.message ??
                'Siamo temporaneamente offline per manutenzione, ci vediamo più tardi! 😉'}
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}
