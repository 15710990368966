import { HorizontalStack, useTheme, VerticalStack } from "@learnn/designn"
import { FC } from "react"
import { TimelineCountItem } from "./TimelineCountItem"


export type TimelineItemProps<D> = {
    data: D
    index: number
    progressPercentage?: number
    previousConnected?: boolean
    nextConnected?: boolean
    hidePrevious?: boolean
    hideNext?: boolean
    label: string
    renderComponent: (item: D, index: number) => JSX.Element
    renderTimelineItem?: (item: D, index: number) => JSX.Element | undefined
}

export const TimelineItem: FC<TimelineItemProps<any>> = ({ data, index, progressPercentage, previousConnected, nextConnected, hideNext, hidePrevious, label, renderComponent, renderTimelineItem }) => {
    const { spacing } = useTheme()

    return (
        <HorizontalStack>
            <VerticalStack>
                <TimelineCountItem
                    previousConnected={previousConnected}
                    nextConnected={nextConnected}
                    hidePrevious={hidePrevious}
                    hideNext={hideNext}
                    renderComponent={renderTimelineItem && renderTimelineItem(data, index)}
                    label={label}
                    progressPercentage={progressPercentage}
                    lineWidth={'5px'}
                    circleWidth={'5px'}
                />
            </VerticalStack>
            <VerticalStack flex={1} mt={index > 0 ? spacing.space_5 : 0}>
                {renderComponent(data, index)}
            </VerticalStack>
        </HorizontalStack>
    )
}