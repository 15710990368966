import { ResponsiveImage } from '../ResponsiveImage'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import * as F from 'fp-ts/lib/function'
import style from './dynamicSections.module.css'
import {
  ComponentSectionDynamicLastSeenCourse,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/dist/api/dynamic-sections/types'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'

export interface ILastCourseProps {
  data: DynamicSectionsValue<DynamicSectionsError, ComponentSectionDynamicLastSeenCourse>
}

export const LastCourse = ({ data }: ILastCourseProps) => {
  const history = useHistory()
  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const render = (section: ComponentSectionDynamicLastSeenCourse) => {
    const item = section.data;
    if (!item) return <></>
    return (
      <CustomCarousel
        title={section.title}
        singleSlide
        carouselKind='lastCourse'
        renderSlide={() => {
          return (
            <div
              className={style.lastCourseCard}
              onClick={() => history.push(`/corso/${item.course.id}`)}>
              <div className={style.lastCourseImageContainer}>
                <ResponsiveImage wrapperClassName={style.lastCourseImage} src={item.coverImage} />
              </div>
              <div className={style.lastCourseInfo}>
                <div>
                  <div className={style.lastCourseSubtitle}>{item.course.title}</div>
                  <div className={style.lastCourseTitle}>{item.title}</div>
                  <div className={style.lastCourseDuration}>{item.duration}</div>
                  <button
                    className={style.lastCourseCallToAction}
                    onClick={() => history.push(`/corso/${item.course.id}`)}>
                    Completa il corso
                    <FontAwesomeIcon icon={faPlay} className='ml-3' />
                  </button>
                </div>
              </div>
              <div className={style.lastCourseProgressBarContainer}>
                <div
                  className={style.lastCourseProgressBar}
                  style={{ width: `${item.course.progress}%` ?? 0 }}
                />
              </div>
            </div>
          )
        }}
      />
    );
  }

  return F.pipe(
    data,
    O.fold(CustomCarouselLoading, data => {
      return F.pipe(data, E.fold(error, render))
    }),
  )
}
