import { useState, FC } from "react";
import cx from "classnames";
import style from "./tooltip.module.css";

type Tooltip = {
  text: string;
  position?: string;
};

//@ts-ignore
export const Tooltip: FC<Tooltip> = ({ children = "", text, position = "top" }) => {
  const [active, setActive] = useState(false);

  const getPositionStyle = (position: string) => {
    if (position === "left") return style.right;
    if (position === "right") return style.right;
    if (position === "bottom") return style.bottom;

    return style.top;
  };

  const showTip = () => {
    setActive(true);
  };

  const hideTip = () => {
    setActive(false);
  };

  return (
    <div
      className={style.TooltipWrapper}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}>
      {/* Wrapping */}
      {children}
      {active && <div className={cx([style.TooltipTip, getPositionStyle(position)])}>{text}</div>}
    </div>
  );
};
