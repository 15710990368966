import { useRef, useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../../components/Header'
import Loader from 'react-loader-spinner'
import { AlertBanner } from '../../components/AlertBanner'
import Error from '../../components/Error'
import Footer from '../../components/Footer'
import { Formik, Field } from 'formik'
import style from './giftRegistrationScreen.module.css'
import * as Yup from 'yup'
import QueryString from 'query-string'
import auth from '../../utils/auth'
import { self } from '../../controllers/User'
import { validatePlan, applyCoupon, checkGifterEmail } from '../../controllers/Payment'
import { LiveChat } from '../../components/LiveChat'

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Inserisci un indirizzo email valido')
    .required('Inserisci un indirizzo email'),
  privacy: Yup.bool().oneOf([true], 'Devi accettare la privacy policy'),
})

const extractPlan = urlParams => urlParams?.plan
const extractCoupon = urlParams => urlParams?.coupon

export const GiftRegistrationScreen = ({ location, history, ...props }) => {
  const urlParams = useRef(QueryString.parse(location.search))
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [submitError, setSubmitError] = useState('')
  const [alertMessage, showAlertMessage] = useState(false)
  const [loginEmail, setLoginEmail] = useState('')
  const plan = extractPlan(urlParams.current)
  const coupon = extractCoupon(urlParams.current)
  const currentLocation = window.location?.href

  const handleForm = async (values, setSubmitting) => {
    const { email } = values
    try {
      const alreadyPresent = await checkGifterEmail(email)
      if (alreadyPresent) {
        setSubmitting(false)
        setLoginEmail(email)
        showAlertMessage(true)
      } else {
        history.push({
          pathname: `/regalo-dettagli/`,
          state: { email: values.email, plan: plan, coupon: coupon },
        })
      }
    } catch (error) {
      setSubmitError('Si è verificato un errore, riprova o contattaci in live chat')
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const checkStatus = async () => {
      const paramsCoupon = extractCoupon(urlParams.current)

      if (paramsCoupon) {
        try {
          await applyCoupon({ plan, coupon: paramsCoupon })
        } catch (error) {
          console.log(error)
          setErrorMessage('Il coupon inserito o il piano selezionato non è valido.')
          setFetchError(true)
          return
        }
      } else {
        try {
          const res = await validatePlan({ plan })
        } catch (error) {
          console.log(error)
          setErrorMessage('Il piano selezionato non è valido.')
          setFetchError(true)
          return
        }
      }

      if (auth.getToken()) {
        try {
          const user = await self()
          history.push({
            pathname: `/regalo-dettagli/`,
            state: { email: user.email, plan: plan, coupon: coupon },
          })
          return
        } catch (error) {
          console.log(error)
          setErrorMessage(
            "Non siamo riusciti a recuperare i dati dell'utente, prova ad effettuare il logout.",
          )
          setFetchError(true)
          return
        }
      }
      setLoading(false)
    }

    checkStatus()
  }, [])

  return !fetchError ? (
    <>
      <LiveChat />
      <Header />
      <Container>
        <Row className={style.mainContainer}>
          {loading ? (
            <Loader type='TailSpin' color='black' height={50} width={50} className='mt-5' />
          ) : (
            <Col xs={11} md={4}>
              <span className='subtitle mb-3'>PASSAGGIO 1 DI 3</span>
              <h1 className={style.title}>Inserisci la tua email.</h1>
              {alertMessage ? (
                <AlertBanner
                  gift
                  onClick={() => {
                    const querystring = coupon ? `?plan=${plan}&coupon=${coupon}` : `?plan=${plan}`

                    history.push({
                      pathname: '/login/',
                      search:
                        currentLocation == 10
                          ? `?appurl=${encodeURIComponent(currentLocation)}`
                          : `?appurl=${encodeURIComponent(`/regalo/${querystring}`)}`,
                      state: {
                        email: loginEmail,
                        message: 'Hai già un account, effettua il login per inviare un regalo.',
                      },
                    })
                  }}
                />
              ) : null}
              <Formik
                initialValues={{ email: '', privacy: false }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleForm(values, setSubmitting)
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <input
                      className='form-control input-field'
                      type='email'
                      name='email'
                      placeholder='Email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <div className='error-text mt-1 mb-1'>
                      {errors.email && touched.email && errors.email}
                    </div>
                    <label className='privacy-check mt-3'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}>
                        <Field type='checkbox' name='privacy' className='privacy-check' />
                        <div>
                          <span className='privacy-text ml-2'>Compilando il form accetto la</span>
                          <a
                            href='https://www.iubenda.com/privacy-policy/54290035'
                            target='_blank'
                            rel='noopener noreferrer'>
                            <span className='privacy-text-bold ml-1'>privacy policy</span>
                          </a>
                        </div>
                      </div>
                    </label>
                    <div className='error-text mt-1 mb-1'>
                      {errors.privacy && touched.privacy && errors.privacy}
                    </div>
                    {submitError ? (
                      <div className='error-text mt-2' role='alert'>
                        {submitError}
                      </div>
                    ) : null}
                    <button
                      className='btn submit-button mt-4'
                      type='submit'
                      disabled={isSubmitting}>
                      CONTINUA ➔
                    </button>
                  </form>
                )}
              </Formik>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </>
  ) : (
    <Error message={errorMessage} />
  )
}
