import { useContext } from 'react'
import { PreferencesPopupContext } from './popupContext'

export const usePreferencesPopup = () => {
  const { dispatch } = useContext(PreferencesPopupContext)

  return {
    show: () => {
      dispatch({
        show: true,
      })
    },
    close: () => {
      dispatch({
        show: false,
      })
    },
  }
}
