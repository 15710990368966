import env from "../env.json";

const Chargebee = window.Chargebee;
Chargebee.init({
  site: env.CHARGEBEE_SITE,
  publishableKey: env.CHARGEBEE_API_KEY,
  iframeOnly: true
});
export const chargebeeInstance = Chargebee.getInstance();

export type PortalTypes =
  | "sub_details"
  | "sub_cancel"
  | "edit_subscription"
  | "scheduled_changes"
  | "account_details"
  | "portal_edit_billing_address"
  | "portal_payment_methods"
  | "portal_add_payment_method"
  | "portal_edit_payment_method"
  | "portal_view_payment_method"
  | "portal_choose_payment_method";

export const getPortalTypes = () => {
  const portalTypes = Chargebee.getPortalSections();
  return Object.keys(portalTypes).map(function (key) {
    return portalTypes[key];
  });
};

export default Chargebee;
