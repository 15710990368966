import { TrialPeriodUnit } from "../controllers/Payment";
import env from '../env.json'

export type FailureReason =
  | 'cardNotAccepted'
  | 'directDebitNotAccepted'
  | 'paypalError'
  | 'addressMatchError'
  | 'UserNotPresent'
  | 'genericError'
  | 'giftedInvalidSubscriptionMessage'

type AddressData = {
  formatted_address: string
  address_components: [{ types: [string]; short_name: string }]
}

const ERROR_MESSAGES = {
  cardNotAccepted:
    'La carta che hai utilizzato non è stata accettata, riprova o contattaci in live chat.',
  directDebitNotAccepted:
    'La banca che hai utilizzato non è stata accettata, riprova o contattaci in live chat.',
  paypalError:
    'Non è stato possibile elaborare il pagamento con PayPal, riprova utilizzando una carta o contattaci in live chat.',
  addressMatchError:
    'Il la nazione di emissione della carta non coincide con la nazione in cui ti trovi, prova con un diverso metodo di pagamento o contattaci in live chat',
  UserNotPresent: 'Il tuo utente non è ancora stato creato',
  giftedInvalidSubscriptionMessage:
    'Questo account non può ricevere regali, contattaci in live chat per maggiori informazioni.',
  defaultMessage: 'Si è verificato un errore con il pagamento, riprova o contattaci in live chat.',
}

export const extractPlanPeriod = (period: number, unit: string) => {
  return `${period} ${unit}${period > 1 && 's'}`
}

export const getErrorMessage = (failureReason: FailureReason): string => {
  //@ts-ignore //TODO
  return ERROR_MESSAGES[failureReason] ?? ERROR_MESSAGES.defaultMessage
}

export const getCookie = (name: string) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : null
}

export const setAddress = (data: AddressData, setFieldValue: Function, setFieldError: Function) => {
  const { formatted_address, address_components } = data
  const streetNumberRow = address_components.find(element =>
    element.types.includes('street_number'),
  )
  const routeRow = address_components.find(element => element.types.includes('route'))
  const cityRow =
    address_components.find(element => element.types.includes('locality')) ??
    address_components.find(element => element.types.includes('administrative_area_level_3'))
  const provinciaRow = address_components.find(element =>
    element.types.includes('administrative_area_level_2'),
  )
  const countryRow = address_components.find(element => element.types.includes('country'))
  const capRow = address_components.find(element => element.types.includes('postal_code'))

  const countryProvincia = ['IT']

  if (streetNumberRow && routeRow && cityRow && countryRow && capRow) {
    setFieldValue('fullAddress', formatted_address)
    setFieldValue('billingAddress', `${routeRow.short_name} ${streetNumberRow.short_name}`)
    setFieldValue('billingCity', cityRow.short_name)
    if (provinciaRow && countryProvincia.includes(countryRow.short_name)) {
      setFieldValue('provincia', provinciaRow.short_name)
    }
    setFieldValue('country', countryRow.short_name)
    setFieldValue('cap', capRow.short_name)
  } else {
    setFieldValue('fullAddress', '')
    setFieldError('fullAddress', 'Inserisci un indirizzo valido')
  }
}

export const displayTrial = (trialPeriod: number, trialPeriodUnit: TrialPeriodUnit) => {
  switch (trialPeriodUnit) {
    case 'month': return `${trialPeriod} ${trialPeriod === 1 ? 'mese' : 'mesi'}`
    case 'day': return `${trialPeriod} ${trialPeriod === 1 ? 'giorno' : 'giorni'}`
    default: return ''
  }
}

export const printTotalPrice = (
  planPrice?: number,
  couponPrice?: number,
  addonPrice?: number,
  addonQuantity?: number,
  creditNotes?: number,
  isTrial?: boolean,
  isVatExempt?: boolean
) => {
  let baseprice = (couponPrice ?? planPrice) ?? 0
  if (isVatExempt)
    baseprice = removeVatFromPrice(baseprice)
  const addon = addonPrice ?? 0
  const credit = creditNotes ?? 0
  const addon_qty = addonQuantity ?? 0
  const addonTotal = addon * addon_qty

  let finalPrice = baseprice === 0 ? addonTotal : (baseprice + addonTotal) - credit
  if (couponPrice && planPrice && addonTotal > 0) {
    finalPrice = couponPrice
  }

  return isTrial ? '0.00€' : finalPrice > 0 ? `${Math.round(finalPrice * 100) / 100}€` : '0.00€'
}

export const calculateTotalPrice = (
  planPrice?: number,
  couponPrice?: number,
  addonPrice?: number,
  addonQuantity?: number,
  creditNotes?: number,
  isTrial?: boolean,
  isVatExempt?: boolean
) => {
  let baseprice = (couponPrice ?? planPrice) ?? 0
  if (isVatExempt)
    baseprice = removeVatFromPrice(baseprice)
  const addon = addonPrice ?? 0
  const credit = creditNotes ?? 0
  const addon_qty = addonQuantity ?? 0
  const addonTotal = addon * addon_qty

  let finalPrice = baseprice + addonTotal - credit

  return isTrial ? 0 : finalPrice > 0 ? Math.round(finalPrice * 100) / 100 : 0
}

export const getAppliedVat = (price: number, vat: number) => {
  const appliedVat = 1 + vat / 100
  const netPrice = price / appliedVat
  const finalPrice = price - netPrice
  return Math.round((finalPrice + Number.EPSILON) * 100) / 100
}

export const removeVatFromPrice = (amount: number): number => {
  const vatFraction = env.DEFAULT_VAT_PERCENTAGE / 100;
  return amount / (1 + vatFraction);
}