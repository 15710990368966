import { useEffect, useRef, useState } from 'react'
import { membershipToken } from '../controllers/User'
import Error from '../components/Error'
import auth from '../utils/auth'
import { chargebeeInstance } from '../utils/chargebee'
import { isMobile } from 'react-device-detect'

const errorMessage =
  'Il tuo abbonamento non è stato trovato. Scrivi al supporto per maggiori informazioni.'

function MyScreen({ history }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const cbPortalRef = useRef()
  const tokenRef = useRef()
  const logout = () => {
    auth.clearAppStorage()
    chargebeeInstance.logout()
    history.push({ pathname: `/login` })
  }

  useEffect(() => {
    setLoading(true)
    if (isMobile) {
      membershipToken().then(t => {
        if (t.status === 404) {
          setLoading(false)
          setError(errorMessage)
          return
        }
        if (t.status === 457) {
          setLoading(false)
          setError('this is a mock, manage subscription feature not supported')
          return
        }
        const accessUrl = t.body.portal_session.access_url
        window.location.href = accessUrl
      })
      return
    }
    if (cbPortalRef.current) {
      return
    }
    chargebeeInstance.setPortalSession(() => {
      if (!tokenRef.current) {
        return membershipToken()
          .then(t => {
            if (t.status === 404) {
              setLoading(false)
              setError(errorMessage)
              return
            }
            if (t.status === 457) {
              setLoading(false)
              setError('this is a mock, manage subscription feature not supported')
              return
            }
            tokenRef.current = t.body.portal_session
            return t.body.portal_session
          })
          .catch(e => {
            console.log(JSON.strigify(e))
          })
      }
      return Promise.resolve(tokenRef.current)
    })
    cbPortalRef.current = chargebeeInstance.createChargebeePortal()
    openChargebeePortal()
  }, [0])
  if (loading) {
    return <></>
  }
  if (error) {
    return <Error message={error} />
  }

  const openChargebeePortal = (e, sectionName) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    cbPortalRef.current.open({
      loaded: () => {},
      close: () => {
        logout()
      },
    })
  }
  return <></>
}

export default MyScreen
