import { Vtt } from '@learnn/sdk/src/api/utils'

export const useSubtitles = (
  subtitles: Vtt[],
  currentTime: number,
): {
  currentBlock: Vtt | null
} => {
  const getCurrentCue = (): number => {
    let left: number = 0
    let right: number = subtitles.length - 1

    while (left <= right) {
      const mid: number = Math.floor((left + right) / 2)

      if (currentTime >= subtitles[mid].start && currentTime <= subtitles[mid].stop) return mid
      if (currentTime <= subtitles[mid].start) right = mid - 1
      else left = mid + 1
    }

    return -1
  }

  const getBlockByTime = (): Vtt | null => {
    const currentIndex = getCurrentCue()

    if (currentIndex > -1) {
      return subtitles[currentIndex]
    }

    return null
  }

  return {
    currentBlock: getBlockByTime(),
  }
}
