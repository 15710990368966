import { ContinueWatchingPopupContext } from './popupContext'
import { useContext, useEffect } from 'react'
import { ResponsiveImage } from '../ResponsiveImage'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'

import style from './continueWatchingPopup.module.css'
import { useQuery } from 'react-query'
import { getLastProgress } from '../../controllers/Engagement'
import { matchQuery } from '../../utils/matchQuery'
import { useContinueWatchingPopup } from './useContinueWatchingPopup'
import { HorizontalStack, ProgressBar, Title, VerticalStack, useTheme } from '@learnn/designn'
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg'
import { Button } from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { CourseType } from '@learnn/sdk/src/api/course'

export type ContinueWatchingPopupData = {
  image: string
  progress: number
  type: CourseType | 'lesson'
  title: string
  courseTitle?: string
  onClick: Function
}

export const ContinueWatchingPopup = () => {
  const { state } = useContext(ContinueWatchingPopupContext)
  const { show, close, hide } = useContinueWatchingPopup()
  const lastProgressesQuery = useQuery('lastProgresses', getLastProgress)
  const { spacing } = useTheme()
  const history = useHistory()

  useEffect(() => {
    matchQuery(lastProgressesQuery, {
      loading: () => {},
      error: () => {},
      success: lastProgress => {
        show({
          image: lastProgress.coverImage,
          progress: lastProgress.progress,
          title: lastProgress.title,
          courseTitle: lastProgress.course?.shortTitle ?? lastProgress.course?.title,
          type: !lastProgress.course ? 'lesson' : lastProgress.course.type.slug,
          onClick: () => {
            hide()
            history.push(`/player/${lastProgress.id}`)
          },
        })
      },
    })
  }, [lastProgressesQuery.data])

  switch (state.show) {
    case true: {
      const renderTextByType = () => {
        switch (state.data.type) {
          case 'course':
            return { cta: 'Continua il Corso', badge: 'Corso', progress: 'COMPLETATA' }
          case 'webinar':
            return { cta: 'Continua il Webinar', badge: 'Webinar', progress: 'COMPLETATA' }
          case 'lesson':
            return { cta: 'Continua la Lezione', badge: 'Lezione', progress: 'COMPLETATA' }
        }
      }
      return (
        <div className={style.card} onClick={() => state.data.onClick()}>
          <div className={style.imageContainer}>
            <div className={style.playIcon}>
              <FontAwesomeIcon icon={faPlay} size='3x' color='#fff' />
            </div>
            <div className={style.overlay}>
              <div
                className={style.closeIconContainer}
                onClick={(e: any) => {
                  e.preventDefault()
                  e.stopPropagation()
                  close()
                }}>
                <CloseIcon className={style.closeIcon} />
              </div>
              <HorizontalStack>
                <Button className={style.mainCta} onClick={() => state.data.onClick()}>
                  <HorizontalStack>
                    <span>{renderTextByType().cta}</span>
                    <FontAwesomeIcon icon={faPlay} className={style.mainCtaIcon} />
                  </HorizontalStack>
                </Button>
              </HorizontalStack>
            </div>
            <ResponsiveImage wrapperClassName={style.borderedImage} src={state.data.image} />
          </div>
          <ProgressBar percentage={state.data.progress} transition />
          <div className={style.cardBody}>
            <VerticalStack>
              {state.data.type !== 'lesson' && (
                <div className={style.courseTitle}>
                  {renderTextByType().badge}: {state.data.courseTitle}
                </div>
              )}
              <Title variant='headingXs' mb={spacing.space_3} truncateLines={1} truncate>
                {state.data.title}
              </Title>
              <HorizontalStack>
                {state.data.type === 'lesson' && (
                  <div className={style.contentType}>{renderTextByType().badge}</div>
                )}
                {state.data.progress > 0 ? (
                  <div className={style.detailBadge}>
                    <ClockIcon className={style.deailIcon} />
                    <div>
                      {state.data.progress < 1 ? '1' : state.data.progress.toFixed()}%{' '}
                      {renderTextByType().progress}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </HorizontalStack>
            </VerticalStack>
          </div>
        </div>
      )
    }

    default: {
      return <></>
    }
  }
}
