import style from './fastScreen.module.css'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { ErrorMessage } from '../../components/ErrorMessage'
import { useContext } from 'react'
import { DynamicSectionsState } from '@learnn/sdk/src/api/dynamic-sections/types'
import * as O from 'fp-ts/Option'
import * as lib from 'fp-ts/lib/function'
import Loader from 'react-loader-spinner'
import { useReactive } from '../../utils/useReactive'
import { getDynamicSections } from '../../controllers/Fast'
import { DynamicSections } from '../../components/DynamicSections/DynamicSections'

interface IFastScreenProps {
  children?: any
}

export const Fast = (_: IFastScreenProps) => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId
  const { data, error } = useReactive<DynamicSectionsState, string>({
    observableFn$: getDynamicSections(userId),
    queryKey: 'fast',
  })

  const renderError = () => (
    <div className={style.container}>
      <ErrorMessage />
    </div>
  )
  const loading = () => (
    <div className={style.containerLoading}>
      <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
    </div>
  )
  const render = (dynamicState: DynamicSectionsState) => {
    return (
      <div className={style.container}>
        <DynamicSections state={dynamicState} />
      </div>
    )
  }
  return lib.pipe(
    error,
    O.fold(() => lib.pipe(data, O.fold(loading, render)), renderError),
  )
}
