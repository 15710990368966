import {useEffect} from "react";
import { useBodyClass } from "../../utils/hooks";
import {AppLayout} from "../../layouts/AppLayout/AppLayout";
import {ComingSoon} from "./ComingSoon";

export const ComingSoonScreen = () => {

  useBodyClass("app");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <AppLayout>
      <ComingSoon />
    </AppLayout>
  );
};
