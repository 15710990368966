import { FC } from 'react'
import style from './quizAttemptResults.module.css'
import ReactMarkdown from 'react-markdown'
import { SingleAccordion } from '../SingleAccordion'
import { QuizAttemptDetails } from '@learnn/sdk/src/api/quiz/'
import { useWindowSize } from '../../utils/hooks'
import correctIconLight from '../../assets/images/icons/passed_quiz.svg'
import wrongIcon from '../../assets/images/icons/wrong.svg'
import Confetti from 'react-confetti'
import cx from 'classnames'
import { ShareComponent } from '../SharePopup/Share'
import { AnswersResults } from './AnswersResults'

type QuizAttemptResults = {
  attempt: QuizAttemptDetails
  share?: {
    title: string
    body: string
    url: string
  }
  containerClassName?: string
  onBackClick(): void
  theme: 'light' | 'dark'
}

export const QuizAttemptResults: FC<QuizAttemptResults> = ({
  attempt,
  containerClassName,
  theme,
  share,
}: QuizAttemptResults) => {
  const size = useWindowSize()
  const { questions } = attempt

  return (
    <div className={cx([style.container, containerClassName])}>
      {attempt.passed ? (
        <div className={style[`${theme}_resultContainer`]}>
          <div className={style.resultInfo}>
            <p className={style.preTitle}>SFIDA SUPERATA</p>
            <h1 className={style[`${theme}_resultTitle`]}>Complimenti! 👏</h1>
            <p className={style[`${theme}_resultDescription`]}>
              Hai completato con successo la sfida.
            </p>
            <div className={style[`${theme}_quizStats`]}>
              <img src={correctIconLight} className={style.listIcon} />
              <span>Risposte corrette: </span>
              {`${attempt.correctAnswers}/${attempt.totalQuestions}`}
            </div>
            <div className={style[`${theme}_quizStats`]}>
              <img src={correctIconLight} className={style.listIcon} />
              <span>Punteggio: </span>
              {`${Math.round((attempt.correctAnswers / attempt.totalQuestions) * 100)}%`}
            </div>
          </div>
          {share && (
            <ShareComponent
              type='external'
              title={share.title}
              url={share.url}
              body={share.body}
              containerClassName={cx([style.shareContainer])}
            />
          )}
        </div>
      ) : attempt.passed === false ? (
        <div className={style[`${theme}_resultContainer`]}>
          <div className={style.resultInfo}>
            <p className={style.preTitle}>SFIDA NON SUPERATA</p>
            <h1 className={style[`${theme}_resultTitle`]}>Ci dispiace! 😔</h1>
            <p className={style[`${theme}_resultDescription`]}>
              Non hai completato con successo la sfida.
            </p>
            <div className={style[`${theme}_quizStats`]}>
              <img src={wrongIcon} className={style.listIcon} />
              <span>Risposte corrette: </span>
              {`${attempt.correctAnswers}/${attempt.totalQuestions}`}
            </div>
            <div className={style[`${theme}_quizStats`]}>
              <img src={wrongIcon} className={style.listIcon} />
              <span>Punteggio: </span>
              {`${Math.round((attempt.correctAnswers / attempt.totalQuestions) * 100)}%`}
            </div>
          </div>
          {share && (
            <ShareComponent
              type='external'
              title={share.title}
              url={share.url}
              body={share.body}
              containerClassName={cx([style.shareContainer])}
            />
          )}
        </div>
      ) : null}
      <h1 className={style[`${theme}_mainTitle`]}>Le tue risposte</h1>
      <div className={style[`${theme}_answersContainer`]}>
        {questions.length
          ? questions.map((q, index: number) => (
              <div
                className={style[`${theme}_questionContainer`]}
                key={`question-${q.question.questionId}`}>
                <div className={style[`${theme}_questionTitleContainer`]}>
                  <h4 className={style[`${theme}_questionNumber`]}>{`Domanda ${index + 1}`} </h4>
                  <ReactMarkdown>{q.question.text}</ReactMarkdown>
                </div>
                <div className={style.answersContainer}>
                  <AnswersResults question={q} theme={theme} />
                  {q.question.answerExplanation && q.question.answerExplanation !== 'null' ? ( //Workaround -> PBI to fix DB is present
                    <SingleAccordion title='Vedi spiegazione' opened={false}>
                      <div className={style[`${theme}_explanationContainer`]}>
                        <ReactMarkdown>{q.question.answerExplanation}</ReactMarkdown>
                        <a
                          href={
                            q.question.explanationLink && q.question.explanationLink !== 'null'
                              ? q.question.explanationLink
                              : `/player/${q.question.lessonId}/?t=${q.question.explanationTime}`
                          }
                          target='_blank'>
                          Approfondisci la spiegazione →
                        </a>
                      </div>
                    </SingleAccordion>
                  ) : null}
                </div>
              </div>
            ))
          : null}
      </div>
      {attempt.passed && (
        <Confetti
          width={size.width ? size.width - 20 : 0}
          height={size.height}
          recycle={false}
          numberOfPieces={1000}
          tweenDuration={6000}
        />
      )}
    </div>
  )
}
