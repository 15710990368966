import { FC } from 'react'
import style from '../../screens/HomeScreen/homeScreen.module.css'
import { DynamicSectionsState } from '@learnn/sdk/dist/api/dynamic-sections/types'
import { SearchResults } from '../SearchResults/SearchResults'
import { RenderSection } from './RenderSections'
import * as O from 'fp-ts/Option'
import { IHookResponse } from '../../utils/useReactive'
import { ProgressiveSearchContent } from '../../controllers/Search'
import * as lib from 'fp-ts/function'
import { ErrorMessage } from '../ErrorMessage'
import Loader from 'react-loader-spinner'
import useAnalytics from '../../analytics/useAnalytics'
import { VerticalStack } from '@learnn/designn'
import { useSearch } from '../SearchProvider/searchContext'

export type SearchComponentProps = {
  searchQuery: string
  searchResponse: IHookResponse<ProgressiveSearchContent, string>
  onClear: () => unknown
}
export const SearchComponent: FC<SearchComponentProps> = ({ searchQuery, searchResponse, onClear }) => {
  const { logSelectSearchItem } = useAnalytics()

  const renderError = () => (
    <div className={style.container}>
      {' '}
      <ErrorMessage />{' '}
    </div>
  )

  const render = (result: O.Option<ProgressiveSearchContent>) => (
    <div className={style.searchContainer}>
      {O.isNone(result) ? (
        <div className={style.containerLoading}>
          <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
        </div>
      ) : (
        <VerticalStack>
          <SearchResults
            onResultClick={c => logSelectSearchItem(searchQuery, c.type.slug, c.id, c.title, '')}
            onClear={onClear}
            data={result.value}
          />
        </VerticalStack>
      )}
    </div>
  )

  return (
    <VerticalStack>
      {
        lib.pipe(
          searchResponse.error,
          O.fold(() => render(searchResponse.data), renderError),
        )
      }
    </VerticalStack>
  )
}

export type DynamicSectionsProps = {
  state: DynamicSectionsState
}
export const DynamicSections = ({ state }: DynamicSectionsProps) => {
  const { query, setKeyword, searchResponse, search, resetSearch } = useSearch()

  if (searchResponse && query) {
    return (
      <SearchComponent
        searchQuery={query}
        searchResponse={searchResponse}
        onClear={() => {
          resetSearch()
          window.scrollTo({ top: 0, behavior: 'auto' })
        }}
      />
    )
  } else {
    return (
      <>
        {Object.keys(state).map((key, i) => {
          return (
            <RenderSection
              section={state[key]}
              key={`section-${i}`}
              onSearch={(keyword: string) => {
                setKeyword(keyword)
                search()
                window.scrollTo({ top: 0, behavior: 'auto' })
              }}
            />
          )
        })}
      </>
    )
  }
}
