import QueryString from 'query-string'

const useLinkNavigation = () => {
  return { parseUrl: parseUrl }
}
export default useLinkNavigation

export const LINK_TYPES = {
  COURSE: 'learnn://course/',
  LESSON: 'learnn://course/lesson/',
}

export const LINK_REGEX =
  /^learnn:\/\/(course|course\/lesson|course\/trailer|live|content)\/(\d+)((?:&?[^=&]*=[^=&]*)*)$/

const parseUrl = link => {
  if (!link) return

  if (link.startsWith('https://') || link.startsWith('http://') || link.startsWith('mailto:')) {
    return link
  } else if (link.startsWith('learnn://')) {
    const match = LINK_REGEX.exec(link)
    const queryParams = QueryString.parseUrl(link).query

    if (!match || match.length < 3 || isNaN(match[2])) return ''

    const id = parseInt(match[2])

    if (link.startsWith(LINK_TYPES.LESSON)) {
      if (queryParams.t) {
        return `/player/${id}/?t=${queryParams.t}`
      }
      return `/player/${id}`
    }

    //Least specific link must be placed at the end
    if (link.startsWith(LINK_TYPES.COURSE)) {
      return `/corso/${id}`
    }
  } else {
    console.log('Unknown URI: ' + link)
    return
  }
}
