import styles from './styles/transcriptionTab.module.css'
import cx from 'classnames'
import {Vtt} from '@learnn/sdk/src/api/utils'
import {useEffect, useRef} from 'react'
import {useSubtitles} from '../useSubtitles'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnglesDown} from "@fortawesome/free-solid-svg-icons";
import {useTranscriptionScroll} from "./useTranscriptionScroll";

type TranscriptionTabProps = {
  subtitles: Vtt[]
  viewTime: number
  onBlockClick: (seconds: number) => void
}
export const TranscriptionTab = ({subtitles, viewTime, onBlockClick}: TranscriptionTabProps) => {
  const {currentBlock} = useSubtitles(subtitles, viewTime)

  const scrollRef = useRef<HTMLSpanElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const {autoScrollActive, setAutoScrollActive} = useTranscriptionScroll({
    currentBlock,
    containerScrollRef: containerRef,
    blockScrollRef: scrollRef
  })

  const isCurrent = (id: number) => id === currentBlock?.id
  const setContainerHeight = () => {
    const header = document.getElementById('sidebar-header')
    const tabs = document.getElementById('tab-list')

    if (!containerRef.current || !header || !tabs) return

    const viewHeight = window.innerHeight
    const headerHeight = header.clientHeight
    const tabsHeight = header.clientHeight

    const newHeight = viewHeight - headerHeight - tabsHeight
    console.log({newHeight})
    //containerRef.current.style.maxHeight = `${newHeight}px`
  }


  useEffect(() => {
    setContainerHeight()
    window.addEventListener('resize', setContainerHeight)

    return () => {
      window.removeEventListener('resize', setContainerHeight)
    }
  }, [])


  return (
    <>
      <div ref={containerRef} className={styles.transcriptContainer}>
        <div className={styles.transcriptText}>
          {subtitles.map(vtt => {
            const finalDot = vtt.text.endsWith('.')

            return (
              <>
              <span
                ref={e => {
                  if (isCurrent(vtt.id)) {
                    scrollRef.current = e
                  }
                }}
                id={String(vtt.id)}
                key={vtt.id}
                onClick={() => onBlockClick(vtt.start)}
                className={cx([styles.transcriptBlock, isCurrent(vtt.id) ? styles.active : ''])}>
                {vtt.text}
              </span>{' '}
                {finalDot && (
                  <>
                    <br/>
                    <br/>
                  </>
                )}
              </>
            )
          })}
        </div>
      </div>
      {
        !autoScrollActive && (
          <div className={styles.followContainer}>
            <button
              className={styles.followButton}
              onClick={() => {
                setAutoScrollActive(true)
              }}>
              <FontAwesomeIcon icon={faAnglesDown} className='mr-3'/>
              Segui
            </button>
          </div>
        )
      }
    </>
  )
}
