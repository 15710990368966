import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useContext } from 'react'
import { getCourseAttempts } from '../../../../controllers/Quiz'
import ErrorScreen from '../../../Error'
import { useBodyClass } from '../../../../utils/hooks'
import { useQuery } from 'react-query'
import { matchQuery } from '../../../../utils/matchQuery'
import { GlobalProviderContext } from '../../../../components/GlobalProvider'
import { AttemptCard } from '../../../../components/AttemptCard'
import { useHistory } from 'react-router-dom'
import { toReadableDate } from '../../../../utils/time'
import style from './verified.module.css'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Title,
  useTheme,
  Box,
  Text,
  Button,
  Container,
  HorizontalStack,
  VerticalStack,
} from '@learnn/designn'
import env from '../../../../env.json'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'

export type VerifiedSectionProps = {
  course: CourseBaseInfoEngagement
}

export const VerifiedSection = ({ course }: VerifiedSectionProps) => {
  useBodyClass('app')
  const { spacing, colors, borders } = useTheme()
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId ?? ''
  const history = useHistory()
  const verifiedQuery = useQuery(['quizAttempts', course.id, userId], () =>
    getCourseAttempts(course.id, userId),
  )

  const COURSE_PROGRESS = course.progress

  return (
    <>
      {matchQuery(verifiedQuery, {
        loading: () => <Loader />,
        error: () => (
          <Container>
            <ErrorScreen />
          </Container>
        ),
        success: quizStatus => (
          <Container>
            <Title variant='heading2xl' mb={spacing.space_6}>
              Verifica
            </Title>
            {COURSE_PROGRESS >= env.QUIZ_ALLOW_THRESHOLD || quizStatus.passed ? (
              quizStatus && quizStatus.attempts.length ? (
                quizStatus.passed === false ? (
                  <>
                    {quizStatus.attempts.map((attempt, index) => (
                      <AttemptCard
                        attempt={attempt}
                        key={`attempt-${attempt.attemptId}`}
                        best={index === 0 && attempt.passed}
                      />
                    ))}
                    {quizStatus.retryDate < new Date(Date.now()) ? (
                      <div className={style.retryQuiz}>
                        <Button
                          variant='primary'
                          size='md'
                          label='Ritenta la verifica'
                          onPress={() => {
                            history.push(`/test/corso/${course.id}`)
                          }}
                        />
                      </div>
                    ) : (
                      <HorizontalStack
                        borderRadius={borders.radius.large}
                        bg={colors.card_background}
                        borderStyle='solid'
                        borderColor={colors.card_border}
                        borderWidth={borders.width.base}
                        p={spacing.space_20}
                        justifyContent='center'>
                        <VerticalStack>
                          <Text
                            variant='bodyMd'
                            lineHeightScale='5'>{`Potrai effettuare il prossimo tentativo il giorno ${toReadableDate(
                            quizStatus.retryDate,
                          )}.`}</Text>
                        </VerticalStack>
                      </HorizontalStack>
                    )}
                  </>
                ) : (
                  quizStatus.attempts.map((attempt, index) => (
                    <AttemptCard
                      attempt={attempt}
                      key={`attempt-${attempt.attemptId}`}
                      best={index === 0 && attempt.passed}
                    />
                  ))
                )
              ) : (
                <div>
                  <Text
                    mb={spacing.space_10}
                    color='primary'
                    variant='bodyMd'
                    lineHeightScale='5'>{`Tentativo disponibile, hai completato almeno l'${env.QUIZ_ALLOW_THRESHOLD}% del corso.`}</Text>
                  <Button
                    variant='primary'
                    squareBorder
                    size='md'
                    label='Tenta la verifica'
                    onPress={() => {
                      history.push(`/test/corso/${course.id}`)
                    }}
                  />
                </div>
              )
            ) : (
              <HorizontalStack
                borderRadius={borders.radius.large}
                bg={colors.card_background}
                borderStyle='solid'
                borderColor={colors.card_border}
                borderWidth={borders.width.base}
                p={spacing.space_20}
                justifyContent='center'>
                <HorizontalStack alignItems='center'>
                  <FontAwesomeIcon icon={faLock} size='xl' style={{ color: colors.text.primary }} />
                  <Text
                    ml={spacing.space_4}
                    alignment='start'
                    variant='bodyMd'
                    lineHeightScale='3'>{`Potrai effettuare un tentativo nel momento in cui avrai completato almeno l'${env.QUIZ_ALLOW_THRESHOLD}% del corso.`}</Text>
                </HorizontalStack>
              </HorizontalStack>
            )}
          </Container>
        ),
      })}
    </>
  )
}

const Loader = () => {
  const { spacing } = useTheme()
  const SkeletonComponent = ({ br }: { br?: number }) => (
    <Skeleton height='100%' borderRadius={br ?? 15} />
  )

  return (
    <div className={style.container}>
      <div className={style.list}>
        <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
          <Box mb={spacing.space_6} style={{ width: '200px', height: '35px' }}>
            <SkeletonComponent />
          </Box>
          {Array.from({ length: 4 }, (_, index) => {
            return (
              <div style={{ width: '100%' }} key={index}>
                <Skeleton
                  borderRadius='1rem'
                  width='100%'
                  height={75}
                  style={{ marginBottom: '2.5rem' }}
                />
              </div>
            )
          })}
        </SkeletonTheme>
      </div>
    </div>
  )
}
