"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function voidAnalytics() {
    const logEvent = () => Promise.resolve();
    const logSelectContent = async () => Promise.resolve();
    const logLogin = async () => Promise.resolve();
    const logScreenView = async () => Promise.resolve();
    const setUserId = async () => Promise.resolve();
    const setUserProperties = async () => Promise.resolve();
    const setUserProperty = async () => Promise.resolve();
    const setUserEmail = async () => Promise.resolve();
    return {
        logLogin,
        logSelectContent,
        logEvent,
        logScreenView,
        setUserId,
        setUserProperties,
        setUserProperty,
        setUserEmail,
        name: 'void',
    };
}
exports.default = voidAnalytics;
