import api from './api'

export const getNote = async (lessonId: string) => {
  const { note } = await api
  try {
    const data = await note.get(lessonId)

    return data ? { noteId: data.noteId, content: data.data } : null
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateNote = async (noteId: string, content: string) => {
  const { note } = await api
  try {
    return await note.updateNote(noteId, content)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createNote = async ({
  content,
  viewTime,
  courseId,
  lessonId,
}: {
  content: string
  viewTime: number
  courseId: string
  lessonId: string
}) => {
  const { note } = await api
  try {
    return await note.createNote(content, viewTime, courseId, lessonId)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const deleteNote = async (noteId: string) => {
  const { note } = await api

  try {
    await note.deleteNote(noteId)
  } catch (error) {
    console.log(error)
    throw error
  }
}
