import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import { CachePersistor } from 'apollo3-cache-persist';
import { isCmsContentVersionChanged } from './utils/cache';
export const getApolloClient = (API_URL, initialToken, ApiKey) => {
    let token = initialToken;
    const refreshToken = (t) => (token = t);
    const uri = API_URL.includes('/graphql') ? API_URL : `${API_URL}/graphql`;
    const httpLink = createHttpLink({
        fetch: fetch,
        uri,
    });
    const authLink = setContext(async (_, { headers }) => {
        if (!token) {
            return {
                headers: {
                    ...headers,
                    ...(ApiKey && { 'x-api-key': ApiKey }),
                },
            };
        }
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
                ...(ApiKey && { 'x-api-key': ApiKey, authorization: token }),
            },
        };
    });
    const defaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
        },
    };
    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions,
    });
    return { client, refreshToken, getToken: () => token };
};
export const getCmsApolloClient = async (API_URL, CMS_CONFIG_API_URL, apiToken, accessToken, persistentStorage) => {
    let token = accessToken;
    const refreshToken = (t) => (token = t);
    const uri = API_URL.includes('/graphql') ? API_URL : `${API_URL}/graphql`;
    const httpLink = createHttpLink({
        fetch: fetch,
        uri,
    });
    const authLink = setContext(async (_, { headers }) => {
        if (!token) {
            return {
                headers: {
                    ...headers,
                    authorization: `Bearer ${apiToken}`,
                },
            };
        }
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${apiToken}`,
                'x-identity-token': `${token}`,
            },
        };
    });
    const defaultOptions = {
        watchQuery: {
            fetchPolicy: persistentStorage ? 'cache-first' : 'network-only',
        },
        query: {
            fetchPolicy: persistentStorage ? 'cache-first' : 'network-only',
        },
    };
    const cache = new InMemoryCache({
        typePolicies: {
            CourseEntity: {
                merge: true,
            },
            ModuleCourseEntity: {
                merge: true,
            },
            LessonEntity: {
                merge: true,
            },
            UploadFileEntity: {
                merge: true,
            },
        },
    });
    if (persistentStorage) {
        let newPersistor = new CachePersistor({
            cache,
            storage: persistentStorage,
            debug: false,
            trigger: 'write',
            maxSize: false,
        });
        await newPersistor.restore();
        if (await isCmsContentVersionChanged(CMS_CONFIG_API_URL, persistentStorage)) {
            await cache.reset();
            await newPersistor.purge();
        }
    }
    const client = new ApolloClient({
        connectToDevTools: true,
        link: authLink.concat(httpLink),
        cache,
        defaultOptions,
    });
    return { client, refreshToken, getToken: () => token };
};
