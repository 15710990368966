import { Text, useTheme, Dropdown, FieldCheckboxMultiChoice, Box } from '@learnn/designn'
import { FormikProps } from 'formik'
import { FC } from 'react'
import { Profile } from '@learnn/sdk/src/api/profile'
import { SectionCard } from './SectionCard'

type ReminderEditorProps = {
  profile?: Profile
  formik: FormikProps<any>
}

const hourList = [
  { id: '1', label: '7:00' },
  { id: '2', label: '8:00' },
  { id: '3', label: '9:00' },
  { id: '4', label: '10:00' },
  { id: '5', label: '11:00' },
  { id: '6', label: '12:00' },
  { id: '7', label: '13:00' },
  { id: '8', label: '14:00' },
  { id: '9', label: '15:00' },
  { id: '10', label: '16:00' },
  { id: '11', label: '17:00' },
  { id: '12', label: '18:00' },
  { id: '13', label: '19:00' },
  { id: '14', label: '20:00' },
  { id: '15', label: '21:00' },
  { id: '16', label: '22:00' },
]

const daysList = [
  { label: 'Lunedì', value: 'Lunedì' },
  { label: 'Martedì', value: 'Martedì' },
  { label: 'Mercoledì', value: 'Mercoledì' },
  { label: 'Giovedì', value: 'Giovedì' },
  { label: 'Venerdì', value: 'Venerdì' },
  { label: 'Sabato', value: 'Sabato' },
  { label: 'Domenica', value: 'Domenica' },
]
export const ReminderEditor: FC<ReminderEditorProps> = ({ profile, formik }) => {
  const { colors, spacing } = useTheme()

  const isNever = (hours: string[]) => hours.includes('Mai')

  return (
    <SectionCard>
      <Box mb={spacing.space_10}>
        <Text textColor={colors.text.dimmed}>
          Queste informazioni ci aiutano a migliorare la tua esperienza su Learnn.
        </Text>
        <Text textColor={colors.text.dimmed}>
          Segli quando vuoi che ti ricordiamo di apprendere.
        </Text>
      </Box>
      <Text variant='bodyXs'>In che giorni?</Text>
      <FieldCheckboxMultiChoice
        options={daysList}
        value={isNever(formik.values.reminderDays) ? [] : formik.values.reminderDays}
        onChange={value => formik.setFieldValue('reminderDays', value.length ? value : ['Mai'])}
      />

      <Dropdown
        variant='dark'
        label='A che ora?'
        size='lg'
        items={hourList}
        onSelect={(value: any) => formik.setFieldValue('reminderTime', value.label)}
        selectedId={
          hourList.find(h => h.label.toUpperCase() === profile?.reminderTime?.toUpperCase())?.id
        }
        mt={spacing.space_10}
        mb={spacing.space_8}
      />
    </SectionCard>
  )
}
