import { VerticalStack, HorizontalStack, Text, Button } from '@learnn/designn'
import { FC } from 'react'
import { SectionTitle } from './SectionTitle'
import { membershipToken } from '../../../controllers/User'
import { chargebeeInstance } from '../../../utils/chargebee'

export type BillingInfoProps = { restrictAccess: boolean }

export const BillingInfo: FC<BillingInfoProps> = ({ restrictAccess }) => {

  const handleClick = async () => {
    try {
      const session = await membershipToken()
      if (session?.status === 404) {
        return
      }
      chargebeeInstance.setPortalSession(() => {
        return Promise.resolve(session?.body.portal_session)
      })
      const portal = chargebeeInstance.createChargebeePortal()
      portal.open({}, { sectionType: 'portal_edit_billing_address' })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <VerticalStack>
      <SectionTitle title='Dati di fatturazione' />
      <HorizontalStack>
        {restrictAccess ? (
          <Text variant='bodySm'>
            Il tuo abbonamento fa parte di un team, solo il proprietario del team può gestire la
            fatturazione
          </Text>
        ) : (
          <HorizontalStack style={{ cursor: 'pointer' }} alignItems='center'>
            <Button
              label='Visualizza o modifica dati di fatturazione'
              onPress={handleClick}
              variant='primary'
            />
          </HorizontalStack>
        )}
      </HorizontalStack>
    </VerticalStack>
  )
}
