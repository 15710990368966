import { useBodyClass } from '../../../../utils/hooks'
import { Title, useTheme, Container, Box } from '@learnn/designn'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import { IFrame } from '../../../../components/IFrame/IFrame'

export type DiscussionSectionProps = {
  course: CourseBaseInfoEngagement
}

export const DiscussionSection = ({ course }: DiscussionSectionProps) => {
  useBodyClass('app')
  const { spacing, borders } = useTheme()

  return (
<Container>
  <Title variant='heading2xl' mb={spacing.space_6}>
    Discussione
  </Title>
  <Box
    backgroundColor='#131313'
    padding={spacing.space_3}
    borderRadius={borders.radius.large}
    flex='1'
    marginBottom={spacing.space_16}
    height='100%'
  >
    <IFrame 
      fullHeight 
      styles={{flex: 1, height: '100%', marginBottom: '50px'}}
      url={`${course.circlePostUrl}?iframe=true&post=true&automatic_login=true`} 
    />
  </Box>
</Container>
  )
}
