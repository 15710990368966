import { gql } from '@apollo/client';
import { extractThumbUrlFromImage, hideDraft, extractCourseAuthorAndProfession, calculateLessonDuration, } from '../utils';
export const search = (client) => {
    const searchByKeyword = async (keyword) => {
        const searchResult = await client.query({
            query: SEARCH_RESULTS_BY_KEY,
            variables: { keyword },
        });
        const courses = searchResult.data?.searchLearnn.courses;
        const lessons = searchResult.data?.searchLearnn.lessons;
        return {
            courses: courses.filter(hideDraft).map(toCourse),
            lessons: lessons.filter(hideDraft).map(toLesson),
        };
    };
    const toCourse = (c) => {
        return {
            id: c.id,
            title: c.title,
            authors: c.authors?.map(extractCourseAuthorAndProfession),
            isWebinar: c.type.slug === 'webinar',
            type: c.type.slug,
            imageUrl: extractThumbUrlFromImage(c.coverImage, 'medium'),
            duration: `${c.totalHours ? c.totalHours + 'h' : ''} ${c.totalMinutes ? c.totalMinutes + 'min' : ''}`,
            lessonsCount: c.lessonsCount,
        };
    };
    const toLesson = (l) => {
        const { course } = l.search;
        return {
            id: l.id,
            title: l.title,
            authors: course.authors.map(extractCourseAuthorAndProfession),
            coverImageUrl: extractThumbUrlFromImage(l.cover ?? course.coverImage, 'medium'),
            duration: calculateLessonDuration(l.duration ?? 0),
            category: course.title,
            free: l.free ?? false,
        };
    };
    return {
        searchByKeyword: searchByKeyword,
    };
};
const SEARCH_RESULTS_BY_KEY = gql `
  query Search($keyword: String!) {
    searchLearnn(key: $keyword) {
      courses {
        id
        draft
        title
        description
        authors {
          id
          firstName
          lastName
          profession
        }
        coverImage {
          formats
          urlCdn
        }
        type {
          id
          title
          description
          slug
        }
        totalHours
        totalMinutes
        lessonsCount
        categories {
          id
          title
        }
      }
      lessons {
        id
        draft
        title
        free
        cover {
          formats
          urlCdn
        }
        duration
        search
      }
    }
  }
`;
