import { extractCourseAuthorAndProfession, extractThumbUrlFromImage } from '../utils';
export const pathDtoConverter = (i) => {
    return {
        id: i.id,
        slug: i.slug,
        summary: i.summary,
        description: i.description,
        image: i.image && i.image.length > 0 ? extractThumbUrlFromImage(i.image[0], 'large') : null,
        title: i.title,
        createdAt: i.createdAt,
        courses: i.courses?.length > 0
            ? i.courses.reduce((a, m) => {
                if (!m.course)
                    return a;
                try {
                    const module = {
                        id: m.course.id.toString(),
                        title: m.title ?? m.course.title,
                        sectionTitle: m.sectionTitle,
                        image: extractThumbUrlFromImage(m.course.coverImage, 'large') ?? '',
                        type: m.course.type,
                        durationHour: m.course.totalHours ?? 0,
                        durationMinutes: m.course.totalMinutes ?? 0,
                        durationString: `${m.course.totalHours ? m.course.totalHours + 'h' : ''} ${m.course.totalMinutes ? m.course.totalMinutes + 'min' : ''}`,
                        lessonsCount: m.course.lessonsCount,
                        author: m.course.authors
                            .map(a => {
                            const authorObj = extractCourseAuthorAndProfession(a);
                            return `${authorObj.authorName} - ${authorObj.profession}`;
                        })
                            .join(', '),
                    };
                    return [...a, module];
                }
                catch (e) {
                    console.error(e);
                    return a;
                }
            }, [])
            : [],
    };
};
