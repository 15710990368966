import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import app_store from '../assets/images/app_store.png'
import google_play from '../assets/images/google_play.png'
import { Box, HorizontalStack, Text, useTheme } from '@learnn/designn'
import { spacing } from '@learnn/designn/build/theme/tokens/spacing'

function Footer({ type }: { type?: 'dark' | 'light' | 'dark-minified' }) {
  const { colors } = useTheme()
  switch (type) {
    case 'dark': return (
      <div className='footer-container-black'>
        <HorizontalStack alignItems='center' justifyContent='space-between' mx={'1rem'}>
          <div className='copyright pl-md-0 mobile-center'>
            {`Copyright © ${new Date().getFullYear()} Learnn. Tutti i diritti riservati.`}
          </div>
          <div
            className='copyright pl-md-0  d-md-flex flex-row justify-content-end mobile-center'>
            <a href='https://apps.apple.com/it/app/learnn/id1527464531'>
              <img src={app_store} className='footer-logo mb-3 mb-md-2 mr-2' />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.learnn.app'>
              <img src={google_play} className='footer-logo mb-3 mb-md-2' />
            </a>
          </div>
        </HorizontalStack>
      </div>
    )
    case 'dark-minified': return (
        <HorizontalStack flexWrap={'wrap'} backgroundColor='rgba(0, 0, 0, 0.8)' alignItems='center' justifyContent='center' py={spacing.space_5} borderWidth={'1px 0 0 0'} borderStyle='solid' borderColor={colors.edge}>
          <Text textColor={colors.text.dimmed} variant='bodyXs'>
            {`Copyright © ${new Date().getFullYear()} Learnn. Tutti i diritti riservati.`}
          </Text>
          <Box ml={spacing.space_1} pl={spacing.space_1} borderWidth={'0 0 0 1px'} borderStyle='solid' borderColor={colors.edge} cursor='pointer' onClick={() => window.open('https://www.iubenda.com/privacy-policy/54290035', '_blank')}>
            <Text textColor={colors.text.dimmed} variant='bodyXs'>Privacy Policy</Text>
          </Box>
          <Box ml={spacing.space_1} pl={spacing.space_1} borderWidth={'0 0 0 1px'} borderStyle='solid' borderColor={colors.edge} cursor='pointer' onClick={() => window.open('https://www.iubenda.com/privacy-policy/54290035/cookie-policy', '_blank')}>
            <Text textColor={colors.text.dimmed} variant='bodyXs'>Cookie Policy</Text>
          </Box>
          <Box ml={spacing.space_1} pl={spacing.space_1} borderWidth={'0 0 0 1px'} borderStyle='solid' borderColor={colors.edge}cursor='pointer' onClick={() => window.open('https://learnn.com/termini-e-condizioni/', '_blank')}>
            <Text textColor={colors.text.dimmed} variant='bodyXs'>Termini e Condizioni</Text>
          </Box>
        </HorizontalStack>
    )
    default:
    case 'light': return (
      <Container fluid className='footer p-4 '>
        <Row>
          <Col>
            <div className='footer-text'>{`Copyright © ${new Date().getFullYear()} Learnn. Tutti i diritti riservati.`}</div>
          </Col>
        </Row>
      </Container>
    )
  }

}

export default Footer
