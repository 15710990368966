import { Provider } from "@learnn/analytics/src/providers"

export default function mergeAnalyticsProviders(analyticsProviders: Provider[]) {
  const logEvent = async (name: string, params: { [key: string]: any }) => {
    await Promise.all(analyticsProviders.map(x => x.logEvent(name, params)))
  }
  const logSelectContent = async (contentType: string, itemId: string) => {
    await Promise.all(analyticsProviders.map(x => x.logSelectContent(contentType, itemId)))
  }
  const logLogin = async (method?: string) => {
    await Promise.all(analyticsProviders.map(x => x.logLogin(method)))
  }
  const logScreenView = async (screenClass: string, screenName: string) => {
    await Promise.all(analyticsProviders.map(x => x.logScreenView(screenClass, screenName)))
  }
  const setUserProperties = async (properties: { [key: string]: any }) => {
    await Promise.all(analyticsProviders.map(x => x.setUserProperties && x.setUserProperties(properties)))
  }
  const setUserProperty = async (name: string, value: any) => {
    await Promise.all(analyticsProviders.map(x => x.setUserProperty && x.setUserProperty(name, value)))
  }
  const setUserId = async (id: string) => {
    await Promise.all(analyticsProviders.map(x => x.setUserId && x.setUserId(id)))
  }
  const setUserEmail = async (email: string) => {
    await Promise.all(analyticsProviders.map(x => x.setUserEmail && x.setUserEmail(email)))
  }
  return {
    logLogin,
    logSelectContent,
    logEvent,
    logScreenView,
    setUserId,
    setUserEmail,
    setUserProperties,
    setUserProperty,
    name: analyticsProviders.map(x => x.name).join('_'),
  }
}
  