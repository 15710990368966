import styles from './popupHeader.module.css'
import { FC, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Path } from '@learnn/sdk/dist/api/paths/types'
import { faBookBookmark } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as VerifiedLogo } from '../../../assets/images/icons/verified.svg'
import { GlobalProviderContext } from '../../../components/GlobalProvider'
import { extractConfiguration } from '../../../utils/data'
import { Button } from '@learnn/designn'
import { ReactComponent as ClockIcon } from '../../../assets/images/icons/clock.svg'
import { spacing } from '@learnn/designn/build/theme/tokens/spacing'

export type PopupHeaderProps = {
  path: Path
  onCtaClick: () => unknown
}
export const PopupHeader: FC<PopupHeaderProps> = ({ path, onCtaClick }) => {
  const { summary } = path
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)

  const totalHoursDuration = Math.round(
    path.courses.reduce((prev: number, i) => prev + i.durationHour + i.durationMinutes / 60, 0),
  )
  const quizAvailability = configuration?.quizAvailability ?? {}
  const verifiedCount = path.courses
    .filter(c => c.type.slug === 'course')
    .filter(course => !!quizAvailability[course.id]).length
  const details = [
    {
      icon: <FontAwesomeIcon icon={faBookBookmark} className={styles.detailIcon} size='sm' />,
      value: `${path.courses.length} Corsi e Webinar`,
    },
    {
      icon: <ClockIcon className={styles.detailIcon} />,
      value: `${totalHoursDuration}H`,
    },
    ...(verifiedCount > 0
      ? [
          {
            icon: <VerifiedLogo className={styles.detailIcon} />,
            value: `${verifiedCount} ${verifiedCount === 1 ? 'Verifica' : 'Verifiche'}`,
          },
        ]
      : []),
  ]

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <div className={styles.contentType}>Percorso</div>

        {details.map(detail => (
          <div className={styles.detailBadge}>
            {detail.icon}
            <div>{detail.value}</div>
          </div>
        ))}
      </div>

      <div className={styles.summary}>{summary}</div>

      <div>
        <Button
          variant='primary'
          size='lg'
          label='Esplora il percorso ➔'
          mb={spacing.space_4}
          onPress={onCtaClick}
        />
      </div>
    </div>
  )
}
