import { ResetPassword } from './ResetPassword'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { LiveChat } from '../../components/LiveChat'


export const ResetPasswordScreen = () => {
  const isLocalhost =
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

  let manyChatScript
  if (!isLocalhost) {
    manyChatScript = <LiveChat />
  }

  return (
    <>
      {manyChatScript}
      <AppLayout>
        <ResetPassword />
      </AppLayout>
    </>
  );
}


