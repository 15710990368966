import {useState} from "react";
import * as O from 'fp-ts/Option'

export type InfiniteQuizState = {
  goalAchievedCounter: number,
  currentAnswersStreak: number,
  correctAnswersToReach: number,
  maxGoalAchievedCounter: number,
  maxAnswersStreak: number,
  totalCorrectAnswers: number,
  totalWrongAnswers: number,
  startTime: O.Option<Date>,
  endTime: O.Option<Date>
}

export type UseInfiniteQuizControllers = {
  start: () => void;
  end: () => void;
  addWrongAnswer: () => void;
  addCorrectAnswer: () => void;
  state: InfiniteQuizState
}

export type InfiniteQuizControllersProps = {
  onGoalAchieved: () => void;
  onStreakLose: (finalState: InfiniteQuizState) => void;
  goals: number[],
  defaultGoalIncrement: number
}

export const useInfiniteQuizControllers = ({
                                             onGoalAchieved,
                                             onStreakLose,
                                             goals,
                                             defaultGoalIncrement
                                           }: InfiniteQuizControllersProps): UseInfiniteQuizControllers => {
  const [state, setState] = useState<InfiniteQuizState>({
    goalAchievedCounter: 0, // contatore obiettivi raggiunti in serie
    currentAnswersStreak: 0, // contatore risposte corrette in serie
    correctAnswersToReach: goals[0], // numero di domande totali per passare al prossimo round
    maxGoalAchievedCounter: 0, // massima serie di obiettivi raggiunti
    maxAnswersStreak: 0, // massima serie di risposte corrette
    totalCorrectAnswers: 0, // totale risposte corrette
    totalWrongAnswers: 0, // totale risposte errate
    startTime: O.none,
    endTime: O.none,
  })

  const start = () => {
    setState((state) => ({
      ...state,
      startTime: O.some(new Date())
    }))
  }

  const end = () => {
    setState((state) => ({
      ...state,
      endTime: O.some(new Date())
    }))
  }

  const addCorrectAnswer = () => {
    const currentAnswersStreak = state.currentAnswersStreak + 1;

    if (currentAnswersStreak === state.correctAnswersToReach) {
      const goalAchievedCounter = state.goalAchievedCounter + 1;
      setState((state) => ({
        ...state,
        goalAchievedCounter: goalAchievedCounter,
        currentAnswersStreak: currentAnswersStreak,
        correctAnswersToReach: goals.length > goalAchievedCounter ? goals[goalAchievedCounter] : state.correctAnswersToReach + defaultGoalIncrement,
        maxGoalAchievedCounter: goalAchievedCounter > state.maxGoalAchievedCounter ? goalAchievedCounter : state.maxGoalAchievedCounter,
        maxAnswersStreak: currentAnswersStreak > state.maxAnswersStreak ? currentAnswersStreak : state.maxAnswersStreak,
        totalCorrectAnswers: state.totalCorrectAnswers + 1
      }))
      onGoalAchieved();
    } else {
      setState((state) => ({
        ...state,
        currentAnswersStreak: currentAnswersStreak,
        maxAnswersStreak: currentAnswersStreak > state.maxAnswersStreak ? currentAnswersStreak : state.maxAnswersStreak,
        totalCorrectAnswers: state.totalCorrectAnswers + 1
      }))
    }
  }

  const addWrongAnswer = () => {
    if (onStreakLose && state.goalAchievedCounter > 1) {
      onStreakLose(state)
    }
    setState((state) => ({
      ...state,
      goalAchievedCounter: 0,
      currentAnswersStreak: 0,
      correctAnswersToReach: goals[0],
      totalWrongAnswers: state.totalWrongAnswers + 1
    }))
  }

  return {
    start,
    end,
    state,
    addCorrectAnswer,
    addWrongAnswer,
  }
}