import {useEffect, useState} from "react";
import { Vtt } from '@learnn/sdk/src/api/utils'

export type UseTranscriptionScrollProps = {
  currentBlock: Vtt | null,
  containerScrollRef: any,
  blockScrollRef: any,
}
export type UseTranscriptionScroll = {
  autoScrollActive: boolean,
  setAutoScrollActive: (state: boolean) => void
}
export const useTranscriptionScroll =
  ({
     currentBlock,
    containerScrollRef,
    blockScrollRef
   }: UseTranscriptionScrollProps): UseTranscriptionScroll => {
    const [autoScrollActive, setAutoScrollActive] = useState(true)


    useEffect(() => {
      if (!blockScrollRef.current) return

      if (autoScrollActive)
        blockScrollRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    }, [currentBlock])

    useEffect(() => {

      containerScrollRef.current?.addEventListener('scroll', () => {
        containerScrollRef.current?.addEventListener('wheel', () => {
          if (autoScrollActive)
            setAutoScrollActive(false)
        })
      })

      return () => {
        containerScrollRef.current?.removeEventListener('scroll', () => {})
      }
    }, [])

    return {
      autoScrollActive,
      setAutoScrollActive: (state) => {
        setAutoScrollActive(state)
        if (blockScrollRef)
          blockScrollRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
      }
    }
  }