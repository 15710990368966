import { ExpertCard as ExpertCardDs } from "@learnn/designn"
import { FC } from "react"

export type ExpertCardProps = {
    coverImage: string
    firstName: string
    lastName: string
    profession?: string
    company?: string
    onClick(): void
}
export const ExpertCard: FC<ExpertCardProps> = ({ coverImage, firstName, lastName, profession, company, onClick }) => {

    const getExpertSubtitle = () => {
        if (!profession && !company)
            return ``
        if (profession && company)
            return `${profession} @ ${company}`
        if (profession)
            return profession
        if (company)
            return `@ ${company}`
        return ''
    }

    return (
            <ExpertCardDs 
                coverImage={coverImage}
                title={`${firstName} ${lastName}`}
                subtitle={getExpertSubtitle()}
                onClick={onClick}  
            />
            
        
    )
}