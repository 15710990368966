import {AppLayout} from "../../layouts/AppLayout/AppLayout"
import {useBodyClass} from "../../utils/hooks";
import { pipe } from 'fp-ts/function'
import {getPaths} from "../../controllers/Paths";
import Loader from "react-loader-spinner";
import {ErrorMessage} from "../../components/ErrorMessage";
import style from "./pathListScreen.module.css";
import {useHistory} from "react-router-dom";
import {CardsGrid} from "../../components/CardsGrid/CardsGrid";
import {PathListItem} from "./components/PathListItem";
import { useContext } from "react";
import { GlobalProviderContext } from "../../components/GlobalProvider";
import { useReactive } from "../../utils/useReactive";
import * as O from 'fp-ts/Option'
import { usePathPopup } from "src/components/PathPopup/usePathPopup";
import { Path } from "@learnn/sdk/dist/api/paths/types";


export const PathListScreen = () => {
  useBodyClass("app");
  const { show } = usePathPopup()
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId ?? ''
  const { data, error } = useReactive({
    observableFn$: getPaths(userId),
    queryKey: ['paths', userId],
  })

  const handlePathClick = (
    path: Path
  ) => {
    // TODO: log path click from slider
    show(path)
  }
  
  return (
    <AppLayout>
      {
        pipe(
          error,
          O.fold(
            () =>
              pipe(
                data,
                O.fold(
                  () => (
                    <div className={style.containerLoading}>
                      <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50}/>
                    </div>
                  ),
                  data => {
                    return (
                        <div className={style.container}>
                          <CardsGrid type="path">
                            {data.map((path, i) => (
                              <PathListItem
                                key={i}
                                path={path}
                                onClick={() => handlePathClick(path as Path)}
                                size="lg"
                              />
                            ))}
                          </CardsGrid>
                        </div>
                    )
                  },
                ),
              ),
            () => <ErrorMessage />,
          ),
        )
      }
    </AppLayout>
  )
}