import { CompletedCourses } from "./CompletedCourses";
import { useBodyClass } from "../../utils/hooks";
import {AppLayout} from "../../layouts/AppLayout/AppLayout";

export const CompletedCoursesScreen = () => {
  useBodyClass("app");
  return (
    <AppLayout>
      <CompletedCourses />
    </AppLayout>
  );
};
