import style from './styles/menu.module.css'
import { ReactComponent as FeedbacksLogo } from '../../assets/images/icons/feedback_menu.svg'
import { ReactComponent as ToolsLogo } from '../../assets/images/icons/tools.svg'
import { ReactComponent as OfflineLogo } from '../../assets/images/icons/offline-logo.svg'
import { ReactComponent as ExpertLogo } from '../../assets/images/icons/expert.svg'
import { ReactComponent as CommunityLogo } from '../../assets/images/icons/community.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUsers, faMessage, faHandshake } from '@fortawesome/free-solid-svg-icons'
import { MacroMenuItem, Menu } from './Menu'
import { FeaturesConfig } from '@learnn/sdk/src/api/feature-flag'
import aiIconPurple from '../../assets/images/icons/ai-purple.svg'

export type SetupMenuConfig = {
  featureFlag: FeaturesConfig
  onAiClick: Function
}

export const setupMenu = ({ featureFlag, onAiClick }: SetupMenuConfig): Menu => {
  return [
    {
      position: 'top',
      label: 'Home',
      icon: <FontAwesomeIcon icon={faHome} className={style.macroMenuItemIcon} />,
      subs: [
        {
          type: 'item',
          label: '😍 Esplora',
          url: '/',
        },
        {
          type: 'item',
          label: '⚡️ Fast',
          url: '/fast',
        },
        {
          type: 'item',
          label: '🚀 Percorsi',
          url: '/paths',
        },
        {
          type: 'item',
          label: '📋 Tutti i corsi',
          url: '/contenuti',
        },
        {
          type: 'item',
          label: '🔥 In Arrivo',
          url: '/in-arrivo',
        },
        {
          type: 'item',
          label: '⭐️ Sessioni Live',
          url: '/live',
        },
        {
          type: 'item',
          label: '🥇 Sfide',
          url: '/infinite',
        },
        {
          type: 'group',
          label: 'MyLearnn',
        },
        {
          type: 'item',
          label: '🔋 Progressi',
          url: '/progressi',
        },
        {
          type: 'item',
          label: '🔖 Salvati',
          url: '/salvati',
        },
        {
          type: 'item',
          label: '☑️ Completati',
          url: '/completati',
        },
        {
          type: 'item',
          label: '✅ Verificati',
          url: '/corsi/verified',
        },
      ],
    },
    {
      position: 'top',
      label: 'Team',
      icon: <FontAwesomeIcon icon={faUsers} className={style.macroMenuItemIcon} />,
      url: '/dashboard-b2b',
    },
    {
      position: 'top',
      label: 'Expert',
      icon: <ExpertLogo className={style.macroMenuItemIconSvg} />,
      subs: [
        {
          type: 'item',
          label: '✅ Scegli Expert',
          url: '/expert',
        },
        {
          type: 'item',
          label: '🦸 Scopri',
          url: '/scopri-expert',
        },
        {
          type: 'item',
          label: '❤️ Missione',
          url: '/missione-expert',
        },
        {
          type: 'item',
          label: '🔒 Tutela',
          url: '/garanzia-expert',
        },
        {
          type: 'item',
          label: '🏆 Selezione',
          url: '/selezione-expert',
        },
        {
          type: 'item',
          label: '😍 Candidati',
          url: '/candidati-expert',
        },
        {
          type: 'item',
          label: '🚀 Aziende',
          url: '/aziende-expert',
        },
      ],
    },
    {
      position: 'top',
      label: 'Community',
      icon: <FontAwesomeIcon icon={faMessage} className={style.macroMenuItemIcon} />,
      url: '/community',
    },
    {
      position: 'top',
      label: 'Tools',
      icon: <ToolsLogo className={style.macroMenuItemIconSvg} />,
      url: '/tools',
    },
    {
      position: 'top',
      label: 'Partner',
      icon: <FontAwesomeIcon icon={faHandshake} className={style.macroMenuItemIcon} />,
      url: '/partner',
    },
    {
      position: 'top',
      label: 'Offline',
      icon: <OfflineLogo className={style.macroMenuItemIconSvg} />,
      subs: [
        {
          type: 'item',
          label: '🎟️ Biglietto',
          url: '/offline/biglietto',
        },
        {
          type: 'item',
          label: 'ℹ️​ Info',
          url: '/offline/info',
        },
        {
          type: 'item',
          label: '👥 Community',
          url: '/offline/community',
        },
      ],
    },
    ...(featureFlag['ai-assistant'] && false
      ? [
          {
            position: 'top',
            label: 'AI',
            icon: <div className={style.macroMenuItemIcon}>
                    <img
                      src={aiIconPurple} 
                      alt="AI Icon"
                      style={{ width: '1.8rem', height: '1.8rem', marginBottom: '2rem' }}
                    />
                </div>,
            
           
            onClick: onAiClick,
          } as MacroMenuItem,
        ]
      : []),
    {
      position: 'bottom',
      label: 'Profilo',
      icon: <FeedbacksLogo className={style.macroMenuItemIconSvg} />,
      subs: [
        {
          type: 'item',
          label: 'Profile',
          url: '/modifica-profilo',
        },
        {
          type: 'item',
          label: 'Preferenze',
          url: '/preferenze',
        },
        {
          type: 'item',
          label: 'Reminder',
          url: '/reminder',
        },
        {
          type: 'item',
          label: 'Gestione abbonamento',
          url: '/abbonamento',
        },
        /*
          {
            label: 'Integrazioni',
            url: '/integrations',
          },
          */
        {
          type: 'item',
          label: 'Cambia password',
          url: '/reset-password',
        },
        {
          type: 'item',
          label: 'Invita un amico',
          url: '/invita',
        },
        {
          type: 'item',
          label: 'FAQ e assistenza',
          url: '/faq',
        },
        {
          type: 'item',
          label: 'Termini del servizio',
          url: 'https://learnn.com/termini-e-condizioni/',
          external: true,
        },
        {
          type: 'item',
          label: 'Logout',
          url: '/logout',
        },
      ],
    },
  ]
}
