const CMS_CONTENT_VERSION_KEY = 'CMS_CONTENT_VERSION';
export const getCmsContentVersion = async (CMS_CONFIG_API_URL) => {
    try {
        const fetchResult = await fetch(`${CMS_CONFIG_API_URL}/content-version`);
        const response = await fetchResult.json();
        return new Date(response.contentVersion);
    }
    catch (e) {
        return new Date(0);
    }
};
export const getLocalContentVersion = async (persistentStorage) => {
    const localStorage = await persistentStorage.getItem(CMS_CONTENT_VERSION_KEY);
    if (localStorage)
        return new Date(localStorage);
    else
        return new Date(0);
};
export const isCmsContentVersionChanged = async (CMS_CONFIG_API_URL, persistentStorage) => {
    const currentCmsContentVersion = await getCmsContentVersion(CMS_CONFIG_API_URL);
    const localContentVersion = await getLocalContentVersion(persistentStorage);
    await persistentStorage.setItem(CMS_CONTENT_VERSION_KEY, currentCmsContentVersion.toISOString());
    return currentCmsContentVersion > localContentVersion;
};
