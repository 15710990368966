import { FC } from 'react'
import { displayCardBrand, PaymentMethodType } from '../../../../controllers/Payment'
import style from './primaryCardPayment.module.css'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { faApplePay, faGooglePay, faPaypal } from '@fortawesome/free-brands-svg-icons'
import { matchQuery } from 'src/utils/matchQuery'
import { useCheckout } from '../../CheckoutContext'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { membershipToken } from 'src/controllers/User'
import { chargebeeInstance } from '../../../../utils/chargebee'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/Option'
import { AlertBanner } from 'src/components/AlertBanner'
import { isMethodValid } from '../../utils'

type IPrimaryPaymentProps = {
}

export const PrimaryMethodPayment: FC<IPrimaryPaymentProps> = ({
}) => {
  const { defaultPaymentMethodQuery } = useCheckout()

  const onChangeCardClick = async () => {
    try {
      const session = await membershipToken()
      if (session?.status === 404) {
        return
      }
      chargebeeInstance.setPortalSession(() => {
        return Promise.resolve(session?.body.portal_session)
      })
      chargebeeInstance.setPortalCallbacks({
        close: () => {
          defaultPaymentMethodQuery.refetch()
        }
      })
      const portal = chargebeeInstance.createChargebeePortal()
      portal.open({}, { sectionType: 'portal_payment_methods' })
    } catch (error) {
      console.error(error)
    }
  }

  const renderPaymentMethod = (primaryPaymentMethod: PaymentMethodType) => {
    switch (primaryPaymentMethod.type) {
      case 'card':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faCreditCard} className={style.methodIcon} />
            <div className={style.methodInfo}>
              <span className={style.cardBrand}>
                {displayCardBrand(primaryPaymentMethod.card.brand)}
              </span>
              <span>termina con</span>
              <span className={style.last4}>{primaryPaymentMethod.card.last4}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica metodo
            </div>
          </div>
        )
      case 'paypal_express_checkout':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faPaypal} className={style.methodIcon} />
            <div className={style.methodInfo}>
              <span className={style.paymentEmail}>{primaryPaymentMethod.paypal.email}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica
            </div>
          </div>
        )
      case 'google_pay':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faGooglePay} className={style.methodIcon} />
            <div className={style.methodInfo}>
              <span>termina con</span>
              <span className={style.last4}>{primaryPaymentMethod.card.last4}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica
            </div>
          </div>
        )
      case 'apple_pay':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faApplePay} className={style.methodIcon} />
            <div className={style.methodInfo}>
              <span>termina con</span>
              <span className={style.last4}>{primaryPaymentMethod.card.last4}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica
            </div>
          </div>
        )
      case 'direct_debit':
        return (
          <div className={style.methodContainer}>
            <FontAwesomeIcon icon={faBuildingColumns} className={style.methodIcon} />
            <div className={style.methodInfo}>
              Intestato a
              <span className={cx([style.cardBrand, 'ml-1'])}>
                {primaryPaymentMethod.bankAccount.nameOnAccount}
              </span>
              <br />
              <span>termina con</span>
              <span className={style.last4}>{primaryPaymentMethod.bankAccount.last4}</span>
            </div>
            <div
              className={cx([style.changeMethodText, style.hoverUnderline])}
              onClick={onChangeCardClick}>
              Modifica metodo
            </div>
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <div>
      <p className={cx([style.header, 'mb-1'])}>METODO DI PAGAMENTO</p>
      {
        matchQuery(defaultPaymentMethodQuery, {
          loading: () => (
            <PrimaryMethodPaymentSkeleton />
          ),
          error: () => (
            <div>
              <AlertBanner
                title='Inserisci un metodo di pagamento'
                message='Il tuo metodo di pagamento non è stato trovato'
                cta={
                  <div
                    className={cx([style.changeMethodAlertText, style.hoverUnderline])}
                    onClick={onChangeCardClick}>
                    Inserisci metodo
                  </div>
                }
              />
            </div>
          ),
          success: (data) => {
            return (
              <div>
                {
                  pipe(
                    data,
                    O.fold(
                      () => (
                        <div>
                          <AlertBanner
                            title='Inserisci un metodo di pagamento'
                            message='Il tuo metodo di pagamento non è stato trovato'
                            cta={
                              <div
                                className={cx([style.changeMethodAlertText, style.hoverUnderline])}
                                onClick={onChangeCardClick}>
                                Inserisci metodo
                              </div>
                            }
                          />
                        </div>
                      ),
                      (data) => {
                        if (isMethodValid(data.status))
                          return (
                            <div>
                              <p className={cx([style.subtitle])}>
                                Questo è il metodo di pagamento preferito impostato
                              </p>
                              {renderPaymentMethod(data)}
                            </div>
                          )
                        else
                          return (
                            <div>
                              <AlertBanner
                                title='Aggiorna il tuo metodo di pagamento'
                                message='Il tuo metodo di pagamento non risulta valido'
                                cta={
                                  <div
                                    className={cx([style.changeMethodAlertText, style.hoverUnderline])}
                                    onClick={onChangeCardClick}>
                                    Aggiorna metodo
                                  </div>
                                }
                              />
                              {renderPaymentMethod(data)}
                            </div>
                          )
                      }
                    )
                  )
                }
              </div>
            )
          }
        })
      }
    </div>
  )
}

export const PrimaryMethodPaymentSkeleton = () => {
  return (
    <SkeletonTheme baseColor='#a3a3a3' highlightColor='#cacaca'>
      <Skeleton style={{ width: '100%', height: '20px' }}></Skeleton>
      <div style={{ marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Skeleton style={{ width: '200px', height: '20px' }}></Skeleton>
        <Skeleton style={{ width: '50px', height: '20px' }}></Skeleton>
      </div>
    </SkeletonTheme>
  )
}