import { FC } from 'react'
import style from './simplePlayer.module.css'
import { useReactPlayer } from '../LessonPlayer/useReactPlayer'
import ReactPlayer from 'react-player'
import { Button } from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faVolumeMute,
  faPause,
  faPlay,
  faRotateLeft,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons'
import { ProgressBar } from '@learnn/designn'

export type SimplePlayerProps = {
  url: string
  startTime?: number
  playerClassName?: string
}
export const SimplePlayer: FC<SimplePlayerProps> = ({ url, playerClassName, startTime = 0 }) => {
  const { ref, state, reactPlayerProps, controls } = useReactPlayer({
    playedSeconds: startTime,
    played: startTime,
    playing: true,
    muted: true,
    loop: true,
  })

  const enableAudio = () => {
    controls.seekTo(0)
    controls.toggleMuted()
  }

  const actionByStatus = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (state.playing) {
      if (state.muted) enableAudio()
      else controls.pause()
    } else controls.play()
  }

  const renderButton = () => {
    if (state.playing) {
      if (state.muted) {
        return (
          <Button
            className={style.playButton}
            onClick={e => {
              e.stopPropagation()
              enableAudio()
            }}>
            <FontAwesomeIcon icon={faVolumeMute} className={style.icon} />
          </Button>
        )
      } else {
        return (
          <Button
            className={style.playButton}
            onClick={e => {
              e.stopPropagation()
              controls.pause()
            }}>
            <FontAwesomeIcon icon={faPause} className={style.icon} />
          </Button>
        )
      }
    } else {
      return (
        <Button
          className={style.playButton}
          onClick={e => {
            e.stopPropagation()
            controls.play()
          }}>
          <FontAwesomeIcon icon={faPlay} className={style.icon} />
        </Button>
      )
    }
  }

  return (
    <div className={style.container}>
      <ReactPlayer
        ref={ref}
        url={url}
        className={[style.player, playerClassName]}
        {...reactPlayerProps}
        width='100%'
        height='100%'
        style={{display: 'flex'}}
        controls={false}
        onReady={() => (startTime ? controls.seekTo(startTime) : null)}
        playsinline
      />
      <div className={style.bottomControllers} onClick={actionByStatus}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {state.playing && !state.muted && (
            <>
              <Button
                className={style.seekButton}
                onClick={e => {
                  e.stopPropagation()
                  controls.seekTo(state.playedSeconds ? state.playedSeconds - 15 : 0)
                }}>
                <FontAwesomeIcon icon={faRotateLeft} className={style.icon} />
              </Button>
              <Button
                className={style.seekButton}
                onClick={e => {
                  e.stopPropagation()
                  controls.seekTo(state.playedSeconds ? state.playedSeconds + 15 : 15)
                }}>
                <FontAwesomeIcon icon={faRotateRight} className={style.icon} />
              </Button>
            </>
          )}

          {renderButton()}
        </div>
      </div>
      <ProgressBar percentage={state.playedPercentage}/>
    </div>
  )
}
