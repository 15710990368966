import React, {useEffect, useState} from 'react';
import {useBodyClass} from "../../utils/hooks";
import IframeResizer from 'iframe-resizer-react'
import style from './iframe.module.css';
import Loader from "react-loader-spinner";

type IFrameProps = {
  url: string,
  fullHeight?: boolean
  styles?: React.CSSProperties
}
export const IFrame: React.FC<IFrameProps> = ({url, styles, fullHeight = false}) => {
  useBodyClass("app");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const iframe = document.getElementById('iframe');
    iframe && iframe.addEventListener('load', () => {
      setLoading(false)
    }, true)
  }, [])

  return (
    <>
      {
        loading && (
          <div className={style.containerLoading}>
            <Loader type="ThreeDots" color="#FFFFFF" height={50} width={50}/>
          </div>
        )
      }
      {
        fullHeight ? (
          <iframe
            style={{display: loading ? 'none' : 'block', ...styles}}
            height={'100% !important'}
            src={url}
            className={style.container}
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
            id='iframe'
          />
        ) : (
          <IframeResizer
            style={{display: loading ? 'none' : 'block', overflow: 'hidden', ...styles}}
            heightCalculationMethod={'lowestElement'}
            src={url}
            inPageLinks={false}
            className={style.container}
            allowFullScreen
            scrolling={false}
            log
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
            id='iframe'
          />
        )
      }
    </>
  )
}