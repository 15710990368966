import { FC, useState } from 'react'
import { Carousel } from '../Carousel'
import { CarouselSlide } from '../Carousel/CarouselSlide'
import prevImage from '../../assets/images/icons/arrow-circle-left.svg'
import nextImage from '../../assets/images/icons/arrow-circle-right.svg'
import {
  HorizontalStack,
  Title,
  Box,
  useTheme,
  Button,
  CircularButton,
  Tooltip,
} from '@learnn/designn'
import style from './customCarousel.module.css'
import { CarouselKinds } from './Carousel'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faEdit } from '@fortawesome/free-solid-svg-icons'
import 'react-loading-skeleton/dist/skeleton.css'
import { usePreferencesPopup } from '../PreferencesPopup/usePreferencesPopup'

const generateCarouselId = () => {
  const length = 10
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export type CustomCarouselProps<T> = {
  title: string
  renderSlide: (item: T) => JSX.Element
  data?: T[]
  singleSlide?: boolean
  carouselKind: CarouselKinds
  onShowAll?: Function
  showPreferences?: boolean
} & typeof Box

export const CustomCarousel: FC<CustomCarouselProps<any>> = ({
  title,
  renderSlide,
  data,
  singleSlide,
  carouselKind,
  onShowAll,
  showPreferences,
  ...props
}) => {
  const { spacing } = useTheme()
  const [id, _setId] = useState(generateCarouselId())
  const [isLastPage, setIsLastPage] = useState(false)
  const [isFirstPage, setIsFirstPage] = useState(true)
  const { show: showPopup } = usePreferencesPopup()

  if ((!data && !singleSlide) || data?.length === 0) return <></>

  return (
    <Box mb={spacing.space_10} {...props}>
      <HorizontalStack justifyContent='space-between' mb={spacing.space_4}>
        <HorizontalStack alignItems='center'>
          <Title variant='headingLg'>{title}</Title>
          {showPreferences && (
            // <Tooltip
            //   label='Personalizza i risultati'
            //   position='bottom'
            //   variant='dark'
            //   width={spacing.space_28}>
            //   <CircularButton
            //     variant='tertiary'
            //     icon={<FontAwesomeIcon size='sm' icon={faEdit} />}
            //     onPress={() => showPopup()}
            //     size='md'
            //   />
            // </Tooltip>
            <CircularButton
              variant='tertiary'
              icon={<FontAwesomeIcon size='sm' icon={faEdit} />}
              onPress={() => showPopup()}
              size='md'
            />
          )}
        </HorizontalStack>
        <HorizontalStack alignItems='center'>
          {onShowAll && (
            <Button
              variant='tertiary'
              label='Vedi tutto'
              icon={<FontAwesomeIcon size='xs' icon={faChevronRight} />}
              iconPosition='right'
              onPress={() => onShowAll()}
            />
          )}
          {data && data.length > 3 ? (
            <>
              <img
                className={`${style.swipePrev} ${!isFirstPage ? style.active : null} prev-${id}`}
                src={prevImage}
              />
              <img
                className={`${style.swipePrev} ${!isLastPage ? style.active : null} next-${id}`}
                src={nextImage}
              />
            </>
          ) : (
            <></>
          )}
        </HorizontalStack>
      </HorizontalStack>
      <Carousel
        options={{ speed: 600 }}
        navigation={{
          prevEl: `.prev-${id}`,
          nextEl: `.next-${id}`,
        }}
        onSlideChange={swiper => {
          if (swiper.isBeginning) {
            setIsFirstPage(true)
            setIsLastPage(false)
            return
          }
          if (swiper.isEnd) {
            setIsLastPage(true)
            setIsFirstPage(false)
            return
          }
          setIsFirstPage(false)
          setIsLastPage(false)
        }}
        kind={carouselKind}>
        {singleSlide ? (
          <CarouselSlide>{renderSlide()}</CarouselSlide>
        ) : (
          data &&
          data.map((item, i) => (
            <CarouselSlide key={`slide-${id}-${i}`}>{renderSlide(item)}</CarouselSlide>
          ))
        )}
      </Carousel>
    </Box>
  )
}

export const CustomCarouselLoading = (carouselKind: CarouselKinds = 'course') => {
  const { spacing } = useTheme()

  return (
    <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
      <Box mb={spacing.space_12}>
        <HorizontalStack justifyContent='space-between'>
          <div style={{ width: 200, height: 50 }}>
            <Skeleton />
          </div>
          <HorizontalStack>
            <div className={`${style.swipePrev}`}>
              <Skeleton />
            </div>
            <div className={`${style.swipePrev}`}>
              <Skeleton />
            </div>
          </HorizontalStack>
        </HorizontalStack>
        <Carousel kind={carouselKind}>
          {Array.from({ length: 4 }, (_, index) => {
            return (
              <CarouselSlide key={`placeholder-path-${carouselKind}-${index}`}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                  }}>
                  <Skeleton height={170} borderRadius={15} />
                </div>
              </CarouselSlide>
            )
          })}
        </Carousel>
      </Box>
    </SkeletonTheme>
  )
}
