import auth from '../utils/auth'
import env from '../env.json'

const bearerToken = () => {
  const token = auth.getToken()
  if (token) {
    return { Authorization: `Bearer ${token}` }
  }
  return {}
}

const authenticatedJsonPost = (url, body, headers) => {
  return jsonPost(url, body, { ...headers, ...bearerToken() })
}

const authenticatedJsonGet = (url, headers) => {
  return jsonGet(url, { ...headers, ...bearerToken() })
}

const jsonPost = async (url, body, headers) => {
  const result = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      ...{
        'Content-Type': 'application/json',
      },
      ...headers,
    }),
    body: JSON.stringify(body),
  })

  const status = result.status
  const text = await result.text()
  if (!text || text.trim() === '') {
    return undefined
  }

  const response = { status: status, body: JSON.parse(text) }

  return response
}

const jsonGet = async (url, headers) => {
  const result = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      ...{
        'Content-Type': 'application/json',
      },
      ...headers,
    }),
  })

  const status = result.status
  const text = await result.text()
  if (!text || text.trim() === '') {
    return undefined
  }

  const response = { status: status, body: JSON.parse(text) }

  return response
}

const authFetch = async (url, body, headers, kind = 'GET') => {
  try {
    const createBody = kind === 'POST' ? { body: JSON.stringify(body) } : {}
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        ...{
          'Content-Type': 'application/json',
        },
        ...headers,
        ...bearerToken(),
      }),
      ...createBody,
    })

    return response.json()
  } catch (error) {
    return error
  }
  return
}
export const fetchClient = {
  get: (slug, headers) =>
    authFetch(`${env.CMS_URL}${slug}`, null, { ...headers, ...bearerToken() }),
  post: (slug, body, headers) => authFetch(`${env.CMS_URL}${slug}`, body, { ...headers }, 'POST'),
}

export const restClient = {
  jsonGet,
  jsonPost,
  authenticatedJsonPost,
  authenticatedJsonGet,
  authFetch,
}
export default restClient
