import env from '../env.json'
import { API, LocalStorageWrapper } from '@learnn/sdk'
import auth from '../utils/auth'
import jwt from 'jsonwebtoken'

const createClientInjectingJwt = async () => {
  await auth

  const token = auth.getToken() ? await String(auth.getToken()) : undefined
  const api = await API.initialize({
    options: {
      cache: {
        persistentStorage: new LocalStorageWrapper(window.localStorage),
      },
      auth: {
        verifyOfflineToken: async (token, publicKey) => {
          return jwt.verify(
            token,
            publicKey,
            {
              algorithms: ['RS256'],
            },
          )
        }
      }
    },
    apiUrl: env.CMS_URL,
    recommenderUrl: env.RECOMMENDER_API_URL,
    quizUrl: env.QUIZ_API_URL,
    configUrl: env.QUIZ_CONFIG_API_URL,
    engagementUrl: env.ENGAGEMENT_URL,
    searchUrl: env.MEILI_SEARCH_API_URL,
    searchApiKey: env.MEILI_SEARCH_API_KEY,
    token: token,
    quizApiKey: env.QUIZ_API_KEY,
    configApiKey: env.QUIZ_CONFIG_API_KEY,
    engagementGraphUrl: env.ENGAGEMENT_GRAPH_URL,
    engagementGraphKey: env.ENGAGEMENT_GRAPH_KEY,
    keycloakRealmUrl: env.KEYCLOAK_REALM_URL,
    clientId: env.WEB_KEYCLOAK_CLIENT_ID,
    clientSecret: env.WEB_KEYCLOAK_CLIENT_SECRET,
    cmsApiToken: env.CMS_API_TOKEN,
    identityApiUrl: env.IDENTITY_API_URL,
    checkoutApiUrl: env.CHECKOUT_API_URL,
    profileApiUrl: env.PROFILE_API_URL,
    profileRestApiUrl: env.PROFILE_REST_API_URL,
    profileApiKey: env.PROFILE_API_KEY,
    myApiUrl: env.MY_API_URL,
    cmsConfigApiUrl: env.CMS_CONFIG_API_URL,
    webKeycloakJwtPublicKey: env.WEB_KEYCLOAK_JWT_PUBLIC_KEY,
    oramaCoursesUrl: env.ORAMA_COURSES_INDEX_ENDPOINT,
    oramaCoursesApiKey: env.ORAMA_COURSES_INDEX_PUBLIC_KEY,
    oramaCoursesSearchType: env.ORAMA_COURSES_SEARCH_TYPE,
    oramaCoursesSearchThreshold: env.ORAMA_COURSES_SEARCH_THRESHOLD,
    oramaLessonsUrl: env.ORAMA_LESSONS_INDEX_ENDPOINT,
    oramaLessonsApiKey: env.ORAMA_LESSONS_INDEX_PUBLIC_KEY,
    oramaLessonsSearchType: env.ORAMA_LESSONS_SEARCH_TYPE,
    oramaLessonsSearchThreshold: env.ORAMA_LESSONS_SEARCH_THRESHOLD,
    oramaPathsUrl: env.ORAMA_PATHS_INDEX_ENDPOINT,
    oramaPathsApiKey: env.ORAMA_PATHS_INDEX_PUBLIC_KEY,
    oramaPathsSearchType: env.ORAMA_PATHS_SEARCH_TYPE,
    oramaPathsSearchThreshold: env.ORAMA_PATHS_SEARCH_THRESHOLD,
    oramaExpertsUrl: env.ORAMA_EXPERTS_INDEX_ENDPOINT,
    oramaExpertsApiKey: env.ORAMA_EXPERTS_INDEX_PUBLIC_KEY,
    oramaExpertsSearchType: env.ORAMA_PATHS_SEARCH_TYPE,
    oramaExpertsSearchThreshold: env.ORAMA_PATHS_SEARCH_THRESHOLD
  });

  return api
}

export const api = createClientInjectingJwt()
export default api
