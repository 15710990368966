import { ProgressCard } from "../ProgressCard";
import {
  ComponentSectionDynamicLastProgresses,
  DynamicSectionsError,
  DynamicSectionsValue,
} from "@learnn/sdk/dist/api/dynamic-sections/types";
import * as F from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { CustomCarousel, CustomCarouselLoading } from "../Carousel/CustomCarousel";

export interface IProgressProps {
  data: DynamicSectionsValue<DynamicSectionsError, ComponentSectionDynamicLastProgresses>;
}

export const LastProgress = ({ data }: IProgressProps) => {
  const error = (error: DynamicSectionsError) => {
    console.error(error);
    return <></>;
  };

  const render = (section: ComponentSectionDynamicLastProgresses) => {
    return (
      <CustomCarousel 
        title={section.title ?? 'Continua a guardare'}
        data={section.data}
        carouselKind='course'
        renderSlide={lastProgress => {
          return (
            <ProgressCard lastProgress={lastProgress} />
          )
        }}
      />
    );
  };

  return F.pipe(
    data,
    O.fold(CustomCarouselLoading, data => {
      return F.pipe(data, E.fold(error, render));
    }),
  );
};
