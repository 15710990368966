import Loader from 'react-loader-spinner'
import style from './PlanDetails.module.css'

type SubmitButtonProps = {
  error: string
  type: 'standard' | 'gift'
  loading: boolean
  isSubmitting: boolean
  onSubmit?(): void
}

function SubmitButton({ error, isSubmitting, loading, type, onSubmit }: SubmitButtonProps) {
  return (
    <>
      {error && (
        <div className={style.error} role='alert'>
          {error}
        </div>
      )}
      <button className={style.button} disabled={isSubmitting} onClick={onSubmit}>
        {loading ? (
          <Loader type='TailSpin' color='white' height={30} width={30} />
        ) : type === 'gift' ? (
          'INVIA REGALO ➔'
        ) : (
          'INIZIA ORA ➔'
        )}
      </button>
      <div className={style.privacyContainer}>
        <span className={style.privacyText}>Completando l'iscrizione accetti i</span>
        <a
          href='https://learnn.com/termini-e-condizioni/'
          target='_blank'
          rel='noopener noreferrer'>
          <span className={style.privacyTextBold}>Termini del servizio</span>
        </a>
      </div>
    </>
  )
}

export default SubmitButton
