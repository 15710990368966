import { gql } from '@apollo/client';
import { Observable } from 'rxjs';
import { dynamicSections, QUERY_SECTIONS } from '../dynamic-sections';
import { normalizeGraphqlResponse } from '../utils';
export const home = (client, engagement, recommender, quiz, course, configuration, player, profile, path) => {
    const getCustomHome = (userId, options) => {
        return new Observable(subscriber => {
            client
                .query({
                query: CUSTOM_HOME,
            })
                .then(normalizeGraphqlResponse)
                .then(result => dynamicSections(client, engagement, recommender, quiz, course, configuration, player, profile, path)(result.data.home.container, userId, options).subscribe(r => subscriber.next(r)))
                .catch(error => {
                subscriber.error(error);
            });
        });
    };
    return {
        getCustomHome: getCustomHome,
    };
};
const CUSTOM_HOME = gql `
  query CustomHome {
    home {
      data {
        attributes {
          ${QUERY_SECTIONS}
        }
      }
    }
  }
`;
