import { useRef, useState, useContext, useEffect, FC } from 'react'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { useBodyClass } from '../../utils/hooks'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import styles from './courseScreen.module.css'
import QueryString from 'query-string'
import { useQuery } from 'react-query'
import { matchQuery } from '../../utils/matchQuery'
import { useParams, useLocation } from 'react-router-dom'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import { getCourseBaseInfo } from '../../controllers/Course'
import { Header, Loader } from './components/Header'
import { Container, VerticalStack } from '@learnn/designn'
import ErrorScreen from '../Error'
import { extractConfiguration } from '../../utils/data'
import { useAutoplay } from './hooks/useAutoplay'
import { Tabs, setupTabs } from './hooks/setupMenu'
import { useFeatureFlag } from '../../components/FeatureFlag/useFeatureFlag'

type LocationState = {
  selectedTab?: Tabs
}

type UrlParams = {
  showMenu?: string
  autoplay?: string
}

const extractShowMenu = (urlParams: UrlParams) => (urlParams?.showMenu ? true : false)
const extractAutoplay = (urlParams: UrlParams) => (urlParams?.autoplay ? true : false)

type CourseScreenComponentProps = {
  course: CourseBaseInfoEngagement
}
export const CourseScreenComponent: FC<CourseScreenComponentProps> = ({ course }) => {
  const { state } = useLocation<LocationState>()
  const openedTab = state?.selectedTab ?? 'overview'
  const [selectedTab, setSelectedTab] = useState<Tabs>(openedTab)
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const userId = globalContext?.userId ?? ''
  const quizAvailability = configuration?.quizAvailability ?? {}
  const verifiedEnabled = !!quizAvailability[course.id]

  const { features } = useFeatureFlag()

  const menu = setupTabs({
    course,
    userId,
    featuresConfig: features,
    selectedTab,
    verifiedEnabled,
    resourcesEnabled: configuration?.premiumVisible ?? false,
    onSelectTab: tab => {
      setSelectedTab(tab)
    },
  })

  useEffect(() => {
      setSelectedTab('overview')
  }, [course.id])

  const renderSections = (selectedTab: Tabs) => {
    return (
      <VerticalStack width='100%' height='100%'>
        {menu.map(item => (
          <VerticalStack
            height='100%'
            style={{ display: selectedTab === item.id ? 'flex' : 'none' }}>
            {item.component}
          </VerticalStack>
        ))}
      </VerticalStack>
    )
  }

  return (
    <>
      <Header
        selectedTab={selectedTab}
        course={course}
        onSelectTab={setSelectedTab}
        verifiedEnabled={!!quizAvailability[course.id]}
        menu={menu}
      />
      <div className={styles.container}>{renderSections(selectedTab)}</div>
    </>
  )
}

export const CourseScreen = () => {
  useBodyClass('app')
  const { id } = useParams<{ id: string }>()
  const urlParams = useRef(QueryString.parse(location.search))
  const showSide = extractShowMenu(urlParams.current)
  const autoplay = extractAutoplay(urlParams.current)
  const courseQuery = useQuery(['course', id], () => getCourseBaseInfo(id))
  useAutoplay({ autoplay, courseId: id })

  return (
    <AppLayout
      hideMobileBackToMenuButton
      hideHeader
      fixedHeader={false}
      hideSecondaryMenu={!showSide}
      fullwidth
      disableMargin
      fillScreen>
      <>
        {matchQuery(courseQuery, {
          loading: () => <Loader />,
          error: () => (
            <Container>
              <ErrorScreen />
            </Container>
          ),
          success: course => <CourseScreenComponent course={course} />,
        })}
      </>
    </AppLayout>
  )
}
