import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import React from 'react'
import { OverviewSection } from '../components/OverviewSection'
import { LessonsSection } from '../components/LessonsSection'
import { NotesSection } from '../components/NotesSection'
import { ResourcesSection } from '../components/ResourcesSection'
import { DiscussionSection } from '../components/DiscussionSection'
import { useQuery } from 'react-query'
import { getCourseVerifiedStatus } from '../../../controllers/Quiz'
import env from '../../../env.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useTheme, Box, Text } from '@learnn/designn'
import { FeaturesConfig } from '@learnn/sdk/src/api/feature-flag'
import { AssistantSection } from '../components/AssistantSection'
import useAnalytics from '../../../analytics/useAnalytics'
import { VerifiedSection } from '../components/VerifiedSection'
import aiIcon from '../../../../src/assets/images/icons/ai.svg'

export type Tabs =
  | 'overview'
  | 'modules'
  | 'notes'
  | 'verified'
  | 'resources'
  | 'discussion'
  | 'assistant'
export type Tab = {
  id: Tabs
  label: string
  onClick?: Function
  leftComponent?: React.ReactNode
  component: React.ReactNode
  additionalClass?: string
  disabled?: boolean
  labelTooltip?: string
  componentTooltip?: React.ReactNode
}

export type SetupTabsConfig = {
  course: CourseBaseInfoEngagement
  selectedTab: Tabs
  onSelectTab: Function
  verifiedEnabled: boolean
  resourcesEnabled: boolean
  userId: string
  featuresConfig: FeaturesConfig
  containerStyle?: React.CSSProperties
}
export const setupTabs = ({
  course,
  userId,
  featuresConfig,
  onSelectTab,
  verifiedEnabled,
  resourcesEnabled,
}: SetupTabsConfig): Tab[] => {
  const { colors, spacing, borders } = useTheme()
  const { logCommunityOpenCourse, logAiCourseAssistantOpen } = useAnalytics()

  const verifiedStatusQuery = useQuery(['verifiedAvailability', course.id], () =>
    getCourseVerifiedStatus(userId, course.id),
  )

  return [
    {
      id: 'overview',
      label: 'Overview',
      component: (
        <OverviewSection
          courseId={course.id}
          onVerifyClick={() => onSelectTab('verified')}
          onShowVerifiedClick={() => onSelectTab('verified')}
          verifiedEnabled={verifiedEnabled}
        />
      ),
    },
    {
      id: 'modules',
      label: 'Moduli',
      component: <LessonsSection courseId={course.id} />,
    },
    {
      id: 'notes',
      label: 'Note',
      component: <NotesSection courseId={course.id} />,
    },
    ...(course.resources
      ? [
          {
            id: 'resources',
            label: 'Risorse',
            component: <ResourcesSection course={course} />,
            ...(!resourcesEnabled && {
              additionalClass: env.PRO_RESOURCES_CLASS,
              disabled: true,
              leftComponent: (
                <FontAwesomeIcon icon={faLock} size='sm' style={{ color: colors.text.dimmed }} />
              ),
              component: <></>,
            }),
          } as Tab,
        ]
      : []),
    ...(course.circlePostUrl
      ? [
          {
            id: 'discussion',
            label: 'Discussione',
            onClick: () => logCommunityOpenCourse(course.id),
            component: <DiscussionSection course={course} />,
          } as Tab,
        ]
      : []),
    ...(verifiedStatusQuery.status === 'success'
      ? [
          {
            id: 'verified',
            label: 'Verified',
            leftComponent:
              course.progress < env.QUIZ_ALLOW_THRESHOLD &&
              verifiedStatusQuery.data.status !== 'passed' ? (
                <FontAwesomeIcon
                  icon={faLock}
                  size='sm'
                  style={{ color: colors.text.dimmed, marginRight: '10px' }}
                />
              ) : (
                <></>
              ),
            component: <VerifiedSection course={course} />,
          } as Tab,
        ]
      : []),
    ...(featuresConfig['ai-assistant']
      ? [
          {
            id: 'assistant',
            label: 'AI',
            componentTooltip: (
              <Box>
                <Text variant='bodyXs' mb='5px'>
                  Questo assistente ha accesso esclusivamente ai dati di questa corso.
                </Text>
                <Text variant='bodyXs'>
                  Puoi utilizzarlo per fare domande, ottenere riassunti, creare esempi pratici o
                  business case per metterti alla prova.
                </Text>
              </Box>
            ),
            leftComponent: (
              <img
                src={aiIcon}
                alt='AI Icon'
                style={{ width: '1.5rem', height: '1.5rem', marginRight: '0.4rem' }}
              />
            ),
            onClick: () => logAiCourseAssistantOpen(course.id),
            component: <AssistantSection course={course} />,
            containerStyle: {
              padding: '0.4rem',
              paddingLeft: '0.8rem',
              paddingRight: '0.8rem',
              backgroundColor: '#8b3dff',
              borderRadius: '0.5rem',
            },
          } as Tab,
        ]
      : []),
  ]
}
