import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import { useHistory } from 'react-router-dom'
import styles from './header.module.css'
import { Title, useTheme, Box, HorizontalStack, Tooltip } from '@learnn/designn'
import { MainCta } from '../MainCta'
import { ReactComponent as BackIcon } from '../../../../assets/images/icons/back_arrow.svg'
import { useContext, useEffect } from 'react'
import { GlobalProviderContext } from '../../../../components/GlobalProvider'
import { useQuery } from 'react-query'
import { getCourseVerifiedStatus } from '../../../../controllers/Quiz'
import { matchQuery } from '../../../../utils/matchQuery'
import { Tab, Tabs } from '../../hooks/setupMenu'
import cx from 'classnames'

export type HeaderProps = {
  course: CourseBaseInfoEngagement
  selectedTab: Tabs
  onSelectTab: Function
  verifiedEnabled: boolean
  menu: Tab[]
}

export const Header = ({
  course,
  selectedTab,
  onSelectTab,
  verifiedEnabled,
  menu,
}: HeaderProps) => {
  const { spacing } = useTheme()
  const history = useHistory()
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId ?? ''

  const verifiedStatusQuery = useQuery(['verifiedAvailability', course.id], () =>
    getCourseVerifiedStatus(userId, course.id),
  )

  const isSelected = (tab: string) => tab === selectedTab

  useEffect(() => {
    if (course.progress > 0) {
      onSelectTab('modules')
    }
  }, [])

  return (
    <div className={styles.header}>
      <div className={styles.backIconContainer}>
        <BackIcon
          className={styles.backIcon}
          onClick={() => {
            if (history.action === 'PUSH') history.goBack()
            else history.push('/')
          }}
        />
      </div>
      <div className={styles.menuContainer}>
        {menu.map((item, index) => {
          const tabStyle = item.containerStyle || {}
          return item.labelTooltip || item.componentTooltip ? (
            <Tooltip
              label={item.labelTooltip ?? ''}
              customComponent={item.componentTooltip}
              position='bottom'
              variant='dark'
              delaySeconds={0.7}
              width={'10rem'}>
              <HorizontalStack
                ml={index > 0 ? spacing.space_10 : undefined}
                onClick={() => {
                  if (!item.disabled) {
                    onSelectTab(item.id)
                    item.onClick && item.onClick()
                  }
                }}
                className={cx([styles.menuItem, item.additionalClass])}
                alignItems='center'
                style={tabStyle}>
                {item.leftComponent}
                <Title
                  variant='headingSm'
                  color={tabStyle.color || 'primary'}
                  fontWeight={isSelected(item.id) ? 'black' : 'semibold'}
                  ml={item.leftComponent ? spacing.space_1 : undefined}>
                  {item.label}
                </Title>
              </HorizontalStack>
            </Tooltip>
          ) : (
            <HorizontalStack
              ml={index > 0 ? spacing.space_10 : undefined}
              onClick={() => {
                if (!item.disabled) {
                  onSelectTab(item.id)
                  item.onClick && item.onClick()
                }
              }}
              className={cx([styles.menuItem, item.additionalClass])}
              alignItems='center'
              style={tabStyle}>
              {item.leftComponent}
              <Title
                variant='headingSm'
                color={tabStyle.color || 'primary'}
                fontWeight={isSelected(item.id) ? 'black' : 'semibold'}
                ml={item.leftComponent ? spacing.space_1 : undefined}>
                {item.label}
              </Title>
            </HorizontalStack>
          )
        })}
      </div>
      <div className={styles.ctaContainer}>
        {course.type.slug === 'course' && verifiedEnabled ? (
          matchQuery(verifiedStatusQuery, {
            loading: () => <></>,
            error: () => <></>,
            success: verifiedState => {
              return (
                <MainCta
                  size='md'
                  course={course}
                  verify={{
                    verifiedState,
                    onShowVerifiedClick: () => onSelectTab('verified'),
                    onVerifyCourseClick: () => onSelectTab('verified'),
                  }}
                />
              )
            },
          })
        ) : (
          <MainCta size='md' course={course} />
        )}
      </div>
    </div>
  )
}

export const Loader = () => {
  const { spacing } = useTheme()
  const SkeletonComponent = ({ br }: { br?: number }) => (
    <Skeleton height='100%' borderRadius={br ?? 15} />
  )

  return (
    <div className={styles.header}>
      <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
        <div style={{ width: '200px', height: '35px' }} className={styles.hideMobile}>
          <SkeletonComponent />
        </div>
        <HorizontalStack>
          <Box style={{ height: '30px', width: '80px' }} ml={spacing.space_8}>
            <SkeletonComponent />
          </Box>
          <Box style={{ height: '30px', width: '80px' }} ml={spacing.space_8}>
            <SkeletonComponent />
          </Box>
          <Box style={{ height: '30px', width: '80px' }} ml={spacing.space_8}>
            <SkeletonComponent />
          </Box>
          <Box style={{ height: '30px', width: '80px' }} ml={spacing.space_8}>
            <SkeletonComponent />
          </Box>
        </HorizontalStack>
        <div style={{ width: '200px', height: '40px' }} className={styles.hideMobile}>
          <SkeletonComponent />
        </div>
      </SkeletonTheme>
    </div>
  )
}
