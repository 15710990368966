import { useBodyClass } from '../../utils/hooks'
import { useHistory } from 'react-router-dom'

import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import correctIconLight from '../../assets/images/icons/passed_quiz.svg'
import cx from 'classnames'
import style from './infiniteIntro.module.css'
import { Button, useTheme } from '@learnn/designn'

export const InfiniteIntroScreen = () => {
  useBodyClass('app')
  const history = useHistory()
  const { spacing } = useTheme()

  return (
    <AppLayout hideHeader>
      <div className={style.container}>
        <div className={style.content}>
          <div
            className={cx([style.box])}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}>
            <div className={style.resultInfo}>
              <p className={style.preTitle}>PRIMA DI INIZIARE</p>
              <h1 className={style.resultTitle}>Come funziona?</h1>
            </div>

            <div className={style.body}>
              <p className={style.description}>
                La sfida è composta di un numero infinito di domande in successione
              </p>

              <div className={style.answer} onClick={() => {}}>
                <img src={correctIconLight} className={style.answerImage} />
                <span>Rispondi correttamente</span>
              </div>
              <div className={style.answer} onClick={() => {}}>
                <img src={correctIconLight} className={style.answerImage} />
                <span>Ottieni serie di risposte corrette consecutive</span>
              </div>

              <p className={style.description}>
                Riuscirai a raggiungere il record di risposte corrette consecutive?
              </p>
              <Button
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                variant='primary'
                size='lg'
                iconPosition='right'
                label='Inizia la sfida'
                onPress={() => history.push('/sfida')}
                mt={spacing.space_6}
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
