import { Path } from '@learnn/sdk/dist/api/paths/types'
import { createContextReducer } from '../../utils/createContext'



export type PopupType =
  | {
      path: (Path)
      show: true
    }
  | {
      show: false
    }

export const popupInitialState: PopupType = {
  show: false,
}

export type IPopupActions = PopupType & {}

function reducer(state: PopupType, newState: IPopupActions) {
  return { ...state, ...newState }
}

const [ctx, Provider] = createContextReducer<PopupType, IPopupActions>(reducer, popupInitialState)
export const PathPopupContext = ctx
export const PathPopupContextProvider = Provider
