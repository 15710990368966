import { useState, useEffect, useRef } from 'react'
import Header from '../../components/Header'

import Footer from '../../components/Footer'
import PlanDescription from '../../components/PlanDescription'
import Error from '../../components/Error'
import CardPayment from '../../components/CardPayment'
import PayPalPayment from '../../components/PayPalPayment'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Loader from 'react-loader-spinner'
import { Formik } from 'formik'
import { Select } from '../../components/Select'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faApple, faGoogle, faPaypal } from '@fortawesome/free-brands-svg-icons'
import {
  validatePlan,
  applyCoupon,
  getCustomerPaymentInfo,
  Addon,
  ValidatedPlan,
  DiscountDetails,
  CustomPrice,
} from '../../controllers/Payment'
import style from './giftPaymentScreen.module.css'
import cx from 'classnames'
import auth from '../../utils/auth'
import env from '../../env.json'
import { PrimaryMethodPayment } from '../../components/PrimaryMethodPayment'
import DatePicker from 'react-date-picker'
import StripePayment from '../../components/StripePayment/StripePayment'

import { chargebeeInstance } from '../../utils/chargebee'
import { Carousel } from '../../components/Carousel'
import { CarouselSlide } from '../../components/Carousel/CarouselSlide'
import { getCookie } from '../../utils/checkout'
import PlanDetails, { SubmitButton } from '../../components/PlanDetails'
import { LiveChat } from '../../components/LiveChat'

type LocationState = {
  plan?: string
  coupon?: string
  email?: string
}

type GiftDetails = {
  senderEmail: string
  receiverName: string
  receiverLastName: string
  receiverEmail: string
  senderMessage: string
  senderName: string
  scheduledAt?: string
}

type PaymentMethod = 'card' | 'paypal' | 'apple-pay' | 'google-pay'

export type FormActions = {
  submit(): void
}

type PaymentForms = Record<PaymentMethod | 'primary', FormActions | null>

const extractPlan = (locationState: LocationState) => locationState?.plan
const extractEmail = (locationState: LocationState) => locationState?.email
const extractCoupon = (locationState: LocationState) => locationState?.coupon

const extractToken = () => {
  const authToken = auth.getToken() as string

  return authToken
}

const validationSchema = Yup.object({
  receiverName: Yup.string().required('Inserisci il nome del destinatario'),
  receiverLastName: Yup.string().required('Inserisci il cognome del destinatario'),
  receiverEmail: Yup.string()
    .email('Inserisci un indirizzo email valido')
    .required("Inserisci l'indirizzo email del destinatario"),
  senderMessage: Yup.string(),
  senderName: Yup.string()
    .max(50, 'Il nome non può superare i 50 caratteri')
    .required('Inserisci il tuo nome'),
  scheduledAt: Yup.object(),
  customDate: Yup.number().when(['scheduledAt'], {
    is: scheduledAt => {
      return scheduledAt.value === `custom`
    },
    then: Yup.number().required('Inserisci una data'),
  }),
})

interface IGiftPaymentScreenProps {
  location: {
    state: LocationState
  }
}

export const GiftPaymentScreen = ({ location }: IGiftPaymentScreenProps) => {
  const [applePaySupported, enableApplePay] = useState(false)
  const [googlePaySupported, _enableGooglePay] = useState(true)
  const [isTrial, _setTrial] = useState(false)
  const [addon, _setAddon] = useState<Addon>()
  const [planInfo, setPlanInfo] = useState<ValidatedPlan>()
  const [loading, setLoading] = useState(true)
  const [paymentLoading, setLoadingPayment] = useState(false)
  const [paymentError, setPaymentError] = useState('')
  const [step, setStep] = useState('details')
  const [giftDetails, setGiftDetails] = useState<GiftDetails>()
  const [selectedPayment, setSelectedPayment] = useState<PaymentMethod>('card')
  const [planIsValid, setPlanIsValid] = useState(true)
  const [planPrice, setPlanPrice] = useState<number>()
  const [coupon, setCoupon] = useState('')
  const [couponPrice, setCouponPrice] = useState<number>()
  const [customPrice, setCustomPrice] = useState<CustomPrice>()
  const [discountDetails, setDiscountDetails] = useState<DiscountDetails>()
  const [primaryPaymentMethod, setPrimaryPaymentMethod] = useState()
  const [promotionalCredits, setPromotionalCredits] = useState(0)
  const [showCustomDate, setShowCustomDate] = useState(false)
  const userToken = extractToken()
  const planQuantity = 1
  const [submitVisible, toggleSubmit] = useState(false)
  const [senderNameLength, setSenderNameLength] = useState(0)

  const plan = extractPlan(location.state) ?? ''
  const senderEmail = extractEmail(location.state)

  const paymentFormsRef = useRef<PaymentForms>({
    card: null,
    'apple-pay': null,
    'google-pay': null,
    paypal: null,
    primary: null,
  })

  const submitCoupon = async () => {
    try {
      const retrievedCoupon = await applyCoupon({ plan, coupon })
      setCustomPrice(retrievedCoupon.customPrice)
      return retrievedCoupon
    } catch (error) {
      throw error
    }
  }

  const onPaymentSucceded = () => {
    window.location.replace(env.GIFT_THANKYOU_URL)
  }

  useEffect(() => {
    const checkPlan = async () => {
      try {
        const paramsCoupon = extractCoupon(location.state)
        if (paramsCoupon) {
          const retrievedPlan = await applyCoupon({ plan, coupon: paramsCoupon })
          setPlanInfo(retrievedPlan)
          setCoupon(paramsCoupon)
          setCouponPrice(retrievedPlan.price)
          setDiscountDetails(retrievedPlan.discount)
          setPlanPrice(retrievedPlan.base_price)
          setCustomPrice(retrievedPlan.customPrice)
        } else {
          if (plan) {
            const retrievedPlan = await validatePlan({ plan })
            setPlanInfo(retrievedPlan)
            setPlanPrice(retrievedPlan.base_price)
            setCustomPrice(retrievedPlan.customPrice)
          }
        }
        setLoading(false)
      } catch (error) {
        setPlanIsValid(false)
      }
    }
    const checkApplePay = () => {
      chargebeeInstance.load('apple-pay').then((applePayHandler: any) => {
        const isApplePayAvailable = applePayHandler.canMakePayments()
        if (isApplePayAvailable) {
          enableApplePay(true)
        }
      })
    }

    checkPlan()
    checkApplePay()
  }, [plan])

  useEffect(() => {
    const loadPaymentInfo = async () => {
      setLoading(true)
      if (userToken) {
        // if logged in get primary payment method
        const paymentInfo = await getCustomerPaymentInfo()
        setPrimaryPaymentMethod(paymentInfo.primaryPaymentMethod)
        setPromotionalCredits(paymentInfo.promotionalCredits ?? 0)
      }
      setLoading(false)
    }
    loadPaymentInfo()
  }, [])

  const renderPaymentTab = () => {
    if (!giftDetails) {
      return
    }

    const price = couponPrice ?? planPrice

    if (!price) {
      return
    }

    switch (selectedPayment) {
      case 'card':
        return (
          <div className='tab-inner'>
            <CardPayment
              ref={e => {
                paymentFormsRef.current.card = e
                toggleSubmit(true)
              }}
              type='gift'
              userToken={userToken}
              plan={plan}
              planInfo={planInfo}
              price={price}
              giftDetails={giftDetails}
              coupon={coupon}
              showCoupon={couponPrice ? false : true}
              setCoupon={setCoupon}
              onSuccess={onPaymentSucceded}
              setCouponPrice={setCouponPrice}
              onCouponSubmit={submitCoupon}
              creditNotes={promotionalCredits}
              setErrorMessage={setPaymentError}
              togglePaymentLoading={setLoadingPayment}
              isVatExempt={false}
              onVatExemptChange={() => {}}
            />
          </div>
        )
      case 'paypal':
        return (
          <div className='tab-inner'>
            <PayPalPayment
              ref={e => {
                paymentFormsRef.current.paypal = e
                toggleSubmit(true)
              }}
              type='gift'
              userToken={userToken}
              plan={plan}
              planInfo={planInfo}
              price={price}
              giftDetails={giftDetails}
              coupon={coupon}
              showCoupon={couponPrice ? false : true}
              setCoupon={setCoupon}
              setCouponPrice={setCouponPrice}
              onSuccess={onPaymentSucceded}
              onCouponSubmit={submitCoupon}
              creditNotes={promotionalCredits}
              setErrorMessage={setPaymentError}
              togglePaymentLoading={setLoadingPayment}
              isVatExempt={false}
              onVatExemptChange={() => {}}
            />
          </div>
        )

      default:
        return (
          <div className={style.tabInner}>
            <StripePayment
              ref={e => {
                paymentFormsRef.current[selectedPayment] = e
                toggleSubmit(true)
              }}
              key={`${selectedPayment}-payment`}
              type='standard'
              method={selectedPayment}
              userToken={userToken}
              plan={plan}
              planInfo={planInfo}
              coupon={coupon}
              setCoupon={setCoupon}
              setCouponPrice={setCouponPrice}
              showCoupon={couponPrice || couponPrice === 0 ? false : true} //TODO calculate coupon price with addon
              price={couponPrice ?? planPrice}
              isTrial={isTrial}
              //trialPeriod={trialPlan?.period}
              onSuccess={onPaymentSucceded}
              onCouponSubmit={submitCoupon}
              creditNotes={promotionalCredits}
              addon={addon}
              planQuantity={planQuantity}
              setErrorMessage={setPaymentError}
              togglePaymentLoading={setLoadingPayment}
              isVatExempt={false}
              onVatExemptChange={() => {}}
            />
          </div>
        )
    }
  }

  if (plan && planIsValid) {
    return (
      <>
        <LiveChat />
        <Header type='light' />
        <Container>
          <Row className={style.mainContainer}>
            {loading ? (
              <Loader type='TailSpin' color='black' height={50} width={50} className='mt-5' />
            ) : (
              <Col xs={11} md={5}>
                {step === 'details' ? (
                  <>
                    <span className='subtitle mb-3'>PASSAGGIO 2 DI 3</span>
                    <h1 className={style.title}>Prepara il regalo.</h1>
                  </>
                ) : (
                  <>
                    <span className='subtitle mb-3'>PASSAGGIO 3 DI 3</span>
                    <h1 className={style.title}>Invia il regalo.</h1>
                  </>
                )}

                <PlanDescription
                  plan={{
                    title: planInfo?.invoice_name ?? 'Regalo',
                    price: planPrice,
                    bullet: [
                      'Accedere a tutti i contenuti',
                      'Non sarà richiesta la sua carta',
                      'Non avverrà rinnovo automatico',
                    ],
                  }}
                  couponPrice={couponPrice}
                  trial={false}
                />

                {step === 'details' ? (
                  <Formik
                    initialValues={{
                      receiverName: '',
                      receiverLastName: '',
                      receiverEmail: '',
                      senderMessage: '',
                      senderName: '',
                      scheduledAt: { value: '', label: 'Invia il regalo Subito' },
                      customDate: undefined,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                      setGiftDetails({
                        senderEmail: senderEmail ?? '',
                        ...values,
                        scheduledAt:
                          showCustomDate && values.customDate
                            ? (values.customDate / 1000).toString()
                            : values.scheduledAt.value,
                      })
                      setStep('payment')
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <p className={cx([style.header, 'mt-5 mb-1'])}>PER CHI É QUESTO REGALO?</p>
                        <p className={cx([style.subtitle, 'mb-4'])}>
                          Inserisci i dettagli del destinatario
                        </p>
                        <div
                          className='mt-1'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}>
                          <div className={style.field} style={{ flex: 1 }}>
                            <input
                              name='receiverName'
                              className={
                                values.receiverName ? cx([style.input, style.val]) : style.input
                              }
                              type='text'
                              placeholder='Il suo nome'
                              value={values.receiverName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label className={style.label}>Il suo nome</label>
                            <i className={style.bar}></i>
                            <div className={style.errorText}>
                              {errors.receiverName && touched.receiverName && errors.receiverName}
                            </div>
                          </div>
                          <div className={style.field} style={{ flex: 1, marginLeft: 16 }}>
                            <input
                              name='receiverLastName'
                              className={
                                values.receiverLastName ? cx([style.input, style.val]) : style.input
                              }
                              type='text'
                              placeholder='Il suo cognome'
                              value={values.receiverLastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label className={style.label}>Il suo cognome</label>
                            <i className={style.bar}></i>
                            <div className={style.errorText}>
                              {errors.receiverLastName &&
                                touched.receiverLastName &&
                                errors.receiverLastName}
                            </div>
                          </div>
                        </div>
                        <div className={style.field}>
                          <input
                            name='receiverEmail'
                            className={
                              values.receiverEmail ? cx([style.input, style.val]) : style.input
                            }
                            type='text'
                            placeholder='La sua email'
                            value={values.receiverEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label className={style.label}>La sua email</label>
                          <i className={style.bar}></i>
                          <div className={style.errorText}>
                            {errors.receiverEmail && touched.receiverEmail && errors.receiverEmail}
                          </div>
                        </div>
                        <p className={cx([style.header, 'mt-4 mb-1'])}>MESSAGGIO PERSONALE</p>
                        <p className={cx([style.subtitle, 'mb-4'])}>
                          Questo messaggio verrà recapitato insieme al regalo
                        </p>
                        <div className={cx([style.field, 'mt-1'])}>
                          <textarea
                            name='senderMessage'
                            className={
                              values.senderMessage ? cx([style.input, style.val]) : style.input
                            }
                            placeholder='Inserisci il tuo messaggio (facoltativo)'
                            value={values.senderMessage}
                            onChange={handleChange}
                            onBlur={handleBlur}></textarea>
                          <label className={style.label}>
                            Inserisci il tuo messaggio (facoltativo)
                          </label>
                          <i className={style.bar}></i>
                          <div className={style.errorText}>
                            {errors.senderMessage && touched.senderMessage && errors.senderMessage}
                          </div>
                        </div>
                        <div className={style.field}>
                          <input
                            name='senderName'
                            className={
                              values.senderName ? cx([style.input, style.val]) : style.input
                            }
                            type='text'
                            placeholder='Inserisci il tuo nome (facoltativo)'
                            value={values.senderName}
                            onChange={e => {
                              setSenderNameLength(e.target.value.length)
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                          />
                          <label className={style.label}>Inserisci il tuo nome </label>
                          <i className={style.bar}></i>
                          {values.senderName.length > 0 && (
                            <div className={style.inputLength}>
                              Caratteri disponibili: {50 - senderNameLength}
                            </div>
                          )}
                          <div className={cx([style.errorText])}>
                            {errors.senderName && touched.senderName && errors.senderName}
                          </div>
                        </div>
                        <p className={cx([style.header, 'mt-4 mb-1'])}>QUANDO LO RICEVERÀ? </p>
                        <p className={cx([style.subtitle, 'mb-4'])}>
                          Scegli quando inviare il regalo al destinatario
                        </p>
                        {!showCustomDate ? (
                          <div className={cx([style.field, 'mb-5'])}>
                            <Select
                              type='single'
                              placeholder='Scegli...'
                              options={[
                                { value: '', label: 'Invia il regalo Subito' },
                                { value: 'custom', label: 'Personalizza la data' },
                              ]}
                              value={values.scheduledAt}
                              //@ts-ignore
                              onChange={option => {
                                setShowCustomDate(option.value.toString() === 'custom')
                                setFieldValue('scheduledAt', option)
                              }}
                            />
                          </div>
                        ) : (
                          <div className={cx([style.field, 'mb-5', 'mt-3'])}>
                            <DatePicker
                              name='customDate'
                              className={style.input}
                              //@ts-ignore
                              minDate={new Date()}
                              //@ts-ignore
                              onChange={e => {
                                setFieldValue('customDate', +new Date(e).setHours(10))
                              }}
                              format='d/MM/y'
                              value={values.customDate ? new Date(values.customDate) : null}
                            />
                            <i className={style.bar}></i>
                            <div className={cx([style.errorText])}>
                              {errors.customDate && touched.customDate && errors.customDate}
                            </div>
                          </div>
                        )}
                        <button className={style.button} type='submit' disabled={isSubmitting}>
                          CONTINUA
                        </button>
                      </form>
                    )}
                  </Formik>
                ) : (
                  <div>
                    {giftDetails &&
                      (primaryPaymentMethod ? (
                        <PrimaryMethodPayment
                          ref={(e: FormActions | null) => {
                            paymentFormsRef.current.primary = e
                            toggleSubmit(true)
                          }}
                          userToken={userToken}
                          primaryPaymentMethod={primaryPaymentMethod}
                          plan={plan}
                          price={couponPrice ?? planPrice}
                          giftDetails={giftDetails}
                          coupon={coupon}
                          onSuccess={onPaymentSucceded}
                          onChangeCardClick={() => {
                            window.location.replace('https://learnn.com/account/abbonamento/')
                          }}
                          creditNotes={promotionalCredits}
                          setErrorMessage={setPaymentError}
                          togglePaymentLoading={setLoadingPayment}
                        />
                      ) : (
                        <>
                          <Carousel
                            options={{ speed: 600 }}
                            grabCursor={false}
                            navigation={{
                              prevEl: `.prev-payment`,
                              nextEl: `.next-payment`,
                            }}
                            kind={
                              applePaySupported && googlePaySupported
                                ? 'paymentFive'
                                : 'paymentFour'
                            }>
                            <CarouselSlide key={`payment-card`}>
                              <div
                                onClick={() => setSelectedPayment('card')}
                                className={
                                  selectedPayment === 'card' ? style.activeTab : style.inactiveTab
                                }>
                                <div className={style.paymentName}>
                                  <FontAwesomeIcon
                                    icon={faCreditCard}
                                    className={style.paymentIcon}
                                  />{' '}
                                  Carta
                                </div>
                              </div>
                            </CarouselSlide>
                            <CarouselSlide key={`payment-paypal`}>
                              <div
                                onClick={() => {
                                  toggleSubmit(false)
                                  setSelectedPayment('paypal')
                                }}
                                className={
                                  selectedPayment === 'paypal' ? style.activeTab : style.inactiveTab
                                }>
                                <div className={style.paymentName}>
                                  <FontAwesomeIcon icon={faPaypal} className={style.paymentIcon} />{' '}
                                  PayPal
                                </div>
                              </div>
                            </CarouselSlide>
                            {googlePaySupported ? (
                              <CarouselSlide key={`payment-google`}>
                                <div
                                  onClick={() => {
                                    toggleSubmit(false)
                                    setSelectedPayment('google-pay')
                                  }}
                                  className={
                                    selectedPayment === 'google-pay'
                                      ? style.activeTab
                                      : style.inactiveTab
                                  }>
                                  <div className={style.paymentName}>
                                    <FontAwesomeIcon
                                      icon={faGoogle}
                                      className={style.paymentIcon}
                                    />{' '}
                                    Google
                                  </div>
                                </div>
                              </CarouselSlide>
                            ) : (
                              <></>
                            )}
                            {applePaySupported ? (
                              <CarouselSlide key={`payment-apple`}>
                                <div
                                  onClick={() => {
                                    toggleSubmit(false)
                                    setSelectedPayment('apple-pay')
                                  }}
                                  className={
                                    selectedPayment === 'apple-pay'
                                      ? style.activeTab
                                      : style.inactiveTab
                                  }>
                                  <div className={style.paymentName}>
                                    <FontAwesomeIcon icon={faApple} className={style.paymentIcon} />{' '}
                                    Apple
                                  </div>
                                </div>
                              </CarouselSlide>
                            ) : (
                              <></>
                            )}
                          </Carousel>
                          {renderPaymentTab()}
                        </>
                      ))}
                    <div className={style.summary}>
                      <PlanDetails
                        type='gift'
                        plan={plan}
                        planInfo={planInfo}
                        coupon={coupon}
                        setCoupon={setCoupon}
                        setCouponPrice={setCouponPrice}
                        setDiscountDetails={setDiscountDetails}
                        planPrice={planPrice}
                        couponPrice={couponPrice}
                        onCouponSubmit={submitCoupon}
                        creditNotes={promotionalCredits}
                        discountDetails={discountDetails}
                        customPrice={customPrice}
                        isVatExempt={false}
                      />
                      {submitVisible ? (
                        <SubmitButton
                          isSubmitting={false}
                          error={paymentError}
                          loading={paymentLoading}
                          type='gift'
                          onSubmit={() => {
                            if (primaryPaymentMethod) paymentFormsRef.current.primary?.submit()
                            else paymentFormsRef.current[selectedPayment]?.submit()
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
              </Col>
            )}
          </Row>
        </Container>
        <Footer />
      </>
    )
  } else {
    return <Error message='Il piano selezionato non è valido, ripeti la procedura di checkout.' />
  }
}
