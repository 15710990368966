import { useContext } from 'react'
import { CoursePopupContext } from './popupContext'
import {CourseBaseInfoEngagement, CourseBaseInfo} from "@learnn/sdk/dist/api/course";

export const useCoursePopup = () => {
  const { dispatch, state: prevState } = useContext(CoursePopupContext)

  return {
    show: (course: CourseBaseInfo & Partial<CourseBaseInfoEngagement>) => {
      dispatch({
        course,
        show: true,
      })
    },
    close: () => {
      dispatch({
        ...prevState,
        show: false,
      })
    },
  }
}
