import { useQuery } from 'react-query'
import { getLessonNotes } from '../../../controllers/Player'
import { matchQuery } from '../../../utils/matchQuery'

import { useEffect, useRef } from 'react'

import style from './styles/notePopup.module.css'

import Loader from 'react-loader-spinner'
import { EditableNote } from '@learnn/designn'
import { NoteEditor, NoteEditorControls } from '../../EditableNote/EditableNote'
import { createNote, deleteNote, updateNote } from '../../../controllers/Note'
import { useQueryClient } from 'react-query'
import useAnalytics from '../../../analytics/useAnalytics'
import { formatSeconds } from '../utils'
import { useShare } from '../../SharePopup/useShare'
import env from '../../../env.json'

type NoteContentProps = {
  lessonId: string
  courseId: string
  moduleId: string
  viewTime: number
}

export const NoteTab = ({ lessonId, courseId, moduleId, viewTime }: NoteContentProps) => {
  const queryClient = useQueryClient()
  const { logSaveNote, logShareNote } = useAnalytics()
  const { share } = useShare()
  const notesQuery = useQuery(['lessonNotes', lessonId], () => getLessonNotes(lessonId))
  const noteEditorRef = useRef<null | NoteEditorControls>(null)

  const saveNewNote = async (text: string) => {
    await createNote({
      content: text,
      lessonId,
      courseId,
      viewTime,
    })

    logSaveNote(courseId, moduleId, lessonId)

    await queryClient.refetchQueries(['lessonNotes', lessonId])
  }

  const saveNoteWhenClosed = async (text: string | undefined) => {
    if (!text) return
    await saveNewNote(text)
  }

  const refetchQueries = async () => {
    await queryClient.refetchQueries(['lessonNotes', lessonId])
    await queryClient.invalidateQueries(['notes', courseId])
  }

  useEffect(() => {
    return () => {
      const lastNoteText = noteEditorRef.current?.getText()
      saveNoteWhenClosed(lastNoteText)
    }
  }, [])

  return (
    <>
      {matchQuery(notesQuery, {
        loading: () => (
          <div className={style.containerLoading}>
            <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
          </div>
        ),
        error: () => <></>,
        success: notes => (
          <div>
            <NoteEditor
              ref={e => {
                if (e) {
                  noteEditorRef.current = e
                }
              }}
              loading={false}
              onSave={saveNewNote}
              resetOnSave
            />
            {notes.map(note => (
              <EditableNote
                variant='small'
                mb={3}
                key={`note-${note.noteId}-${lessonId}`}
                title={formatSeconds(note.viewTime)}
                body={note.data}
                //@ts-ignore //TODO
                onEditClick={async value => {
                  await updateNote(note.noteId, value)
                  refetchQueries()
                }}
                onDeleteClick={async () => {
                  await deleteNote(note.noteId)
                  refetchQueries()
                }}
                onShareClick={() => {
                  share({
                    body: `Ho appena seguito una lezione su Learnn e ho preso una nota:\n\n"${note.data}"`,
                    type: 'note',
                    url: `${env.SITE_URL}/lezione/${note.lessonId}`,
                    title: 'Condividi la nota con un tuo collega',
                    onPress: () => logShareNote(courseId, moduleId, lessonId),
                  })
                }}
              />
            ))}
          </div>
        ),
      })}
    </>
  )
}
