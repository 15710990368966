export function formatSeconds (seconds: number): string {
  const date = new Date(Number(seconds) * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) return `${hh}:${pad(mm)}:${ss}`;
  return `${mm}:${ss}`;
}

export function pad (s: number): string {
  return (`0${s}`).slice(-2);
}
