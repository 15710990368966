import { useQuery } from "react-query"
import { getCourseBaseInfo, getProgressiveCourseModuleLessons } from "src/controllers/Course"
import { matchQuery } from "../../../utils/matchQuery"
import { useReactive } from "../../../utils/useReactive"
import { useEffect } from "react"
import * as lib from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { useHistory } from "react-router-dom"

type UseAutoplayProps = {
    autoplay: boolean
    courseId: string
}

export const useAutoplay = ({ courseId, autoplay }: UseAutoplayProps) => {
    const history = useHistory()
    const courseQuery = useQuery(['course', courseId], () => getCourseBaseInfo(courseId), { enabled: autoplay })
    const { data, error } = useReactive({
        observableFn$: getProgressiveCourseModuleLessons(courseId),
        queryKey: ['courseModuleLessons', courseId],
    })

    const goToLesson = (lessonId: string) => {
        history.push(`/player/${lessonId}`)
    }

    useEffect(() => {
        if (autoplay)
            matchQuery(courseQuery, {
                loading: () => { },
                error: () => { },
                success: course => {
                    if (course.lastProgress) {
                        goToLesson(course.lastProgress.id)
                    } else {
                        lib.pipe(
                            error,
                            O.fold(
                                () => {
                                    return lib.pipe(
                                        data,
                                        O.fold(
                                            () => {
                                            },
                                            ({ modules }) => {
                                                const firstLessonId =
                                                    modules.length > 0 && modules[0].lessons.length > 0
                                                        ? modules[0].lessons[0].id
                                                        : null
                                                if (firstLessonId)
                                                    goToLesson(firstLessonId)
                                            }
                                        )
                                    )
                                },
                                () => {
                                    
                                }
                            )
                        )
                    }
                }
            })

    }, [courseQuery.data, data, autoplay])

}