import { formatDuration } from 'date-fns'
import { it } from 'date-fns/locale'

export const durationToHuman = (secondsAmount?: number | null): string | null => {
  if (!secondsAmount) return null

  const seconds = secondsAmount < 60 ? 60 : secondsAmount

  var h = Math.floor(seconds / 3600)
  var m = Math.floor((seconds % 3600) / 60)
  var s = Math.floor((seconds % 3600) % 60)

  return formatDuration(
    { hours: h, minutes: m, seconds: s },
    { format: ['hours', 'minutes'], delimiter: ' e ', locale: it },
  )
}

export const durationinMinutes = (secondsAmount?: number | null): string | null => {
  if (!secondsAmount) return null

  if (secondsAmount < 60) return `1 min`

  const m = Math.trunc(secondsAmount / 60)

  return `${m} min`
}

export const toReadableDate = (date: Date) => {
  return `${date.getDate()}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`
}

export const addDaysToDate = (date: Date, days: number) => {
  const attemptUnlockDate = new Date(date)
  attemptUnlockDate.setDate(attemptUnlockDate.getDate() + days)
  return attemptUnlockDate
}
