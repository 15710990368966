import { useContext, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Formik } from 'formik'
import style from './resetPasswordScreen.module.css'
import InputPassword from '../../components/InputPassword'
import cx from 'classnames'
import * as Yup from 'yup'
import Container from 'react-bootstrap/Container'
import Loader from 'react-loader-spinner'
import initApi from '../../controllers/api'
import { useBodyClass } from '../../utils/hooks'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { Button, Text, TextInput, Title, useTheme, VerticalStack } from '@learnn/designn'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero.',
    )
    .required('Inserisci una password'),
  changepassword: Yup.string().when('password', {
    is: val => !!(val && val.length > 0),
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Entrambe le password devono essere uguali')
      .required('Inserisci la password'),
  }),
})

export const ResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId ?? ''
  const { spacing, colors } = useTheme()

  useBodyClass('app')

  const handleForm = async (password: string, setSubmitting: any) => {
    setLoading(true)
    try {
      const api = await initApi
      await api.account.updatePassword(userId, password)

      setError('')
      setSubmitted(true)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setSubmitting(false)
      setLoading(false)
      setError(
        'Si è verificato un errore durante la reimpostazione della password, riprova o contattaci in live chat',
      )
    }
  }

  if (loading) {
    return (
      <div className={style.loaderWrapDark}>
        <Loader type='TailSpin' color={'white'} height={60} width={60} />
      </div>
    )
  }

  return (
    <Container className={style.container}>
      <Row className='justify-content-center'>
        <Col xs={12} md={6}>
          {!submitted ? (
            <>
            <Title variant='heading2xl' alignment='center' mb={20} mt={10}>Reimposta la tua password.</Title>
            <Text variant='bodySm' alignment='center'>Inserisci qui sotto la tua nuova password.</Text>
            </>
          ) : (
            <>
              <Title variant='heading2xl' alignment='center' mb={20} mt={10}>Password reimpostata.</Title>
              <Text variant='bodySm' alignment='center'>La tua password è stata correttamente resettata.</Text>
            </>
          )}
        </Col>
      </Row>
      <Row className='justify-content-center'>
        {!submitted && (
          <Col xs={11} md={5} lg={4}>
            <Formik
              initialValues={{
                password: '',
                changepassword: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                validationSchema
                  .validate(values)
                  .then(() => {
                    handleForm(values.password, actions.setSubmitting)
                  })
                  .catch(() => {
                    actions.setSubmitting(false)
                    actions.setErrors({ password: 'Errore' })
                  })
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <VerticalStack mt={spacing.space_8}>
                    <InputPassword
                      name='password'
                      placeholder='Nuova password...'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={errors.password && touched.password && errors.password}
                      
                    />
                    <TextInput
                      type = 'password'
                      name = 'changepassword'
                      placeholder='Conferma password...'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.changepassword}
                      error={errors.changepassword && touched.changepassword && errors.changepassword}
                      size='xl'
                      borderColor={colors.outline}
                      borderWidth={spacing.space_035}
                      mt={spacing.space_1}
                      >
                    </TextInput>
                   
                    </VerticalStack>

                  
                    <VerticalStack mt={spacing.space_4} alignment='center'>
                      {error && 
                        <Text variant='bodyXs' color='red' alignment='center' mt={spacing.space_2} role='alert'>{error}</Text>    
                      }
                      <Button 
                        variant='primary' 
                        size='lg' 
                        label={'Reimposta password'} 
                        type='submit' 
                        loading = {isSubmitting}
                        width = '100%'
                        />
                      </VerticalStack>
                  
                </form>
              )}
            </Formik>
          </Col>
        )}
      </Row>
    </Container>
  )
}