import { FC } from 'react'

import style from './circularProgress.module.css'

export interface ICircularProgress {
  size?: number
  labelSize?: number | string
  progress?: number
  trackWidth?: number
  trackColor?: string
  indicatorWidth?: number
  indicatorColor?: string
  label?: string
  labelColor?: string
}

export const CircularProgress: FC<ICircularProgress> = ({
  size = 75,
  labelSize,
  progress = 0,
  trackWidth = 3,
  trackColor = `rgba(255, 255, 255, 0.2)`,
  indicatorWidth = 4,
  indicatorColor = `#ffffff`,
  labelColor = `#ffffff`,
}) => {
  const center = size / 2,
    radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100)
  return (
    <>
      <div className={style.wrapper} style={{ width: size, height: size }}>
        <svg className={style.inner} style={{ width: size, height: size }}>
          <circle
            className={style.track}
            cx={center}
            cy={center}
            fill='transparent'
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            className={style.indicator}
            style={{ animationDuration: '2s' }}
            cx={center}
            cy={center}
            fill='transparent'
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap='round'
          />
        </svg>
        <div className={style.label} style={{ color: labelColor }}>
          <span className={style.labelProgress} style={{ fontSize: labelSize }}>{`${
            progress > 100 ? 100 : progress
          }%`}</span>
        </div>
      </div>
    </>
  )
}
