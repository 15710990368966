import screenfull from 'screenfull'
import { useContext } from 'react'
import { FullscreenContext } from './fullscreenContext'
import { isIOS } from 'react-device-detect'

export const useFullscreenWrapper = () => {
  const { state, dispatch } = useContext(FullscreenContext)

  const _requestFullscreen = () => {
    if (isIOS) {
      if (document.getElementsByTagName('video').length > 0) {
        const video = document.getElementsByTagName('video')[0]
        if (video) {
          // @ts-ignore
          video.webkitEnterFullscreen()
        }
      }
    } else if (screenfull.isEnabled) {
      screenfull.request()
      screenfull.on('change', () => {
        // @ts-ignore
        dispatch({ fullscreen: screenfull.isFullscreen })
      })
    }
  }
  const _exitFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.exit()
      screenfull.on('change', () => {
        // @ts-ignore
        dispatch({ fullscreen: screenfull.isFullscreen })
      })
    }
  }
  const _setMobileFullscreen = () => {
    try {
      _requestFullscreen()
      screen.orientation.lock('landscape')
    } catch (e) {
      console.error(e)
    }
  }
  const _exitMobileFullscreen = () => {
    try {
      _exitFullscreen()
      screen.orientation.unlock()
    } catch (e) {
      console.error(e)
    }
  }
  const _toggleMobileFullscreen = () => {
    if (state.fullscreen) {
      _exitMobileFullscreen()
    } else {
      _requestFullscreen()
    }
  }

  return {
    requestFullscreen: _requestFullscreen,
    exitFullscreen: _exitFullscreen,
    toggleFullscreen: () => {
      if (screenfull.isEnabled) {
        screenfull.toggle()
        screenfull.on('change', () => {
          // @ts-ignore
          dispatch({ fullscreen: screenfull.isFullscreen })
        })
      }
    },
    toggleMobileFullscreen: _toggleMobileFullscreen,
    setMobileFullscreen: _setMobileFullscreen,
    exitMobileFullscreen: _exitMobileFullscreen,
    // @ts-ignore //TODO: 2023-04-14 # not sure why this is needed 
    isFullscreen: screenfull.isFullscreen,
  }
}
