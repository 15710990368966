import { useTheme, Title, VerticalStack } from "@learnn/designn"
import React, { FC } from "react"
import styles from "./timelineCountItem.module.css"




export type TimelineCountItemCircleProps = {
    progressPercentage?: number
    hideCircle?: boolean
    containerStyle?: React.CSSProperties
    innerStyle?: React.CSSProperties
    circleWidth?: React.CSSProperties
}
export const TimelineCountItemCircle: FC<TimelineCountItemCircleProps> = ({ children, containerStyle, innerStyle, hideCircle, progressPercentage, circleWidth='5px' }) => {
    const { colors } = useTheme()

    const borderStyle = {
        backgroundColor: colors.card_border,
        background: `conic-gradient(white ${progressPercentage}%, ${colors.card_border} ${progressPercentage}%)`,
        padding: circleWidth,
    };

    if (hideCircle)
        return (
            <div className={styles.counterContainer} style={{...containerStyle}}>
                {children}
            </div>
        )

    return (
        <div className={styles.counterContainer} style={{...borderStyle, ...containerStyle}}>
            <div className={styles.counterInner} style={innerStyle}>
                {children}
            </div>
        </div>
    )
}


export type TimelineCountItemProps = {
    previousConnected?: boolean
    nextConnected?: boolean
    hidePrevious?: boolean
    hideNext?: boolean
    label: string
    circleWidth?: React.CSSProperties
    lineWidth?: React.CSSProperties
    renderComponent?: JSX.Element
    progressPercentage?: number
}
export const TimelineCountItem: FC<TimelineCountItemProps> = ({ previousConnected, nextConnected, hidePrevious, hideNext, label, renderComponent, progressPercentage = 0, lineWidth='5px', circleWidth='5px'}) => {
    const { spacing, colors } = useTheme()

    const renderCounterInner = (stepNumber: string = label) => {
        if (renderComponent)
            return renderComponent

        else
            return (
                <TimelineCountItemCircle progressPercentage={progressPercentage} circleWidth={circleWidth}>
                    <Title variant='headingMd'>{stepNumber}</Title>
                </TimelineCountItemCircle>
            )
    }

    return (
        <VerticalStack justifyContent='center' alignItems='center' mr={spacing.space_2} flex={1}>
            <div className={styles.bar} style={{ width: hidePrevious ? 0 : lineWidth, backgroundColor: previousConnected ? 'white' : colors.card_border }}></div>
            {renderCounterInner()}
            <div className={styles.bar} style={{ width: hideNext ? 0 : lineWidth, backgroundColor: nextConnected ? 'white' : colors.card_border }}></div>
        </VerticalStack>
    )
}
