import { FC } from 'react'
import style from './quizProgress.module.css'
import triangle from '../../assets/images/icons/down_triangle.svg'
import { Countdown } from '../../components/Countdown'
import { QuizQuestion } from '@learnn/sdk/dist/api/quiz/'
import env from '../../env.json'
import { QuizItem } from '@learnn/sdk/src/api/quiz/'

type QuizProgress = {
  quiz?: QuizItem
  maxTime?: number
  currentQuestion: number
  theme: 'light' | 'dark'
  onTimeUp(): void
  inline?: boolean
}

export const QuizProgress: FC<QuizProgress> = ({
  quiz,
  currentQuestion,
  maxTime,
  onTimeUp,
  theme,
  inline,
}) => {
  return inline ? (
    quiz ? (
      <div className={style.inlineProgressContainer}>
        <div className={style.inlineProgressBar}>
          {quiz.questions &&
            quiz.questions.map((q: QuizQuestion, index: number) => (
              <div
                key={`question-item-${q.questionId}`}
                className={
                  index <= currentQuestion
                    ? style[`${theme}_inlineCurrentProgressQuestion`]
                    : style[`${theme}_inlineProgressQuestion`]
                }>
                {index === currentQuestion ? (
                  <div className={style[`${theme}_inlinePercentage`]}>
                    <span>{`${Math.round(
                      ((currentQuestion + 1) * 100) / quiz.questions.length,
                    )}%`}</span>
                    <img className={style[`${theme}_inlineTriangleIcon`]} src={triangle} />
                  </div>
                ) : null}
              </div>
            ))}
        </div>
        <div className={style.inlineTimer}>
          <Countdown
            containerClassName={style[`${theme}_countdown`]}
            durationInMinutes={maxTime ?? env.QUIZ_DURATION_MINUTES}
            onCountdownEnd={onTimeUp}
          />
        </div>
      </div>
    ) : null
  ) : (
    <div className={style.header}>
      <div className={style.headerContainer}>
        <div className={style.headerLeft}>
          <div />
        </div>
        <h1 className={style.quizTitle}>{quiz?.title}</h1>
        <div className={style.headerRight}>
          <Countdown
            containerClassName={style[`${theme}_countdown`]}
            durationInMinutes={maxTime ?? env.QUIZ_DURATION_MINUTES}
            onCountdownEnd={onTimeUp}
          />
        </div>
      </div>
      {quiz ? (
        <div className={style.progressContainer}>
          <div className={style.progressBar}>
            {quiz.questions &&
              quiz.questions.map((q: QuizQuestion, index: number) => (
                <div
                  key={`question-item-${q.questionId}`}
                  className={
                    index <= currentQuestion
                      ? style[`${theme}_currentProgressQuestion`]
                      : style[`${theme}_progressQuestion`]
                  }>
                  {index === currentQuestion ? (
                    <div className={style[`${theme}_percentage`]}>
                      <span>{`${Math.round(
                        ((currentQuestion + 1) * 100) / quiz.questions.length,
                      )}%`}</span>
                      <img className={style[`${theme}_triangleIcon`]} src={triangle} />
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
          <span className={style[`${theme}_questionNumber`]}>{`DOMANDA ${currentQuestion + 1} / ${
            quiz.questions.length
          }`}</span>
        </div>
      ) : null}
    </div>
  )
}
