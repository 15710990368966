import style from './styles/accordion.module.css'
import cx from 'classnames'

import { useState } from 'react'
import { Link } from 'react-router-dom'

import { MacroMenuItem, SecondaryMenu } from './Menu'
import { AnimatePresence, motion } from 'framer-motion'
import arrowDown from '../../assets/images/icons/arrow-down.png'

export type AccordionProps = {
  item: MacroMenuItem
  isLast: boolean
  onItemPress(): void
  onSubPress(): void
}

export const Accordion: React.FC<AccordionProps> = ({
  item,
  onItemPress,
  onSubPress,
  isLast = false,
}) => {
  const [visible, setVisible] = useState(false)
  const toggleAccordion = () => setVisible(!visible)

  const variants = {
    hidden: { height: 0, opacity: 0 },
    visible: { height: 'auto', opacity: 1 },
  }

  const variantsLastItem = {
    hidden: { height: 0, opacity: 0 },
    visible: { minHeight: '100vh', height: 'auto', opacity: 1 },
  }

  const arrowVariants = {
    normal: { rotate: 0 },
    rotated: { rotate: 180 },
  }

  const renderSubs = (subs: SecondaryMenu[]) => {
    return subs.map((item, i) =>
      item.type === 'item' ? (
        item.external ? (
          <a
            onClick={onSubPress}
            href={item.url}
            key={i}
            className={cx([style.secondaryMenuItem, item.disabled && style.hidden])}
            target='_blank'
            rel='noreferrer'>
            {item.label}
          </a>
        ) : (
          <Link
            onClick={onSubPress}
            to={item.url}
            key={i}
            className={cx([style.secondaryMenuItem, item.disabled && style.hidden])}>
            {item.label}
          </Link>
        )
      ) : (
        <div key={i} className={style.secondaryMenuGroup}>
          {item.label}
        </div>
      ),
    )
  }

  return item.subs && item.subs?.length > 0 ? (
    <motion.div>
      <button
        className={style.button}
        onClick={() => {
          toggleAccordion()
        }}>
        <div className={style.headerInfo}>
          <div
            id={`${item.label}-link`}
            className={cx([style.macroMenuItem, item.disabled && style.hidden])}>
            {item.icon}
            {item.label && <p className={style.macroMenuItemLabel}>{item.label}</p>}
          </div>
        </div>
        <div className={style.arrowContainer} data-rotate={visible}>
          <motion.img
            variants={arrowVariants}
            initial='open'
            animate={visible ? 'rotated' : 'normal'}
            className={style.arrow}
            src={arrowDown}
          />
        </div>
      </button>

      <motion.div
        initial='hidden'
        animate={visible ? 'visible' : 'hidden'}
        variants={isLast ? variantsLastItem : variants}
        className={style.itemsContainer}>
        <AnimatePresence initial={false}>
          {visible ? (
            <motion.div className={style.itemsInner}>{renderSubs(item.subs)}</motion.div>
          ) : null}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  ) : (
    <div
      id={`${item.label}-link`}
      onClick={onItemPress}
      className={cx([style.macroMenuItem, item.disabled && style.hidden])}>
      {item.icon}
      {item.label && <p className={style.macroMenuItemLabel}>{item.label}</p>}
    </div>
  )
}
