import api from "./api";
import env from "../env.json";

export const createFeedback = async ({
  categories,
  data,
  courseId,
  lessonId,
  contentType,
  viewTime,
  userDeviceInfo,
}: {
  categories: string[];
  data: string;
  courseId: string;
  lessonId: string;
  contentType: string;
  viewTime: number;
  userDeviceInfo: string;
}) => {
  try {
    const { feedback } = await api;

    const feedbackId = await feedback.send({
      categories, data, courseId, lessonId, contentType, viewTime, userDeviceInfo
    });

    return feedbackId;
  } catch (e) {
    console.warn(e);
    throw e;
  }
};

export const getFeedbackCategories = async () => {
  try {
    const { feedback } = await api;

    const feedbackCategories = await feedback.getCategories();

    return feedbackCategories.map(el => ({ value: el.feedbackCategoryId, label: el.name }));
  } catch (e) {
    console.warn(e);
    throw e;
  }
};

export const getUselessCategoryId = async () => {
  try {
    const { feedback } = await api;

    const feedbackCategories = await feedback.getCategories();

    const filteredCategories = feedbackCategories.filter(el => el.name === "Suggerimento");

    return filteredCategories && filteredCategories.length ? filteredCategories[0].feedbackCategoryId : env.DEFAULT_FEEDBACK_CATEGORY_ID;
  } catch (e) {
    console.warn(e);
    return env.DEFAULT_FEEDBACK_CATEGORY_ID;
  }
};
