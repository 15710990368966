import { IVideoControls, IVideoReadOnlyState } from '../useReactPlayer'

import { useContext, useEffect } from 'react'

import { VideoControlsCourseInfo } from './VideoControls'
import {
  ControlsContext,
  playerControlsInitialState,
  IPlayerControlsPopupKinds,
} from './controlsContext'
import { useHistory } from 'react-router-dom'

export const useVideoControls = (
  controls: IVideoControls,
  state: IVideoReadOnlyState,
  { nextLessonId }: VideoControlsCourseInfo,
) => {
  const history = useHistory()

  const { dispatch, state: controlsState } = useContext(ControlsContext)

  const togglePlay = () => {
    controls.togglePlay()
  }

  useEffect(() => {
    if (state.ended) {
      triggerLessonEnded(true)
    }
  }, [state.ended])

  const seek = (n: number) => {
    controls.seekTo(n, 'seconds')
  }

  const triggerPopupVisibility = (kind: IPlayerControlsPopupKinds, visible: boolean) => {
    dispatch({
      ...playerControlsInitialState,
      [kind]: visible,
      nextLessonDismissed: true,
    })
  }

  const triggerFeedbackPopup = (
    visible: boolean,
    options: { selectedCategoryId?: string } | void,
  ) => {
    dispatch({
      ...playerControlsInitialState,
      feedbackPopup: { visible, selectedCategoryId: options?.selectedCategoryId },
      nextLessonDismissed: true,
    })
  }

  const triggerLessonEnded = (visible: boolean) => {
    dispatch({
      ...playerControlsInitialState,
      nextLessonVisible: visible,
      nextLessonDismissed:
        controlsState.nextLessonVisible && !visible ? true : controlsState.nextLessonDismissed,
    })
  }

  const triggerNextLesson = (visible: boolean) => {
    dispatch({
      ...controlsState,
      nextLessonVisible: visible,
      nextLessonDismissed:
        controlsState.nextLessonVisible && !visible ? true : controlsState.nextLessonDismissed,
    })
  }

  const goToNextLesson = () => {
    if (nextLessonId) {
      history.push(`/player/${nextLessonId}?play=true`, {
        startingRate: state.playbackRate,
        subtitlesVisible: state.subtitlesVisible,
      })
    }
  }

  return {
    togglePlay,
    seekForward: () => {
      seek(Number(state.playedSeconds) + 15)
    },
    seekBackward: () => {
      seek(Number(state.playedSeconds) - 15)
    },
    setPlaybackRate: (n: number) => {
      controls.setPlaybackRate(n)
    },
    goToNextLesson: () => {
      goToNextLesson()
    },
    togglePopup: (kind: IPlayerControlsPopupKinds) => {
      if (controlsState[kind] === true) {
        triggerPopupVisibility(kind, false)
        return
      }

      triggerPopupVisibility(kind, true)
    },
    toggleFeedback: (options: { selectedCategoryId?: string } | void) => {
      if (controlsState.feedbackPopup.visible === true) {
        triggerFeedbackPopup(false, undefined)
        return
      }
      triggerFeedbackPopup(true, options)
    },
    showNextLesson: () => {
      triggerNextLesson(true)
    },
    dismissNextLesson: () => {
      triggerNextLesson(false)
    },
    dismissEndLesson: () => {
      triggerLessonEnded(false)
    },
    toggleSubtitles: (visible: boolean) => {
      controls.setSubtitlesVisible(visible)
    }
  }
}
