import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Profile as ProfileType } from '@learnn/sdk/src/api/profile'
import { PublicProfileEditor } from '../../components/Profile/PublicProfileEditor'
import { Box, Button, Text, HorizontalStack, VerticalStack } from '@learnn/designn'
import { updateProfile, uploadProfileImage } from '../../controllers/Profile'
import { ApolloError } from '@apollo/client'
import { colors } from '@learnn/designn/build/theme/tokens/colors'
import { spacing } from '@learnn/designn/build/theme/tokens/spacing'
import { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useAnalytics from '../../analytics/useAnalytics'

const PublicProfileSchema = Yup.object().shape({
  name: Yup.string(),
  lastName: Yup.string(),
  location: Yup.string(),
  profession: Yup.string(),
  company: Yup.string(),
  bio: Yup.string(),
  nickname: Yup.string().required("L'username è richiesto"),
  linkedinLink: Yup.string(),
  socialLink: Yup.string(),
  websiteLink: Yup.string(),
  availableFor: Yup.array().of(Yup.string()),
  competences: Yup.array().of(Yup.string()),
  isPublic: Yup.boolean(),
  showProgress: Yup.boolean(),
  showRefId: Yup.boolean(),
  showMarketPlace: Yup.boolean(),
})
export const Profile = ({
  profile,
  onImageUpdate,
}: {
  profile?: ProfileType
  onImageUpdate: () => void
}) => {
  const { logProfileUpdate } = useAnalytics()
  const history = useHistory()

  const [isSubmitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const [success, setSuccess] = useState<false | string>(false)
  const formRef = useRef<FormikProps<any>>(null)

  const handleSubmit = async (values: ProfileType) => {
    setSubmitLoading(true)
    setError(null)
    setSuccess(false)
    try {
      await updateProfile(values)

      await logProfileUpdate()

      setSuccess('Profilo aggiornato correttamente')
      setSubmitLoading(false)
      formRef.current?.resetForm({ values })
    } catch (error: any) {
      if (error.name === 'ApolloError') {
        const apolloError = error as ApolloError
        const errorCode = JSON.parse(apolloError.message).code
        switch (errorCode) {
          case 'UsernameAlreadyExistsError': {
            formRef?.current?.setFieldError('nickname', 'Username non disponibile')
            break
          }
          default: {
            setError('Si è verificato un errore imprevisto')
          }
        }
      } else {
        setError('Si è verificato un errore imprevisto')
      }
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    const unblock = history.block((location: any) => {
      if (formRef.current?.dirty) {
        if (window.confirm('Le modifiche non sono state salvate. Sei sicuro di voler uscire?')) {
          unblock()
          history.push(location.pathname)
        } else {
          return false
        }
      } else {
        unblock()
        history.push(location.pathname)
      }
      return false
    })

    return () => {
      unblock()
    }
  }, [history])

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }, [success])

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        name: profile?.name ?? undefined,
        lastName: profile?.lastName ?? undefined,
        location: profile?.location ?? undefined,
        profession: profile?.profession ?? undefined,
        company: profile?.company ?? undefined,
        bio: profile?.bio ?? undefined,
        nickname: profile?.nickname ?? undefined,
        linkedinLink: profile?.linkedinLink ?? undefined,
        socialLink: profile?.socialLink ?? undefined,
        websiteLink: profile?.websiteLink ?? undefined,
        availableFor: profile?.availableFor ?? [],
        competences: profile?.competences ?? [],
        isPublic: profile?.isPublic ?? false,
        showProgress: profile?.showProgress ?? false,
        showRefId: profile?.showRefId ?? false,
        showMarketPlace: profile?.showMarketPlace ?? false,
      }}
      validationSchema={PublicProfileSchema}
      onSubmit={handleSubmit}>
      {formik => {
        const SubmitButton = ({ size }: { size: 'sm' | 'lg' }) => {
          return (
            <VerticalStack flex={3} mt={{ _: spacing.space_4, medium: 0 }}>
              <Button
                disabled={!formRef.current?.dirty}
                variant='primary'
                label='Salva modifiche'
                size={size}
                loading={isSubmitLoading}
                onPress={formik.handleSubmit}
              />
              {error && (
                <Box mt={spacing.space_2}>
                  <Text variant='bodyXs' textColor={colors.text.error} alignment='center'>
                    {error}
                  </Text>
                </Box>
              )}
              {success && (
                <Box mt={spacing.space_2}>
                  <Text variant='bodyXs' textColor={colors.text.success} alignment='center'>
                    {success}
                  </Text>
                </Box>
              )}
            </VerticalStack>
          )
        }

        return (
          <VerticalStack gap={spacing.space_4} maxWidth='900px' mb={spacing.space_20}>
            <HorizontalStack justifyContent='flex-end'>
              <HorizontalStack
                justifyContent='flex-end'
                display={{ _: 'none !important', large: 'flex !important' }}>
                <SubmitButton size={'sm'} />
              </HorizontalStack>
            </HorizontalStack>
            <PublicProfileEditor
              formik={formik}
              profile={profile}
              onProfileImageChange={async file => {
                try {
                  await uploadProfileImage(file)
                  await onImageUpdate()
                  setSuccess('Immagine di profilo aggiornata')
                } catch (e) {
                  setError("Si è verificato un errore nell'aggiornamento")
                }
              }}
            />
            <HorizontalStack display={{ _: 'flex !important', large: 'none !important' }}>
              <SubmitButton size={'lg'} />
            </HorizontalStack>
          </VerticalStack>
        )
      }}
    </Formik>
  )
}
