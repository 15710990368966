import { IFrame } from '../../components/IFrame/IFrame'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export const OfflineInfoScreen = () => {
  return (
    <AppLayout>
      <IFrame url='https://learnn.com/int/offline/info/' fullHeight />
    </AppLayout>
  )
}
