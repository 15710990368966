import {
  Box,
  Grid,
  HorizontalStack,
  Text,
  FieldProfileImage,
  FieldCheckboxMultiChoice,
  FieldTagsInput,
  VerticalStack,
  useTheme,
  TextInput,
  TextArea,
  ToggleSwitch,
  LocationAutocomplete,
} from '@learnn/designn'
import env from '../../env.json'

import { FormikProps } from 'formik'
import { FC, useState } from 'react'
import { Profile } from '@learnn/sdk/src/api/profile'
import { faEdit, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SectionCard } from './SectionCard'
import { useShare } from '../SharePopup/useShare'

type PublicProfileEditorProps = {
  profile?: Profile
  onProfileImageChange: (profileImage: File) => unknown
  formik: FormikProps<any>
}
export const PublicProfileEditor: FC<PublicProfileEditorProps> = ({
  profile,
  formik,
  onProfileImageChange,
}) => {
  const { colors, spacing } = useTheme()
  const [isUploadImageLoading, setUploadImageLoading] = useState(false)
  const { share } = useShare()

  return (
    <SectionCard>
      <Box mb={spacing.space_8}>
        <Text textColor={colors.text.secondary}>INFORMAZIONI PERSONALI</Text>
      </Box>

      <HorizontalStack
        gap={spacing.space_10}
        flexDirection={{ _: 'column', medium: 'row' }}
        alignItems='center'>
        <Box>
          <FieldProfileImage
            imageUrl={profile?.profileImage}
            editIcon={<FontAwesomeIcon icon={faEdit} size='2x' color='white' />}
            loading={isUploadImageLoading}
            variant='edit'
            onFileChange={async file => {
              setUploadImageLoading(true)
              await onProfileImageChange(file)
              setUploadImageLoading(false)
            }}
          />
        </Box>
        <VerticalStack width='100%' gap={spacing.space_4}>
          <Grid gridTemplateColumns={{ _: '1fr', medium: '1fr 1fr 1fr' }} gridGap={spacing.space_4}>
            <TextInput
              label='Nome'
              placeholder='Mario'
              name='name'
              variant='dark'
              size='lg'
              error={formik.touched.name ? formik.errors.name : undefined}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextInput
              label='Cognome'
              placeholder='Rossi'
              name='lastName'
              variant='dark'
              size='lg'
              error={formik.touched.lastName ? formik.errors.lastName : undefined}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <LocationAutocomplete 
              apiKey={env.PLACES_API_KEY}
              label='Città'
              placeholder='Roma'
              variant='dark'
              size='lg'
              value={formik.values.location}
              onChange={(x) => {
                formik.setFieldValue('location', x)
              }}
            />
          </Grid>
          <Grid
            mt={spacing.space_6}
            gridTemplateColumns={{ _: '1fr', medium: '1fr 1fr' }}
            gridGap={spacing.space_4}>
            <TextInput
              label='Qualifica'
              placeholder='Growth Manager'
              name='profession'
              variant='dark'
              size='lg'
              error={formik.touched.profession ? formik.errors.profession : undefined}
              value={formik.values.profession}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            <TextInput
              label='Azienda o Università'
              placeholder='Learnn'
              name='company'
              variant='dark'
              size='lg'
              error={formik.touched.company ? formik.errors.company : undefined}
              value={formik.values.company}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <HorizontalStack>
            <Text variant='bodySm' textColor={colors.text.dimmed}>
              La tua Headline:
            </Text>
            <Text variant='bodySm' fontWeight='semibold' ml={spacing.space_2}>
              {formik.values.profession} @ {formik.values.company}
            </Text>
          </HorizontalStack>
        </VerticalStack>
      </HorizontalStack>

      <Box mt={spacing.space_6}>
        <TextArea
          rows={5}
          variant='dark'
          label='Dettagli su di te'
          placeholder=''
          name='bio'
          error={formik.touched.bio ? formik.errors.bio : undefined}
          value={formik.values.bio}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Box>

      <Box my={spacing.space_10}>
        <Text textColor={colors.text.secondary}>SOCIAL NETWORK</Text>
      </Box>

      <Grid gridTemplateColumns={{ _: '1fr', medium: '1fr 1fr 1fr' }} gridGap={spacing.space_4}>
        <TextInput
          label='LinkedIn'
          placeholder='https://linkedin.com'
          name='linkedinLink'
          variant='dark'
          size='lg'
          error={formik.touched.linkedinLink ? formik.errors.linkedinLink : undefined}
          value={formik.values.linkedinLink}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <TextInput
          label='Social'
          placeholder='https://social.com'
          name='socialLink'
          variant='dark'
          size='lg'
          error={formik.touched.socialLink ? formik.errors.socialLink : undefined}
          value={formik.values.socialLink}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <TextInput
          label='Sito / Blog / Profilo'
          placeholder='https://example.com'
          name='websiteLink'
          variant='dark'
          size='lg'
          error={formik.touched.websiteLink ? formik.errors.websiteLink : undefined}
          value={formik.values.websiteLink}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Grid>

      <Box my={spacing.space_10}>
        <Text textColor={colors.text.secondary}>OPPORTUNITÀ E SKILLS</Text>
      </Box>
      <Box mb={spacing.space_4}>
        <Text textColor={colors.text.dimmed}>Sei attualmente disponibile per</Text>
      </Box>
      <Box mb={spacing.space_3}>
        <FieldCheckboxMultiChoice
          options={[
            { label: 'Collaborazioni', value: 'collaborazioni' },
            { label: 'Consulenza', value: 'consulenza' },
            { label: 'Assunzione', value: 'assunzione' },
            { label: 'Formazione', value: 'formazione' },
            { label: 'Freelancing', value: 'freelancing' },
            { label: 'Networking', value: 'networking' },
          ]}
          value={formik.values.availableFor.length === 0 ? [] : formik.values.availableFor}
          onChange={value => formik.setFieldValue('availableFor', value)}
        />
      </Box>
      <Box mt={spacing.space_6} mb={spacing.space_4}>
        <Text textColor={colors.text.dimmed}>Le tue 5 competenze</Text>
      </Box>
      <Box mt={spacing.space_3} mb={spacing.space_3}>
        <FieldTagsInput
          maxItems={5}
          onChange={value => formik.setFieldValue('competences', value)}
          value={formik.values.competences.length === 0 ? [] : formik.values.competences}
        />
      </Box>
      <Box
        my={spacing.space_8}
        mt={spacing.space_8}
        borderTop={`1px solid ${colors.card_border}`}
        pt={spacing.space_8}>
        <Text textColor={colors.text.secondary}>IMPOSTAZIONI CONDIVISIONE</Text>
      </Box>
      <Box mb={spacing.space_8}>
        <Text textColor={colors.text.dimmed}>
          Pubblica e condividi il tuo profilo Learnn per mostrare i tuoi progressi e risultati ad
          aziende e clienti.
        </Text>
      </Box>
      <Grid
        gridTemplateColumns={{ _: '1fr', medium: '1fr 2fr' }}
        gap={spacing.space_8}
        mb={spacing.space_8}>
        <Box>
          <TextInput
            label='Username'
            placeholder=''
            name='nickname'
            variant='dark'
            size='lg'
            error={formik.touched.nickname ? formik.errors.nickname : undefined}
            value={formik.values.nickname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>
        <HorizontalStack alignItems='flex-end'>
          <VerticalStack justifyContent='flex-end'>
            <Box mb={spacing.space_1}>
              <Text textColor={colors.text.dimmed}>Anteprima link pubblico:</Text>
            </Box>
            <Text variant='bodySm' fontWeight='bold'>
              https://learnn.com/profile/{formik.values.nickname}
            </Text>
          </VerticalStack>
          {formik.values.isPublic && (
            <Box
              ml={spacing.space_8}
              cursor='pointer'
              onClick={() =>
                share({
                  title: 'Condividi il tuo profilo Learnn',
                  type: 'profile',
                  url: `https://learnn.com/profile/${profile?.nickname}`,
                })
              }>
              <FontAwesomeIcon icon={faShareFromSquare} size='lg' color={colors.text.primary} />
            </Box>
          )}
        </HorizontalStack>
      </Grid>

      <ToggleSwitch
        id='isPublic'
        label='Rendi pubblico il tuo profilo'
        checked={formik.values.isPublic}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </SectionCard>
  )
}
