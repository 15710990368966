import style from './MyLearnnScreen.module.css'
import {useHistory} from 'react-router-dom'

//Components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {ErrorMessage} from '../../components/ErrorMessage'
import {Button} from '../../components/Button'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {LessonsCarousel} from '../../components/LessonsCarousel'

// Controllers
import {CourseCard} from '../../components/CourseCard'

import {getMyLearnn} from '../../controllers/MyLearnn'

import Loader from 'react-loader-spinner'
import {CourseBaseInfo, CourseBaseInfoEngagement} from "@learnn/sdk/dist/api/course";
import {useReactive} from "../../utils/useReactive";
import * as lib from "fp-ts/function";
import * as O from "fp-ts/Option";
import { VerticalStack } from '@learnn/designn'
import { CustomCarousel } from '../../components/Carousel/CustomCarousel'

export const MyLearnn = () => {
  const history = useHistory()

  const {data, error} = useReactive({
    observableFn$: getMyLearnn(),
    queryKey: ['myLearnn']
  })

  const renderCourses = (course: CourseBaseInfo & Partial<CourseBaseInfoEngagement>) => (
      <CourseCard
        course={course}
        myLearnnId={course.myLearnnId}
        myLearnn
        onClick={() => history.push(`/corso/${course.id}`)}
      />
  )

  const renderWebinars = (course: CourseBaseInfo & Partial<CourseBaseInfoEngagement>) => (
      <CourseCard
        course={course}
        myLearnnId={course.myLearnnId}
        myLearnn
        onClick={() => history.push(`/corso/${course.id}`)}
      />
  )

  return lib.pipe(
    error,
    O.fold(() => {
        if (O.isNone(data))
          return (
            <div className={style.containerLoading}>
              <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50}/>
            </div>
          )
        else {
          if (!data?.value.courses.length && !data?.value.webinars.length && !data?.value.lessons.length) {
            return (
              <Container>
                <Row className='pt-5'>
                  <Col xs={12} md={{span: 8, offset: 2}} className={style.containerEmpty}>
                    <h1 className={style.emptyTextTitle}>Nessun contenuto salvato</h1>
                    <h4 className={style.emptyText}>
                      In questa sezione troverai tutti i corsi, i webinar e le lezioni che hai salvato.
                    </h4>
                    <Button className={style.continueButton} onClick={() => history.push(`/contenuti/`)}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <p className={style.buttonText}>{'Vai ai contenuti'}</p>
                        <FontAwesomeIcon icon={faChevronRight} className={style.playIcon}/>
                      </div>
                    </Button>
                  </Col>
                </Row>
              </Container>
            )
          }

          return (
            <VerticalStack>
              <CustomCarousel 
                title={'Corsi'}
                data={data.value.courses}
                carouselKind='course'
                renderSlide={renderCourses}
              />
              <CustomCarousel 
                title={'Webinar'}
                data={data.value.webinars}
                carouselKind='course'
                renderSlide={renderWebinars}
              />
               <LessonsCarousel
                  lessons={data.value.lessons}
                  title='Lezioni'
                  titleClass={style.sectionTitle}
                  myLearnn
                />
            </VerticalStack>
          );
        }
      },
      () => <ErrorMessage/>
    ),
  )

}
