"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.truncateString = exports.suppressError = void 0;
const suppressError = (f) => {
    f.catch((e) => console.log('TRACK ERROR. TRACK SUPPRESSED:', e.message));
};
exports.suppressError = suppressError;
const truncateString = (s, n) => {
    if (!s || s.length <= n) {
        return s;
    }
    return `${s.substring(0, n - 3)}...`;
};
exports.truncateString = truncateString;
