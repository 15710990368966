import { FlowiseChatPlayer } from 'src/components/FlowiseChat/FlowiseChatPlayer'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { GlobalProviderContext } from '../../GlobalProvider'
import { matchQuery } from '../../../utils/matchQuery'
import { getUserProfileWithInterests } from 'src/controllers/Profile'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

type AssistantTabProps = {
  courseName: string
  lessonName: string
  transcript: string
}
export const AssistantTab = ({ courseName, lessonName, transcript }: AssistantTabProps) => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  const profileQuery = useQuery(['profile', userId], () => getUserProfileWithInterests(userId))

  return (
    <>
      {matchQuery(profileQuery, {
        loading: () => <AssistantTabSkeleton />,
        error: () => (
          <FlowiseChatPlayer
            courseName={courseName}
            lessonName={lessonName}
            transcript={transcript}
          />
        ),
        success: profile =>
          pipe(
            profile,
            O.fold(
              () => (
                <FlowiseChatPlayer
                  courseName={courseName}
                  lessonName={lessonName}
                  transcript={transcript}
                />
              ),
              profile => (
                <FlowiseChatPlayer
                  courseName={courseName}
                  lessonName={lessonName}
                  transcript={transcript}
                  profile={profile}
                />
              ),
            ),
          ),
      })}
    </>
  )
}

export const AssistantTabSkeleton = () => {
  return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
          <SkeletonTheme baseColor='#27272C' highlightColor='#37393E'>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px', marginBottom: '16px' }}>
              <Skeleton style={{ height: '52px', borderRadius: '15px' }}></Skeleton>
              <Skeleton style={{ height: '52px', borderRadius: '15px' }}></Skeleton>
              <Skeleton style={{ height: '52px', borderRadius: '15px' }}></Skeleton>
              <Skeleton style={{ height: '52px', borderRadius: '15px' }}></Skeleton>
            </div>
            <Skeleton style={{ width: '100%', height: '52px', marginBottom: '0px', borderRadius: '15px' }}></Skeleton>
          </SkeletonTheme>
        </div>
      )
}