import { FC } from 'react'
import { ResponsiveImage } from '../ResponsiveImage'
import style from './verifiedCard.module.css'
import { QuizCourse } from '@learnn/sdk/src/api/dynamic-sections/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

export interface IVerifiedCard {
  quiz: QuizCourse
  onClick: () => unknown
}

export const VerifiedCard: FC<IVerifiedCard> = ({ quiz, onClick }) => {
  return (
    <div className={style.card} onClick={onClick}>
      <div className={style.cardInner}>
        <div className={style.imageContainer}>
          <ResponsiveImage wrapperClassName={style.image} src={quiz.coverImage} />
        </div>
        <div className={style.infoContainer}>
          <p className={style.title}>{quiz.title}</p>
          <p className={style.author}>{`10 DOMANDE`}</p>
          <span className={style.continue}>
            Inizia la verifica <FontAwesomeIcon icon={faCaretRight} size='lg' />
          </span>
        </div>
      </div>
    </div>
  )
}
