import { useEffect, useState } from "react"
import { Message } from "../Assistant/types"
import { AiChatAssistantService } from "./assistantService"

type UseAiChatAssistantProps = {
    assistantFactory: () => AiChatAssistantService
}

export const useAiChatAssistant = ({ assistantFactory }: UseAiChatAssistantProps) => {
    const [assistantService, setAssistantService] = useState<AiChatAssistantService>()
    const [messages, setMessages] = useState<Message[]>([])
    const [isLoading, setLoading] = useState(false)
    const [token, setToken] = useState<string>()

    useEffect(() => {
        const service = assistantFactory()
        service.onMessageReceive(async ({ message, sources }) => {
            setLoading(false)
            setMessages(m => ([
                ...m,
                {
                    role: 'assistant',
                    data: message,
                    createdAt: new Date(),
                    sources
                }
            ]))
        })
        service.onTokenReceive(async (token) => {
            setToken(token)
        })

        setAssistantService(service)
    }, [])

    return {
        sendMessage: async (message: string) => {
            if (!assistantService) {
                console.error('Assistant Service instance not ready')
                return
            }
            setLoading(true)
            await assistantService.sendMessage(message)
            setMessages(m => ([
                ...m,
                {
                    role: 'user',
                    createdAt: new Date(),
                    data: message
                }
            ]))
        },
        isLoading,
        messages,
        token
    }
}