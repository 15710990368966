import { useContext, useEffect } from 'react'
import { IVideoControls, IVideoReadOnlyState } from '../useReactPlayer'
import { ControlsContext } from './controlsContext'
import { PlayerSideContext } from '../PlayerSideContent/playerSideContext'
import { useKeyPress, useKeyClick } from '../../../utils/hooks'

export const useKeyboardControls = (controls: IVideoControls, state: IVideoReadOnlyState) => {
  const { state: controlsState } = useContext(ControlsContext)
  const { state: playerSideState } = useContext(PlayerSideContext)

  const spacePressed = useKeyClick(' ', () => {
    if (
      spacePressed &&
      !controlsState.feedbackPopup.visible &&
      (playerSideState.status === 'hide' ||
        (playerSideState.status === 'visible' && !playerSideState.playerSideFocus))
    ) {
      if (state.playing) {
        controls.pause()
      } else {
        controls.play()
      }
    }
  })
  const forwardPressed = useKeyPress('ArrowRight')
  const backwardPressed = useKeyPress('ArrowLeft')

  useEffect(() => {
    if (
      forwardPressed &&
      !controlsState.feedbackPopup.visible &&
      (playerSideState.status === 'hide' ||
        (playerSideState.status === 'visible' && !playerSideState.playerSideFocus))
    ) {
      seek(Number(state.playedSeconds) + 15)
    }
  }, [forwardPressed])

  useEffect(() => {
    if (
      backwardPressed &&
      !controlsState.feedbackPopup.visible &&
      (playerSideState.status === 'hide' ||
        (playerSideState.status === 'visible' && !playerSideState.playerSideFocus))
    ) {
      seek(Number(state.playedSeconds) - 15)
    }
  }, [backwardPressed])

  const seek = (n: number) => {
    controls.seekTo(n, 'seconds')
  }
}
