import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function PlanDescription({ plan, couponPrice, trial }) {
  const bullets = plan.bullet
  return (
    <Row className='align-items-center mb-4'>
      <Col xs={12} md={7} className='d-none d-md-block'>
        {bullets.map((bullet, index) => (
          <div key={bullet} className={index !== bullets.length - 1 ? 'mb-2' : ''}>
            <FontAwesomeIcon icon={faCheck} /> <span className='bullet-list'>{bullet}</span>
          </div>
        ))}
      </Col>
      <Col xs={12} md={5}>
        {trial ? (
          <div className='plan-description-container-trial'>
            <div className='plan-description-title'>Accesso Gratuito</div>
            <span className='plan-description-price' style={{ fontWeight: 700 }}>
              {`${trial.period} giorni`}
            </span>
          </div>
        ) : (
          <div className='plan-description-container'>
            <div className='plan-description-title'>{plan.title}</div>
            {couponPrice !== undefined && couponPrice !== null ? (
              <span className='plan-description-price' style={{ fontWeight: 700 }}>
                {couponPrice}€
              </span>
            ) : (
              <span className='plan-description-price'>{plan.price}€</span>
            )}
          </div>
        )}
      </Col>
    </Row>
  )
}

export default PlanDescription
