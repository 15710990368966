import { getCourseBaseInfo } from '../../../../controllers/Course'
import { useBodyClass } from '../../../../utils/hooks'
import { useQuery } from 'react-query'
import { matchQuery } from '../../../../utils/matchQuery'
import { getAuthorsNames } from '../../../../utils/data'
import useAnalytics from '../../../../analytics/useAnalytics'
import { Overview } from './Overview'
import { Loader } from './Loader'

export type OverviewSectionProps = {
  courseId: string
  onVerifyClick: Function
  onShowVerifiedClick: Function
  verifiedEnabled: boolean
}

export const OverviewSection = ({
  courseId,
  onVerifyClick,
  onShowVerifiedClick,
  verifiedEnabled,
}: OverviewSectionProps) => {
  useBodyClass('app')

  const courseQuery = useQuery(['course', courseId], () => getCourseBaseInfo(courseId))
  const { logCourseOpened } = useAnalytics()

  return (
    <>
      {matchQuery(courseQuery, {
        loading: () => <Loader />,
        error: () => <>error</>,
        success: course => (
          <Overview
            course={course}
            onShowVerifiedClick={onShowVerifiedClick}
            onVerifyCourseClick={onVerifyClick}
            verifiedEnabled={verifiedEnabled}
            onLoad={course =>
              logCourseOpened(course.id, {
                course_title: course.title,
                course_type: course.type.slug,
                authors: getAuthorsNames(course.authors),
                course_progress: course.progress ? course.progress : 0,
              })
            }
          />
        ),
      })}
    </>
  )
}
