import style from "./homeScreen.module.css";

export interface IHomeBannerProps {
  content: any;
}

export const HomeBanner = ({ content }: IHomeBannerProps) => {
  if (content.url) {
    return (
      <div className={style.banner} style={{ backgroundImage: `url(${content.imageUrl})` }}>
        <a className={style.bannerLink} href={content.url} target={content.target ?? "_self"}>
          <h3 className={style.bannerTitle}>{content.title}</h3>
          <p className={style.bannerDescription}>{content.description}</p>
        </a>
      </div>
    );
  } else {
    return (
      <div className={style.banner} style={{ backgroundImage: `url(${content.imageUrl})` }}>
        <h3 className={style.bannerTitle}>{content.title}</h3>
        <p className={style.bannerDescription}>{content.description}</p>
      </div>
    );
  }
};
