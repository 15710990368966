import { useContext, useEffect, useState } from 'react'
import { AppLayout } from '../layouts/AppLayout/AppLayout'
import { TeamRole } from '@learnn/sdk/dist/api/auth'
import { GlobalProviderContext } from 'src/components/GlobalProvider'
import { Button } from '@learnn/designn'
import env from '../env.json'
import { IFrame } from 'src/components/IFrame/IFrame'

const isB2BOwner = (role: TeamRole | undefined) => role === 'owner'
const isB2BUser = (teamId: string | undefined) => teamId !== undefined

export const DashboardB2BScreen = () => {
  const globalContext = useContext(GlobalProviderContext)

  const isB2B = isB2BUser(globalContext?.b2b.teamId)
  const b2bOwner = isB2BOwner(globalContext?.b2b.role)

  const isIndividualUser = !isB2B && !b2bOwner

  const blurStyle = {
    filter: isIndividualUser ? 'blur(6px)' : 'none',
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800); 
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div style={{ backgroundColor: 'black' }}>
    <AppLayout fullwidth hideHeader disableMargin>
      {isIndividualUser ? <div style={{ position: 'relative', textAlign: 'center', height: '100vh', overflow: 'hidden' }}>
        <img
          src={ isMobile ? env.B2B_DASHBOARD_IMAGE_URL_MOBILE : env.B2B_DASHBOARD_IMAGE_URL}
          alt="Preview dashboard Learnn B2B"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: 'auto',
            height: '100vh',
            minWidth: '100%',
            minHeight: '100%',
            transform: 'translate(-50%, -50%) scale(1.25)',
            objectFit: 'cover',
            ...blurStyle,
          }}
        />

        <Button  
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', 
            zIndex: 1, 
          }}
          variant='primary' 
          size='lg' 
          label={'Scopri Learnn per il tuo Team ➔'} 
          onPress={() => window.open('https://learnn.com/int/dashboard-b2b/', '_blank')}
          />
      </div> : <IFrame url='https://learnn.com/int/dashboard-b2b/' />}
    </AppLayout>
          </div>
  )
}
