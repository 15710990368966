import { gql } from '@apollo/client';
import { normalizeGraphqlResponse } from '../utils';
export const configuration = (cmsClient, configServiceCLient, auth) => {
    const getAppConfiguration = async () => {
        const defaultConfiguration = { enableTracking: false, trackingMode: 'void' };
        try {
            const result = await cmsClient
                .query({
                query: APP_CONFIGURATION,
            })
                .then(normalizeGraphqlResponse);
            const configurations = result.data?.configurations ?? [];
            if (configurations.length === 1) {
                return { ...defaultConfiguration, ...toConfiguration(configurations[0]) };
            }
            return {
                ...defaultConfiguration,
                ...toConfiguration(result.data.configurations[0]),
            };
        }
        catch (e) {
            console.warn(e);
            return defaultConfiguration;
        }
    };
    const getWebConfiguration = (me) => async () => {
        const defaultConfiguration = {
            enableTracking: false,
            trackingMode: 'void',
            premiumVisible: false,
        };
        try {
            const result = await cmsClient
                .query({
                query: WEB_CONFIGURATION,
            })
                .then(normalizeGraphqlResponse);
            const role = await getRole(me)();
            const configurations = result.data?.configurations ?? [];
            if (configurations.length === 1) {
                return {
                    ...defaultConfiguration,
                    premiumVisible: role === 'Authenticated',
                    ...toConfiguration(configurations[0]),
                };
            }
            return {
                ...defaultConfiguration,
                premiumVisible: role === 'Authenticated',
                ...toConfiguration(result.data.configurations[0]),
            };
        }
        catch (e) {
            console.warn(e);
            return defaultConfiguration;
        }
    };
    const getRole = (me) => async () => {
        const defaultRole = 'Free';
        try {
            const result = await me();
            if (result?.role) {
                return result.role;
            }
            return defaultRole;
        }
        catch (e) {
            console.log(e);
            return defaultRole;
        }
    };
    const toConfiguration = (c) => {
        if (!c) {
            return {};
        }
        const jsonConfig = (typeof c === 'string' ? JSON.parse(c) : c).jsonConfig;
        if (!jsonConfig || (typeof jsonConfig === 'string' && jsonConfig.trim() === '')) {
            return {};
        }
        return typeof jsonConfig === 'string' ? JSON.parse(jsonConfig) : jsonConfig;
    };
    const getAll = async () => {
        let configHash = {};
        let nextToken;
        try {
            do {
                const configResult = await configServiceCLient.query({
                    query: GET_ALL,
                    variables: {
                        nextToken,
                        limit: 200,
                    },
                });
                const configurations = configResult.data?.configurations?.items ?? [];
                nextToken = configResult.data?.configurations?.nextToken;
                configurations.forEach((c) => {
                    configHash[c.courseId] = c.available;
                });
            } while (!!nextToken);
            return configHash;
        }
        catch (error) {
            return configHash;
        }
    };
    const getAllIDs = async () => {
        let configHash = [];
        let nextToken;
        try {
            do {
                const configResult = await configServiceCLient.query({
                    query: GET_ALL,
                    variables: {
                        nextToken,
                        limit: 200,
                    },
                });
                const configurations = configResult.data?.configurations?.items ?? [];
                nextToken = configResult.data?.configurations?.nextToken;
                configurations
                    .filter((c) => c.available === true)
                    .forEach((c) => {
                    configHash.push(c.courseId);
                });
            } while (!!nextToken);
            return configHash;
        }
        catch (error) {
            return configHash;
        }
    };
    const getQuizAvailabilityByCourse = async (courseId) => {
        const configResult = await configServiceCLient.query({
            query: GET_ONE,
            variables: { courseId },
        });
        const config = configResult.data?.configuration;
        if (!configResult)
            throw new Error(`no configuration found for ${courseId}`);
        return config.available ?? false;
    };
    return {
        getApp: getAppConfiguration,
        getWeb: getWebConfiguration(auth.me),
        quizAvailability: getAll,
        quizAvailabilityList: getAllIDs,
        isQuizAvailable: getQuizAvailabilityByCourse,
    };
};
const APP_CONFIGURATION = gql `
  query APP_CONFIGURATION {
    configurations(filters: { platform: { eq: "app" } }) {
      data {
        id
        attributes {
          jsonConfig
        }
      }
    }
  }
`;
export const WEB_CONFIGURATION = gql `
  query WEB_CONFIGURATION {
    configurations(filters: { platform: { eq: "web" } }) {
      data {
        id
        attributes {
          jsonConfig
        }
      }
    }
  }
`;
const GET_ALL = gql `
  query getAllConfig($limit: Int!, $nextToken: String) {
    configurations(limit: $limit, nextToken: $nextToken) {
      items {
        available
        courseId
      }
      nextToken
    }
  }
`;
const GET_ONE = gql `
  query getConfiguration($courseId: String!) {
    configuration(courseId: $courseId) {
      available
      courseId
    }
  }
`;
