import { Box, Title, VerticalStack, useTheme } from '@learnn/designn'
import { FC } from 'react'

export type SectionTitleProps = {
  title: string
}
export const SectionTitle: FC<SectionTitleProps> = ({ title }) => {
  const { colors, spacing } = useTheme()

  return (
    <VerticalStack>
      <Title variant='headingXs' fontWeight='bold' mb={spacing.space_5}>
        {title}
      </Title>
      <Box width='100%' height='1px' backgroundColor={colors.edge} mb={spacing.space_8} />
    </VerticalStack>
  )
}
