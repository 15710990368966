"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analytics = exports.courseSummary = exports.consoleAnalytics = exports.voidAnalytics = void 0;
var providers_1 = require("./providers");
Object.defineProperty(exports, "voidAnalytics", { enumerable: true, get: function () { return providers_1.voidAnalytics; } });
Object.defineProperty(exports, "consoleAnalytics", { enumerable: true, get: function () { return providers_1.consoleAnalytics; } });
var utils_1 = require("./utils");
Object.defineProperty(exports, "courseSummary", { enumerable: true, get: function () { return utils_1.courseSummary; } });
var functions_1 = require("./functions");
Object.defineProperty(exports, "analytics", { enumerable: true, get: function () { return functions_1.analytics; } });
