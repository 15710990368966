import { GetFeaturesInput } from "@learnn/sdk/src/api/feature-flag";
import api from "./api";


export const getFeatures = async (config: GetFeaturesInput) => {
    if (!config.userId || !config.acData)
        throw new Error('User id not defined')

    const { featureFlag } = await api;

    return featureFlag.getFeatures(config)

}