import { useEffect, useRef } from 'react'
import useAnalytics from '../../../analytics/useAnalytics'
import { QuizLogInfo } from '@learnn/analytics/src'
import { QuizStatus } from '../QuizScreen'
import { QuizAttemptDetails, QuizItem } from '@learnn/sdk/src/api/quiz/'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course/'

const useQuizAnalytics = (
  quiz: QuizItem | undefined,
  attempt: QuizAttemptDetails | undefined,
  course: CourseBaseInfoEngagement | undefined,
  _quizError: Error | null,
  status: QuizStatus,
) => {
  const loggedState = useRef<QuizStatus>('not_started')

  const { logQuizStarted, logQuizSubmitted, logQuizFailed, logQuizPassed } = useAnalytics()

  const logStartQuizEvents = () => {
    if (!quiz || !course) return

    logQuizStarted(quiz.attemptId, {
      title: `Quiz:${course.title}`,
      course_title: course.title,
      course_id: course.id,
      type: 'COURSE',
      score: 0,
    })
  }

  function logSubmitQuizEvents() {
    if (!quiz || !course || !attempt) return

    const attemptToLog: QuizLogInfo = {
      title: `Quiz:${course.title}`,
      course_title: course.title,
      course_id: course.id,
      type: 'COURSE',
      score: Math.round((attempt.correctAnswers / attempt.totalQuestions) * 100),
    }

    logQuizSubmitted(quiz.attemptId, attemptToLog)

    if (attempt.passed) {
      logQuizPassed(quiz.attemptId, attemptToLog)
    } else {
      logQuizFailed(quiz.attemptId, attemptToLog)
    }
    attemptToLog
  }

  useEffect(() => {
    if (loggedState.current === status) return

    if (status === 'started') {
      logStartQuizEvents()
    } else if (status === 'completed') {
      logSubmitQuizEvents()
    }
  }, [status])

  return {}
}

export default useQuizAnalytics
